import Api from 'requestapijs';
import {
  AddSalesVariable,
  SalesNodeEdgeMutation,
  salesMutation,
  updatePaymentModeForSalesMutation,
} from '../components/Sales/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  UpdatePaymentModeForSalesInput,
  UpdatePaymentModeInput,
  UpdatePaymentModeForSalesPayload,
} from '../schema';
import {
  SalesPersonNodeEdge,
  SalesPersonVariables,
  salesPersonMutation,
} from '../components/master/SalesPerson/constants';

export class SalesService {
  static instance;

  constructor() {
    if (SalesService.instance) {
      return SalesService.instance;
    } else {
      SalesService.instance = this;
    }
  }

  companyService = new CompanyService();

  addSales(
    data: SalesNodeEdgeMutation,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails?.map((pd) => {
      pd.date = data.date;
      pd.movementForId =
        pd.movementForId ||
        this.companyService?.selectedBussiness$.getValue()?.id;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });
    data.oldProductDetails = data.oldProductDetails?.map((pd) => {
      pd.date = data.date;
      pd.movementForId =
        pd.movementForId ||
        this.companyService?.selectedBussiness$.getValue()?.id;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });
    data.paymentModes = data.paymentModes.map((pm) => ({
      ...pm,
      date: data.date,
    }));
    Api.graphql<AddSalesVariable, {}>(
      API_URL,
      {
        input: {
          sales: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
            branchId:
              data.branchId ||
              this.companyService?.selectedBussiness$.getValue()?.id,
          },
        },
      },
      salesMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }

  addSalesPerson(
    data: SalesPersonNodeEdge,
    onSuccess: (response) => void,
    onError: (error) => void,
  ) {
    Api.graphql<SalesPersonVariables, {}>(
      API_URL,
      {
        input: {
          salesPerson: {
            ...data,
            companyId: this.companyService?.company$.value?.id,
            branchId: this.companyService?.selectedBussiness$.value?.id,
          },
        },
      },
      salesPersonMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => {
        onError(error);
      },
    );
  }

  updatePaymentModeForSales(
    data: UpdatePaymentModeInput,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    Api.graphql<
      { input: UpdatePaymentModeForSalesInput },
      UpdatePaymentModeForSalesPayload
    >(
      API_URL,
      {
        input: {
          paymentMode: data,
        },
      },
      updatePaymentModeForSalesMutation,
      HEADER,
      (response) => {
        onSuccess && onSuccess(response);
      },
      onError,
    );
  }
}

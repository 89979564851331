import * as React from 'react';
import {
  Row,
  Col,
  Skeleton,
  Icon,
  Button,
  DatePicker,
  Divider,
  Table,
} from 'antd';
import { invoiceCreditPaymentReportQuery } from './constants';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { PaymentModeNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { GraphqlQuery } from 'requestapijs';
import moment from 'moment';
import { SalesService } from '../../service/SalesService';
import { CREDIT_PAYMENT_HISTORY_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';

export class InvoiceCreditPaymentReport extends React.Component<Props, State> {
  salesService = new SalesService();

  constructor(props: Props) {
    super(props);
    this.state = {
      startDate:
        moment().get('month') < 3
          ? `${moment().subtract(1, 'year').format('YYYY')}-04-01`
          : `${moment().get('year')}-04-01`,
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
      updateDataCount: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Invoice No',
      dataIndex: 'node.salechallanSet.edges[0].node.challanNo',
      key: 'billNo',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'node.salechallanSet.edges[0].node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Customer',
      dataIndex:
        'node.salechallanSet.edges[0].node.deliveryInstruction.customer.name',
      key: 'customer',
    },
    {
      title: 'Total Amount',
      dataIndex: 'node.salechallanSet.edges[0].node.totalAmount',
      key: 'netAmount',
      render: (value) => Number(Number(value).toFixed(2)),
    },
    {
      title: 'Total Credit Amount',
      dataIndex:
        'node.salechallanSet.edges[0].node.paymentModes.edges[0].node.amount',
      key: 'totalCreditAmount',
      render: (value) => Number(Number(value).toFixed(2)),
    },
    {
      title: 'Payment Date',
      dataIndex: 'node.date',
      key: 'paymentDate',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Payment Type',
      dataIndex: 'node.paymentType',
      key: 'paymentType',
    },
    {
      title: 'Payment Amount',
      dataIndex: 'node.amount',
      key: 'paymentAmount',
    },
    {
      title: 'Discount',
      dataIndex: 'node.balanceAmount',
      key: 'balanceAmount',
    },
    {
      title: 'Narration',
      dataIndex: 'node.narration',
      key: 'narration',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CREDIT_PAYMENT_HISTORY_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `cerdit-payment-history-${new Date()}`,
          'CREDIT PAYMENT HISTORY REPORT',
          this.props.company.name,
          '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { after, currentPage, updateDataCount, startDate, endDate } =
      this.state;
    const {
      company: { id: companyId },
    } = this.props;
    const variables = { companyId, startDate, endDate, after, updateDataCount };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={invoiceCreditPaymentReportQuery}
          variables={variables}
          render={(
            response: { allPaymentModes: PaymentModeNodeConnection },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allPaymentModes.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allPaymentModes.pageInfo.endCursor,
                      response.allPaymentModes.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv({ ...variables, forSaleInvoice: 1 })}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
  updateDataCount: number;
}

import * as React from 'react';
import {
  DatePicker,
  Divider,
  Spin,
  Row,
  Col,
  Button,
  Skeleton,
  Table,
} from 'antd';
import moment from 'moment';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { downloadToFile } from '../../helper';
import { TSInput } from '../../common/TSInput';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { interLocationProductionTransfer } from './constants';
import { ConversionDetailNodeConnection } from '../../../schema';
import { FINISHED_PRODUCT_TRANSFER_REPORT_CSV_URL } from '../../../config';

export class FinishedProductTransferReport extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      product: '',
    };
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (value) => value && moment(value).format('DD-MM-YYYY'),
      sortingValue: (value) => value,
    },
    {
      title: 'Finished Product',
      key: 'finishedProduct',
      dataIndex: 'node.conversion.finishedProduct',
      sortingValue: (value) => value,
    },
    {
      title: 'From',
      key: 'from',
      dataIndex: 'node.sourceBranch.name',
      render: (value, record) =>
        value
          ? `(${record.node.sourceBranch.category})${value}`
          : `(Vendor)${record.node.vendorFor.name}`,
      sortingValue: (value, record) => value || record.node.vendorFor.name,
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'node.destinationBranch.name',
      render: (value, record) =>
        value
          ? `(${record.node.destinationBranch.category})${value}`
          : record.node.vendorFor && record.node.sourceBranch
          ? `(Vendor)${record.node.vendorFor.name}`
          : 'Head Office',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(FINISHED_PRODUCT_TRANSFER_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `finished-product-transfer-${new Date()}`,
          `FINISHED PRODUCT TRANSFER REPORT`,
          this.props.company.name,
          'Head Production',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { startDate, endDate, currentPage, after, product } = this.state;
    const {
      company: { id: companyId },
      activeKey,
    } = this.props;
    const variables = {
      startDate,
      endDate,
      companyId,
      product,
      after,
      activeKey,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={startDate ? moment(startDate) : null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={endDate ? moment(endDate) : null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.product}
              onChange={(e) => this.setState({ product: e.target.value })}
              name="product"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
        </Row>
        <Divider />
        {startDate && endDate && (
          <GraphqlQuery
            queryString={interLocationProductionTransfer}
            variables={variables}
            render={(
              response: {
                allConversionDetails: ConversionDetailNodeConnection;
              },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allConversionDetails.edges}
                    columns={this.columns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allConversionDetails.pageInfo.endCursor,
                        response.allConversionDetails.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
        {startDate && endDate && (
          <Button
            key="download"
            style={{ float: 'right' }}
            icon="download"
            onClick={() => this.downloadCsv(variables)}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
}

interface State {
  startDate: string;
  endDate: string;
  currentPage: number;
  after: string;
  product: string;
}

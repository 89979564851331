import * as React from 'react';
import {
  DeliveryInstructionNode,
  DeliveryInstructionInput,
  ProductNode,
  CustomerNode,
  ProductDetailsInput,
} from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { DeliveryInstructionService } from '../../service/DeliveryInstructionService';
import { handleProductDetailsData } from '../Transfer/constants';
import { ProductDetailsNodeConnection } from '../Sales/constants';
import {
  deliveryInstructionObject,
  deliveryProductDetailsObject,
  DeliveryInstructionError,
  deliveryInstructionErrorObject,
  deliveryProductDetailsError,
} from './constants';
import { notification, Button, Modal, Popconfirm } from 'antd';
import { DeliveryInstructionForm } from './DeliveryInstructionForm';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';

export class AddDeliveryInstruction extends React.Component<Props, State> {
  deliveryInstructionService = new DeliveryInstructionService();

  constructor(props: Props) {
    super(props);
    this.state = {
      deliveryInstruction: this.handleValuePropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      buttonDisabled: false,
      productObjList: [],
      customerObj: null,
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const deliveryInstruction: DeliveryInstructionInput =
      this.handleValuePropsUpdate(nextProps);
    const error: DeliveryInstructionError =
      this.handleErrorPropsUpdate(nextProps);
    this.setState({ deliveryInstruction, error });
  }

  handleValuePropsUpdate(props: Props) {
    const deliveryInstruction: DeliveryInstructionInput =
      props.deliveryInstruction
        ? {
            ...props.deliveryInstruction,
            customerId: props.deliveryInstruction.customer.id,
            productDetails: handleProductDetailsData(
              props.deliveryInstruction
                .productDetails as any as ProductDetailsNodeConnection,
            ),
          }
        : JSON.parse(JSON.stringify(deliveryInstructionObject));
    delete (deliveryInstruction as any).customer;
    return deliveryInstruction;
  }

  handleErrorPropsUpdate(nextProps: Props) {
    const error: DeliveryInstructionError = JSON.parse(
      JSON.stringify(deliveryInstructionErrorObject),
    );
    const productDetails =
      nextProps.deliveryInstruction &&
      (nextProps.deliveryInstruction
        .productDetails as any as ProductDetailsNodeConnection);
    productDetails &&
      productDetails.edges.forEach(() => {
        if (productDetails.edges.length !== error.productDetails.length) {
          error.productDetails.push(deliveryProductDetailsError);
        }
      });
    return error;
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const deliveryInstruction = { ...this.state.deliveryInstruction };
    deliveryInstruction[e.target.name] = e.target.value;
    this.setState({ deliveryInstruction });
  };

  checkError = () => {
    const error: DeliveryInstructionError = JSON.parse(
      JSON.stringify(this.state.error),
    );
    const deliveryInstructionValue = this.state.deliveryInstruction;
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !deliveryInstructionValue[errKey] &&
        deliveryInstructionValue[errKey] !== false &&
        errKey !== 'productDetails'
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.deliveryInstruction.productDetails[index][e.target.name] =
      e.target.value;
    if (
      nextState.deliveryInstruction.productDetails[index].quantity &&
      nextState.deliveryInstruction.productDetails[index].rate
    ) {
      nextState.deliveryInstruction.productDetails[index].amount =
        Number(nextState.deliveryInstruction.productDetails[index].quantity) *
        Number(nextState.deliveryInstruction.productDetails[index].rate);
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.deliveryInstruction.productDetails.push(
        JSON.parse(JSON.stringify(deliveryProductDetailsObject)),
      );
      nextState.error.productDetails.push(
        JSON.parse(JSON.stringify(deliveryProductDetailsError)),
      );
    } else {
      nextState.deliveryInstruction.productDetails.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.deliveryInstruction.productDetails[index].rate =
      productObj.sellingRate;
    nextState.deliveryInstruction.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  checkProductError = () => {
    const productDetails: ProductDetailsInput[] = {
      ...this.state.deliveryInstruction.productDetails,
    };
    const error: State['error'] = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.deliveryInstruction.customerId = customerObj.id;
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveDeliveryInstruction = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.deliveryInstructionService?.addDeliveryInstruction(
      this.state.deliveryInstruction,
      (response) => {
        notification.success({
          message:
            'Delivery Instruction ' + this.state.deliveryInstruction.id
              ? 'Updated'
              : 'Added',
          description: `Delivery Instruction was successfully ${
            this.state.deliveryInstruction.id ? 'Updated' : 'Added'
          }`,
        });
        this.clear();
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Delivery Instruction ' + this.state.deliveryInstruction.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      deliveryInstruction: JSON.parse(
        JSON.stringify(deliveryInstructionObject),
      ),
      error: JSON.parse(JSON.stringify(deliveryInstructionErrorObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <DeliveryInstructionForm
          values={this.state.deliveryInstruction}
          error={this.state.error}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          company={this.props.company}
          updateCount={this.state.updateCount}
          customerObjChangehandler={this.customerObjChangehandler}
          handleModalAction={this.handleModalAction}
        />
        <Modal
          title="Add Customer"
          visible={this.state.modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            onCustomerAdded={() =>
              this.setState({ updateCount: this.state.updateCount + 1 })
            }
            company={this.props.company}
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={this.state.modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={this.state.updateCount}
            showOnlyList
          />
        </Modal>
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="dashed"
            onClick={this.clear}
            children="Clear"
            style={{ width: '50%' }}
          />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveDeliveryInstruction}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  deliveryInstruction?: DeliveryInstructionNode;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
}

// type deliveryKeys = keyof DeliveryInstructionInput;

interface State {
  deliveryInstruction: DeliveryInstructionInput;
  error: DeliveryInstructionError;
  customerObj: CustomerNode | null;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
}

import moment from 'moment';
import {
  DirectProductionInput,
  ProductDetailsMovementType,
} from '../../../schema';

export const directProductionObject: DirectProductionInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  companyId: '',
  productDetails: [
    {
      date: '',
      productId: '',
      rate: 0,
      purchaseRate: 0,
      quantity: null,
      amount: 0,
      movementType: ProductDetailsMovementType.PRODUCTION,
      companyId: '',
      remark: null,
    },
  ],
  remarks: '',
};

export interface DirectProductionProductDetailsError {
  productId: string;
  quantity: string;
}

export const directProductionDetailsError: DirectProductionProductDetailsError =
  {
    productId: '',
    quantity: '',
  };

export interface DirectProductionError {
  date: string;
  productDetails: DirectProductionProductDetailsError[];
}

export const directProductionErrorObj: DirectProductionError = {
  date: '',
  productDetails: [directProductionDetailsError],
};

export const directProductionMutation = `
mutation addDirectProduction($input: CreateDirectProductionInput!) {
  createDirectProduction(input: $input){
    newDirectProduction{
      id
    }
  }
}
`;

export const directProductionQuery = `
query DirectProductionList($companyId: ID, $after: String, $date_Gte: Date, $date_Lte: Date) {
  allDirectProductions(first: 20, after: $after, companyId: $companyId, date_Gte: $date_Gte, date_Lte: $date_Lte) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        date
        billNo
        remarks
        productDetails {
          edges {
            node {
              id
              product {
                id
              }
              quantity
              purchaseRate
              rate
              amount
              remark
              movementType
            }
          }
        }
      }
    }
  }
}
`;

export const directProductionReportQuery = `
query DirectProductionReportQuery($after: String, $companyId: ID, $startDate: Date, $endDate: Date) {
  allProductDetails(
    after: $after,
    companyId: $companyId,
    movementType: "PRODUCTION",
    date_Gte: $startDate,
    date_Lte: $endDate,
    orderBy: "date,productName",
  ) {
    edges {
      node {
        id
        product {
          name
          oldBarcodeId
        }
        quantity
        date
      }
    }
  }
}
`;

import { Spin } from 'antd';
import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import { OptionProps } from 'antd/lib/select';
import * as React from 'react';
import { GraphqlQuery } from 'requestapijs';
import { VendorNodeConnection, VendorNodeEdge } from '../../../schema';

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

export class VendorDropdown extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      name: '',
      vendor: null,
      touched: false,
    };
  }

  value = (vendors: VendorNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const vendor = vendors.find((v) => v.node?.id === this.props.value);
    if (!vendor) return;
    return vendor.node?.name;
  };

  render() {
    const { name } = this.state;
    const { value, onSelect, companyId } = this.props;
    return (
      <GraphqlQuery
        queryString={`
          query vendorDropdown($id: ID, $name: String, $companyId: ID) {
            allVendors(id: $id, name: $name ,companyId: $companyId) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `}
        variables={{
          name,
          id: value && !name ? value : '',
          companyId,
        }}
        render={(
          response: { allVendors: VendorNodeConnection },
          error,
          loading,
        ) => {
          if (loading) return <Spin />;
          if (error) return <div>{JSON.stringify(error)}</div>;
          if (response) {
            return (
              <div style={{ width: '100%' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Product Vendor"
                  dataSource={response.allVendors.edges.map((vendor) => (
                    <Option
                      key={vendor.node?.id}
                      lable={vendor.node}
                      vendor={vendor}
                    >
                      {vendor.node?.name}
                    </Option>
                  ))}
                  value={this.value(response.allVendors.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(vendor) =>
                    this.setState({ name: vendor, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.vendor.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
          return <Spin />;
        }}
      />
    );
  }
}

interface CustomOption extends OptionProps {
  lable?: React.ReactNode;
  display?: string;
  vendor?: any;
}

interface Props extends AutoCompleteProps {
  companyId: string;
}

interface State {
  name: string;
  vendor: VendorNodeEdge | null;
  touched: boolean;
}

import * as React from 'react';
import { Button, notification } from 'antd';
import { conversionObject, handleRemunerationDetailData } from './constants';
import {
  ConversionInput,
  ProductNode,
  RemunerationDetailNodeConnection,
  ConversionNodeEdge,
} from '../../../schema';
import { ConversionForm } from './ConversionForm';
import { ConversionService } from '../../../service/ConversionService';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { handleProductDetailsData } from '../../Transfer/constants';
import { ProductDetailsNodeConnection } from '../../Sales/constants';

export class AddConversion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      conversion: this.handleConversionEditData(props),
      buttonDisabled: false,
      wastageList: this.handleWastageEditData(props),
    };
  }

  conversionService = new ConversionService();

  componentWillReceiveProps(nextProps: Props) {
    const conversion: ConversionInput =
      this.handleConversionEditData(nextProps);
    const wastageList: string[] | null = this.handleWastageEditData(nextProps);
    this.setState({ conversion, wastageList: wastageList });
  }

  handleConversionEditData = (props: Props) => {
    const conversion: ConversionInput = props.conversion
      ? {
          ...props.conversion.node,
          rawMaterials: handleProductDetailsData(
            props.conversion.node
              ?.rawMaterials as any as ProductDetailsNodeConnection,
          ),
          remunerations: handleRemunerationDetailData(
            props.conversion.node
              ?.remunerations as any as RemunerationDetailNodeConnection,
          ),
        }
      : JSON.parse(JSON.stringify(conversionObject));
    return conversion;
  };

  handleWastageEditData = (props: Props) => {
    const wastageList: string[] | null = props.conversion
      ? (
          props.conversion.node
            ?.rawMaterials as any as ProductDetailsNodeConnection
        ).edges.map((raw) =>
          (
            (Number(raw.node.wastage) * 100) /
            Number(raw.node.quantity)
          ).toString(),
        )
      : [];
    return wastageList;
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const conversion = { ...this.state.conversion };
    conversion[e.target.name] = e.target.value;
    this.setState({ conversion });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
    rowType: string,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (rowType === 'product') {
      if (nextState.conversion?.rawMaterials)
        nextState.conversion.rawMaterials[index][e.target.name] =
          e.target.value;
      if (e.target.name === 'wastage' && nextState.wastageList) {
        nextState.wastageList[index] = (
          (Number(e.target.value) * 100) /
          Number(nextState.conversion?.rawMaterials?.[index].quantity)
        ).toString();
      }
    } else if (rowType === 'remuneration') {
      if (nextState.conversion?.remunerations)
        nextState.conversion.remunerations[index][e.target.name] =
          e.target.value;
    } else {
      if (nextState.wastageList)
        nextState.wastageList[index] = e.target.value as string;
    }

    if (['product', 'wastageList'].indexOf(rowType) > -1) {
      const rawMaterials = nextState.conversion?.rawMaterials;
      if (rawMaterials) {
        rawMaterials[index].wastage = Number(
          (
            (Number(rawMaterials[index].quantity) *
              Number(nextState.wastageList?.[index])) /
            100
          ).toFixed(4),
        );
        rawMaterials[index].amount = Number(
          (
            (Number(rawMaterials[index].quantity) +
              Number(rawMaterials[index].wastage) +
              Number(rawMaterials[index].peduncleQuantity)) *
            Number(rawMaterials[index].purchaseRate)
          ).toFixed(2),
        );
      }
    } else {
      const amt =
        Number(nextState.conversion?.remunerations?.[index].quantity) *
        Number(nextState.conversion?.remunerations?.[index].rate);
      if (nextState.conversion?.remunerations)
        nextState.conversion.remunerations[index].amount =
          amt +
          (amt *
            Number(
              nextState.conversion.remunerations[index].supervisionCharge,
            )) /
            100;
    }
    this.handleAmounts(nextState);
    this.setState(nextState);
  };

  handleAmounts = (nextState: State) => {
    if (nextState.conversion) {
      nextState.conversion.rawMaterialsCost = Number(
        nextState.conversion.rawMaterials
          ?.reduce((total, raw) => total + Number(raw.amount), 0)
          .toFixed(2),
      );
      nextState.conversion.remunerationsCost = Number(
        nextState.conversion.remunerations
          ?.reduce((total, rem) => total + Number(rem.amount), 0)
          .toFixed(2),
      );
      nextState.conversion.netCost = Number(
        (
          nextState.conversion.rawMaterialsCost +
          nextState.conversion.remunerationsCost
        ).toFixed(2),
      );
      this.setState(nextState);
    }
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    if (nextState.conversion?.rawMaterials) {
      nextState.conversion.rawMaterials[index].rate = productObj.sellingRate;
      nextState.conversion.rawMaterials[index].purchaseRate =
        productObj.sellingRate; // setting sellingRate as changed to sellingRate
      nextState.conversion.rawMaterials[index].gstRate = productObj.hsn
        ? productObj.hsn.hsnWithSameCode.length > 1
          ? productObj.hsn.hsnWithSameCode.find(
              (hsn) =>
                hsn.maxValue !== undefined &&
                hsn.maxValue >= productObj.purchaseRate &&
                hsn.minValue !== undefined &&
                hsn.minValue <= productObj.purchaseRate,
            )?.gst
          : productObj.hsn.hsnWithSameCode[0].gst
        : 0;
      this.setState(nextState);
    }
  };

  updateRow = (type: string, index: number, rowType: string) => {
    const nextState: State = { ...this.state };
    if (rowType === 'product') {
      if (type === '+') {
        nextState.conversion?.rawMaterials?.push({
          date: null,
          productId: '',
          quantity: null,
          rate: null,
          purchaseRate: null,
          wastage: 0,
          peduncleQuantity: 0,
          amount: null,
          movementType: 'CONVERSION',
          companyId: null,
        });
        nextState.wastageList?.push('');
      } else {
        nextState.conversion?.rawMaterials?.splice(index, 1);
        nextState.wastageList?.splice(index, 1);
        this.handleAmounts(nextState);
      }
    }

    if (rowType === 'remuneration') {
      if (type === '+') {
        nextState.conversion?.remunerations?.push({
          date: null,
          remunerationId: '',
          quantity: null,
          rate: null,
          supervisionCharge: null,
          amount: null,
          companyId: null,
        });
      } else {
        nextState.conversion?.remunerations?.splice(index, 1);
        this.handleAmounts(nextState);
      }
    }
    this.setState(nextState);
  };

  saveConversion = () => {
    this.setState({ buttonDisabled: true });
    this.state.conversion &&
      this.conversionService?.addConversion(
        this.state.conversion,
        (response) => {
          if (!this.props.conversion) {
            this.setState({
              conversion: JSON.parse(JSON.stringify(conversionObject)),
              wastageList: [],
              buttonDisabled: false,
            });
          }
          notification.success({
            message:
              'Conversion ' + this.state.conversion?.id ? 'Updated' : 'Added',
            description:
              `Conversion was successfully ` +
              `${this.state.conversion?.id ? 'Updated' : 'Added'}`,
          });
          this.props.handleTabChange('1');
        },
        (error) => {
          notification.error({
            message:
              'Conversion' + this.state.conversion?.id
                ? 'Update Error'
                : 'Add Error',
            description: JSON.stringify(error),
          }),
            this.setState({ buttonDisabled: false });
        },
      );
  };

  clear = () => {
    this.setState({ conversion: JSON.parse(JSON.stringify(conversionObject)) });
  };

  render() {
    return (
      <div>
        <ConversionForm
          values={this.state.conversion}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateRow={this.updateRow}
          productObjChangehandler={this.productObjChangehandler}
          company={this.props.company}
          wastageList={this.state.wastageList}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveConversion}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  conversion?: ConversionNodeEdge | null;
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  conversion?: ConversionInput;
  buttonDisabled: boolean;
  wastageList: string[];
}

import moment from 'moment';
import { RequestInput } from '../../schema';

export const requestObject: RequestInput = {
  date: moment().format('YYYY-MM-DD'),
  detail: '',
  currentLocation: 'MARKETING',
  isAvailable: null,
  branchId: null,
  companyId: null,
};

export interface RequestError {
  date: string;
  detail: string;
  isAvailable: string;
}

export const requestError: RequestError = {
  date: '',
  detail: '',
  isAvailable: '',
};

export const locationObjForGroup = {
  Inventory: 'STORE',
  Marketing: 'MARKETING',
  Production: 'PRODUCTION',
};

export const requestQuery = `
query Request($after: String, $companyId: ID, $first: Int, $currentLocation: String, $branchId: ID) {
  allRequests(first: $first, after: $after, companyId: $companyId, currentLocation: $currentLocation, branchId: $branchId) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        detail
        currentLocation
        isAvailable
        branch {
          id
          name
        }
      }
    }
  }
}
`;

export const requestMutation = `
mutation Request($input: CreateRequestInput!) {
  createRequest(input: $input) {
    newRequest {
      id
    }
  }
}
`;

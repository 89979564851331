import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import { sumBy } from 'lodash';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import {
  ProductionTransferNode,
  ConversionDetailNodeConnection,
} from '../../../schema';
import { inWords } from '../../helper';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../../config';

export const ProductionTransferReceiptJharcraft: React.FunctionComponent<Props> =
  (props) => {
    const conversionDetailsEdges = (
      props.transfer.conversionDetails as any as ConversionDetailNodeConnection
    ).edges;
    const setBoxHeight = conversionDetailsEdges.length < 15;
    const totalQty = sumBy(
      conversionDetailsEdges,
      (value) => value.node?.quantity || 0,
    );
    const totalAmount = sumBy(
      conversionDetailsEdges,
      (value) => (value.node && value.node.cost * value.node.quantity) || 0,
    );
    return (
      <Document>
        <Page size="A4" style={{ paddingBottom: 50 }}>
          <View fixed>
            <View style={[style.section, style.inline, { marginTop: 12 }]}>
              <Image
                style={[{ flex: 0.3, height: 43 }]}
                source={JHARCRAFT_LOGO_URL}
              />
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textCenter,
                  { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
                ]}
              >
                Jharkhand Silk, Textile &amp; Handicraft Development Corporation
                Ltd.
              </Text>
              <Image
                style={[{ flex: 0.15, height: 43 }]}
                source={JHARKHAND_GOVT_LOGO_URL}
              />
            </View>
            <View style={style.section}>
              <Text
                style={[
                  style.darkBlackText,
                  style.textCenter,
                  { fontSize: '11pt' },
                ]}
              >
                {props.company.address}
              </Text>
            </View>
            <View style={[style.divider, { marginTop: 10 }]} />
            <View style={style.section}>
              <Text
                style={[
                  style.textCenter,
                  style.mediumBlackText,
                  { fontSize: '12pt' },
                ]}
              >
                Delivery Challan
              </Text>
            </View>
            <View style={style.divider} />
            <View style={[style.section, style.inline]}>
              <Text style={[style.inlineHead, style.mediumBlackText]}>
                GSTIN: {props.business.gstin}
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                GST STATE:{' '}
                {`${props.business.state} (${props.business.gstin?.slice(
                  0,
                  2,
                )})`}
              </Text>
            </View>
            <View style={style.divider} />
            <View style={[style.section, style.inline]}>
              <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                Invoice No:{' '}
                <Text style={[style.darkBlackText]}>
                  {props.transfer.transferInvoiceNo}
                </Text>
              </Text>
              <Text
                style={[
                  style.inlineHeadDouble,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                Date: {moment(props.transfer.date).format('DD-MM-YYYY')}
              </Text>
            </View>
            <View style={style.divider} />
            <View style={[style.section, style.inline]}>
              <View
                style={[
                  style.box,
                  { padding: 5, marginRight: 1 },
                  style.inlineHead,
                ]}
              >
                {props.transfer.fromBranch ? (
                  <View>
                    <Text style={style.mediumBlackText}>
                      Bill From: ({props.transfer.fromBranch.category})
                      {props.transfer.fromBranch.name}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      {props.transfer.fromBranch.address}
                    </Text>
                    <Text style={[style.mediumBlackText, style.marginTop]}>
                      GSTIN: {props.transfer.fromBranch.gstin}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      GST State: {props.transfer.fromBranch.state}(
                      {props.transfer.fromBranch.gstin?.slice(0, 2)})
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text style={style.mediumBlackText}>
                      Bill From: (Vendor){props.transfer.vendorFor?.name}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      {props.transfer.vendorFor?.address}
                    </Text>
                    <Text style={[style.mediumBlackText, style.marginTop]}>
                      GSTIN: {props.transfer.vendorFor?.gstin}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      GST State: {props.transfer.vendorFor?.state}(
                      {props.transfer.vendorFor?.gstin &&
                        props.transfer.vendorFor?.gstin.slice(0, 2)}
                      )
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={[
                  style.box,
                  { padding: 5, marginLeft: 1 },
                  style.inlineHead,
                ]}
              >
                {props.transfer.toBranch || props.transfer.toHeadOffice ? (
                  <View>
                    <Text style={style.mediumBlackText}>
                      Bill To: (
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.category
                        : 'Head Office'}
                      )
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.name
                        : props.company.name}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.address
                        : props.company.address}
                    </Text>
                    <Text style={[style.mediumBlackText, style.marginTop]}>
                      GSTIN:{' '}
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.gstin
                        : props.company.gstin}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      GST State:{' '}
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.state
                        : props.company.state}
                      (
                      {props.transfer.toBranch
                        ? props.transfer.toBranch.gstin?.slice(0, 2)
                        : props.company.gstin?.slice(0, 2)}
                      )
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text style={style.mediumBlackText}>
                      Bill To: (Vendor){props.transfer.vendorFor?.name}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      {props.transfer.vendorFor?.address}
                    </Text>
                    <Text style={[style.mediumBlackText, style.marginTop]}>
                      GSTIN: {props.transfer.vendorFor?.gstin}
                    </Text>
                    <Text style={style.mediumBlackText}>
                      GST State: {props.transfer.vendorFor?.state}(
                      {props.transfer.vendorFor?.gstin &&
                        props.transfer.vendorFor?.gstin.slice(0, 2)}
                      )
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={[style.section, style.box, { marginTop: 2 }]}>
              <Text style={[style.mediumBlackText, { padding: 3 }]}>
                Remarks: {props.transfer.remarks}
              </Text>
            </View>
          </View>
          <View>
            {setBoxHeight ? (
              <View
                style={[
                  style.section,
                  style.box,
                  { marginTop: 2, height: 450 },
                ]}
              >
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {conversionDetailsEdges.map((cd, i) => (
                  <View style={[style.inline]} key={'cd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {cd.node?.conversion?.finishedProduct}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {cd.node?.hsn && cd.node?.hsn.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {cd.node?.quantity}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {cd.node?.cost.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {cd.node && (cd.node.quantity * cd.node.cost).toFixed(2)}
                    </Text>
                  </View>
                ))}
              </View>
            ) : (
              <View style={[style.section, style.box, { marginTop: 2 }]}>
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {conversionDetailsEdges.map((cd, i) => (
                  <View style={[style.inline]} key={'cd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {cd.node?.conversion?.finishedProduct}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {cd.node?.hsn && cd.node?.hsn.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {cd.node?.quantity}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {cd.node?.cost.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {cd.node && (cd.node.quantity * cd.node.cost).toFixed(2)}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  height: 17,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {totalQty}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  {Math.round(totalAmount).toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[style.section, style.box, { marginTop: 0, height: 22 }]}
            >
              <Text
                style={[
                  style.darkBlackText,
                  { fontSize: '10.2pt' },
                  { padding: 4 },
                ]}
              >
                Rs. {inWords(Number(Math.round(totalAmount).toFixed(2)))}.
              </Text>
            </View>
            <View style={[style.section, style.inline, style.marginTop]}>
              <Text style={[style.inlineHead, style.darkBlackText]}>
                Received By
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.darkBlackText,
                  style.textCenter,
                ]}
              >
                Checked By
              </Text>
              <Text
                style={[style.inlineHead, style.darkBlackText, style.textRight]}
              >
                Authorized Signatory
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  transfer: ProductionTransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
}

import * as React from 'react';
import { TransferNode, TransferError } from './constants';
import { Row, Col, DatePicker, Divider, Button, Radio } from 'antd';
import moment from 'moment';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { TSInput } from '../common/TSInput';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { BranchEdges } from '../master/Branch/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { CommissionDetailsNode, ProductNode } from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { Label } from '../common/TSLabel';

export const TransferForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={6}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={
            props.isEditActive
              ? () => {}
              : (e) =>
                  props.onChange({
                    target: {
                      name: 'date',
                      value: e && moment(e).format('YYYY-MM-DD'),
                    },
                  })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      {props.transferTo === 'to_branch' && (
        <Col span={8}>
          <Label title="To">
            <Radio.Group
              name="toHeadOffice"
              value={props.values.toHeadOffice}
              onChange={(e) =>
                props.onChange({
                  target: { name: 'toHeadOffice', value: e.target.value },
                })
              }
              disabled={
                props.business.type === 'company' ||
                props.company.name.toLowerCase().includes('pearl')
              }
            >
              <Radio value={true}>Head Office</Radio>
              <Radio value={false}>Other Location</Radio>
            </Radio.Group>
          </Label>
        </Col>
      )}
      {props.values.toHeadOffice ? (
        <Col span={10}>
          <TSInput
            error={''}
            addonBefore="Head Office"
            value={props.company.name}
          />
        </Col>
      ) : (
        <Col span={10}>
          <TSAutoComplete
            error={props.error.toLocationId || ''}
            inputProps={{
              name: 'toLocationId',
              addonBefore:
                props.transferTo === 'to_branch' ? 'Location' : 'Department',
            }}
            dataSource={props.branch.filter((br) => {
              if (props.business.type === 'branch')
                return br.node.id !== props.business.id;
              return br;
            })}
            key="id"
            name="toLocationId"
            onValueSelect={(e) =>
              props.values.id
                ? {}
                : props.onChange({
                    target: { name: 'toLocationId', value: e.target.value },
                  })
            }
            displayKey="name"
            textValue={getValue(props.branch, props.values.toLocationId)}
            allowClear={!props.values.id}
          />
        </Col>
      )}
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product</Col>
        <Col span={4}>Quantity</Col>
        <Col span={4}>
          {props.company.name.toLowerCase().includes('khadi')
            ? 'Sale Rate'
            : 'Pur. Rate'}
        </Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={8}>
              <div>
                <ProductDropDown
                  onSelect={(e, f) => {
                    props.isEditActive
                      ? () => {}
                      : props.productObjChangehandler(
                          (f as any).props.label,
                          index,
                        );
                    props.isEditActive
                      ? () => {}
                      : props.productChangeHandler(
                          { target: { name: 'productId', value: String(e) } },
                          index,
                        );
                  }}
                  allowClear
                  extrafields={[
                    'section',
                    'purchaseRate',
                    'sellingRate',
                    'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                  ]}
                  company={props.company}
                  value={product.productId || ''}
                />
                <span style={{ color: 'red' }}>
                  {props.error.productDetails[index].productId}
                </span>
              </div>
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.productDetails[index].quantity}
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onBlur={props.checkForDuplicateProduct}
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={4}>
              {props.company.name.toLowerCase().includes('khadi') ? (
                <TSInput
                  error={props.error.productDetails[index].purchaseRate}
                  type="number"
                  value={product.rate || ''}
                  name="rate"
                  placeholder="Sale Rate"
                  onChange={
                    props.isRateEditable || !props.isEditActive
                      ? (e) => props.productChangeHandler(e, index)
                      : () => {}
                  }
                  required
                  disabled
                />
              ) : (
                <TSInput
                  error={props.error.productDetails[index].purchaseRate}
                  type="number"
                  value={product.purchaseRate || ''}
                  name="purchaseRate"
                  placeholder="Pur. Rate"
                  onChange={
                    props.isRateEditable || !props.isEditActive
                      ? (e) => props.productChangeHandler(e, index)
                      : () => {}
                  }
                  required
                  disabled
                />
              )}
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.productDetails[index].amount}
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('-', index)
                }
                disabled={
                  props.values.productDetails.length === 1 || props.isEditActive
                }
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('+', index)
                }
                disabled={
                  (product.productId &&
                  product.quantity &&
                  (props.company.name.toLowerCase().includes('pearl')
                    ? true
                    : product.purchaseRate && product.amount)
                    ? false
                    : true) || props.isEditActive
                }
              />
            </Col>
            {props.isEditActive && (
              <Col span={24}>
                <Row>
                  <Col span={4}>
                    {product.status === 'REJECTED' ? (
                      <div style={{ color: '#f00' }}>{product.status}</div>
                    ) : (
                      <div style={{ color: '#22f' }}>{product.status}</div>
                    )}
                  </Col>
                  <Col span={20}>
                    <TSInput
                      error={''}
                      type="text"
                      value={product.remark || 'No Remark!'}
                      name="remark"
                      addonBefore="Remark"
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      ))}
      <Divider />
      {props.company.name.toLowerCase().includes('khadi') && (
        <>
          <h4>Transfer Commission</h4>
          <Row>
            <Col span={1}>Sl. No.</Col>
            <Col span={6}>Category</Col>
            <Col span={6} offset={1}>
              Description
            </Col>
            <Col span={4} offset={1}>
              Description %
            </Col>
            <Col span={4} offset={1}>
              Description Value
            </Col>
            <Divider />
          </Row>
          {props.values.commissionDetails &&
            props.values.commissionDetails.map((commission, index) => (
              <div key={'products' + index}>
                <Row>
                  <Col span={1}>{index + 1}.</Col>
                  <Col span={6}>
                    <TSInput
                      error=""
                      type="text"
                      value={commission.category || ''}
                      name="category"
                      disabled={true}
                    />
                  </Col>
                  <Col span={6} offset={1}>
                    <TSInput
                      error=""
                      type="text"
                      value={commission.description || ''}
                      name="description"
                      disabled={true}
                    />
                  </Col>
                  <Col span={4} offset={1}>
                    <TSInput
                      error=""
                      type="number"
                      value={commission.descriptionPercent || 0}
                      name="descriptionPercent"
                      onChange={
                        props.isEditActive
                          ? () => {}
                          : (e) =>
                              props.transferCommissionChangeHandler(
                                {
                                  target: {
                                    name: e.target.name,
                                    value: e.target.value as unknown as number,
                                  },
                                },
                                index,
                              )
                      }
                      disabled={commission.description != 'Special Addition(%)'}
                    />
                  </Col>
                  <Col span={4} offset={1}>
                    <TSInput
                      error=""
                      type="number"
                      value={commission.descriptionValue || 0}
                      name="descriptionValue"
                      onChange={
                        props.isEditActive
                          ? () => {}
                          : (e) =>
                              props.transferCommissionChangeHandler(
                                {
                                  target: {
                                    name: e.target.name,
                                    value: e.target.value as unknown as number,
                                  },
                                },
                                index,
                              )
                      }
                      disabled={
                        commission.description != 'Packing/Other Charge' &&
                        commission.description != 'Courier and Postal Charges'
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}
          <Divider />
        </>
      )}
      <Col span={12}>
        <TSInput
          error=""
          type="text"
          value={props.totalQuantity}
          name="quantity"
          addonBefore="Total Quantity"
          placeholder="Total Quantity"
          readOnly
        />
      </Col>
      <Col span={12}>
        <TSInput
          error=""
          type="text"
          value={props.totalAmount.toFixed(2)}
          name="amount"
          addonBefore="Total Amount"
          placeholder="Total Amount"
          readOnly
        />
      </Col>
      <Col>
        <TSInput
          error={''}
          type="text"
          value={props.values.remarks || ''}
          name="remarks"
          addonBefore="Remarks"
          onChange={props.onChange}
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  error: TransferError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangehandler: (productObj: ProductNode, index: number) => void;
  isEditActive: boolean;
  isRateEditable: boolean;
  transferTo: 'to_department' | 'to_branch';
  totalQuantity: number;
  totalAmount: number;
  checkForDuplicateProduct: () => void;
  transferCommissionChangeHandler: (
    e: { target: { name: string; value: number } },
    index: number,
  ) => void;
}

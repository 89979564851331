import React from 'react';
import { SalesPersonError, SalesPersonNodeEdge } from './constants';
import { TSInput } from '../../common/TSInput';

export const SalesPersonForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
    </div>
  );
};

interface Props {
  values: SalesPersonNodeEdge;
  error: SalesPersonError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: boolean } },
  ) => void;
}

import * as React from 'react';
import { Row, Col, DatePicker, Divider, Button, Radio, Checkbox } from 'antd';
import moment from 'moment';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { TSInput } from '../common/TSInput';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { BranchEdges } from '../master/Branch/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { Label } from '../common/TSLabel';
import { TransferNode } from '../Transfer/constants';
import { ReceivingError } from './constants';
import { CommissionDetailsNode } from '../../schema';

export const ReceivingForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={4}>
        <Label title="Transfer Date">
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={4}>
        <Label title="Receiving Date">
          <DatePicker
            value={
              props.values.receivingDate
                ? moment(props.values.receivingDate)
                : moment(moment().format('YYYY-MM-DD'))
            }
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={7}>
        <Label title="From">
          <Radio.Group
            name="fromHeadOffice"
            value={props.values.fromHeadOffice}
            disabled={
              props.business.type === 'company' ||
              props.company.name.toLowerCase().includes('pearl')
            }
          >
            <Radio value={true}>Head Office</Radio>
            <Radio value={false}>Other Location</Radio>
          </Radio.Group>
        </Label>
      </Col>
      {props.values.fromHeadOffice ? (
        <Col span={9}>
          <TSInput
            error={''}
            addonBefore="Head Office"
            value={props.company.name}
          />
        </Col>
      ) : (
        <Col span={9}>
          <TSAutoComplete
            error={''}
            inputProps={{ name: 'fromLocationId', addonBefore: 'Location' }}
            dataSource={props.branch}
            key="id"
            name="fromLocationId"
            onValueSelect={() => {}}
            displayKey="name"
            textValue={getValue(props.branch, props.values.fromLocationId)}
            allowClear
          />
        </Col>
      )}
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={7}>Product</Col>
        <Col span={2}>Quantity</Col>
        <Col span={3}>
          {props.company.name.toLowerCase().includes('khadi')
            ? 'Sale Rate'
            : 'Cost Price'}
        </Col>
        <Col span={3}>Amount</Col>
        <Col span={1}>Accept</Col>
        <Col span={1}>Reject</Col>
        <Col span={1}>Status</Col>
        <Col span={5}>Remarks</Col>
        <Divider />
      </Row>
      <Row>
        <Col span={15}>{}</Col>
        <Col span={2}>
          <Label title="Accept All">
            <Checkbox
              checked={props.acceptAll}
              onChange={(e) =>
                props.onChangeAcceptAll({
                  target: { name: 'acceptAll', value: e.target.checked },
                })
              }
            />
          </Label>
        </Col>
      </Row>
      {props.values.productDetails.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={7}>
              <div>
                <ProductDropDown
                  value={product.productId || ''}
                  allowClear
                  company={props.company}
                />
                <span style={{ color: 'red' }}>
                  {props.error.productDetails[index].productId}
                </span>
              </div>
            </Col>
            <Col span={2}>
              <TSInput
                error={''}
                type="number"
                value={product.quantity || 0}
                name="quantity"
                placeholder="Quantity"
                required
              />
            </Col>
            <Col span={3}>
              {props.company.name.toLowerCase().includes('khadi') ? (
                <TSInput
                  error={''}
                  type="number"
                  value={product.rate || 0}
                  name="rate"
                  placeholder="Sale Rate"
                  required
                />
              ) : (
                <TSInput
                  error={''}
                  type="number"
                  value={product.purchaseRate || 0}
                  name="purchaseRate"
                  placeholder="Cost Price"
                  required
                />
              )}
            </Col>
            <Col span={3}>
              <TSInput
                error={''}
                type="number"
                value={product.amount || 0}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={1}>
              <Button
                type="default"
                shape="circle"
                icon="check"
                style={{ marginLeft: '5px', marginTop: '5px' }}
                onClick={(e) =>
                  props.productChangeHandler(
                    { target: { name: 'status', value: 'ACCEPTED' } },
                    index,
                  )
                }
              />
            </Col>
            <Col span={1}>
              <Button
                type="danger"
                shape="circle"
                icon="close"
                style={{ marginLeft: '5px', marginTop: '5px' }}
                onClick={(e) =>
                  props.productChangeHandler(
                    { target: { name: 'status', value: 'REJECTED' } },
                    index,
                  )
                }
              />
            </Col>
            <Col span={1} style={{ marginTop: '5px' }}>
              <span
                style={
                  product.status === 'ACCEPTED'
                    ? {
                        color: 'green',
                        fontSize: '23px',
                      }
                    : product.status === 'REJECTED'
                    ? {
                        color: 'red',
                        fontSize: '23px',
                      }
                    : { color: 'blue', fontSize: '23px' }
                }
              >
                {product.status?.[0]}
              </span>
            </Col>
            <Col span={5}>
              <TSInput
                error={props.error.productDetails[index].remark}
                type="text"
                value={product.remark}
                name="remark"
                onChange={(e) => props.productChangeHandler(e, index)}
                placeholder="Remarks"
                disabled={product.status !== 'REJECTED'}
                required
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />
      {props.company.name.toLowerCase().includes('khadi') && (
        <>
          <h4>Transfer Commission</h4>
          <Row>
            <Col span={1}>Sl. No.</Col>
            <Col span={6}>Category</Col>
            <Col span={6} offset={1}>
              Description
            </Col>
            <Col span={4} offset={1}>
              Description %
            </Col>
            <Col span={4} offset={1}>
              Description Value
            </Col>
            <Divider />
          </Row>
          {props.values.commissionDetails &&
            props.values.commissionDetails.map((commission, index) => (
              <div key={'products' + index}>
                <Row>
                  <Col span={1}>{index + 1}.</Col>
                  <Col span={6}>
                    <TSInput
                      error=""
                      type="text"
                      value={commission.category || ''}
                      name="category"
                    />
                  </Col>
                  <Col span={6} offset={1}>
                    <TSInput
                      error=""
                      type="text"
                      value={commission.description || ''}
                      name="description"
                    />
                  </Col>
                  <Col span={4} offset={1}>
                    <TSInput
                      error=""
                      type="number"
                      value={commission.descriptionPercent || 0}
                      name="descriptionPercent"
                    />
                  </Col>
                  <Col span={4} offset={1}>
                    <TSInput
                      error=""
                      type="number"
                      value={commission.descriptionValue || 0}
                      name="descriptionValue"
                    />
                  </Col>
                </Row>
              </div>
            ))}
          <Divider />
        </>
      )}
      <Col>
        <TSInput
          error={''}
          type="text"
          value={props.values.remarks || ''}
          name="remarks"
          addonBefore="Remarks"
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  error: ReceivingError;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  acceptAll: boolean;
  onChangeAcceptAll: (e: { target: { name: string; value: boolean } }) => void;
}

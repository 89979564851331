import * as React from 'react';
import { DatePicker, Divider, Button, Spin, Col } from 'antd';
import moment from 'moment';
import { ColumnProps, Table } from '../common/Table';
import { monthlyGSTReportQuery } from './constants';
import { downloadToFile } from '../helper';
import { GraphqlQuery } from 'requestapijs';
import { MonthlyGSTSalesReport as MonthlyGSTSalesReportSchema } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';

export class MonthlyGSTSalesReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      month: moment().format('YYYY-MM'),
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Branch',
      key: 'branch',
      render: (value: MonthlyGSTSalesReportSchema) => value.branch,
      sortingValue: (value: MonthlyGSTSalesReportSchema) => value.branch || '',
    },
    {
      title: 'Gross Amount',
      key: 'grossAmount',
      render: (value: MonthlyGSTSalesReportSchema) =>
        !this.props.company.name.toLowerCase().includes('pearl')
          ? value.totalAmount?.toFixed(2)
          : (
              (value.grossAmount || 0) -
              (value.cgst || 0) -
              (value.sgst || 0)
            ).toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) =>
        !this.props.company.name.toLowerCase().includes('pearl')
          ? value.totalAmount || ''
          : (value.grossAmount || 0) - (value.cgst || 0) - (value.sgst || 0),
    },
    {
      title: 'CGST',
      key: 'cgst',
      render: (value: MonthlyGSTSalesReportSchema) => value.cgst?.toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) => value.cgst || '',
    },
    {
      title: 'SGST',
      key: 'sgst',
      render: (value: MonthlyGSTSalesReportSchema) => value.sgst?.toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) => value.sgst || '',
    },
    {
      title: 'Net Amount',
      key: 'netAmount',
      render: (value: MonthlyGSTSalesReportSchema) =>
        ((value.netAmount || 0) + (value.exchangeAmount || 0)).toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) =>
        (value.netAmount || 0) + (value.exchangeAmount || 0),
    },
    {
      title: 'Exchange Amount',
      key: 'exchangeAmount',
      render: (value: MonthlyGSTSalesReportSchema) =>
        value.exchangeAmount?.toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) =>
        value.exchangeAmount || '',
    },
    {
      title: 'Bill Amount',
      key: 'billAmount',
      render: (value: MonthlyGSTSalesReportSchema) =>
        value.netAmount?.toFixed(2),
      sortingValue: (value: MonthlyGSTSalesReportSchema) =>
        value.netAmount || '',
    },
  ];

  downloadCsv = (monthlyGstSalesReport: MonthlyGSTSalesReportSchema[]) => {
    const data = monthlyGstSalesReport
      .map((dl) =>
        this.columns()
          .map((col) => {
            return col.render?.(dl);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `monthly-gst-sales-report-${
        this.props.company.name
      }-${new Date().toISOString()}`,
      'MONTHLY GST SALES REPORT',
      this.props.company.name,
      '',
      `01-${moment(this.state.month).format('MM-YYYY')}`,
      `${moment(this.state.month, 'YYYY-MM')
        .endOf('month')
        .format('DD')}-${moment(this.state.month).format('MM-YYYY')}`,
      '',
    );
  };

  render() {
    if (!this.state.month) {
      return <Spin />;
    }
    const companyId = this.props.company.id;
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    return (
      <div>
        <Col span={5}>
          <DatePicker.MonthPicker
            format="MMM-YYYY"
            placeholder="Month"
            value={
              this.state.month
                ? moment(this.state.month)
                : moment(moment().format('YYYY-MM'))
            }
            onChange={(month) =>
              this.onChange({
                target: {
                  name: 'month',
                  value: month && month.format('YYYY-MM'),
                },
              })
            }
          />
        </Col>
        <Divider />
        <GraphqlQuery
          queryString={monthlyGSTReportQuery}
          variables={{ companyId, startDate, endDate }}
          render={(
            response: { monthlyGstSalesReport: MonthlyGSTSalesReportSchema[] },
            error,
            loading,
          ) => {
            if (loading) {
              return <Spin />;
            }
            if (response && companyId) {
              return [
                <Table
                  dataSource={response.monthlyGstSalesReport}
                  columns={this.columns()}
                  key="table"
                  rowKey="id"
                  pagination={{ pageSize: 11 }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(response.monthlyGstSalesReport)
                  }
                  key="button"
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  month: string;
}

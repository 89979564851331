import * as React from 'react';
import { DatePicker, Divider, Button, Spin, Col } from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { ItemWiseTotalStock } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { CompanyService } from '../../service/CompanyService';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ColumnProps, Table } from '../common/Table';
import { ITEM_WISE_TOTAL_STOCK_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';

export class ItemWiseTotalStockReport extends React.Component<Props, State> {
  companyService = new CompanyService();
  constructor(props: Props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      productId: '',
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = (): ColumnProps<ItemWiseTotalStock>[] => [
    {
      title: 'Branch',
      key: 'branchName',
      render: (value: ItemWiseTotalStock) => value.branchName,
      sortingValue: (value: ItemWiseTotalStock) => value.branchName || '',
    },
    {
      title: 'Current Stock',
      key: 'quantity',
      render: (value: ItemWiseTotalStock) => value.quantity,
      sortingValue: (value: ItemWiseTotalStock) => value.quantity || '',
    },
    {
      title: 'MRP Value',
      key: 'MRP',
      render: (value: ItemWiseTotalStock) => value.totalStocksValue,
      sortingValue: (value: ItemWiseTotalStock) => value.totalStocksValue || '',
    },
    {
      title: 'Cost Value',
      key: 'cost',
      render: (value: ItemWiseTotalStock) => value.totalStocksPurchaseValue,
      sortingValue: (value: ItemWiseTotalStock) =>
        value.totalStocksPurchaseValue || '',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_TOTAL_STOCK_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) => {
        downloadToFile(
          v,
          `item-wise-total-stock-${new Date()}`,
          'ITEM WISE TOTAL STOCK REPORT',
          this.props.company.name,
          '',
          this.state.date,
          '',
          '',
        );
      });
  };

  render() {
    if (!this.state.date) {
      return <Spin />;
    }
    const companyId = this.props.company.id;
    const { company } = this.props;
    const { date, productId } = this.state;
    return (
      <div>
        {this.companyService?.selectedBussiness$.value?.type !== 'branch' && (
          <Col span={8}>
            <ProductDropDown
              onSelect={(e, f) =>
                this.onChange({
                  target: { name: 'productId', value: String(e) },
                })
              }
              allowClear
              company={company}
              // value={productId}
            />
          </Col>
        )}
        <Col span={5}>
          <DatePicker
            format="DD-MM-YYYY"
            placeholder="Date"
            value={
              this.state.date
                ? moment(this.state.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(choosenDate) =>
              this.onChange({
                target: {
                  name: 'date',
                  value:
                    choosenDate && moment(choosenDate).format('YYYY-MM-DD'),
                },
              })
            }
          />
        </Col>
        <Divider />
        <GraphqlQuery
          queryString={itemWiseTotalStockQuery}
          variables={{ companyId, productId, date }}
          render={(
            response: { itemWiseTotalStock: ItemWiseTotalStock[] },
            error,
            loading,
          ) => {
            if (loading) {
              return <Spin />;
            }
            if (response && companyId) {
              return [
                <Table
                  dataSource={response.itemWiseTotalStock}
                  columns={this.columns()}
                  key="table"
                  rowKey="id"
                  pagination={{ pageSize: 11 }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv({ companyId, productId, date })}
        />
        ,
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  date: string;
  productId: string;
}

export const itemWiseTotalStockQuery = `
query itemWiseTotalStockQuery(
  $companyId: ID!,
  $productId: ID!,
  $date: Date!
){
  itemWiseTotalStock(
    companyId: $companyId,
    productId: $productId,
    date: $date
  ){
    branchName
    quantity
    totalStocksPurchaseValue
    totalStocksValue
    mrp
  }
}
`;

import moment from 'moment';
import {
  ConversionDetailNodeConnection,
  ConversionDetailInput,
  ProductionTransferNode,
  ProductionTransferInput,
} from '../../../schema';

export interface ProductionTransferError {
  date: string;
  fromBranchId?: string;
  toBranchId?: string;
  conversionDetails: {
    conversionId: string;
    cost: string;
    quantity: string;
    amount: string;
    hsnId: string;
  }[];
  status: string;
}

export const productionTransferObj: ProductionTransferInput = {
  date: moment().format('YYYY-MM-DD'),
  toHeadOffice: false,
  fromBranchId: null,
  toBranchId: null,
  conversionDetails: [
    {
      date: null,
      conversionId: '',
      cost: 0,
      quantity: 0,
      amount: 0,
      hsnId: null,
      gstRate: null,
      companyId: null,
      sourceBranchId: null,
      destinationBranchId: null,
      conversionType: 'TRANSFER',
      status: 'PENDING',
      remark: null,
      vendorForId: null,
    },
  ],
  status: 'PENDING',
  remarks: '',
  receivingDate: null,
  transferInvoiceNo: null,
  fromVendor: false,
  toVendor: false,
  jobOrderId: null,
  vendorForId: null,
  companyId: null,
};

export const productionTransferError: ProductionTransferError = {
  date: '',
  fromBranchId: '',
  toBranchId: '',
  conversionDetails: [
    {
      conversionId: '',
      cost: '',
      quantity: '',
      amount: '',
      hsnId: '',
    },
  ],
  status: '',
};

export const productionTransferQuery = `
  query ProductionTransferList($fromBranchId: ID, $companyId: ID, $date_Lte: Date!) {
    allProductionTransfers(
      fromBranchId: $fromBranchId,
      companyId: $companyId,
      date_Lte: $date_Lte) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          date
          toHeadOffice
          fromBranch {
            id
            name
            address
            gstin
            state
            category
          }
          toBranch {
            id
            name
            address
            gstin
            state
            category
          }
          transferInvoiceNo
          fromVendor
          toVendor
          vendorFor {
            id
            name
            address
            gstin
            state
          }
          hsnGstWiseDetails {
            hsnCode
            gst
            quantity
            amount
          }
          remarks
          status
          receivingDate
          conversionDetails {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                conversion {
                  id
                  finishedProduct
                }
                quantity
                cost
                amount
                hsn {
                  id
                  hsnCode
                }
                gstRate
                conversionType
                status
                sourceBranch {
                  id
                }
                destinationBranch {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const interLocationProductionTransfer = `
query InterLocationProductionTransfer($after: String, $companyId: ID, $startDate: Date, $endDate: Date, $product: String) {
  allConversionDetails(
    first: 20,
    after: $after,
    companyId: $companyId,
    status: "ACCEPTED",
    conversionType: "TRANSFER",
    date_Gte: $startDate,
    date_Lte: $endDate,
    conversion_FinishedProduct_Icontains: $product,
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        conversion {
          finishedProduct
        }
        sourceBranch {
          name
          category
        }
        destinationBranch {
          name
          category
        }
        vendorFor {
          name
        }
      }
    }
  }
}
`;

export interface AddProductionTransferVariable {
  input: {
    productionTransfer: ProductionTransferNode;
  };
}

export interface ProductionTransferQueryResponse {
  data: {
    allProductionTransfers: {
      edges: ProductionTransferEdges[];
    };
  };
}

export interface ProductionTransferEdges {
  cusror: string;
  node: ProductionTransferNode;
}

export const productionTransferMutation = `
mutation addProductionTransfer($input: CreateProductionTransferInput!) {
  createProductionTransfer(input: $input){
    newProductionTransfer{
      id
      date
      fromBranch {
        id
        name
      }
      toBranch {
        id
        name
      }
    }
  }
}
`;

export const handleConversionDetailData = (
  conversionDetail: ConversionDetailNodeConnection,
): ConversionDetailInput[] => {
  if (!conversionDetail.hasOwnProperty('edges')) return [];
  return conversionDetail.edges.map((cd) => ({
    ...cd.node,
    conversionId: cd.node?.conversion?.id,
    hsnId: cd.node?.hsn?.id,
    companyId: cd.node?.company?.id,
    sourceBranchId: cd.node?.sourceBranch?.id,
    destinationBranchId: cd.node?.destinationBranch?.id,
    vendorForId: cd.node?.vendorFor?.id,
  }));
};

import * as React from 'react';
import { ProductionTransferEdges } from './constants';
import { Tabs, Table, Icon, Spin } from 'antd';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import { AddProductionTransfer } from './AddProductionTransfer';
import { ProductionTransferReceiptJharcraft } from './ProductionTransferReceiptJharcraft';
import { ProductionTransferService } from '../../../service/ProductionTransferService';
import { CompanyService } from '../../../service/CompanyService';
import { checkAddPermission } from '../../helper';
import { Bussiness, CompanyNodeEdge } from '../../master/Company/constants';
import { ProductionTransferTaxInvoiceJharcraft } from './ProductionTransferTaxInvoiceJharcraft';
import { FinishedProductTransferReport } from './FinishedProductTransferReport';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class ProductionTransfer extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      productionTransfers: [],
      activeKey: '1',
      selectedProductionTransfer: null,
      business: null,
      company: null,
      isEditActive: false,
      user: null,
    };
  }

  productionTransferService = new ProductionTransferService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.productionTransferService?.productionTransfer$.subscribe(
      (productionTransfers) => this.setState({ productionTransfers }),
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }, () => {
        this.setState({
          activeKey: this.state.business?.type === 'company' ? '5' : '1',
        });
      }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({
        activeKey,
        isEditActive: false,
        selectedProductionTransfer: null,
      });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  transferColumn = () => {
    const transferColumns = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
        render: (text, obj) => {
          return (
            <a
              onClick={() => {
                this.setState({
                  selectedProductionTransfer: obj,
                  activeKey: '2',
                  isEditActive: true,
                });
              }}
            >
              {text}
            </a>
          );
        },
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'To',
        dataIndex: 'node.toBranch.name',
        key: 'toBranch',
        render: (value) => value || 'Head Office',
      },
      {
        title: 'Receipt',
        dataIndex: 'receipt',
        key: 'receipt',
        render: (text, obj: ProductionTransferEdges) => (
          <a
            onClick={() => {
              this.setState({
                selectedProductionTransfer: obj,
                activeKey:
                  (obj.node.fromBranch || obj.node.vendorFor)?.gstin?.slice(
                    0,
                    2,
                  ) ===
                  (obj.node.toHeadOffice
                    ? this.state.company?.gstin?.slice(0, 2)
                    : (obj.node.toBranch || obj.node.vendorFor)?.gstin?.slice(
                        0,
                        2,
                      ))
                    ? '3'
                    : '4',
              });
            }}
          >
            <Icon type="download" />
          </a>
        ),
      },
      {
        title: 'Remarks',
        dataIndex: 'node.remarks',
        key: 'remarks',
      },
    ];
    return transferColumns;
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        {this.state.business.type === 'branch' && [
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                List Finished Product Transfer
              </span>
            }
            key="1"
            closable={false}
          >
            <Table
              dataSource={this.state.productionTransfers}
              columns={this.transferColumn()}
              rowKey={(record) => record.node.id || ''}
            />
          </Tabs.TabPane>,
          this.state.user && checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Finished Product Transfer
                </span>
              }
              key="2"
            >
              <AddProductionTransfer
                productionTransfer={this.state.selectedProductionTransfer?.node}
                handleTabChange={this.handleTabChange}
                isEditActive={this.state.isEditActive}
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          ),
          this.state.selectedProductionTransfer &&
            this.state.activeKey === '3' && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="file" />
                    Receipt
                  </span>
                }
                key="3"
              >
                {(this.state.company?.name === 'Jharcraft' ||
                  this.state.company?.name.toLowerCase().includes('khadi')) && (
                  <PDFViewer style={{ width: '100%', height: '1000px' }}>
                    <ProductionTransferReceiptJharcraft
                      company={this.state.company}
                      business={this.state.business}
                      transfer={this.state.selectedProductionTransfer.node}
                    />
                  </PDFViewer>
                )}
              </Tabs.TabPane>
            ),
          this.state.selectedProductionTransfer &&
            this.state.activeKey === '4' && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="file" />
                    Receipt
                  </span>
                }
                key="4"
              >
                {(this.state.company?.name === 'Jharcraft' ||
                  this.state.company?.name.toLowerCase().includes('khadi')) && (
                  <PDFViewer style={{ width: '100%', height: '1000px' }}>
                    <ProductionTransferTaxInvoiceJharcraft
                      company={this.state.company}
                      business={this.state.business}
                      transfer={this.state.selectedProductionTransfer.node}
                    />
                  </PDFViewer>
                )}
              </Tabs.TabPane>
            ),
        ]}
        {this.state.business.type === 'company' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Finished Product Transfer Report
              </span>
            }
            key="5"
          >
            <FinishedProductTransferReport
              company={this.state.company}
              activeKey={this.state.activeKey}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  productionTransfers: ProductionTransferEdges[];
  activeKey: string;
  selectedProductionTransfer: ProductionTransferEdges | null;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
}

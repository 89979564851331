import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Company } from '../master/Company/Company';
import { Branch } from '../master/Branch/Branch';
import { Unit } from '../master/Unit/Unit';
import { ProductCategories } from '../master/ProductCategories/ProductCategories';
import { ProductSubCategories } from '../master/ProductSubCategories/ProductSubCategories';
import { Product } from '../master/Product/Product';
import { Sales } from '../Sales/Sales';
import { Purchase } from '../Purchase/Purchase';
import { Transfer } from '../Transfer/Transfer';
import { Damage } from '../Damage/Damage';
import { TransferReturn } from '../TransferReturn/TransferReturn';
import { PurchaseReturn } from '../PurchaseReturn/PurchaseReturn';
import { Employee } from '../master/Employee/Employee';
import { ViewProduct } from '../master/Product/ViewProduct';
import { Approval } from '../Approval/Approval';
import { Valueadition } from '../Valueadition/Valueadition';
import { OpeningStock } from '../OpeningStock/OpeningStock';
import { Vendor } from '../master/vendor/Vendor';
import { StockReport } from '../StockReport/StockReport';
import { Hsn } from '../master/Hsn/Hsn';
import { PurchaseOrder } from '../PurchaseOrder/PurchaseOrder';
import { Challan } from '../Challan/Challan';
import { Margin } from '../Margin/Margin';
import { Remuneration } from '../master/Remuneration/Remuneration';
import { Conversion } from '../Production/Conversion/Conversion';
import { Production } from '../Production/Production/Production';
import { Gift } from '../Gift/Gift';
import { ProductionTransfer } from '../Production/ProductionTransfer/ProductionTransfer';
import { PendingReceiving } from '../Receiving/PendingReceiving';
import { JobOrder } from '../JobOrder/JobOrder';
import { Customer } from '../master/Customer/Customer';
import { PendingProductionReceiving } from '../Production/ProductionReceiving/PendingProductionReceiving';
import { CodeConversion } from '../CodeConversion/CodeConversion';
import { State } from '../master/State/State';
import { Subarea } from '../master/Subarea/Subarea';
import { District } from '../master/District/District';
import { Area } from '../master/Area/Area';
import { Request } from '../Request/Request';
import { Contractor } from '../master/Contractor/Contractor';
import { ProductConversion } from '../master/ProductConversion/ProductConversion';
import { Deposit } from '../Deposit/Deposit';
import { DeliveryInstruction } from '../DeliveryInstruction/DeliveryInstruction';
import { SaleInvoice } from '../SaleInvoice/SaleInvoice';
import { DirectProduction } from '../Production/DirectProduction/DirectProduction';
import { Issue } from '../Production/Issue/Issue';
import { Quotation } from '../Quotation/Quotation';
import { MdDashboard } from './MdDashboard';
import { MasterDataReport } from '../Sales/MasterDataReport';
import { CompanyService } from '../../service/CompanyService';
import { JharcraftNewStockSummary } from '../StockReport/JharcraftNewStockSummary';
import { PurchaseOrderReport } from '../PurchaseOrder/PurchaseOrderReport';
import { DiscountCategories } from '../master/DiscountCategory/DiscountCategories';
import { Memo } from '../Memo/Memo';
import { Discount } from '../master/Discount/Discount';
import { SalesPerson } from '../master/SalesPerson/SalesPerson';

export default class DashboardRouter extends React.Component<Props, S> {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  // For master data url
  companyService = new CompanyService();

  componentDidMount() {}

  AccessDenied = (props) => <h1>Access Denied</h1>;

  abc = (props) => <h1>404</h1>;
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard/master" component={MdDashboard} />
        <Route path="/dashboard/master/company" component={Company} />
        <Route path="/dashboard/master/branch/:branchType" component={Branch} />
        <Route path="/dashboard/master/unit" component={Unit} />
        <Route
          path="/dashboard/master/product_categories"
          component={ProductCategories}
        />
        <Route
          path="/dashboard/master/product_sub_categories"
          component={ProductSubCategories}
        />
        <Route path="/dashboard/master/hsn" component={Hsn} />
        <Route path="/dashboard/master/vendor" component={Vendor} />
        <Route path="/dashboard/master/user" component={Employee} />
        <Route path="/dashboard/master/remuneration" component={Remuneration} />
        <Route exact path="/dashboard/master/product" component={Product} />
        <Route
          exact
          path="/dashboard/master/product/:id"
          render={(props) => <ViewProduct route={props} />}
        />
        <Route path="/dashboard/master/customer" component={Customer} />
        <Route
          path="/dashboard/master/discount_categories"
          component={DiscountCategories}
        />
        <Route path="/dashboard/sales" component={Sales} />
        <Route path="/dashboard/value_addition" component={Valueadition} />
        <Route path="/dashboard/approval" component={Approval} />
        <Route path="/dashboard/gift" component={Gift} />
        <Route path="/dashboard/purchase_order" component={PurchaseOrder} />
        <Route path="/dashboard/challan" component={Challan} />
        <Route path="/dashboard/margin" component={Margin} />
        <Route path="/dashboard/purchase" component={Purchase} />
        <Route path="/dashboard/purchase_return" component={PurchaseReturn} />
        <Route path="/dashboard/transfer/:transferTo" component={Transfer} />
        <Route path="/dashboard/receiving" component={PendingReceiving} />
        <Route path="/dashboard/transfer_return" component={TransferReturn} />
        <Route path="/dashboard/deposit" component={Deposit} />
        <Route path="/dashboard/damage" component={Damage} />
        <Route path="/dashboard/code_conversion" component={CodeConversion} />
        <Route path="/dashboard/opening_stock" component={OpeningStock} />
        <Route path="/dashboard/stock_report" component={StockReport} />
        <Route path="/dashboard/production/conversion" component={Conversion} />
        <Route path="/dashboard/production/production" component={Production} />
        <Route
          path="/dashboard/production_transfer"
          component={ProductionTransfer}
        />
        <Route
          path="/dashboard/production_receiving"
          component={PendingProductionReceiving}
        />
        <Route path="/dashboard/production/issue" component={Issue} />
        <Route
          path="/dashboard/production/direct-production"
          component={DirectProduction}
        />
        <Route path="/dashboard/job_order" component={JobOrder} />
        <Route path="/dashboard/request" component={Request} />
        <Route path="/dashboard/master/state" component={State} />
        <Route path="/dashboard/master/district" component={District} />
        <Route path="/dashboard/master/area" component={Area} />
        <Route path="/dashboard/master/subarea" component={Subarea} />
        <Route path="/dashboard/master/contractor" component={Contractor} />
        <Route
          path="/dashboard/master/product_conversion"
          component={ProductConversion}
        />
        <Route
          path="/dashboard/delivery_instruction"
          component={DeliveryInstruction}
        />
        <Route path="/dashboard/sale_invoice" component={SaleInvoice} />
        <Route path="/dashboard/quotation" component={Quotation} />
        <Route
          path="/dashboard/purchase_order_report"
          component={PurchaseOrderReport}
        />
        <Route
          path="/dashboard/master_data_report"
          render={() => (
            <MasterDataReport company={this.companyService.company$.value} />
          )}
        />
        <Route
          path="/dashboard/new_stock_report"
          render={() => <JharcraftNewStockSummary />}
        />
        <Route path="/dashboard/memo" render={() => <Memo />} />
        <Route path="/dashboard/master/discount" component={Discount} />
        <Route path="/dashboard/master/sales_person" component={SalesPerson} />
      </Switch>
    );
  }
}

interface Props {}

interface S {
  user: any;
}

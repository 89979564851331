import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { DateService } from './DateService';
import {
  JobOrderInput,
  CreateJobOrderInput,
  ConversionDetailNode,
  CancelJobOrderInput,
  CancelJobOrderPayload,
  JobOrderPartialCancelInput,
  CreateJobOrderPartialCancelInput,
  CreateJobOrderPartialCancelPayload,
} from '../schema';
import { ProductDetailNodeEdge } from '../components/Sales/constants';
import {
  jobOrderCancelMutation,
  jobOrderMutation,
  jobOrderPartialCancelMutation,
} from '../components/JobOrder/constants';

export class JobOrderService {
  static instance: JobOrderService;
  constructor() {
    if (JobOrderService.instance) {
      return JobOrderService.instance;
    } else {
      JobOrderService.instance = this;
    }
  }

  companyService = new CompanyService();
  dateService = new DateService();

  addJobOrder(
    data: JobOrderInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.date = data.date || data.transferData?.date;
    data.orderFor = data.orderFor?.map((order) => {
      delete (order as any as ConversionDetailNode).conversion;
      delete (order as any as ConversionDetailNode).sourceBranch;
      delete (order as any as ConversionDetailNode).destinationBranch;
      delete (order as any as ConversionDetailNode).vendorFor;
      return {
        ...order,
        sourceBranchId: data.transferData?.fromLocationId,
        destinationBranchId: data.transferData?.toLocationId,
        vendorForId: data.transferData?.vendorForId,
        companyId: this.companyService?.company$.getValue()?.id,
      };
    });
    data.transferData.productDetails =
      data?.transferData?.productDetails &&
      data.transferData.productDetails.map((p) => {
        p.date = data.transferData?.date;
        p.movementForId = data.transferData?.toLocationId;
        p.movementFromId = data.transferData?.fromLocationId;
        p.vendorForId = data.transferData?.vendorForId;
        p.companyId = this.companyService?.company$.getValue()?.id;
        delete (p as any as ProductDetailNodeEdge).product;
        delete (p as any as ProductDetailNodeEdge).movementFor;
        delete (p as any as ProductDetailNodeEdge).movementFrom;
        delete (p as any as ProductDetailNodeEdge).vendorFor;
        delete (p as any as ProductDetailNodeEdge).availableQuantityForJobOrder;
        return p;
      });
    Api.graphql<{ input: CreateJobOrderInput }, {}>(
      API_URL,
      {
        input: {
          jobOrder: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
            transferData: {
              ...data.transferData,
              companyId: this.companyService?.company$.getValue()?.id,
            },
          },
        },
      },
      jobOrderMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }

  cancelJobOrder(
    id: string,
    onSuccess: (reponse) => void,
    onError?: (response) => void,
  ) {
    Api.graphql<{ input: CancelJobOrderInput }, CancelJobOrderPayload>(
      API_URL,
      {
        input: { id },
      },
      jobOrderCancelMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }

  jobOrderPartialCancel(
    data: JobOrderPartialCancelInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<
      { input: CreateJobOrderPartialCancelInput },
      CreateJobOrderPartialCancelPayload
    >(
      API_URL,
      {
        input: {
          jobOrderPartialCancel: {
            ...data,
          },
        },
      },
      jobOrderPartialCancelMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

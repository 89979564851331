import { PurchaseNodeEdgeMutation } from '../Purchase/constants';
import moment from 'moment';

export const purchaseReturnObject: PurchaseNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      purchaseRate: 0,
      quantity: 0,
      amount: 0,
      movementForId: '',
      movementType: 'PURCHASE_RETURN',
      companyId: null,
    },
  ],
  billNo: '',
  vendorId: null,
  vendorInvoiceNo: null,
  vendorInvoiceDate: null,
  isReturn: true,
  purchaseToReturnId: null,
  companyId: null,
};

export const purchaseReturnReportQuery = `
query PurchaseReturnReport($companyId: ID, $after: String) {
  allProductDetails(
    companyId: $companyId,
    movementType: "PURCHASE_RETURN",
    first: 20,
    after: $after,
    orderBy: "-date",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
          purchaseRate
        }
        quantity
        amount
        purchaseSet {
          edges {
            node {
              date
              billNo
              vendor {
                name
              }
            }
          }
        }
      }
    }
  }
}`;

import * as React from 'react';
import { Table, Tabs, Icon, Spin } from 'antd';
import { checkAddPermission } from '../helper';
import { ApprovalService } from '../../service/ApprovalService';
import { AddApproval } from './AddApproval';
import { CompanyService } from '../../service/CompanyService';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { ApprovalNodeEdgeQuery, ApprovalEdges } from './constants';
import { ApprovalReceiptJharcraft } from './ApprovalReceiptJharcraft';
import { PDFViewer } from '@react-pdf/renderer';
import { ApprovalReceipt } from './ApprovalReceipt';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';
import { ApprovalReport } from './ApprovalReport';
import { ApprovalReceiptKhadi } from './ApprovalReceiptKhadi';

export class Approval extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      approvals: [],
      activeKey: '1',
      selectedApproval: null,
      branch: null,
      company: null,
      isEditActive: false,
      user: null,
    };
  }

  approvalService = new ApprovalService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.approvalService?.approval$.subscribe((approvals) =>
      this.setState({ approvals }),
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );

    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1' || this.state.activeKey === '3') {
      this.setState({ activeKey, selectedApproval: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  approvalColumn = () => [
    {
      title: 'Approval No',
      dataIndex: 'node.approvalNo',
      key: 'approvalNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedApproval: obj,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
    },
    {
      title: 'Return For',
      dataIndex: 'node.oldApproval.approvalNo',
      key: 'returnFor',
      render: (value, record: ApprovalEdges) => {
        return record.node.isReturn
          ? value
          : this.state.approvals.find(
              (app) => app.node?.oldApproval?.id === record.node.id,
            )
          ? 'Returned'
          : '';
      },
    },
    {
      title: 'Customer',
      dataIndex: 'node.customer.name',
      key: 'customer',
    },
    {
      title: 'Total Cost Amount',
      dataIndex: 'node.totalCostAmount',
      key: 'totalCostAmount',
    },
    {
      title: 'Total MRP',
      dataIndex: 'node.totalMrp',
      key: 'totalMrp',
    },
    {
      title: 'Approved By',
      dataIndex: 'node.approvedByName',
      key: 'approvedByName',
      render: (value, record) =>
        `${record.node.approvedByName}(${record.node.approvedByDesignation})`,
    },
    {
      title: 'Receipt',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedApproval: obj, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  getApprovalsToReturn = (approvals: ApprovalEdges[]) => {
    return approvals.filter(
      (app) =>
        !app.node.isReturn &&
        !approvals.find((appr) => appr.node?.oldApproval?.id === app.node.id),
    );
  };

  render() {
    if (!this.state.company) return <Spin />;
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          {this.state.user && checkViewPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  List Approval
                </span>
              }
              key="1"
              closable={false}
            >
              <Table
                dataSource={this.state.approvals}
                columns={this.approvalColumn()}
                rowKey={(record) => record.node.id || ''}
              />
            </Tabs.TabPane>
          )}
          {this.state.user && checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Approval
                </span>
              }
              key="2"
            >
              {this.companyService?.company$.value && (
                <AddApproval
                  approval={this.state.selectedApproval?.node}
                  handleTabChange={this.handleTabChange}
                  company={this.companyService?.company$.value}
                  approvalList={this.getApprovalsToReturn(this.state.approvals)}
                  isEditActive={this.state.isEditActive}
                />
              )}
            </Tabs.TabPane>
          )}
          {this.state.selectedApproval && this.state.activeKey === '3' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Receipt
                </span>
              }
              key="3"
            >
              {this.companyService?.selectedBussiness$.value && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  {this.companyService?.company$.value &&
                  this.state.company.name === 'Jharcraft' ? (
                    <ApprovalReceiptJharcraft
                      company={this.companyService?.company$.value}
                      approval={this.state.selectedApproval.node}
                      branch={this.companyService?.selectedBussiness$.value}
                    />
                  ) : this.companyService?.company$.value &&
                    this.state.company?.name.toLowerCase().includes('khadi') ? (
                    <ApprovalReceiptKhadi
                      company={this.companyService?.company$.value}
                      approval={this.state.selectedApproval.node}
                      branch={this.companyService?.selectedBussiness$.value}
                    />
                  ) : (
                    <ApprovalReceipt
                      approval={this.state.selectedApproval.node}
                      branch={this.companyService?.selectedBussiness$.value}
                    />
                  )}
                </PDFViewer>
              )}
            </Tabs.TabPane>
          )}
          {this.state.user && checkViewPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Item Wise Approval Report
                </span>
              }
              key="4"
              closable={false}
            >
              <ApprovalReport
                company={this.state.company}
                branch={this.state.branch}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

interface State {
  approvals: {
    cursor: string;
    node: ApprovalNodeEdgeQuery;
  }[];
  activeKey: string;
  selectedApproval: {
    cursor: string;
    node: ApprovalNodeEdgeQuery;
  } | null;
  branch: BranchNodeEdge | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import {
  Tabs,
  Icon,
  Skeleton,
  Table,
  Row,
  Col,
  DatePicker,
  Button,
  Spin,
} from 'antd';
import {
  ProductionNodeConnection,
  ProductionNodeEdge,
  ConversionDetailNodeEdge,
  BranchCategory,
} from '../../../schema';
import { CompanyService } from '../../../service/CompanyService';
import { AddProduction } from './AddProduction';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { productionQuery, productionReportQuery } from './constants';
import { Pagination } from 'sha-el-design/lib';
import moment from 'moment';
import { checkAddPermission } from '../../helper';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class Production extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedProduction: null,
      company: null,
      business: null,
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      branchId: '',
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) => {
      this.setState({
        business,
        activeKey:
          business?.type === 'company' &&
          !business.name.toLowerCase().includes('tiles')
            ? '3'
            : '1',
      });
    });
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedProduction: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  productionColumn = () => [
    {
      title: 'Invoice No',
      dataIndex: 'node.billNo',
      key: 'billNo',
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ selectedProduction: record, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Amount',
      dataIndex: 'node.amount',
      key: 'amount',
    },
    {
      title: 'Remarks',
      dataIndex: 'node.remark',
      key: 'remark',
    },
  ];

  productionReportColumn = () => [
    {
      title: 'Date',
      dataIndex: 'productionDate',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      render: (text) => text,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text) => text,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => text,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => text,
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (text) => text,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      render: (text) => text,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  getReportTable = (productionList: ProductionNodeEdge[]) => {
    let objList: any[] = [];
    productionList.forEach((element) => {
      const forOneProduction = (
        element.node?.conversionDetails as any
      ).edges.map((conversionNode: ConversionDetailNodeEdge) => {
        const conversionDetail = conversionNode.node;
        return {
          item: conversionDetail?.conversion?.finishedProduct,
          rate: conversionDetail?.cost,
          quantity: conversionDetail?.quantity,
          amount: conversionDetail?.amount,
          itemType: conversionDetail?.conversion?.productionType,
          invoiceNo: element.node?.billNo,
          productionDate: element.node?.date,
          description: conversionDetail?.remark,
        };
      });
      objList = [...objList, ...forOneProduction];
    });
    return objList;
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }
    if (!this.state.startDate || !this.state.endDate) {
      return <Spin />;
    }
    const {
      company,
      after,
      currentPage,
      activeKey,
      business,
      startDate,
      endDate,
      branchId,
      selectedProduction,
    } = this.state;
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        {(company.name.toLowerCase().includes('tiles') ||
          business?.type === 'branch') && [
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Production List
              </span>
            }
            key="1"
            closable={false}
          >
            <Row>
              <Col span={5}>
                <DatePicker
                  format="DD-MM-YYYY"
                  placeholder="Start Date"
                  value={moment(startDate)}
                  onChange={(date) =>
                    this.onChange({
                      target: {
                        name: 'startDate',
                        value: date && moment(date).format('YYYY-MM-DD'),
                      },
                    })
                  }
                  disabledDate={(currentDate) => {
                    if (!currentDate || !endDate) {
                      return false;
                    }
                    return currentDate.valueOf() > moment(endDate).valueOf();
                  }}
                  style={{ marginTop: '5px', width: '100%' }}
                />
              </Col>
              <Col span={5}>
                <DatePicker
                  format="DD-MM-YYYY"
                  placeholder="End Date"
                  value={moment(endDate)}
                  onChange={(date) =>
                    this.onChange({
                      target: {
                        name: 'endDate',
                        value: date && moment(date).format('YYYY-MM-DD'),
                      },
                    })
                  }
                  disabledDate={(currentDate) => {
                    if (!currentDate || !startDate) {
                      return false;
                    }
                    return currentDate.valueOf() < moment(startDate).valueOf();
                  }}
                  style={{ marginTop: '5px', width: '100%' }}
                />
              </Col>
            </Row>
            <GraphqlQuery
              queryString={productionQuery}
              variables={{
                companyId: company.id,
                after,
                activeKey,
                branchId: business?.type === 'branch' ? business?.id : '',
                date_Gte: startDate,
                date_Lte: endDate,
              }}
              render={(
                response: { allProductions: ProductionNodeConnection },
                error,
                loading,
              ) => {
                if (loading) {
                  return <Skeleton active />;
                }
                if (response && company) {
                  return [
                    <Table
                      key="table"
                      dataSource={response.allProductions.edges}
                      columns={this.productionColumn()}
                      rowKey={(obj) => obj.node?.id || ''}
                      pagination={false}
                    />,
                    <Pagination
                      key="pagination"
                      totalCount={0}
                      currentPage={currentPage}
                      batchSize={20}
                      cursorBasedPagination
                      onChange={(_p, _ps, next) =>
                        this.onPageChange(
                          next,
                          response.allProductions.pageInfo.endCursor,
                          response.allProductions.pageInfo.hasNextPage,
                        )
                      }
                      style={{ float: 'right' }}
                    />,
                  ];
                }
                return <Skeleton active />;
              }}
            />
          </Tabs.TabPane>,
          this.state.user && checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Production
                </span>
              }
              key="2"
            >
              <AddProduction
                production={selectedProduction?.node}
                handleTabChange={this.handleTabChange}
                company={company}
              />
            </Tabs.TabPane>
          ),
        ]}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Production Report
            </span>
          }
          key="3"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            {business?.type === 'company' && [
              <Col span={8} key="brachId">
                <TSAutoComplete
                  error={''}
                  inputProps={{ name: 'branchId', addonBefore: 'Location' }}
                  dataSource={
                    business.branches?.edges.filter(
                      (element) =>
                        element.node.category === BranchCategory.FINISHING_UNIT,
                    ) || []
                  }
                  key="id"
                  name="branchId"
                  onValueSelect={(e) =>
                    this.setState({ branchId: e.target.value })
                  }
                  displayKey="name"
                  textValue={getValue(business.branches?.edges, branchId)}
                />
              </Col>,
              <Col span={3} key="clearLocation">
                <Button
                  children="Clear Location"
                  onClick={() => this.setState({ branchId: '' })}
                  style={{ marginTop: '5px', width: '100%' }}
                />
              </Col>,
            ]}
          </Row>
          {!(business?.type === 'company' && branchId === '') && (
            <GraphqlQuery
              queryString={productionReportQuery}
              variables={{
                companyId: company.id,
                after,
                activeKey,
                branchId: business?.type === 'branch' ? business.id : branchId,
                startDate,
                endDate,
              }}
              render={(
                response: { allProductions: ProductionNodeConnection },
                error,
                loading,
              ) => {
                if (loading) {
                  return <Skeleton active />;
                }
                if (response && company) {
                  return [
                    <Table
                      key="table"
                      dataSource={this.getReportTable(
                        response.allProductions.edges,
                      )}
                      columns={this.productionReportColumn()}
                      rowKey={(obj) => obj.invoiceNo}
                      pagination={false}
                    />,
                    <Pagination
                      key="pagination"
                      totalCount={0}
                      currentPage={currentPage}
                      batchSize={20}
                      cursorBasedPagination
                      onChange={(_p, _ps, next) =>
                        this.onPageChange(
                          next,
                          response.allProductions.pageInfo.endCursor,
                          response.allProductions.pageInfo.hasNextPage,
                        )
                      }
                      style={{ float: 'right' }}
                    />,
                  ];
                }
                return <Skeleton active />;
              }}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedProduction: ProductionNodeEdge | null;
  company: CompanyNodeEdge | null;
  business: Bussiness | null;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  branchId: string;
  user?: UserNodeEdge | null;
}

interface Props {}

import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import {
  productCategoriesQuery,
  ProductCategoryEdges,
  ProductCategoryQueryResponse,
  ProductCategoryNodeEdge,
  productCategoryMutation,
  AddProductCategoryVariables,
} from '../components/master/ProductCategories/constants';
import { BehaviorSubject } from 'rxjs';

export class ProductCategoryService {
  static instance;
  public orderBy;

  constructor(orderBy = '-id') {
    if (ProductCategoryService.instance) {
      return ProductCategoryService.instance;
    } else {
      this.orderBy = orderBy;
      ProductCategoryService.instance = this;
    }
    this.onInit();
  }

  companyService = new CompanyService();
  public readonly productCategory$ = new BehaviorSubject<
    ProductCategoryEdges[]
  >([]);

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  getAll(companyId: string) {
    Api.graphql<{}, ProductCategoryQueryResponse>(
      API_URL,
      { companyId, orderBy: this.orderBy },
      productCategoriesQuery,
      HEADER,
      (response) =>
        this.productCategory$.next(response.data.allProductCategories.edges),
      (error) => error,
    );
  }

  addProductCategory(
    data: ProductCategoryNodeEdge,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    Api.graphql<AddProductCategoryVariables, {}>(
      API_URL,
      {
        input: {
          productCategory: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      productCategoryMutation,
      HEADER,
      (response) => {
        this.companyService?.shouldUpdate$.next(
          this.companyService.shouldUpdate$.getValue() + 1,
        );
        onSuccess(response);
      },
      onError,
    );
  }
}

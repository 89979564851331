import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { OptionProps } from 'antd/lib/select';
import {
  DeliveryInstructionNodeConnection,
  DeliveryInstructionNodeEdge,
} from '../../schema';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  deliveryInstruction?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface ConversionDropDownProps extends AutoCompleteProps {
  extrafields?: string[];
  companyId?: string;
}

export class DeliveryInstructionDropdown extends React.Component<
  ConversionDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      touched: false,
    };
  }

  value = (deliveryInstructions: DeliveryInstructionNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const deliveryInstruction = deliveryInstructions.find(
      (v) => v.node?.id === this.props.value,
    );
    if (!deliveryInstruction) return;
    return deliveryInstruction.node?.deliveryInstructionNo;
  };

  render() {
    const { name } = this.state;
    const { extrafields, value, onSelect, companyId } = this.props;
    return (
      <GraphqlQuery
        queryString={`query DeliveryInstructionDropDown($id: ID, $name: String, $companyId: ID) {
          allDeliveryInstructions(first: 10, id: $id, deliveryInstructionNo_Icontains: $name, companyId: $companyId) {
            edges {
              node {
                id
                date
                customer {
                  id
                  name
                }
                productDetails {
                  edges {
                    node {
                      quantity
                    }
                  }
                }
                deliveryInstructionNo
                layingAndFittingCharge
                transportationCharge
                otherCharges
                saleChallanProductDetails {
                  productId
                  orderQuantity
                  rate
                  purchaseRate
                  gstRate
                  dispatchedQuantity
                  remainingQuantity
                  quantity
                  movementType
                  product {
                    id
                    hsn {
                      hsnCode
                      gst
                      minValue
                      maxValue
                      hsnWithSameCode {
                        hsnCode
                        gst
                        minValue
                        maxValue
                      }
                    }
                  }
                }
              }
            }
          }
        }`}
        variables={{
          name,
          companyId,
          id: value && !name ? value : '',
        }}
        render={(
          response: {
            allDeliveryInstructions: DeliveryInstructionNodeConnection;
          },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <h3>Something Went Wrong!!</h3>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Delivery Instruction"
                dataSource={response.allDeliveryInstructions.edges.map(
                  (delivery) => (
                    <Option
                      key={delivery.node?.id}
                      label={delivery.node}
                      deliveryInstruction={delivery}
                    >
                      {delivery.node?.deliveryInstructionNo}
                    </Option>
                  ),
                )}
                value={this.value(response.allDeliveryInstructions.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(delivery) =>
                  this.setState({ name: delivery, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    name: (f as any).props.deliveryInstruction.node
                      .deliveryInstructionNo,
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  name: string;
  touched: boolean;
}

import { ProductDetailNodeEdge } from '../Sales/constants';
import { TransferNode } from '../Transfer/constants';
import moment from 'moment';

export const transferReturnNode: TransferNode<ProductDetailNodeEdge[]> = {
  date: moment().format('YYYY-MM-DD'),
  fromLocationId: '',
  fromHeadOffice: false,
  toHeadOffice: true,
  toLocationId: '',
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      quantity: 0,
      amount: 0,
      movementForId: '',
      movementType: 'UP',
      companyId: null,
    },
  ],
  isTransfer: false,
  remarks: null,
  status: 'PENDING',
  fromVendor: false,
  toVendor: false,
};

import * as React from 'react';
import { DepositNode, DepositNodeConnection } from '../../schema';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { CompanyService } from '../../service/CompanyService';
import { Spin, Tabs, Icon, Table, Skeleton } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { AddDeposit } from './AddDeposit';
import { checkAddPermission } from '../helper';
import { Pagination } from 'sha-el-design/lib';
import { depositQuery } from './constants';
import moment from 'moment';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';

export class Deposit extends React.Component<{}, State> {
  companyService = new CompanyService();
  userService = new UserService();

  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedDeposit: null,
      isEditActive: false,
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      user: null,
    };
  }

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  depositColumns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedDeposit: obj,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {moment(text).format('DD-MM-YYYY')}
        </a>
      ),
    },
    {
      title: 'Deposit Amount',
      key: 'depositAmount',
      dataIndex: 'node.depositAmount',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      dataIndex: 'node.remarks',
    },
  ];

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedDeposit: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    const {
      activeKey,
      selectedDeposit,
      isEditActive,
      business,
      company,
      after,
      currentPage,
    } = this.state;
    const variables = {
      companyId: company.id,
      branchId: business.id,
      activeKey,
      first: 20,
      after,
    };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Deposit
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={depositQuery}
            variables={variables}
            render={(
              response: { allDeposits: DepositNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (error) {
                return <h3>Something Went Wrong!</h3>;
              }
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allDeposits.edges}
                    columns={this.depositColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={30}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allDeposits.pageInfo.endCursor,
                        response.allDeposits.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Deposit
              </span>
            }
            key="2"
          >
            <AddDeposit
              deposit={selectedDeposit}
              handleTabChange={this.handleTabChange}
              company={company}
              business={business}
              isEditActive={isEditActive}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedDeposit: DepositNode | null;
  isEditActive: boolean;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

import { BehaviorSubject } from 'rxjs';
import moment from 'moment';

export class DateService {
  static instance: DateService;
  public readonly date$ = new BehaviorSubject<string>(
    moment().format('YYYY-MM-DD'),
  );

  constructor() {
    if (DateService.instance) {
      return DateService.instance;
    } else {
      DateService.instance = this;
    }
  }
}

import { BranchNodeEdge } from '../Branch/constants';

export interface CompanyNodeEdge {
  id?: string;
  name: string;
  address?: string;
  state?: string;
  contactNo?: string;
  gstin?: string;
  gstRate: number | null;
  amountPerPointSale: number | null;
  amountPerPointRedeem: number | null;
  hsnCode: string;
  withProduction?: boolean;
  withAmc?: boolean;
  branches?: {
    edges: {
      cursor: string;
      node: BranchNodeEdge;
    }[];
  };
  bankName: string;
  branch: string;
  accountNo: string;
  ifscCode: string;
}

export interface CompanyError {
  name: string;
  address: string;
  state: string;
  contactNo: string;
  gstin: string;
  gstRate: string;
  hsnCode: string;
  amountPerPointSale: string;
  amountPerPointRedeem: string;
  bankName: string;
  branch: string;
  accountNo: string;
  ifscCode: string;
}

export const companyObject: CompanyNodeEdge = {
  name: '',
  address: '',
  state: '',
  contactNo: '',
  gstin: '',
  gstRate: null,
  hsnCode: '',
  amountPerPointSale: null,
  amountPerPointRedeem: null,
  bankName: '',
  branch: '',
  accountNo: '',
  ifscCode: '',
};

export const companyErrorObject: CompanyError = {
  name: '',
  address: '',
  state: '',
  contactNo: '',
  gstin: '',
  gstRate: '',
  hsnCode: '',
  amountPerPointSale: '',
  amountPerPointRedeem: '',
  bankName: '',
  accountNo: '',
  ifscCode: '',
  branch: '',
};

export const initialQuery = `
  query UserBranchQuery($id: ID!) {
    userDetail(id: $id) {
      company {
        id
        name
        address
        state
        contactNo
        gstin
        gstRate
        hsnCode
        amountPerPointSale
        amountPerPointRedeem
        withProduction
        withAmc
        bankName
        branch
        accountNo
        ifscCode
        branches {
          edges {
            node {
              id
              name
              initials
              address
              state
              contactNo
              email
              gstin
              category
              incharge
              parentCategory
              parentLocation {
                id
                name
              }
              company {
                id
              }
              favourOf
            }
            cursor
          }
        }
      }
      branch {
        id
        name
        address
        state
        contactNo
        email
        gstin
        category
        incharge
        parentCategory
        parentLocation {
          id
          name
        }
        companyId
        companyName
        favourOf
      }
    }
  }
`;

export interface InitialQueryResponse {
  data: {
    userDetail: {
      company: CompanyNodeEdge;
      branch: BranchNodeEdge;
    };
  };
}

export interface AddCompanyVariables {
  input: {
    company: CompanyNodeEdge;
  };
}

export const companyMutation = `
  mutation addCompany(
    $input: CreateCompanyInput!
  ) {
    createCompany(input: $input){
      newCompany {
        id
        name
        gstin
        amountPerPointSale
        amountPerPointRedeem
      }
    }
  }
`;

export interface Bussiness
  extends CompanyNodeEdge,
    Omit<BranchNodeEdge, 'name'> {
  type: 'company' | 'branch';
}

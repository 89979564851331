import { CompanyNodeEdge } from '../Company/constants';

export interface VendorNode {
  id?: string;
  name: string;
  address: string;
  state: string;
  contactPersonName: string;
  contactNo: string;
  email: string;
  gstin: string;
  companyId?: string | null;
  company?: CompanyNodeEdge;
}

export interface VendorError {
  name: string;
  contactPersonName: string;
  contactNo: string;
  state: string;
  gstin: string;
}

export const vendorObject: VendorNode = {
  name: '',
  address: '',
  state: '',
  contactPersonName: '',
  contactNo: '',
  email: '',
  gstin: '',
  companyId: null,
};

export const vendorErrorObject: VendorError = {
  name: '',
  contactPersonName: '',
  contactNo: '',
  state: '',
  gstin: '',
};

export interface VendorQueryVariable {
  companyId: string;
}

export interface VendorQueryResponse {
  data: {
    allVendors: {
      edges: VendorEdges[];
    };
  };
}

export interface VendorEdges {
  cursor?: string;
  node: VendorNode;
}

export const vendorQuery = `
  query vendorList($companyId: ID!) {
    allVendors(companyId: $companyId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          address
          state
          contactPersonName
          contactNo
          email
          gstin
        }
      }
    }
  }
`;

export interface AddVendorVariables {
  input: {
    vendor: VendorNode;
  };
}

export const vendorMutation = `
  mutation addVendor($input: CreateVendorInput!) {
    createVendor(input: $input){
      newVendor {
        id
        name
      }
    }
  }
`;

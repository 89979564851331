import * as React from 'react';
import { TransferService } from '../../service/TransferService';
import { notification, Button, Popconfirm } from 'antd';
import {
  transferError,
  transferNode,
  TransferNode,
  TransferError,
  handleProductDetailsData,
  handleCommissionDetailsData,
  generateCommissionDetailsObj,
  descriptions,
} from './constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { TransferForm } from './TransferForm';
import { BranchEdges } from '../master/Branch/constants';
import { BranchService } from '../../service/BranchService';
import {
  CommissionDetailsNode,
  CommissionDetailsNodeConnection,
  ProductNode,
} from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { forEach } from 'lodash';
import moment from 'moment';
import { DiscountCategoryService } from '../../service/DiscountCategorySerive';
import { DiscountCategoryNode } from '../master/DiscountCategory/constants';

export class AddTransfer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transfer: this.handleTransferPropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      branch: [],
      totalQuantity: 0,
      totalAmount: 0,
      buttonDisabled: false,
      productObjList: [],
      discountCategories: [],
      isRateEditable:
        props.company.name.includes('Seva Sadan') ||
        props?.business?.category === 'FINISHING_UNIT'
          ? true
          : false,
    };
  }

  transferService = new TransferService();
  branchService = new BranchService();
  discountCategoryService = new DiscountCategoryService();

  componentDidMount() {
    this.fetchBranch();
    if (this.props.company.name.toLowerCase().includes('khadi')) {
      this.fetchDiscountCategory();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      transfer: this.handleTransferPropsUpdate(nextProps),
      error: this.handleErrorPropsUpdate(nextProps),
    });
  }

  handleTransferPropsUpdate = (nextProps: Props) => {
    const transfer = nextProps.transfer
      ? {
          ...nextProps.transfer,
          fromLocationId:
            nextProps.transfer.fromLocation &&
            nextProps.transfer.fromLocation.id,
          toLocationId:
            nextProps.transfer.toLocation && nextProps.transfer.toLocation.id,
          productDetails: handleProductDetailsData(
            nextProps.transfer.productDetails as ProductDetailsNodeConnection,
          ),
          commissionDetails: handleCommissionDetailsData(
            nextProps.transfer
              .commissionDetails as CommissionDetailsNodeConnection,
          ),
        }
      : JSON.parse(
          JSON.stringify({
            ...transferNode,
            toHeadOffice: this.props.business.type === 'branch' ? true : false,
          }),
        );
    if (
      this.props.company.name.toLowerCase().includes('khadi') &&
      !nextProps.transfer
    ) {
      transfer.commissionDetails = generateCommissionDetailsObj();
    }
    delete transfer.fromLocation;
    delete transfer.toLocation;
    delete transfer.hsnGstWiseAmount;
    delete transfer.vendorFor;
    return transfer;
  };

  handleErrorPropsUpdate = (nextProps: Props) => {
    const error: TransferError = JSON.parse(JSON.stringify(transferError));
    nextProps.transfer &&
      nextProps.transfer.productDetails.edges.forEach(() => {
        if (
          nextProps.transfer?.productDetails.edges.length !==
          error.productDetails.length
        ) {
          error.productDetails.push({
            productId: '',
            quantity: '',
            purchaseRate: '',
            amount: '',
          });
        }
      });
    return error;
  };

  fetchBranch = () => {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
  };

  fetchDiscountCategory = () => {
    this.discountCategoryService.discountCategory$.subscribe((dc) => {
      const discountCategories: DiscountCategoryNode[] = [];
      dc.forEach((value) => {
        discountCategories.push({
          name: value.node.name,
          mda: value.node.mda,
          commission: value.node.commission,
        });
      });
      this.setState({ discountCategories });
    });
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState.transfer[e.target.name] = e.target.value;
    if (e.target.name === 'toLocationId') {
      const branch = nextState.branch.find(
        (br) => br.node.id === e.target.value,
      );
      nextState.isRateEditable =
        branch?.node.category === 'FINISHING_UNIT' ? true : false;
    }
    this.setState(nextState);
  };

  checkError = () => {
    const error = { ...this.state.error };
    let isError = false;
    const notRequiredFields = [
      'fromHeadOffice',
      'fromLocationId',
      'toLocationId',
      'companyId',
      'productDetails',
      'transferInvoiceNo',
      'receivingDate',
      'remarks',
      'commissionDetails',
    ];
    forEach(this.state.transfer, (value, key) => {
      if (
        typeof value === 'string' &&
        notRequiredFields.indexOf(key) < 0 &&
        !value.trim()
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (
        !value &&
        value !== false &&
        notRequiredFields.indexOf(key) < 0
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (notRequiredFields.indexOf(key) < 0) {
        error[key] = '';
      }
    });
    if (
      !this.state.transfer.toHeadOffice &&
      !this.state.transfer.toLocationId
    ) {
      error.toLocationId = `This is a required field`;
      isError = true;
    } else {
      error.toLocationId = '';
      isError = isError;
    }
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.transfer.productDetails[index][e.target.name] = e.target.value;

    if (this.props.company.name.toLowerCase().includes('khadi')) {
      if (
        nextState.transfer.productDetails[index].quantity &&
        nextState.transfer.productDetails[index].rate
      ) {
        nextState.transfer.productDetails[index].amount = Number(
          (
            Number(nextState.transfer.productDetails[index].quantity) *
            Number(nextState.transfer.productDetails[index].rate)
          ).toFixed(2),
        );
      }
    } else {
      if (
        nextState.transfer.productDetails[index].quantity &&
        nextState.transfer.productDetails[index].purchaseRate
      ) {
        nextState.transfer.productDetails[index].amount = Number(
          (
            Number(nextState.transfer.productDetails[index].quantity) *
            Number(nextState.transfer.productDetails[index].purchaseRate)
          ).toFixed(2),
        );
      }
    }
    if (
      this.props.company?.name.toLowerCase().includes('pearl') ||
      this.props.company?.name.toLowerCase().includes('seva sadan') ||
      this.props.company.withAmc ||
      this.props.company?.name.toLowerCase().includes('kraftribe')
    ) {
      nextState.transfer.productDetails[index].status = 'ACCEPTED';
    }

    if (this.props.company.name.toLowerCase().includes('khadi')) {
      this.handleTransferCommission(nextState);
    }
    this.calculateTotalQuantity(nextState);
    this.calculateTotalAmount(nextState);
    this.setState(nextState);
  };

  calculateTotalQuantity = (nextState: State) => {
    nextState.totalQuantity = Number(
      nextState.transfer.productDetails
        .reduce((total, product) => total + Number(product.quantity), 0)
        .toFixed(2),
    );

    this.setState(nextState);
  };

  calculateTotalAmount = (nextState: State) => {
    nextState.totalAmount = nextState.transfer.productDetails.reduce(
      (pr, curr) => pr + (curr.amount || 0),
      0,
    );

    if (
      nextState.transfer.commissionDetails &&
      nextState.transfer.commissionDetails.length > 3
    ) {
      const commissions = nextState.transfer.commissionDetails?.slice(0, -3);
      const totalCommission = commissions.reduce(
        (prev, curr) => prev + curr.descriptionValue,
        0,
      );
      nextState.totalAmount -= Number(totalCommission);

      const additions = nextState.transfer.commissionDetails?.slice(-3);
      const totalAdditions = additions.reduce(
        (prev, curr) => prev + curr.descriptionValue,
        0,
      );
      nextState.totalAmount += Number(totalAdditions);
    }
  };

  handleTransferCommission = (nextState: State) => {
    const commissionDetails: CommissionDetailsNode[] = [];

    const productObjList = this.state.productObjList;
    productObjList.forEach((prodObj, index) => {
      const category = prodObj.section?.toLowerCase();
      const amount = nextState.transfer.productDetails[index].amount;

      if (amount == 0) return;

      const mdaIndex = commissionDetails.findIndex?.(
        (el) =>
          el.category.toLowerCase() == category && el.description == 'MDA',
      );
      const commIndex = commissionDetails.findIndex?.(
        (el) =>
          el.category.toLowerCase() == category && el.description == 'COMM',
      );

      if (mdaIndex == -1 || commIndex == -1) {
        const discountCategories = [...this.state.discountCategories];
        const idx = discountCategories.findIndex(
          (el) => el.name.toLowerCase() == category,
        );
        if (idx != -1) {
          commissionDetails.push({
            category: discountCategories[idx].name,
            description: 'MDA',
            descriptionPercent: discountCategories[idx].mda,
            descriptionValue:
              ((amount || 0) * discountCategories[idx].mda) / 100 || 0,
          });
          commissionDetails.push({
            category: discountCategories[idx].name,
            description: 'COMM',
            descriptionPercent: discountCategories[idx].commission,
            descriptionValue:
              ((amount || 0) * discountCategories[idx].commission) / 100 || 0,
          });
        }
      } else {
        commissionDetails[mdaIndex].descriptionValue +=
          ((amount || 0) *
            (commissionDetails[mdaIndex].descriptionPercent || 0)) /
          100;
        commissionDetails[commIndex].descriptionValue +=
          ((amount || 0) *
            (commissionDetails[commIndex].descriptionPercent || 0)) /
          100;
      }
    });
    nextState.transfer.commissionDetails?.forEach((el) => {
      if (
        el.category == '' &&
        descriptions.indexOf(el.description || '') != -1
      ) {
        commissionDetails.push({
          category: el.category,
          description: el.description,
          descriptionPercent: el.descriptionPercent,
          descriptionValue: el.descriptionValue,
        });
      }
    });

    this.updateSpecialAddition(nextState, commissionDetails);

    nextState.transfer.commissionDetails = [...commissionDetails];
  };

  updateSpecialAddition = (
    nextState: State,
    commissionDetails: CommissionDetailsNode[],
  ) => {
    let totalAmount = nextState.transfer.productDetails.reduce(
      (pr, curr) => pr + (curr.amount || 0),
      0,
    );
    const commissionDetailsCategory = commissionDetails.slice(0, -3);
    const totalCommission = commissionDetailsCategory.reduce(
      (prev, curr) => prev + curr.descriptionValue,
      0,
    );
    totalAmount -= totalCommission;
    const idx = commissionDetails.findIndex(
      (el) => el.description == 'Special Addition(%)',
    );
    if (commissionDetails[idx]) {
      commissionDetails[idx].descriptionValue =
        (totalAmount * (commissionDetails[idx]?.descriptionPercent || 0)) / 100;
    }
  };

  transferCommissionChangeHandler = (
    e: { target: { name: string; value: number } },
    index: number,
  ) => {
    const nextState = { ...this.state };
    const commissionDetails: CommissionDetailsNode[] =
      nextState.transfer.commissionDetails || [];

    if (
      commissionDetails.length == 0 ||
      commissionDetails[index].category != ''
    ) {
      return;
    }

    let totalAmount = nextState.transfer.productDetails.reduce(
      (pr, curr) => pr + (curr.amount || 0),
      0,
    );

    const commissionDetailsCategory = commissionDetails.slice(0, -3);
    const totalCommission = commissionDetailsCategory.reduce(
      (prev, curr) => prev + curr.descriptionValue,
      0,
    );
    totalAmount -= totalCommission;

    if (
      commissionDetails[index].description == 'Special Addition(%)' &&
      e.target.name == 'descriptionPercent'
    ) {
      commissionDetails[index][e.target.name] = Number(e.target.value);
      commissionDetails[index].descriptionValue =
        (totalAmount * e.target.value) / 100;
    } else if (
      e.target.name == 'descriptionValue' &&
      (commissionDetails[index].description == 'Packing/Other Charge' ||
        commissionDetails[index].description == 'Courier and Postal Charges')
    ) {
      commissionDetails[index][e.target.name] = Number(e.target.value);
    }

    this.calculateTotalAmount(nextState);
    this.setState(nextState);
  };

  checkProductError = () => {
    const produtDetails: ProductDetailNodeEdge[] = {
      ...this.state.transfer.productDetails,
    };
    let isError = false;
    const error = { ...this.state.error };
    error.productDetails.map((pd, index) => {
      Object.keys(pd).forEach((pdkey) => {
        if (
          !produtDetails[index][pdkey] &&
          ['purchaseRate', 'amount'].indexOf(pdkey) === -1
        ) {
          isError = true;
          pd[pdkey] = `This is a required field`;
        } else if (['purchaseRate', 'amount'].indexOf(pdkey) === -1) {
          pd[pdkey] = '';
        }
        if (
          ['purchaseRate', 'amount'].indexOf(pdkey) > -1 &&
          !produtDetails[index][pdkey] &&
          (!this.props.company.name.toLowerCase().includes('pearl')
            ? true
            : produtDetails[index][pdkey] !== 0)
        ) {
          isError = true;
          pd[pdkey] = `This is a required field`;
        } else if (['purchaseRate', 'amount'].indexOf(pdkey) > -1) {
          pd[pdkey] = '';
        }
      });
      return pd;
    });
    this.setState({ error });
    return isError;
  };

  checkForDuplicateProduct = () => {
    const state = { ...this.state };
    const lastProduct =
      state.transfer.productDetails[state.transfer.productDetails.length - 1];

    if (!lastProduct) {
      return;
    }

    const existingProductIndex = state.transfer.productDetails.findIndex(
      (v) => v?.productId === lastProduct.productId,
    );

    if (
      existingProductIndex === -1 ||
      existingProductIndex === state.transfer.productDetails.length - 1
    ) {
      return;
    }

    const newQuantity =
      Number(
        state.transfer.productDetails[existingProductIndex].quantity || 0,
      ) + Number(lastProduct.quantity || 0);

    state.transfer.productDetails[existingProductIndex].quantity = newQuantity;

    state.transfer.productDetails[existingProductIndex].amount = Number(
      (
        Number(state.transfer.productDetails[existingProductIndex].quantity) *
        Number(state.transfer.productDetails[existingProductIndex].purchaseRate)
      ).toFixed(2),
    );

    state.transfer.productDetails.splice(
      state.transfer.productDetails.length - 1,
      1,
    );

    state.error.productDetails.splice(
      state.transfer.productDetails.length - 1,
      1,
    );

    this.setState(state);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.transfer.productDetails.push({
        date: null,
        productId: '',
        quantity: 1,
        rate: 0,
        amount: 0,
        gstRate: 0,
        movementType: 'DOWN',
        status: 'PENDING',
        companyId: null,
      });
      nextState.error.productDetails.push({
        productId: '',
        purchaseRate: '',
        quantity: '',
        amount: '',
      });
    } else {
      nextState.transfer.productDetails.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
    }
    if (this.props.company.name.toLowerCase().includes('khadi')) {
      this.handleTransferCommission(nextState);
      this.calculateTotalAmount(nextState);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.transfer.productDetails[index].rate = productObj.sellingRate;
    nextState.transfer.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    nextState.transfer.productDetails[index].gstRate = productObj.hsn
      ? productObj.hsn.hsnWithSameCode.length > 1
        ? productObj.hsn.hsnWithSameCode.find(
            (hsn) =>
              hsn.maxValue !== undefined &&
              hsn.maxValue >= productObj.purchaseRate &&
              hsn.minValue !== undefined &&
              hsn.minValue <= productObj.purchaseRate,
          )?.gst
        : productObj.hsn.hsnWithSameCode[0].gst
      : 0;

    this.setState(nextState);
  };

  saveTransfer = () => {
    console.log(this.state);
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.transferService?.addTransfer(
      {
        ...this.state.transfer,
        fromHeadOffice: this.props.business.type === 'company' ? true : false,
        fromLocationId:
          this.props.business.type === 'company'
            ? null
            : this.props.business.id,
        toLocationId: this.state.transfer.toHeadOffice
          ? null
          : this.state.transfer.toLocationId,
        status:
          this.props.company?.name.toLowerCase().includes('pearl') ||
          this.props.company?.name.toLowerCase().includes('seva sadan') ||
          this.props.company.withAmc ||
          this.props.company?.name.toLowerCase().includes('kraftribe')
            ? 'ACCEPTED'
            : 'PENDING',
        receivingDate:
          this.props.company?.name.toLowerCase().includes('pearl') ||
          this.props.company?.name.toLowerCase().includes('seva sadan') ||
          this.props.company.withAmc
            ? moment().format('YYYY-MM-DD')
            : null,
      },
      () => {
        this.setState({
          transfer: JSON.parse(JSON.stringify(transferNode)),
          error: JSON.parse(JSON.stringify(transferError)),
          buttonDisabled: false,
        });
        notification.success({
          message: 'Transfer ' + this.state.transfer.id ? 'Updated' : 'Added',
          description: `Transfer was successfully ${
            this.state.transfer.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Transfer ' + this.state.transfer.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      transfer: JSON.parse(
        JSON.stringify({
          ...transferNode,
          toHeadOffice: this.props.business.type === 'branch' ? true : false,
        }),
      ),
      error: { ...transferError },
      totalQuantity: 0,
      totalAmount: 0,
    });
  };

  render() {
    return (
      <div>
        {this.props.transferTo && (
          <TransferForm
            values={this.state.transfer}
            error={this.state.error}
            onChange={this.onChange}
            productChangeHandler={this.productChangeHandler}
            updateProductsRow={this.updateProductsRow}
            branch={this.state.branch}
            productObjChangehandler={this.productObjChangehandler}
            isEditActive={this.props.isEditActive}
            company={this.props.company}
            business={this.props.business}
            isRateEditable={this.state.isRateEditable}
            transferTo={this.props.transferTo}
            totalQuantity={this.state.totalQuantity}
            totalAmount={this.state.totalAmount}
            checkForDuplicateProduct={this.checkForDuplicateProduct}
            transferCommissionChangeHandler={
              this.transferCommissionChangeHandler
            }
          />
        )}
        {!this.props.isViewActive && (
          <div>
            <Popconfirm
              title="Are you sure to clear?"
              onConfirm={this.clear}
              okText="Yes"
              cancelText="No"
            >
              <Button type="dashed" children="Clear" style={{ width: '50%' }} />
            </Popconfirm>
            <Button
              type="primary"
              onClick={this.saveTransfer}
              children="Submit"
              style={{ width: '50%' }}
              disabled={this.state.buttonDisabled}
            />
          </div>
        )}
      </div>
    );
  }
}

interface Props {
  transfer?: TransferNode | null;
  company: CompanyNodeEdge;
  business: Bussiness;
  isEditActive: boolean;
  handleTabChange?: (key) => void;
  transferTo?: 'to_department' | 'to_branch';
  isViewActive?: boolean;
}

interface State {
  transfer: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  error: TransferError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  isRateEditable: boolean;
  totalQuantity: number;
  totalAmount: number;
  discountCategories: DiscountCategoryNode[];
}

import { SubareaInput } from '../../../schema';

export interface SubareaError {
  name: string;
  pincode: string;
  areaId: string;
}

export const SUBAREA_DATA: SubareaInput = {
  name: '',
  pincode: '',
  areaId: '',
};

export const SUBAREA_ERROR: SubareaError = {
  name: '',
  pincode: '',
  areaId: '',
};

export const subareaMutation = `
mutation addSubarea ($input: CreateSubareaInput!) {
  createSubarea (input: $input) {
    newSubarea {
      id
      name
      pincode
    }
  }
}
`;

export const subareaQuery = `
query Subarea ($after: String) {
  allSubarea (first: 20, after: $after){
    pageInfo {
      hasNextPage
      endCursor
      }
      edges {
        node {
          id
          name
          pincode
          area {
            id
            name
          }
        }
      }
    }
  }
`;

import { BehaviorSubject } from 'rxjs';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  GiftEdges,
  GiftQueryResponse,
  GiftQueryVariable,
  giftQuery,
  GiftNodeEdgeMutation,
  AddGiftVariable,
  giftMutation,
} from '../components/Gift/constants';
import { DateService } from './DateService';

export class GiftService {
  static instance;

  constructor() {
    if (GiftService.instance) {
      return GiftService.instance;
    } else {
      GiftService.instance = this;
    }
    this.onInit();
  }

  companyService = new CompanyService();
  dateService = new DateService();

  onInit() {
    this.companyService?.selectedBussiness$.subscribe((bussiness) => {
      if (!bussiness) return;
      bussiness.type === 'branch'
        ? this.getAll({
            companyId: this.companyService?.company$.getValue()?.id,
            branchId: bussiness.id,
          })
        : this.getAll({
            companyId: this.companyService?.company$.getValue()?.id,
            branchId: null,
          });
    });
  }

  public readonly gift$ = new BehaviorSubject<GiftEdges[]>([]);

  getAll({ companyId: companyId, branchId: branchId }) {
    const body = {
      query: giftQuery,
      variables: {
        companyId,
        branchId,
        date_Lte: this.dateService?.date$.value,
      },
    };

    Api.post<{ query: string; variables: GiftQueryVariable }>(
      API_URL,
      body,
      HEADER,
    ).subscribe((response) =>
      this.gift$.next(
        (response.response as GiftQueryResponse).data.allGifts.edges,
      ),
    );
  }

  addGift(
    data: GiftNodeEdgeMutation,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails?.map((pd) => {
      pd.date = data.date;
      pd.movementForId = this.companyService?.selectedBussiness$.getValue()?.id;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });

    Api.graphql<AddGiftVariable, {}>(
      API_URL,
      {
        input: {
          gift: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
            branchId: this.companyService?.selectedBussiness$.getValue()?.id,
          },
        },
      },
      giftMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.onInit();
      },
      (error) => onError(error),
    );
  }
}

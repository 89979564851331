import * as React from 'react';
import { Row, Col, DatePicker, Divider, Button } from 'antd';
import moment from 'moment';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { TSInput } from '../../common/TSInput';
import { BranchEdges } from '../../master/Branch/constants';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { Label } from '../../common/TSLabel';
import { AcceptProductionReceivingError } from './constants';
import { ProductionTransferInput } from '../../../schema';
import { ConversionDropDown } from '../Conversion/ConversionDropdown';

export const PendingProductionReceivingForm: React.FunctionComponent<Props> = (
  props,
) => {
  return (
    <Row>
      <Col span={5}>
        <Label title="Transfer Date">
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={5}>
        <Label title="Receiving Date">
          <DatePicker
            value={
              props.values.receivingDate
                ? moment(props.values.receivingDate)
                : moment(moment().format('YYYY-MM-DD'))
            }
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={14}>
        <TSAutoComplete
          error={''}
          inputProps={{ name: 'fromBranchId', addonBefore: 'From Location' }}
          dataSource={props.branch}
          key="id"
          name="fromBranchId"
          onValueSelect={() => {}}
          displayKey="name"
          textValue={getValue(props.branch, props.values.fromBranchId)}
          allowClear
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={7}>Product</Col>
        <Col span={2}>Quantity</Col>
        <Col span={3}>Cost Price</Col>
        <Col span={3}>Amount</Col>
        <Col span={1}>Accept</Col>
        <Col span={1}>Reject</Col>
        <Col span={1}>Status</Col>
        <Col span={5}>Remarks</Col>
        <Divider />
      </Row>
      {props.values.conversionDetails.map((conversion, index) => (
        <div key={'conversions' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={7}>
              <div>
                <ConversionDropDown
                  value={conversion.conversionId || ''}
                  allowClear
                />
                <span style={{ color: 'red' }}>
                  {props.error.conversionDetails[index].conversionId}
                </span>
              </div>
            </Col>
            <Col span={2}>
              <TSInput
                error={''}
                type="number"
                value={conversion.quantity || 0}
                name="quantity"
                placeholder="Quantity"
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={''}
                type="number"
                value={conversion.cost || 0}
                name="cost"
                placeholder="Cost Price"
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={''}
                type="number"
                value={conversion.amount || 0}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={1}>
              <Button
                type="default"
                shape="circle"
                icon="check"
                style={{ marginLeft: '5px', marginTop: '5px' }}
                onClick={(e) =>
                  props.conversionChangeHandler(
                    { target: { name: 'status', value: 'ACCEPTED' } },
                    index,
                  )
                }
              />
            </Col>
            <Col span={1}>
              <Button
                type="danger"
                shape="circle"
                icon="close"
                style={{ marginLeft: '5px', marginTop: '5px' }}
                onClick={(e) =>
                  props.conversionChangeHandler(
                    { target: { name: 'status', value: 'REJECTED' } },
                    index,
                  )
                }
              />
            </Col>
            <Col span={1} style={{ marginTop: '5px' }}>
              <span
                style={
                  conversion.status === 'ACCEPTED'
                    ? {
                        color: 'green',
                        fontSize: '23px',
                      }
                    : conversion.status === 'REJECTED'
                    ? {
                        color: 'red',
                        fontSize: '23px',
                      }
                    : { color: 'blue', fontSize: '23px' }
                }
              >
                {conversion.status?.[0]}
              </span>
            </Col>
            <Col span={5}>
              <TSInput
                error={props.error.conversionDetails[index].remark}
                type="text"
                value={conversion.remark || ''}
                name="remark"
                onChange={(e) => props.conversionChangeHandler(e, index)}
                placeholder="Remarks"
                disabled={conversion.status !== 'REJECTED'}
                required
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />
      <Col>
        <TSInput
          error={''}
          type="text"
          value={props.values.remarks}
          name="remarks"
          addonBefore="Remarks"
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: ProductionTransferInput;
  error: AcceptProductionReceivingError;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  conversionChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
}

import { BehaviorSubject } from 'rxjs';
import Api from 'requestapijs';
import {
  PurchaseEdges,
  purchaseQuery,
  PurchaseQueryResponse,
  PurchaseQueryVariable,
  AddPurchaseVariable,
  PurchaseNodeEdgeMutation,
  purchaseMutation,
} from '../components/Purchase/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import { DateService } from './DateService';

export class PurchaseService {
  static instance;

  constructor() {
    if (PurchaseService.instance) {
      return PurchaseService.instance;
    } else {
      PurchaseService.instance = this;
    }
    this.onInit();
  }

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  public readonly purchase$ = new BehaviorSubject<PurchaseEdges[]>([]);
  companyService = new CompanyService();
  dateService = new DateService();

  getAll(companyId: string) {
    Api.graphql<PurchaseQueryVariable, PurchaseQueryResponse>(
      API_URL,
      { companyId, date_Lte: this.dateService?.date$.value },
      purchaseQuery,
      HEADER,
      (response) => this.purchase$.next(response.data.allPurchases.edges),
      (error) => error,
    );
  }

  addPurchase(
    data: PurchaseNodeEdgeMutation,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete data.purchaseToReturn;
    delete data.vendor;
    data.productDetails = data.productDetails?.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });
    Api.graphql<AddPurchaseVariable, {}>(
      API_URL,
      {
        input: {
          purchase: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      purchaseMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.companyService?.company$.value?.id &&
          this.getAll(this.companyService?.company$.value.id);
      },
      (error) => onError(error),
    );
  }
}

import * as React from 'react';
import { notification, Button, Popconfirm } from 'antd';
import { productionTransferObj } from './../ProductionTransfer/constants';
import { BranchEdges } from '../../master/Branch/constants';
import { BranchService } from '../../../service/BranchService';
import {
  ConversionDetailNodeConnection,
  ConversionNode,
  ProductionTransferInput,
  HsnNode,
  JobOrderNode,
  TransferNodeConnection,
  ConversionDetailInput,
} from '../../../schema';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { ProductionTransferService } from '../../../service/ProductionTransferService';
import { ProductionReceivingForm } from './ProductionReceivingForm';
import { VendorEdges } from '../../master/vendor/constants';
import { VendorService } from '../../../service/VendorService';
import moment from 'moment';
import {
  ProductionReceivingError,
  productionReceivingError,
} from './constants';

export class AddProductionReceiving extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productionReceiving: JSON.parse(
        JSON.stringify({
          ...productionTransferObj,
          conversionDetails: [
            {
              ...productionTransferObj.conversionDetails[0],
              status: 'ACCEPTED',
            },
          ],
        }),
      ),
      error: JSON.parse(JSON.stringify(productionReceivingError)),
      branch: [],
      buttonDisabled: false,
      conversionDetails: [],
      hsnDetails: [],
      jobOrder: null,
      vendors: [],
      forJobOrder: false,
    };
  }

  productionReceivingService = new ProductionTransferService();
  branchService = new BranchService();
  vendorService = new VendorService();

  componentDidMount() {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const productionReceiving = { ...this.state.productionReceiving };
    productionReceiving[e.target.name] = e.target.value;
    if (e.target.name === 'fromVendor') {
      productionReceiving.jobOrderId = null;
      productionReceiving.vendorForId = null;
      productionReceiving.fromBranchId = null;
      productionReceiving.conversionDetails = JSON.parse(
        JSON.stringify([
          {
            ...productionTransferObj.conversionDetails[0],
            status: 'ACCEPTED',
          },
        ]),
      );
    }
    this.setState({ productionReceiving });
  };

  onChangeForJobOrder = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState.forJobOrder = e.target.value as any as boolean;
    nextState.productionReceiving.jobOrderId = null;
    nextState.productionReceiving.vendorForId = null;
    nextState.productionReceiving.fromBranchId = null;
    nextState.productionReceiving.conversionDetails = JSON.parse(
      JSON.stringify([
        {
          ...productionTransferObj.conversionDetails[0],
          status: 'ACCEPTED',
        },
      ]),
    );
    this.setState(nextState);
  };

  checkError = () => {
    const productionReceiving = { ...this.state.productionReceiving };
    const error = { ...this.state.error };
    let isError = false;
    if (this.state.forJobOrder && !productionReceiving.jobOrderId) {
      error.jobOrderId = `This is a required field`;
      isError = true;
    } else if (
      !this.state.forJobOrder &&
      productionReceiving.fromVendor &&
      !productionReceiving.vendorForId
    ) {
      error.vendorForId = `This is a required field`;
      isError = true;
    } else if (
      !this.state.forJobOrder &&
      !productionReceiving.fromVendor &&
      !productionReceiving.fromBranchId
    ) {
      error.fromBranchId = `This is a required field`;
      isError = true;
    }
    this.setState({ error });
    return isError;
  };

  checkConversionDetailError = () => {
    const conversionDetails: ConversionDetailInput[] = {
      ...this.state.productionReceiving.conversionDetails,
    };
    let isError = false;
    const error = { ...this.state.error };
    error.conversionDetails.map((econ, index) => {
      Object.keys(econ).forEach((conkey) => {
        if (!conversionDetails[index][conkey]) {
          isError = true;
          econ[conkey] = `This is a required field`;
        }
      });
      return econ;
    });
    this.setState({ error });
    return isError;
  };

  conversionDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.productionReceiving.conversionDetails[index][e.target.name] =
      e.target.value;
    nextState.productionReceiving.conversionDetails[index].amount = Number(
      (
        Number(
          nextState.productionReceiving.conversionDetails[index].quantity,
        ) * Number(nextState.productionReceiving.conversionDetails[index].cost)
      ).toFixed(2),
    );
    this.setState(nextState);
  };

  conversionObjChangehandler = (
    conversionObj: ConversionNode,
    index: number,
  ) => {
    const nextState = { ...this.state };
    nextState.conversionDetails[index] = conversionObj;
    nextState.productionReceiving.conversionDetails[index].cost =
      conversionObj.netCost;
    this.setState(nextState);
  };

  updateConsversionsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.productionReceiving.conversionDetails.push(
        JSON.parse(
          JSON.stringify({
            ...productionTransferObj.conversionDetails[0],
            status: 'ACCEPTED',
          }),
        ),
      );
      nextState.error.conversionDetails.push({
        conversionId: '',
        cost: '',
        quantity: '',
        amount: '',
        hsnId: '',
      });
    } else {
      nextState.productionReceiving.conversionDetails.splice(index, 1);
      nextState.error.conversionDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  jobOrderObjChangehandler = (jobOrderObj: JobOrderNode) => {
    const nextState = { ...this.state };
    nextState.jobOrder = jobOrderObj;
    nextState.productionReceiving.jobOrderId = jobOrderObj.id;
    nextState.productionReceiving.vendorForId = (
      jobOrderObj.transfers as any as TransferNodeConnection
    ).edges[0].node?.vendorFor?.id;
    nextState.productionReceiving.fromBranchId = (
      jobOrderObj.transfers as any as TransferNodeConnection
    ).edges[0].node?.toLocation?.id;
    nextState.productionReceiving.conversionDetails = (
      jobOrderObj.orderFor as any as ConversionDetailNodeConnection
    ).edges.map((orderObj) => {
      return {
        ...nextState.productionReceiving.conversionDetails[0],
        conversionId: orderObj.node?.conversion?.id,
        cost: orderObj.node?.conversion?.netCost,
      };
    });

    nextState.productionReceiving.conversionDetails.forEach(() => {
      if (
        nextState.productionReceiving.conversionDetails.length >
        nextState.error.conversionDetails.length
      ) {
        nextState.error.conversionDetails.push({
          conversionId: '',
          cost: '',
          quantity: '',
          amount: '',
          hsnId: '',
        });
      }
    });
    this.setState(nextState);
  };

  hsnObjChangehandler = (hsnObj: HsnNode, index: number) => {
    const nextState = { ...this.state };
    const cost = nextState.productionReceiving.conversionDetails[index].cost;
    nextState.hsnDetails[index] = hsnObj;
    nextState.productionReceiving.conversionDetails[index].gstRate =
      hsnObj.hsnWithSameCode.length > 1
        ? hsnObj.hsnWithSameCode.find(
            (hsn) =>
              cost &&
              hsn.maxValue !== undefined &&
              hsn.maxValue >= cost &&
              hsn.minValue !== undefined &&
              hsn.minValue <= cost,
          )?.gst
        : hsnObj.hsnWithSameCode[0].gst;
    this.setState(nextState);
  };

  saveProductionReceiving = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkConversionDetailError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.productionReceivingService?.addProductionTransfer(
      {
        ...this.state.productionReceiving,
        toBranchId:
          this.props.business.type === 'company'
            ? null
            : this.props.business.id,
        toHeadOffice: this.props.business.type === 'company' ? true : false,
        status: 'ACCEPTED',
        receivingDate: moment().format('YYYY-MM-DD'),
      },
      (response) => {
        this.setState({
          productionReceiving: JSON.parse(
            JSON.stringify({
              ...productionTransferObj,
              conversionDetails: [
                {
                  ...productionTransferObj.conversionDetails[0],
                  status: 'ACCEPTED',
                },
              ],
            }),
          ),
          error: JSON.parse(JSON.stringify(productionReceivingError)),
          buttonDisabled: false,
        });
        notification.success({
          message:
            'Production Receiving ' + this.state.productionReceiving.id
              ? 'Updated'
              : 'Added',
          description: `Production Receiving was successfully ${
            this.state.productionReceiving.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('3');
      },
      (error) => {
        notification.error({
          message:
            'Production Receiving ' + this.state.productionReceiving.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      productionReceiving: JSON.parse(
        JSON.stringify({
          ...productionTransferObj,
          toHeadOffice: this.props.business.type === 'branch' ? true : false,
        }),
      ),
    });
  };

  render() {
    return (
      <div>
        <ProductionReceivingForm
          values={this.state.productionReceiving}
          error={this.state.error}
          onChange={this.onChange}
          conversionDetailChangeHandler={this.conversionDetailChangeHandler}
          branch={this.state.branch}
          company={this.props.company}
          business={this.props.business}
          hsnObjChangehandler={this.hsnObjChangehandler}
          vendors={this.state.vendors}
          jobOrderObjChangehandler={this.jobOrderObjChangehandler}
          onChangeForJobOrder={this.onChangeForJobOrder}
          forJobOrder={this.state.forJobOrder}
          conversionObjChangehandler={this.conversionObjChangehandler}
          updateconversionDetailsRow={this.updateConsversionsRow}
          jobOrder={this.state.jobOrder}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveProductionReceiving}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business: Bussiness;
  handleTabChange: (key) => void;
}

interface State {
  productionReceiving: ProductionTransferInput;
  error: ProductionReceivingError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  conversionDetails: ConversionNode[];
  hsnDetails: HsnNode[];
  jobOrder: JobOrderNode | null;
  vendors: VendorEdges[];
  forJobOrder: boolean;
}

import * as React from 'react';
import { Table, Tabs, Icon, Spin, Skeleton, Row, Col, DatePicker } from 'antd';
import { checkAddPermission } from '../helper';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { QuotationNodeEdge, QuotationNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../common/TSInput';
import { QuotationService } from '../../service/QuotationService';
import { quotationQuery } from './constants';
import { AddQuotation } from './AddQuotation';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';
import CheckableTag from 'antd/lib/tag/CheckableTag';

export class Quotation extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedQuotation: null,
      company: null,
      after: '',
      currentPage: 1,
      name: '',
      startDate:
        moment().get('month') < 3
          ? `${moment().subtract(1, 'year').format('YYYY')}-04-01`
          : `${moment().get('year')}-04-01`,
      endDate: moment().format('YYYY-MM-DD'),
      user: null,
    };
  }

  quotationService = new QuotationService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedQuotation: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  quotationColumn = () => {
    const quotationColumns = [
      {
        title: 'Quotation  No',
        dataIndex: 'node.quotationNumber',
        key: 'QuotationNo',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({ selectedQuotation: obj, activeKey: '2' });
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (text) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Customer',
        dataIndex: 'node.customer.name',
        key: 'customer',
      },
    ];
    return quotationColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) return <Spin />;

    const {
      company,
      after,
      currentPage,
      activeKey,
      name,
      selectedQuotation,
      startDate,
      endDate,
    } = this.state;
    const variables = {
      companyId: company.id,
      activeKey,
      date_Gte: startDate,
      date_Lte: endDate,
      name,
      after,
    };
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Quotation{' '}
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={8}>
              <TSInput
                error=""
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                addonBefore="Search"
                placeholder="Enter Customer Name"
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={quotationQuery}
            variables={variables}
            render={(
              response: { allQuotation: QuotationNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (error) return <h3>Something Went Wrong!!</h3>;
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allQuotation.edges}
                    columns={this.quotationColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allQuotation.pageInfo.endCursor,
                        response.allQuotation.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user &&
          this.companyService?.company$.value &&
          checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Quotation{' '}
                </span>
              }
              key="2"
            >
              <AddQuotation
                quotation={selectedQuotation?.node}
                handleTabChange={this.handleTabChange}
                company={this.companyService?.company$.value}
              />
            </Tabs.TabPane>
          )}
        {/* {selectedQuotation && activeKey === '3' && <Tabs.TabPane tab={<span><Icon type='file' />Receipt</span>} key='3'>
          <PDFViewer style={{ width: '100%', height: '1000px' }}>
            <QuotationRecieptHT
              Quotation={selectedQuotation?.node}
              company={company}
            />
          </PDFViewer>
        </Tabs.TabPane>} */}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedQuotation: QuotationNodeEdge | null;
  company: CompanyNodeEdge | null;
  name: string;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  user?: UserNodeEdge | null;
}

interface Props {}

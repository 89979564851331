import * as React from 'react';
import { DatePicker, Divider, Spin, Row, Col, Button, Skeleton } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { PurchaseNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { VENDOR_WISE_PURCHASE_REPORT_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { VendorEdges } from '../master/vendor/constants';
import { VendorService } from '../../service/VendorService';
import { vendorWisePurchaseQuery } from './constants';

export class VendorWisePurchaseReport extends React.Component<Props, State> {
  vendorService = new VendorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      vendors: [],
      vendorId: '',
    };
  }

  componentDidMount() {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (value) => value && moment(value).format('DD-MM-YYYY'),
      sortingValue: (value) => value,
    },
    {
      title: 'Bill No',
      key: 'billNo',
      dataIndex: 'node.billNo',
      sortingValue: (value) => value,
    },
    {
      title: 'Vendor Invoice No',
      key: 'vendorInvoiceNo',
      dataIndex: 'node.vendorInvoiceNo',
      sortingValue: (value) => value,
    },
    {
      title: 'Vendor Invoice Date',
      key: 'vendorInvoiceDate',
      dataIndex: 'node.vendorInvoiceDate',
      sortingValue: (value) => value,
    },
    {
      title: 'Purchase Order No',
      key: 'purchaseOrderNo',
      dataIndex: 'node.purchaseOrderNo',
      render: (value, record) =>
        value || record.node?.margin?.challan?.purchaseOrder?.purchaseOrderNo,
      sortingValue: (value) => value,
    },
    {
      title: 'Purchase Order Date',
      key: 'purchaseOrderDate',
      dataIndex: 'node.purchaseOrderDate',
      render: (value, record) =>
        value || record.node?.margin?.challan?.purchaseOrder?.date,
      sortingValue: (value) => value,
    },
    {
      title: 'Purchase Order Quantity',
      key: 'purchaseOrderQty',
      dataIndex: 'node.purchaseOrderQty',
      sortingValue: (value) => value,
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(VENDOR_WISE_PURCHASE_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `vendor-wise-${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'stock-in'
              : 'purchase'
          }-${new Date()}`,
          `VENDOR WISE ${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'STOCK IN'
              : 'PURCHASE'
          } REPORT`,
          this.props.company.name,
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          this.state.vendors.find(
            (vendor) => vendor.node.id === this.state.vendorId,
          )?.node.name || '',
        ),
      );
  };

  render() {
    const { startDate, endDate, currentPage, after, vendors, vendorId } =
      this.state;
    const {
      company: { id: companyId },
      activeKey,
    } = this.props;
    const variables = {
      startDate,
      endDate,
      companyId,
      vendorId,
      after,
      activeKey,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={startDate ? moment(startDate) : null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={endDate ? moment(endDate) : null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSAutoComplete
              error=""
              textValue={getValue(vendors, vendorId)}
              dataSource={vendors}
              onValueSelect={this.onChange}
              name="vendorId"
              key="id"
              inputProps={{ name: 'vendorId', placeholder: 'Select Vendor' }}
              displayKey="name"
              allowClear
            />
          </Col>
        </Row>
        <Divider />
        {vendorId && startDate && endDate && (
          <GraphqlQuery
            queryString={vendorWisePurchaseQuery}
            variables={variables}
            render={(
              response: { allPurchases: PurchaseNodeConnection },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allPurchases.edges}
                    columns={this.columns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allPurchases.pageInfo.endCursor,
                        response.allPurchases.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
        {vendorId && startDate && endDate && (
          <Button
            key="download"
            style={{ float: 'right' }}
            icon="download"
            onClick={() => this.downloadCsv(variables)}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
}

interface State {
  startDate: string;
  endDate: string;
  currentPage: number;
  after: string;
  vendors: VendorEdges[];
  vendorId: string;
}

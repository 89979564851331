import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { ProductNodeConnection, ProductNodeEdge } from '../../../schema';
import { OptionProps, SelectValue } from 'antd/lib/select';
import { CompanyNodeEdge } from '../Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  product?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface ProductDropDownProps extends AutoCompleteProps {
  extrafields: string[];
  company: CompanyNodeEdge | null;
  productTypeIn?: string;
  notEditable?: boolean;
}

export class ProductDropDown extends React.Component<
  ProductDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };
  timeout: NodeJS.Timer | undefined;

  constructor(props: Readonly<ProductDropDownProps>) {
    super(props);

    this.state = {
      barcode: '',
      touched: false,
      variables: null,
    };
  }

  componentWillMount() {
    const { barcode } = this.state;
    const { company, value, productTypeIn } = this.props;
    const variables = {
      after: '',
      barcode,
      companyId: company?.id,
      id: value && !barcode ? value : '',
      productTypeIn,
    };

    this.setState({ variables });
  }

  updateVariables = () => {
    const { barcode } = this.state;
    const { company, value, productTypeIn } = this.props;
    const variables = {
      after: '',
      barcode,
      companyId: company?.id,
      id: value && !barcode ? value : '',
      productTypeIn,
    };

    this.setState({ variables });
  };

  debounce = (updateVariables, delay: number) => {
    this.timeout && clearTimeout(this.timeout);

    this.timeout = setTimeout(updateVariables, delay);
  };

  getBarcodeListWithName = (product?: ProductNodeEdge) => {
    if (!product) {
      return;
    }

    return [
      product.node?.oldBarcodeId,
      product.node?.newBarcodeId,
      product.node?.name,
      product.node?.hsn?.hsnCode,
    ].join(', ');
  };

  value = (products: ProductNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.barcode;
    }

    return this.getBarcodeListWithName(
      products.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { extrafields, onSelect, notEditable, company } = this.props;
    this.debounce(this.updateVariables, 400);
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query ProductDropDown($after: String!, $id: ID, $barcode: String, $companyId: ID!, $productTypeIn: String) {
          allProducts(first: 5, after: $after, id: $id, barcodeBothContains: $barcode, companyId: $companyId, productType_In: $productTypeIn) {
            edges {
              node {
                id
                name
                oldBarcodeId
                newBarcodeId
                hsn{
                  hsnCode
                }
                ${extrafields.join('\n')}
              }
            }
          }
        }`}
        variables={this.state.variables}
        render={(
          response: { allProducts: ProductNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          response.allProducts.edges.map((product) => {
            product.node?.id && localStorage.setItem('myid', product.node?.id);
          });
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Product"
                dataSource={response.allProducts.edges.map((product) => (
                  <Option
                    key={product.node?.id}
                    display={this.getBarcodeListWithName(product)}
                    label={product.node}
                    product={product}
                    style={{ whiteSpace: 'normal' }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid #555',
                        fontWeight: 'bolder',
                      }}
                    >
                      {product.node?.name}
                    </div>
                    <span style={{ marginRight: '5px', color: '#001529' }}>
                      Old Barcode: {product.node?.oldBarcodeId}
                    </span>{' '}
                    |
                    <span style={{ marginLeft: '5px', color: '#f60' }}>
                      New Barcode: {product.node?.newBarcodeId}
                    </span>{' '}
                    |
                    <span style={{ marginLeft: '5px', color: '#f60' }}>
                      Hsn:{product.node?.hsn?.hsnCode}
                    </span>
                  </Option>
                ))}
                value={this.value(response.allProducts.edges)}
                optionLabelProp="display"
                style={{ width: '100%' }}
                onSearch={(product) =>
                  notEditable
                    ? {}
                    : this.setState({ barcode: product, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    barcode:
                      this.getBarcodeListWithName((f as any).props.product) ||
                      '',
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  barcode: string;
  touched: boolean;
  variables: {
    after: string;
    barcode: string;
    companyId?: string;
    id: SelectValue | string;
    productTypeIn?: string;
  } | null;
}

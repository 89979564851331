import { CompanyNodeEdge } from '../Company/constants';

export const category = [
  'EMPORIUM',
  'STOCK_POINT',
  'REGIONAL_OFFICE',
  'FINISHING_UNIT',
  'CLUSTER',
  'PWCS',
  'SHGS',
  'CFC',
  'MELA',
];
export const parentCategory = [
  'EMPORIUM',
  'STOCK_POINT',
  'REGIONAL_OFFICE',
  'FINISHING_UNIT',
  'CLUSTER',
];

export interface BranchNodeEdge {
  id?: string;
  name?: string;
  initials?: string;
  address?: string;
  state?: string;
  contactNo?: string;
  email?: string;
  gstin?: string;
  companyId?: string | null;
  companyName?: string;
  parentLocationId?: string | null;
  category?: string;
  incharge?: string;
  parentCategory?: string;
  parentLocation?: BranchNodeEdge;
  company?: CompanyNodeEdge;
  type?: string;
  favourOf?: string;
}

export interface BranchError {
  name: string;
  state: string;
  contactNo: string;
  gstin: string;
  category: string;
}

export const branchObject: BranchNodeEdge = {
  name: '',
  initials: '',
  address: '',
  state: '',
  contactNo: '',
  email: '',
  gstin: '',
  category: '',
  incharge: '',
  parentCategory: '',
  parentLocationId: null,
  companyId: null,
  type: '',
  favourOf: '',
};

export const branchErrorObject: BranchError = {
  name: '',
  state: '',
  contactNo: '',
  gstin: '',
  category: '',
};

export const branchMutation = `
mutation addBranch(
  $input: CreateBranchInput!
) {
  createBranch(input: $input){
    newBranch {
      id
      name
    }
  }
}
`;

export interface AddBranchVariables {
  input: {
    branch: BranchNodeEdge;
  };
}

export interface BranchEdges {
  cursor: string;
  node: BranchNodeEdge;
}

// export constant branchQuery= `

// `;

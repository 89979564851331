import * as React from 'react';
import { DatePicker, Divider, Button, Spin, Col } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { cashBankReportQuery } from './constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { BranchDropDown } from '../master/Branch/BranchDropdown';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyService } from '../../service/CompanyService';
import { CASH_BANK_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class CashReport extends React.Component<Props, State> {
  companyService = new CompanyService();

  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      branchId: '',
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = (headerList: string[]) => {
    const columns: any[] = [];
    headerList.forEach((header, index) => {
      columns.push({
        title: header,
        key: header,
        render: (text, obj) => obj[index],
      });
    });
    return columns;
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CASH_BANK_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `cash-bank-report-${
            this.props.branch?.name
          }-${new Date().toISOString()}`,
          'CASH BANK REPORT',
          this.props.company.name,
          this.props.branch?.name || '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    if (!this.state.startDate || !this.state.endDate) {
      return <Spin />;
    }
    const companyId = this.props.company.id;
    const branchId = this.props.branch
      ? this.props.branch.id
      : this.state.branchId;
    const { startDate, endDate } = this.state;
    const variables = { companyId, branchId, startDate, endDate };
    return (
      <div>
        {this.companyService?.selectedBussiness$.value?.type !== 'branch' && (
          <Col span={5}>
            <BranchDropDown
              onSelect={(e) =>
                this.onChange({
                  target: { name: 'branchId', value: e.toString() },
                })
              }
              allowClear
            />
          </Col>
        )}
        <Col span={5}>
          <DatePicker
            format="DD-MM-YYYY"
            placeholder="Start Date"
            value={moment(this.state.startDate)}
            onChange={(date) =>
              this.onChange({
                target: {
                  name: 'startDate',
                  value: date && moment(date).format('YYYY-MM-DD'),
                },
              })
            }
            disabledDate={(currentDate) => {
              if (!currentDate || !this.state.endDate) {
                return false;
              }
              return (
                currentDate.valueOf() > moment(this.state.endDate).valueOf()
              );
            }}
            style={{ marginTop: '5px', width: '100%' }}
          />
        </Col>
        <Col span={5}>
          <DatePicker
            format="DD-MM-YYYY"
            placeholder="End Date"
            value={moment(this.state.endDate)}
            onChange={(date) =>
              this.onChange({
                target: {
                  name: 'endDate',
                  value: date && moment(date).format('YYYY-MM-DD'),
                },
              })
            }
            disabledDate={(currentDate) => {
              if (!currentDate || !this.state.startDate) {
                return false;
              }
              return (
                currentDate.valueOf() < moment(this.state.startDate).valueOf()
              );
            }}
            style={{ marginTop: '5px', width: '100%' }}
          />
        </Col>
        <Divider />
        {branchId !== '' && (
          <GraphqlQuery
            queryString={cashBankReportQuery}
            variables={variables}
            render={(
              response: { cashBankReport: string[][] },
              error,
              loading,
            ) => {
              if (loading) {
                return <Spin />;
              }
              if (response && companyId) {
                const dataList = response.cashBankReport.slice(1);
                return [
                  <Table
                    dataSource={dataList}
                    columns={this.columns(response.cashBankReport[0])}
                    key="table"
                    rowKey="id"
                    pagination={{ pageSize: 13 }}
                  />,
                  <Button
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() => this.downloadCsv(variables)}
                    key="button"
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch?: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  branchId: string;
}

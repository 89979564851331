import { ProductCategoryNodeEdge } from '../ProductCategories/constants';
import { UnitNodeEdge } from '../Unit/constants';
import { HsnNode } from '../../../schema';
import { ProductSubCategoryNode } from '../ProductSubCategories/constants';
import { VendorNode } from '../vendor/constants';
import { DiscountCategoryNode } from '../DiscountCategory/constants';

export interface ProductNodeEdge {
  id: string | null;
  categoryId: string | null;
  category?: ProductCategoryNodeEdge;
  subCategory?: ProductSubCategoryNode;
  subCategoryId?: string | null;
  oldBarcodeId: string;
  newBarcodeId: string;
  name: string;
  unitId: string;
  unit?: UnitNodeEdge;
  colour: string;
  purchaseRate: number | null;
  sellingRate: number | null;
  hsnCode: string;
  hsnId?: string | null;
  hsn?: HsnNode;
  cgstRate: number | null;
  sgstRate: number | null;
  gstRate: number | null;
  division: string;
  section: string;
  materialType: string;
  productType: 'FINISHED' | 'SEMI_FINISHED' | 'RAW_MATERIAL' | null;
  reorderLevel: number | null;
  companyId?: string;
  stock?: string;
  quantityPerPiece?: number | null;
  remark?: string;
  vendorId: string | null;
  vendor?: VendorNode;
  waiver?: string;
  design?: string;
  dimension?: string;
  discountCategory?: DiscountCategoryNode;
  discountCategoryId?: string | null;
}

export interface ProductError {
  categoryId: string;
  oldBarcodeId: string;
  newBarcodeId: string;
  name: string;
  unitId: string;
  colour: string;
  purchaseRate: string;
  sellingRate: string;
  hsnCode: string;
  hsnId: string;
  cgstRate: string;
  sgstRate: string;
  gstRate: string;
  division: string;
  section: string;
  materialType: string;
  productType: string;
}

export const productObject: ProductNodeEdge = {
  id: null,
  categoryId: null,
  subCategoryId: null,
  oldBarcodeId: '',
  newBarcodeId: '',
  name: '',
  unitId: '',
  colour: '',
  purchaseRate: null,
  sellingRate: null,
  hsnCode: '',
  hsnId: null,
  cgstRate: null,
  sgstRate: null,
  gstRate: null,
  division: '',
  section: '',
  materialType: '',
  reorderLevel: null,
  productType: null,
  quantityPerPiece: null,
  vendorId: null,
  discountCategoryId: null,
};

export const productErrorObject: ProductError = {
  categoryId: '',
  oldBarcodeId: '',
  newBarcodeId: '',
  name: '',
  unitId: '',
  colour: '',
  purchaseRate: '',
  sellingRate: '',
  hsnCode: '',
  hsnId: '',
  cgstRate: '',
  sgstRate: '',
  gstRate: '',
  division: '',
  section: '',
  materialType: '',
  productType: '',
};

export const productQuery = `
  query ProductList(
    $companyId: ID!,
    $after: String,
    $barcode: String,
    $sellingRate: Float,
    $purchaseRate: Float
  ) {
    allProducts(
      companyId: $companyId,
      first: 20,
      after: $after,
      barcodeBothContains: $barcode,
      sellingRate: $sellingRate,
      purchaseRate: $purchaseRate
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          category {
            id
            name
          }
          subCategory {
            id
            name
          }
          oldBarcodeId
          newBarcodeId
          name
          unit {
            id
            name
          }
          colour
          purchaseRate
          sellingRate
          hsnCode
          hsn {
            id
            hsnCode
            gst
          }
          cgstRate
          sgstRate
          gstRate
          division
          section
          materialType
          productType
          reorderLevel
          quantityPerPiece
          vendor {
            id
            name
          }
          waiver
          design
          dimension
          discountCategory {
            id
            name
          }
        }
      }
    }
  }
`;

export const productWithBarcodeQuery = `
  query ProductList($companyId: ID!, $barcode: String) {
    allProducts(companyId: $companyId, barcodeBothExact: $barcode) {
      edges {
        node {
          id
          category {
            id
            name
          }
          oldBarcodeId
          newBarcodeId
          name
        }
      }
    }
  }
`;

export const stocksQuery = `
query StockList($companyId: ID!, $branchId: ID, $date: Date!, $first: Int!, $after: String!, $barcode: String, $mostUsedProduct: Boolean) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode, mostUsedProduct: $mostUsedProduct) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        category {
          name
        }
        quantityPerPiece
        unit {
          name
        }
        oldBarcodeId
        newBarcodeId
        stock(branchId: $branchId, companyId: $companyId, date: $date) {
          openingStock
          transfer
          receiving
          sale
          saleReturn
          approval
          approvalReturn
          damage
          gift
          purchase
          purchaseReturn
          codeConversion
          totalStocks
          mrp
          totalStocksValue
          physicalStock
          purchaseRate
          totalStocksPurchaseValue
        }
      }
    }
  }
}
`;

export const newStocksQuery = `
query NewStockList($companyId: ID!, $branchId: ID, $date: Date!, $first: Int!, $after: String!, $barcode: String, $mostUsedProduct: Boolean) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode, mostUsedProduct: $mostUsedProduct) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        division
        category {
          name
        }
        quantityPerPiece
        unit {
          name
        }
        oldBarcodeId
        newBarcodeId
        newStock(branchId: $branchId, companyId: $companyId, date: $date) {
          openingStock
          transfer
          receiving
          sale
          saleReturn
          saleCancel
          approval
          approvalReturn
          damage
          gift
          purchase
          purchaseReturn
          codeConversion
          totalStocks
          mrp
          totalStocksValue
          physicalStock
          purchaseRate
          totalStocksPurchaseValue
          date
          physicalStockPresent
        }
      }
    }
  }
}`;

export const stockWithDateRangeQuery = `
query StockListWithDateRange($companyId: ID!, $branchId: ID, $startDate: Date!, $date: Date!, $first: Int!, $after: String!, $barcode: String, $mostUsedProduct: Boolean) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode, mostUsedProduct: $mostUsedProduct) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        category {
          name
        }
        division
        unit {
          name
        }
        oldBarcodeId
        newBarcodeId
        sellingRate
        purchaseRate
        stockDateRange(branchId: $branchId, companyId: $companyId, startDate: $startDate, endDate: $date) {
          date
          physicalStockPresent
          openingStock
          inwardQty
          outwardQty
          totalStocks
        }
      }
    }
  }
}`;

export const jharcraftCategoryWiseStockReportQuery = `
query JharcraftCategoryWiseStockReport($companyId: ID!, $branchId: ID, $startDate: Date!, $date: Date!, $first: Int!, $after: String!, $barcode: String, $mostUsedProduct: Boolean, $categoryId: ID) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode, mostUsedProduct: $mostUsedProduct, categoryId: $categoryId) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        category {
          name
        }
        division
        unit {
          name
        }
        oldBarcodeId
        newBarcodeId
        sellingRate
        stockDateRange(branchId: $branchId, companyId: $companyId, startDate: $startDate, endDate: $date) {
          date
          physicalStockPresent
          openingStock
          inwardQty
          outwardQty
          totalStocks
        }
      }
    }
  }
}`;

export const productStockForCategoryQuery = `
query StockList($companyId: ID!, $categoryId: ID, $branchId: ID, $date: Date!, $after: String!, $barcode: String, $fyStock: Boolean) {
  allProducts(companyId: $companyId, categoryId: $categoryId, after: $after, first: 20, barcodeBothContains: $barcode) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        oldBarcodeId
        newBarcodeId
        stock(branchId: $branchId, companyId: $companyId, date: $date, fyStock: $fyStock) {
          totalStocks
          mrp
          totalStocksValue
        }
      }
    }
  }
}
`;

export const itemWiseSalesBranchQuery = `
query ItemWiseSalesList($companyId: ID, $branchId: ID, $startDate: Date!, $endDate: Date!, $first: Int!, $after: String!, $barcode: String) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        sellingRate
        hsn {
          id
          hsnCode
          gst
        }
        unit {
          name
        }
        itemWiseSalesBranch(branchId: $branchId, startDate: $startDate, endDate: $endDate) {
          productName
          oldBarcodeId
          newBarcodeId
          categoryName
          quantity
          sellingRate
          amount
        }
      }
    }
  }
}
`;

export const itemWiseSalesCentralQuery = `
query ItemWiseSalesList($companyId: ID, $startDate: Date!, $endDate: Date!, $first: Int!, $after: String!, $barcode: String) {
  allProducts(companyId: $companyId, after: $after, first: $first, barcodeBothContains: $barcode) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        oldBarcodeId
        newBarcodeId
        sellingRate
        hsn {
          id
          hsnCode
          gst
        }
        itemWiseSalesCentral(startDate: $startDate, endDate: $endDate) {
          productName
          branchName
          quantity
          totalQuantity
          totalAmount
        }
      }
    }
  }
}
`;

export const itemWisePurchaseQuery = `
query ItemwisePuchaseReport($companyId: ID!, $startDate: Date!, $endDate: Date!, $after: Int!) {
  itemWisePurchase(companyId: $companyId, startDate: $startDate, endDate: $endDate, limit: 10, offset: $after) {
    grcNo
    grcDate
    vendorName
    itemName
    oldBarcode
    newBarcode
    category
    division
    quantity
    purchaseCost
    value
    mrp
    purchaseOrderNo
    purchaseOrderDate
  }
}	
`;

export const reorderLevelQuery = `
query ProductList($companyId: ID!, $categoryId: ID, $date: Date!, $after: String!, $barcode: String) {
  allProducts(companyId: $companyId, categoryId: $categoryId, after: $after, first: 20, barcodeBothContains: $barcode) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        oldBarcodeId
        newBarcodeId
        reorderLevel
        stock(companyId: $companyId, date: $date) {
          totalStocks
        }
      }
    }
  }
}
`;

export const exchangeReportQuery = `
query ExchangeReportQuery($companyId: ID, $after: String, $branchId: ID, $startDate: Date, $endDate: Date) {
  allProductDetails(
    companyId: $companyId,
    movementForId: $branchId,
    movementType: "EXCHANGE",
    first: 20,
    after: $after,
    date_Gte: $startDate,
    date_Lte: $endDate,
    orderBy: "productName",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
        }
        quantity
        rate
        amount
        salesOldProductDetails {
          edges {
            node {
              date
              billNo
            }
          }
        }
      }
    }
  }
}
`;

export const itemWiseReceiptAndSalesStatusQuery = `
query ItemWiseReceiptAndSalesStatus(
  $companyId: ID!,
  $branchId: ID!,
  $startDate: Date!,
  $endDate: Date!,
  $categoryId: ID!,
  $after: Int!,
) {
  itemWiseReceiptAndSalesStatus(
    companyId: $companyId,
    branchId: $branchId,
    startDate: $startDate,
    endDate: $endDate,
    categoryId: $categoryId,
    limit: 20,
    offset: $after,
  ) {
    itemName
    date
    qtyReceived
    costPrice
    mrp
    qtySold
  }
}
`;

export interface AddProductVariables {
  input: {
    product: ProductNodeEdge;
  };
}

export interface ProductQueryResponse {
  data: {
    allProducts: {
      edges: ProductEdges[];
    };
  };
}

export interface ProductEdges {
  cursor: string;
  node: ProductNodeEdge;
}

export const productMutation = `
mutation addProduct(
  $input: CreateProductInput!
) {
  createProduct(input: $input){
    newProduct {
      id
      name
      oldBarcodeId
      newBarcodeId
      sellingRate
    }
  }
}
`;

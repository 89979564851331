import { BehaviorSubject } from 'rxjs';
import {
  TransferEdges,
  TransferNode,
  AddTransferVariable,
  transferMutation,
  transferDeleteMutation,
} from '../components/Transfer/constants';
import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { ProductDetailNodeEdge } from '../components/Sales/constants';
import {
  CommissionDetailsNode,
  DeleteTransferInput,
  DeleteTransferPayload,
} from '../schema';

export class TransferService {
  static instance: TransferService;
  constructor() {
    if (TransferService.instance) {
      return TransferService.instance;
    } else {
      TransferService.instance = this;
    }
  }

  public readonly transfer$ = new BehaviorSubject<TransferEdges[]>([]);
  companyService = new CompanyService();

  addTransfer(
    data: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails.map((p) => {
      p.date = data.date;
      p.movementForId = data.toLocationId;
      p.movementFromId = data.fromLocationId;
      p.companyId = this.companyService?.company$.getValue()?.id;
      delete p.product;
      delete p.movementFor;
      delete p.movementFrom;
      return p;
    });
    Api.graphql<AddTransferVariable, {}>(
      API_URL,
      {
        input: {
          transfer: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      transferMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }

  deleteTransfer(
    id: string,
    onSuccess: (reponse) => void,
    onError?: (response) => void,
  ) {
    Api.graphql<{ input: DeleteTransferInput }, DeleteTransferPayload>(
      API_URL,
      {
        input: { id },
      },
      transferDeleteMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }
}

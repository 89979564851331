import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { CustomerNodeConnection, CustomerNodeEdge } from '../../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyNodeEdge } from '../Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  customer?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface CustomerDropDownProps extends AutoCompleteProps {
  extrafields: string[];
  company: CompanyNodeEdge;
  updateCount: number;
}

export class CustomerDropDown extends React.Component<
  CustomerDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props: Readonly<CustomerDropDownProps>) {
    super(props);

    this.state = {
      name: '',
      touched: false,
    };
  }

  getNumberWithName = (customer?: CustomerNodeEdge) => {
    if (!customer) {
      return;
    }
    return this.props.company.name === 'Jharcraft' ||
      this.props.company.name.toLocaleLowerCase().includes('tiles') ||
      this.props.company?.name.toLowerCase().includes('khadi')
      ? [customer.node?.name, customer.node?.contactNo].join(', ')
      : [
          customer.node?.name,
          customer.node?.contactNo,
          customer.node?.dateOfBirth,
          customer.node?.dateOfAnniversary,
        ].join(', ');
  };

  value = (customers: CustomerNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }
    return this.getNumberWithName(
      customers.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { name } = this.state;
    const { extrafields, company, value, onSelect } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query CustomerDropDown($after: String!, $id: ID, $name: String, $companyId: ID) {
          allCustomers(first: 10, after: $after, id: $id, nameOrNumberIcontains: $name, companyId: $companyId) {
            edges {
              node {
                id
                name
                contactNo
                dateOfBirth
                dateOfAnniversary
                ${extrafields.join('\n')}
              }
            }
          }
        }`}
        variables={{
          after: '',
          name,
          companyId: company.id,
          id: value && !name ? value : '',
          updateCount: this.props.updateCount,
        }}
        render={(
          response: { allCustomers: CustomerNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <div>Something Went Wrong!!</div>;
          }
          if (response) {
            return (
              <div style={{ margin: '5px 0' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Customer"
                  dataSource={response.allCustomers.edges.map((customer) => (
                    <Option
                      key={customer.node?.id}
                      display={this.getNumberWithName(customer)}
                      label={customer.node}
                      customer={customer}
                    >
                      <span style={{ marginRight: '5px', color: '#001529' }}>
                        {customer.node?.name}
                      </span>{' '}
                      |
                      <span style={{ marginLeft: '5px', color: '#f60' }}>
                        {customer.node?.contactNo}
                      </span>
                      {this.props.company.name === 'Jharcraft' ||
                        this.props.company.name
                          .toLocaleLowerCase()
                          .includes('tiles') ||
                        this.state.company?.name
                          .toLowerCase()
                          .includes('khadi') || (
                          <>
                            {' '}
                            |
                            <span style={{ marginLeft: '5px', color: '#f60' }}>
                              {customer.node?.dateOfBirth}
                            </span>{' '}
                            |
                            <span style={{ marginLeft: '5px', color: '#f60' }}>
                              {customer.node?.dateOfAnniversary}
                            </span>
                          </>
                        )}
                    </Option>
                  ))}
                  value={this.value(response.allCustomers.edges)}
                  optionLabelProp="display"
                  style={{ width: '100%' }}
                  onSearch={(customer) =>
                    this.setState({ name: customer, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: this.getNumberWithName((f as any).props.customer),
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
        }}
      />
    );
  }
}

interface State {
  name?: string;
  touched: boolean;
  company?: CompanyNodeEdge;
}

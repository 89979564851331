import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  PurchaseOrderNodeConnection,
  PurchaseOrderNodeEdge,
} from '../../schema';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  purchaseOrder?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface PurchaseOrderDropDownProps extends AutoCompleteProps {
  extrafields: string[];
  activeKey: string;
}

export class PurchaseOrderDropDown extends React.Component<
  PurchaseOrderDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      purchaseOrderNo: '',
      company: null,
      touched: false,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  getOrderNumber = (purchaseOrder?: PurchaseOrderNodeEdge) => {
    if (!purchaseOrder) {
      return;
    }

    return purchaseOrder.node?.purchaseOrderNo;
  };

  value = (purchaseOrders: PurchaseOrderNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.purchaseOrderNo;
    }

    return this.getOrderNumber(
      purchaseOrders.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { purchaseOrderNo, company } = this.state;
    const { extrafields, value, onSelect, activeKey } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query PurchaseOrderDropDown($after: String!, $id: ID, $purchaseOrderNo: String, $companyId: ID) {
          allPurchaseOrders(companyId: $companyId, first: 10, id: $id, purchaseOrderNo_Icontains: $purchaseOrderNo, after: $after, isCancel: false) {
            edges {
              node {
                id
                date
                purchaseOrderNo
                productDetailsWithAvailbleQty {
                  productDetails
                }
                vendor {
                  id
                  name
                }
              }
            }
          }
        }`}
        variables={{
          after: '',
          purchaseOrderNo,
          companyId: company.id,
          activeKey,
          id: value && !purchaseOrderNo ? value : '',
        }}
        render={(
          response: { allPurchaseOrders: PurchaseOrderNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Purchase Order"
                dataSource={response.allPurchaseOrders.edges.map(
                  (purchaseOrder) => (
                    <Option
                      key={purchaseOrder.node?.id}
                      label={purchaseOrder.node}
                      purchaseOrder={purchaseOrder}
                    >
                      {purchaseOrder.node?.purchaseOrderNo}
                    </Option>
                  ),
                )}
                value={this.value(response.allPurchaseOrders.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(purchaseOrder) =>
                  this.setState({
                    purchaseOrderNo: purchaseOrder,
                    touched: true,
                  })
                }
                onSelect={(e, f) => {
                  this.setState({
                    purchaseOrderNo:
                      this.getOrderNumber((f as any).props.purchaseOrder) || '',
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  company: CompanyNodeEdge | null;
  purchaseOrderNo: string;
  touched: boolean;
}

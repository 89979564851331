import * as React from 'react';
import { Button, Col, DatePicker, Row, Skeleton, Table } from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { CustomerNodeConnection } from '../../../schema';
import { Pagination } from 'sha-el-design/lib';
import { CustomerHistoryReportQuery } from './constants';
import { CUSTOMER_HISTORY_REPORT_CSV } from '../../../config';
import { downloadToFile } from '../../helper';
import { CompanyNodeEdge } from '../Company/constants';

export class CustomerHistory extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  customerHistoryColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'node.contactNo',
      key: 'mobile',
    },
    {
      title: 'Total Purchase Amount',
      dataIndex: 'node.totalPurchaseDetails.amount',
      key: 'total',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CUSTOMER_HISTORY_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `customer-history-report-${new Date().toISOString()}`,
          'CUSTOMER HISTORY REPORT',
          this.props.company?.name || '',
          '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.props.companyId) {
      return <Skeleton active />;
    }
    const { currentPage, startDate, endDate, after } = this.state;
    const { companyId, branchId } = this.props;
    const variables = { startDate, endDate, after, branchId, companyId };

    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={CustomerHistoryReportQuery}
          variables={variables}
          render={(
            response: { allCustomers: CustomerNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allCustomers.edges}
                  columns={this.customerHistoryColumn()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allCustomers.pageInfo.endCursor,
                      response.allCustomers.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv(variables)}
                  key="button"
                />,
              ];
            }
            return <Skeleton />;
          }}
        />
      </div>
    );
  }
}

interface State {
  currentPage: number;
  startDate: string;
  endDate: string;
  after: string;
}

interface Props {
  companyId?: string;
  branchId?: string;
  company: CompanyNodeEdge | null;
}

import * as React from 'react';
import {
  CompanyNodeEdge,
  CompanyError,
  companyObject,
  companyErrorObject,
} from './constants';
import { CompanyForm } from './CompanyForm';
import { Button, notification } from 'antd';
import { CompanyService } from '../../../service/CompanyService';

export class AddCompany extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      company: props.company || JSON.parse(JSON.stringify(companyObject)),
      error: { ...companyErrorObject },
    };
  }

  companyService = new CompanyService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      company: nextProps.company || JSON.parse(JSON.stringify(companyObject)),
    });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const company = { ...this.state.company };
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };

  saveCompany = () => {
    this.companyService?.addCompany(
      this.state.company,
      (response) => {
        notification.success({
          message: 'Company ' + this.state.company.id ? 'Updated' : 'Added',
          description: `Company named ${
            this.state.company.name
          } was successfuly ${this.state.company.id ? 'Updated' : 'Added'}`,
        });
      },
      (error) =>
        notification.error({
          message:
            'Company ' + this.state.company.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      company: JSON.parse(JSON.stringify(companyObject)),
      error: { ...companyErrorObject },
    });
  };

  render() {
    return (
      <div>
        <CompanyForm
          values={this.state.company}
          error={this.state.error}
          onChange={this.onChange}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveCompany}
          children="Submit"
          style={{ width: '50%' }}
        />
      </div>
    );
  }
}

interface Props {
  company?: CompanyNodeEdge;
}

interface State {
  company: CompanyNodeEdge;
  error: CompanyError;
}

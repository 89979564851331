import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { sumBy, uniq } from 'lodash';
import { inWords } from '../helper';
import { TransferNode, ProductDetailsNodeConnection } from '../../schema';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../config';

export const ReceivingTaxInvoiceJharcraft: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailsEdges = (
    props.receiving.productDetails as any as ProductDetailsNodeConnection
  ).edges;
  const setBoxHeight = productDetailsEdges.length < 9;
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node?.quantity || 0,
  );
  const totalAmount = sumBy(
    productDetailsEdges,
    (value) =>
      (value.node && value.node.purchaseRate * value.node.quantity) || 0,
  );
  const gstRateList = props.receiving.hsnGstWiseAmountReceiving.map((obj) =>
    Number(obj.gst),
  );
  const gstRates = uniq(gstRateList);
  const amountWithGst: {
    [gst: number]: number;
  } = {};
  gstRates.forEach((gst) => {
    amountWithGst[gst] = Number(
      sumBy(
        props.receiving.hsnGstWiseAmountReceiving.filter(
          (obj) => obj.gst === gst,
        ),
        (obj) => obj.amount || 0,
      ).toFixed(2),
    );
  });
  const grandTotal = (
    Number(totalAmount.toFixed(2)) +
    Number(
      sumBy(gstRates, (gst) => amountWithGst[gst] * (gst / 100)).toFixed(2),
    )
  ).toFixed(2);

  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.15, height: 43 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Tax Invoice
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.business.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.business.state} (${props.business.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.receiving.receivingInvoiceNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Date: {moment(props.receiving.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              For Transfer Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.receiving.transferInvoiceNo}
              </Text>
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <View
              style={[
                style.box,
                { padding: 3, marginRight: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill From: (
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.category
                  : 'Head Office'}
                )
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.name
                  : props.company.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.address
                  : props.company.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.gstin
                  : props.company.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.state
                  : props.company.state}
                (
                {props.receiving.fromLocation
                  ? props.receiving.fromLocation.gstin?.slice(0, 2)
                  : props.company.gstin?.slice(0, 2)}
                )
              </Text>
            </View>
            <View
              style={[
                style.box,
                { padding: 3, marginLeft: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill To: (
                {props.receiving.toLocation
                  ? props.receiving.toLocation.category
                  : 'Head Office'}
                )
                {props.receiving.toLocation
                  ? props.receiving.toLocation.name
                  : props.company.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {props.receiving.toLocation
                  ? props.receiving.toLocation.address
                  : props.company.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {props.receiving.toLocation
                  ? props.receiving.toLocation.gstin
                  : props.company.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {props.receiving.toLocation
                  ? props.receiving.toLocation.state
                  : props.company.state}
                (
                {props.receiving.toLocation
                  ? props.receiving.toLocation.gstin?.slice(0, 2)
                  : props.company.gstin?.slice(0, 2)}
                )
              </Text>
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.receiving.remarks}
            </Text>
          </View>
        </View>
        {
          <View>
            {setBoxHeight ? (
              <View
                style={[
                  style.section,
                  style.box,
                  { marginTop: 2, height: 265 },
                ]}
              >
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    Barcode
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {productDetailsEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}({pd.node?.rate}/-)
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.oldBarcodeId}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {pd.node?.product?.hsn?.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {pd.node?.quantity}{' '}
                      {pd.node?.product?.unit.name
                        .split('(')[1]
                        .replace(')', '')}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {pd.node?.purchaseRate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {pd.node &&
                        (pd.node.quantity * pd.node.purchaseRate).toFixed(2)}
                    </Text>
                  </View>
                ))}
              </View>
            ) : (
              <View style={[style.section, style.box, { marginTop: 2 }]}>
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    Barcode
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {productDetailsEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}({pd.node?.rate}/-)
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.oldBarcodeId}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {pd.node?.product?.hsn?.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.5 },
                      ]}
                    >
                      {pd.node?.quantity}{' '}
                      {pd.node?.product?.unit.name
                        .split('(')[1]
                        .replace(')', '')}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {pd.node?.purchaseRate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3, flex: 0.6 },
                      ]}
                    >
                      {pd.node &&
                        (pd.node.quantity * pd.node.purchaseRate).toFixed(2)}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <View
              style={[
                style.section,
                {
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  marginTop: 0,
                  height: 17,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {totalQty}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {totalAmount.toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                { borderTop: '1pt solid black', marginTop: 0 },
              ]}
            >
              {gstRates.map((gst) => (
                <View
                  style={[
                    {
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.darkBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { flex: 2.1, padding: 3 },
                      ]}
                    >
                      Integrated GST (IGST) [{gst}% on {amountWithGst[gst]}]
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3 },
                      ]}
                    >
                      {(amountWithGst[gst] * (gst / 100)).toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={[
                style.section,
                {
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderTop: '1pt solid black',
                  marginTop: 0,
                  height: 22,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Grand Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 4, fontSize: '10.2pt' },
                  ]}
                >
                  {grandTotal}
                </Text>
              </View>
            </View>
            <View
              style={[style.section, style.box, { marginTop: 0, height: 22 }]}
            >
              <Text
                style={[
                  style.darkBlackText,
                  { padding: 4, fontSize: '10.2pt' },
                ]}
              >
                Rs. {inWords(Number(grandTotal))}.
              </Text>
            </View>
          </View>
        }
        {
          <View wrap={false}>
            <View
              style={[style.box, style.section, style.inline, { marginTop: 2 }]}
            >
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                HSN Code
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3, flex: 0.4 },
                ]}
              >
                IGST %
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3, flex: 0.4 },
                ]}
              >
                Quantity
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                Taxable Amount
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                IGST Amount
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                Total Amount
              </Text>
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1 pt solid black',
                  borderRight: '1 pt solid black',
                  borderBottom: '1 pt solid black',
                },
              ]}
            >
              {props.receiving.hsnGstWiseAmountReceiving.map((obj) => (
                <View style={[style.inline, { marginTop: 0 }]}>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.hsnCode}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.4,
                      },
                    ]}
                  >
                    {obj.gst}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.4,
                      },
                    ]}
                  >
                    {obj.quantity}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.amount?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.amount &&
                      obj.gst &&
                      (obj.amount * (obj.gst / 100)).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      { padding: 3, borderBottom: '1 pt solid black' },
                    ]}
                  >
                    {obj.amount &&
                      obj.gst &&
                      (obj.amount + obj.amount * (obj.gst / 100)).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1 pt solid black',
                  borderRight: '1 pt solid black',
                  borderBottom: '1 pt solid black',
                },
              ]}
            >
              <View style={[style.inline, { marginTop: 0 }]}>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    { borderBottom: '1 pt solid black', padding: 3 },
                  ]}
                >
                  Net Total
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3.5,
                      flex: 0.4,
                    },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                      flex: 0.4,
                    },
                  ]}
                >
                  {sumBy(
                    props.receiving.hsnGstWiseAmountReceiving,
                    (obj) => obj.quantity || 0,
                  )}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                    },
                  ]}
                >
                  {sumBy(
                    props.receiving.hsnGstWiseAmountReceiving,
                    (obj) => obj.amount || 0,
                  ).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                    },
                  ]}
                >
                  {sumBy(
                    props.receiving.hsnGstWiseAmountReceiving,
                    (obj) =>
                      (obj.amount && obj.gst && obj.amount * (obj.gst / 100)) ||
                      0,
                  ).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    { padding: 3, borderBottom: '1 pt solid black' },
                  ]}
                >
                  {sumBy(
                    props.receiving.hsnGstWiseAmountReceiving,
                    (obj) =>
                      (obj.amount &&
                        obj.gst &&
                        obj.amount + obj.amount * (obj.gst / 100)) ||
                      0,
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={[style.section, style.inline, style.marginTop]}>
              <Text style={[style.inlineHead, style.darkBlackText]}>
                Received By
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.darkBlackText,
                  style.textCenter,
                ]}
              >
                Checked By
              </Text>
              <Text
                style={[style.inlineHead, style.darkBlackText, style.textRight]}
              >
                Authorized Signatory
              </Text>
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  receiving: TransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
}

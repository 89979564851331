import { BehaviorSubject } from 'rxjs';
import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import {
  OpeningStockEdges,
  OpeningStockNode,
  AddOpeningStockVariable,
  OpeningStockMutation,
  SetDefaultOpeningStockMutation,
} from '../components/OpeningStock/constants';
import { ProductDetailNodeEdge } from '../components/Sales/constants';
import { DateService } from './DateService';

export class OpeningStockService {
  static instance: OpeningStockService;
  constructor() {
    if (OpeningStockService.instance) {
      return OpeningStockService.instance;
    } else {
      OpeningStockService.instance = this;
    }
  }

  public readonly openingStock$ = new BehaviorSubject<OpeningStockEdges[]>([]);
  companyService = new CompanyService();
  dateService = new DateService();

  addOpeningStock(
    data: OpeningStockNode<ProductDetailNodeEdge[]>,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete data.company;
    delete data.branch;
    data.productDetails = data.productDetails.map((dpd) => {
      dpd.date = data.date;
      dpd.companyId = this.companyService?.company$.value?.id;
      dpd.movementForId =
        this.companyService?.selectedBussiness$.value?.type === 'branch'
          ? this.companyService.selectedBussiness$.value.id
          : null;
      delete dpd.product;
      delete dpd.movementFor;
      return dpd;
    });

    Api.graphql<AddOpeningStockVariable, {}>(
      API_URL,
      {
        input: {
          openingStock: data,
        },
      },
      OpeningStockMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }

  addDefaultOpeningStock(
    quantity: number,
    onSuccess: () => void,
    onError: () => void,
  ) {
    Api.graphql<
      {
        input: {
          value: { companyId?: string; branchId?: string; quantity: number };
        };
      },
      {}
    >(
      API_URL,
      {
        input: {
          value: {
            quantity,
            companyId: this.companyService?.company$.value?.id,
            branchId: this.companyService?.selectedBussiness$.value?.id,
          },
        },
      },
      SetDefaultOpeningStockMutation,
      HEADER,
      () => {
        onSuccess();
      },
      onError,
    );
  }
}

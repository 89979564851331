import * as React from 'react';
import { Tabs, Icon, Skeleton } from 'antd';
import { Table } from '../../common/Table';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { SubareaNodeEdge, SubareaNodeConnection } from '../../../schema';
import { subareaQuery } from './constants';
import { checkAddPermission } from '../../helper';
import { AddSubarea } from './AddSubarea';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';

export class Subarea extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedSubarea: null,
      currentPage: 1,
      after: '',
      user: null,
    };
  }

  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedSubarea: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  subareaColumns = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedSubarea: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Pin Code',
      dataIndex: 'node.pincode',
      key: 'pincode',
    },
    {
      title: 'Area',
      dataIndex: 'node.area.name',
      key: 'area',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey } = this.state;
    const variables = { after, activeKey };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Subarea
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={subareaQuery}
            variables={variables}
            render={(
              response: { allSubarea: SubareaNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allSubarea.edges}
                    columns={this.subareaColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allSubarea.pageInfo.endCursor,
                        response.allSubarea.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Subarea
            </span>
          }
          key="2"
        >
          <AddSubarea
            handleTabChange={this.handleTabChange}
            subarea={this.state.selectedSubarea?.node}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedSubarea: SubareaNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {}

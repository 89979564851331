import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { GroupNodeConnection, GroupNodeEdge } from '../../../schema';
import { OptionProps } from 'antd/lib/select';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  group?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

type GroupDropdownProps = AutoCompleteProps;

export class GroupDropdown extends React.Component<GroupDropdownProps, State> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props: Readonly<GroupDropdownProps>) {
    super(props);

    this.state = {
      name: '',
      touched: false,
    };
  }

  getName = (group?: GroupNodeEdge) => {
    if (!group) {
      return;
    }

    return group.node?.name;
  };

  value = (groups: GroupNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    return this.getName(groups.find((v) => v.node?.id === this.props.value));
  };

  render() {
    const { name } = this.state;
    const { value, onSelect } = this.props;
    return (
      <GraphqlQuery
        queryString={`query GroupDropdown($after: String!, $id: ID, $name: String) {
          allGroups(first: 5, after: $after, id: $id, name_Icontains: $name) {
            edges {
              node {
                id
                name
              }
            }
          }
        }`}
        variables={{
          after: '',
          name,
          id: value && !name ? value : '',
        }}
        render={(
          response: { allGroups: GroupNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Group"
                dataSource={response.allGroups.edges.map((group) => (
                  <Option key={group.node?.id} label={group.node} group={group}>
                    {this.getName(group)}
                  </Option>
                ))}
                value={this.value(response.allGroups.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(group) =>
                  this.setState({ name: group, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    name: this.getName((f as any).props.group) || '',
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  name: string;
  touched: boolean;
}

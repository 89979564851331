import * as React from 'react';
import { TSInput } from '../common/TSInput';
import { Button, Icon, Spin } from 'antd';
import { UserService } from '../../service/UserService';

export class Login extends React.Component<Props, State> {
  userService = new UserService();

  constructor(props) {
    super(props);
    this.state = {
      value: {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
      },
      error: {
        username: '',
        password: '',
      },
      buttonDisabled: false,
      requesting: false,
    };
  }

  style = {
    width: '396px',
    background: '#fff',
    borderRadius: '10px',
    overflow: 'hidden' as const,
    padding: '77px 55px 33px 55px',
  };

  containerStyle = {
    width: '100%',
    minHeight: '100vh',
    display: '-webkit-flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    padding: '15px',
    background: '#f2f2f2',
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState: State = {
      ...this.state,
      value: {
        ...this.state.value,
        [e.target.name]: e.target.value,
      },
    };
    this.setState(nextState, this.checkError);
  };

  login = () => {
    if (this.checkError()) {
      return;
    }
    console.log(this);
    this.userService?.login(
      this.state.value,
      () => {
        this.props.history.push('/dashboard/master/');
      },
      (e) => this.setState({ error: { username: '', password: e.response } }),
    );
  };

  checkError = () => {
    const requiredFields = ['username', 'password'];
    const error = { ...this.state.error };
    const value = { ...this.state.value };
    let isError = false;
    Object.keys(error).forEach((erKey) => {
      if (!value[erKey]) {
        isError = true;
        error[erKey] = `${erKey.slice(0, 1).toUpperCase()}${erKey.slice(
          1,
        )} is a required field`;
      } else {
        error[erKey] = '';
      }
    });
    this.setState({ error, buttonDisabled: isError });
    return isError;
  };

  render() {
    return (
      <div style={this.containerStyle}>
        <div style={this.style}>
          <h2>Inventory Management</h2>
          <TSInput
            placeholder="Username"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="username"
            type="username"
            required
            error={this.state.error.username}
            value={this.state.value.username || ''}
            onChange={this.onChange}
            onPressEnter={this.login}
            autoFocus
            size="large"
            style={{ margin: '10px 0' }}
          />
          <TSInput
            placeholder="Password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            type="password"
            required
            error={this.state.error.password}
            value={this.state.value.password || ''}
            onChange={this.onChange}
            onPressEnter={this.login}
            size="large"
            style={{ margin: '10px 0' }}
          />
          <Button
            onClick={this.login}
            disabled={this.state.buttonDisabled}
            style={{ width: '100%', margin: '25px 0' }}
            type="primary"
          >
            {this.state.requesting ? <Spin /> : 'Sign In'}
          </Button>
        </div>
      </div>
    );
  }
}

interface State {
  value: {
    username: string | null;
    password: string | null;
  };
  error: {
    username: string;
    password: string;
  };
  buttonDisabled: boolean;
  requesting: boolean;
}

interface Props {
  location: any;
  history: any;
}

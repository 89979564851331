import * as React from 'react';
import moment from 'moment';
import { DepositInput } from '../../schema';
import { DepositError } from './constants';
import { Row, DatePicker, Col } from 'antd';
import { TSInput } from '../common/TSInput';

export const DepositForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={6}>
          <DatePicker
            value={(props.values.date && moment(props.values.date)) || null}
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: {
                        name: 'date',
                        value: (e && moment(e).format('YYYY-MM-DD')) || '',
                      },
                    })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
          <span style={{ color: 'red' }}>{props.error.date}</span>
        </Col>
        <Col span={6}>
          <TSInput
            error={props.error.depositAmount}
            value={props.values.depositAmount || ''}
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: { name: 'depositAmount', value: e.target.value },
                    })
            }
            name="depositAmount"
            type="number"
            addonBefore="Deposit Amount"
          />
        </Col>
        <Col span={6}>
          <TSInput
            error={''}
            value={props.values.remarks}
            onChange={props.onChange}
            name="remarks"
            type="text"
            addonBefore="Remarks"
          />
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  values: DepositInput;
  error: DepositError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
  ) => void;
  isEditActive: boolean;
}

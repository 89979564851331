import * as React from 'react';
import { OptionProps } from 'antd/lib/select';
import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import {
  DiscountCategoryNodeConnection,
  DiscountCategoryNodeEdge,
} from './constants';
import { GraphqlQuery } from 'requestapijs';
import { Spin } from 'antd';

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

export class DiscountCategoryDropdown extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      name: '',
      discountCategory: null,
      touched: false,
    };
  }

  findValue = (discountCategories: DiscountCategoryNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const discountCategory = discountCategories.find(
      (v) => v.node.id === this.props.value,
    );
    if (!discountCategory) return;
    return discountCategory.node.name;
  };

  render() {
    const { name } = this.state;
    const { onSelect, companyId } = this.props;
    return (
      <GraphqlQuery
        queryString={`
          query DiscountCategoryDropdown($name: String, $companyId: ID) {
            allDiscountCategories(
              name_Icontains: $name,
              companyId: $companyId 
            ) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `}
        variables={{
          name,
          companyId,
        }}
        render={(
          response: {
            allDiscountCategories: DiscountCategoryNodeConnection;
          },
          error,
          loading,
        ) => {
          if (loading) return <Spin />;
          if (error) return <div>{JSON.stringify(error)}</div>;
          if (response) {
            return (
              <div style={{ width: '100%' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Discount Category"
                  dataSource={response.allDiscountCategories.edges.map(
                    (discountCategory) => (
                      <Option
                        key={discountCategory.node.id}
                        lable={discountCategory.node}
                        discountCategory={discountCategory}
                      >
                        {discountCategory.node.name}
                      </Option>
                    ),
                  )}
                  value={this.findValue(response.allDiscountCategories.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(discountCategory) =>
                    this.setState({ name: discountCategory, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.discountCategory.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
          return <Spin />;
        }}
      />
    );
  }
}

interface CustomOption extends OptionProps {
  lable?: React.ReactNode;
  display?: string;
  discountCategory?: any;
}

interface Props extends AutoCompleteProps {
  companyId: string;
}

interface State {
  name: string;
  discountCategory: DiscountCategoryNodeEdge | null;
  touched: boolean;
}

import * as React from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Icon, Row, Table } from 'antd';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { Bussiness } from '../master/Company/constants';
import { BranchCategory } from '../../schema';
import { getValue } from '../common/AutoCompleteHelper';
import { NEW_STOCK_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class MultiBranchStockReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      date: moment().format('YYYY-MM-DD'),
      branchId: null,
      isLoading: false,
      message: '',
      branchName: '',
      locations: [],
    };
  }

  columns = () => [
    {
      title: 'Selected Location Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Remove',
      key: 'id',
      render: (obj) => (
        <Button
          children={<Icon type="delete" />}
          type="danger"
          onClick={() => {
            let locations = [...this.state.locations];
            locations = locations.filter((value) => value.id !== obj.id);
            this.setState({ locations });
          }}
        />
      ),
    },
  ];

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  downloadCsv = (variables) => {
    const { startDate, date, companyId } = variables;

    let branchId = '';
    let branchName = '  ';

    const locations = [...this.state.locations];
    locations.forEach((location) => {
      branchId += `${location.id},`;
      branchName += `${location.name}  `;
    });

    branchId = branchId.slice(0, -1);

    const queryParams = `companyId=${companyId}&startDate=${startDate}&date=${date}&branchId=${branchId}`;

    this.setState({ isLoading: true, message: 'Please Wait' });

    fetch(NEW_STOCK_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) => {
        if (csv === 'Wait') {
          this.setState({
            message: 'Generating data. You can downlaod it after 10 to 15 mins',
          });
        } else if (csv === 'Creating') {
          this.setState({
            message: 'You have already requested for this data. Please wait!',
          });
        } else {
          const data = csv.split('####');
          downloadToFile(
            data[0],
            `multiple-branch-stock-report-${new Date()}-${btoa(data[1])}`,
            'MULTIPLE BRANCH STOCK REPORT',
            'Jharcraft',
            branchName,
            moment(this.state.startDate).format('DD-MM-YYYY'),
            moment(this.state.date).format('DD-MM-YYYY'),
            '',
          );
          this.setState({ message: '' });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const { startDate, date, branchId, branchName } = this.state;
    const { companyId, business } = this.props;
    const variables = {
      startDate,
      date,
      companyId,
    };
    return (
      <>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={
                (this.state.startDate && moment(this.state.startDate)) || null
              }
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.date) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.date).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(this.state.date && moment(this.state.date)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'date',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSAutoComplete
              error={''}
              inputProps={{
                name: 'branchId',
                addonBefore: 'Change Location',
              }}
              dataSource={
                business.branches?.edges.filter(
                  (element) =>
                    element.node.category === BranchCategory.EMPORIUM,
                ) || []
              }
              key="id"
              name="branchId"
              onValueSelect={(e) =>
                this.setState({
                  branchId: e.target.value,
                  branchName: getValue(
                    business.branches?.edges,
                    e.target.value,
                  ),
                })
              }
              displayKey="name"
              textValue={getValue(business.branches?.edges, branchId)}
            />
          </Col>
          <Col span={3}>
            <Button
              children="Add Location"
              onClick={() => {
                if (branchId) {
                  const locations = [...this.state.locations];
                  const locationFound = locations.find(
                    (value) => value.id === branchId,
                  );
                  if (!locationFound) {
                    const location = {
                      id: branchId,
                      name: branchName,
                    };
                    locations.push(location);
                    this.setState({ locations });
                  }
                }
                this.setState({ branchId: null, branchName: '' });
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={3}>
            <Button
              children="Clear Location"
              onClick={() => this.setState({ branchId: null, branchName: '' })}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Table
          key="table"
          dataSource={this.state.locations}
          columns={this.columns()}
          rowKey={(obj) => obj.id || ''}
          pagination={false}
        />
        {this.state.locations.length > 0 && (
          <>
            <Button
              key="download"
              style={{ float: 'right' }}
              icon="download"
              onClick={() => this.downloadCsv(variables)}
              disabled={this.state.isLoading}
            >
              Download Report
            </Button>
            <span style={{ float: 'right', margin: '7px' }}>
              {this.state.message}
            </span>
          </>
        )}
      </>
    );
  }
}

interface State {
  startDate: string;
  date: string;
  branchId?: string | null;
  isLoading: boolean;
  message: string;
  branchName: string;
  locations: {
    id: string;
    name: string;
  }[];
}

interface Props {
  companyId: string;
  business: Bussiness;
}

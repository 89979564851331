import * as React from 'react';
import { TSInput } from '../../common/TSInput';
import { Row } from 'antd';
import { VendorNode, VendorError } from './constants';

export const VendorForm: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Row>
      <TSInput
        error={props.error.name}
        value={props.value.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error={''}
        value={props.value.address}
        onChange={props.onChange}
        name="address"
        type="text"
        addonBefore="Address"
      />
      <TSInput
        error={props.error.state}
        value={props.value.state}
        onChange={props.onChange}
        name="state"
        type="text"
        addonBefore="State"
      />
      <TSInput
        error={props.error.contactPersonName}
        value={props.value.contactPersonName}
        onChange={props.onChange}
        name="contactPersonName"
        type="text"
        addonBefore="Contact Person Name"
      />
      <TSInput
        error={props.error.contactNo}
        value={props.value.contactNo}
        onChange={props.onChange}
        name="contactNo"
        type="number"
        addonBefore="Contact No"
      />
      <TSInput
        error={''}
        value={props.value.email}
        onChange={props.onChange}
        name="email"
        type="text"
        addonBefore="Email"
      />
      <TSInput
        error={props.error.gstin}
        value={props.value.gstin}
        onChange={props.onChange}
        name="gstin"
        type="text"
        addonBefore="GST IN"
      />
    </Row>
  );
};

interface Props {
  value: VendorNode;
  error: VendorError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } },
  ) => void;
}

import * as React from 'react';
import moment from 'moment';
import { Col, DatePicker, Row, Select } from 'antd';
import { TSInput } from '../common/TSInput';
import { MemoError, MemoNode } from './constants';
import { Label } from '../common/TSLabel';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { BranchEdges } from '../master/Branch/constants';
import { getValue } from '../common/AutoCompleteHelper';

export const MemoForm: React.FunctionComponent<Props> = (props) => (
  <div>
    <Row>
      <Col span={11}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={11} offset={1}>
        <TSAutoComplete
          error={props.error.locationId}
          defaultValue={
            props.values?.locationId
              ? getValue(props.branch, props.values.locationId)
              : null
          }
          inputProps={{ name: 'locationId', addonBefore: 'Location' }}
          dataSource={props.branch || []}
          key="id"
          name="locationId"
          onValueSelect={(e) =>
            props.onChange({
              target: {
                name: 'locationId',
                value: e.target.value,
              },
            })
          }
          displayKey="name"
          textValue={getValue(props.branch, props.values.locationId)}
        />
      </Col>
      <Col span={11}>
        <TSInput
          error={props.error.bookNumber}
          value={props.values.bookNumber}
          type="text"
          name="bookNumber"
          onChange={props.onChange}
          addonBefore="Book Number"
          required
        />
      </Col>
      <Col span={11} offset={1}>
        <TSInput
          error={props.error.financialYear}
          value={props.values.financialYear}
          type="text"
          name="financialYear"
          onChange={props.onChange}
          addonBefore="Financial Year"
          required
        />
      </Col>
      <Col span={11}>
        <TSInput
          error={props.error.serialStart}
          value={props.values.serialStart}
          type="text"
          name="serialStart"
          onChange={props.onChange}
          addonBefore="Serial Start"
        />
      </Col>
      <Col span={11} offset={1}>
        <TSInput
          error={props.error.serialEnd}
          value={props.values.serialEnd}
          type="text"
          name="serialEnd"
          onChange={props.onChange}
          addonBefore="Serial End"
        />
      </Col>
      <Col span={11}>
        <Label title="Memo Type">
          <Select
            style={{ width: '100%' }}
            value={props.values.memoType}
            onChange={(value) =>
              props.onChange({
                target: {
                  name: 'memoType',
                  value: value && value.toString(),
                },
              })
            }
          >
            <Select.Option value="CASH MEMO">CASH MEMO</Select.Option>
            <Select.Option value="BIJAK">BIJAK</Select.Option>
          </Select>
        </Label>
      </Col>
    </Row>
  </div>
);

interface Props {
  values: MemoNode;
  error: MemoError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  branch: BranchEdges[];
}

import * as React from 'react';
import { Row, Col, Spin, Button, Popconfirm } from 'antd';
import { TSInput } from '../common/TSInput';
import moment from 'moment';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import {
  PhysicalStockNode,
  ProductNodeConnection,
  StockReport,
} from '../../schema';
import { Pagination } from 'sha-el-design';
import { productQuery } from './Constants';

export const PhysicalStockReport: React.FunctionComponent<Props> = (props) => {
  const { after, currentPage, onPageChange } = props;
  return (
    <div>
      {props.categoryId && (
        <GraphqlQuery
          queryString={productQuery}
          variables={{
            companyId: props.company.id,
            categoryId: props.categoryId,
            branchId:
              props.business.type === 'branch' ? props.business.id : null,
            date: moment().format('YYYY-MM-DD'),
            after,
          }}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (error) return <div>{JSON.stringify(error)}</div>;
            if (loading) return <Spin />;
            if (response) {
              return [
                <div key="report">
                  <Row>
                    <Col
                      span={3}
                      style={{
                        marginLeft: '8px',
                        marginTop: '9px',
                        marginBottom: '3px',
                      }}
                    >
                      {<b>Old Barcode</b>}
                    </Col>
                    <Col
                      span={3}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>New Barcode</b>}
                    </Col>
                    <Col
                      span={4}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Name</b>}
                    </Col>
                    <Col
                      span={3}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Cost Price</b>}
                    </Col>
                    <Col
                      span={2}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>MRP</b>}
                    </Col>
                    <Col
                      span={3}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Date</b>}
                    </Col>
                    <Col
                      span={2}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Stock Available</b>}
                    </Col>
                    <Col
                      span={2}
                      offset={1}
                      style={{
                        marginTop: '9px',
                        marginBottom: '3px',
                        marginRight: '8px',
                      }}
                    >
                      {<b>Physical Stock</b>}
                    </Col>
                  </Row>
                  {response.allProducts.edges.map((prod) => (
                    <Row>
                      <Col
                        span={3}
                        style={{
                          marginLeft: '8px',
                          marginTop: '9px',
                          marginBottom: '3px',
                        }}
                      >
                        {prod.node?.oldBarcodeId}
                      </Col>
                      <Col
                        span={3}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.newBarcodeId}
                      </Col>
                      <Col
                        span={4}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.name}
                      </Col>
                      <Col
                        span={3}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.purchaseRate}
                      </Col>
                      <Col
                        span={2}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.sellingRate}
                      </Col>
                      <Col
                        span={3}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.physicalStock
                          ? (prod.node.physicalStock as PhysicalStockNode).date
                          : moment().format('YYYY-MM-DD')}
                      </Col>
                      <Col
                        span={2}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        <TSInput
                          error={''}
                          value={
                            prod.node?.physicalStock
                              ? (prod.node.physicalStock as PhysicalStockNode)
                                  .availableStock
                              : (prod.node?.stock as StockReport).totalStocks
                          }
                        />
                      </Col>
                      <Col
                        span={2}
                        offset={1}
                        style={{
                          marginTop: '9px',
                          marginBottom: '3px',
                          marginRight: '8px',
                        }}
                      >
                        <TSInput
                          error={''}
                          value={
                            (prod.node?.physicalStock as PhysicalStockNode)
                              ?.physicalStock
                          }
                        />
                      </Col>
                    </Row>
                  ))}
                </div>,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
      )}
    </div>
  );
};

export interface Props {
  categoryId: string;
  company: CompanyNodeEdge;
  business: Bussiness;
  after: string;
  currentPage: number;
  onPageChange: (
    next: boolean,
    afterProduct: string,
    nextPage: boolean,
  ) => void;
}

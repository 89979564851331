import * as React from 'react';
import { AutoComplete, Input, Button, Icon, Modal } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { InputProps } from 'antd/lib/input';

export class TSAutoComplete extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      textValue: this.props.textValue || '',
      modalVisible: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ textValue: nextProps.textValue || '' });
  }

  generateAutofills = (item) => {
    return (
      <AutoComplete.Option key={item.node.id}>
        {item?.node?.[this.props.displayKey]}
      </AutoComplete.Option>
    );
  };

  canOpenModel = () => {
    return this.props.componentToOpen
      ? {
          suffix: (
            <Button
              type="primary"
              onClick={() => this.setState({ modalVisible: true })}
            >
              <Icon type="plus" />
            </Button>
          ),
        }
      : null;
  };

  render() {
    return (
      <div style={{ margin: '5px 0' }}>
        <AutoComplete
          {...this.props}
          style={{ width: '100%' }}
          onSelect={(e) => {
            this.props.onValueSelect({
              target: {
                name: this.props.name,
                value: e,
              },
            });
          }}
          dataSource={
            this.props.dataSource &&
            this.props.dataSource.map((value) => this.generateAutofills(value))
          }
          filterOption={(inputValue, option) => {
            return typeof option.props.children === 'string'
              ? option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              : false;
          }}
          value={this.state.textValue}
          onSearch={(textValue) => {
            this.setState({ textValue });
          }}
        >
          <Input
            style={{ border: this.props.error ? '1px solid red' : '' }}
            {...this.canOpenModel()}
            {...this.props.inputProps}
          />
        </AutoComplete>
        <span style={{ color: 'red' }}>{this.props.error}</span>
        <Modal
          bodyStyle={{ width: '100%' }}
          style={{ width: '100%' }}
          maskStyle={{ width: '100%' }}
          visible={this.state.modalVisible}
          footer={<div />}
          onCancel={() => this.setState({ modalVisible: false })}
          closable
        >
          {this.props.componentToOpen}
        </Modal>
      </div>
    );
  }
}

interface Props extends AutoCompleteProps {
  error: string;
  inputProps: InputProps;
  displayKey: string;
  name: string;
  onValueSelect: ({ target: { name: string, value: number } }) => void;
  textValue: string;
  componentToOpen?: React.ReactChild;
  dataSource: any[];
}

interface State {
  textValue: string;
  modalVisible: boolean;
}

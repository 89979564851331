import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  DirectProductionInput,
  CreateDirectProductionInput,
  CreateDirectProductionPayload,
  ProductDetailsNode,
} from '../schema';
import { directProductionMutation } from '../components/Production/DirectProduction/constants';

export class DirectProductionService {
  static instance;

  constructor() {
    if (DirectProductionService.instance) {
      return DirectProductionService.instance;
    } else {
      DirectProductionService.instance = this;
    }
  }

  companyService = new CompanyService();

  addDirectProduction(
    data: DirectProductionInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails.map((productDetail) => {
      productDetail.date = data.date;
      productDetail.companyId = this.companyService?.company$.getValue()?.id;
      delete (productDetail as any as ProductDetailsNode).product;
      return productDetail;
    });
    Api.graphql<
      { input: CreateDirectProductionInput },
      CreateDirectProductionPayload
    >(
      API_URL,
      {
        input: {
          production: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      directProductionMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

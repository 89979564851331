import * as React from 'react';
import { ProductDetailsInput, ProductNode } from '../../schema';
import { values } from './constants';
import { CodeConversionForm } from './CodeConversionForm';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { Button, Popconfirm, notification } from 'antd';
import { ProductDetailsService } from '../../service/ProductDetailsService';
import { BranchEdges } from '../master/Branch/constants';

export class AddCodeConversion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      values: JSON.parse(JSON.stringify(values)),
      buttonDisabled: false,
      oldProductObjList: [],
    };
  }

  productDetailsService = new ProductDetailsService();

  componentDidMount() {}

  onBranchChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState.values.map((val) => (val[e.target.name] = e.target.value));
    this.setState(nextState);
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.values[index][e.target.name] = e.target.value;
    nextState.values[index].amount =
      Number(nextState.values[index].quantity) *
      Number(nextState.values[index].rate);
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.oldProductObjList[index] = productObj;
    nextState.values[index].rate = productObj.sellingRate;
    nextState.values[index].purchaseRate = productObj.purchaseRate;
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.values.push({
        date: moment().format('YYYY-MM-DD'),
        productId: null,
        quantity: null,
        purchaseRate: null,
        rate: null,
        amount: null,
        newProductId: null,
        movementType: 'CODE_CONVERSION',
        companyId: null,
      });
    } else {
      nextState.values.splice(index, 1);
    }
    this.setState(nextState);
  };

  saveCodeConversion = () => {
    this.setState({ buttonDisabled: true });
    const productDetails = this.state.values.map((val) => ({
      ...val,
      movementForId: this.state.values[0].movementForId,
    }));
    this.productDetailsService?.addProductDetails(
      productDetails,
      (response) => {
        this.clear();
        notification.success({
          message:
            'Code Conversion ' + this.state.values[0].id ? 'Updated' : 'Added',
          description: `Code Conversion was successfully ${
            this.state.values[0].id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Code Conversion ' + this.state.values[0].id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      values: JSON.parse(JSON.stringify(values)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <CodeConversionForm
          values={this.state.values}
          company={this.props.company}
          productChangeHandler={this.productChangeHandler}
          productObjChangehandler={this.productObjChangehandler}
          updateProductsRow={this.updateProductsRow}
          branch={this.props.branch}
          onBranchChange={this.onBranchChange}
        />
        <div style={{ marginTop: '50px' }}>
          <Popconfirm
            title="Are you sure to clear?"
            onConfirm={this.clear}
            okText="Yes"
            cancelText="No"
          >
            <Button type="dashed" children="Clear" style={{ width: '50%' }} />
          </Popconfirm>
          <Button
            type="primary"
            onClick={this.saveCodeConversion}
            children="Submit"
            style={{ width: '50%' }}
            disabled={this.state.buttonDisabled}
          />
        </div>
      </div>
    );
  }
}

interface State {
  values: ProductDetailsInput[];
  buttonDisabled: boolean;
  oldProductObjList: ProductNode[];
}

interface Props {
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
  branch: BranchEdges[];
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  DepositInput,
  CreateDepositInput,
  CreateDepositPayload,
} from '../schema';
import { depositMutation } from '../components/Deposit/constants';

export class DepositService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (DepositService.instance) {
      return DepositService.instance;
    } else {
      DepositService.instance = this;
    }
  }

  addDeposit(
    data: DepositInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<{ input: CreateDepositInput }, CreateDepositPayload>(
      API_URL,
      {
        input: {
          deposit: {
            ...data,
            companyId: this.companyService?.company$.value?.id,
            branchId: this.companyService?.selectedBussiness$.value?.id,
          },
        },
      },
      depositMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

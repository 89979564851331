import { Col, DatePicker, Row } from 'antd';
import * as React from 'react';
import { TSInput } from '../../common/TSInput';
import { Label } from '../../common/TSLabel';
import { DiscountCategoryDropdown } from '../DiscountCategory/DiscountCategoryDropdown';
import { DiscountNode } from './constants';
import moment from 'moment';
import { BranchDropDown } from '../Branch/BranchDropdown';

export const DiscountForm: React.FunctionComponent<Props> = (props) => (
  <div>
    <Row>
      <Col span={6}>
        <DatePicker
          format="DD-MM-YYYY"
          placeholder="Start Date"
          value={
            (props.values.startDate && moment(props.values.startDate)) || null
          }
          onChange={(date) =>
            props.onChange({
              target: {
                name: 'startDate',
                value: date && moment(date).format('YYYY-MM-DD'),
              },
            })
          }
          disabledDate={(currentDate) => {
            if (!currentDate || !props.values.endDate) {
              return false;
            }
            return (
              currentDate.valueOf() > moment(props.values.endDate).valueOf()
            );
          }}
          style={{ marginTop: '5px', width: '100%' }}
        />
      </Col>
      <Col span={6}>
        <DatePicker
          format="DD-MM-YYYY"
          placeholder="End Date"
          value={(props.values.endDate && moment(props.values.endDate)) || null}
          onChange={(date) =>
            props.onChange({
              target: {
                name: 'endDate',
                value: date && moment(date).format('YYYY-MM-DD'),
              },
            })
          }
          disabledDate={(currentDate) => {
            if (!currentDate || !props.values.startDate) {
              return false;
            }
            return (
              currentDate.valueOf() < moment(props.values.startDate).valueOf()
            );
          }}
          style={{ marginTop: '5px', width: '100%' }}
        />
      </Col>
      <Col span={12}>
        <TSInput
          error=""
          value={props.values.discountPercent}
          type="text"
          name="discountPercent"
          onChange={props.onChange}
          addonBefore="Discount Percent(%)"
        />
      </Col>
      <Col span={12}>
        <Label title="Discount Category">
          <DiscountCategoryDropdown
            onSelect={(e) => {
              props.onChange({
                target: { name: 'categoryId', value: String(e) },
              });
            }}
            value={props.values.categoryId || ''}
            companyId={props.companyId || ''}
            allowClear
          />
        </Label>
      </Col>
      <Col span={12}>
        <Label title="Branch">
          <BranchDropDown
            onSelect={(e) =>
              props.onChange({
                target: { name: 'branchId', value: e.toString() },
              })
            }
            value={props.values.branchId || ''}
            allowClear
          />
        </Label>
      </Col>
    </Row>
  </div>
);

interface Props {
  values: DiscountNode;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  companyId?: string | null;
}

import * as React from 'react';
import { ProductCategoryNodeEdge } from './constants';
import { Table, Tabs, Icon } from 'antd';
import { AddProductCategories } from './AddProductCategories';
import { checkAddPermission } from '../../helper';
import { ProductCategoryService } from '../../../service/ProductCategoryService';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';

export class ProductCategories extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      productCategories: [],
      activeKey: '1',
      selectedProductCategories: null,
      user: null,
    };
  }

  productCategoryService = new ProductCategoryService();
  userService = new UserService();

  componentDidMount() {
    this.productCategoryService?.productCategory$.subscribe(
      (productCategories) => this.setState({ productCategories }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedProductCategories: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productCategoriesColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedProductCategories: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Initials',
      dataIndex: 'node.initials',
      key: 'initials',
    },
  ];

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Product Categories
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.productCategories}
            columns={this.productCategoriesColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Product Categories
            </span>
          }
          key="2"
        >
          <AddProductCategories
            productCategories={this.state.selectedProductCategories?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  productCategories: {
    cursor: string;
    node: ProductCategoryNodeEdge;
  }[];
  activeKey: string;
  selectedProductCategories: {
    cursor: string;
    node: ProductCategoryNodeEdge;
  } | null;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';

export const Label: React.FunctionComponent<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={labelStyle}>{props.title}</div>
      <div style={childrenStyle}>{props.children}</div>
    </div>
  );
};

const childrenStyle = {
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #d9d9d9',
  height: '32px',
  padding: '0 5px',
};

const labelStyle = {
  padding: '0 11px',
  fontSize: '14px',
  lineHeight: '1',
  color: 'rgba(0, 0, 0, 0.65)',
  textAlign: 'center' as const,
  backgroundColor: '#fafafa',
  border: '1px solid #d9d9d9',
  borderRadius: '4px',
  transition: 'all .3s',
  margin: '5px 0',
  width: 'auto',
  display: 'flex',
  flex: '0',
  whiteSpace: 'nowrap' as const,
  verticalAlign: 'middle',
  height: '32px',
  alignItems: 'center',
};

interface Props {
  children: React.ReactChild;
  title: string;
}

import { CompanyNodeEdge } from '../Company/constants';

export interface UnitNodeEdge {
  id?: string;
  name: string;
  value: number;
  companyId?: string;
  company?: CompanyNodeEdge;
  allowDecimal?: boolean;
}

export interface UnitError {
  name: string;
  value: string;
}

export const unitObject: UnitNodeEdge = {
  name: '',
  value: 0,
};

export const unitErrorObject: UnitError = {
  name: '',
  value: '',
};

export const unitQuery = `
  query UnitList($companyId: ID) {
    allUnits(companyId: $companyId) {
      edges {
        cursor
        node {
          id
          name
          value
          allowDecimal
        }
      }
    }
  }
`;

export interface UnitQueryVariable {
  companyId: string;
}

export interface UnitQueryResponse {
  data: {
    allUnits: {
      edges: UnitEdges[];
    };
  };
}

export interface UnitEdges {
  cursor: string;
  node: UnitNodeEdge;
}

export interface UnitVariables {
  input: {
    unit: UnitNodeEdge;
  };
}

export const unitMutation = `
mutation addUnit(
  $input: CreateUnitInput!
) {
  createUnit(input: $input){
    newUnit {
      id
      name
      allowDecimal
    }
  }
}
`;

import * as React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { SalesNodeEdgeQuery } from './constants';
import moment from 'moment';
import { inWords } from '../helper';

export const SaleReceiptThermalPrinter: React.FunctionComponent<Props> = (
  props,
) => (
  <Document>
    <Page
      size={{ width: 215, height: 2000 }}
      style={[styles.body, { margin: '0px', padding: '2px' }]}
    >
      <View>
        <Text style={[{ fontSize: 12, textAlign: 'center' }]}>
          {props.company.name}
        </Text>
        <View>
          <Text style={[{ fontSize: 8, textAlign: 'center' }]}>
            {props.branch.name}
          </Text>
          <Text
            style={[
              styles.author,
              styles.inlineHeadDouble,
              { fontSize: 8, textAlign: 'center' },
            ]}
          >
            GSTIN No. {props.branch.gstin}
          </Text>
          <Text
            style={[
              styles.inlineHeadDouble,
              { fontSize: 9, textAlign: 'center' },
            ]}
          >
            {props.branch.address}
          </Text>
          {/* <Text
                style={[
                  styles.inlineHeadDouble,
                  { fontSize: 9, textAlign: 'center' },
                ]}
              ></Text> */}
        </View>
        <Text style={[{ fontSize: 9, textAlign: 'center' }]}>
          Contact Number:- {props.branch.contactNo}
        </Text>
        <Text
          style={[
            styles.inlineHeadDouble,
            { fontSize: 10, textAlign: 'center', margin: '4px' },
          ]}
        >
          TAX INVOICE
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Date: {moment(props.sale.date).format('DD-MM-YYYY')}
        </Text>
      </View>
      <View>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Customer Name: {props.sale.customer?.name}
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHead,
            { marginBottom: '4px' },
          ]}
        >
          Bill No.- {props.sale.billNo}
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.minFlex,
            { fontWeight: 'bold', flex: 3 },
          ]}
        >
          S.No.
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHead,
            { fontWeight: 'bold', flex: 6 },
          ]}
        >
          Item
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHeadDouble,
            { fontWeight: 'bold', textAlign: 'left' },
          ]}
        >
          Quantity
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.minFlex,
            { fontWeight: 'bold', textAlign: 'center' },
          ]}
        >
          Rate
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHeadDouble,
            { fontWeight: 'bold' },
          ]}
        >
          Amount
        </Text>
      </View>
      {props.sale.productDetails?.edges.map((pd, idx) => {
        return (
          <View style={[styles.inline]} key={idx}>
            <Text
              style={[
                styles.author,
                styles.boxMargin,
                styles.snoflex,
                styles.paddingTop,
              ]}
            >
              {idx + 1}
            </Text>
            <Text
              style={[
                styles.author,
                styles.boxMargin,
                styles.inlineHead,
                { fontWeight: 'bold' },
              ]}
            >
              {pd.node.product?.name}
            </Text>
            <Text
              style={[
                styles.author,
                styles.boxMargin,
                styles.inlineHeadDouble,
                { fontWeight: 'bold' },
              ]}
            >
              {pd.node.quantity}
            </Text>
            <Text
              style={[
                styles.author,
                styles.boxMargin,
                styles.minFlex,
                { fontWeight: 'bold' },
              ]}
            >
              {pd.node.rate}
            </Text>
            <Text
              style={[
                styles.author,
                styles.boxMargin,
                styles.inlineHeadDouble,
                { fontWeight: 'bold' },
              ]}
            >
              {pd.node.amount}
            </Text>
          </View>
        );
      })}
      <View style={[styles.author, styles.boxMargin, styles.price]}>
        {props.sale.totalAmount != props.sale.netAmount ? (
          <>
            <Text style={[styles.minFlex]}>
              Total: {props.sale.totalAmount?.toFixed(2)}
            </Text>
            <Text style={[styles.minFlex]}>
              Discount:{' '}
              {((props.sale.totalAmount || 0) - props.sale.netAmount).toFixed(
                2,
              )}
            </Text>
            <Text style={[styles.minFlex]}>
              Price After Discount : {props.sale.netAmount.toFixed(2)}
            </Text>
            <Text>Rs. {inWords(Math.round(props.sale.netAmount))} only</Text>
          </>
        ) : (
          <>
            <Text style={[styles.minFlex]}>
              Total: {props.sale.netAmount.toFixed(2)}
            </Text>
            <Text style={[styles.minFlex]}>
              Rs. {inWords(Math.round(props.sale.netAmount))} only
            </Text>
          </>
        )}
      </View>
      <View style={[styles.boxMargin, { fontSize: 9 }]}>
        <Text style={[styles.minFlex]}>GST Inclusive</Text>
      </View>
      <View style={[styles.inline]}>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            { fontWeight: 'extrabold', textAlign: 'center' },
          ]}
        >
          Thank You!!!
        </Text>
      </View>
      <View>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            { fontWeight: 'extrabold', textAlign: 'center' },
          ]}
        >
          Visit Again!!!
        </Text>
      </View>
    </Page>
  </Document>
);

interface Props {
  sale: SalesNodeEdgeQuery;
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 0,
  },
  title: {
    fontSize: 14,
    marginLeft: '43%',
  },
  author: {
    fontSize: 10,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    color: 'grey',
  },
  dottedBorder: {
    borderTop: '2px dotted black',
    borderBottom: '2px dotted black',
  },

  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  minFlex: { flex: 5 },
  inlineHead: { flex: 9 },
  inlineHeadDouble: { flex: 5 },
  boxMargin: { margin: 1 },
  lowFlexInlineHead: { flex: 0.7 },
  textSize: { fontSize: 8 },
  heading: { fontWeight: 'bold' },
  footer: { marginLeft: '25%', marginRight: 'auto', width: '8em' },
  list: { marginLeft: '10%', textAlign: 'left' },
  price: { marginRight: '10%', textAlign: 'right' },
  snoflex: { flex: 3 },
  paddingTop: { paddingTop: '10px' },
});

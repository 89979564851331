import * as React from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  DatePicker,
  Divider,
  Button,
  Spin,
  AutoComplete,
} from 'antd';
import {
  PurchaseOrderInput,
  ProductNodeConnection,
  ProductNodeEdge,
} from '../../schema';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { TSInput } from '../common/TSInput';
import { ProductDetails, PurchaseOrderError } from './constants';
import { VendorEdges } from '../master/vendor/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { OptionProps } from 'antd/lib/select';
import { AutoCompleteProps } from 'antd/lib/auto-complete';

export const PurchaseOrderForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={6}>
        <DatePicker
          value={props.values.date ? moment(props.values.date) : null}
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Purchase Order Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
        <span style={{ color: 'red' }}>{props.error.date}</span>
      </Col>
      <Col span={18}>
        <TSAutoComplete
          error={props.error.vendorId}
          inputProps={{ name: 'vendorId', placeholder: 'Vendor' }}
          dataSource={props.vendors}
          onValueSelect={props.onChange}
          textValue={getValue(props.vendors, props.values.vendorId)}
          key="id"
          name="vendorId"
          displayKey="name"
          allowClear
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product Name</Col>
        <Col span={3}>Quantity</Col>
        <Col span={2}>Unit</Col>
        <Col span={3}>Rate</Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {(JSON.parse(props.values.productDetails) as ProductDetails[]).map(
        (product, index) => (
          <div key={'products' + index}>
            <Row>
              <Col span={1}>{index + 1}.</Col>
              <Col span={8}>
                {!props.company.name.toLowerCase().includes('jharcraft') &&
                !props.company?.name.toLowerCase().includes('khadi') ? (
                  <ProductDropDown
                    onSelect={(e, f) =>
                      props.productChangeHandler(
                        {
                          target: {
                            name: 'productName',
                            value: f['props']['display'],
                          },
                        },
                        index,
                      )
                    }
                    company={props.company}
                    placeholder
                    value={product.productName}
                  />
                ) : (
                  <TSInput
                    error={props.error.productDetails[index].productName}
                    value={product.productName}
                    onChange={(e) => props.productChangeHandler(e, index)}
                    placeholder="Product Name"
                    name="productName"
                    type="text"
                  />
                )}
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].quantity}
                  type="number"
                  value={product.quantity || ''}
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(e) => props.productChangeHandler(e, index)}
                  required
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error={props.error.productDetails[index].unit}
                  type="text"
                  value={product.unit}
                  name="unit"
                  placeholder="Unit"
                  onChange={(e) => props.productChangeHandler(e, index)}
                  required
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].rate}
                  type="number"
                  value={product.rate || ''}
                  name="rate"
                  onChange={(e) => props.productChangeHandler(e, index)}
                  placeholder="Rate"
                  required
                />
              </Col>
              <Col span={4}>
                <TSInput
                  error=""
                  type="number"
                  value={product.amount || ''}
                  name="amount"
                  placeholder="Amount"
                  required
                />
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="minus"
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    props.updateProductsRow('-', index);
                  }}
                  disabled={
                    (
                      JSON.parse(
                        props.values.productDetails,
                      ) as ProductDetails[]
                    ).length === 1
                  }
                />
              </Col>
              <Col span={1}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    props.updateProductsRow('+', index);
                  }}
                  disabled={
                    product.productName && product.quantity && product.rate
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>
          </div>
        ),
      )}
      <Divider />
      {props.company.name.toLowerCase().includes('tiles') && (
        <Col span={8}>
          <TSInput
            error=""
            type="text"
            value={props.values.shippingAddress}
            onChange={props.onChange}
            name="shippingAddress"
            addonBefore="Shipping Address"
          />
        </Col>
      )}
      {props.company.name.toLowerCase().includes('tiles') && (
        <Col span={8}>
          <TSInput
            error=""
            type="text"
            value={props.values.quotationDetails}
            onChange={props.onChange}
            name="quotationDetails"
            addonBefore="Quotation Number / Date"
          />
        </Col>
      )}
      <Col span={!props.company.name.toLowerCase().includes('tiles') ? 24 : 8}>
        <TSInput
          error=""
          type="text"
          value={props.values.remarks || ''}
          onChange={props.onChange}
          name="remarks"
          addonBefore="Remarks"
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: PurchaseOrderInput;
  error: PurchaseOrderError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  vendors: VendorEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  company: CompanyNodeEdge;
}

import * as React from 'react';
import {
  CustomerNodeEdge,
  CustomerError,
  customerObject,
  customerErrorObject,
} from './constants';
import { CustomerForm } from './CustomerForm';
import { Button, notification } from 'antd';
import { CustomerService } from '../../../service/CustomerService';
import { CompanyNodeEdge } from '../Company/constants';

export class AddCustomer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      customer: props.customer || JSON.parse(JSON.stringify(customerObject)),
      error: { ...customerErrorObject },
      buttonDisabled: false,
    };
  }

  customerService = new CustomerService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      customer:
        nextProps.customer || JSON.parse(JSON.stringify(customerObject)),
    });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const customer = { ...this.state.customer };
    customer[e.target.name] = e.target.value;
    this.setState({ customer });
  };

  customError = () => {
    const customer = { ...this.state.customer };
    const error = { ...this.state.error };
    let isError = false;
    const datePattern = /^[0-3][0-9]-[0-1][0-9]-[0-9]{4}$/;
    if (customer.dateOfBirth && !datePattern.test(customer.dateOfBirth)) {
      isError = true;
      error.dateOfBirth = `Correct Format is: DD-MM-YYYY`;
    } else error.dateOfBirth = '';
    if (
      customer.dateOfAnniversary &&
      !datePattern.test(customer.dateOfAnniversary)
    ) {
      isError = true;
      error.dateOfAnniversary = `Correct Format is: DD-MM-YYYY`;
    } else error.dateOfAnniversary = '';
    this.setState({ error });
    return isError;
  };

  saveCustomer = () => {
    if (this.customError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const customer = { ...this.state.customer };
    if (customer.dateOfBirth)
      customer.dateOfBirth = customer.dateOfBirth
        .split('-')
        .reverse()
        .join('-');
    if (customer.dateOfAnniversary)
      customer.dateOfAnniversary = customer.dateOfAnniversary
        .split('-')
        .reverse()
        .join('-');
    this.customerService?.addCustomer(
      customer,
      (response) => {
        notification.success({
          message: 'Customer ' + customer.id ? 'Updated' : 'Added',
          description: `Customer named ${customer.name} was successfuly ${
            customer.id ? 'Updated' : 'Added'
          }`,
        });
        this.clear();
        this.props.onCustomerAdded && this.props.onCustomerAdded();
        this.props.handleTabChange && this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message: 'Customer ' + customer.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      customer: JSON.parse(JSON.stringify(customerObject)),
      error: { ...customerErrorObject },
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <CustomerForm
          values={this.state.customer}
          error={this.state.error}
          onChange={this.onChange}
          company={this.props.company}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveCustomer}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  onCustomerAdded?: () => void;
  customer?: CustomerNodeEdge;
  company: CompanyNodeEdge;
  handleTabChange?: (key) => void;
}

interface State {
  customer: CustomerNodeEdge;
  error: CustomerError;
  buttonDisabled: boolean;
}

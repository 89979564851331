import * as React from 'react';
import { Button, notification } from 'antd';
import {
  DeliveryInstructionNode,
  SaleChallanInput,
  SaleChallanNode,
  SaleChallanProductDetails,
} from '../../schema';
import { SaleInvoiceForm } from './SaleInvoiceForm';
import {
  saleChallanObject,
  saleChallanErrorObj,
  SaleChallanError,
} from './constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { SaleInvoiceService } from '../../service/SaleInvoiceService';
import { ProductDetailsNodeConnection } from '../Sales/constants';

export class AddSaleInvoice extends React.Component<Props, State> {
  saleChallanService = new SaleInvoiceService();

  constructor(props: Props) {
    super(props);
    this.state = {
      saleChallan: this.handlePropsValuesUpdate(props),
      error: this.handlePropsErrorUpdate(props),
      buttonDisabled: false,
      deliveryInstruction: props.saleChallan
        ? props.saleChallan.deliveryInstruction
        : null,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const deliveryInstruction: DeliveryInstructionNode | undefined =
      nextProps?.saleChallan?.deliveryInstruction;
    this.setState({
      saleChallan: this.handlePropsValuesUpdate(nextProps),
      error: this.handlePropsErrorUpdate(nextProps),
      deliveryInstruction,
    });
  }

  handlePropsValuesUpdate = (props: Props) => {
    return props.saleChallan
      ? {
          ...props.saleChallan,
          deliveryInstructionId: props.saleChallan.deliveryInstruction?.id,
          productDetails: this.handleProductObject(props),
        }
      : JSON.parse(JSON.stringify(saleChallanObject));
  };

  handleProductObject = (props: Props) => {
    if (props.saleChallan?.deliveryInstruction) {
      const productFromDeliveryInstruction: SaleChallanProductDetails[] =
        this.productDeliveryHandler(props.saleChallan.deliveryInstruction);
      return productFromDeliveryInstruction.map((obj, index) => {
        const productDetail = (
          props.saleChallan
            ?.productDetails as any as ProductDetailsNodeConnection
        ).edges.find((pd) => {
          return pd.node.product?.id === obj.productId;
        });
        return {
          ...obj,
          quantity: productDetail ? productDetail.node.quantity : obj.quantity,
          rate: productDetail ? productDetail.node.rate : obj.rate,
        };
      });
    }
  };

  handlePropsErrorUpdate = (nextProps: Props) => {
    const error: SaleChallanError = JSON.parse(
      JSON.stringify(saleChallanErrorObj),
    );
    if (nextProps.saleChallan && nextProps.saleChallan?.deliveryInstruction) {
      const productFromDeliveryInstruction: SaleChallanProductDetails[] =
        this.productDeliveryHandler(nextProps.saleChallan.deliveryInstruction);
      productFromDeliveryInstruction.forEach(() => {
        if (
          productFromDeliveryInstruction.length !== error.productDetails.length
        ) {
          error.productDetails.push({
            quantity: '',
          });
        }
      });
    }
    return error;
  };

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const saleChallan = { ...this.state.saleChallan };
    saleChallan[e.target.name] = e.target.value;
    this.setState({ saleChallan });
  };

  checkError = () => {
    const error = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    Object.keys(saleChallanErrorObj).forEach((errKey) => {
      if (
        !this.state.saleChallan[errKey] &&
        ['productDetails'].indexOf(errKey) === -1
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const productDetails: SaleChallanProductDetails[] = JSON.parse(
      JSON.stringify(this.state.saleChallan.productDetails),
    );
    const error: SaleChallanError = JSON.parse(
      JSON.stringify(this.state.error),
    );
    let isError = false;
    const productDetailObj = productDetails.find((pd) => !!Number(pd.quantity));
    if (!productDetailObj) {
      isError = true;
      error.productDetails[
        error.productDetails.length - 1
      ].quantity = `At least 1 Product must be delivered`;
      this.setState({ error });
      return isError;
    }
    const firstGstRate = productDetailObj.product?.hsn?.gst;
    productDetails.forEach((pd, index) => {
      if (
        pd.remainingQuantity &&
        pd.quantity &&
        pd.remainingQuantity < pd.quantity
      ) {
        isError = true;
        error.productDetails[
          index
        ].quantity = `Delivery quantity can't be greater than remaining quantity`;
      }
    });
    const productDetWithDiffGst = productDetails.find(
      (pd) => Number(pd.quantity) && pd.product?.hsn?.gst !== firstGstRate,
    );
    if (productDetWithDiffGst) {
      isError = true;
      error.productDetails[error.productDetails.length - 1].quantity =
        'Different GST Rates are not allowed in 1 invoice';
    }
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const { saleChallan } = this.state;
    saleChallan.productDetails[index][e.target.name] = e.target.value;
    saleChallan.productDetails[index].amount =
      (saleChallan.productDetails[index].rate || 0) *
      Number(saleChallan.productDetails[index].quantity);
    saleChallan.productDetails[index].amount =
      (saleChallan.productDetails[index].amount || 0) +
      (saleChallan.productDetails[index].amount || 0) *
        ((saleChallan.productDetails[index].gstRate || 0) / 100);
    this.calculateAmounts(saleChallan);
    this.setState({ saleChallan });
  };

  calculateAmounts = (saleChallan: SaleChallanInput) => {
    const { deliveryInstruction } = this.state;
    const totalDeliveryQty = (
      deliveryInstruction?.productDetails as any as ProductDetailsNodeConnection
    ).edges.reduce((qty, pde) => Number(qty + (pde.node.quantity || 0)), 0);
    const totalInvoiceQty = saleChallan.productDetails.reduce(
      (qty, pd) => Number(qty + (pd.quantity || 0)),
      0,
    );
    saleChallan.miscellaneousCharge = Number(
      (
        ((deliveryInstruction?.layingAndFittingCharge || 0) /
          totalDeliveryQty) *
        totalInvoiceQty
      ).toFixed(2),
    );
    saleChallan.transportationCharges = Number(
      (
        ((deliveryInstruction?.transportationCharge || 0) / totalDeliveryQty) *
        totalInvoiceQty
      ).toFixed(2),
    );
    saleChallan.otherCharges = Number(
      (
        ((deliveryInstruction?.otherCharges || 0) / totalDeliveryQty) *
        totalInvoiceQty
      ).toFixed(2),
    );
    this.setState({ saleChallan });
  };

  productDeliveryHandler = (
    deliveryInstructionObj: DeliveryInstructionNode,
  ) => {
    return deliveryInstructionObj.saleChallanProductDetails as any[];
  };

  deliveryInstructionObjChangeHandler = (
    deliveryInstructionObj: DeliveryInstructionNode,
  ) => {
    const nextState = { ...this.state };
    nextState.saleChallan.deliveryInstructionId = deliveryInstructionObj.id;
    nextState.deliveryInstruction = deliveryInstructionObj;
    nextState.saleChallan.productDetails = this.productDeliveryHandler(
      deliveryInstructionObj,
    );
    nextState.saleChallan &&
      nextState.saleChallan.productDetails.forEach(() => {
        if (
          nextState.saleChallan.productDetails.length !==
          nextState.error.productDetails.length
        ) {
          nextState.error.productDetails.push({
            quantity: '',
          });
        }
      });
    this.setState(nextState);
  };

  saveChallan = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const { saleChallan } = this.state;

    saleChallan.productDetails = saleChallan.productDetails.filter(
      (pd) => !!Number(pd.quantity),
    );
    const totalProductAmount = saleChallan.productDetails.reduce(
      (sum, obj) => sum + (obj.amount || 0),
      0,
    );
    const extraCharges =
      Number(saleChallan.miscellaneousCharge) +
      Number(saleChallan.transportationCharges) +
      Number(saleChallan.otherCharges);
    saleChallan.totalAmount =
      totalProductAmount +
      extraCharges +
      (extraCharges * (saleChallan.productDetails[0].gstRate || 0)) / 100;
    this.saleChallanService?.addSaleChallan(
      saleChallan,
      (response) => {
        notification.success({
          message:
            'Sale Invoice ' + this.props.saleChallan ? 'Updated' : 'Added',
          description:
            `Invoice for order no ${this.state.deliveryInstruction?.deliveryInstructionNo}` +
            ` was successfully ${this.props.saleChallan ? 'Updated' : 'Added'}`,
        });
        this.clear();
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Invoice ' + this.state.saleChallan.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      saleChallan: JSON.parse(JSON.stringify(saleChallanObject)),
      error: JSON.parse(JSON.stringify(saleChallanErrorObj)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <SaleInvoiceForm
          values={this.state.saleChallan}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          deliveryInstructionObjChangeHandler={
            this.deliveryInstructionObjChangeHandler
          }
          deliveryInstruction={this.state.deliveryInstruction}
          company={this.props.company}
          error={this.state.error}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveChallan}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  saleChallan?: SaleChallanNode | null;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
}

interface State {
  saleChallan: SaleChallanInput;
  error: SaleChallanError;
  buttonDisabled: boolean;
  deliveryInstruction?: DeliveryInstructionNode | null;
}

import {
  ConversionInput,
  RemunerationDetailNodeConnection,
  RemunerationDetailInput,
} from '../../../schema';
import moment from 'moment';

export const conversionObject: ConversionInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  productionType: 'HANDLOOM',
  rawMaterials: [
    {
      date: '',
      productId: '',
      rate: null,
      purchaseRate: null,
      quantity: null,
      amount: null,
      peduncleQuantity: 0,
      wastage: 0,
      movementType: 'CONVERSION',
      companyId: '',
      remark: null,
    },
  ],
  remunerations: [
    {
      date: '',
      remunerationId: '',
      rate: null,
      quantity: null,
      supervisionCharge: null,
      amount: null,
      companyId: '',
      remark: null,
    },
  ],
  companyId: '',
  finishedProduct: '',
  rawMaterialsCost: 0,
  remunerationsCost: 0,
  netCost: 0,
  designCode: null,
};

export const PRODUCTION_TYPE = ['HANDLOOM', 'HANDICRAFT', 'SERICULTURE'];
export const HIND_PROD_TYPE = ['PAVER TILES'];

export const conversionMutation = `
mutation addConversion($input: CreateConversionInput!) {
  createConversion(input: $input){
    newConversion{
      id
      finishedProduct
      remunerations {
        edges {
          node {
            id
            remuneration {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const conversionQuery = `
query ConversionList($companyId: ID, $after: String, $product: String) {
  allConversions(companyId: $companyId, first: 20, after: $after, finishedProduct_Icontains: $product) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        date
        productionType
        finishedProduct
        designCode
        oldDesignCode
        remark
        rawMaterialsCost
        remunerationsCost
        netCost
        image
        rawMaterials {
          edges {
            node {
              id
              product {
                id
                name
                oldBarcodeId
              }
              rate
              purchaseRate
              quantity
              remark
              wastage
              peduncleQuantity
              amount
              movementType
            }
          }
        }
        remunerations {
          edges {
            node {
              id
              remuneration {
                id
                name
              }
              quantity
              rate
              supervisionCharge
              amount
              remark
            }
          }
        }
      }
    }
  }
}
`;

export const handleRemunerationDetailData = (
  remunerationDetail: RemunerationDetailNodeConnection,
): RemunerationDetailInput[] => {
  if (!remunerationDetail.hasOwnProperty('edges')) return [];
  return remunerationDetail.edges.map((rd) => ({
    ...rd.node,
    date: rd.node?.date,
    remunerationId: rd.node?.remuneration?.id,
    companyId: rd.node?.company?.id,
  }));
};

import * as React from 'react';
import { CustomerNodeEdge, customerQuery } from './constants';
import { Table, Tabs, Icon, Spin, Row, Col, Skeleton, Button } from 'antd';
import { CustomerService } from '../../../service/CustomerService';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';
import { SpecialDateReport } from './SpecialDateReport';
import { AddCustomer } from './AddCustomer';
import { TSInput } from '../../common/TSInput';
import { GraphqlQuery } from 'requestapijs';
import { CustomerNodeConnection } from '../../../schema';
import { Pagination } from 'sha-el-design';
import { CUSTOMER_LIST_CSV_URL } from '../../../config';
import { RouteComponentProps } from 'react-router';
import { checkAddPermission, downloadToFile } from '../../helper';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class Customer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedCustomer: null,
      company: null,
      name: '',
      after: '',
      currentPage: 1,
      user: null,
    };
  }

  customerService = new CustomerService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedCustomer: null });
      return;
    }
    this.setState({ activeKey });
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CUSTOMER_LIST_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then(
        (v) =>
          this.state.company &&
          downloadToFile(
            v,
            `customer-list-${new Date()}`,
            'CUSTOMER LIST',
            this.state.company.name,
            'Head Office',
            '',
            '',
            '',
          ),
      );
  };

  customerColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() =>
            this.setState({ selectedCustomer: obj, activeKey: '2' })
          }
          children={text}
        />
      ),
    },
    {
      title: 'Address',
      dataIndex: 'node.address',
      key: 'address',
    },
    {
      title: 'Contact No',
      dataIndex: 'node.contactNo',
      key: 'contactNo',
    },
    {
      title: 'Email',
      dataIndex: 'node.email',
      key: 'email',
    },
    {
      title: 'Gstin No',
      dataIndex: 'node.gstinNumber',
      key: 'gstinNumber',
    },
    {
      title: 'Gstin Legal Name',
      dataIndex: 'node.gstinLegalName',
      key: 'gstLegalName',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) return <Spin />;
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { company, name, after, currentPage, activeKey } = this.state;
    const variables = {
      companyId: company.id,
      after,
      name,
      activeKey,
      updateCount: this.props.updateCount,
    };

    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Customer
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                name="name"
                type="text"
                addonBefore="Search Customer"
                placeholder="Enter Name or Contact No."
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={customerQuery}
            variables={name ? { ...variables, name } : variables}
            render={(
              response: { allCustomers: CustomerNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && company) {
                return [
                  <Table
                    key="Table"
                    dataSource={response.allCustomers.edges}
                    columns={this.customerColumn()}
                    rowKey={(record) => record.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={Infinity}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allCustomers.pageInfo.endCursor,
                        response.allCustomers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
          {
            <Button
              key="download"
              style={{ float: 'right' }}
              icon="download"
              onClick={() => this.downloadCsv(variables)}
            />
          }
        </Tabs.TabPane>
        {(!this.props.showOnlyList || this.state.activeKey === '2') && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Customer
              </span>
            }
            key="2"
          >
            <AddCustomer
              company={company}
              onCustomerAdded={() => this.setState({})}
              customer={this.state.selectedCustomer?.node}
              handleTabChange={this.handleTabChange}
            />
          </Tabs.TabPane>
        )}
        {company.name.toLowerCase().includes('pearl') &&
          !this.props.isReportsNotVisible && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Special Date Report
                </span>
              }
              key="3"
            >
              <SpecialDateReport company={company} />
            </Tabs.TabPane>
          )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedCustomer: {
    cursor: string;
    node: CustomerNodeEdge;
  } | null;
  company: CompanyNodeEdge | null;
  name: string;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {
  isReportsNotVisible?: boolean;
  updateCount?: number;
  showOnlyList: boolean;
}

import * as React from 'react';
import { IssueInput, ProductNode } from '../../../schema';
import { Row, DatePicker, Col, Divider, Button } from 'antd';
import moment from 'moment';
import { TSInput } from '../../common/TSInput';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { ProductDropDown } from '../../master/Product/ProductDropdown';
import { IssueError } from './constants';

export const IssueForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(e) =>
              props.onChange({
                target: {
                  name: 'date',
                  value: e && moment(e).format('YYYY-MM-DD'),
                },
              })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
          <span>{props.error.date}</span>
        </Col>
        <Col span={12}>
          <TSInput
            error={''}
            type="text"
            value={props.values.remarks}
            name="remarks"
            placeholder="Remarks"
            onChange={(e) => props.onChange(e)}
            addonBefore="Remarks"
          />
        </Col>
      </Row>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product</Col>
        <Col span={6}>Quantity</Col>
        <Col span={6}>Remarks</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails.map((productDetail, index) => (
        <div key={'productDetails' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={8}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.productObjChangeHandler((f as any).props.label, index);
                  props.productDetailChangeHandler(
                    { target: { name: 'productId', value: String(e) } },
                    index,
                  );
                }}
                allowClear
                extrafields={[
                  'sellingRate',
                  'purchaseRate',
                  'gstRate',
                  'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                ]}
                company={props.company}
                value={productDetail.productId || ''}
                productTypeIn="RAW_MATERIAL,SEMI_FINISHED"
              />
              <span style={{ color: 'red' }}>
                {props.error.productDetails[index].productId}
              </span>
            </Col>
            <Col span={6}>
              <TSInput
                error={props.error.productDetails[index].quantity}
                type="number"
                value={productDetail.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.productDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={6}>
              <TSInput
                error=""
                type="text"
                value={productDetail.remark || ''}
                name="remark"
                placeholder="Remarks"
                onChange={(e) => props.productDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('-', index)}
                disabled={props.values.productDetails.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('+', index)}
              />
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

interface Props {
  values: IssueInput;
  error: IssueError;
  company: CompanyNodeEdge;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productDetailChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateRow: (type: string, index: number) => void;
  productObjChangeHandler: (productObj: ProductNode, index: number) => void;
}

import * as React from 'react';
import { Layout, Menu, Icon, Avatar, Popover, Drawer, Spin } from 'antd';
import { style } from 'typestyle/lib';
import { capitalize } from 'lodash';
import DashboardRouter from './DashboardRouter';
import { match, RouterProps } from 'react-router';
import { User } from '../user/constants';
import { UserPanel } from '../user/UserPanel';
import { UserService } from '../../service/UserService';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { CompanyPanel } from '../master/Company/CompanyPanel';
import { ProductionLink } from './ProductionLink';
import { MasterLink } from './MasterLink';
import { InventoryLink } from './InventoryLink';
import { SideBarMenu } from '../../constants';

export class Dashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      user: null,
      company: null,
      panelOpen: false,
      bussiness: null,
    };
  }

  userService = new UserService();
  companyService = new CompanyService();

  componentDidMount() {
    this.userService?.login(
      { token: localStorage.getItem('token') },
      (user) => {
        const response = user && user.response;
        this.setState({ user: response });
      },
      (error) => error,
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((bussiness) =>
      this.setState({ bussiness }),
    );
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  logoStyle = style({
    fontSize: '20px',
    textAlign: 'center',
    color: '#001529',
    background: '#94C5CC',
  });

  iconStyle = {
    backgroundColor: 'white',
    color: '#555',
    cursor: 'pointer',
  };

  avatarContainerStyle = style({
    display: 'inline-block',
    float: 'right',
    marginRight: '10px',
    $nest: {
      '&::after': {
        clear: 'both',
      },
    },
  });

  bussinessContainerStyle: React.CSSProperties = {
    border: '1px solid #ccc',
    borderLeft: 'none',
    borderRadius: '0 5px 5px 0',
    padding: '5px',
  };

  checkUserGroup = (group: string) => {
    const userGroup = this.state.user?.user?.user?.groups;

    for (const indx in userGroup) {
      if (userGroup[indx].name === group) return Number(indx);
    }

    return -1;
  };

  jharcraftSideBar = (
    master: SideBarMenu,
    inventory: SideBarMenu,
    production: SideBarMenu,
  ) => {
    const {
      BRANCH,
      UNIT,
      PRODUCT_CATEGORY,
      PRODUCT_SUB_CATEGORY,
      HSN,
      PRODUCT,
      VENDOR,
      REMUNERATION,
      SALES_PERSON,
    } = master;
    const {
      OPENING_STOCK,
      SALE,
      VALUE_ADDITION,
      APPROVAL,
      PURCHASE_ORDER,
      PURCHASE_ORDER_REPORT,
      CHALLAN,
      MARGIN,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      RECEIVING,
      DAMAGE,
      CODE_CONVERSION,
      STOCK_REPORT,
      REQUEST,
      GIFT,
      DEPOSIT,
    } = inventory;
    const {
      CONVERSION,
      PRODUCTION_REPORT,
      PRODUCTION_TRANSFER,
      PRODUCTION_RECEIVING,
      JOB_ORDER,
    } = production;

    if (this.state.bussiness?.type === 'company') {
      // For Head Inventory
      if (this.checkUserGroup('Inventory') > -1) {
        return [
          <Menu.SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="mail" />
                <span>Master</span>
              </span>
            }
          >
            {[
              BRANCH,
              UNIT,
              PRODUCT_SUB_CATEGORY,
              PRODUCT_CATEGORY,
              HSN,
              PRODUCT,
              VENDOR,
              REMUNERATION,
            ]}
          </Menu.SubMenu>,
          OPENING_STOCK,
          SALE,
          VALUE_ADDITION,
          APPROVAL,
          <Menu.SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="shopping-cart" />
                <span>Purchase Menu</span>
              </span>
            }
          >
            {[PURCHASE_ORDER, PURCHASE_ORDER_REPORT, CHALLAN, PURCHASE, MARGIN]}
          </Menu.SubMenu>,
          PURCHASE_RETURN,
          TRANSFER,
          RECEIVING,
          DAMAGE,
          CODE_CONVERSION,
          STOCK_REPORT,
          REQUEST,
          <Menu.SubMenu
            title={
              <span>
                <Icon type="thunderbolt" />
                <span>Production</span>
              </span>
            }
            key="sub3"
          >
            {[CONVERSION]}
          </Menu.SubMenu>,
        ];
      }

      // For Head Production
      if (this.checkUserGroup('Production') > -1) {
        const jharcraftHeadProduction = [
          VALUE_ADDITION,
          TRANSFER,
          RECEIVING,
          STOCK_REPORT,
        ];
        this.state.company?.withProduction &&
          jharcraftHeadProduction.push(
            <Menu.SubMenu
              title={
                <span>
                  <Icon type="thunderbolt" />
                  <span>Production</span>
                </span>
              }
              key="sub1"
            >
              {[
                CONVERSION,
                PRODUCTION_REPORT,
                PRODUCTION_TRANSFER,
                PRODUCTION_RECEIVING,
                JOB_ORDER,
              ]}
            </Menu.SubMenu>,
          );
        return jharcraftHeadProduction;
      }

      // For Head Marketing
      if (this.checkUserGroup('Marketing') > -1) {
        return [
          VALUE_ADDITION,
          APPROVAL,
          REQUEST,
          SALE,
          STOCK_REPORT,
          <Menu.SubMenu
            title={
              <span>
                <Icon type="thunderbolt" />
                <span>Production</span>
              </span>
            }
            key="sub3"
          >
            {[CONVERSION]}
          </Menu.SubMenu>,
        ];
      }

      // For Head without above userGroup or no userGroup
      return [
        VALUE_ADDITION,
        APPROVAL,
        REQUEST,
        <Menu.SubMenu
          title={
            <span>
              <Icon type="thunderbolt" />
              <span>Production</span>
            </span>
          }
          key="sub3"
        >
          {[CONVERSION]}
        </Menu.SubMenu>,
      ];
    }

    // For Emporium Branch
    if (this.state.bussiness?.category === 'EMPORIUM') {
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[PRODUCT, SALES_PERSON]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        GIFT,
        TRANSFER,
        RECEIVING,
        DEPOSIT,
        STOCK_REPORT,
        REQUEST,
        <Menu.SubMenu
          title={
            <span>
              <Icon type="thunderbolt" />
              <span>Production</span>
            </span>
          }
          key="sub2"
        >
          {[CONVERSION]}
        </Menu.SubMenu>,
      ];
    }

    // For Finishing Unit and Regional Office Branch
    if (
      this.state.bussiness?.category &&
      ['FINISHING_UNIT', 'REGIONAL_OFFICE'].indexOf(
        this.state.bussiness?.category,
      ) > -1
    ) {
      const jharcraftBranch = [
        PRODUCT,
        OPENING_STOCK,
        VALUE_ADDITION,
        TRANSFER,
        RECEIVING,
        STOCK_REPORT,
      ];
      this.state.company?.withProduction &&
        jharcraftBranch.push(
          <Menu.SubMenu
            title={
              <span>
                <Icon type="thunderbolt" />
                <span>Production</span>
              </span>
            }
            key="sub1"
          >
            {[
              CONVERSION,
              PRODUCTION_REPORT,
              PRODUCTION_TRANSFER,
              PRODUCTION_RECEIVING,
              JOB_ORDER,
            ]}
          </Menu.SubMenu>,
        );
      return jharcraftBranch;
    }

    // For Mela Branch
    if (this.state.bussiness?.category === 'MELA') {
      return [
        PRODUCT,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        GIFT,
        TRANSFER,
        RECEIVING,
        DEPOSIT,
        STOCK_REPORT,
        REQUEST,
        <Menu.SubMenu
          title={
            <span>
              <Icon type="thunderbolt" />
              <span>Production</span>
            </span>
          }
          key="sub3"
        >
          {[CONVERSION]}
        </Menu.SubMenu>,
      ];
    }

    // For remaining branches
    return [
      PRODUCT,
      OPENING_STOCK,
      VALUE_ADDITION,
      TRANSFER,
      RECEIVING,
      STOCK_REPORT,
      <Menu.SubMenu
        title={
          <span>
            <Icon type="thunderbolt" />
            <span>Production</span>
          </span>
        }
        key="sub3"
      >
        {[CONVERSION]}
      </Menu.SubMenu>,
    ];
  };

  pearlHouseSideBar = (master: SideBarMenu, inventory: SideBarMenu) => {
    const { BRANCH, UNIT, PRODUCT_CATEGORY, PRODUCT, VENDOR, CUSTOMER, USER } =
      master;
    const {
      OPENING_STOCK,
      SALE,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      DAMAGE,
      STOCK_REPORT,
      APPROVAL,
    } = inventory;
    if (this.state.bussiness?.type === 'company') {
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[BRANCH, UNIT, PRODUCT_CATEGORY, PRODUCT, VENDOR, CUSTOMER, USER]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        PURCHASE,
        PURCHASE_RETURN,
        TRANSFER,
        DAMAGE,
        STOCK_REPORT,
      ];
    }
    return [PRODUCT, OPENING_STOCK, SALE, APPROVAL, TRANSFER, STOCK_REPORT];
  };

  fashionSideBar = (master: SideBarMenu, inventory: SideBarMenu) => {
    const {
      BRANCH,
      UNIT,
      PRODUCT_CATEGORY,
      HSN,
      PRODUCT,
      VENDOR,
      REMUNERATION,
      USER,
    } = master;
    const {
      OPENING_STOCK,
      SALE,
      PURCHASE_ORDER,
      CHALLAN,
      MARGIN,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      RECEIVING,
      DAMAGE,
      STOCK_REPORT,
      VALUE_ADDITION,
      APPROVAL,
      GIFT,
    } = inventory;
    if (this.state.bussiness?.type === 'company') {
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[
            BRANCH,
            UNIT,
            PRODUCT_CATEGORY,
            HSN,
            PRODUCT,
            VENDOR,
            REMUNERATION,
            USER,
          ]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        <Menu.SubMenu
          key="sub2"
          title={
            <span>
              <Icon type="shopping-cart" />
              <span>Purchase Menu</span>
            </span>
          }
        >
          {[PURCHASE_ORDER, CHALLAN, MARGIN, PURCHASE]}
        </Menu.SubMenu>,
        PURCHASE_RETURN,
        TRANSFER,
        RECEIVING,
        DAMAGE,
        STOCK_REPORT,
      ];
    }
    return [
      PRODUCT,
      OPENING_STOCK,
      SALE,
      VALUE_ADDITION,
      APPROVAL,
      GIFT,
      TRANSFER,
      RECEIVING,
      STOCK_REPORT,
    ];
  };

  sevaSadanSideBar = (master: SideBarMenu, inventory: SideBarMenu) => [
    <Menu.SubMenu
      key="sub1"
      title={
        <span>
          <Icon type="mail" />
          <span>Master</span>
        </span>
      }
    >
      {[
        master.BRANCH,
        master.UNIT,
        master.PRODUCT_CATEGORY,
        master.HSN,
        master.PRODUCT,
        master.VENDOR,
      ]}
    </Menu.SubMenu>,
    inventory.OPENING_STOCK,
    inventory.SALE,
    inventory.PURCHASE,
    inventory.PURCHASE_RETURN,
    inventory.TRANSFER,
    inventory.DAMAGE,
    inventory.STOCK_REPORT,
  ];

  companyWithTilesSideBar = (
    master: SideBarMenu,
    inventory: SideBarMenu,
    production: SideBarMenu,
  ) => [
    <Menu.SubMenu
      key="sub1"
      title={
        <span>
          <Icon type="mail" />
          <span>Master</span>
        </span>
      }
    >
      {[
        master.VENDOR,
        master.UNIT,
        master.PRODUCT_CATEGORY,
        master.HSN,
        master.PRODUCT,
        master.CUSTOMER,
      ]}
    </Menu.SubMenu>,
    inventory.OPENING_STOCK,
    inventory.PURCHASE,
    inventory.PURCHASE_RETURN,
    inventory.DELIVERY_INSTRUCTION,
    inventory.SALE_INVOICE,
    inventory.DAMAGE,
    inventory.ISSUE,
    production.QUOTATION,
    inventory.PURCHASE_ORDER,
    inventory.STOCK_REPORT,
  ];

  khadiSideBar = (
    master: SideBarMenu,
    inventory: SideBarMenu,
    production: SideBarMenu,
  ) => {
    const {
      BRANCH,
      UNIT,
      PRODUCT_CATEGORY,
      PRODUCT_SUB_CATEGORY,
      HSN,
      PRODUCT,
      VENDOR,
      REMUNERATION,
      DISCOUNT_CATEGORY,
      DISCOUNT,
    } = master;
    const {
      OPENING_STOCK,
      SALE,
      VALUE_ADDITION,
      APPROVAL,
      PURCHASE_ORDER,
      PURCHASE_ORDER_REPORT,
      CHALLAN,
      MARGIN,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      RECEIVING,
      DAMAGE,
      CODE_CONVERSION,
      STOCK_REPORT,
      REQUEST,
      GIFT,
      DEPOSIT,
      MEMO,
    } = inventory;
    const {
      CONVERSION,
      PRODUCTION_REPORT,
      PRODUCTION_TRANSFER,
      PRODUCTION_RECEIVING,
      JOB_ORDER,
    } = production;

    if (this.state.bussiness?.type === 'company') {
      // For Head Inventory
      if (this.checkUserGroup('Inventory') > -1) {
        return [
          <Menu.SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="mail" />
                <span>Master</span>
              </span>
            }
          >
            {[
              BRANCH,
              UNIT,
              PRODUCT_SUB_CATEGORY,
              PRODUCT_CATEGORY,
              HSN,
              PRODUCT,
              VENDOR,
              REMUNERATION,
              DISCOUNT_CATEGORY,
              DISCOUNT,
            ]}
          </Menu.SubMenu>,
          OPENING_STOCK,
          SALE,
          VALUE_ADDITION,
          APPROVAL,
          <Menu.SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="shopping-cart" />
                <span>Purchase Menu</span>
              </span>
            }
          >
            {[PURCHASE_ORDER, PURCHASE_ORDER_REPORT, CHALLAN, PURCHASE, MARGIN]}
          </Menu.SubMenu>,
          PURCHASE_RETURN,
          TRANSFER,
          RECEIVING,
          MEMO,
          DAMAGE,
          CODE_CONVERSION,
          STOCK_REPORT,
          REQUEST,
        ];
      }

      // For Head Production
      if (this.checkUserGroup('Production') > -1) {
        const khadiHeadProduction = [
          VALUE_ADDITION,
          TRANSFER,
          RECEIVING,
          STOCK_REPORT,
        ];
        this.state.company?.withProduction &&
          khadiHeadProduction.push(
            <Menu.SubMenu
              title={
                <span>
                  <Icon type="thunderbolt" />
                  <span>Production</span>
                </span>
              }
              key="sub1"
            >
              {[
                CONVERSION,
                PRODUCTION_REPORT,
                PRODUCTION_TRANSFER,
                PRODUCTION_RECEIVING,
                JOB_ORDER,
              ]}
            </Menu.SubMenu>,
          );
        return khadiHeadProduction;
      }

      // For Head Marketing
      if (this.checkUserGroup('Marketing') > -1) {
        return [VALUE_ADDITION, APPROVAL, REQUEST, SALE, STOCK_REPORT];
      }

      // For Head without above userGroup or no userGroup
      return [VALUE_ADDITION, APPROVAL, REQUEST];
    }

    // For Emporium Branch
    if (this.state.bussiness?.category === 'EMPORIUM') {
      return [
        PRODUCT,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        GIFT,
        TRANSFER,
        RECEIVING,
        DEPOSIT,
        STOCK_REPORT,
        REQUEST,
      ];
    }

    // For Finishing Unit and Regional Office Branch
    if (
      this.state.bussiness?.category &&
      ['FINISHING_UNIT', 'REGIONAL_OFFICE'].indexOf(
        this.state.bussiness?.category,
      ) > -1
    ) {
      const khadiBranch = [
        PRODUCT,
        OPENING_STOCK,
        VALUE_ADDITION,
        TRANSFER,
        RECEIVING,
        STOCK_REPORT,
      ];
      this.state.company?.withProduction &&
        khadiBranch.push(
          <Menu.SubMenu
            title={
              <span>
                <Icon type="thunderbolt" />
                <span>Production</span>
              </span>
            }
            key="sub1"
          >
            {[
              PRODUCTION_REPORT,
              PRODUCTION_TRANSFER,
              PRODUCTION_RECEIVING,
              JOB_ORDER,
            ]}
          </Menu.SubMenu>,
        );
      return khadiBranch;
    }

    // For Mela Branch
    if (this.state.bussiness?.category === 'MELA') {
      return [
        PRODUCT,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        GIFT,
        TRANSFER,
        RECEIVING,
        DEPOSIT,
        STOCK_REPORT,
        REQUEST,
      ];
    }

    // For remaining branches
    return [
      PRODUCT,
      OPENING_STOCK,
      VALUE_ADDITION,
      TRANSFER,
      RECEIVING,
      STOCK_REPORT,
    ];
  };

  budhiaAgenciesSideBar = (master: SideBarMenu, inventory: SideBarMenu) => {
    const { BRANCH, UNIT, PRODUCT_CATEGORY, PRODUCT, VENDOR, CUSTOMER, USER } =
      master;
    const {
      OPENING_STOCK,
      SALE,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      DAMAGE,
      STOCK_REPORT,
      APPROVAL,
    } = inventory;
    if (this.state.bussiness?.type === 'company') {
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[BRANCH, UNIT, PRODUCT_CATEGORY, PRODUCT, VENDOR, CUSTOMER, USER]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        PURCHASE,
        PURCHASE_RETURN,
        TRANSFER,
        DAMAGE,
        STOCK_REPORT,
      ];
    }
    return [PRODUCT, OPENING_STOCK, SALE, APPROVAL, TRANSFER, STOCK_REPORT];
  };

  sugarHighSideBar = (
    master: SideBarMenu,
    inventory: SideBarMenu,
    production: SideBarMenu,
  ) => {
    const {
      BRANCH,
      UNIT,
      PRODUCT_CATEGORY,
      PRODUCT_SUB_CATEGORY,
      HSN,
      PRODUCT,
      VENDOR,
      REMUNERATION,
      DISCOUNT_CATEGORY,
      DISCOUNT,
    } = master;
    const {
      OPENING_STOCK,
      SALE,
      VALUE_ADDITION,
      APPROVAL,
      PURCHASE_ORDER,
      PURCHASE_ORDER_REPORT,
      CHALLAN,
      MARGIN,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      RECEIVING,
      DAMAGE,
      CODE_CONVERSION,
      STOCK_REPORT,
      REQUEST,
      GIFT,
      DEPOSIT,
    } = inventory;
    const {
      CONVERSION,
      PRODUCTION_REPORT,
      PRODUCTION_TRANSFER,
      PRODUCTION_RECEIVING,
      JOB_ORDER,
    } = production;

    if (this.state.bussiness?.type === 'company') {
      // For Head Inventory & Production
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[
            BRANCH,
            UNIT,
            PRODUCT_SUB_CATEGORY,
            PRODUCT_CATEGORY,
            HSN,
            PRODUCT,
            VENDOR,
            REMUNERATION,
            DISCOUNT_CATEGORY,
            DISCOUNT,
          ]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        <Menu.SubMenu
          key="sub2"
          title={
            <span>
              <Icon type="shopping-cart" />
              <span>Purchase Menu</span>
            </span>
          }
        >
          {[PURCHASE_ORDER, PURCHASE_ORDER_REPORT, CHALLAN, PURCHASE, MARGIN]}
        </Menu.SubMenu>,
        PURCHASE_RETURN,
        TRANSFER,
        RECEIVING,
        DAMAGE,
        CODE_CONVERSION,
        STOCK_REPORT,
        REQUEST,
        <Menu.SubMenu
          title={
            <span>
              <Icon type="thunderbolt" />
              <span>Production</span>
            </span>
          }
          key="sub3"
        >
          {[
            CONVERSION,
            PRODUCTION_REPORT,
            PRODUCTION_TRANSFER,
            PRODUCTION_RECEIVING,
            JOB_ORDER,
          ]}
        </Menu.SubMenu>,
      ];
    }

    // For Emporium Branch
    if (this.state.bussiness?.category === 'EMPORIUM') {
      return [
        PRODUCT,
        OPENING_STOCK,
        SALE,
        VALUE_ADDITION,
        APPROVAL,
        GIFT,
        TRANSFER,
        RECEIVING,
        DEPOSIT,
        STOCK_REPORT,
        REQUEST,
      ];
    }

    // For remaining branches
    return [
      PRODUCT,
      OPENING_STOCK,
      VALUE_ADDITION,
      TRANSFER,
      RECEIVING,
      STOCK_REPORT,
    ];
  };

  kraftribeSidebar = (
    master: SideBarMenu,
    inventory: SideBarMenu,
    production: SideBarMenu,
  ) => {
    const {
      BRANCH,
      UNIT,
      PRODUCT_CATEGORY,
      PRODUCT,
      VENDOR,
      CUSTOMER,
      USER,
      HSN,
    } = master;
    const {
      OPENING_STOCK,
      SALE,
      PURCHASE,
      PURCHASE_RETURN,
      TRANSFER,
      DAMAGE,
      STOCK_REPORT,
      APPROVAL,
    } = inventory;
    const {
      CONVERSION,
      PRODUCTION_REPORT,
      PRODUCTION_TRANSFER,
      PRODUCTION_RECEIVING,
      JOB_ORDER,
    } = production;
    if (this.state.bussiness?.type === 'company') {
      return [
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="mail" />
              <span>Master</span>
            </span>
          }
        >
          {[
            BRANCH,
            UNIT,
            PRODUCT_CATEGORY,
            HSN,
            PRODUCT,
            VENDOR,
            CUSTOMER,
            USER,
          ]}
        </Menu.SubMenu>,
        OPENING_STOCK,
        SALE,
        PURCHASE,
        PURCHASE_RETURN,
        TRANSFER,
        DAMAGE,
        STOCK_REPORT,
        <Menu.SubMenu
          title={
            <span>
              <Icon type="thunderbolt" />
              <span>Production</span>
            </span>
          }
          key="sub3"
        >
          {[
            CONVERSION,
            PRODUCTION_REPORT,
            PRODUCTION_TRANSFER,
            PRODUCTION_RECEIVING,
            JOB_ORDER,
          ]}
        </Menu.SubMenu>,
      ];
    }
    return [PRODUCT, OPENING_STOCK, SALE, APPROVAL, TRANSFER, STOCK_REPORT];
  };

  renderSideBar = () => {
    const master: SideBarMenu = MasterLink({
      history: this.props.history,
      company: this.state.company,
      bussiness: this.state.bussiness,
    });

    const inventory: SideBarMenu = InventoryLink({
      history: this.props.history,
      company: this.state.company,
      bussiness: this.state.bussiness,
    });

    const production: SideBarMenu = ProductionLink({
      history: this.props.history,
      company: this.state.company,
    });

    if (this.state.company?.name.toLowerCase() === 'jharcraft') {
      return this.jharcraftSideBar(master, inventory, production);
    }

    if (this.state.company?.name?.toLowerCase().includes('pearl')) {
      return this.pearlHouseSideBar(master, inventory);
    }

    if (this.state.company?.name === 'Fashion 11-11') {
      return this.fashionSideBar(master, inventory);
    }

    if (this.state.company?.name.toLowerCase().includes('seva sadan')) {
      return this.sevaSadanSideBar(master, inventory);
    }

    if (this.state.company?.name.toLowerCase().includes('tiles')) {
      return this.companyWithTilesSideBar(master, inventory, production);
    }

    if (this.state.company?.name.toLowerCase().includes('khadi')) {
      return this.khadiSideBar(master, inventory, production);
    }

    if (this.state.company?.name === 'Budhia Agencies') {
      return this.budhiaAgenciesSideBar(master, inventory);
    }

    if (this.state.company?.name.toLocaleLowerCase().includes('sugar high')) {
      return this.sugarHighSideBar(master, inventory, production);
    }

    if (this.state.company?.name.toLocaleLowerCase().includes('kraftribe')) {
      return this.kraftribeSidebar(master, inventory, production);
    }
  };

  render() {
    if (!this.state.company || !this.state.user) return <Spin />;
    const { Header, Sider, Content } = Layout;
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div
            className={`logo ${this.logoStyle}`}
            onClick={() => this.props.history.push('/dashboard/master')}
          >
            SITS {this.state.collapsed ? <br /> : ''}
            WARES
          </div>
          <Menu mode="inline" theme="dark">
            {this.renderSideBar()}
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0, height: 'auto' }}>
            <Menu mode="horizontal">
              <Menu.Item onClick={this.toggle}>
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                />
              </Menu.Item>

              <div className={this.avatarContainerStyle}>
                {this.state.bussiness?.type === 'company' && (
                  <span
                    style={this.iconStyle}
                    onClick={() => this.setState({ panelOpen: true })}
                  >
                    <Avatar
                      size={40}
                      shape="square"
                      style={this.iconStyle}
                      icon="solution"
                    />
                    <span style={this.bussinessContainerStyle}>
                      Using Company: {capitalize(this.state.bussiness?.name)}
                    </span>
                  </span>
                )}
                {this.state.bussiness?.type === 'branch' && (
                  <span
                    onClick={() =>
                      this.companyService?.changeBussiness('branch')
                    }
                  >
                    <Avatar
                      size={40}
                      shape="square"
                      style={this.iconStyle}
                      icon="team"
                    />
                    {this.state.bussiness?.type === 'branch' && (
                      <span style={this.bussinessContainerStyle}>
                        Using Branch: {capitalize(this.state.bussiness?.name)}
                      </span>
                    )}
                  </span>
                )}
                <Popover
                  content={<UserPanel user={this.state.user} />}
                  title={
                    <div>
                      User Detail
                      <Icon
                        style={{
                          float: 'right',
                          cursor: 'pointer',
                          padding: '5px',
                          border: '1px solid #40a9ff',
                          borderRadius: '50%',
                        }}
                        type="edit"
                        theme="twoTone"
                      />
                    </div>
                  }
                >
                  <Avatar
                    size={40}
                    shape="circle"
                    style={{ backgroundColor: '#f60', cursor: 'pointer' }}
                    icon="user"
                  />
                </Popover>
              </div>
            </Menu>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: '100vh',
            }}
          >
            <DashboardRouter />
            {this.state.bussiness &&
              (this.state.bussiness as any).type === 'company' && (
                <Drawer
                  title="Company Detail"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ panelOpen: false })}
                  visible={this.state.panelOpen}
                  width={500}
                >
                  <CompanyPanel
                    company={this.state.company}
                    isSuperUser={this.state.user?.user?.user?.is_superuser}
                  />
                </Drawer>
              )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

interface State {
  collapsed: boolean;
  user: User | null;
  company: CompanyNodeEdge | null;
  panelOpen: boolean;
  bussiness: Bussiness | null;
}

interface Props extends RouterProps {
  match: match<{}>;
}

import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';

export interface DamageNode<T = ProductDetailsNodeConnection> {
  id?: string;
  date: string;
  company?: CompanyNodeEdge;
  companyId?: string;
  productDetails: T;
  remarks: string;
}

export interface DamageError {
  id?: string;
  date: string;
  companyId?: string;
  productdetails: string;
}

export const damageNode: DamageNode<ProductDetailNodeEdge[]> = {
  date: moment().format('YYYY-MM-DD'),
  companyId: '',
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      quantity: 0,
      amount: 0,
      movementForId: '',
      movementType: 'DAMAGE',
      companyId: '',
    },
  ],
  remarks: '',
};

export const damageError: DamageError = {
  id: '',
  date: '',
  companyId: '',
  productdetails: '',
};

export const damageQuery = `
  query DamageList($companyId: ID, $date_Lte: Date!) {
    allDamages(companyId: $companyId, date_Lte: $date_Lte) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          date
          remarks
          productDetails {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                product {
                  id
                  name
                }
                rate
                purchaseRate
                quantity
                amount
                movementType
                movementFor {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const damageReportQuery = `
query DamageReport($companyId: ID, $after: String) {
  allProductDetails(
    companyId: $companyId,
    movementType: "DAMAGE",
    first: 20,
    after: $after,
    orderBy: "-date",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        date
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
          purchaseRate
        }
        quantity
        amount
      }
    }
  }
}`;

export interface AddDamageVariable {
  input: {
    damage: DamageNode<ProductDetailNodeEdge[]>;
  };
}

export interface DamageQueryResponse {
  data: {
    allDamages: {
      edges: DamageEdges[];
    };
  };
}

export interface DamageEdges {
  cusror: string;
  node: DamageNode;
}

export const damageMutation = `
  mutation addDamage(
    $input: CreateDamageInput!
    ) {
    createDamage(input: $input) {
      newDamage {
        id
        date
      }
    }
  }
`;

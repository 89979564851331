import * as React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import {
  productionObject,
  ProductionError,
  productionErrorObj,
} from './constants';
import { ProductionForm } from './ProductionForm';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { ProductionService } from '../../../service/ProductionService';
import { CompanyService } from '../../../service/CompanyService';
import {
  ProductionNode,
  ProductionInput,
  ConversionNode,
  ConversionDetailNodeConnection,
} from '../../../schema';

export class AddProduction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      production: this.handleProductionPropsValue(props),
      buttonDisabled: false,
      error: JSON.parse(JSON.stringify(productionErrorObj)),
    };
  }

  productionService = new ProductionService();
  companyService = new CompanyService();

  componentWillReceiveProps(props: Props) {
    this.setState({
      production: this.handleProductionPropsValue(props),
      error: JSON.parse(JSON.stringify(productionErrorObj)),
    });
  }

  handleProductionPropsValue = (props: Props) => {
    const production: ProductionInput = props.production
      ? {
          ...props.production,
          conversionDetails: (
            props.production
              .conversionDetails as any as ConversionDetailNodeConnection
          ).edges.map((con) => ({
            ...con.node,
            conversionId: con.node?.conversion?.id,
          })),
        }
      : JSON.parse(JSON.stringify(productionObject));
    return production;
  };

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const production = { ...this.state.production };
    production[e.target.name] = e.target.value;
    this.setState({ production });
  };

  conversionDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.production.conversionDetails[index][e.target.name] =
      e.target.value;
    nextState.production.conversionDetails[index].amount = Number(
      (
        Number(nextState.production.conversionDetails[index].quantity) *
        Number(nextState.production.conversionDetails[index].cost)
      ).toFixed(2),
    );
    this.calculateAmount(nextState);
    this.setState(nextState);
  };

  calculateAmount = (nextState: State) => {
    nextState.production.amount = nextState.production.conversionDetails.reduce(
      (total, con) => total + Number(con.amount),
      0,
    );
    this.setState(nextState);
  };

  conversionObjChangehandler = (
    conversionObj: ConversionNode,
    index: number,
  ) => {
    const nextState = { ...this.state };
    nextState.production.conversionDetails[index].cost = conversionObj.netCost;
    this.setState(nextState);
  };

  updateRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.production.conversionDetails.push({
        date: null,
        conversionId: '',
        quantity: null,
        cost: null,
        amount: null,
        sourceBranchId: null,
        conversionType: 'PRODUCTION',
        companyId: null,
      });
    } else {
      nextState.production.conversionDetails.splice(index, 1);
      this.calculateAmount(nextState);
    }
    this.setState(nextState);
  };

  saveProduction = () => {
    this.setState({ buttonDisabled: true });
    this.productionService?.addProduction(
      this.state.production,
      (response) => {
        if (!this.props.production) {
          this.setState({
            production: JSON.parse(JSON.stringify(productionObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Production ' + this.state.production.id ? 'Updated' : 'Added',
          description:
            `Production was successfully ` +
            `${this.state.production.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        this.setState({ buttonDisabled: false });
        notification.error({
          message:
            'Production' + this.state.production.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  clear = () => {
    this.setState({ production: JSON.parse(JSON.stringify(productionObject)) });
  };

  render() {
    return (
      <div>
        <ProductionForm
          values={this.state.production}
          company={this.props.company}
          onChange={this.onChange}
          conversionDetailChangeHandler={this.conversionDetailChangeHandler}
          updateRow={this.updateRow}
          conversionObjChangehandler={this.conversionObjChangehandler}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveProduction}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  production?: ProductionNode;
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  production: ProductionInput;
  error: ProductionError;
  buttonDisabled: boolean;
}

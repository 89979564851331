import * as React from 'react';
import { Tabs, Icon, Skeleton } from 'antd';
import { Table } from '../../common/Table';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { AreaNodeEdge, AreaNodeConnection } from '../../../schema';
import { areaQuery } from './constants';
import { AddArea } from './AddArea';
import { checkAddPermission } from '../../helper';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class Area extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      area: [],
      activeKey: '1',
      selectedArea: null,
      currentPage: 1,
      after: '',
      user: null,
    };
  }

  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedArea: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  areaColumns = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedArea: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'District',
      dataIndex: 'node.district.name',
      key: 'district',
    },
  ];

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey } = this.state;
    const variables = { after, activeKey };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Area
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={areaQuery}
            variables={variables}
            render={(
              response: { allAreas: AreaNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allAreas.edges}
                    columns={this.areaColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allAreas.pageInfo.endCursor,
                        response.allAreas.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Area
            </span>
          }
          key="2"
        >
          {this.state.selectedArea?.node && (
            <AddArea
              handleTabChange={this.handleTabChange}
              area={this.state.selectedArea?.node}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  area: AreaNodeEdge[];
  activeKey: string;
  selectedArea: AreaNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {}

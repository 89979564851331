import * as React from 'react';
import { notification, Button } from 'antd';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { damageNode, damageError, DamageNode, DamageError } from './constants';
import { DamageService } from '../../service/DamageService';
import { DamageForm } from './DamageForm';
import { ProductNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { handleProductDetailsData } from '../Transfer/constants';

export class AddDamage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      damage: props.damage
        ? {
            ...props.damage,
            productDetails: handleProductDetailsData(
              props.damage.productDetails as ProductDetailsNodeConnection,
            ),
          }
        : JSON.parse(JSON.stringify(damageNode)),
      error: { ...damageError },
      buttonDisabled: false,
      productObjList: [],
    };
  }

  damageService = new DamageService();

  componentWillReceiveProps(nextProps: Props) {
    const damage = nextProps.damage
      ? {
          ...nextProps.damage,
          productDetails: handleProductDetailsData(
            nextProps.damage.productDetails as ProductDetailsNodeConnection,
          ),
        }
      : JSON.parse(JSON.stringify(damageNode));
    this.setState({ damage });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const damage = { ...this.state.damage };
    damage[e.target.name] = e.target.value;
    this.setState({ damage });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.damage.productDetails[index][e.target.name] = e.target.value;
    if (
      nextState.damage.productDetails[index].quantity &&
      nextState.damage.productDetails[index].purchaseRate
    ) {
      nextState.damage.productDetails[index].amount =
        Number(nextState.damage.productDetails[index].quantity) *
        Number(nextState.damage.productDetails[index].purchaseRate);
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.damage.productDetails.push({
        date: null,
        productId: '',
        quantity: 0,
        rate: 0,
        amount: 0,
        movementType: 'DAMAGE',
        companyId: null,
      });
    } else {
      nextState.damage.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.damage.productDetails[index].rate = productObj.sellingRate;
    nextState.damage.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  saveDamage = () => {
    this.setState({ buttonDisabled: true });
    this.damageService?.addDamage(
      this.state.damage,
      (response) => {
        this.setState({
          damage: this.props.damage
            ? {
                ...this.props.damage,
                productDetails: handleProductDetailsData(
                  this.props.damage
                    .productDetails as ProductDetailsNodeConnection,
                ),
              }
            : JSON.parse(JSON.stringify(damageNode)),
          error: { ...damageError },
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Damage ' + this.state.damage.id ? 'Updated' : 'Added',
          description: `Damage was successfuly ${
            this.state.damage.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Damage ' + this.state.damage.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      damage: JSON.parse(JSON.stringify(damageNode)),
      error: { ...damageError },
    });
  };

  render() {
    return (
      <div>
        <DamageForm
          values={this.state.damage}
          error={this.state.error}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          company={this.props.company}
          isEditActive={this.props.isEditActive}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveDamage}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  damage?: DamageNode;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
}

interface State {
  damage: DamageNode<ProductDetailNodeEdge[]>;
  error: DamageError;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
}

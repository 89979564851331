import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { sumBy } from 'lodash';
import { inWords } from '../helper';
import {
  DeliveryInstructionNode,
  ProductDetailsNodeConnection,
} from '../../schema';
import {
  RAMESHWARAM_INDUSTRIES_HEADER,
  RAMESHWARAM_PROJECTS_HEADER,
} from '../../config';

export const DeliveryInstructionRecieptHT: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailEdges = (
    props.deliveryInstruction
      .productDetails as any as ProductDetailsNodeConnection
  ).edges;
  const setBoxHeight = productDetailEdges.length < 15;
  const totalAmount =
    sumBy(productDetailEdges, (value) => value.node?.amount || 0) +
    props.deliveryInstruction.layingAndFittingCharge +
    props.deliveryInstruction.transportationCharge +
    props.deliveryInstruction.otherCharges;
  const totalQuantity = sumBy(
    productDetailEdges,
    (value) => value.node?.quantity || 0,
  );
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View
            style={
              props.company.name.toLowerCase().includes('hindustan')
                ? { marginTop: 150 }
                : {}
            }
          >
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          {!props.company.name.toLowerCase().includes('hindustan') && (
            <View style={[style.section, style.inline, { marginTop: 12 }]}>
              {props.company.name.toLowerCase().includes('projects') ? (
                <Image
                  style={[{ height: 100 }]}
                  source={RAMESHWARAM_PROJECTS_HEADER}
                />
              ) : (
                <Image
                  style={[{ height: 100 }]}
                  source={RAMESHWARAM_INDUSTRIES_HEADER}
                />
              )}
            </View>
          )}
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Delivery Instruction
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              DI No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.deliveryInstruction.deliveryInstructionNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              DI Date:{' '}
              {moment(props.deliveryInstruction.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.box]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Customer Name: {props.deliveryInstruction.customer.name}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Customer Address: {props.deliveryInstruction.customer.address}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              GSTIN: {props.deliveryInstruction.customer.gstinNumber}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              GSTIN Legal Name:{' '}
              {props.deliveryInstruction.customer.gstinLegalName}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Contact No: {props.deliveryInstruction.customer.contactNo}
            </Text>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.deliveryInstruction.remarks}
            </Text>
          </View>
        </View>
        {
          <View>
            {setBoxHeight ? (
              <View
                style={[
                  style.section,
                  style.box,
                  { marginTop: 2, height: 270 },
                ]}
              >
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Rate
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Per
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {productDetailEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.quantity}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.rate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.product?.unit.name}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {(pd.node ? pd.node.quantity * pd.node.rate : 0).toFixed(
                        2,
                      )}
                    </Text>
                  </View>
                ))}
              </View>
            ) : (
              <View style={[style.section, style.box, { marginTop: 2 }]}>
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Rate
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Per
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {productDetailEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.quantity}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.rate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.product?.unit.name}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {(pd.node ? pd.node.quantity * pd.node.rate : 0).toFixed(
                        2,
                      )}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderBottom: '1pt solid black',
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 1.2,
                    },
                  ]}
                >
                  Laying And Fitting Charge
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {Math.round(
                    props.deliveryInstruction.layingAndFittingCharge,
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderBottom: '1pt solid black',
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 1.2,
                    },
                  ]}
                >
                  Transportation Charge
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {Math.round(
                    props.deliveryInstruction.transportationCharge,
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderBottom: '1pt solid black',
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 1.2,
                    },
                  ]}
                >
                  Other Charges
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {Math.round(props.deliveryInstruction.otherCharges).toFixed(
                    2,
                  )}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {totalQuantity}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    {
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {Math.round(totalAmount).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={[style.section, style.box, { marginTop: 0 }]}>
              <Text
                style={[
                  style.darkBlackText,
                  { fontSize: '10.2pt' },
                  { padding: 4 },
                ]}
              >
                Rs. {inWords(Number(Math.round(totalAmount).toFixed(2)))}.
              </Text>
            </View>
            <View style={[style.section, style.marginTop]}>
              <Text style={[style.darkBlackText, style.textRight]}>
                Authorized Signatory
              </Text>
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 60 },
});

interface Props {
  deliveryInstruction: DeliveryInstructionNode;
  company: CompanyNodeEdge;
}

import { Col, Row } from 'antd';
import * as React from 'react';
import { getValue } from '../../common/AutoCompleteHelper';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { TSInput } from '../../common/TSInput';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { ProductSubCategoryNode } from './constants';

export const ProductSubCategoryForm: React.FunctionComponent<Props> = (
  props,
) => (
  <div>
    <Row>
      <Col span={12}>
        <TSInput
          error=""
          value={props.values.name}
          type="text"
          name="name"
          onChange={props.onChange}
          addonBefore="Name"
        />
      </Col>
      <Col span={12}>
        {props.categories && (
          <TSAutoComplete
            error=""
            inputProps={{ name: 'categoryId', addonBefore: 'Category' }}
            dataSource={props.categories}
            key="categoryId"
            name="categoryId"
            onValueSelect={props.onChange}
            displayKey="name"
            textValue={getValue(props.categories, props.values.categoryId)}
            allowClear
          />
        )}
      </Col>
    </Row>
  </div>
);

interface Props {
  values: ProductSubCategoryNode;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  categories?: ProductCategoryEdges[];
}

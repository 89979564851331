import * as React from 'react';
import { Table, Tabs, Icon, Spin, Skeleton } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import moment from 'moment';
import { Pagination } from 'sha-el-design';
import { CompanyService } from '../../../service/CompanyService';
import { productDetailsQuery } from '../../CodeConversion/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailsNodeEdge,
} from '../../../schema';
import { AddProductConversion } from './AddProductConversion';
import { CompanyNodeEdge } from '../Company/constants';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';
import { checkAddPermission } from '../../helper';

export class ProductConversion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      productConversions: [],
      activeKey: '1',
      company: null,
      after: '',
      currentPage: 1,
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productDetailsColumn = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Product From',
      dataIndex: 'node.product.name',
      key: 'oldProduct',
    },
    {
      title: 'Quantity',
      dataIndex: 'node.quantity',
      key: 'quantity',
    },
    {
      title: 'Product To',
      dataIndex: 'node.newProduct.name',
      key: 'newProduct',
    },
    {
      title: 'Quantity',
      dataIndex: 'node.quantityForNewProduct',
      key: 'quantityForNewProduct',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) return <Spin />;
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
    } = this.state;
    const variables = {
      companyId,
      activeKey,
      after,
      movementType: 'ITEM_CONVERSION',
    };
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                List Product Conversion
              </span>
            }
            key="1"
            closable={false}
          >
            <GraphqlQuery
              queryString={productDetailsQuery}
              variables={variables}
              render={(
                response: { allProductDetails: ProductDetailsNodeConnection },
                error,
                loading,
              ) => {
                if (error) {
                  return <h3>Something Went Wrong!!</h3>;
                }
                if (loading) return <Skeleton active />;
                if (response && companyId) {
                  return [
                    <Table
                      key="table"
                      dataSource={response.allProductDetails.edges}
                      columns={this.productDetailsColumn()}
                      rowKey={(obj) => obj.node?.id || ''}
                      pagination={false}
                    />,
                    <Pagination
                      key="pagination"
                      totalCount={0}
                      currentPage={currentPage}
                      batchSize={20}
                      cursorBasedPagination
                      onChange={(_p, _ps, next) =>
                        this.onPageChange(
                          next,
                          response.allProductDetails.pageInfo.endCursor,
                          response.allProductDetails.pageInfo.hasNextPage,
                        )
                      }
                      style={{ float: 'right' }}
                    />,
                  ];
                }
                return <Skeleton active />;
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Product Conversion
              </span>
            }
            key="2"
          >
            {this.companyService?.company$.value && (
              <AddProductConversion
                handleTabChange={this.handleTabChange}
                company={this.companyService?.company$.value}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

interface State {
  productConversions: ProductDetailsNodeEdge[];
  activeKey: string;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {}

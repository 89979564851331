import * as React from 'react';
import { CompanyNodeEdge } from '../../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  Skeleton,
  Table,
  Row,
  Col,
  Button,
  DatePicker,
  Divider,
  Icon,
} from 'antd';
import { summarizedProductionReceivingReport } from './constants';
import { SummarizedReceivingReport } from '../../../schema';
import { downloadToFile } from '../../helper';
import { SUMMARIZED_PRODUCTION_RECEIVING_REPORT_CSV } from '../../../config';

export class SummarizedProductionReceivingReport extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: 0,
      currentPage: 1,
      updateDataCount: 0,
      isLoading: false,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Job Order Date',
      dataIndex: 'jobOrderDate',
      key: 'jobOrderDate',
      render: (value: SummarizedReceivingReport) =>
        value && moment(value.jobOrderDate).format('DD-MM-YYYY'),
    },
    {
      title: 'Job Order No',
      dataIndex: 'jobOrderNo',
      key: 'jobOrderNo',
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: 'Production Center Name',
      dataIndex: 'productionCenter',
      key: 'productionCenter',
    },
    {
      title: 'Ordered Quantity',
      dataIndex: 'orderedQty',
      key: 'orderedQty',
    },
    {
      title: 'Received Quantity',
      dataIndex: 'receivedQty',
      key: 'receivedQty',
    },
    {
      title: 'Cost Price',
      dataIndex: 'costPrice',
      key: 'costPrice',
    },
    {
      title: 'WAP Number',
      dataIndex: 'wapNo',
      key: 'wapNo',
    },
  ];

  downloadCsv = () => {
    const queryParams = `companyId=${this.props.company.id}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;

    this.setState({ isLoading: true });

    fetch(SUMMARIZED_PRODUCTION_RECEIVING_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) =>
        downloadToFile(
          csv,
          `summarized-production_receiving-report-${new Date()}`,
          'SUMMARIZED PRODUCTION RECEIVING REPORT',
          this.props.company.name,
          'Head Production',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      )
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const { startDate, endDate, after, currentPage, updateDataCount } =
      this.state;
    const {
      company: { id: companyId },
      activeKey,
    } = this.props;
    const variables = {
      startDate,
      endDate,
      companyId,
      after,
      activeKey,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        {startDate && endDate && (
          <>
            <GraphqlQuery
              queryString={summarizedProductionReceivingReport}
              variables={variables}
              render={(
                response: {
                  summarizedReceivingReport: SummarizedReceivingReport[];
                },
                error,
                loading,
              ) => {
                if (loading) return <Skeleton active />;
                if (error) {
                  return <h3>Something went wrong</h3>;
                }
                if (response) {
                  return [
                    <Table
                      key="table"
                      dataSource={response.summarizedReceivingReport}
                      columns={this.columns()}
                      rowKey={(obj) =>
                        `${obj.wapNo}${obj.vendor}${obj.productionCenter}${obj.jobOrderDate}`
                      }
                      pagination={false}
                      style={{ overflowX: 'scroll' }}
                    />,
                    <Row key="Pagination">
                      <Col offset={21} span={1}>
                        <Button
                          onClick={this.onPrev}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </Button>
                      </Col>
                      <Col span={1} style={{ paddingLeft: 10 }}>
                        <Button type="primary">{currentPage}</Button>
                      </Col>
                      <Col span={1}>
                        <Button
                          onClick={this.onNext}
                          disabled={
                            !response.summarizedReceivingReport.length ||
                            response.summarizedReceivingReport.length < 20
                          }
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>,
                  ];
                }
                return <Skeleton active />;
              }}
            />
            <Button
              key="download"
              style={{ float: 'right' }}
              icon="download"
              onClick={() => this.downloadCsv()}
              disabled={this.state.isLoading}
            />
          </>
        )}
      </div>
    );
  }
}

interface State {
  startDate: string;
  endDate: string;
  after: number;
  currentPage: number;
  updateDataCount: number;
  isLoading: boolean;
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
}

import * as React from 'react';
import { SalesNodeEdgeMutation, SalesError } from './constants';
import { TSInput } from '../common/TSInput';
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Button,
  Select,
  Checkbox,
  Icon,
} from 'antd';
import moment from 'moment';
import { Label } from '../common/TSLabel';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { ProductNode, CustomerNode } from '../../schema';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { CustomerDropDown } from '../master/Customer/CustomerDropdown';
import { SalesPersonDropDown } from '../master/SalesPerson/SalesPersonDropdown';

export const SalesForm: React.FunctionComponent<Props> = (props) => {
  const isCompanySugarHigh = props.company.name.toLowerCase().includes('sugar');

  return (
    <div>
      <Row>
        <Col span={4}>
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: {
                        name: 'date',
                        value: e && moment(e).format('YYYY-MM-DD'),
                      },
                    })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
            disabledDate={(currentDate) => {
              if (props.company.name.toLowerCase().includes('jharcraft')) {
                return (
                  ((currentDate && currentDate.valueOf()) || '') <
                    moment().subtract(1, 'days').valueOf() ||
                  ((currentDate && currentDate.valueOf()) || '') >
                    moment().valueOf()
                );
              }
              return (
                ((currentDate && currentDate.valueOf()) || '') >
                moment().valueOf()
              );
            }}
          />
        </Col>
        <Col offset={1} span={13}>
          <CustomerDropDown
            onSelect={(e, f) => {
              props.customerObjChangehandler((f as any).props.label);
              props.onChange({
                target: { name: 'customerId', value: String(e) },
              });
            }}
            extrafields={[
              'pointBalance',
              'address',
              'email',
              'dateOfBirth',
              'dateOfAnniversary',
              'gstinNumber',
              'gstinLegalName',
            ]}
            allowClear
            company={props.company}
            value={props.values.customerId || ''}
            updateCount={props.updateCount}
          />
          <span style={{ color: 'red' }}>{props.error.customerId}</span>
        </Col>
        <Col span={1}>
          <Button
            children={<Icon type="plus" />}
            type="primary"
            onClick={() => props.handleModalAction('ADD_CUSTOMER')}
            style={{ marginTop: '5px' }}
          />
        </Col>
        <Col span={1}>
          <Button
            children={<Icon type="edit" />}
            type="primary"
            onClick={() => props.handleModalAction('LIST_CUSTOMER')}
            style={{ marginTop: '5px' }}
          />
        </Col>
        {(props.company.name.toLowerCase().includes('jharcraft') ||
          props.company?.name.toLowerCase().includes('khadi')) && (
          <Col offset={1} span={3}>
            <Label title="Is Service?">
              <Checkbox
                checked={props.service}
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) =>
                        props.onChange({
                          target: {
                            name: 'service',
                            value: e.target.checked,
                          },
                        })
                }
              />
            </Label>
          </Col>
        )}
        <Divider />
        <h4>Products</h4>
        <Row>
          <Col span={1}>Sl. No.</Col>
          <Col span={7}>Product</Col>
          <Col span={props.company.gstRate ? 4 : 3}>Quantity</Col>
          <Col span={props.company.gstRate ? 4 : 3}>Rate</Col>
          {props.company.gstRate ? (
            ''
          ) : (
            <div>
              <Col span={2}>Gst Rate</Col>
              <Col span={2}>Discount(%)</Col>
            </div>
          )}
          <Col span={props.company.gstRate ? 4 : 3}>Amount</Col>
          <Col span={2}>Remove</Col>
          <Col span={props.company.gstRate ? 2 : 1}>Add</Col>
          <Divider />
        </Row>
        {props.values.productDetails?.map((product, index) => (
          <div key={'products' + index}>
            <Row>
              <Col span={1}>{index + 1}.</Col>
              <Col span={7}>
                <div>
                  <ProductDropDown
                    onSelect={(e, f) => {
                      props.isEditActive
                        ? () => {}
                        : props.productObjChangehandler(
                            (f as any).props.label,
                            index,
                          );
                      props.isEditActive
                        ? () => {}
                        : props.productChangeHandler(
                            { target: { name: 'productId', value: String(e) } },
                            index,
                          );
                    }}
                    allowClear
                    extrafields={[
                      'sellingRate',
                      'purchaseRate',
                      'gstRate',
                      'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                      `newStock(branchId: "${
                        props.business?.type === 'branch'
                          ? props.business.id
                          : ''
                      }", ` +
                        `companyId: "${props.company.id}", ` +
                        `date: "${
                          props.values.date
                            ? moment(props.values.date).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                        }") { totalStocks } ` +
                        `unit { allowDecimal }` +
                        `productType discountCategory { name }`,
                    ]}
                    company={props.company}
                    value={product.productId || ''}
                  />
                  <span style={{ color: 'red' }}>
                    {props.error.productDetails[index].productId}
                  </span>
                </div>
              </Col>
              <Col span={props.company.gstRate ? 4 : 3}>
                <TSInput
                  error={props.error.productDetails[index].quantity}
                  type="number"
                  value={product.quantity || ''}
                  name="quantity"
                  placeholder="Quantity"
                  onChange={
                    props.isEditActive
                      ? () => {}
                      : (e) => props.productChangeHandler(e, index)
                  }
                  onClick={(e) =>
                    e.target.addEventListener('mousewheel', (e) =>
                      e.preventDefault(),
                    )
                  }
                  required
                />
                <div
                  style={{
                    color: (product.totalStock || 1) < 1 ? 'red' : 'black',
                  }}
                >
                  {product.productId && `Available: ${product.totalStock}`}
                </div>
              </Col>
              <Col span={props.company.gstRate ? 4 : 3}>
                <TSInput
                  error={props.error.productDetails[index].rate}
                  type="number"
                  value={product.rate || ''}
                  name="rate"
                  placeholder="Rate"
                  required
                  tabIndex={-1}
                />
              </Col>
              {props.company.gstRate ? (
                ''
              ) : (
                <div>
                  <Col span={2}>
                    <TSInput
                      error={props.error.productDetails[index].gstRate}
                      type="number"
                      value={product.gstRate || ''}
                      name="gstRate"
                      placeholder="Gst Rate"
                      required
                      tabIndex={-1}
                    />
                  </Col>
                  <Col span={2}>
                    <TSInput
                      error={props.error.productDetails[index].discount}
                      type="number"
                      value={product.discount || 0}
                      name="discount"
                      placeholder="Discount(%)"
                      onChange={
                        // || props.company.name.toLowerCase().includes('khadi')
                        props.isEditActive
                          ? () => {}
                          : (e) => props.productChangeHandler(e, index)
                      }
                      onClick={(e) =>
                        e.target.addEventListener('mousewheel', (e) =>
                          e.preventDefault(),
                        )
                      }
                    />
                  </Col>
                </div>
              )}
              <Col span={props.company.gstRate ? 4 : 3}>
                <TSInput
                  error={props.error.productDetails[index].amount}
                  type="number"
                  value={product.amount || ''}
                  name="amount"
                  placeholder="Amount"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="minus"
                  style={{ marginLeft: '5px' }}
                  onClick={
                    props.isEditActive
                      ? () => {}
                      : () => props.updateProductsRow('-', index)
                  }
                  disabled={
                    props.values.productDetails?.length === 1 ||
                    props.isEditActive
                  }
                />
              </Col>
              <Col span={props.company.gstRate ? 2 : 1}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  style={{ marginLeft: '5px' }}
                  onClick={
                    props.isEditActive
                      ? () => {}
                      : () => props.updateProductsRow('+', index)
                  }
                  disabled={
                    (product.productId &&
                    product.quantity &&
                    product.rate &&
                    product.amount
                      ? false
                      : true) || props.isEditActive
                  }
                />
              </Col>
            </Row>
          </div>
        ))}
        <Divider />
        <Col span={8}>
          <TSInput
            error=""
            type="text"
            value={props.totalQuantity}
            name="quantity"
            addonBefore="Total Quantity"
            placeholder="Total Quantity"
            readOnly
          />
        </Col>

        <Col span={8}>
          <TSInput
            error={props.error.billAmount}
            value={props.values.billAmount || ''}
            name="billAmount"
            type="number"
            addonBefore="Bill Amount"
          />
        </Col>
        {props.company.gstRate ? (
          <div>
            <Col span={12}>
              <TSInput
                error={props.error.discount}
                value={props.values.discount}
                onChange={props.isEditActive ? () => {} : props.onChange}
                name="discount"
                type="number"
                addonBefore="Discount"
                addonAfter="%"
              />
            </Col>
            <Col span={8}>
              <TSInput
                error={props.error.grossAmount}
                value={props.values.grossAmount || ''}
                name="grossAmount"
                type="number"
                addonBefore="Gross Amount"
              />
            </Col>
            <Col span={12}>
              <TSInput
                error={props.error.cgst}
                value={props.values.cgst || ''}
                name="cgst"
                type="number"
                addonBefore="CGST"
              />
            </Col>
            <Col span={12}>
              <TSInput
                error={props.error.sgst}
                value={props.values.sgst || ''}
                name="sgst"
                type="number"
                addonBefore="SGST"
              />
            </Col>
          </div>
        ) : (
          <Col span={8}>
            <TSInput
              error={''}
              value={props.values.totalAmount || ''}
              name="totalAmount"
              type="number"
              addonBefore="Gross Amount"
            />
          </Col>
        )}
        <Col span={12}>
          <TSInput
            error={props.error.redeemablePoint}
            value={props.values.redeemablePoint}
            name="redeemablePoint"
            type="number"
            addonBefore="Redeemable Point"
          />
        </Col>
        <Col span={3}>
          <Label title="Redeem">
            <Checkbox
              checked={props.values.toRedeem}
              onChange={
                props.isEditActive
                  ? () => {}
                  : (e) =>
                      props.onChange({
                        target: { name: 'toRedeem', value: e.target.checked },
                      })
              }
            />
          </Label>
          <span style={{ color: 'red' }}>{props.error.toRedeem}</span>
        </Col>
        {props.values.toRedeem && (
          <Col span={4}>
            <TSInput
              error={props.error.redeemValue}
              value={props.values.redeemValue}
              name="redeemValue"
              type="number"
              addonBefore="Redeem Value"
            />
          </Col>
        )}
        <Col span={5}>
          <TSInput
            error={props.error.netAmount}
            value={props.values.netAmount || ''}
            name="netAmount"
            type="number"
            addonBefore="Net Amount"
          />
        </Col>
        <Divider />
        <h4>Payment</h4>
        {props.values.paymentModes.map((mode, index) => (
          <Row key={'payment' + index}>
            <Col span={10}>
              <Label title="Payment Mode">
                <Select
                  defaultValue="Cash"
                  style={{ width: '100%' }}
                  value={mode.paymentType || 'Cash'}
                  onChange={(value) =>
                    props.paymentModeChangeHandler(
                      {
                        target: {
                          name: 'paymentType',
                          value: value.toString(),
                        },
                      },
                      index,
                    )
                  }
                >
                  <Select.Option value="Cash">Cash</Select.Option>
                  {isCompanySugarHigh && (
                    <Select.Option value="Swiggy">Swiggy</Select.Option>
                  )}
                  {isCompanySugarHigh && (
                    <Select.Option value="Zomato">Zomato</Select.Option>
                  )}
                  {isCompanySugarHigh && (
                    <Select.Option value="EazyDinner">EazyDinner</Select.Option>
                  )}
                  {isCompanySugarHigh && (
                    <Select.Option value="Paytm POS">Paytm POS</Select.Option>
                  )}
                  {isCompanySugarHigh && (
                    <Select.Option value="Hotel Green Horizon">
                      Hotel Green Horizon
                    </Select.Option>
                  )}
                  {isCompanySugarHigh && (
                    <Select.Option value="Hotel Green Acres">
                      Hotel Green Acres
                    </Select.Option>
                  )}
                  <Select.Option value="Credit">Credit</Select.Option>
                  {!isCompanySugarHigh && (
                    <Select.Option value="Card">Card</Select.Option>
                  )}
                  <Select.Option value="Cheque">Cheque</Select.Option>
                  {!isCompanySugarHigh && (
                    <Select.Option value="Bank">
                      RTGS/NEFT/IMPS/UPI
                    </Select.Option>
                  )}
                  {!isCompanySugarHigh && (
                    <Select.Option value="COD">COD</Select.Option>
                  )}
                  <Select.Option value="Coupon">Coupon</Select.Option>
                </Select>
              </Label>
              <span>{props.error.paymentModes[index].paymentType}</span>
            </Col>
            <Col span={11}>
              <TSInput
                error={props.error.paymentModes[index].amount}
                type="number"
                value={mode.amount}
                name="amount"
                addonBefore="Amount"
                onChange={(e) => props.paymentModeChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updatePaymentModeRow('-', index);
                }}
                disabled={props.values.paymentModes.length === 1}
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updatePaymentModeRow('+', index);
                }}
                disabled={
                  mode.paymentType &&
                  mode.amount &&
                  props.values.paymentModes.length === 1
                    ? false
                    : true
                }
              />
            </Col>
          </Row>
        ))}
        {props.values.paymentModes[0].paymentType === 'Cheque' && (
          <div>
            <Col span={12}>
              <TSInput
                error=""
                value={props.values.chequeNumber}
                onChange={props.onChange}
                name="chequeNumber"
                type="text"
                addonBefore="Cheque Number"
              />
            </Col>
            <Col span={12}>
              <TSInput
                error=""
                value={props.values.bankName}
                onChange={props.onChange}
                name="bankName"
                type="text"
                addonBefore="Bank Name"
              />
            </Col>
          </div>
        )}
        <Col span={12}>
          <TSInput
            error=""
            value={props.values.customerPaid || ''}
            onChange={props.onChange}
            name="customerPaid"
            type="number"
            addonBefore="Tender Amount"
          />
        </Col>
        <Col span={12}>
          <TSInput
            error=""
            value={props.values.balanceRefund || ''}
            name="balanceRefund"
            type="number"
            addonBefore="Balance Refund"
          />
        </Col>
        <Col span={12}>
          <TSInput
            error={props.error.gstinLegalName}
            value={props.values.gstinLegalName}
            onChange={props.onChange}
            name="gstinLegalName"
            type="text"
            addonBefore="GstIn Legal Name"
          />
        </Col>
        <Col span={12}>
          <TSInput
            error={props.error.gstinNumber}
            value={props.values.gstinNumber}
            onChange={props.onChange}
            name="gstinNumber"
            type="text"
            addonBefore="GstIn Number"
          />
        </Col>
      </Row>
      <Divider />
      <h4>Exchange And Other Services</h4>
      <Col span={4}>
        <Label title="Is Exchange?">
          <Checkbox
            checked={props.values.isExchange}
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: { name: 'isExchange', value: e.target.checked },
                    })
            }
          />
        </Label>
        <span style={{ color: 'red' }}>{props.error.isExchange}</span>
      </Col>
      {(props.company.name === 'Jharcraft' ||
        props.company?.name.toLowerCase().includes('khadi')) && (
        <Col span={4}>
          <Label title="Add Services">
            <Checkbox
              checked={props.values.isValueAddition}
              onChange={
                props.isEditActive
                  ? () => {}
                  : (e) =>
                      props.onChange({
                        target: {
                          name: 'isValueAddition',
                          value: e.target.checked,
                        },
                      })
              }
            />
          </Label>
          <span style={{ color: 'red' }}>{props.error.isValueAddition}</span>
        </Col>
      )}
      <Row>
        {props.values.isExchange && (
          <div>
            <Col span={16}>
              <TSInput
                required
                error={props.error.oldBillNo}
                value={props.values.oldBillNo}
                onChange={props.isEditActive ? () => {} : props.onChange}
                name="oldBillNo"
                type="text"
                addonBefore="Old Bill No."
              />
            </Col>
            <h4>Exchange</h4>
            <Row>
              <Col span={1}>Sl. No.</Col>
              <Col span={8}>Exchange Product</Col>
              <Col span={4}>Quantity</Col>
              <Col span={4}>Rate</Col>
              <Col span={4}>Amount</Col>
              <Col span={2}>Remove</Col>
              <Col span={1}>Add</Col>
              <Divider />
            </Row>
            {props.values.oldProductDetails?.map((product, index) => (
              <div key={'oldProducts' + index}>
                <Row>
                  <Col span={1}>{index + 1}.</Col>
                  <Col span={8}>
                    <div>
                      <ProductDropDown
                        onSelect={(e, f) => {
                          props.isEditActive
                            ? () => {}
                            : props.oldProductObjChangehandler(
                                (f as any).props.label,
                                index,
                              );
                          props.isEditActive
                            ? () => {}
                            : props.oldProductChangeHandler(
                                {
                                  target: {
                                    name: 'productId',
                                    value: String(e),
                                  },
                                },
                                index,
                              );
                        }}
                        allowClear
                        extrafields={['purchaseRate', 'hsn { gst }']}
                        company={props.company}
                        value={product.productId || ''}
                      />
                      <span style={{ color: 'red' }}>
                        {props.error.oldProductDetails[index].productId}
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error={props.error.oldProductDetails[index].quantity}
                      type="number"
                      value={product.quantity || ''}
                      name="quantity"
                      placeholder="Quantity"
                      onChange={
                        props.isEditActive
                          ? () => {}
                          : (e) => props.oldProductChangeHandler(e, index)
                      }
                      required
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error={props.error.oldProductDetails[index].rate}
                      type="number"
                      value={product.rate || ''}
                      name="rate"
                      placeholder="Rate"
                      disabled
                      required
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error={props.error.oldProductDetails[index].amount}
                      type="number"
                      value={product.amount || ''}
                      name="amount"
                      placeholder="Amount"
                      onChange={
                        props.isEditActive
                          ? () => {}
                          : (e) => props.oldProductChangeHandler(e, index)
                      }
                      required
                    />
                  </Col>
                  <Col span={2}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon="minus"
                      style={{ marginLeft: '5px' }}
                      onClick={() => {
                        props.updateOldProductsRow('-', index);
                      }}
                      disabled={
                        props.values.oldProductDetails?.length === 1 ||
                        props.isEditActive
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon="plus"
                      style={{ marginLeft: '5px' }}
                      onClick={() => {
                        props.updateOldProductsRow('+', index);
                      }}
                      disabled={
                        (product.productId && product.quantity
                          ? false
                          : true) || props.isEditActive
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}
      </Row>
      <Row>
        {props.values.isValueAddition && (
          <div>
            {props.values.isExchange && <Divider />}
            <h4>Other Services</h4>
            <Col span={12}>
              <TSInput
                error={props.error.valueAdditionRemarks}
                value={props.values.valueAdditionRemarks}
                onChange={props.onChange}
                name="valueAdditionRemarks"
                type="text"
                addonBefore={
                  props.company?.name.toLowerCase().includes('khadi')
                    ? 'Remarks'
                    : 'Value Addition Remarks'
                }
              />
            </Col>
            <Col span={12}>
              <TSInput
                error={props.error.valueAdditionAmount}
                value={props.values.valueAdditionAmount || ''}
                onChange={props.onChange}
                name="valueAdditionAmount"
                type="text"
                addonBefore={
                  props.company?.name.toLowerCase().includes('khadi')
                    ? 'Packaging/ Transportation/ Labour Charges'
                    : 'Value Addition Amount'
                }
              />
            </Col>
          </div>
        )}
      </Row>
      <Divider />
      <Row>
        {props.company.name.toLowerCase().includes('pearl') && (
          <div>
            <Col span={12}>
              <TSInput
                error={''}
                value={props.values.saleBy}
                onChange={props.onChange}
                name="saleBy"
                type="text"
                addonBefore="Sale By"
              />
            </Col>
            <Col span={12}>
              <TSInput
                error={''}
                value={props.values.billBy}
                onChange={props.onChange}
                name="billBy"
                type="text"
                addonBefore="Bill By"
              />
            </Col>
          </div>
        )}
        {props.company.name.toLowerCase().includes('jharcraft') && (
          <Col>
            <Label title="Sales Person Name">
              <SalesPersonDropDown
                onSelect={(e, f) => {
                  props.onChange({
                    target: { name: 'salesPersonId', value: String(e) },
                  });
                }}
                value={props.values.salesPersonId || ''}
                branchId={props.business?.id}
                companyId={props.company.id}
                allowClear
              />
            </Label>
            <span style={{ color: 'red' }}>{props.error.salesPersonId}</span>
          </Col>
        )}
        <Col span={24}>
          <TSInput
            error={props.error.remarks}
            value={props.values.remarks}
            onChange={props.onChange}
            name="remarks"
            type="text"
            addonBefore="Remarks"
          />
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  values: SalesNodeEdgeMutation;
  error: SalesError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  isEditActive: boolean;
  company: CompanyNodeEdge;
  business?: Bussiness;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  oldProductChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateOldProductsRow: (type: string, index: number) => void;
  paymentModeChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updatePaymentModeRow: (type: string, index: number) => void;
  productObjChangehandler: (value: ProductNode, index: number) => void;
  oldProductObjChangehandler: (value: ProductNode, index: number) => void;
  customerObjChangehandler: (value: CustomerNode) => void;
  handleModalAction: (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => void;
  updateCount: number;
  totalQuantity: number;
  service: boolean;
}

import moment from 'moment';
import { JobOrderInput } from '../../schema';
import { TransferNode } from '../Transfer/constants';
import { ProductDetailNodeEdge } from '../Sales/constants';

export interface JobOrderPartialCancelNode {
  id?: string | null;
  jobOrderId: string;
  vendorInvoiceNo: string;
  vendorDate: string;
  transferData: TransferNode<ProductDetailNodeEdge[]>;
  remarks?: string | null;
  companyId: string;
}

export interface JobOrderPartialCancelError {
  vendorInvoiceNo: string;
  vendorDate: string;
}

export const jobOrderPartialCancelError: JobOrderPartialCancelError = {
  vendorInvoiceNo: '',
  vendorDate: '',
};

export const jobOrderObject: JobOrderInput = {
  date: moment().format('YYYY-MM-DD'),
  continuation: false,
  oldJobOrderId: null,
  orderFor: [
    {
      date: moment().format('YYYY-MM-DD'),
      conversionId: null,
      quantity: null,
      cost: null,
      amount: null,
      conversionType: 'JOB_ORDER',
      companyId: null,
    },
  ],
  transferCompleted: false,
  transferData: {
    date: moment().format('YYYY-MM-DD'),
    fromHeadOffice: false,
    toHeadOffice: false,
    fromLocationId: null,
    toLocationId: null,
    productDetails: [],
    status: 'ACCEPTED',
    isTransfer: true,
    remarks: null,
    transferInvoiceNo: null,
    receivingDate: moment().format('YYYY-MM-DD'),
    fromVendor: false,
    toVendor: false,
    vendorForId: null,
    companyId: null,
  },
  companyId: null,
};

export interface JobOrderError {
  date?: string;
  continuation?: string;
  oldJobOrderId?: string;
  orderFor?: [
    {
      conversionId: string;
      cost: string;
      quantity: string;
      amount: string;
    },
  ];
  quantity?: string;
  transferCompleted?: string;
  transferData?: TransferError;
}

export interface TransferError {
  date: string;
  fromLocationId?: string;
  toLocationId?: string;
  productDetails: {
    productId: string;
    purchaseRate: string;
    quantity: string;
    amount: string;
  }[];
  vendorForId: string;
}

export const transferError: TransferError = {
  date: '',
  fromLocationId: '',
  toLocationId: '',
  productDetails: [
    {
      productId: '',
      purchaseRate: '',
      quantity: '',
      amount: '',
    },
  ],
  vendorForId: '',
};

export const jobOrderError: JobOrderError = {
  date: '',
  continuation: '',
  oldJobOrderId: '',
  orderFor: [
    {
      conversionId: '',
      quantity: '',
      cost: '',
      amount: '',
    },
  ],
  quantity: '',
  transferCompleted: '',
  transferData: transferError,
};

export const jobOrderQuery = `
query TransferList($fromLocationId: ID, $companyId: ID, $fromHeadOffice: Boolean, $date_Lte: Date!, $jobOrderNo: String, $after: String) {
  allTransfers(
    fromLocationId: $fromLocationId,
    isTransfer: true,
    first: 20,
    after: $after,
    companyId: $companyId,
    fromHeadOffice: $fromHeadOffice,
    date_Lte: $date_Lte,
    jobOrderId_Isnull: false,
    jobOrder_JobOrderNo_Icontains: $jobOrderNo,
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        date
        fromHeadOffice
        toHeadOffice
        fromLocation {
          id
          name
          address
          gstin
          state
          category
        }
        toLocation {
          id
          name
          address
          gstin
          state
          category
        }
        isTransfer
        remarks
        status
        receivingDate
        transferInvoiceNo
        fromVendor
        toVendor
        vendorFor {
          id
          name
          address
          state
          gstin
        }
        hsnGstWiseAmount {
          hsnCode
          gst
          quantity
          amount
        }
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
                oldBarcodeId
                hsn {
                  hsnCode
                }
                unit {
                  name
                }
              }
              rate
              purchaseRate
              quantity
              amount
              gstRate
              movementType
              status
              movementFor {
                id
                name
              }
              movementFrom {
                id
                name
              }
              reed
              pick
              gsm
              vendorFor {
                id
              }
            }
          }
        }
        jobOrder {
          id
          date
          firstTransferId
          orderFor {
            edges {
              node {
                id
                date
                conversion {
                  id
                  finishedProduct
                  designCode
                  oldDesignCode
                }
                quantity
                cost
                amount
                conversionType
              }
            }
          }
          jobOrderNo
          transferCompleted
          partialCancel
        }
      }
    }
  }
}
`;

export const productIssueReportQuery = `
query ProductIssueReport($companyId: ID!, $startDate: Date!, $endDate: Date!, $after: Int!) {
  productIssue(
    companyId: $companyId,
    startDate: $startDate,
    endDate: $endDate,
    limit: 20,
    offset: $after
  ) {
    jobOrderNo
    date
    location
    productName
    oldBarcode
    newBarcode
    category
    division
    vendorName
    locationName
    costPrice
    quantity
    costValue
  }
}`;

export const jobOrderPartialCancelReportQuery = `
query JobOrderPartialCancelReport($companyId: ID, $after: String) {
  allJobOrderPartialCancel(
    companyId: $companyId,
    first: 20,
    after: $after,
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        jobOrder {
          jobOrderNo
        }
        vendorInvoiceNo
        vendorDate
        remarks
        transfer {
          id
          date
          fromHeadOffice
          toHeadOffice
          fromLocation {
            id
            name
            address
            gstin
            state
            category
          }
          toLocation {
            id
            name
            address
            gstin
            state
            category
          }
          isTransfer
          remarks
          status
          receivingDate
          transferInvoiceNo
          fromVendor
          toVendor
          vendorFor {
            id
            name
            address
            state
            gstin
          }
          hsnGstWiseAmount {
            hsnCode
            gst
            quantity
            amount
          }
          productDetails {
            edges {
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  unit {
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                gstRate
                movementType
                status
                movementFor {
                  id
                  name
                }
                movementFrom {
                  id
                  name
                }
                reed
                pick
                gsm
                vendorFor {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const jobOrderMutation = `
  mutation addJobOrder(
    $input: CreateJobOrderInput!
  ) {
    createJobOrder(input: $input) {
      newJobOrder {
        id
        date
        jobOrderNo
      }
    }
  }
`;

export const jobOrderCancelMutation = `
  mutation CancelJobOrder(
    $input: CancelJobOrderInput!
  ) {
    cancelJobOrder(input: $input) {
      jobOrderToCancel {
        id
        jobOrderNo
      }
    }
  }
`;

export const jobOrderPartialCancelMutation = `
  mutation addJobOrderPartialCancel(
    $input: CreateJobOrderPartialCancelInput!
  ) {
    jobOrderPartialCancel(input: $input) {
      newJobOrderPartialCancel {
        id
        jobOrder {
          partialCancel
        }
      }
    }
  }
`;

import { CompanyService } from './CompanyService';
import {
  ProductDetailsInput,
  CreateProductDetailsInput,
  CreateProductDetailsPayload,
} from '../schema';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { productDetailsMutation } from '../components/CodeConversion/constants';

export class ProductDetailsService {
  static instance: ProductDetailsService;

  constructor() {
    if (ProductDetailsService.instance) {
      return ProductDetailsService.instance;
    } else {
      ProductDetailsService.instance = this;
    }
  }

  companyService = new CompanyService();

  addProductDetails(
    data: ProductDetailsInput[],
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data = data.map((d) => ({
      ...d,
      companyId: this.companyService?.company$.getValue()?.id,
    }));
    Api.graphql<
      { input: CreateProductDetailsInput },
      CreateProductDetailsPayload
    >(
      API_URL,
      { input: { productDetails: data } },
      productDetailsMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

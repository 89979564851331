import * as React from 'react';
import { Button, notification } from 'antd';
import { PurchaseService } from '../../service/PurchaseService';
import { PurchaseReturnForm } from './PurchaseReturnForm';
import { handleProductDetailsData } from '../Transfer/constants';
import {
  PurchaseNodeEdgeQuery,
  PurchaseNodeEdgeMutation,
  PurchaseEdges,
} from '../Purchase/constants';
import { purchaseReturnObject } from './constants';
import { ProductNode, PurchaseNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { VendorService } from '../../service/VendorService';
import { VendorEdges } from '../master/vendor/constants';
import { ProductDetailsNodeConnection } from '../Sales/constants';

export class AddPurchaseReturn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      purchaseReturn: props.purchaseReturn
        ? {
            ...props.purchaseReturn,
            purchaseToReturnId: props.purchaseReturn.purchaseToReturn.id,
            vendorId: props.purchaseReturn.vendor.id,
            productDetails: handleProductDetailsData(
              props.purchaseReturn.productDetails,
            ),
          }
        : JSON.parse(JSON.stringify(purchaseReturnObject)),
      buttonDisabled: false,
      productObjList: [],
      vendors: [],
      purchaseToReturn: null,
    };
  }

  purchaseReturnService = new PurchaseService();
  vendorService = new VendorService();

  componentDidMount() {
    this.fetchVendors();
  }

  fetchVendors = () => {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  };

  componentWillReceiveProps(nextProps: Props) {
    const purchaseReturn: any = nextProps.purchaseReturn
      ? {
          ...nextProps.purchaseReturn,
          purchaseToReturnId: nextProps.purchaseReturn.purchaseToReturn.id,
          vendorId: nextProps.purchaseReturn.vendor.id,
          productDetails: handleProductDetailsData(
            nextProps.purchaseReturn.productDetails,
          ),
        }
      : JSON.parse(JSON.stringify(purchaseReturnObject));
    this.setState({ purchaseReturn });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState.purchaseReturn[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.purchaseReturn.productDetails) {
      nextState.purchaseReturn.productDetails[index][e.target.name] =
        e.target.value;
      if (
        nextState.purchaseReturn.productDetails[index].quantity &&
        nextState.purchaseReturn.productDetails[index].purchaseRate
      ) {
        nextState.purchaseReturn.productDetails[index].amount =
          Number(nextState.purchaseReturn.productDetails[index].quantity) *
          Number(nextState.purchaseReturn.productDetails[index].purchaseRate);
      }
      this.setState(nextState);
    }
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.purchaseReturn.productDetails?.push({
        date: null,
        productId: '',
        quantity: 0,
        rate: 0,
        purchaseRate: 0,
        amount: 0,
        movementType: 'PURCHASE_RETURN',
        companyId: null,
      });
    } else {
      nextState.purchaseReturn.productDetails?.splice(index, 1);
    }
    this.setState(nextState);
  };

  purchaseObjChangeHandler = (purchaseObj: PurchaseNode) => {
    const nextState = { ...this.state };
    if (nextState.purchaseReturn.productDetails) {
      nextState.purchaseToReturn = purchaseObj;
      nextState.purchaseReturn.purchaseToReturnId = purchaseObj.id;
      const productDetails =
        purchaseObj.productDetails as any as ProductDetailsNodeConnection;
      nextState.purchaseReturn.productDetails = productDetails.edges.map(
        (pd) => {
          const newPd = {
            ...pd.node,
            productId: pd.node.product?.id,
            movementType: 'PURCHASE_RETURN' as const,
          };
          delete newPd.id;
          return newPd;
        },
      );
      nextState.purchaseReturn.vendorId = purchaseObj.vendor?.id;
      nextState.purchaseReturn.vendorInvoiceDate =
        purchaseObj.vendorInvoiceDate;
      nextState.purchaseReturn.vendorInvoiceNo = purchaseObj.vendorInvoiceNo;
      this.setState(nextState);
    }
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    if (nextState.purchaseReturn.productDetails) {
      nextState.productObjList[index] = productObj;
      nextState.purchaseReturn.productDetails[index].rate =
        productObj.sellingRate;
      nextState.purchaseReturn.productDetails[index].purchaseRate =
        productObj.purchaseRate;
      this.setState(nextState);
    }
  };

  savePurchaseReturn = () => {
    this.setState({ buttonDisabled: true });
    this.purchaseReturnService?.addPurchase(
      this.state.purchaseReturn,
      (response) => {
        if (!this.props.purchaseReturn) {
          this.setState({
            purchaseReturn: JSON.parse(JSON.stringify(purchaseReturnObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Purchase Return ' + this.state.purchaseReturn.id
              ? 'Updated'
              : 'Added',
          description: `Purchase Return was successfuly ${
            this.state.purchaseReturn.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Purchase Return ' + this.state.purchaseReturn.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      purchaseReturn: JSON.parse(JSON.stringify(purchaseReturnObject)),
    });
  };

  render() {
    return (
      <div>
        <PurchaseReturnForm
          values={this.state.purchaseReturn}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          company={this.props.company}
          vendors={this.state.vendors}
          purchaseObjChangehandler={this.purchaseObjChangeHandler}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.savePurchaseReturn}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  purchaseReturn?: PurchaseNodeEdgeQuery;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
}

interface State {
  purchaseReturn: PurchaseNodeEdgeMutation;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  vendors: VendorEdges[];
  purchaseToReturn: PurchaseNode | null;
}

import * as React from 'react';
import moment from 'moment';
import { CompanyNodeEdge } from '../master/Company/constants';
import { CompanyService } from '../../service/CompanyService';
import {
  Tabs,
  Icon,
  Table,
  Skeleton,
  Row,
  Col,
  Button,
  DatePicker,
  Divider,
} from 'antd';
import { SetMargin } from './SetMargin';
import { GraphqlQuery } from 'requestapijs';
import { marginListDataQuery } from './constants';
import { MarginListData } from '../../schema';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission, downloadToFile } from '../helper';
import { MARGIN_REPORT_CSV } from '../../config';

export class Margin extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      company: null,
      after: 0,
      currentPage: 1,
      user: null,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.componentDidMount();
    }
    this.setState({ activeKey });
  };

  marginColumns = () => [
    {
      title: 'Item From',
      dataIndex: 'itemFrom',
      key: 'itemFrom',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Old Barcode',
      dataIndex: 'oldBarcode',
      key: 'oldBarcode',
    },
    {
      title: 'New Barcode',
      dataIndex: 'newBarcode',
      key: 'newBarcode',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'Amount',
    },
    {
      title: 'Total Per Quantity',
      dataIndex: 'totalPerQuantity',
      key: 'totalperQuantity',
    },
    {
      title: 'Margin(%)',
      dataIndex: 'margin',
      key: 'margin',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      key: 'Mrp',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(MARGIN_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `margin-report-${new Date().toISOString()}`,
          'MARGIN REPORT',
          this.state.company?.name || '',
          '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }
    const { after, activeKey, company, currentPage, startDate, endDate } =
      this.state;
    const variables = {
      after,
      activeKey,
      companyId: company.id,
      startDate,
      endDate,
    };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Margin
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(this.state.startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !this.state.endDate) {
                    return false;
                  }
                  return (
                    currentDate.valueOf() > moment(this.state.endDate).valueOf()
                  );
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(this.state.endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !this.state.startDate) {
                    return false;
                  }
                  return (
                    currentDate.valueOf() <
                    moment(this.state.startDate).valueOf()
                  );
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          </Row>
          <Divider />
          <GraphqlQuery
            queryString={marginListDataQuery}
            variables={variables}
            render={(
              response: { marginListData: MarginListData[] },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (error) {
                return <h3>Something went wrong</h3>;
              }
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.marginListData}
                    columns={this.marginColumns()}
                    rowKey={(obj) => String(obj.id)}
                    pagination={false}
                  />,
                  <Row key="Pagination">
                    <Col offset={21} span={1}>
                      <Button
                        onClick={this.onPrev}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </Button>
                    </Col>
                    <Col span={1} style={{ paddingLeft: 10 }}>
                      <Button type="primary">{currentPage}</Button>
                    </Col>
                    <Col span={1}>
                      <Button
                        onClick={this.onNext}
                        disabled={
                          !response.marginListData?.length ||
                          response.marginListData?.length < 20
                        }
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>,
                  <Button
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() => this.downloadCsv(variables)}
                    key="button"
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Set Margin
              </span>
            }
            key="2"
          >
            <SetMargin
              company={this.state.company}
              handleTabChange={this.handleTabChange}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  company?: CompanyNodeEdge | null;
  after: number;
  currentPage: number;
  user?: UserNodeEdge | null;
  startDate: string;
  endDate: string;
}
interface Props {}

import moment from 'moment';
import { ProductDetailNodeEdge } from '../Sales/constants';

export interface PhysicalStockNode {
  date: string;
  companyId?: string | null;
  branchId?: string | null;
  productDetails?: ProductDetailNodeEdge[];
  physicalStocks?: number[];
}

export const physicalStockObject: PhysicalStockNode = {
  date: moment().format('YYYY-MM-DD'),
  companyId: null,
  branchId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      quantity: null,
      amount: 0,
      movementForId: '',
      movementType: 'OPENING_STOCK',
      companyId: '',
    },
  ],
  physicalStocks: [],
};

export interface StockObject {
  productId?: string | null;
  availableStock?: number | null;
  physicalStock?: number;
}

export interface AddPhysicalStockVariable {
  input: {
    value: {
      date: string;
      companyId?: string | null;
      branchId?: string | null;
      stockObj: StockObject[];
    };
  };
}

export const PhysicalStockMutation = `
  mutation CreatePhysicalStockMutation($input: CreatePhysicalStockInput!) {
    createPhysicalStock(input: $input) {
      response
    }
  }
`;

export const productQuery = `
query ProductList($companyId: ID!, $categoryId: ID, $after: String, $branchId: ID, $date: Date!) {
  allProducts(companyId: $companyId, first: 20, categoryId: $categoryId, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        oldBarcodeId
        newBarcodeId
        purchaseRate
        sellingRate
        stock(branchId: $branchId, companyId: $companyId, date: $date) {
          totalStocks
        }
        physicalStock(branchId: $branchId, companyId: $companyId) {
          availableStock,
          physicalStock,
          date
        }
      }
    }
  }
}
`;

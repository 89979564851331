import * as React from 'react';
import { DatePicker, Divider, Button, Spin, Col, Row, Icon } from 'antd';
import moment from 'moment';
import { ColumnProps, Table } from '../common/Table';
import { dailySalesBranchQuery, salesErrorObject } from './constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { downloadToFile } from '../helper';
import { GraphqlQuery } from 'requestapijs';
import { DailySalesBranch } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';

export class DailySalesReportBranch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      display: false,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => {
    const columns: ColumnProps<DailySalesBranch>[] = [
      {
        title: 'Date',
        key: 'date',
        render: (text, value: DailySalesBranch, idx) =>
          value.date === 'Total' || value.date === 'Value Addition'
            ? value.date
            : moment(value.date).format('DD-MM-YYYY'),
        sortingValue: (value: DailySalesBranch) => value.date || '',
      },
      {
        title: 'Bill No.',
        key: 'billNo',
        dataIndex: 'billNo',
        sortingValue: (value) => value,
      },
      {
        title: 'Customer Name',
        key: 'customerName',
        dataIndex: 'customerName',
        sortingValue: (value) => value,
      },
    ];

    if (this.props.company.name === 'Jharcraft') {
      columns.push({
        title: 'Sales Person Name',
        key: 'salesPersonName',
        dataIndex: 'salesPersonName',
        sortingValue: (value) => value,
      });
    }

    columns.push(
      {
        title: 'GSTIN Number',
        key: 'gstinNumber',
        dataIndex: 'gstinNumber',
        sortingValue: (value) => value,
      },
      {
        title: 'Gross Amount',
        key: 'grossAmount',
        render: (text, value: DailySalesBranch, idx) => {
          return !this.props.company.name.toLowerCase().includes('pearl')
            ? value.totalAmount?.toFixed(2)
            : (
                (value.grossAmount || 0) -
                (value.cgst || 0) -
                (value.sgst || 0)
              ).toFixed(2);
        },
        sortingValue: (value: DailySalesBranch) =>
          !this.props.company.name.toLowerCase().includes('pearl')
            ? value.totalAmount || ''
            : (value.grossAmount || 0) - (value.cgst || 0) - (value.sgst || 0),
      },
    );

    if (
      this.props.company.name === 'Jharcraft' ||
      this.props.company?.name.toLowerCase().includes('khadi')
    ) {
      columns.push({
        title: 'Taxable Amount',
        key: 'taxableAmount',
        render: (text, value: DailySalesBranch, idx) =>
          (
            (value.netAmount || 0) -
            ((value.cgst || 0) + (value.sgst || 0))
          ).toFixed(2),
        sortingValue: (value: DailySalesBranch) =>
          value.netAmount
            ? Number(
                (
                  (value.netAmount || 0) -
                  ((value.cgst || 0) + (value.sgst || 0))
                ).toFixed(2),
              )
            : 0,
      });
    }

    columns.push(
      {
        title: 'CGST',
        key: 'cgst',
        render: (text, value: DailySalesBranch, idx) => value.cgst?.toFixed(2),
        sortingValue: (value: DailySalesBranch) => value.cgst || '',
      },
      {
        title: 'SGST',
        key: 'sgst',
        render: (text, value: DailySalesBranch, idx) => value.sgst?.toFixed(2),
        sortingValue: (value: DailySalesBranch) => value.sgst || '',
      },
      {
        title: `${
          this.props.company.name.toLowerCase().includes('jharcraft') ||
          this.props.company?.name.toLowerCase().includes('khadi')
            ? 'Bill Amount'
            : 'Net Amount'
        }`,
        key: 'netAmount',
        render: (text, value: DailySalesBranch, __idx) =>
          ((value.netAmount || 0) + (value.exchangeAmount || 0)).toFixed(2),
        sortingValue: (value: DailySalesBranch) =>
          (value.netAmount || 0) + (value.exchangeAmount || 0),
      },
    );
    if (
      this.props.company.name === 'Jharcraft' ||
      this.props.company?.name.toLowerCase().includes('khadi')
    ) {
      columns.push({
        title: 'Discount',
        key: 'discount',
        render: (text, value: DailySalesBranch, idx) =>
          (
            (value.totalAmount || 0) -
            ((value.netAmount || 0) + (value.exchangeAmount || 0))
          ).toFixed(2),
        sortingValue: (value: DailySalesBranch) =>
          (
            (value.totalAmount || 0) -
            ((value.netAmount || 0) + (value.exchangeAmount || 0))
          ).toFixed(2),
      });
    }

    columns.push(
      {
        title: 'Exchange Amount',
        key: 'exchangeAmount',
        render: (text, value: DailySalesBranch, idx) =>
          value.exchangeAmount?.toFixed(2),
        sortingValue: (value: DailySalesBranch) => value.exchangeAmount || '',
      },
      {
        title: `${
          this.props.company.name.toLowerCase().includes('jharcraft') ||
          this.props.company?.name.toLowerCase().includes('khadi')
            ? 'Net Amount'
            : 'Bill Amount'
        }`,
        key: 'billAmount',
        render: (text, value: DailySalesBranch, idx) =>
          ((value.netAmount || 0) + (value.valueAdditionAmount || 0)).toFixed(
            2,
          ),
        sortingValue: (value: DailySalesBranch) => value.netAmount || '',
      },
      {
        title: 'Payment Mode',
        key: 'paymentMode',
        dataIndex: 'paymentMode',
        sortingValue: (value) => value,
      },
      {
        title: 'Credit Amount Received',
        key: 'creditAmountReceived',
        render: (text, value: DailySalesBranch) =>
          value.creditAmountReceived?.toFixed(2),
      },
    );
    return columns;
  };

  downloadCsv = (dailySalesBranchList: DailySalesBranch[]) => {
    const data = dailySalesBranchList
      .map((dl, i) =>
        this.columns()
          .map((col) => {
            if (col.dataIndex) {
              return dl[col.dataIndex]?.replace(',', ' ');
            }
            return col.render?.(null, dl, i);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `daily-sales-report-${
        this.props.branch.name
      }-${new Date().toISOString()}`,
      'DAILY SALES REPORT',
      this.props.company.name,
      this.props.branch.name || '',
      moment(this.state.startDate).format('DD-MM-YYYY'),
      moment(this.state.endDate).format('DD-MM-YYYY'),
      '',
    );
  };

  render() {
    const branchId = this.props.branch.id;
    const { startDate, endDate } = this.state;
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={
                (this.state.startDate && moment(this.state.startDate)) || null
              }
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  this.state.display ||
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(this.state.endDate && moment(this.state.endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  this.state.display ||
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={4} offset={1}>
            <Button
              disabled={this.state.display}
              onClick={() => this.setState({ display: true })}
            >
              Display
            </Button>
          </Col>
        </Row>
        <Button
          disabled={!this.state.display}
          onClick={() => {
            this.setState({ display: false });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          Refresh
          <Icon type="retweet" />
        </Button>
        <Divider />
        {this.state.display && this.state.startDate && this.state.endDate && (
          <GraphqlQuery
            queryString={dailySalesBranchQuery}
            variables={{ branchId, startDate, endDate }}
            render={(
              response: { dailySalesBranch: DailySalesBranch[] },
              error,
              loading,
            ) => {
              if (loading) {
                return <Spin />;
              }
              if (response && branchId) {
                return [
                  <Table
                    dataSource={response.dailySalesBranch}
                    columns={this.columns()}
                    key="table"
                    rowKey="id"
                    pagination={{ pageSize: 11 }}
                  />,
                  <Button
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() => this.downloadCsv(response.dailySalesBranch)}
                    key="button"
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  display: boolean;
}

import * as React from 'react';
import {
  Button,
  notification,
  Tabs,
  Icon,
  Modal,
  Skeleton,
  Popconfirm,
} from 'antd';
import {
  MarginInput,
  ChallanNode,
  ProductNodeConnection,
  MarginNodeConnection,
  CreateMarginPayload,
  ProductionTransferNode,
} from '../../schema';
import {
  marginObject,
  ProductDetails,
  productDetailsObject,
  productSearchQuery,
} from './constants';
import { MarginService } from '../../service/MarginService';
import { MarginForm } from './MarginForm';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { Table } from '../common/Table';
import { ProductNodeEdge } from '../master/Product/constants';
import { Pagination } from 'sha-el-design/lib';
import { AddProduct } from '../master/Product/AddProduct';
import { TSInput } from '../common/TSInput';
import { ProductCategoryService } from '../../service/ProductCategoryService';
import { ProductCategoryEdges } from '../master/ProductCategories/constants';
export class SetMargin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      margin: JSON.parse(JSON.stringify(marginObject)),
      challan: null,
      productAction: 'Close Modal',
      currentPage: 1,
      after: '',
      currentProductDetails: {
        index: null,
        productDetails: productDetailsObject,
      },
      selectedProduct: null,
      productDetailsToSave: '',
      barcode: '',
      productCategories: [],
    };
  }

  marginService = new MarginService();
  productCategoryService = new ProductCategoryService();

  componentDidMount() {
    this.productCategoryService?.productCategory$.subscribe(
      (productCategories) => this.setState({ productCategories }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    let margin = { ...this.state.margin };
    margin[e.target.name] = e.target.value;
    if (e.target.name === 'itemsFrom') {
      if (typeof e.target.value === 'string')
        margin = { ...marginObject, itemsFrom: e.target.value };
    }
    this.setState({ margin }, this.calculateAmount);
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const margin = this.state.margin;
    if (margin.productDetails) {
      const productDetails: ProductDetails[] = JSON.parse(
        margin.productDetails,
      ) as ProductDetails[];
      productDetails[index][e.target.name] = e.target.value;
      margin.productDetails = JSON.stringify(productDetails);
      this.setState({ margin }, this.calculateAmount);
    }
  };

  calculateAmount = () => {
    const margin = { ...this.state.margin };
    if (margin.productDetails) {
      const productDetails: ProductDetails[] = JSON.parse(
        margin.productDetails,
      ) as ProductDetails[];
      const totalQuantity = productDetails.reduce(
        (sum, pd) => sum + Number(pd.quantity),
        0,
      );
      productDetails.map((pd) => {
        const total =
          Number(pd.rate) +
          (Number(margin.freight) + Number(margin.otherExpenses)) /
            totalQuantity;
        pd.total = Number(total.toFixed(2));
        pd.mrp = Math.ceil(total + total * (Number(pd.margin) / 100));
        return pd;
      });
      margin.productDetails = JSON.stringify(productDetails);
      this.setState({ margin });
    }
  };

  challanObjChangeHandler = (challanObj: ChallanNode) => {
    const nextState = { ...this.state };
    nextState.margin.challanId = challanObj.id;
    nextState.challan = challanObj;
    nextState.margin.id = (
      challanObj.marginSet as any as MarginNodeConnection
    ).edges[0]?.node?.id;
    nextState.margin.freight =
      Number(
        (challanObj.marginSet as any as MarginNodeConnection).edges[0]?.node
          ?.id,
      ) || 0;
    nextState.margin.otherExpenses =
      Number(
        (challanObj.marginSet as any as MarginNodeConnection).edges[0]?.node
          ?.otherExpenses,
      ) || 0;
    nextState.margin.productDetails =
      challanObj.productDetailsWithMargin.productDetails;
    nextState.productDetailsToSave =
      (challanObj.marginSet as any as MarginNodeConnection).edges[0]?.node
        ?.productDetails || '[]';
    this.setState(nextState);
  };

  transferObjChangeHandler = (
    productionTransferObj: ProductionTransferNode,
  ) => {
    const nextState = { ...this.state };
    nextState.margin.productionTransferId = productionTransferObj.id;
    nextState.margin.id = (
      productionTransferObj.marginSet as any as MarginNodeConnection
    ).edges[0]?.node?.id;
    nextState.margin.freight =
      Number(
        (productionTransferObj.marginSet as any as MarginNodeConnection)
          .edges[0]?.node?.freight,
      ) || 0;
    nextState.margin.otherExpenses =
      Number(
        (productionTransferObj.marginSet as any as MarginNodeConnection)
          .edges[0]?.node?.otherExpenses,
      ) || 0;
    nextState.margin.productDetails =
      productionTransferObj.productDetailsWithMargin.productDetails;
    nextState.productDetailsToSave =
      (productionTransferObj.marginSet as any as MarginNodeConnection).edges[0]
        ?.node?.productDetails || '[]';
    this.setState(nextState);
  };

  onClickSearchProduct = (productDetails: ProductDetails, index: number) => {
    const nextState: State = { ...this.state };
    nextState.productAction = 'Search';
    nextState.currentProductDetails = {
      index,
      productDetails,
    };
    this.setState(nextState);
  };

  onClickAddProduct = (
    productDetails: ProductDetails,
    index: number | null,
  ) => {
    const nextState: State = { ...this.state };
    nextState.productAction = 'Add';
    nextState.currentProductDetails = {
      index,
      productDetails,
    };
    this.setState(nextState);
  };

  productColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      sortingValue: (text) => text,
    },
    {
      title: 'Category',
      dataIndex: 'node.category.name',
      key: 'category',
    },
    {
      title: 'MRP',
      dataIndex: 'node.sellingRate',
      key: 'sellingRate',
    },
    {
      title: 'HSN Code',
      dataIndex: 'node.hsn.hsnCode',
      key: 'hsnCode',
    },
    {
      title: 'GST Rate',
      dataIndex: 'node.hsn.gst',
      key: 'gstRate',
    },
    {
      title: 'Barcode 1',
      dataIndex: 'node.oldBarcodeId',
      key: 'oldBarcodeId',
      sortingValue: (text) => text,
    },
    {
      title: 'Barcode 2',
      dataIndex: 'node.newBarcodeId',
      key: 'newBarcodeId',
      sortingValue: (text) => text,
    },
    {
      title: 'Select',
      key: 'select',
      render: (value, record) => (
        <Popconfirm
          title="Are you sure to tag this product?"
          onConfirm={() => this.setState({ selectedProduct: record.node })}
          okText="Yes"
          cancelText="No"
        >
          <Button children={<Icon type="check" />} />
        </Popconfirm>
      ),
    },
  ];

  handleSelectedProduct = (selectedProduct) => {
    this.setState({ selectedProduct });
  };

  handleStateForSelectedProduct = (nextState: State) => {
    if (nextState.selectedProduct) {
      nextState.currentProductDetails.productDetails.productId =
        nextState.selectedProduct?.id || null;
      nextState.currentProductDetails.productDetails.productName =
        `${nextState.currentProductDetails.productDetails?.productName}` +
        `(${nextState.selectedProduct?.oldBarcodeId}, ${nextState.selectedProduct?.newBarcodeId}, ${nextState.selectedProduct?.name})`;
      nextState.currentProductDetails.productDetails.mrp =
        nextState.selectedProduct.sellingRate;
      nextState.currentProductDetails.productDetails.margin =
        nextState.selectedProduct
          ? Number(
              (
                ((nextState.selectedProduct.sellingRate || 0) /
                  nextState.currentProductDetails.productDetails.total -
                  1) *
                100
              ).toFixed(2),
            )
          : null;
    }
    return nextState;
  };

  saveMargin = () => {
    let nextState: State = JSON.parse(JSON.stringify(this.state));
    const productDetails = JSON.parse(
      nextState.productDetailsToSave,
    ) as ProductDetails[];
    nextState = this.handleStateForSelectedProduct(nextState);
    productDetails.push(nextState.currentProductDetails.productDetails);
    nextState.margin.productDetails = JSON.stringify(productDetails);
    this.marginService?.addMargin(
      nextState.margin,
      (response: { data: { createMargin: CreateMarginPayload } }) => {
        nextState.margin.productDetails =
          nextState.margin.itemsFrom === 'PURCHASE'
            ? response.data.createMargin.newMargin.challan
                ?.productDetailsWithMargin.productDetails
            : response.data.createMargin.newMargin.productionTransfer
                .productDetailsWithMargin.productDetails;
        nextState.margin.id = response.data.createMargin.newMargin.id;
        nextState.selectedProduct = null;
        nextState.productDetailsToSave =
          response.data.createMargin.newMargin.productDetails;
        nextState.productAction = 'Close Modal';
        this.setState(nextState);
        notification.success({
          message: 'Added',
          description: `Margin was successfully Set`,
        });
      },
      (error) =>
        notification.error({
          message: 'Margin Set Error',
          description: JSON.stringify(error),
        }),
    );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.props.company) {
      return <Skeleton active />;
    }

    const { after, currentPage, currentProductDetails } = this.state;
    const { company } = this.props;
    const barcode =
      this.state.barcode ||
      (currentProductDetails &&
        currentProductDetails.productDetails.productName);
    const sellingRate_Lte =
      Number(
        currentProductDetails && currentProductDetails.productDetails.mrp,
      ) + 25;
    const sellingRate_Gte =
      Number(
        currentProductDetails && currentProductDetails.productDetails.mrp,
      ) - 25;
    return (
      <Tabs>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Set Margin
            </span>
          }
          key="1"
          closable={false}
        >
          <MarginForm
            values={this.state.margin}
            productChangeHandler={this.productChangeHandler}
            challanObjChangeHandler={this.challanObjChangeHandler}
            onClickSearchProduct={this.onClickSearchProduct}
            onClickAddProduct={this.onClickAddProduct}
            onChange={this.onChange}
            transferObjChangeHandler={this.transferObjChangeHandler}
          />
          {this.state.productAction === 'Search' && (
            <Modal
              title="Select Product"
              visible={this.state.productAction === 'Search'}
              footer={<div />}
              onCancel={() =>
                this.setState({ productAction: 'Close Modal', barcode: '' })
              }
            >
              <TSInput
                error=""
                value={this.state.barcode || undefined}
                onChange={(e) => this.setState({ barcode: e.target.value })}
                addonBefore="Search Product"
                placeholder={`Didn't Get the Required Product! Search Here Within the Price Range..`}
              />
              <GraphqlQuery
                queryString={productSearchQuery}
                variables={{
                  companyId: company.id,
                  after,
                  barcode,
                  sellingRate_Lte,
                  sellingRate_Gte,
                }}
                render={(
                  response: { allProducts: ProductNodeConnection },
                  error,
                  loading,
                ) => {
                  if (loading) return <Skeleton active />;
                  if (response && company) {
                    return [
                      <Table
                        key="table"
                        dataSource={response.allProducts.edges}
                        columns={this.productColumn()}
                        rowKey={(obj) => obj.node?.id || ''}
                        pagination={false}
                      />,
                      <Pagination
                        key="pagination"
                        totalCount={response.allProducts.totalCount || 0}
                        currentPage={currentPage}
                        batchSize={20}
                        cursorBasedPagination
                        onChange={(_p, _ps, next) =>
                          this.onPageChange(
                            next,
                            response.allProducts.pageInfo.endCursor,
                            response.allProducts.pageInfo.hasNextPage,
                          )
                        }
                        style={{ float: 'right' }}
                      />,
                    ];
                  }
                  return <Skeleton active />;
                }}
              />
              <Button
                type="primary"
                children="Switch to Add Product"
                style={{ width: '50%', float: 'right', marginTop: '5px' }}
                onClick={() =>
                  this.onClickAddProduct(
                    this.state.currentProductDetails.productDetails,
                    this.state.currentProductDetails.index,
                  )
                }
              />
            </Modal>
          )}
          {this.state.productAction === 'Add' && (
            <Modal
              title="Add Product"
              visible={this.state.productAction === 'Add'}
              footer={<div />}
              onCancel={() =>
                this.setState({ productAction: 'Close Modal', barcode: '' })
              }
            >
              <AddProduct
                company={company}
                handleAddedProduct={this.handleSelectedProduct}
                productForMargin={{
                  name: this.state.currentProductDetails.productDetails
                    .productName,
                  purchaseRate:
                    this.state.currentProductDetails.productDetails.total,
                  sellingRate:
                    this.state.currentProductDetails.productDetails.mrp,
                }}
                productCategories={this.state.productCategories}
              />
            </Modal>
          )}
          {this.state.selectedProduct && this.saveMargin()}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  handleTabChange?: (activeKey: string) => void;
}

interface State {
  margin: MarginInput;
  challan: ChallanNode | null;
  productAction: 'Search' | 'Add' | 'Close Modal';
  after: string;
  currentPage: number;
  currentProductDetails: {
    index: number | null;
    productDetails: ProductDetails;
  };
  selectedProduct: ProductNodeEdge | null;
  productDetailsToSave: string;
  barcode: string;
  productCategories: ProductCategoryEdges[];
}

import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { sumBy } from 'lodash';
import {
  TransferNode,
  ProductDetailsNodeConnection,
  CommissionDetailsNodeConnection,
} from '../../schema';
import { inWords } from '../helper';
import {
  JHARKHAND_GOVT_LOGO_URL,
  JHARKHAND_KHADI_LOGO_URL,
  KHADI_INDIA_LOGO_URL,
} from '../../config';

export const ReceivingReceiptKhadi: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailsEdges = (
    props.receiving.productDetails as unknown as ProductDetailsNodeConnection
  ).edges;
  const setBoxHeight = productDetailsEdges.length < 15;
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node?.quantity || 0,
  );
  const totalAmount = sumBy(
    productDetailsEdges,
    (value) => (value.node && value.node.rate * value.node.quantity) || 0,
  );

  let totalAfterCommission = totalAmount;
  let netTotal = 0;
  let finalNetTotal = 0;

  const packingDetails: Details = {
    description: 'Packing/Other Charge',
    value: 0,
  };
  const postalDetails: Details = {
    description: 'Courier and Postal Charges',
    value: 0,
  };
  const specialAdditionDetails: Details = {
    description: 'Special Addition(%) ( 0% )',
    value: 0,
  };

  const commissionDetails: Details[] = [];
  (
    props.receiving
      .commissionDetails as unknown as CommissionDetailsNodeConnection
  )?.edges.forEach((cd) => {
    if (cd.node?.category == null) {
      if (cd.node?.description == 'Packing/Other Charge') {
        packingDetails.value = cd.node.descriptionValue;
        netTotal += cd.node.descriptionValue;
      }
      if (cd.node?.description == 'Courier and Postal Charges') {
        postalDetails.value = cd.node.descriptionValue;
        netTotal += cd.node.descriptionValue;
      }
      if (cd.node?.description == 'Special Addition(%)') {
        specialAdditionDetails.description = `Special Addition(%) ( ${
          cd.node.descriptionPercent || 0
        }% )`;
        specialAdditionDetails.value = cd.node.descriptionValue;
        finalNetTotal += cd.node.descriptionValue;
      }
    } else {
      totalAfterCommission -= cd.node.descriptionValue;
      commissionDetails.push({
        description: `${cd.node.category}@${cd.node.description}( ${
          cd.node.descriptionPercent || 0
        }% )`,
        value: cd.node.descriptionValue,
      });
    }
  });
  netTotal += totalAfterCommission;
  finalNetTotal += netTotal;

  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.3, height: 50, margin: 'auto' }]}
              source={KHADI_INDIA_LOGO_URL}
            />
          </View>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.2, height: 55 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                {
                  fontSize: '15.3pt',
                  marginLeft: '10px',
                  marginRight: '10px',
                },
              ]}
            >
              Jharkhand State Khadi &amp; Village Industries Board 2nd Floor,
              Udyog Bhavan, Ratu Road, Ranchi — 834001.
            </Text>
            <Image
              style={[{ flex: 0.3, height: 50 }]}
              source={JHARKHAND_KHADI_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Tax Invoice
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.business.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.business.state} (${props.business.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.receiving.receivingInvoiceNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Date: {moment(props.receiving.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              For Transfer Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.receiving.transferInvoiceNo}
              </Text>
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <View
              style={[
                style.box,
                { padding: 5, marginRight: 1 },
                style.inlineHead,
              ]}
            >
              {props.receiving.fromLocation ||
              props.receiving.fromHeadOffice ? (
                <View>
                  <Text style={style.mediumBlackText}>
                    Bill From: (
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.category
                      : 'Central Store'}
                    )
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.name
                      : props.company.name}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.address
                      : props.company.address}
                  </Text>
                  <Text style={[style.mediumBlackText, style.marginTop]}>
                    GSTIN:{' '}
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.gstin
                      : props.company.gstin}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    GST State:{' '}
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.state
                      : props.company.state}
                    (
                    {props.receiving.fromLocation
                      ? props.receiving.fromLocation.gstin?.slice(0, 2)
                      : props.company.gstin?.slice(0, 2)}
                    )
                  </Text>
                </View>
              ) : (
                <View>
                  <Text style={style.mediumBlackText}>
                    Bill From: (Vendor){props.receiving.vendorFor?.name}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    {props.receiving.vendorFor?.address}
                  </Text>
                  <Text style={[style.mediumBlackText, style.marginTop]}>
                    GSTIN: {props.receiving.vendorFor?.gstin}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    GST State: {props.receiving.vendorFor?.state}(
                    {props.receiving.vendorFor?.gstin &&
                      props.receiving.vendorFor?.gstin.slice(0, 2)}
                    )
                  </Text>
                </View>
              )}
            </View>
            <View
              style={[
                style.box,
                { padding: 5, marginLeft: 1 },
                style.inlineHead,
              ]}
            >
              {props.receiving.toLocation || props.receiving.toHeadOffice ? (
                <View>
                  <Text style={style.mediumBlackText}>
                    Bill To: (
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.category
                      : 'Central Store'}
                    )
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.name
                      : props.company.name}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.address
                      : props.company.address}
                  </Text>
                  <Text style={[style.mediumBlackText, style.marginTop]}>
                    GSTIN:{' '}
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.gstin
                      : props.company.gstin}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    GST State:{' '}
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.state
                      : props.company.state}
                    (
                    {props.receiving.toLocation
                      ? props.receiving.toLocation.gstin?.slice(0, 2)
                      : props.company.gstin?.slice(0, 2)}
                    )
                  </Text>
                </View>
              ) : (
                <View>
                  <Text style={style.mediumBlackText}>
                    Bill To: (Vendor){props.receiving.vendorFor?.name}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    {props.receiving.vendorFor?.address}
                  </Text>
                  <Text style={[style.mediumBlackText, style.marginTop]}>
                    GSTIN: {props.receiving.vendorFor?.gstin}
                  </Text>
                  <Text style={style.mediumBlackText}>
                    GST State: {props.receiving.vendorFor?.state}(
                    {props.receiving.vendorFor?.gstin &&
                      props.receiving.vendorFor?.gstin.slice(0, 2)}
                    )
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.receiving.remarks}
            </Text>
          </View>
        </View>
        <View>
          {setBoxHeight ? (
            <View
              style={[style.section, style.box, { marginTop: 2, height: 445 }]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.2 },
                  ]}
                >
                  Sl.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Product
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3 },
                  ]}
                >
                  Barcode
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  HSN Code
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Sale Price
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={style.divider} />
              {productDetailsEdges.map((pd, i) => (
                <View style={[style.inline]} key={'pd' + i} wrap={false}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      style.textCenter,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {i + 1}.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node?.product?.name}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node?.product?.oldBarcodeId}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node?.product?.hsn?.hsnCode}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node?.quantity}{' '}
                    {pd.node?.product?.unit.name.split('(')[1].replace(')', '')}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node?.rate.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node && (pd.node.quantity * pd.node.rate).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
          ) : (
            <View style={[style.section, style.box, { marginTop: 2 }]}>
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.2 },
                  ]}
                >
                  Sl.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Product
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3 },
                  ]}
                >
                  Barcode
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  HSN Code
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Sale Price
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={style.divider} />
              {productDetailsEdges.map((pd, i) => (
                <View style={[style.inline]} key={'pd' + i} wrap={false}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      style.textCenter,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {i + 1}.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node?.product?.name}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node?.product?.oldBarcodeId}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node?.product?.hsn?.hsnCode}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node?.quantity}{' '}
                    {pd.node?.product?.unit.name.split('(')[1].replace(')', '')}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node?.rate.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node && (pd.node.quantity * pd.node.rate).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
          )}
          <View
            style={[
              style.section,
              {
                marginTop: 0,
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                height: 17,
              },
            ]}
          >
            <View style={[style.inline]}>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                Total
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3, flex: 0.5 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.5 },
                ]}
              >
                {totalQty}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {Math.round(totalAmount).toFixed(2)}
              </Text>
            </View>
          </View>
          {commissionDetails?.length > 0 && (
            <View
              style={[
                style.section,
                {
                  borderTop: '1pt solid black',
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  marginTop: 0,
                },
              ]}
            >
              {commissionDetails?.map((cd) => (
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      { flex: 2.1, padding: 3 },
                    ]}
                  >
                    {cd?.description}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {cd?.value.toFixed(2)} (-)
                  </Text>
                </View>
              ))}
            </View>
          )}
          {commissionDetails?.length > 0 && (
            <View
              style={[
                style.section,
                {
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderTop: '1pt solid black',
                  marginTop: 0,
                  height: 20,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {totalAfterCommission.toFixed(2)}
                </Text>
              </View>
            </View>
          )}
          <View
            style={[
              style.section,
              {
                borderTop: '1pt solid black',
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                marginTop: 0,
              },
            ]}
          >
            {[packingDetails, postalDetails].map((details) => (
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { flex: 2.1, padding: 3 },
                  ]}
                >
                  {details?.description}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {details?.value.toFixed(2)} (+)
                </Text>
              </View>
            ))}
          </View>
          <View
            style={[
              style.section,
              {
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                borderTop: '1pt solid black',
                marginTop: 0,
                height: 20,
              },
            ]}
          >
            <View style={[style.inline]}>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                Net Total
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {netTotal.toFixed(2)}
              </Text>
            </View>
          </View>
          <View
            style={[
              style.section,
              {
                borderTop: '1pt solid black',
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                marginTop: 0,
              },
            ]}
          >
            <View style={[style.inline]}>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { flex: 2.1, padding: 3 },
                ]}
              >
                {specialAdditionDetails?.description}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {specialAdditionDetails?.value.toFixed(2)} (+)
              </Text>
            </View>
          </View>
          <View
            style={[
              style.section,
              {
                borderTop: '1pt solid black',
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                marginTop: 0,
                height: 20,
              },
            ]}
          >
            <View style={[style.inline]}>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                Final Net Total
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {finalNetTotal.toFixed(2)}
              </Text>
            </View>
          </View>
          <View
            style={[style.section, style.box, { marginTop: 0, height: 22 }]}
          >
            <Text
              style={[style.darkBlackText, { padding: 4, fontSize: '10.2pt' }]}
            >
              Rs. {inWords(Number(finalNetTotal))}.
            </Text>
          </View>
          <View style={[style.section, style.inline, style.marginTop]}>
            <Text style={[style.inlineHead, style.darkBlackText]}>
              Received By
            </Text>
            <Text
              style={[style.inlineHead, style.darkBlackText, style.textRight]}
            >
              Prepared by
            </Text>
          </View>
          <View style={[style.section, style.inline, style.marginTop]}>
            <Text style={[style.inlineHead, style.darkBlackText]}>
              Checked By
            </Text>
            <Text
              style={[style.inlineHead, style.darkBlackText, style.textRight]}
            >
              Manager
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

type Details = {
  description: string;
  value: number;
};

interface Props {
  receiving: TransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
}

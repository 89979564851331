export interface ReceivingError {
  receivingDate: string;
  productDetails: {
    productId: string;
    status: string;
    remark: string;
  }[];
  status: string;
}

export const receivingError: ReceivingError = {
  receivingDate: '',
  productDetails: [
    {
      productId: '',
      remark: '',
      status: '',
    },
  ],
  status: '',
};

export const pendingReceivingQuery = `
query PendingReceivingList($toLocationId: ID, $companyId: ID, $toHeadOffice: Boolean, $date_Lte: Date!, $transferInvoice: String) {
  allTransfers(
    toLocationId: $toLocationId,
    isTransfer: true,
    companyId: $companyId,
    toHeadOffice: $toHeadOffice,
    date_Lte: $date_Lte,
    status: "PENDING"
    transferInvoiceNo: $transferInvoice,
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        date
        receivingDate
        fromHeadOffice
        toHeadOffice
        fromLocation {
          id
          name
        }
        toLocation {
          id
        }
        fromVendor
        toVendor
        isTransfer
        remarks
        transferInvoiceNo
        status
        productDetails {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            cursor
            node {
              id
              product {
                id
                name
                oldBarcodeId
                newBarcodeId
                hsn {
                  hsnCode
                }
                unit {
                  name
                }
              }
              rate
              purchaseRate
              quantity
              amount
              gstRate
              movementType
              remark
              status
              movementFor {
                id
                name
              }
              movementFrom {
                id
                name
              }
            }
          }
        }
        commissionDetails {
          edges {
            node {
              category
              description
              descriptionPercent
              descriptionValue
            }
          }
        }
      }
    }
  }
}
`;

export const acceptedReceivingQuery = `
  query AcceptedReceivingList($toLocationId: ID, $companyId: ID, $toHeadOffice: Boolean, $date_Lte: Date!, $after: String, $transferInvoice: String) {
    allTransfers(
      toLocationId: $toLocationId,
      isTransfer: true,
      companyId: $companyId,
      toHeadOffice: $toHeadOffice,
      date_Lte: $date_Lte,
      status_Contains: "ACCEPTED",
      after: $after,
      first: 20,
      transferInvoiceNo: $transferInvoice,
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          date
          receivingDate
          fromHeadOffice
          toHeadOffice
          fromLocation {
            id
            name
            address
            gstin
            state
            category
          }
          toLocation {
            id
            name
            address
            gstin
            state
            category
          }
          isTransfer
          remarks
          fromVendor
          toVendor
          vendorFor {
            id
            name
            address
            state
            gstin
          }
          status
          transferInvoiceNo
          receivingInvoiceNo
          hsnGstWiseAmountReceiving {
            hsnCode
            gst
            quantity
            amount
          }
          productDetails(status: "ACCEPTED") {
            edges {
              cursor
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  newBarcodeId
                  hsn {
                    hsnCode
                  }
                  unit {
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                gstRate
                movementType
                remark
                status
                movementFor {
                  id
                  name
                }
                movementFrom {
                  id
                  name
                }
              }
            }
          }
          commissionDetails {
            edges {
              node {
                category
                description
                descriptionPercent
                descriptionValue
              }
            }
          }
        }
      }
    }
  }
`;

export const itemWiseReceivingQuery = `
query receivingReport($companyId: ID!, $startDate: Date!, $endDate: Date!, $movementForId: ID, $after: String, $isHeadOffice: Boolean) {
	allProductDetails(
    companyId: $companyId, 
    date_Gte: $startDate, 
    date_Lte: $endDate, 
    movementForId: $movementForId,
    movementForId_Isnull: $isHeadOffice,
    transfer_ToVendor: false,
    status: "ACCEPTED", 
    after: $after,
    first: 20
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        product {
          name
          oldBarcodeId
          newBarcodeId
          purchaseRate
          sellingRate
          division
          category {
            name
          }
          gstRate
          hsnCode
        }
        quantity
        movementFrom {
          name
        }
        transferInvoiceNo
        receivingInvoiceNo
        vendorFor {
          name
        }
      }
    }
  }
}	
`;

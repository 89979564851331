import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  BranchNodeEdge,
  AddBranchVariables,
  branchMutation,
} from '../components/master/Branch/constants';

export class BranchService {
  static instance;

  companyService = new CompanyService();
  public readonly branch$ = new BehaviorSubject<
    { cursor: string; node: BranchNodeEdge }[]
  >([]);

  constructor() {
    if (BranchService.instance) {
      return BranchService.instance;
    } else {
      BranchService.instance = this;
    }
    this.onInit();
  }

  onInit() {
    this.companyService?.company$.subscribe((company) => {
      company?.branches?.edges && this.branch$.next(company.branches.edges);
    });
  }

  addBranch(
    branch: BranchNodeEdge,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    const input = {
      input: {
        branch: {
          ...branch,
          companyId: this.companyService?.company$.value?.id,
        },
      },
    };
    delete input.input.branch.company;
    delete input.input.branch.parentLocation;
    delete input.input.branch.type;
    Api.graphql<AddBranchVariables, {}>(
      API_URL,
      input,
      branchMutation,
      HEADER,
      (response) => {
        this.companyService?.shouldUpdate$.next(
          this.companyService.shouldUpdate$.getValue() + 1,
        );
        onSuccess(response);
      },
      (error) => {
        onError(error);
      },
    );
  }
}

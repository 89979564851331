import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import {
  EmployeeQueryResponse,
  employeeQuery,
  EmployeeEdges,
  EmployeeNodeEdge,
  AddEmployeeVariables,
  employeeMutation,
} from '../components/master/Employee/constants';
import { API_URL, HEADER } from '../config';
import { BehaviorSubject } from 'rxjs';

export class EmployeeService {
  static instance;

  constructor() {
    if (EmployeeService.instance) {
      return EmployeeService.instance;
    } else {
      EmployeeService.instance = this;
    }
    this.onInit();
  }

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  private readonly token = localStorage.getItem('token');
  public readonly employee$ = new BehaviorSubject<EmployeeEdges[]>([]);
  companyService = new CompanyService();

  getAll(companyId?: string) {
    Api.graphql<{}, EmployeeQueryResponse>(
      API_URL,
      { companyId },
      employeeQuery,
      HEADER,
      (response) => this.employee$.next(response.data.allUsers.edges),
      (error) => error,
    );
  }

  addEmployee(
    data: EmployeeNodeEdge,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<AddEmployeeVariables, {}>(
      API_URL,
      {
        input: {
          userDetails: {
            ...data,
            companyId: this.companyService?.company$.value?.id,
          },
        },
      },
      employeeMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.getAll(this.companyService?.company$.value?.id);
      },
      (response) => onError(response),
    );
  }
}

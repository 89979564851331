import { Button, notification } from 'antd';
import { forEach } from 'lodash';
import React from 'react';
import { BranchService } from '../../service/BranchService';
import { MemoService } from '../../service/MemoService';
import { BranchEdges } from '../master/Branch/constants';
import { MemoError, memoErrorObject, MemoNode, memoObject } from './constants';
import { MemoForm } from './MemoForm';

export class AddMemo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      memo: this.handlePropsValueUpdate(props),
      error: JSON.parse(JSON.stringify(memoErrorObject)),
      buttonDisabled: false,
      branch: [],
    };
  }

  memoService = new MemoService();
  branchService = new BranchService();

  handlePropsValueUpdate = (props: Props) =>
    props.memo ? props.memo : JSON.parse(JSON.stringify(memoObject));

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      memo: this.handlePropsValueUpdate(nextProps),
    });
  }

  componentDidMount() {
    this.fetchBranch();
  }

  fetchBranch = () => {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const memo: MemoNode = {
      ...this.state.memo,
    };
    console.log(e.target.value);
    memo[e.target.name] = e.target.value;
    this.setState({ memo });
  };

  checkError = () => {
    const error = { ...memoErrorObject };
    let isError = false;

    forEach(this.state.memo, (value, key) => {
      if (!value) {
        isError = true;
        error[key] = 'This is a Required field';
      }
    });

    this.setState({ error });
    return isError;
  };

  saveMemo = () => {
    if (this.checkError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.memoService?.addMemo(
      this.state.memo,
      () => {
        notification.success({
          message: 'Memo' + this.state.memo.id ? 'Updated' : 'Added',
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Discount Category ' + this.state.memo.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      memo: JSON.parse(JSON.stringify(memoObject)),
      error: JSON.parse(JSON.stringify(memoErrorObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <MemoForm
          values={this.state.memo}
          error={this.state.error}
          onChange={this.onChange}
          branch={this.state.branch}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveMemo}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  memo?: MemoNode;
  handleTabChange?: (activeKey: string) => void;
}

interface State {
  memo: MemoNode;
  error: MemoError;
  buttonDisabled: boolean;
  branch: BranchEdges[];
}

import { BehaviorSubject } from 'rxjs';
import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { ProductDetailNodeEdge } from '../components/Sales/constants';
import {
  DamageEdges,
  DamageQueryResponse,
  damageQuery,
  DamageNode,
  AddDamageVariable,
  damageMutation,
} from '../components/Damage/constants';
import { DateService } from './DateService';

export class DamageService {
  static instance: DamageService;
  constructor() {
    if (DamageService.instance) {
      return DamageService.instance;
    } else {
      DamageService.instance = this;
    }
    this.onInit();
  }

  private readonly token = localStorage.getItem('token');
  public readonly damage$ = new BehaviorSubject<DamageEdges[]>([]);
  companyService = new CompanyService();
  dateService = new DateService();

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  getAll(companyId: string) {
    Api.graphql<{ companyId: string; date_Lte?: string }, DamageQueryResponse>(
      API_URL,
      { companyId, date_Lte: this.dateService?.date$.value },
      damageQuery,
      HEADER,
      (response) => this.damage$.next(response.data.allDamages.edges),
      (error) => error,
    );
  }

  addDamage(
    data: DamageNode<ProductDetailNodeEdge[]>,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete data.company;
    data.productDetails = data.productDetails.map((p) => {
      p.date = data.date;
      p.companyId = this.companyService?.company$.getValue()?.id;
      delete p.product;
      delete p.movementFor;
      return p;
    });
    Api.graphql<AddDamageVariable, {}>(
      API_URL,
      {
        input: {
          damage: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      damageMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.onInit();
      },
      (error) => onError(error),
    );
  }
}

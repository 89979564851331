import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { sumBy, uniq } from 'lodash';
import { inWords } from '../helper';
import {
  JobOrderNode,
  TransferNodeConnection,
  ProductDetailsNodeConnection,
  ConversionDetailNodeConnection,
} from '../../schema';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../config';

export const JobOrderTaxInvoiceJharcraft: React.FunctionComponent<Props> = (
  props,
) => {
  const transfer = (props.jobOrder?.transfers as any as TransferNodeConnection)
    .edges[0].node;
  const productDetailsEdges = (
    transfer?.productDetails as any as ProductDetailsNodeConnection
  ).edges;
  const setBoxHeight = productDetailsEdges.length < 9;
  const totalQty = sumBy(productDetailsEdges, (value) =>
    value.node ? value.node.quantity : 0,
  );
  const totalAmount = sumBy(productDetailsEdges, (value) =>
    value.node ? value.node.purchaseRate * value.node.quantity : 0,
  );
  const gstRateList = transfer?.hsnGstWiseAmount.map((obj) => Number(obj.gst));
  const gstRates = uniq(gstRateList);
  const amountWithGst: {
    [gst: number]: number;
  } = {};
  gstRates.forEach((gst) => {
    amountWithGst[gst] = Number(
      sumBy(
        transfer?.hsnGstWiseAmount.filter((obj) => obj.gst === gst),
        (obj) => obj.amount || 0,
      ).toFixed(2),
    );
  });
  const grandTotal = (
    Number(totalAmount.toFixed(2)) +
    Number(
      sumBy(gstRates, (gst) => amountWithGst[gst] * (gst / 100)).toFixed(2),
    )
  ).toFixed(2);

  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.15, height: 43 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Job Order
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.business.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.business.state} (${props.business.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Transfer Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {transfer?.transferInvoiceNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Date: {moment(transfer?.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <View
              style={[
                style.box,
                { padding: 3, marginRight: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill From: (
                {transfer?.fromLocation
                  ? transfer.fromLocation.category
                  : 'Head Office'}
                )
                {transfer?.fromLocation
                  ? transfer.fromLocation.name
                  : props.company.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {transfer?.fromLocation
                  ? transfer.fromLocation.address
                  : props.company.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {transfer?.fromLocation
                  ? transfer.fromLocation.gstin
                  : props.company.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {transfer?.fromLocation
                  ? transfer.fromLocation.state
                  : props.company.state}
                (
                {transfer?.fromLocation
                  ? transfer.fromLocation.gstin?.slice(0, 2)
                  : props.company.gstin?.slice(0, 2)}
                )
              </Text>
            </View>
            <View
              style={[
                style.box,
                { padding: 5, marginLeft: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill To: (
                {transfer?.toLocation ? transfer.toLocation.category : 'Vendor'}
                )
                {transfer?.toLocation
                  ? transfer.toLocation.name
                  : transfer?.vendorFor?.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {transfer?.toLocation
                  ? transfer?.toLocation.address
                  : transfer?.vendorFor?.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {transfer?.toLocation
                  ? transfer?.toLocation.gstin
                  : transfer?.vendorFor?.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {transfer?.toLocation
                  ? transfer?.toLocation.state
                  : transfer?.vendorFor?.state}
                (
                {transfer?.toLocation
                  ? transfer.toLocation.gstin?.slice(0, 2)
                  : transfer?.vendorFor?.gstin &&
                    transfer.vendorFor.gstin.slice(0, 2)}
                )
              </Text>
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {transfer?.remarks}
            </Text>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Description: This Transfer is for Job No.{' '}
              {props.jobOrder?.jobOrderNo}.
              {(
                props.jobOrder
                  ?.orderFor as any as ConversionDetailNodeConnection
              ).edges
                .map((cde) => {
                  return (
                    `${cde.node?.quantity} ${cde.node?.conversion?.finishedProduct}` +
                    `(${cde.node?.conversion?.oldDesignCode},${cde.node?.conversion?.designCode})`
                  );
                })
                .join(', ')}{' '}
              to be made using following Raw Materials.
            </Text>
          </View>
        </View>
        {
          <View>
            {setBoxHeight ? (
              <View
                style={[
                  style.section,
                  style.box,
                  { marginTop: 2, height: 250 },
                ]}
              >
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    Barcode
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Amount
                  </Text>
                  {(
                    props.jobOrder
                      ?.orderFor as any as ConversionDetailNodeConnection
                  ).edges.find(
                    (cde) =>
                      cde.node?.conversion?.productionType === 'HANDLOOM',
                  ) ? (
                    <View>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.3,
                          },
                        ]}
                      >
                        Reed
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.3,
                          },
                        ]}
                      >
                        Pick
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.3,
                          },
                        ]}
                      >
                        GSM
                      </Text>
                    </View>
                  ) : (
                    <View />
                  )}
                </View>
                <View style={style.divider} />
                {productDetailsEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}({pd.node?.rate}/-)
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.oldBarcodeId}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.product?.hsn?.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.quantity}{' '}
                      {pd.node?.product?.unit.name
                        .split('(')[1]
                        .replace(')', '')}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.purchaseRate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node
                        ? (pd.node.quantity * pd.node.purchaseRate).toFixed(2)
                        : 0}
                    </Text>
                    {(
                      props.jobOrder
                        ?.orderFor as any as ConversionDetailNodeConnection
                    ).edges.find(
                      (cde) =>
                        cde.node?.conversion?.productionType === 'HANDLOOM',
                    ) ? (
                      <View>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.darkBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.3,
                            },
                          ]}
                        >
                          Reed
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.darkBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.3,
                            },
                          ]}
                        >
                          Pick
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.darkBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.3,
                            },
                          ]}
                        >
                          GSM
                        </Text>
                      </View>
                    ) : (
                      <View />
                    )}
                  </View>
                ))}
              </View>
            ) : (
              <View style={[style.section, style.box, { marginTop: 2 }]}>
                <View style={[style.inline]}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3, flex: 0.2 },
                    ]}
                  >
                    Sl.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    Product
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    Barcode
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textCenter,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    HSN Code
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    Quantity
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Cost Price
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.darkBlackText,
                      style.textRight,
                      {
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    Amount
                  </Text>
                </View>
                <View style={style.divider} />
                {productDetailsEdges.map((pd, i) => (
                  <View style={[style.inline]} key={'pd' + i} wrap={false}>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        style.textCenter,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {i + 1}.
                    </Text>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.name}({pd.node?.rate}/-)
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        { padding: 3 },
                      ]}
                    >
                      {pd.node?.product?.oldBarcodeId}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textCenter,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.product?.hsn?.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {pd.node?.quantity}{' '}
                      {pd.node?.product?.unit.name
                        .split('(')[1]
                        .replace(')', '')}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node?.purchaseRate.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        {
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {pd.node
                        ? (pd.node.quantity * pd.node.purchaseRate).toFixed(2)
                        : 0}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <View
              style={[
                style.section,
                {
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  marginTop: 0,
                  height: 17,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {totalQty}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {totalAmount.toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                { borderTop: '1pt solid black', marginTop: 0 },
              ]}
            >
              {gstRates.map((gst) => (
                <View
                  style={[
                    {
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.darkBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { flex: 2.1, padding: 3 },
                      ]}
                    >
                      Integrated GST (IGST) [{gst}% on {amountWithGst[gst]}]
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3 },
                      ]}
                    >
                      {(amountWithGst[gst] * (gst / 100)).toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={[
                style.section,
                {
                  borderLeft: '1pt solid black',
                  borderRight: '1pt solid black',
                  borderTop: '1pt solid black',
                  marginTop: 0,
                  height: 22,
                },
              ]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Grand Total
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 4, fontSize: '10.2pt' },
                  ]}
                >
                  {grandTotal}
                </Text>
              </View>
            </View>
            <View
              style={[style.section, style.box, { marginTop: 0, height: 22 }]}
            >
              <Text
                style={[
                  style.darkBlackText,
                  { padding: 4, fontSize: '10.2pt' },
                ]}
              >
                Rs. {inWords(Number(grandTotal))}.
              </Text>
            </View>
          </View>
        }
        {
          <View wrap={false}>
            <View
              style={[style.box, style.section, style.inline, { marginTop: 2 }]}
            >
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                HSN Code
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  style.textRight,
                  {
                    borderRight: '1 pt solid black',
                    padding: 3,
                    flex: 0.4,
                  },
                ]}
              >
                IGST %
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.lowFlexInlineHead,
                  style.textRight,
                  {
                    borderRight: '1 pt solid black',
                    padding: 3,
                    flex: 0.4,
                  },
                ]}
              >
                Quantity
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                Taxable Amount
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { borderRight: '1 pt solid black', padding: 3 },
                ]}
              >
                IGST Amount
              </Text>
              <Text
                style={[
                  style.mediumBlackText,
                  style.inlineHead,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                Total Amount
              </Text>
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1 pt solid black',
                  borderRight: '1 pt solid black',
                  borderBottom: '1 pt solid black',
                },
              ]}
            >
              {transfer?.hsnGstWiseAmount.map((obj) => (
                <View style={[style.inline, { marginTop: 0 }]}>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.hsnCode}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.4,
                      },
                    ]}
                  >
                    {obj.gst}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.4,
                      },
                    ]}
                  >
                    {obj.quantity}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.amount?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                      },
                    ]}
                  >
                    {obj.amount && obj.gst
                      ? (obj.amount * (obj.gst / 100)).toFixed(2)
                      : 0}
                  </Text>
                  <Text
                    style={[
                      style.mediumBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        padding: 3,
                        borderBottom: '1 pt solid black',
                      },
                    ]}
                  >
                    {obj.amount && obj.gst
                      ? (obj.amount + obj.amount * (obj.gst / 100)).toFixed(2)
                      : 0}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={[
                style.section,
                {
                  marginTop: 0,
                  borderLeft: '1 pt solid black',
                  borderRight: '1 pt solid black',
                  borderBottom: '1 pt solid black',
                },
              ]}
            >
              <View style={[style.inline, { marginTop: 0 }]}>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    {
                      borderBottom: '1 pt solid black',
                      padding: 3,
                    },
                  ]}
                >
                  Net Total
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3.5,
                      flex: 0.4,
                    },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                      flex: 0.4,
                    },
                  ]}
                >
                  {sumBy(
                    transfer?.hsnGstWiseAmount,
                    (obj) => obj.quantity || 0,
                  )}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                    },
                  ]}
                >
                  {sumBy(
                    transfer?.hsnGstWiseAmount,
                    (obj) => obj.amount || 0,
                  ).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      borderBottom: '1 pt solid black',
                      padding: 3,
                    },
                  ]}
                >
                  {sumBy(transfer?.hsnGstWiseAmount, (obj) =>
                    obj.amount && obj.gst ? obj.amount * (obj.gst / 100) : 0,
                  ).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.darkBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      padding: 3,
                      borderBottom: '1 pt solid black',
                    },
                  ]}
                >
                  {sumBy(transfer?.hsnGstWiseAmount, (obj) =>
                    obj.amount && obj.gst
                      ? obj.amount + obj.amount * (obj.gst / 100)
                      : 0,
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={[
                style.section,
                style.inline,
                style.marginTop,
                { marginTop: 60 },
              ]}
            >
              <Text style={[style.inlineHead, style.darkBlackText]}>
                Received By
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.darkBlackText,
                  style.textCenter,
                ]}
              >
                Prepared By
              </Text>
              <Text
                style={[style.inlineHead, style.darkBlackText, style.textRight]}
              >
                Authorized Signatory
              </Text>
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  jobOrder?: JobOrderNode | null;
  company: CompanyNodeEdge;
  business: Bussiness;
}

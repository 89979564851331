import * as React from 'react';
import {
  TransferEdges,
  transferQuery,
  rejectedTransferQuery,
  TransferNode,
} from './constants';
import {
  Tabs,
  Table,
  Icon,
  Spin,
  Row,
  Col,
  Skeleton,
  Button,
  notification,
  Popconfirm,
} from 'antd';
import { CompanyService } from '../../service/CompanyService';
import { AddTransfer } from './AddTransfer';
import { checkAddPermission } from '../helper';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { PDFViewer } from '@react-pdf/renderer';
import moment from 'moment';
import { TransferTaxInvoiceJharcraft } from './TransferTaxInvoiceJharcraft';
import { ColumnProps } from '../common/Table';
import { TransferReceipt } from './TransferReceipt';
import { TSInput } from '../common/TSInput';
import { GraphqlQuery } from 'requestapijs';
import { DateService } from '../../service/DateService';
import { TransferNodeConnection, TransferNodeEdge } from '../../schema';
import { Pagination } from 'sha-el-design/lib';
import { RouteComponentProps } from 'react-router';
import { LocationWiseTransferReport } from './LocationWiseTransferReport';
import { InterLocationTransferReport } from './InterLocationTransferReport';
import { TransferService } from '../../service/TransferService';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { ItemWiseTransferReport } from './ItemWiseTransferReport';
import { BranchNodeEdge } from '../master/Branch/constants';
import { TransferTaxInvoiceKhadi } from './TransferTaxInvoiceKhadi';
import { ItemWiseTransitReport } from './ItemWiseTransitReport';

export class Transfer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedTransfer: null,
      business: null,
      company: null,
      isEditActive: false,
      invoiceNo: '',
      after: '',
      currentPage: 1,
      transferToSameState: false,
      updateListCount: 0,
      user: null,
      branch: null,
    };
  }

  companyService = new CompanyService();
  dateService = new DateService();
  transferService = new TransferService();
  userService = new UserService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1' || this.state.activeKey === '3') {
      this.setState({ activeKey, isEditActive: false, selectedTransfer: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey, invoiceNo: '' });
  };

  transferColumn = () => {
    const transferColumns: ColumnProps<TransferNodeEdge>[] = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({
                selectedTransfer: {
                  cusror: obj.cursor,
                  node: obj.node as unknown as TransferNode,
                },
                activeKey: '2',
                isEditActive:
                  this.state.company?.name.includes('Seva Sadan') ||
                  obj.node?.status === 'PENDING'
                    ? false
                    : true,
              });
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'To',
        dataIndex: 'node.toLocation.name',
        key: 'toLocation',
        render: (value) => value || 'Head Office',
      },
    ];
    if (
      ['Seva Sadan '].indexOf(
        (this.state.company?.name as string).substr(0, 11),
      ) === -1
    ) {
      transferColumns.push({
        title: 'Receipt',
        dataIndex: 'receipt',
        key: 'receipt',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({
                selectedTransfer: {
                  cusror: obj.cursor,
                  node: obj.node as unknown as TransferNode,
                },
                transferToSameState:
                  (obj.node?.fromHeadOffice
                    ? this.state.company?.gstin?.slice(0, 2)
                    : (
                        obj.node?.fromLocation || obj.node?.vendorFor
                      )?.gstin?.slice(0, 2)) ===
                  (obj.node?.toHeadOffice
                    ? this.state.company?.gstin?.slice(0, 2)
                    : (
                        obj.node?.toLocation || obj.node?.vendorFor
                      )?.gstin?.slice(0, 2))
                    ? true
                    : false,
                activeKey: '3',
              });
            }}
          >
            <Icon type="download" />
          </a>
        ),
      });
    }
    transferColumns.push({
      title: 'Cancel',
      key: 'cancel',
      render: (text: TransferEdges) => (
        <Popconfirm
          title="Are you sure to Delete?"
          onConfirm={() =>
            text.node.id &&
            this.transferService?.deleteTransfer(text.node.id, () => {
              this.setState({
                updateListCount: this.state.updateListCount + 1,
              });
              notification.success({
                message: 'Deleted',
                description: `Transfer was successfully deleted`,
              });
            })
          }
          okText="Yes"
          cancelText="No"
          disabled={
            text.node.status !== 'PENDING' ||
            (!!this.state.user && !checkAddPermission(this.state.user))
          }
        >
          <Button
            children={<Icon type="delete" />}
            disabled={
              text.node.status !== 'PENDING' ||
              (!!this.state.user && !checkAddPermission(this.state.user))
            }
            type="danger"
          />
        </Popconfirm>
      ),
    });
    return transferColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;

    const {
      company: { id: companyId },
      business,
      after,
      currentPage,
      activeKey,
      invoiceNo,
    } = this.state;
    const date_Lte = this.dateService?.date$.value;
    const { fromLocationId, fromHeadOffice } =
      business.type === 'branch'
        ? {
            fromLocationId: business.id,
            fromHeadOffice: false,
          }
        : {
            fromLocationId: null,
            fromHeadOffice: true,
          };
    const variables = {
      companyId,
      fromLocationId,
      fromHeadOffice,
      date_Lte,
      activeKey,
      first: 20,
      jobOrderId_Isnull: true,
    };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Transfer
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.invoiceNo}
                onChange={(e) => this.setState({ invoiceNo: e.target.value })}
                name="invoiceNo"
                type="text"
                addonBefore="Search"
                placeholder="Enter Invoice No"
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={transferQuery}
            variables={
              invoiceNo ? { ...variables, invoiceNo } : { ...variables, after }
            }
            render={(
              response: { allTransfers: TransferNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allTransfers.edges}
                    columns={this.transferColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allTransfers.pageInfo.endCursor,
                        response.allTransfers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Transfer
              </span>
            }
            key="2"
          >
            <AddTransfer
              transfer={this.state.selectedTransfer?.node}
              handleTabChange={this.handleTabChange}
              isEditActive={this.state.isEditActive}
              company={this.state.company}
              business={this.state.business}
              transferTo={this.props.match.params.transferTo}
            />
          </Tabs.TabPane>
        )}
        {this.state.selectedTransfer && this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            <PDFViewer style={{ width: '100%', height: '1000px' }}>
              {this.state.company?.name === 'Jharcraft' ? (
                <TransferTaxInvoiceJharcraft
                  company={this.state.company}
                  business={this.state.business}
                  transfer={this.state.selectedTransfer.node}
                  transferToSameState={this.state.transferToSameState}
                />
              ) : this.state.company?.name.toLowerCase().includes('khadi') ? (
                <TransferTaxInvoiceKhadi
                  company={this.state.company}
                  business={this.state.business}
                  transfer={this.state.selectedTransfer.node}
                  transferToSameState={this.state.transferToSameState}
                />
              ) : (
                <TransferReceipt
                  company={this.state.company}
                  business={this.state.business}
                  transfer={this.state.selectedTransfer.node}
                />
              )}
            </PDFViewer>
          </Tabs.TabPane>
        )}
        {this.state.business.type === 'company' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                {this.state.company.name.toLowerCase().includes('seva sadan')
                  ? 'Department'
                  : 'Location'}{' '}
                Wise Transfer Report
              </span>
            }
            key="4"
          >
            <LocationWiseTransferReport company={this.state.company} />
          </Tabs.TabPane>
        )}
        {(this.state.company.name.toLowerCase() === 'jharcraft' ||
          this.state.company?.name.toLowerCase().includes('khadi')) &&
          this.state.business.type.toLowerCase() === 'branch' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" /> Location Wise Transfer Report
                </span>
              }
              key="4"
            >
              <LocationWiseTransferReport company={this.state.company} />
            </Tabs.TabPane>
          )}
        {(this.state.company.name === 'Jharcraft' ||
          this.state.company?.name.toLowerCase().includes('khadi')) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Inter Location Transfer Report
              </span>
            }
            key="5"
          >
            <InterLocationTransferReport
              company={this.state.company}
              activeKey={this.state.activeKey}
              business={this.state.business}
              transferTo={this.props.match.params.transferTo}
            />
          </Tabs.TabPane>
        )}
        {(this.state.company.name.toLowerCase() === 'jharcraft' ||
          this.state.company?.name.toLowerCase().includes('khadi')) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Rejected Transfer List
              </span>
            }
            key="6"
          >
            <Row>
              <Col span={8}>
                <TSInput
                  error=""
                  value={this.state.invoiceNo}
                  onChange={(e) => this.setState({ invoiceNo: e.target.value })}
                  name="invoiceNo"
                  type="text"
                  addonBefore="Search"
                  placeholder="Enter Invoice No"
                />
              </Col>
            </Row>
            <GraphqlQuery
              queryString={rejectedTransferQuery}
              variables={
                invoiceNo
                  ? {
                      ...variables,
                      invoiceNo,
                      status: 'REJECTED, PARTIALLY_ACCEPTED',
                    }
                  : {
                      ...variables,
                      after,
                      status: 'REJECTED, PARTIALLY_ACCEPTED',
                    }
              }
              render={(
                response: { allTransfers: TransferNodeConnection },
                error,
                loading,
              ) => {
                if (loading) return <Skeleton active />;
                if (response && companyId) {
                  return [
                    <Table
                      key="table"
                      dataSource={response.allTransfers.edges}
                      columns={this.transferColumn()}
                      rowKey={(obj) => obj.node?.id || ''}
                      pagination={false}
                    />,
                    <Pagination
                      key="pagination"
                      totalCount={0}
                      currentPage={currentPage}
                      batchSize={20}
                      cursorBasedPagination
                      onChange={(_p, _ps, next) =>
                        this.onPageChange(
                          next,
                          response.allTransfers.pageInfo.endCursor,
                          response.allTransfers.pageInfo.hasNextPage,
                        )
                      }
                      style={{ float: 'right' }}
                    />,
                  ];
                }
                return <Skeleton active />;
              }}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Item Wise Transfer Report
            </span>
          }
          key="7"
        >
          <ItemWiseTransferReport
            company={this.state.company}
            branch={this.state.branch}
            business={this.state.business}
            activeKey={this.state.activeKey}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Item Wise Transit Report
            </span>
          }
          key="8"
        >
          <ItemWiseTransitReport
            company={this.state.company}
            branch={this.state.branch}
            business={this.state.business}
            activeKey={this.state.activeKey}
          />
        </Tabs.TabPane>
        {/* {this.state.company.name.toLowerCase() === 'jharcraft' &&
          this.state.business.type === 'company' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Transfer from HO
                </span>
              }
              key="8"
            >
              <iframe
                src="http://reports.scriptitsolution.tech/public/question/0bc9cc1f-4cf1-4aff-8fbb-f5da0aa10313"
                frameBorder="0"
                width="100%"
                height="1000"
                allowTransparency
              ></iframe>
            </Tabs.TabPane>
          )}
        {this.state.company.name.toLowerCase() === 'jharcraft' &&
          this.state.business.type === 'company' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Transfer From One - Another Location
                </span>
              }
              key="9"
            >
              <iframe
                src="http://reports.scriptitsolution.tech/public/question/7c817a67-4aa8-44d8-b046-4dc7a45ac11c"
                frameBorder="0"
                width="100%"
                height="1000"
                allowTransparency
              ></iframe>
            </Tabs.TabPane>
          )} */}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedTransfer: TransferEdges | null;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  invoiceNo: string;
  after: string;
  currentPage: number;
  transferToSameState: boolean;
  updateListCount: number;
  user?: UserNodeEdge | null;
  branch?: BranchNodeEdge | null;
}

type Props = RouteComponentProps<{ transferTo: 'to_department' | 'to_branch' }>;

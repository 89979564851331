import * as React from 'react';
import {
  SalesPersonNodeEdge,
  SalesPersonQueryResponse,
  allSalesPersonQuery,
} from './constants';
import { Table, Tabs, Icon, Skeleton } from 'antd';
import { AddSalesPerson } from './AddSalesPerson';
import { checkAddPermission } from '../../helper';
import { SalesService } from '../../../service/SalesService';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';
import { GraphqlQuery } from 'requestapijs';
import { CompanyService } from '../../../service/CompanyService';
import { Pagination } from 'sha-el-design';

export class SalesPerson extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      salesPerson: [],
      activeKey: '1',
      selectedSalesPerson: null,
      after: '',
      currentPage: 1,
      user: null,
      branchId: null,
      companyId: null,
    };
  }

  salesService = new SalesService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyId: company.id }),
    );
    this.companyService?.selectedBussiness$.subscribe(
      (business) => business && this.setState({ branchId: business.id }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedSalesPerson: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  salesPersonColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedSalesPerson: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
  ];

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey, branchId, companyId } = this.state;
    const variables = { after, branchId, companyId, activeKey };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Sales Person
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={allSalesPersonQuery}
            variables={variables}
            render={(response: SalesPersonQueryResponse, error, loading) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allSalesPerson.edges}
                    columns={this.salesPersonColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allSalesPerson.pageInfo.endCursor,
                        response.allSalesPerson.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Sales Person
            </span>
          }
          key="2"
        >
          <AddSalesPerson
            salesPerson={this.state.selectedSalesPerson?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  salesPerson: {
    cursor: string;
    node: SalesPersonNodeEdge;
  }[];
  activeKey: string;
  selectedSalesPerson: {
    cursor: string;
    node: SalesPersonNodeEdge;
  } | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  branchId?: string | null;
  companyId?: string | null;
}

interface Props {}

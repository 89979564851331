import * as React from 'react';
import { SalesNodeEdgeQuery } from './constants';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { sumBy, uniq } from 'lodash';
import { inWords } from '../helper';
import moment from 'moment';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../config';

export const SaleReceiptJharcraft: React.FunctionComponent<Props> = (props) => {
  let productDetailsEdges = props.sale.productDetails?.edges;
  const oldProductDetailsEdges = props.sale.oldProductDetails?.edges;
  const totalOldAmount =
    oldProductDetailsEdges?.length &&
    sumBy(oldProductDetailsEdges, (value) => value.node.amount || 0);
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node.quantity || 0,
  );
  const totalAmount = sumBy(
    productDetailsEdges,
    (value) => value.node.amount || 0,
  );
  const totalSale = sumBy(
    productDetailsEdges,
    (value) => (value.node.quantity || 0) * (value.node.rate || 0),
  );
  const valueAdditionAmount = Number(
    props.sale.isValueAddition && props.sale.valueAdditionAmount,
  );
  const totalExchange =
    oldProductDetailsEdges?.length &&
    sumBy(
      oldProductDetailsEdges,
      (value) => (value.node.quantity || 0) * (value.node.rate || 0),
    );
  const gstRateList = productDetailsEdges?.map((pde) =>
    Number(pde.node.gstRate),
  );
  const gstRates = uniq(gstRateList);
  productDetailsEdges = productDetailsEdges?.map((pde) => {
    // tslint:disable-next-line:no-string-literal
    pde.node['taxableAmt'] =
      (pde.node.amount || 0) / (1 + 0.01 * Number(pde.node.gstRate));
    // tslint:disable-next-line:no-string-literal
    pde.node['cgstAmt'] = pde.node['sgstAmt'] =
      ((pde.node.amount || 0) - pde.node['taxableAmt']) / 2;
    return pde;
  });
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.15, height: 50 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={[style.section, { marginTop: 0 }]}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '13pt' },
              ]}
            >
              {props.branch.name}
            </Text>
          </View>
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.lightBlackText]}>
              {props.branch.address}, Mo: {props.branch.contactNo}
            </Text>
            <Text
              style={[style.inlineHead, style.lightBlackText, { flex: 0.7 }]}
            >
              Helpline No: {props.company.contactNo}
            </Text>
            <Text style={[style.inlineHead, style.lightBlackText]}>
              Email: {props.branch.email}
            </Text>
            <Text
              style={[style.inlineHead, style.lightBlackText, { flex: 0.8 }]}
            >
              GSTIN: {props.branch.gstin}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Tax Invoice
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>{props.sale.billNo}</Text>
            </Text>
            <Text style={[style.lightBlackText, { flex: 0.5 }]}>
              Date: {moment(props.sale.date).format('DD-MM-YYYY')}
            </Text>
            <Text
              style={[style.lightBlackText, style.textCenter, { flex: 0.4 }]}
            >
              Cashier: Manager
            </Text>
            <Text
              style={[style.lightBlackText, style.textRight, { flex: 0.4 }]}
            >
              Original Bill
            </Text>
          </View>
          <View style={style.divider} />
        </View>
        <View>
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textLeft,
                { flex: 0.2 },
              ]}
            >
              Sl.
            </Text>
            <Text
              style={[
                style.highFlexInlineHead,
                style.mediumBlackText,
                { flex: 3.1 },
                style.textLeft,
              ]}
            >
              Product
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textLeft]}
            >
              Barcode
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textLeft,
                { flex: 0.5 },
              ]}
            >
              HSN
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Qty
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Rate
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              Disc.
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Amount
            </Text>
          </View>
          <View style={style.divider} />
          {productDetailsEdges?.map((pd, i) => (
            <View
              style={[style.section, style.inline]}
              key={'pd' + i}
              wrap={false}
            >
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  style.textLeft,
                  { flex: 0.2 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.mediumBlackText,
                  style.textLeft,
                  { flex: 3.1 },
                ]}
              >
                {pd.node.product?.name}
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textLeft,
                ]}
              >
                {pd.node.product?.oldBarcodeId}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textLeft,
                  { flex: 0.5 },
                ]}
              >
                {pd.node.product?.hsn && pd.node.product?.hsn.hsnCode}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node.quantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node.rate}
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {(
                  ((pd.node.quantity || 0) *
                    (pd.node.rate || 0) *
                    (pd.node.discount || 0)) /
                  100
                ).toFixed(2) + `(${Number(pd.node.discount)}%)`}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {pd.node.amount?.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={style.divider} />
          {oldProductDetailsEdges?.length ? (
            <View>
              <View style={[style.section, style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                    { flex: 0.2 },
                  ]}
                >
                  Sl.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                    { flex: 3.1 },
                  ]}
                >
                  Exchange Product
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                  ]}
                >
                  Barcode
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                    { flex: 0.5 },
                  ]}
                >
                  HSN
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { flex: 0.4 },
                  ]}
                >
                  Qty
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { flex: 0.4 },
                  ]}
                >
                  Rate
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={style.divider} />
              {oldProductDetailsEdges.map((opd, i) => (
                <View
                  style={[style.section, style.inline]}
                  key={'opd' + i}
                  wrap={false}
                >
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      style.textLeft,
                      { flex: 0.2 },
                    ]}
                  >
                    {i + 1}.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      style.textLeft,
                      { flex: 3.1 },
                    ]}
                  >
                    {opd.node.product?.name}
                  </Text>
                  <Text
                    style={[
                      style.inlineHead,
                      style.mediumBlackText,
                      style.textLeft,
                    ]}
                  >
                    {opd.node.product?.oldBarcodeId}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textLeft,
                      { flex: 0.5 },
                    ]}
                  >
                    {opd.node.product?.hsn && opd.node.product?.hsn.hsnCode}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { flex: 0.4 },
                    ]}
                  >
                    {opd.node.quantity}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { flex: 0.4 },
                    ]}
                  >
                    {opd.node.rate}
                  </Text>
                  <Text
                    style={[
                      style.inlineHead,
                      style.mediumBlackText,
                      style.textRight,
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                    ]}
                  >
                    {opd.node.amount?.toFixed(2)}
                  </Text>
                </View>
              ))}
              <View style={style.divider} />
            </View>
          ) : (
            <Text />
          )}
          {props.sale.isValueAddition ? (
            <View>
              <View style={[style.section, style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                    { flex: 0.2 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.mediumBlackText,
                    style.textLeft,
                    { flex: 6.4 },
                  ]}
                >
                  Value Addition({props.sale.valueAdditionRemarks})
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  {props.sale.valueAdditionAmount?.toFixed(2)}
                </Text>
              </View>
              <View style={style.divider} />
            </View>
          ) : (
            <Text />
          )}
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.highFlexInlineHead,
                style.darkBlackText,
                style.textLeft,
                { flex: 3.1 },
              ]}
            >
              Grand Total:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.2 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.5 },
              ]}
            >
              {}
            </Text>
            <Text style={[style.inlineHead, style.mediumBlackText]}>{}</Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              {totalQty}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.4 },
              ]}
            >
              {}
            </Text>
            <Text style={[style.inlineHead, style.mediumBlackText]}>{}</Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {Math.round(
                totalAmount -
                  (totalOldAmount || 0) +
                  (props.sale.valueAdditionAmount || 0),
              ).toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={style.section}>
            <Text style={style.darkBlackText}>
              Rs.{' '}
              {inWords(
                Math.round(
                  totalAmount -
                    (totalOldAmount || 0) +
                    (props.sale.valueAdditionAmount || 0),
                ),
              )}
              .
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.inlineHead,
                style.darkBlackText,
                { fontSize: '9.0pt' },
              ]}
            >
              Total Sale: {(totalSale + valueAdditionAmount).toFixed(2) + '/-'}
            </Text>
            <Text
              style={[
                style.inlineHead,
                style.darkBlackText,
                { fontSize: '9.0pt' },
              ]}
            >
              Manual Disc.:{' '}
              {sumBy(
                productDetailsEdges,
                (pd) =>
                  ((pd.node.quantity || 0) *
                    (pd.node.rate || 0) *
                    (pd.node.discount || 0)) /
                  100,
              ).toFixed(2) + '/-'}
            </Text>
            <Text
              style={[
                style.inlineHead,
                style.darkBlackText,
                { fontSize: '9.0pt' },
              ]}
            >
              Total Savings:{' '}
              {sumBy(
                productDetailsEdges,
                (pd) =>
                  ((pd.node.quantity || 0) *
                    (pd.node.rate || 0) *
                    (pd.node.discount || 0)) /
                  100,
              ).toFixed(2) + '/-'}
            </Text>
            <Text
              style={[
                style.inlineHead,
                style.darkBlackText,
                { fontSize: '9.0pt' },
              ]}
            >
              Exch.: {totalExchange?.toFixed(2) + '/-'}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                { fontSize: '9.0pt' },
              ]}
            >
              Round Off:
              {(
                Math.round(
                  totalAmount -
                    (totalOldAmount || 0) +
                    (props.sale.valueAdditionAmount || 0),
                ) -
                (totalAmount -
                  (totalOldAmount || 0) +
                  (props.sale.valueAdditionAmount || 0))
              ).toFixed(2) + '/-'}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={style.section}>
            <Text style={[style.textRight, style.darkBlackText]}>
              Net Payable:{' '}
              {Math.round(
                totalAmount -
                  (totalOldAmount || 0) +
                  (props.sale.valueAdditionAmount || 0),
              ).toFixed(2) + '/-'}
            </Text>
          </View>
          <View style={style.divider} />
        </View>
        <View wrap={false}>
          <View style={[style.section, style.inline]}>
            <View style={[style.box, { padding: 5 }, style.inlineHead]}>
              {props.sale.paymentModes.edges.map((pm) => (
                <Text style={style.lightBlackText}>
                  {pm.node.paymentType}
                  {pm.node.paymentType === 'Bank' && `(RTGS/NEFT/IMPS)`}:{' '}
                  {Math.round(pm.node.amount).toFixed(2) + '/-'}
                </Text>
              ))}
              <Text style={style.lightBlackText}>
                Total:{' '}
                {Math.round(
                  totalAmount -
                    (totalOldAmount || 0) +
                    (props.sale.valueAdditionAmount || 0),
                ).toFixed(2) + '/-'}
              </Text>
              {props.sale.oldBillNo && (
                <Text style={style.lightBlackText}>
                  Old Bill No: {props.sale.oldBillNo}
                </Text>
              )}
              {props.sale.customer && (
                <Text style={style.lightBlackText}>
                  Customer: {props.sale.customer.name}
                  {props.sale.customer.contactNo
                    ? `(${props.sale.customer.contactNo})`
                    : ''}
                </Text>
              )}
              {props.sale.gstinLegalName && (
                <Text style={style.lightBlackText}>
                  GSTIN Legel Name: {props.sale.gstinLegalName}
                </Text>
              )}
              {props.sale.gstinNumber && (
                <Text style={style.lightBlackText}>
                  GSTIN Number: {props.sale.gstinNumber}
                </Text>
              )}
            </View>
            <View style={[style.box, { padding: 5 }, style.highFlexInlineHead]}>
              <Text style={style.darkBlackText}>GST Summary</Text>
              {props.sale.gstinNumber &&
              props.sale.gstinNumber.slice(0, 2) !==
                props.branch.gstin?.slice(0, 2) ? (
                <View>
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      Description
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      Taxable Amt
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      IGST Amt
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      CESS Amt
                    </Text>
                  </View>
                </View>
              ) : (
                <View>
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      Description
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      Taxable Amt
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      CGST Amt
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      SGST Amt
                    </Text>
                    <Text
                      style={[
                        style.darkBlackText,
                        style.inlineHead,
                        { textDecoration: 'underline', fontSize: '9.3pt' },
                      ]}
                    >
                      CESS Amt
                    </Text>
                  </View>
                </View>
              )}
              {gstRates.map((gr) =>
                props.sale.gstinNumber &&
                props.sale.gstinNumber.slice(0, 2) !==
                  props.branch.gstin?.slice(0, 2) ? (
                  <View style={[style.inline, { paddingTop: 1 }]}>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      GST {gr}%
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      {Number(
                        sumBy(productDetailsEdges, (value) =>
                          Number(value.node.gstRate) === gr
                            ? /* tslint:disable-next-line:no-string-literal */
                              Number(value.node['taxableAmt'])
                            : 0,
                        ).toFixed(2),
                      )}
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      {Number(
                        (
                          sumBy(productDetailsEdges, (value) =>
                            Number(value.node.gstRate) === gr
                              ? /* tslint:disable-next-line:no-string-literal */
                                Number(value.node['cgstAmt'])
                              : 0,
                          ) * 2
                        ).toFixed(2),
                      )}
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      0.00
                    </Text>
                  </View>
                ) : (
                  <View style={[style.inline, { paddingTop: 1 }]}>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      GST {gr}%
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      {Number(
                        sumBy(productDetailsEdges, (value) =>
                          Number(value.node.gstRate) === gr
                            ? /* tslint:disable-next-line:no-string-literal */
                              Number(value.node['taxableAmt'])
                            : 0,
                        ).toFixed(2),
                      )}
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      {Number(
                        sumBy(productDetailsEdges, (value) =>
                          Number(value.node.gstRate) === gr
                            ? /* tslint:disable-next-line:no-string-literal */
                              Number(value.node['cgstAmt'])
                            : 0,
                        ).toFixed(2),
                      )}
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      {Number(
                        sumBy(productDetailsEdges, (value) =>
                          Number(value.node.gstRate) === gr
                            ? /* tslint:disable-next-line:no-string-literal */
                              Number(value.node['sgstAmt'])
                            : 0,
                        ).toFixed(2),
                      )}
                    </Text>
                    <Text style={[style.mediumBlackText, style.inlineHead]}>
                      0.00
                    </Text>
                  </View>
                ),
              )}
              <View style={style.divider} />
              {props.sale.gstinNumber &&
              props.sale.gstinNumber.slice(0, 2) !==
                props.branch.gstin?.slice(0, 2) ? (
                <View style={[style.inline, { paddingTop: 2 }]}>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    Totals:
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    {/* tslint:disable-next-line:no-string-literal */}
                    {Number(
                      sumBy(productDetailsEdges, (value) =>
                        Number(value.node['taxableAmt']),
                      ).toFixed(2),
                    )}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    {/* tslint:disable-next-line:no-string-literal */}
                    {Number(
                      (
                        sumBy(productDetailsEdges, (value) =>
                          Number(value.node['cgstAmt']),
                        ) * 2
                      ).toFixed(2),
                    )}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    0.00
                  </Text>
                </View>
              ) : (
                <View style={[style.inline, { paddingTop: 2 }]}>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    Totals:
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    {/* tslint:disable-next-line:no-string-literal */}
                    {Number(
                      sumBy(productDetailsEdges, (value) =>
                        Number(value.node['taxableAmt']),
                      ).toFixed(2),
                    )}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    {/* tslint:disable-next-line:no-string-literal */}
                    {Number(
                      sumBy(productDetailsEdges, (value) =>
                        Number(value.node['cgstAmt']),
                      ).toFixed(2),
                    )}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    {/* tslint:disable-next-line:no-string-literal */}
                    {Number(
                      sumBy(productDetailsEdges, (value) =>
                        Number(value.node['sgstAmt']),
                      ).toFixed(2),
                    )}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      { fontSize: '9.3pt' },
                    ]}
                  >
                    0.00
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Sales Person: {props.sale.salesPerson?.name ?? 'NA'}
            </Text>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.sale.remarks}
            </Text>
          </View>
          <View style={style.section}>
            <Text>
              <Text
                style={[style.darkBlackText, { textDecoration: 'underline' }]}
              >
                Terms &amp; Conditions
              </Text>
              <Text style={[style.darkBlackText]}>:- </Text>
              <Text style={[style.mediumBlackText, { fontSize: '10pt' }]}>
                1.{' '}
              </Text>
              <Text style={[style.lightBlackText]}>
                This Invoice must be produced in the event of any complaint.
              </Text>
              <Text style={[style.mediumBlackText, { fontSize: '10pt' }]}>
                {' '}
                2.{' '}
              </Text>
              <Text style={[style.lightBlackText]}>
                Goods once sold will not be taken back.
              </Text>
              <Text style={[style.mediumBlackText, { fontSize: '10pt' }]}>
                {' '}
                3.{' '}
              </Text>
              <Text style={[style.lightBlackText]}>
                Price are inclusive of GST.
              </Text>
              <Text style={[style.mediumBlackText, { fontSize: '10pt' }]}>
                {' '}
                4.{' '}
              </Text>
              <Text style={[style.lightBlackText]}>
                This is computer generated invoice &amp; doesn't require
                signature.
              </Text>
              <Text style={[style.mediumBlackText, { fontSize: '10pt' }]}>
                {' '}
                5.{' '}
              </Text>
              <Text style={[style.lightBlackText]}>
                Cheque/DD payment will be accepted only in favour of "Jharkhand
                Silk, Textile &amp; Handicraft Development Corporation Ltd."
              </Text>
            </Text>
          </View>
          <View style={style.section}>
            <Text style={[style.mediumBlackText, style.textCenter]}>
              ***THANK YOU. PLEASE VISIT AGAIN***
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#363636', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.4pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  sale: SalesNodeEdgeQuery;
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

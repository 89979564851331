import * as React from 'react';
import { Tabs, Table, Icon, Spin, Skeleton } from 'antd';
import { CompanyService } from '../../../service/CompanyService';
import { checkAddPermission } from '../../helper';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { pendingProductionReceivingQuery } from './constants';
import {
  ProductionTransferNodeEdge,
  ProductionTransferNodeConnection,
} from '../../../schema';
import { Pagination } from 'sha-el-design/lib';
import { DateService } from '../../../service/DateService';
import { PDFViewer } from '@react-pdf/renderer';
import { ProductionTransferService } from '../../../service/ProductionTransferService';
import { AddPendingProductionReceiving } from './AddPendingProductionReceiving';
import { AcceptedProductionReceiving } from './AcceptedProductionReceiving';
import { ProductionReceivingReceiptJharcraft } from './ProductionReceivingReceiptJharcraft';
import { ProductionReceivingTaxInvoiceJharcraft } from './ProductionReceivingTaxInvoiceJharcraft';
import { AddProductionReceiving } from './AddProductionReceiving';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';
import { ItemWiseProductionReceivngReport } from './ItemWiseProductionReceivingReport';
import { SummarizedProductionReceivingReport } from './SummarizedProductionReceivingReport';

export class PendingProductionReceiving extends React.Component<
  unknown,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedTransfer: null,
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      user: null,
    };
  }

  productionTransferService = new ProductionTransferService();
  companyService = new CompanyService();
  dateService = new DateService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedTransfer: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  transferColumn = () => {
    const transferColumns = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({ selectedTransfer: obj, activeKey: '2' });
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'From',
        dataIndex: 'node.fromBranch.name',
        key: 'fromBranch',
      },
    ];
    return transferColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  handleSelectedReceiving = (
    selectedTransfer: ProductionTransferNodeEdge,
    activeKey: string,
  ) => {
    this.setState({ selectedTransfer, activeKey });
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      business: { type: businessType, id: businessId },
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Pending Production Receiving
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={pendingProductionReceivingQuery}
            variables={{
              companyId,
              activeKey,
              toBranchId: businessType === 'branch' ? businessId : '',
              toHeadOffice: businessType === 'company' ? true : false,
              date_Lte: this.dateService?.date$.value,
            }}
            render={(
              response: {
                allProductionTransfers: ProductionTransferNodeConnection;
              },
              error,
              loading,
            ) => {
              if (loading) {
                return <Spin />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProductionTransfers.edges}
                    columns={this.transferColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProductionTransfers.pageInfo.endCursor,
                        response.allProductionTransfers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.activeKey === '2' &&
          this.state.user &&
          checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Accept/Reject
                </span>
              }
              key="2"
            >
              <AddPendingProductionReceiving
                transfer={this.state.selectedTransfer?.node}
                handleTabChange={this.handleTabChange}
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Accepted Production Receiving
            </span>
          }
          key="3"
        >
          <AcceptedProductionReceiving
            activeKey={this.state.activeKey}
            handleSelectedReceiving={this.handleSelectedReceiving}
          />
        </Tabs.TabPane>
        {this.state.selectedTransfer && this.state.activeKey === '4' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="4"
          >
            {(this.state.company?.name === 'Jharcraft' ||
              this.state.company?.name.toLowerCase().includes('khadi')) &&
              this.state.selectedTransfer.node && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <ProductionReceivingReceiptJharcraft
                    company={this.state.company}
                    business={this.state.business}
                    receiving={this.state.selectedTransfer.node}
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
        {this.state.selectedTransfer && this.state.activeKey === '5' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="5"
          >
            {(this.state.company?.name === 'Jharcraft' ||
              this.state.company?.name.toLowerCase().includes('khadi')) &&
              this.state.selectedTransfer.node && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <ProductionReceivingTaxInvoiceJharcraft
                    company={this.state.company}
                    business={this.state.business}
                    receiving={this.state.selectedTransfer.node}
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Production Receiving
              </span>
            }
            key="6"
          >
            <AddProductionReceiving
              company={this.state.company}
              business={this.state.business}
              handleTabChange={this.handleTabChange}
            />
          </Tabs.TabPane>
        )}
        {(this.state.company?.name === 'Jharcraft' ||
          this.state.company?.name.toLowerCase().includes('khadi')) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Item Wise Production Receiving Report
              </span>
            }
            key="7"
          >
            <ItemWiseProductionReceivngReport
              company={this.state.company}
              activeKey={this.state.activeKey}
            />
          </Tabs.TabPane>
        )}
        {(this.state.company?.name === 'Jharcraft' ||
          this.state.company?.name.toLowerCase().includes('khadi')) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Summarized Production Receiving Report
              </span>
            }
            key="8"
          >
            <SummarizedProductionReceivingReport
              company={this.state.company}
              activeKey={this.state.activeKey}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedTransfer: ProductionTransferNodeEdge | null;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

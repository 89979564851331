import moment from 'moment';
import { ProductionInput } from '../../../schema';

export const productionObject: ProductionInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  companyId: '',
  branchId: '',
  conversionDetails: [
    {
      date: '',
      conversionId: '',
      cost: null,
      quantity: null,
      amount: null,
      companyId: '',
      remark: null,
      sourceBranchId: '',
      conversionType: 'PRODUCTION',
    },
  ],
  amount: 0,
  remark: '',
};

export const productionMutation = `
mutation addProduction($input: CreateProductionInput!) {
  createProduction(input: $input){
    newProduction{
      id
      amount
    }
  }
}
`;

export const productionQuery = `
query ProductionList($companyId: ID, $after: String, $branchId: ID, $date_Gte: Date, $date_Lte: Date) {
  allProductions(branchId: $branchId, first: 20, after: $after, companyId: $companyId, date_Gte: $date_Gte, date_Lte: $date_Lte) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        date
        billNo
        amount
        remark
        conversionDetails {
          edges {
            node {
              id
              conversion {
                id
              }
              quantity
              cost
              amount
              remark
              conversionType
            }
          }
        }
      }
    }
  }
}
`;

export const productionReportQuery = `
query ProductionList($companyId: ID, $after: String, $branchId: ID, $startDate: Date, $endDate: Date) {
  allProductions(branchId: $branchId, first: 20, after: $after, companyId: $companyId, date_Gte: $startDate, date_Lte: $endDate) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        date
        billNo
        amount
        remark
        conversionDetails {
          edges {
            node {
              id
              conversion {
                id
                finishedProduct
                productionType
              }
              quantity
              cost
              amount
              remark
              conversionType
            }
          }
        }
      }
    }
  }
}
`;

export const stockReportForProductsWithoutBarcode = `
query stockReportForProductsWithoutBarcode(
  $companyId: ID!,
  $productName: String,
  $branchId: ID!,
  $startDate: Date!,
  $endDate: Date!,
  $after: String,
  $first: Int
){
  allConversions(
    companyId: $companyId,
    finishedProduct_Icontains: $productName,
    after: $after,
    first: $first
  ){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        stock(
          branchId: $branchId,
          companyId: $companyId,
          startDate: $startDate,
          endDate: $endDate
        ){
          product
          production
          transfer
          receiving
        }
      }
    }
  }
}
`;

export interface ProductionError {
  date?: string;
  conversion?: string;
  quantity?: string;
  cost?: string;
  amount?: string;
  remark?: string;
}

export const productionErrorObj: ProductionError = {
  date: '',
  conversion: '',
  quantity: '',
  cost: '',
  amount: '',
  remark: '',
};

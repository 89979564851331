import * as React from 'react';
import { ProductCategoryNodeEdge, ProductCategoryError } from './constants';
import { TSInput } from '../../common/TSInput';

export const ProductCategoriesForm: React.FunctionComponent<Props> = (
  props,
) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error={props.error.initials}
        value={props.values.initials}
        onChange={props.onChange}
        maxLength={2}
        placeholder="Only 2 letters allowed"
        name="initials"
        type="text"
        addonBefore="Initials"
      />
    </div>
  );
};

interface Props {
  values: ProductCategoryNodeEdge;
  error: ProductCategoryError;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

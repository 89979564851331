import * as React from 'react';
import { Col, Row } from 'antd';
import { TSInput } from '../../common/TSInput';
import { DiscountCategoryNode } from './constants';

export const DiscountCategoryForm: React.FunctionComponent<Props> = (props) => (
  <div>
    <Row>
      <Col span={10}>
        <TSInput
          error=""
          value={props.values.name}
          type="text"
          name="name"
          onChange={props.onChange}
          addonBefore="Name"
        />
      </Col>
      <Col span={14}>
        <TSInput
          error=""
          value={props.values.description}
          type="text"
          name="description"
          onChange={props.onChange}
          addonBefore="Description"
        />
      </Col>
      {props.companyName?.toLowerCase().includes('khadi') && (
        <>
          <Col span={12}>
            <TSInput
              error=""
              value={props.values.mda}
              type="number"
              name="mda"
              onChange={props.onChange}
              addonBefore="MDA%"
            />
          </Col>
          <Col span={12}>
            <TSInput
              error=""
              value={props.values.commission}
              type="number"
              name="commission"
              onChange={props.onChange}
              addonBefore="Commission%"
            />
          </Col>
        </>
      )}
    </Row>
  </div>
);

interface Props {
  values: DiscountCategoryNode;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  companyName: string | null;
}

import * as React from 'react';
import { Tabs, Icon, Skeleton, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { remunerationQuery } from './constants';
import { RemunerationNode, RemunerationNodeConnection } from '../../../schema';
import { AddRemuneration } from './AddRemuneration';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';
import { checkAddPermission } from '../../helper';

export class Remuneration extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedRemuneration: null,
      company: null,
      currentPage: 1,
      after: '',
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedRemuneration: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  remunerationColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedRemuneration: obj.node, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;

    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Remuneration
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={remunerationQuery}
            variables={{ companyId, first: 20, after, activeKey }}
            render={(
              response: { allRemunerations: RemunerationNodeConnection },
              error,
              loading,
            ) => {
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allRemunerations.edges}
                    columns={this.remunerationColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allRemunerations.pageInfo.endCursor,
                        response.allRemunerations.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Remuneration
            </span>
          }
          key="2"
        >
          <AddRemuneration
            remuneration={this.state.selectedRemuneration}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedRemuneration: RemunerationNode | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {}

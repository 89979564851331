import * as React from 'react';
import moment from 'moment';
import { Row, Col, DatePicker, Divider } from 'antd';
import { TSInput } from '../common/TSInput';
import {
  SaleChallanInput,
  DeliveryInstructionNode,
  SaleChallanProductDetails,
} from '../../schema';
import { Label } from '../common/TSLabel';
import { CompanyNodeEdge } from '../master/Company/constants';
import { DeliveryInstructionDropdown } from '../DeliveryInstruction/DeliveryInstructionDropdown';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { SaleChallanError } from './constants';

export const SaleInvoiceForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={4}>
        <DatePicker
          value={props.values.date ? moment(props.values.date) : undefined}
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
          disabled={!props.company.name.toLowerCase().includes('tiles')}
        />
        <span style={{ color: 'red' }}>{props.error.date}</span>
      </Col>
      <Col span={6}>
        <DeliveryInstructionDropdown
          onSelect={(e, f) => {
            props.deliveryInstructionObjChangeHandler((f as any).props.label);
            props.onChange({
              target: { name: 'deliveryInstructionId', value: String(e) },
            });
          }}
          value={props.values.deliveryInstructionId || ''}
          allowClear
        />
        <span style={{ color: 'red' }}>
          {props.error.deliveryInstructionId}
        </span>
      </Col>
      <Col span={6}>
        <TSInput
          error=""
          type="text"
          value={
            (props.deliveryInstruction &&
              props.deliveryInstruction.customer.name) ||
            ''
          }
          placeholder="Customer"
        />
      </Col>
      <Col span={8}>
        <Label title="Delivery Instruction Date">
          <DatePicker
            value={
              props.deliveryInstruction && props.deliveryInstruction.date
                ? moment(props.deliveryInstruction.date)
                : undefined
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={9}>Product</Col>
        <Col span={2}>Order Quantity</Col>
        <Col span={2}>Rate</Col>
        <Col span={2}>Gst Rate</Col>
        <Col span={3}>Dispatched Quantity</Col>
        <Col span={2}>Remaining</Col>
        <Col span={3}>Quantity To Deliver</Col>
        <Divider />
      </Row>
      {(props.values.productDetails as any as SaleChallanProductDetails[]).map(
        (product, index) => (
          <div key={'products' + index}>
            <Row>
              <Col span={1}>{index + 1}.</Col>
              <Col span={9}>
                <ProductDropDown
                  allowClear
                  company={props.company}
                  value={product.productId}
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.orderQuantity}
                  name="orderQuantity"
                  placeholder="Order Quantity"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.rate && product.rate.toFixed(2)}
                  name="rate"
                  onChange={(e) => props.productChangeHandler(e, index)}
                  placeholder="Rate"
                  required
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={
                    product.product
                      ? product.product.hsn?.gst || 0
                      : props.company.gstRate || 0
                  }
                  name="gstRate"
                  placeholder="Gst Rate"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error=""
                  type="number"
                  value={product.dispatchedQuantity}
                  name="availableQuantity"
                  placeholder="Dispatched Quantity"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.remainingQuantity}
                  name="remainingQuantity"
                  placeholder="Remaining Quantity"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].quantity}
                  type="number"
                  value={product.quantity}
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(e) => props.productChangeHandler(e, index)}
                  disabled={product.remainingQuantity === 0}
                  required
                />
              </Col>
            </Row>
          </div>
        ),
      )}
      <TSInput
        error=""
        type="text"
        value={props.values.remarks || ''}
        name="remarks"
        placeholder="Remarks"
        onChange={props.onChange}
        required
      />
      <Col span={8}>
        <TSInput
          error={props.error.dispatchedThrough}
          type="text"
          value={props.values.dispatchedThrough || ''}
          name="dispatchedThrough"
          placeholder="Dispatched Through"
          onChange={props.onChange}
          required
        />
      </Col>
      <Col span={8}>
        <TSInput
          error={props.error.vehicleNo}
          type="text"
          value={props.values.vehicleNo}
          name="vehicleNo"
          placeholder="Vehicle No."
          onChange={props.onChange}
          required
        />
      </Col>
      <Col span={8}>
        <TSInput
          error={props.error.destination}
          type="text"
          value={props.values.destination}
          name="destination"
          placeholder="Destination"
          onChange={props.onChange}
          required
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          type="number"
          value={props.values.miscellaneousCharge}
          name="miscellaneousCharge"
          placeholder="Laying and Fitting Charge"
          onChange={props.onChange}
          addonBefore="Laying and Fitting Charge"
          required
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          type="number"
          value={props.values.transportationCharges}
          name="transportationCharges"
          placeholder="Transportation Charge"
          onChange={props.onChange}
          addonBefore="Transportation Charge"
          required
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          type="number"
          value={props.values.otherCharges}
          name="otherCharges"
          placeholder="Other Charges"
          onChange={props.onChange}
          addonBefore="Other Charges"
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: SaleChallanInput;
  error: SaleChallanError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  deliveryInstructionObjChangeHandler: (value: DeliveryInstructionNode) => void;
  deliveryInstruction?: DeliveryInstructionNode | null;
  company: CompanyNodeEdge;
}

import {
  DeliveryInstructionInput,
  ProductDetailsInput,
  ProductDetailsMovementType,
} from '../../schema';
import moment from 'moment';

export const deliveryProductDetailsObject: ProductDetailsInput = {
  productId: null,
  date: moment().format('YYYY-MM-DD'),
  movementType: ProductDetailsMovementType.DELIVERY_INSTRUCTION,
  quantity: null,
  rate: null,
  purchaseRate: null,
  amount: null,
  companyId: null,
};

export const deliveryInstructionObject: DeliveryInstructionInput = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  productDetails: [deliveryProductDetailsObject],
  purchaseOrderNumber: '',
  purchaseOrderDate: null,
  layingAndFittingCharge: 0,
  transportationCharge: 0,
  otherCharges: 0,
  isGstInclusive: false,
  remarks: '',
  companyId: null,
};

export interface DeliveryInstructionProductDetailsError {
  productId: string;
  quantity: string;
  rate: string;
  amount: string;
}

export const deliveryProductDetailsError: DeliveryInstructionProductDetailsError =
  {
    productId: '',
    quantity: '',
    rate: '',
    amount: '',
  };

export interface DeliveryInstructionError {
  date: string;
  customerId: string;
  isGstInclusive: string;
  productDetails: DeliveryInstructionProductDetailsError[];
}

export const deliveryInstructionErrorObject: DeliveryInstructionError = {
  customerId: '',
  date: '',
  isGstInclusive: '',
  productDetails: [deliveryProductDetailsError],
};

export const deliveryInstructionQuery = `
query DeliveryInstruction($companyId: ID, $after: String, $deliveryInstructionNo: String, $date_Gte: Date, $date_Lte: Date) {
  allDeliveryInstructions(
    companyId: $companyId,
    first: 20,
    after: $after,
    deliveryInstructionNo_Icontains: $deliveryInstructionNo,
    date_Gte: $date_Gte,
    date_Lte: $date_Lte,
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        date
        deliveryInstructionNo
        customer {
          id
          name
          contactNo
          gstinNumber
          address
          gstinLegalName
        }
        remarks
        purchaseOrderNumber
        purchaseOrderDate
        layingAndFittingCharge
        transportationCharge
        otherCharges
        isGstInclusive
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
                oldBarcodeId
                newBarcodeId
                unit {
                  name
                }
              }
              quantity
              rate
              purchaseRate
              amount
              movementType
            }
          }
        }
      }
    }
  }
}
`;

export const deliveryInstructionMutation = `
mutation DeliveryInstructionMutation($input: CreateDeliveryInstructionInput!) {
  createDeliveryInstruction(input: $input) {
    newDeliveryInstruction {
      id
    }
  }
}
`;

import * as React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import { VendorEdges } from '../master/vendor/constants';
import { VendorService } from '../../service/VendorService';
import { PurchaseOrderInput, PurchaseOrderNode } from '../../schema';
import {
  ProductDetails,
  purchaseOrderObject,
  PurchaseOrderError,
  purchaseOrderErrorObject,
  productDetailsErrorObject,
} from './constants';
import { PurchaseOrderService } from '../../service/PurchaseOrderService';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { CompanyNodeEdge } from '../master/Company/constants';

export class AddPurchaseOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      purchaseOrder: this.handlePropsValueUpdate(props),
      error: this.handlePropsErrorUpdate(props),
      vendors: [],
      buttonDisabled: false,
    };
  }

  purchaseOrderService = new PurchaseOrderService();
  vendorService = new VendorService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      purchaseOrder: this.handlePropsValueUpdate(nextProps),
      error: this.handlePropsErrorUpdate(nextProps),
    });
  }

  handlePropsValueUpdate = (props: Props) => {
    return props.purchaseOrder
      ? {
          ...props.purchaseOrder,
          vendorId: props.purchaseOrder.vendor?.id,
        }
      : JSON.parse(JSON.stringify(purchaseOrderObject));
  };

  handlePropsErrorUpdate = (props: Props) => {
    const error: PurchaseOrderError = JSON.parse(
      JSON.stringify(purchaseOrderErrorObject),
    );
    if (props.purchaseOrder) {
      const productDetails: ProductDetails[] = JSON.parse(
        props.purchaseOrder.productDetails,
      );
      productDetails.forEach(() => {
        if (productDetails.length !== error.productDetails.length) {
          error.productDetails.push(productDetailsErrorObject);
        }
      });
    }
    return error;
  };

  componentDidMount() {
    this.fetchVendors();
  }

  fetchVendors = () => {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const purchaseOrder = { ...this.state.purchaseOrder };
    purchaseOrder[e.target.name] = e.target.value;
    this.setState({ purchaseOrder });
  };

  checkError = () => {
    const error = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    Object.keys(purchaseOrderErrorObject).forEach((errKey) => {
      if (!this.state.purchaseOrder[errKey] && errKey !== 'productDetails') {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const productDetails: ProductDetails[] = JSON.parse(
      this.state.purchaseOrder.productDetails,
    );
    let isError = false;
    const error = JSON.parse(JSON.stringify(this.state.error));
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  customError = () => {
    const productDetails: ProductDetails[] = JSON.parse(
      this.state.purchaseOrder.productDetails,
    );
    let isError = false;
    const error = JSON.parse(JSON.stringify(this.state.error));
    const duplicateProducts = {};
    productDetails.forEach((productDetail, i) => {
      const index = productDetails.findIndex(
        (pd, j) => pd.productName === productDetail.productName && i !== j,
      );
      if (index > -1) {
        isError = true;
        duplicateProducts[index] = i;
      }
    });
    Object.keys(duplicateProducts).forEach((dp) => {
      error.productDetails[
        Number(dp)
      ].productName = `Product Name is Identical To Row No. ${
        duplicateProducts[dp] + 1
      }`;
    });
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const purchaseOrder = this.state.purchaseOrder;
    const productDetails: ProductDetails[] = JSON.parse(
      purchaseOrder.productDetails,
    ) as ProductDetails[];
    productDetails[index][e.target.name] = e.target.value;
    if (productDetails[index].quantity && productDetails[index].rate) {
      productDetails[index].amount =
        Number(productDetails[index].quantity) *
        Number(productDetails[index].rate);
    }
    purchaseOrder.productDetails = JSON.stringify(productDetails);
    this.setState({ purchaseOrder });
  };

  updateProductsRow = (type: string, index: number) => {
    const purchaseOrder = this.state.purchaseOrder;
    const productDetails: ProductDetails[] = JSON.parse(
      purchaseOrder.productDetails,
    ) as ProductDetails[];
    const error = { ...this.state.error };
    if (type === '+') {
      productDetails.push({
        productName: '',
        quantity: null,
        unit: '',
        rate: null,
        amount: null,
      });
      error.productDetails.push(productDetailsErrorObject);
    } else {
      productDetails.splice(index, 1);
      error.productDetails.splice(index, 1);
    }
    purchaseOrder.productDetails = JSON.stringify(productDetails);
    this.setState({ purchaseOrder });
  };

  savePurchaseOrder = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    if (this.customError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.purchaseOrderService?.addPurchaseOrder(
      this.state.purchaseOrder,
      (response) => {
        if (!this.props.purchaseOrder) {
          this.setState({
            purchaseOrder: JSON.parse(JSON.stringify(purchaseOrderObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Purchase Order ' + this.state.purchaseOrder.id
              ? 'Updated'
              : 'Added',
          description:
            `Purchase Order for order no ${this.state.purchaseOrder.purchaseOrderNo} was successfully ` +
            `${this.state.purchaseOrder.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Purchase Order ' + this.state.purchaseOrder.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      purchaseOrder: JSON.parse(JSON.stringify(purchaseOrderObject)),
      error: JSON.parse(JSON.stringify(purchaseOrderErrorObject)),
    });
  };

  render() {
    return (
      <div>
        <PurchaseOrderForm
          values={this.state.purchaseOrder}
          error={this.state.error}
          onChange={this.onChange}
          vendors={this.state.vendors}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          company={this.props.company}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.savePurchaseOrder}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  purchaseOrder?: PurchaseOrderNode | null;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
}

interface State {
  purchaseOrder: PurchaseOrderInput;
  error: PurchaseOrderError;
  vendors: VendorEdges[];
  buttonDisabled: boolean;
}

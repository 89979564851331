import * as React from 'react';
import { CompanyNodeEdge } from '../../master/Company/constants';
import moment from 'moment';
import {
  Row,
  Col,
  DatePicker,
  Skeleton,
  Table,
  Button,
  Icon,
  Divider,
} from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { directProductionReportQuery } from './constants';
import { ProductDetailsNodeConnection } from '../../../schema';
import { Pagination } from 'sha-el-design/lib';
import { DIRECT_PRODUCTION_REPORT_CSV_URL } from '../../../config';
import { downloadToFile } from '../../helper';

export class DirectProductionReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      updateDataCount: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Item',
      dataIndex: 'node.product.name',
      key: 'item',
      render: (text, record) => `${text} (${record.node.product.oldBarcodeId})`,
    },
    {
      title: 'Quantity',
      dataIndex: 'node.quantity',
      key: 'quantity',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(DIRECT_PRODUCTION_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `production-report-${new Date()}`,
          'PRODUCTION REPORT',
          this.props.company.name,
          '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { currentPage, after, startDate, endDate, updateDataCount } =
      this.state;
    const { company } = this.props;
    const variables = {
      companyId: company.id,
      after,
      startDate,
      endDate,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={directProductionReportQuery}
          variables={variables}
          render={(
            response: { allProductDetails: ProductDetailsNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && company) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProductDetails.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProductDetails.pageInfo.endCursor,
                      response.allProductDetails.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv({ ...variables, forSaleInvoice: 1 })}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  currentPage: number;
  after: string;
  startDate: string;
  endDate: string;
  updateDataCount: number;
}

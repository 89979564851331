import * as React from 'react';
import { notification, Button, Popconfirm } from 'antd';
import { ProductDetailsNodeConnection } from '../Sales/constants';
import { BranchEdges } from '../master/Branch/constants';
import { BranchService } from '../../service/BranchService';
import {
  ProductNode,
  JobOrderNode,
  JobOrderInput,
  ConversionNode,
  ProductDetailsInput,
  TransferNodeConnection,
  ConversionNodeConnection,
  ConversionDetailInput,
  ConversionDetailNodeConnection,
} from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { JobOrderError, jobOrderObject, jobOrderError } from './constants';
import { JobOrderService } from '../../service/JobOrderService';
import { JobOrderForm } from './JobOrderForm';
import { VendorService } from '../../service/VendorService';
import { VendorEdges } from '../master/vendor/constants';
import { handleProductDetailsData } from '../Transfer/constants';
import moment from 'moment';
import { handleConversionDetailData } from '../Production/ProductionTransfer/constants';

export class AddJobOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      jobOrder: this.handleJobOrderPropsUpdate(props.jobOrder),
      error: this.handleErrorPropsUpdate(props.jobOrder),
      branch: [],
      buttonDisabled: false,
      productObjList: [],
      vendors: [],
      conversion: props.jobOrder
        ? (
            props.jobOrder.orderFor as any as ConversionNodeConnection
          )?.edges.map((con) => con.node)
        : [],
      quantityNeededList: [],
    };
  }

  jobOrderService = new JobOrderService();
  branchService = new BranchService();
  vendorService = new VendorService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      jobOrder: this.handleJobOrderPropsUpdate(nextProps.jobOrder),
      error: this.handleErrorPropsUpdate(nextProps.jobOrder),
      conversion: nextProps.jobOrder
        ? (
            nextProps.jobOrder.orderFor as any as ConversionNodeConnection
          )?.edges.map((con) => con.node)
        : [],
    });
  }

  handleJobOrderPropsUpdate = (jobOrderNode?: JobOrderNode | null) => {
    if (jobOrderNode) {
      (
        jobOrderNode.orderFor as unknown as ConversionDetailNodeConnection
      ).edges.map((obj) => delete obj.node?.quantityReceived);
    }
    const jobOrder = jobOrderNode
      ? {
          ...jobOrderNode,
          continuation:
            (jobOrderNode.transfers as any as TransferNodeConnection)?.edges[0]
              .node?.id === jobOrderNode.firstTransferId
              ? false
              : true,
          oldJobOrderId:
            (jobOrderNode.transfers as any as TransferNodeConnection)?.edges[0]
              .node?.id === jobOrderNode.firstTransferId
              ? null
              : jobOrderNode.id,
          orderFor: handleConversionDetailData(
            jobOrderNode.orderFor as any as ConversionDetailNodeConnection,
          ),
          transferData: this.createTransferData(jobOrderNode),
        }
      : JSON.parse(JSON.stringify({ ...jobOrderObject }));
    delete jobOrder.firstTransferId;
    delete jobOrder.transfers;
    delete jobOrder.transferData.fromLocation;
    delete jobOrder.transferData.toLocation;
    delete jobOrder.transferData.hsnGstWiseAmount;
    delete jobOrder.transferData.vendorFor;
    return jobOrder;
  };

  createTransferData = (jobOrder: JobOrderNode) => {
    return {
      ...(jobOrder.transfers as any as TransferNodeConnection)?.edges[0].node,
      fromLocationId: (jobOrder.transfers as any as TransferNodeConnection)
        ?.edges[0].node?.fromLocation?.id,
      toLocationId: (jobOrder.transfers as any as TransferNodeConnection)
        ?.edges[0].node?.toLocation?.id,
      vendorForId: (jobOrder.transfers as any as TransferNodeConnection)
        ?.edges[0].node?.vendorFor?.id,
      productDetails: handleProductDetailsData(
        (jobOrder.transfers as any as TransferNodeConnection)?.edges[0].node
          ?.productDetails as any as ProductDetailsNodeConnection,
      ),
    };
  };

  handleErrorPropsUpdate = (jobOrder?: JobOrderNode | null) => {
    const error: JobOrderError = JSON.parse(JSON.stringify(jobOrderError));
    const orderFor =
      jobOrder && (jobOrder.orderFor as any as ConversionDetailNodeConnection);
    const productDetails =
      jobOrder &&
      (jobOrder.transfers as any as TransferNodeConnection)?.edges[0].node
        ?.productDetails;
    if (orderFor) {
      orderFor?.edges.forEach(() => {
        if (orderFor.edges.length > (error.orderFor?.length || 0)) {
          error.orderFor?.push({
            conversionId: '',
            quantity: '',
            cost: '',
            amount: '',
          });
        }
      });
    }
    if (productDetails) {
      (productDetails as any as ProductDetailsNodeConnection)?.edges.forEach(
        () => {
          if (
            error.transferData &&
            (productDetails as any as ProductDetailsNodeConnection)?.edges
              .length > error.transferData.productDetails.length
          ) {
            error.transferData?.productDetails.push({
              productId: '',
              purchaseRate: '',
              quantity: '',
              amount: '',
            });
          }
        },
      );
    }
    return error;
  };

  componentDidMount() {
    this.fetchBranch();
    this.fetchVendors();
  }

  fetchVendors = () => {
    this.vendorService.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  };

  fetchBranch = () => {
    this.branchService.branch$.subscribe((branch) => this.setState({ branch }));
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const jobOrder = { ...this.state.jobOrder };
    jobOrder[e.target.name] = e.target.value;
    this.setState({ jobOrder });
  };

  onChangeTransfer = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const jobOrder = { ...this.state.jobOrder };
    jobOrder.transferData[e.target.name] = e.target.value;
    this.setState({ jobOrder });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.jobOrder.transferData.productDetails) {
      nextState.jobOrder.transferData.productDetails[index][e.target.name] =
        e.target.value;
      nextState.jobOrder.transferData.productDetails[index].amount = Number(
        (
          Number(
            nextState.jobOrder.transferData.productDetails[index].quantity,
          ) *
          Number(
            nextState.jobOrder.transferData.productDetails[index].purchaseRate,
          )
        ).toFixed(2),
      );
      this.props.company.name.includes('Seva Sadan') &&
        (nextState.jobOrder.transferData.productDetails[index].status =
          'ACCEPTED');
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.jobOrder.transferData.productDetails?.push({
        date: null,
        productId: '',
        quantity: 0,
        purchaseRate: 0,
        rate: 0,
        amount: 0,
        gstRate: 0,
        reed: '',
        pick: '',
        gsm: '',
        movementType: 'DOWN',
        status: 'ACCEPTED',
        companyId: null,
      });
    } else {
      nextState.jobOrder.transferData.productDetails?.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    if (nextState.jobOrder.transferData.productDetails) {
      nextState.jobOrder.transferData.productDetails[index].rate =
        productObj.sellingRate;
      nextState.jobOrder.transferData.productDetails[index].purchaseRate =
        productObj.purchaseRate;
      nextState.jobOrder.transferData.productDetails[index].gstRate =
        productObj.hsn
          ? productObj.hsn.hsnWithSameCode.length > 1
            ? productObj.hsn.hsnWithSameCode.find(
                (hsn) =>
                  hsn.maxValue !== undefined &&
                  hsn.maxValue >= productObj.purchaseRate &&
                  hsn.minValue !== undefined &&
                  hsn.minValue <= productObj.purchaseRate,
              )?.gst
            : productObj.hsn.hsnWithSameCode[0].gst
          : 0;
    }
    this.setState(nextState);
  };

  jobOrderObjChangeHandler = (jobOrderObj: JobOrderNode) => {
    const nextState: State = JSON.parse(JSON.stringify(this.state));
    nextState.conversion = (
      jobOrderObj.orderFor as any as ConversionDetailNodeConnection
    )?.edges.map((cde) => cde.node?.conversion);
    const jobOrder: JobOrderInput = this.handleJobOrderPropsUpdate(jobOrderObj);
    nextState.jobOrder = {
      ...nextState.jobOrder,
      ...jobOrder,
      oldJobOrderId: jobOrderObj.id,
      transferData: {
        ...jobOrder.transferData,
        id: '',
        date: moment().format('YYYY-MM-DD'),
        status: 'ACCEPTED',
        isTransfer: true,
        productDetails: jobOrder.transferData.productDetails?.map((pd) => ({
          ...pd,
          id: '',
          quantity: null,
          amount: 0,
          movementType: 'DOWN',
          status: 'ACCEPTED',
        })),
      },
    };
    nextState.error = {
      ...nextState.error,
      ...this.handleErrorPropsUpdate(jobOrderObj),
    };
    this.setState(nextState);
  };

  conversionDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.jobOrder.orderFor[index][e.target.name] = e.target.value;
    nextState.jobOrder.orderFor[index].amount = Number(
      (
        Number(nextState.jobOrder.orderFor[index].quantity) *
        Number(nextState.jobOrder.orderFor[index].cost)
      ).toFixed(2),
    );
    this.setState(nextState, () => this.updateQuantityNeeded(index));
  };

  checkError = () => {
    const nextState = { ...this.state };
    let isError = false;
    if (
      nextState.error.transferData &&
      (!nextState.error.transferData?.toLocationId ||
        !nextState.error.transferData?.vendorForId)
    ) {
      nextState.error.transferData.toLocationId =
        nextState.error.transferData.vendorForId = `This is a required field`;
      isError = true;
    }
    this.setState(nextState);
    return isError;
  };

  checkConversionDetailError = () => {
    const conversionDetails: ConversionDetailInput[] = {
      ...this.state.jobOrder.orderFor,
    };
    let isError = false;
    const error = { ...this.state.error };
    error.orderFor?.map((econ, index) => {
      Object.keys(econ).forEach((conkey) => {
        if (!conversionDetails[index][conkey]) {
          isError = true;
          econ[conkey] = `This is a required field`;
        } else {
          econ[conkey] = '';
        }
      });
      return econ;
    });
    this.setState({ error });
    return isError;
  };

  conversionObjChangeHandler = (
    conversionObj: ConversionNode | undefined,
    index: number,
    remove: boolean,
  ) => {
    const nextState = { ...this.state };
    if (!remove) {
      nextState.conversion[index] = conversionObj;
      nextState.jobOrder.orderFor[index].cost = conversionObj?.netCost;
    } else {
      nextState.conversion.splice(index, 1);
    }
    this.createTransferDataFromConversion(nextState);
    this.setState(nextState);
  };

  updateQuantityNeeded = (index: number) => {
    const nextState: State = { ...this.state };
    (
      nextState.conversion[index]
        ?.rawMaterials as any as ProductDetailsNodeConnection
    )?.edges.forEach((rme) => {
      const quantityNeededObj = nextState.quantityNeededList.find((qn) => {
        return (
          qn.conversionId === nextState.conversion[index]?.id &&
          qn.productId === rme.node.product?.id
        );
      });
      if (quantityNeededObj) {
        quantityNeededObj.quantity =
          (rme.node.quantity || 0) *
          Number(nextState.jobOrder.orderFor[index].quantity);
      } else {
        nextState.quantityNeededList.push({
          conversionId: nextState.conversion[index]?.id || '',
          productId: rme.node.product?.id || '',
          quantity:
            (rme.node.quantity || 0) *
            Number(nextState.jobOrder.orderFor[index].quantity),
        });
      }
    });
    this.setState(nextState);
  };

  createTransferDataFromConversion = (nextState: State) => {
    nextState.jobOrder.transferData.productDetails = [];
    nextState.conversion.forEach((conversionObj) => {
      nextState.jobOrder.transferData.remarks += `${conversionObj?.designCode}, `;
      const conversionProductDetails = (
        conversionObj?.rawMaterials as any as ProductDetailsNodeConnection
      )?.edges;
      conversionProductDetails.forEach((rme) => {
        const existingProductDetail =
          nextState.jobOrder.transferData.productDetails?.find(
            (pd) => pd.productId === rme.node?.product?.id,
          );
        if (existingProductDetail && existingProductDetail.quantity) {
          existingProductDetail.quantity += Number(rme.node?.quantity);
          existingProductDetail.amount = Number(
            (
              existingProductDetail.quantity * Number(rme.node?.purchaseRate)
            ).toFixed(2),
          );
        } else {
          nextState.jobOrder.transferData.productDetails?.push({
            ...rme.node,
            id: '',
            productId: rme.node?.product?.id,
            quantity: 0,
            amount: 0,
            movementType: 'DOWN',
            status: 'ACCEPTED',
          } as ProductDetailsInput);
        }
      });
      nextState.jobOrder.transferData.productDetails?.forEach(() => {
        if (
          nextState.error.transferData &&
          nextState.error.transferData.productDetails.length <
            (nextState.jobOrder.transferData.productDetails?.length || 0)
        ) {
          nextState.error.transferData.productDetails.push({
            productId: '',
            purchaseRate: '',
            quantity: '',
            amount: '',
          });
        }
      });
    });
    this.setState(nextState);
  };

  updateConsversionsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.jobOrder.orderFor.push({
        date: moment().format('YYYY-MM-DD'),
        conversionId: null,
        quantity: null,
        cost: null,
        amount: null,
        conversionType: 'JOB_ORDER',
        companyId: null,
      });
      nextState.error.orderFor?.push({
        conversionId: '',
        cost: '',
        quantity: '',
        amount: '',
      });
    } else {
      nextState.jobOrder.orderFor.splice(index, 1);
      nextState.error.orderFor?.splice(index, 1);
      this.conversionObjChangeHandler(nextState.conversion[index], index, true);
    }
    this.setState(nextState);
  };

  saveTransfer = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkConversionDetailError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.jobOrderService.addJobOrder(
      {
        ...this.state.jobOrder,
        transferData: {
          ...this.state.jobOrder.transferData,
          fromHeadOffice: this.props.business.type === 'company' ? true : false,
          fromLocationId:
            this.props.business.type === 'company'
              ? null
              : this.props.business.id,
          toLocationId: this.state.jobOrder.transferData.toVendor
            ? null
            : this.state.jobOrder.transferData.toLocationId,
          vendorForId: this.state.jobOrder.transferData.toVendor
            ? this.state.jobOrder.transferData.vendorForId
            : null,
        },
      },
      (response) => {
        this.setState({
          jobOrder: JSON.parse(JSON.stringify(jobOrderObject)),
          error: JSON.parse(JSON.stringify(jobOrderError)),
          buttonDisabled: false,
        });
        notification.success({
          message: 'Job order ' + this.state.jobOrder.id ? 'Updated' : 'Added',
          description: `Job order was successfully ${
            this.state.jobOrder.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Job order ' + this.state.jobOrder.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      jobOrder: JSON.parse(JSON.stringify({ ...jobOrderObject })),
    });
  };

  render() {
    return (
      <div>
        <JobOrderForm
          values={this.state.jobOrder}
          error={this.state.error}
          onChange={this.onChange}
          onChangeTransfer={this.onChangeTransfer}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          branch={this.state.branch}
          conversionObjChangeHandler={this.conversionObjChangeHandler}
          isEditActive={this.props.isEditActive}
          company={this.props.company}
          business={this.props.business}
          vendors={this.state.vendors}
          // conversion={this.state.conversion}
          jobOrderObjChangeHandler={this.jobOrderObjChangeHandler}
          activeKey={this.props.activeKey}
          conversionDetailChangeHandler={this.conversionDetailChangeHandler}
          updateconversionDetailsRow={this.updateConsversionsRow}
          quantityNeededList={this.state.quantityNeededList}
          // updateQuantityNeeded={this.updateQuantityNeeded}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveTransfer}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  jobOrder?: JobOrderNode | null;
  company: CompanyNodeEdge;
  business: Bussiness;
  isEditActive: boolean;
  handleTabChange: (key) => void;
  activeKey: string;
}

interface State {
  jobOrder: JobOrderInput;
  error: JobOrderError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  vendors: VendorEdges[];
  conversion: (ConversionNode | undefined)[];
  quantityNeededList: {
    conversionId: string;
    productId: string;
    quantity: number;
  }[];
}

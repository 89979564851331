import { CompanyNodeEdge } from '../master/Company/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import moment from 'moment';
import { VendorNode } from '../master/vendor/constants';

export interface PurchaseNodeEdgeQuery {
  id?: string;
  date: string;
  productDetails?: ProductDetailsNodeConnection;
  billNo: string;
  vendor: VendorNode;
  vendorInvoiceNo: string;
  vendorInvoiceDate: string;
  purchaseOrderNo: string;
  purchaseOrderDate: string;
  receivingChallanNo: string;
  packingCharges?: number;
  transportationCharges?: number;
  otherCharges?: number;
  netAmount?: number;
  isReturn: boolean;
  purchaseToReturn: PurchaseNodeEdgeQuery;
  remarks?: string;
  company?: CompanyNodeEdge;
}

export interface PurchaseNodeEdgeMutation {
  id?: string;
  date: string;
  productDetails?: ProductDetailNodeEdge[];
  billNo: string;
  vendor?: VendorNode;
  vendorId?: string | null;
  vendorInvoiceNo?: string | null;
  vendorInvoiceDate?: string | null;
  purchaseOrderNo?: string | null;
  purchaseOrderDate?: string | null;
  receivingChallanNo?: string | null;
  packingCharges?: number | null;
  transportationCharges?: number | null;
  otherCharges?: number | null;
  netAmount?: number | null;
  isReturn: boolean;
  purchaseToReturn?: PurchaseNodeEdgeQuery;
  purchaseToReturnId: string | null;
  remarks?: string | null;
  companyId?: string | null;
}

export const purchaseObject: PurchaseNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  productDetails: [
    {
      date: null,
      productId: '',
      rate: null,
      purchaseRate: null,
      gstRate: null,
      quantity: null,
      discount: null,
      amount: null,
      movementForId: '',
      movementType: 'PURCHASE',
      companyId: '',
    },
  ],
  vendorId: null,
  vendorInvoiceNo: null,
  vendorInvoiceDate: null,
  purchaseOrderNo: null,
  purchaseOrderDate: null,
  receivingChallanNo: null,
  billNo: '',
  packingCharges: null,
  transportationCharges: null,
  otherCharges: null,
  netAmount: null,
  isReturn: false,
  purchaseToReturnId: null,
  remarks: null,
  companyId: null,
};

export const purchaseQuery = `
query PurchaseList($companyId: ID!, $date_Gte: Date, $date_Lte: Date, $isReturn: Boolean, $after: String) {
  allPurchases(companyId: $companyId, date_Gte: $date_Gte, date_Lte: $date_Lte, isReturn: $isReturn, first: 20, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        billNo
        productDetails {
          edges {
            cursor
            node {
              id
              product {
                id
                name
                oldBarcodeId
              }
              rate
              purchaseRate
              quantity
              discount
              gstRate
              amount
              movementType
              movementFor {
                id
                name
              }
            }
          }
        }
        vendor {
          id
          name
          address
          gstin
          contactPersonName
          contactNo
        }
        vendorInvoiceNo
        vendorInvoiceDate
        purchaseOrderNo
        purchaseOrderDate
        receivingChallanNo
        packingCharges
        transportationCharges
        otherCharges
        netAmount
        isReturn
        purchaseToReturn {
          id
          billNo
        }
        remarks
      }
    }
  }
}
`;

export const vendorWisePurchaseQuery = `
query VendorWisePurchaseList($companyId: ID, $vendorId: ID, $startDate: Date, $endDate: Date, $after: String) {
  allPurchases(companyId: $companyId, vendorId: $vendorId, date_Gte: $startDate, date_Lte: $endDate, first: 20, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        billNo
        vendorInvoiceNo
        vendorInvoiceDate
        purchaseOrderNo
        purchaseOrderDate
        purchaseOrderQty
        margin {
          challan {
            purchaseOrder {
              date
              purchaseOrderNo
            }
          }
        }
      }
    }
  }
}
`;

export interface PurchaseQueryVariable {
  companyId: string;
  date_Lte?: string;
}

export interface PurchaseQueryResponse {
  data: {
    allPurchases: {
      edges: PurchaseEdges[];
    };
  };
}

export interface PurchaseEdges {
  cursor: string;
  node: PurchaseNodeEdgeQuery;
}

export interface AddPurchaseVariable {
  input: {
    purchase: PurchaseNodeEdgeMutation;
  };
}

export const purchaseMutation = `
mutation addPurchase(
  $input: CreatePurchaseInput!
) {
  createPurchase(input: $input){
    newPurchase {
      id
      date
      productDetails {
        edges {
          node {
            id
            product {
              id
              name
            }
          }
        }
      }
    }
  }
}
`;

import * as React from 'react';
import { TSInput } from '../common/TSInput';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { DatePicker, Divider, Row, Col, Button, Checkbox, Icon } from 'antd';
import moment from 'moment';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import {
  ValueaditionNodeEdgeMutation,
  ValueaditionEdges,
  ValueaditionError,
} from './constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ProductNode, CustomerNode } from '../../schema';
import { CustomerDropDown } from '../master/Customer/CustomerDropdown';
import { VendorEdges } from '../master/vendor/constants';
import { UserNodeEdge } from '../../components/user/constants';

export const ValueaditionForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={4}>
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(e) => {
              props.isEditActive
                ? () => {}
                : props.onChange({
                    target: {
                      name: 'date',
                      value: e && moment(e).format('YYYY-MM-DD'),
                    },
                  });
            }}
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
          <span style={{ color: 'red' }}>{props.error.date}</span>
        </Col>
        {props.selectedoption === 'CUSTOMER' ? (
          <div>
            <Col span={17}>
              {props.company && (
                <CustomerDropDown
                  onSelect={(e, f) => {
                    props.isEditActive
                      ? () => {}
                      : props.customerObjChangehandler((f as any).props.label);
                    props.isEditActive
                      ? () => {}
                      : props.onChange({
                          target: { name: 'customerId', value: String(e) },
                        });
                  }}
                  extrafields={[
                    'pointBalance',
                    'address',
                    'email',
                    'dateOfBirth',
                    'dateOfAnniversary',
                  ]}
                  allowClear
                  company={props.company}
                  value={props.values.customerId || ''}
                  updateCount={props.updateCount}
                />
              )}
            </Col>
            <Col span={1}>
              <Button
                children={<Icon type="plus" />}
                type="primary"
                onClick={() => props.handleModalAction('ADD_CUSTOMER')}
                style={{ marginTop: '5px' }}
              />
            </Col>
            <Col span={1}>
              <Button
                children={<Icon type="edit" />}
                type="primary"
                onClick={() => props.handleModalAction('LIST_CUSTOMER')}
                style={{ marginTop: '5px' }}
              />
            </Col>
          </div>
        ) : (
          <Col span={19}>
            <TSAutoComplete
              error={props.error.vendorId}
              inputProps={{ name: 'vendorId', addonBefore: 'Vendor' }}
              dataSource={props.vendors}
              onValueSelect={props.onChange}
              textValue={getValue(props.vendors, props.values.vendorId)}
              key="id"
              name="vendorId"
              displayKey="name"
              allowClear
            />
          </Col>
        )}
        <Col span={4}>
          <Checkbox
            checked={props.values.isReturn}
            onChange={(e) => {
              props.isEditActive
                ? () => {}
                : props.onChange({
                    target: { name: 'isReturn', value: e.target.checked },
                  });
            }}
            style={{ marginTop: '10px' }}
          >
            Is Return?
          </Checkbox>
        </Col>
        {props.values.isReturn && (
          <Col span={24}>
            <TSAutoComplete
              error={props.error.oldValueaditionId}
              inputProps={{
                name: 'oldValueaditionId',
                addonBefore: 'Old Valueadition No.',
              }}
              dataSource={props.valueaditionList}
              key="id"
              name="oldValueaditionId"
              onValueSelect={props.onChange}
              displayKey="valueaditionNo"
              textValue={getValue(
                props.valueaditionList,
                props.values.oldValueaditionId,
                'valueaditionNo',
              )}
              allowClear
            />
          </Col>
        )}
        <Divider />
        <h4>Products</h4>
        <Row>
          <Col span={1}>Sl. No.</Col>
          <Col span={7}>Product</Col>
          <Col span={3}>MRP</Col>
          <Col span={4}>Value Addition Cost</Col>
          <Col span={3}>Quantity</Col>
          <Col span={3}>Amount</Col>
          <Col span={2}>Remove</Col>
          <Col span={1}>Add</Col>
          <Divider />
        </Row>
        {props.values.productDetails?.map((product, index) => (
          <div key={'products' + index}>
            <Row>
              <Col span={1}>{index + 1}.</Col>
              <Col span={7}>
                <ProductDropDown
                  onSelect={(e, f) => {
                    props.values.oldValueaditionId || props.isEditActive
                      ? () => ''
                      : props.productChangeHandler(
                          { target: { name: 'productId', value: String(e) } },
                          index,
                        );
                    props.values.oldValueaditionId || props.isEditActive
                      ? () => ''
                      : props.productObjChangehandler(
                          (f as any).props.label,
                          index,
                        );
                  }}
                  allowClear
                  extrafields={['sellingRate', 'purchaseRate']}
                  company={props.company}
                  value={product.productId || ''}
                />
                <span style={{ color: 'red' }}>
                  {props.error.productDetails[index].productId}
                </span>
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].rate || ''}
                  type="number"
                  value={product.rate || ''}
                  name="rate"
                  placeholder="MRP"
                  onChange={(e) =>
                    props.values.oldValueaditionId || props.isEditActive
                      ? () => ''
                      : props.productChangeHandler(e, index)
                  }
                  required
                />
              </Col>
              <Col span={4}>
                <TSInput
                  error=""
                  type="number"
                  value={product.discount || ''}
                  name="discount"
                  placeholder="Value Addition cost"
                  required
                  onChange={(e) =>
                    props.values.oldValueaditionId || props.isEditActive
                      ? props.productChangeHandler(e, index)
                      : props.productChangeHandler(e, index)
                  }
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].quantity || ''}
                  type="number"
                  value={product.quantity || ''}
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(e) =>
                    props.values.oldValueaditionId || props.isEditActive
                      ? props.productChangeHandler(e, index)
                      : props.productChangeHandler(e, index)
                  }
                  required
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error={props.error.productDetails[index].amount || ''}
                  type="number"
                  value={product.amount || ''}
                  name="amount"
                  placeholder="Amount"
                  required
                />
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="minus"
                  style={{ marginLeft: '5px' }}
                  onClick={() => props.updateProductsRow('-', index)}
                  disabled={
                    props.values.productDetails?.length === 1 ||
                    !!props.values.oldValueaditionId ||
                    props.isEditActive
                  }
                />
              </Col>
              <Col span={1}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  style={{ marginLeft: '5px' }}
                  onClick={() => props.updateProductsRow('+', index)}
                  disabled={
                    (product.productId &&
                    product.quantity &&
                    product.rate &&
                    product.amount
                      ? false
                      : true) ||
                    !!props.values.oldValueaditionId ||
                    props.isEditActive
                  }
                />
              </Col>
            </Row>
          </div>
        ))}
        <Col span={12}>
          <TSInput
            error=""
            value={props.values.totalMrp || ''}
            name="totalMrp"
            type="number"
            addonBefore="Total MRP"
          />
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col>Remarks :</Col>
        <Col span={24}>
          <TSInput
            error={props.error.remark}
            value={props.values.remark || ''}
            name="remark"
            type="text"
            onChange={(e) => {
              props.isEditActive
                ? () => {}
                : props.onChange({
                    target: { name: 'remark', value: String(e.target.value) },
                  });
            }}
          />
        </Col>
        <Col span={12}></Col>
        <Col span={12}>
          <TSInput
            error={props.error.approvedByName}
            value={props.values.approvedByName || ''}
            name="approvedByName"
            type="text"
            placeholder=""
            onChange={(e) => {
              props.isEditActive
                ? () => {}
                : props.onChange({
                    target: {
                      name: 'approvedByName',
                      value: String(e.target.value),
                    },
                  });
            }}
            addonBefore="Authority Name"
          />
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  values: ValueaditionNodeEdgeMutation;
  error: ValueaditionError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge | null;
  valueaditionList: ValueaditionEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangehandler: (value: ProductNode, index: number) => void;
  isEditActive: boolean;
  customerObjChangehandler: (value: CustomerNode) => void;
  handleModalAction: (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => void;
  updateCount: number;
  vendors: VendorEdges[];
  selectedoption: 'CUSTOMER' | 'VENDOR';
  user?: UserNodeEdge | null;
  bussiness: Bussiness | null;
}
interface state {}

import * as React from 'react';
import { DistrictInput } from '../../../schema';
import { TSInput } from '../../common/TSInput';
import { Row, Col } from 'antd';
import { Label } from '../../common/TSLabel';
import { StateDropdown } from '../State/StateDropdown';

export const DistrictForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <TSInput
            error=""
            value={props.district.name}
            type="text"
            name="name"
            onChange={props.onChange}
            addonBefore="Name"
          />
        </Col>
        <Col span={12}>
          <Label title="State">
            <StateDropdown
              onSelect={(e, f) => {
                props.onChange({
                  target: { name: 'stateId', value: String(e) },
                });
              }}
              value={props.district.stateId}
              allowClear
            />
          </Label>
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  district: DistrictInput;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
}

import * as React from 'react';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { Row, Col, DatePicker, Divider, Spin, Modal, Button, Icon } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { interLocationTransferQuery, TransferNode } from './constants';
import { TransferNodeConnection } from '../../schema';
import { Table } from '../common/Table';
import { Pagination } from 'sha-el-design/lib';
import { AddTransfer } from './AddTransfer';
import { INTER_LOCATION_TRANSFER_REPORT } from '../../config';
import { downloadToFile } from '../helper';

export class InterLocationTransferReport extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
      openDetails: false,
      selectedTransfer: null,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (value) => value && moment(value).format('DD-MM-YYYY'),
      sortingValue: (value) => value,
    },
    {
      title: 'Invoice No',
      key: 'transferInvoiceNo',
      dataIndex: 'node.transferInvoiceNo',
      sortingValue: (value) => value,
    },
    {
      title: 'From',
      key: 'fromLocation.name',
      dataIndex: 'node.fromLocation.name',
      sortingValue: (value) => value,
    },
    {
      title: 'To',
      key: 'toLocation.name',
      dataIndex: 'node.toLocation.name',
      sortingValue: (value) => value,
    },
    {
      title: 'Details',
      key: 'details',
      render: (value) => {
        return (
          <Button
            onClick={() =>
              this.setState({
                selectedTransfer: value?.node,
                openDetails: true,
              })
            }
            children={<Icon type="eye" />}
          />
        );
      },
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(INTER_LOCATION_TRANSFER_REPORT + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `inter-location-transfer-report-${new Date().toISOString()}`,
          'INTER LOCATION TRANSFER REPORT',
          this.props.company.name,
          '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { startDate, endDate, currentPage, after, selectedTransfer } =
      this.state;
    const {
      company: { id: companyId },
      activeKey,
      transferTo,
    } = this.props;
    const variables = { startDate, endDate, companyId, after, activeKey };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(this.state.startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(this.state.endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Divider />
        <GraphqlQuery
          queryString={interLocationTransferQuery}
          variables={variables}
          render={(
            response: { allTransfers: TransferNodeConnection },
            error,
            loading,
          ) => {
            if (loading) return <Spin />;
            if (error) return <h3>Something Went Wrong!!</h3>;
            if (response) {
              return [
                <Table
                  dataSource={response.allTransfers.edges}
                  columns={this.columns()}
                  key="Table"
                  pagination={false}
                  rowKey="node.id"
                />,
                <Pagination
                  key="Pagination"
                  totalCount={0}
                  cursorBasedPagination
                  currentPage={currentPage}
                  batchSize={20}
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allTransfers.pageInfo.endCursor,
                      response.allTransfers.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv(variables)}
                  key="button"
                />,
              ];
            }
          }}
        />
        <Modal
          title="View Transfer Details"
          visible={this.state.openDetails}
          footer={<div />}
          onCancel={() => this.setState({ openDetails: false })}
        >
          <AddTransfer
            transfer={selectedTransfer}
            company={this.props.company}
            business={
              {
                ...(selectedTransfer && selectedTransfer.fromLocation),
                type: 'branch',
              } as any
            }
            isEditActive
            isViewActive
            transferTo={transferTo}
          />
        </Modal>
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
  business: Bussiness;
  transferTo: 'to_department' | 'to_branch';
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
  openDetails: boolean;
  selectedTransfer: TransferNode | null;
}

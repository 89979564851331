import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { HsnNodeConnection, HsnNodeEdge } from '../../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  hsn?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface HsnDropDownProps extends AutoCompleteProps {
  extrafields: string[];
}

export class HsnDropDown extends React.Component<HsnDropDownProps, State> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      hsnCode: '',
      company: null,
      touched: false,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  getHsnListWithRange = (hsn?: HsnNodeEdge) => {
    if (!hsn) return;

    return `${hsn.node?.hsnCode} (${hsn.node?.minValue}-${hsn.node?.maxValue})`;
  };

  value = (hsns: HsnNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.hsnCode;
    }

    return this.getHsnListWithRange(
      hsns.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { hsnCode, company } = this.state;
    const { extrafields, value, onSelect } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query HsnDropDown($after: String!, $id: ID, $hsnCode: String, $companyId: ID) {
          allHsns(first: 10, after: $after, id: $id, hsnCode_Icontains: $hsnCode, companyId: $companyId) {
            edges {
              node {
                id
                hsnCode
                minValue
                maxValue
                ${extrafields.join('\n')}
              }
            }
          }
        }`}
        variables={{
          after: '',
          hsnCode,
          companyId: company.id,
          id: value && !hsnCode ? value : '',
        }}
        render={(response: { allHsns: HsnNodeConnection }, error, loading) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0', width: '100%' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Hsn"
                dataSource={response.allHsns.edges.map((hsn) => (
                  <Option key={hsn.node?.id} label={hsn.node} hsn={hsn}>
                    {this.getHsnListWithRange(hsn)}
                  </Option>
                ))}
                value={this.value(response.allHsns.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(hsn) =>
                  this.setState({ hsnCode: hsn, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    hsnCode:
                      this.getHsnListWithRange((f as any).props.hsn) || '',
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  company: CompanyNodeEdge | null;
  hsnCode: string;
  touched: boolean;
}

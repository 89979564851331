import React from 'react';
import moment from 'moment';
import { Icon, Skeleton, Table, Tabs } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design/lib';
import { CompanyService } from '../../service/CompanyService';
import { UserService } from '../../service/UserService';
import { checkAddPermission } from '../helper';
import { UserNodeEdge } from '../user/constants';
import { memoQuery, MemoNodeConnection, MemoNodeEdge } from './constants';
import { AddMemo } from './AddMemo';
import { PDFViewer } from '@react-pdf/renderer';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { MemoReceipt } from './MemoReceipt';

export class Memo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedMemo: null,
      after: '',
      currentPage: 1,
      user: null,
      companyId: null,
      company: null,
      business: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyId: company.id }),
    );
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedMemo: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productSubCategoryColumns = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
      sortingValue: (text) => text,
    },
    {
      title: 'Book Number',
      dataIndex: 'node.bookNumber',
      key: 'bookNumber',
      sortingValue: (text) => text,
    },
    {
      title: 'Serial Start',
      dataIndex: 'node.serialStart',
      key: 'serialStart',
      sortingValue: (text) => text,
    },
    {
      title: 'Serial End',
      dataIndex: 'node.serialEnd',
      key: 'serialEnd',
      sortingValue: (text) => text,
    },
    {
      title: 'Financial Year',
      dataIndex: 'node.financialYear',
      key: 'financialYear',
      sortingValue: (text) => text,
    },
    {
      title: 'Memo Type',
      dataIndex: 'node.memoType',
      key: 'memoType',
      sortingValue: (text) => text,
    },
    {
      title: 'Location',
      dataIndex: 'node.location.name',
      key: 'location',
      sortingValue: (text) => text,
    },
    {
      title: 'Receipt',
      dataIndex: 'node',
      key: 'receipt',
      render: (text, obj: MemoNodeEdge) => (
        <a
          onClick={() => {
            obj.node.locationId = obj.node.location?.id;
            this.setState({ selectedMemo: obj, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Edit',
      dataIndex: 'node',
      key: 'edit',
      render: (text, obj: MemoNodeEdge) => (
        <a
          onClick={() => {
            obj.node.locationId = obj.node.location?.id;
            this.setState({ selectedMemo: obj, activeKey: '2' });
          }}
        >
          <Icon type="edit" />
        </a>
      ),
      sortingValue: (text) => text,
    },
  ];

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey, companyId } = this.state;
    const variables = { after, companyId, activeKey };
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Memo
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={memoQuery}
            variables={variables}
            render={(
              response: {
                allMemo: MemoNodeConnection;
              },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allMemo?.edges}
                    columns={this.productSubCategoryColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allMemo.pageInfo.endCursor,
                        response.allMemo.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Memo
            </span>
          }
          key="2"
        >
          <AddMemo
            memo={this.state.selectedMemo?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
        {this.state.selectedMemo && this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            {this.state.company && this.state.business && (
              <PDFViewer style={{ width: '100%', height: '1000px' }}>
                <MemoReceipt
                  memo={this.state.selectedMemo}
                  company={this.state.company}
                  business={this.state.business}
                />
              </PDFViewer>
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedMemo: MemoNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  companyId?: string | null;
  company: CompanyNodeEdge | null;
  business: Bussiness | null;
}

interface Props {}

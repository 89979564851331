import * as React from 'react';
import { notification, Button, Popconfirm } from 'antd';
import { BranchEdges } from '../../master/Branch/constants';
import { BranchService } from '../../../service/BranchService';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import {
  AcceptProductionReceivingError,
  acceptProductionReceivingError,
} from './constants';
import moment from 'moment';
import { ProductionTransferService } from '../../../service/ProductionTransferService';
import {
  ProductionTransferNode,
  ConversionDetailNodeConnection,
  ProductionTransferInput,
  ConversionDetailInput,
} from '../../../schema';
import {
  handleConversionDetailData,
  productionTransferObj,
} from '../ProductionTransfer/constants';
import { PendingProductionReceivingForm } from './PendingProductionReceivingForm';

export class AddPendingProductionReceiving extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transfer: this.handleTransferPropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      branch: [],
      buttonDisabled: false,
    };
  }

  productionTransferService = new ProductionTransferService();
  branchService = new BranchService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      transfer: this.handleTransferPropsUpdate(nextProps),
      error: this.handleErrorPropsUpdate(nextProps),
    });
  }

  handleTransferPropsUpdate = (nextProps: Props) => {
    const transfer = nextProps.transfer
      ? {
          ...nextProps.transfer,
          fromBranchId:
            nextProps.transfer.fromBranch && nextProps.transfer.fromBranch.id,
          toBranchId:
            nextProps.transfer.toBranch && nextProps.transfer.toBranch.id,
          conversionDetails: handleConversionDetailData(
            nextProps.transfer
              .conversionDetails as any as ConversionDetailNodeConnection,
          ),
        }
      : JSON.parse(
          JSON.stringify({
            ...productionTransferObj,
            toHeadOffice: this.props.business.type === 'branch' ? true : false,
          }),
        );
    delete transfer.fromBranch;
    delete transfer.toBranch;
    delete transfer.hsnGstWiseAmount;
    return transfer;
  };

  handleErrorPropsUpdate = (nextProps: Props) => {
    const error: AcceptProductionReceivingError = JSON.parse(
      JSON.stringify(acceptProductionReceivingError),
    );
    nextProps.transfer &&
      (
        nextProps.transfer
          .conversionDetails as any as ConversionDetailNodeConnection
      ).edges.forEach(() => {
        if (
          (
            nextProps.transfer
              ?.conversionDetails as any as ConversionDetailNodeConnection
          ).edges.length !== error.conversionDetails.length
        ) {
          error.conversionDetails.push({
            conversionId: '',
            remark: '',
            status: '',
          });
        }
      });
    return error;
  };

  componentDidMount() {
    this.fetchBranch();
  }

  fetchBranch = () => {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
  };

  checkConversionDetailError = () => {
    const conversionDetails: ConversionDetailInput[] = {
      ...this.state.transfer.conversionDetails,
    };
    let isError = false;
    const error: AcceptProductionReceivingError = { ...this.state.error };
    error.conversionDetails.map((cd, index) => {
      if (conversionDetails[index].status === 'PENDING') {
        isError = true;
        cd.conversionId = `Please Accept or Reject`;
      } else {
        isError = isError;
        cd.conversionId = '';
      }
      if (
        conversionDetails[index].status === 'REJECTED' &&
        !conversionDetails[index].remark
      ) {
        isError = true;
        cd.remark = `This is a required field`;
      } else {
        isError = isError;
        cd.remark = '';
      }
      return cd;
    });
    this.setState({ error });
    return isError;
  };

  conversionChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.transfer.conversionDetails[index][e.target.name] = e.target.value;
    if (e.target.name === 'status' && e.target.value === 'REJECTED') {
      nextState.transfer.conversionDetails.push(
        nextState.transfer.conversionDetails.splice(index, 1)[0],
      );
    } else if (e.target.name === 'status' && e.target.value === 'ACCEPTED') {
      nextState.transfer.conversionDetails.unshift(
        nextState.transfer.conversionDetails.splice(index, 1)[0],
      );
    }
    this.setState(nextState);
  };

  saveReceiving = () => {
    if (this.checkConversionDetailError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const transfer: ProductionTransferInput = {
      ...this.state.transfer,
      receivingDate: moment().format('YYYY-MM-DD'),
    };
    if (
      transfer.conversionDetails[0].status !==
      transfer.conversionDetails[transfer.conversionDetails.length - 1].status
    )
      transfer.status = 'PARTIALLY_ACCEPTED';
    else transfer.status = transfer.conversionDetails[0].status;
    this.productionTransferService?.addProductionTransfer(
      transfer,
      (response) => {
        this.setState({
          transfer: JSON.parse(JSON.stringify(productionTransferObj)),
          error: JSON.parse(JSON.stringify(acceptProductionReceivingError)),
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Receiving ' + this.state.transfer.id ? 'Updated' : 'Added',
          description: `Receiving was successfully ${
            this.state.transfer.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Receiving ' + this.state.transfer.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      transfer: JSON.parse(
        JSON.stringify({
          ...productionTransferObj,
          toHeadOffice: this.props.business.type === 'branch' ? true : false,
        }),
      ),
      error: { ...acceptProductionReceivingError },
    });
  };

  render() {
    return (
      <div>
        <PendingProductionReceivingForm
          values={this.state.transfer}
          error={this.state.error}
          branch={this.state.branch}
          conversionChangeHandler={this.conversionChangeHandler}
          company={this.props.company}
          business={this.props.business}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveReceiving}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  transfer?: ProductionTransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
  handleTabChange: (key) => void;
}

interface State {
  transfer: ProductionTransferInput;
  error: AcceptProductionReceivingError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
}

import { Button, notification } from 'antd';
import React from 'react';
import { DiscountCategoryService } from '../../../service/DiscountCategorySerive';
import { DiscountCategoryNode, discountCategoryObject } from './constants';
import { DiscountCategoryForm } from './DiscountCategoryForm';

export class AddDiscountCategory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      discountCategory: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
    };
  }

  discountCategoryService = new DiscountCategoryService();

  handlePropsValueUpdate = (props: Props) => {
    const discountCategory = props.discountCategory
      ? props.discountCategory
      : JSON.parse(JSON.stringify(discountCategoryObject));

    return discountCategory;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      discountCategory: this.handlePropsValueUpdate(nextProps),
    });
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const discountCategory: DiscountCategoryNode = {
      ...this.state.discountCategory,
    };
    discountCategory[e.target.name] = e.target.value;
    this.setState({ discountCategory });
  };

  saveDiscountCategory = () => {
    this.setState({ buttonDisabled: true });
    this.discountCategoryService?.addDiscountCategory(
      this.state.discountCategory,
      () => {
        notification.success({
          message:
            'Discount Category' + this.state.discountCategory.id
              ? 'Updated'
              : 'Added',
          description: `Discount Category ${
            this.state.discountCategory.name
          } was successfully ${
            this.state.discountCategory.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Discount Category ' + this.state.discountCategory.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      discountCategory: JSON.parse(JSON.stringify(discountCategoryObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <DiscountCategoryForm
          values={this.state.discountCategory}
          onChange={this.onChange}
          companyName={this.props.companyName}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%', marginTop: '20px' }}
        />
        <Button
          type="primary"
          onClick={this.saveDiscountCategory}
          children="Submit"
          style={{ width: '50%', marginTop: '20px' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  discountCategory?: DiscountCategoryNode;
  handleTabChange?: (key) => void;
  companyName: string | null;
}

interface State {
  discountCategory: DiscountCategoryNode;
  buttonDisabled: boolean;
}

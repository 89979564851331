import * as React from 'react';
import {
  contractorErrorObject,
  contractorObject,
  ContractorError,
} from './constants';
import { Button, notification } from 'antd';
import { CompanyNodeEdge } from '../Company/constants';
import { ContractorForm } from './ContractorForms';
import { ContractorService } from '../../../service/ContractorService';
import { ContractorInput, ContractorNode } from '../../../schema';

export class AddContractor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contractor:
        props.contractor || JSON.parse(JSON.stringify(contractorObject)),
      error: { ...contractorErrorObject },
      buttonDisabled: false,
    };
  }

  contractorService = new ContractorService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      contractor:
        nextProps.contractor || JSON.parse(JSON.stringify(contractorObject)),
    });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const contractor = { ...this.state.contractor };
    contractor[e.target.name] = e.target.value;
    this.setState({ contractor });
  };

  checkError = () => {
    const values = { ...this.state.contractor };
    const error = { ...this.state.error };
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (!values[errKey]) {
        isError = true;
        error[errKey] = `This is a required field`;
      }
    });
    this.setState({ error });
    return isError;
  };

  saveContractor = () => {
    if (this.checkError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const contractor = { ...this.state.contractor };
    this.contractorService?.addContractor(
      contractor,
      (response) => {
        notification.success({
          message: 'Contractor ' + contractor.id ? 'Updated' : 'Added',
          description: `Contractor named ${contractor.name} was successfuly ${
            contractor.id ? 'Updated' : 'Added'
          }`,
        });
        this.clear();
        this.props.handleTabChange && this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message: 'Contractor ' + contractor.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      contractor: JSON.parse(JSON.stringify(contractorObject)),
      error: { ...contractorErrorObject },
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <ContractorForm
          values={this.state.contractor}
          error={this.state.error}
          onChange={this.onChange}
          company={this.props.company}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveContractor}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  contractor?: ContractorNode;
  company: CompanyNodeEdge;
  handleTabChange?: (key) => void;
}

interface State {
  contractor: ContractorInput;
  error: ContractorError;
  buttonDisabled: boolean;
}

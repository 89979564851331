import * as React from 'react';
import { User } from './constants';
import { Button, Divider } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';

export const UserPanel: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <h5>Hey {capitalize(props.user?.user?.user?.username)},</h5>
      <h5>
        Role:
        <span style={detailStyle}>
          {props.user?.user?.user?.is_superuser ? 'Admin' : 'Employee'}
        </span>
      </h5>
      <Divider style={{ border: '0.2px solid #40a9ff', margin: '10px 0' }} />
      <h5>
        Email:
        <span style={detailStyle}>{props.user?.user?.user?.email}</span>
      </h5>
      <h5>
        First Name:
        <span style={detailStyle}>
          {capitalize(props.user?.user?.user?.first_name)}
        </span>
      </h5>
      <h5>
        Last Name:
        <span style={detailStyle}>
          {capitalize(props.user?.user?.user?.last_name)}
        </span>
      </h5>
      <Divider style={{ border: '0.2px solid #40a9ff', margin: '10px 0' }} />
      <h5>
        Last Login:
        <span style={detailStyle}>
          {moment().calendar(props.user?.user?.user?.date_joined, {
            sameElse: 'LLLL',
          })}
        </span>
      </h5>
      <Button
        children="Log Out"
        icon="logout"
        type="ghost"
        style={{ width: '100%' }}
        onClick={() => {
          window.location.href = '/';
          localStorage.removeItem('token');
        }}
      />
    </div>
  );
};

const detailStyle: React.CSSProperties = {
  fontWeight: 'lighter',
  marginLeft: '5px',
};

export interface Props {
  user: User;
}

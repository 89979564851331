import { districtMutation } from './../components/master/District/constant';
import {
  DistrictInput,
  CreateDistrictPayload,
  CreateDistrictInput,
  DistrictNode,
} from '../schema';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';

export class DistrictService {
  static instance: DistrictService;

  constructor() {
    if (DistrictService.instance) {
      return DistrictService.instance;
    } else {
      DistrictService.instance = this;
    }
  }

  AddDistrict(
    data: DistrictInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as DistrictNode).state;
    Api.graphql<{ input: CreateDistrictInput }, CreateDistrictPayload>(
      API_URL,
      { input: { district: data } },
      districtMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  PurchaseOrderInput,
  CreatePurchaseOrderInput,
  CreatePurchaseOrderPayload,
  PurchaseOrderNode,
  CancelPurchaseOrderInput,
  CancelPurchaseOrderPayload,
} from '../schema';
import {
  purchaseOrderCancelMutation,
  purchaseOrderMutation,
} from '../components/PurchaseOrder/constants';

export class PurchaseOrderService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (PurchaseOrderService.instance) {
      return PurchaseOrderService.instance;
    } else {
      PurchaseOrderService.instance = this;
    }
  }

  addPurchaseOrder(
    data: PurchaseOrderInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as PurchaseOrderNode).vendor;
    Api.graphql<
      { input: CreatePurchaseOrderInput },
      CreatePurchaseOrderPayload
    >(
      API_URL,
      {
        input: {
          purchaseOrder: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      purchaseOrderMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }

  cancelPurchaseOrder(
    id: string,
    onSuccess: (reponse) => void,
    onError?: (response) => void,
  ) {
    Api.graphql<
      { input: CancelPurchaseOrderInput },
      CancelPurchaseOrderPayload
    >(
      API_URL,
      {
        input: { id },
      },
      purchaseOrderCancelMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }
}

import * as React from 'react';
import { DatePicker, Divider, Spin, Row, Col, Skeleton } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { VendorWiseTotalStock } from '../../schema';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { VendorEdges } from '../master/vendor/constants';
import { VendorService } from '../../service/VendorService';
import { vendorWiseStockQuery } from './constants';

export class VendorWiseStockReport extends React.Component<Props, State> {
  vendorService = new VendorService();

  constructor(props: Props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      vendors: [],
      vendorId: '',
    };
  }

  componentDidMount() {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Product Name',
      key: 'name',
      dataIndex: 'name',
      sortingValue: (value) => value,
    },
    {
      title: 'Barcode',
      key: 'barcode',
      dataIndex: 'productBarCode',
      sortingValue: (value) => value,
    },
    {
      title: 'Total Purchase',
      key: 'purchase',
      dataIndex: 'totalPurchase',
      sortingValue: (value) => value,
    },
    {
      title: 'Purchase Rate',
      key: 'purchase_rate',
      dataIndex: 'purchaseRate',
      sortingValue: (value) => value,
    },
    {
      title: 'Total Sale',
      key: 'sale',
      dataIndex: 'totalSale',
      sortingValue: (value) => value,
    },
    {
      title: 'MRP',
      key: 'mrp',
      dataIndex: 'mrp',
      sortingValue: (value) => value,
    },
    {
      title: 'Stock',
      key: 'stock',
      dataIndex: 'stock',
      sortingValue: (value) => value,
    },
    {
      title: 'Stock Value',
      key: 'stock_value',
      dataIndex: 'stockValue',
      sortingValue: (value) => value,
    },
    {
      title: 'Purchase Value',
      key: 'purchase_value',
      dataIndex: 'purchaseValue',
      sortingValue: (value) => value,
    },
  ];

  afterStack = [''];
  render() {
    const { date, vendors, vendorId } = this.state;
    const {
      company: { id: companyId },
    } = this.props;
    const variables = { date, companyId, vendorId };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              value={moment(date)}
              onChange={(date) =>
                this.setState({ date: moment(date).format('YYYY-MM-DD') })
              }
              placeholder="Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSAutoComplete
              error=""
              textValue={getValue(vendors, vendorId)}
              dataSource={vendors}
              onValueSelect={this.onChange}
              name="vendorId"
              key="id"
              inputProps={{ name: 'vendorId', placeholder: 'Select Vendor' }}
              displayKey="name"
              allowClear
            />
          </Col>
        </Row>
        <Divider />
        {vendorId && date && (
          <GraphqlQuery
            queryString={vendorWiseStockQuery}
            variables={variables}
            render={(
              response: { vendorWiseTotalStock: VendorWiseTotalStock[] },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.vendorWiseTotalStock}
                    columns={this.columns()}
                    rowKey={(a) => a.productBarCode || ''}
                    pagination={false}
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  date: string;
  vendors: VendorEdges[];
  vendorId: string;
}

import * as React from 'react';
import { EmployeeService } from '../../../service/EmployeeService';
import {
  EmployeeNodeEdge,
  EmployeeError,
  employeeErrorObject,
  employeeObject,
} from './constants';
import { BranchEdges } from '../../master/Branch/constants';
import { BranchService } from '../../../service/BranchService';
import { EmployeeForm } from './EmployeeForm';
import { Button, notification } from 'antd';
import { forEach } from 'lodash';

export class AddEmployee extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      employee: this.handlePropsUpdate(props),
      error: { ...employeeErrorObject },
      branches: [],
      buttonDisabled: false,
    };
  }

  branchService = new BranchService();
  employeeService = new EmployeeService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ employee: this.handlePropsUpdate(nextProps) });
  }

  handlePropsUpdate(nextProps: Props) {
    const employee: any = nextProps.employee
      ? {
          ...nextProps.employee,
          branchId: nextProps.employee.branch && nextProps.employee.branch.id,
          userId: nextProps.employee.id,
          groupId: nextProps?.employee?.group?.id,
        }
      : JSON.parse(JSON.stringify(employeeObject));
    if (nextProps.employee) {
      delete employee.id, delete employee.branch;
      delete employee.dateJoined;
      delete employee.isActive;
      delete employee.isStaff;
      delete employee.lastLogin;
      delete employee.group;
    }
    return employee;
  }

  componentDidMount() {
    this.fetchBranches();
  }

  fetchBranches = () => {
    this.branchService?.branch$.subscribe((branches) =>
      this.setState({ branches }),
    );
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const employee = { ...this.state.employee };
    employee[e.target.name] = e.target.value;
    this.setState({ employee });
  };

  checkError = () => {
    const error = { ...this.state.error };
    let isError = false;
    const requiredFields = ['username', 'password'];
    forEach(this.state.employee, (value, key) => {
      if (
        typeof value === 'string' &&
        requiredFields.indexOf(key) > -1 &&
        !value.trim()
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (!value && requiredFields.indexOf(key) > -1) {
        isError = true;
        error[key] = `This is a Required field`;
      } else {
        error[key] = '';
      }
    });
    this.setState({ error });
    return isError;
  };

  customCheck = () => {
    const error = { ...this.state.error };
    let isError = false;
    const employee = { ...this.state.employee };
    isError = !employee.isSuperuser && !employee.branchId;
    if (isError) {
      error.branchId = 'Location is required if user is not admin';
      isError = true;
    }
    // TODO: To be added validation
    // isError = (employee.password.length < 8) || ()
    this.setState({ error });
    return isError;
  };

  saveEmployee = () => {
    if (this.checkError()) {
      return;
    }
    if (this.customCheck()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.employeeService?.addEmployee(
      this.state.employee,
      (response) => {
        this.setState({
          employee:
            this.props.employee || JSON.parse(JSON.stringify(employeeObject)),
          error: { ...employeeErrorObject },
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'User ' + this.state.employee.user?.id ? 'Updated' : 'Added',
          description: `User was successfuly ${
            this.state.employee.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'User ' + this.state.employee.user?.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      employee: JSON.parse(JSON.stringify(employeeObject)),
      error: { ...employeeErrorObject },
    });
  };

  render() {
    return (
      <div>
        <EmployeeForm
          values={this.state.employee}
          error={this.state.error}
          onChange={this.onChange}
          branches={this.state.branches}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveEmployee}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  employee?: EmployeeNodeEdge | null;
  handleTabChange: (key) => void;
}

interface State {
  employee: EmployeeNodeEdge;
  error: EmployeeError;
  branches: BranchEdges[];
  buttonDisabled: boolean;
}

export interface CustomerNodeEdge {
  id?: string;
  name: string;
  address: string;
  contactNo: number | null;
  email: string;
  dateOfBirth: string | null;
  dateOfAnniversary: string | null;
  pointBalance: number;
  gstinNumber?: string;
  gstinLegalName?: string;
  creditLimit: number | null;
  companyId?: string;
}

export interface CustomerError {
  name: string;
  address: string;
  contactNo: string;
  email: string;
  dateOfBirth: string;
  dateOfAnniversary: string;
  gstinNumber: string;
  gstinLegalName: string;
}

export const customerObject: CustomerNodeEdge = {
  name: '',
  address: '',
  contactNo: null,
  email: '',
  dateOfBirth: null,
  dateOfAnniversary: null,
  pointBalance: 0,
  gstinNumber: '',
  gstinLegalName: '',
  creditLimit: null,
};

export const customerErrorObject: CustomerError = {
  name: '',
  address: '',
  contactNo: '',
  email: '',
  dateOfBirth: '',
  dateOfAnniversary: '',
  gstinLegalName: '',
  gstinNumber: '',
};

export const customerQuery = `
  query CustomerList($companyId: ID, $after: String, $name: String) {
    allCustomers(companyId: $companyId, after: $after, first: 20, nameOrNumberIcontains: $name) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          address
          contactNo
          email
          dateOfBirth
          dateOfAnniversary
          pointBalance
          gstinNumber
          gstinLegalName
          creditLimit
        }
      }
    }
  }
`;

export const specialDateReportQuery = `
query CustomerList($companyId: ID, $birthDate: String, $anniversaryDate: String) {
  allCustomersUsingBirthDate: allCustomers(companyId: $companyId, dateOfBirth_Contains: $birthDate) {
    edges {
      cursor
      node {
        id
        name
        address
        contactNo
        email
        dateOfBirth
        dateOfAnniversary
        lastPurchaseDetails {
          lastPurchasedFrom
          lastPurchasedAt
        }
      }
    }
  }
  allCustomersUsingAnniversaryDate: allCustomers(companyId: $companyId, dateOfAnniversary_Contains: $anniversaryDate) {
    edges {
      cursor
      node {
        id
        name
        address
        contactNo
        email
        dateOfBirth
        dateOfAnniversary
        lastPurchaseDetails {
          lastPurchasedFrom
          lastPurchasedAt
        }
      }
    }
  }
}
`;

export const CustomerHistoryReportQuery = `
query CustomerHistoryReport($branchId: ID, $startDate: Date!, $endDate: Date!, $after: String, $companyId: ID) {
  allCustomers (first: 20, after: $after, companyId: $companyId) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        contactNo
        totalPurchaseDetails(branchId: $branchId, startDate: $startDate, endDate: $endDate) {
          amount
        }
      }
    }
  }
}
`;

export const customerMutation = `
mutation addCustomer(
  $input: CreateCustomerInput!
) {
  createCustomer(input: $input){
    newCustomer {
      id
      name
    }
  }
}
`;

export interface CustomerQueryResponse {
  data: {
    allCustomers: {
      edges: CustomerEdges[];
    };
  };
}

export interface CustomerEdges {
  cursor: string;
  node: CustomerNodeEdge;
}

export interface AddCustomerVariables {
  input: {
    customer: CustomerNodeEdge;
  };
}

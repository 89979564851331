import * as React from 'react';
import { Row, Col, Button, Divider } from 'antd';
import { ProductDropDown } from '../Product/ProductDropdown';
import { TSInput } from '../../common/TSInput';
import { ProductDetailsInput, ProductNode } from '../../../schema';
import { CompanyNodeEdge } from '../Company/constants';

export const ProductConversionForm: React.FunctionComponent<Props> = (
  props,
) => {
  return (
    <div>
      <Divider />
      <Row>
        <Col span={6}>Product From</Col>
        <Col span={2}>Unit</Col>
        <Col span={2}>Quantity</Col>
        <Col span={6}>Product To</Col>
        <Col span={2}>Unit</Col>
        <Col span={2}>Quantity</Col>
        <Col span={2}>Remove</Col>
        <Col span={2}>Add</Col>
      </Row>
      <Divider />
      {props.values.map((pd, index) => (
        <Row key={'products' + index}>
          <Col span={6}>
            <ProductDropDown
              onSelect={(e, f) => {
                props.productChangeHandler(
                  { target: { name: 'productId', value: String(e) } },
                  index,
                );
                props.productObjChangeHandler((f as any).props.label, index);
              }}
              allowClear
              extrafields={[
                `
                sellingRate
                purchaseRate
                unit {
                  name
                }
              `,
              ]}
              company={props.company}
              value={pd.productId || ''}
            />
          </Col>
          <Col span={2}>
            <TSInput
              error=""
              type="text"
              name="unit"
              placeholder="Unit"
              value={props.productObjList[index]?.unit?.name
                ?.split('(')[0]
                .toUpperCase()}
            />
          </Col>
          <Col span={2}>
            <TSInput
              error=""
              type="number"
              value={pd.quantity || 0}
              name="quantity"
              placeholder="Quantity"
              onChange={(e) => props.productChangeHandler(e, index)}
            />
          </Col>
          <Col span={6}>
            <ProductDropDown
              onSelect={(e, f) => {
                props.productChangeHandler(
                  { target: { name: 'newProductId', value: String(e) } },
                  index,
                );
                props.newProductObjChangeHandler((f as any).props.label, index);
              }}
              allowClear
              extrafields={[
                `
              unit {
                name
              }
            `,
              ]}
              company={props.company}
              value={pd.newProductId || ''}
            />
          </Col>
          <Col span={2}>
            <TSInput
              error=""
              type="text"
              name="unit"
              placeholder="Unit"
              value={props.newProductObjList[index]?.unit?.name
                ?.split('(')[0]
                .toUpperCase()}
            />
          </Col>
          <Col span={2}>
            <TSInput
              error=""
              type="number"
              value={pd.quantityForNewProduct}
              name="quantityForNewProduct"
              placeholder="Quantity"
              onChange={(e) => props.productChangeHandler(e, index)}
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              shape="circle"
              icon="minus"
              style={{ marginLeft: '5px' }}
              onClick={() => props.updateProductsRow('-', index)}
              disabled={props.values.length === 1}
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              style={{ marginLeft: '5px' }}
              onClick={() => props.updateProductsRow('+', index)}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

interface Props {
  values: ProductDetailsInput[];
  company: CompanyNodeEdge;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  productObjChangeHandler: (value: ProductNode, index: number) => void;
  newProductObjChangeHandler: (value: ProductNode, index: number) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjList: ProductNode[];
  newProductObjList: ProductNode[];
}

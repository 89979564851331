import * as React from 'react';
import { DatePicker, Divider, Button, Spin } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { monthlySalesReportQuery } from './constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { downloadToFile } from '../helper';
import { GraphqlQuery } from 'requestapijs';

export class MonthlySalesReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      year: moment().format('YYYY'),
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = (headerList: string[]) => {
    const columns: any[] = [];
    headerList.forEach((header, index) => {
      columns.push({
        title: header,
        key: header,
        render: (text, obj) => obj[index],
      });
    });
    return columns;
  };

  downloadCsv = (
    monthlySalesReport: string[][],
    headerList: string[],
    dataList: string[][],
  ) => {
    const csv = monthlySalesReport
      .map((dl) =>
        this.columns(headerList)
          .map((col) => {
            return col.render('', dl);
          })
          .join(','),
      )
      .join('\n');

    downloadToFile(
      csv,
      `monthly-sales-report-${new Date().toISOString()}`,
      'MONTHLY SALES REPORT',
      this.props.company.name,
      'Head Office',
      `01-April-${this.state.year}`,
      `31-March-${this.state.year + 1}`,
      '',
    );
  };

  render() {
    if (!this.state.year) {
      return <Spin />;
    }
    const companyId = this.props.company.id;
    const currentYear = moment().format('YYYY');
    return (
      <div>
        <DatePicker.MonthPicker
          format="YYYY"
          placeholder="Year"
          value={
            this.state.year
              ? moment(this.state.year)
              : moment(moment().format('YYYY'))
          }
          onChange={(year) =>
            this.onChange({
              target: { name: 'year', value: year && year.format('YYYY') },
            })
          }
        />
        <Divider />
        <GraphqlQuery
          queryString={monthlySalesReportQuery}
          variables={{ companyId, year: Number(this.state.year) }}
          render={(
            response: { monthlySalesReport: string[][] },
            error,
            loading,
          ) => {
            if (loading) {
              return <Spin />;
            }
            if (response && companyId) {
              const dataList = response.monthlySalesReport.slice(1);
              return [
                <Table
                  dataSource={dataList}
                  columns={this.columns(response.monthlySalesReport[0])}
                  key="table"
                  rowKey="id"
                  pagination={{ pageSize: 13 }}
                  scroll={{ x: true }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(
                      response.monthlySalesReport,
                      response.monthlySalesReport[0],
                      dataList,
                    )
                  }
                  key="button"
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  year: string;
}

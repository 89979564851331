import * as React from 'react';
import { DatePicker, Table, Spin, Row, Col, Skeleton } from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { ProductNodeConnection } from '../../schema';
import { DateService } from '../../service/DateService';
import { Bussiness } from '../master/Company/constants';
import { finishedStockReportQuery } from './constants';

export class FinishedStockReport extends React.Component<Props, State> {
  dateService = new DateService();

  constructor(props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      name: '',
    };
  }

  componentWillUnmount() {
    this.dateService?.date$.next(moment().format('YYYY-MM-DD'));
  }

  dateChangeHandler = (date) => {
    this.setState({ date: moment(date).format('YYYY-MM-DD') }, () => {
      this.dateService?.date$.next(this.state.date);
    });
  };

  columns = () => {
    return [
      {
        title: 'Product name',
        dataIndex: 'node.name',
        key: 'name',
      },
      {
        title: 'Unit',
        dataIndex: 'node.unit.name',
        key: 'unit',
      },
      {
        title: 'Opening Stock',
        dataIndex: 'node.stock.openingStock',
        key: 'openingStock',
      },
      {
        title: 'Production',
        dataIndex: 'node.stock.production',
        key: 'production',
      },
      {
        title: 'Sale',
        dataIndex: 'node.stock.saleChallan',
        key: 'sale',
      },
      {
        title: 'Damage',
        dataIndex: 'node.stock.damage',
        key: 'damage',
      },
      {
        title: 'Closing',
        dataIndex: 'node.stock.totalStocks',
        key: 'totalStocks',
      },
      {
        title: 'MRP',
        dataIndex: 'node.stock.mrp',
        key: 'mrp',
      },
      {
        title: 'Value',
        dataIndex: 'node.stock.totalStocksValue',
        key: 'totalStockValue',
      },
    ];
  };

  render() {
    const { companyId, branchId } = this.props;
    const { date } = this.state;
    const variables = {
      date,
      companyId,
      branchId,
      orderBy: 'name',
      type: 'FINISHED',
    };
    const { name } = this.state;
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              value={moment(this.state.date)}
              onChange={this.dateChangeHandler}
              placeholder="Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={finishedStockReportQuery}
          variables={name ? { ...variables, name } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </div>
    );
  }
}

interface State {
  date: string;
  name: string;
}

interface Props {
  companyId: string;
  branchId?: string | null;
  business: Bussiness;
  handleCategoryData: (
    categoryId: string,
    categoryName: string,
    activeKey: string,
    categoryDate: string,
  ) => void;
}

import moment from 'moment';
import { DepositInput } from '../../schema';

export const depositObject: DepositInput = {
  date: moment().format('YYYY-MM-DD'),
  depositAmount: null,
  remarks: '',
  branchId: null,
  companyId: null,
};

export interface DepositError {
  date: string;
  depositAmount: string;
}

export const depositErrorObject: DepositError = {
  date: '',
  depositAmount: '',
};

export const depositQuery = `
query DepositList($after: String, $companyId: ID, $branchId: ID) {
  allDeposits(first: 20, after: $after, companyId: $companyId, branchId: $branchId) {
    edges {
      node {
        id
        date
        depositAmount
        remarks
      }
    }
  }
}
`;

export const depositMutation = `
mutation AddDeposit(
  $input: CreateDepositInput!
) {
  createDeposit(input: $input){
    newDeposit {
      id
      depositAmount
    }
  }
}
`;

import * as React from 'react';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import moment from 'moment';
import { InvoiceCreditReport } from '../../schema';
import {
  Skeleton,
  Table,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  Spin,
  Icon,
  Divider,
  notification,
} from 'antd';
import { TSInput } from '../common/TSInput';
import { SalesService } from '../../service/SalesService';
import { PaginationConfig } from 'antd/lib/table';
import {
  creditSalesObject,
  creditSalesError,
  CreditSales,
} from '../Sales/constants';
import { creditSaleInvoiceQuery } from './constants';

export class CreditSaleInvoiceReport extends React.Component<Props, State> {
  salesService = new SalesService();
  creditAmountList: number[] = [];
  constructor(props) {
    super(props);

    this.state = {
      values: JSON.parse(JSON.stringify(creditSalesObject)),
      error: creditSalesError,
      loading: {},
      updateDataCount: 0,
      currentPage: 1,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
    index?: number,
  ) => {
    const nextState = { ...this.state };
    if (index || index === 0) {
      nextState.values[e.target.name][
        (nextState.currentPage - 1) * 10 + index
      ] = e.target.value;
    } else {
      nextState.values[e.target.name] = e.target.value;
    }
    this.setState(nextState);
  };

  checkError = (index: number) => {
    const values = { ...this.state.values };
    const error = { ...this.state.error };
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !values[errKey] &&
        ['paymentAmountObj', 'paymentDateObj', 'paymentTypeObj'].indexOf(
          errKey,
        ) === -1
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (
        !values[errKey][index] &&
        ['startDate', 'endDate'].indexOf(errKey) === -1
      ) {
        isError = true;
        error[errKey][index] = `This is a required field`;
      }
    });
    this.setState({ error });
    return isError;
  };

  columns = () => [
    {
      title: 'Invoice Date',
      key: 'date',
      dataIndex: 'billDate',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Invoice No',
      key: 'billNo',
      dataIndex: 'billNo',
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customerName',
    },
    {
      title: 'Total Amount',
      key: 'netAmount',
      dataIndex: 'netAmount',
    },
    {
      title: 'Credit Amount',
      key: 'creditAmount',
      dataIndex: 'creditAmount',
    },
    {
      title: 'Payment Date',
      key: 'paymentDate',
      render: (value, record, index) => (
        <div>
          <DatePicker
            value={
              (this.state.values.paymentDateObj[
                (this.state.currentPage - 1) * 10 + index
              ] &&
                moment(
                  this.state.values.paymentDateObj[
                    (this.state.currentPage - 1) * 10 + index
                  ],
                )) ||
              null
            }
            format="DD-MM-YYYY"
            onChange={(e) =>
              this.onChange(
                {
                  target: {
                    name: 'paymentDateObj',
                    value: (e && moment(e).format('YYYY-MM-DD')) || '',
                  },
                },
                index,
              )
            }
            name="paymentDateObj"
            placeholder="Enter Payment Date"
          />
          <span style={{ color: 'red' }}>
            {
              this.state.error.paymentDateObj[
                (this.state.currentPage - 1) * 10 + index
              ]
            }
          </span>
        </div>
      ),
    },
    {
      title: 'Payment Type',
      key: 'paymentType',
      render: (value, record, index) => (
        <div>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            placeholder="Select Payment Type"
            value={
              this.state.values.paymentTypeObj[
                (this.state.currentPage - 1) * 10 + index
              ]
            }
            onChange={(e) =>
              this.onChange(
                { target: { name: 'paymentTypeObj', value: e.toString() } },
                index,
              )
            }
          >
            <Select.Option value="Cash">Cash</Select.Option>
            <Select.Option value="Card">Card</Select.Option>
            <Select.Option value="Cheque">Cheque</Select.Option>
            <Select.Option value="Bank">RTGS/NEFT/IMPS/UPI</Select.Option>
          </Select>
          <span style={{ color: 'red' }}>
            {
              this.state.error.paymentTypeObj[
                (this.state.currentPage - 1) * 10 + index
              ]
            }
          </span>
        </div>
      ),
    },
    {
      title: 'Payment Amount',
      key: 'paymentAmount',
      render: (value, record, index) => (
        <TSInput
          error={
            this.state.error.paymentAmountObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          value={
            this.state.values.paymentAmountObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          onChange={(e) =>
            this.onChange(
              { target: { name: 'paymentAmountObj', value: e.target.value } },
              index,
            )
          }
          name="paymentAmountObj"
          type="number"
          placeholder="Enter Payment Amount"
        />
      ),
    },
    {
      title: 'Discount',
      key: 'balanceAmount',
      render: (value, record, index) => (
        <TSInput
          error={
            this.state.error.balanceAmountObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          value={
            this.state.values.balanceAmountObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          onChange={(e) =>
            this.onChange(
              { target: { name: 'balanceAmountObj', value: e.target.value } },
              index,
            )
          }
          name="balanceAmountObj"
          type="number"
          placeholder="Enter Balance Amount"
        />
      ),
    },
    {
      title: 'Narration',
      key: 'narration',
      render: (value, record, index) => (
        <TSInput
          error={
            this.state.error.narrationObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          value={
            this.state.values.narrationObj[
              (this.state.currentPage - 1) * 10 + index
            ]
          }
          onChange={(e) =>
            this.onChange(
              { target: { name: 'narrationObj', value: e.target.value } },
              index,
            )
          }
          name="narrationObj"
          type="text"
          placeholder="Enter Narration"
        />
      ),
    },
    {
      title: 'Submit',
      key: 'submit',
      render: (value, record, index) => (
        <Button
          type="default"
          children={
            this.state.loading &&
            this.state.loading[(this.state.currentPage - 1) * 10 + index] ? (
              <Spin />
            ) : (
              <Icon type="check" />
            )
          }
          disabled={
            this.state.loading[(this.state.currentPage - 1) * 10 + index]
          }
          onClick={() =>
            this.savePayment(
              record.id,
              record.billNo,
              (this.state.currentPage - 1) * 10 + index,
            )
          }
        />
      ),
    },
  ];

  savePayment = (saleInvoiceId: string, billNo: string, index: number) => {
    if (this.checkError(index)) {
      return;
    }
    const loading = {};
    loading[index] = true;
    this.setState({ loading });
    const values = { ...this.state.values };
    if (
      this.creditAmountList[index] <
      Number(values.paymentAmountObj[index]) +
        Number(values.balanceAmountObj[index])
    ) {
      this.setState({ loading: { [index]: false } });
      return notification.error({
        message: 'Payment Amount and Balance Amount more than Credit Amount!',
      });
    }
    this.salesService?.updatePaymentModeForSales(
      {
        salesId: saleInvoiceId,
        date: values.paymentDateObj[index],
        forSaleChallan: true,
        paymentType: values.paymentTypeObj[index],
        amount: Number(values.paymentAmountObj[index]),
        balanceAmount: Number(values.balanceAmountObj[index]),
        narration: values.narrationObj[index],
      },
      () => {
        this.setState({
          loading: { [index]: false },
          updateDataCount: this.state.updateDataCount + 1,
          values: JSON.parse(JSON.stringify(creditSalesObject)),
        });
        notification.success({
          message: 'Payment Mode Updated',
          description: `Payment Mode for bill no ${billNo} was successfully Updated`,
        });
      },
      (error) => {
        notification.error({
          message: 'Payment Mode Update Error',
          description: JSON.stringify(error),
        });
        this.setState({ loading: { [index]: false } });
      },
    );
  };

  onPageChange = (pagination: PaginationConfig) => {
    this.setState({ currentPage: pagination.current || 1 });
  };

  render() {
    const {
      company: { id: companyId },
    } = this.props;
    const {
      values: { startDate: startDate, endDate: endDate },
      updateDataCount,
    } = this.state;

    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={creditSaleInvoiceQuery}
          variables={{
            companyId,
            startDate,
            endDate,
            updateDataCount,
          }}
          render={(
            response: { invoiceCreditReport: InvoiceCreditReport[] },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              this.creditAmountList = response.invoiceCreditReport.map((e) =>
                Number(e.creditAmount),
              );
              return [
                <Table
                  key="table"
                  dataSource={response.invoiceCreditReport}
                  columns={this.columns()}
                  rowKey={(obj) => obj.id || ''}
                  onChange={this.onPageChange}
                />,
              ];
            }
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  values: CreditSales;
  error: CreditSales;
  loading: {
    [index: number]: boolean;
  };
  updateDataCount: number;
  currentPage: number;
}

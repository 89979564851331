import * as React from 'react';
import { CompanyNodeEdge } from '../Company/constants';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import moment from 'moment';
import {
  Row,
  Col,
  DatePicker,
  Button,
  Icon,
  Divider,
  Skeleton,
  Table,
} from 'antd';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { GraphqlQuery } from 'requestapijs';
import { itemWiseReceiptAndSalesStatusQuery } from './constants';
import { ItemWiseReceiptAndSalesStatus } from '../../../schema';
import { ITEM_WISE_RECEIPT_AND_SALES_CSV_URL } from '../../../config';
import { downloadToFile } from '../../helper';

export class ItemWiseReceiptAndSalesStatusReport extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      branchId: '',
      startDate:
        moment().get('month') < 3
          ? `${moment().subtract(1, 'year').format('YYYY')}-04-01`
          : `${moment().get('year')}-04-01`,
      endDate: moment().format('YYYY-MM-DD'),
      categoryId: '',
      after: 0,
      currentPage: 1,
      updateDataCount: 0,
      error: {
        branchId: '',
        categoryId: '',
      },
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Quantity Received',
      dataIndex: 'qtyReceived',
      key: 'qtyReceived',
    },
    {
      title: 'Cost Price',
      dataIndex: 'costPrice',
      key: 'costPrice',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      key: 'mrp',
    },
    {
      title: 'Quantity Sold',
      dataIndex: 'qtySold',
      key: 'qtySold',
    },
  ];

  downloadCsv = (variables) => {
    const branchName = this.props.company.branches?.edges.find(
      (brEdge) => brEdge.node.id === this.state.branchId,
    )?.node.name;
    const categoryName = this.props.productCategories.find(
      (catEdge) => catEdge.node.id === this.state.categoryId,
    )?.node.name;
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_RECEIPT_AND_SALES_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then(
        (v) =>
          branchName &&
          categoryName &&
          downloadToFile(
            v,
            `item-wise-receipt-and-sales-${new Date()}`,
            'ITEM WISE RECEIPT AND SALES STATUS REPORT',
            this.props.company.name,
            branchName,
            moment(this.state.startDate).format('DD-MM-YYYY'),
            moment(this.state.endDate).format('DD-MM-YYYY'),
            categoryName,
          ),
      );
  };

  render() {
    const {
      branchId,
      startDate,
      endDate,
      categoryId,
      after,
      currentPage,
      error: err,
      updateDataCount,
    } = this.state;
    const { company, productCategories } = this.props;
    const variables = {
      companyId: company.id,
      branchId,
      startDate,
      endDate,
      categoryId,
      after,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={8}>
            {company.branches && (
              <TSAutoComplete
                error={branchId ? '' : 'This is required'}
                inputProps={{ name: 'branchId', addonBefore: 'EMPORIUM' }}
                dataSource={company.branches.edges.filter(
                  (branch) => branch.node.category === 'EMPORIUM',
                )}
                key="id"
                name="branchId"
                onValueSelect={this.onChange}
                displayKey="name"
                textValue={getValue(company.branches.edges, branchId)}
                allowClear
              />
            )}
          </Col>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSAutoComplete
              error={categoryId ? '' : 'This is required'}
              inputProps={{ name: 'categoryId', addonBefore: 'Category' }}
              dataSource={productCategories}
              key="id"
              name="categoryId"
              onValueSelect={this.onChange}
              displayKey="name"
              textValue={getValue(productCategories, categoryId)}
              allowClear
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        {branchId && categoryId && (
          <GraphqlQuery
            queryString={itemWiseReceiptAndSalesStatusQuery}
            variables={variables}
            render={(
              response: {
                itemWiseReceiptAndSalesStatus: ItemWiseReceiptAndSalesStatus[];
              },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (error) {
                return <h3>Something went wrong</h3>;
              }
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={
                      branchId && categoryId
                        ? response.itemWiseReceiptAndSalesStatus
                        : []
                    }
                    columns={this.columns()}
                    rowKey={(obj) => String(obj.id)}
                    pagination={false}
                  />,
                  <Row key="Pagination">
                    <Col offset={21} span={1}>
                      <Button
                        onClick={this.onPrev}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </Button>
                    </Col>
                    <Col span={1} style={{ paddingLeft: 10 }}>
                      <Button type="primary">{currentPage}</Button>
                    </Col>
                    <Col span={1}>
                      <Button
                        onClick={this.onNext}
                        disabled={
                          !response.itemWiseReceiptAndSalesStatus.length ||
                          response.itemWiseReceiptAndSalesStatus.length < 20
                        }
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>,
                ];
              }
              return <Skeleton active />;
            }}
          />
        )}
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  productCategories: ProductCategoryEdges[];
}

interface State {
  branchId: string;
  startDate: string;
  endDate: string;
  categoryId: string;
  after: number;
  currentPage: number;
  updateDataCount: number;
  error: {
    branchId: string;
    categoryId: string;
  };
}

import * as React from 'react';
import { Table, Spin, Skeleton, Button, Row, Col } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { ProductNodeConnection, ProductNodeEdge } from '../../schema';
import { ColumnProps } from '../common/Table';
import { Bussiness } from '../master/Company/constants';
import { downloadToFile } from '../helper';
import { productStockForCategoryQuery } from '../master/Product/constants';
import { Pagination } from 'sha-el-design';
import moment from 'moment';
import { TSInput } from '../common/TSInput';
import { ITEM_STOCK_FOR_CATEGORY_REPORT_CSV_URL } from '../../config';

export class ProductStockForCategoryReport extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      after: '',
      barcode: '',
    };
  }

  componentWillReceiveProps() {
    this.setState({ currentPage: 1, after: '', barcode: '' });
  }

  columns = () => {
    const stockColumns: ColumnProps<ProductNodeEdge>[] = [
      {
        title: 'Product Name',
        dataIndex: 'node.name',
        key: 'name',
      },
      {
        title: 'Current Stock',
        key: 'totalStocks',
        render: (data) =>
          Number(Number(data.node.stock.totalStocks).toFixed(2)),
      },
      {
        title: 'MRP',
        dataIndex: 'node.stock.mrp',
        key: 'mrp',
      },
      {
        title: 'Closing Stock Value',
        key: 'stockValue',
        render: (data) =>
          Number(Number(data.node.stock.totalStocksValue).toFixed(2)),
      },
    ];
    return stockColumns;
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_STOCK_FOR_CATEGORY_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `item-stock-for-category-${new Date()}`,
          'PRODUCT STOCK REPORT FOR PARTICULAR CATEGORY',
          (this.props.business.type === 'branch'
            ? this.props.business.companyName
            : this.props.business.name) || '',
          this.props.business.type === 'branch'
            ? this.props.business.name
            : 'Head Office',
          moment(this.props.date).format('DD-MM-YYYY'),
          moment(this.props.date).format('DD-MM-YYYY'),
          this.props.categoryName,
        ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { after, barcode } = this.state;
    const { date, companyId, branchId, categoryId, fy_stock } = this.props;
    let variables: any = {
      categoryId,
      companyId,
      date,
      after,
      barcode,
      fy_stock,
    };
    variables = branchId ? { ...variables, branchId } : variables;
    return (
      <div>
        <Row>
          <Col span={8}>
            <TSInput
              error=""
              value={barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={productStockForCategoryQuery}
          variables={barcode ? { ...variables, barcode } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={this.state.currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
        ,
      </div>
    );
  }
}

interface State {
  currentPage: number;
  after: string;
  barcode: string;
}

interface Props {
  categoryId: string;
  companyId: string;
  branchId?: string | null;
  business: Bussiness;
  categoryName: string;
  date: string;
  fy_stock: boolean;
}

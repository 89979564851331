import { HsnInput, HsnNodeConnection } from '../../../schema';

export const hsnObject: HsnInput = {
  hsnCode: '',
  gst: 0,
  minValue: 0,
  maxValue: 0,
  companyId: null,
};

export const hsnQuery = `
  query HsnList($companyId: ID, $after: String, $hsnCode: String) {
    allHsns(companyId: $companyId, first: 20, after: $after, hsnCode_Icontains: $hsnCode) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          hsnCode
          gst
          minValue
          maxValue
        }
      }
    }
  }
`;

export const hsnMutation = `
mutation addHsn(
  $input: CreateHsnInput!
) {
  createHsn(input: $input){
    newHsn {
      id
      hsnCode
    }
  }
}
`;

export interface HsnQueryResponse {
  data: {
    allHsns: HsnNodeConnection;
  };
}

import {
  ProductDetailsInput,
  ProductDetailsMovementType,
  SaleChallanInput,
} from '../../schema';
import moment from 'moment';

export const saleChallanProductDetailsObject: ProductDetailsInput = {
  date: moment().format('YYYY-MM-DD'),
  productId: null,
  quantity: null,
  rate: null,
  purchaseRate: null,
  gstRate: null,
  amount: null,
  movementType: ProductDetailsMovementType.SALE_CHALLAN,
  companyId: null,
};

export const saleChallanObject: SaleChallanInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  challanNo: null,
  deliveryInstructionId: null,
  productDetails: [saleChallanProductDetailsObject],
  remarks: null,
  companyId: null,
  totalAmount: 0,
  dispatchedThrough: null,
  miscellaneousCharge: 0,
  transportationCharges: 0,
  otherCharges: 0,
  destination: '',
  vehicleNo: '',
  cancelled: false,
};

export interface SaleChallanProductDetailsError {
  quantity: string;
}

export const saleChallanProductDetailsErrorobj: SaleChallanProductDetailsError =
  {
    quantity: '',
  };

export interface SaleChallanError {
  date: string;
  deliveryInstructionId: string;
  dispatchedThrough: string;
  vehicleNo: string;
  destination: string;
  productDetails: SaleChallanProductDetailsError[];
}

export const saleChallanErrorObj: SaleChallanError = {
  date: '',
  deliveryInstructionId: '',
  dispatchedThrough: '',
  destination: '',
  vehicleNo: '',
  productDetails: [saleChallanProductDetailsErrorobj],
};

export const saleChallanQuery = `
query SaleChallanList($companyId: ID, $after: String, $date_Gte: Date, $date_Lte: Date) {
  allSaleChallans(companyId: $companyId, first: 20, after: $after, date_Gte: $date_Gte, date_Lte: $date_Lte) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        challanNo
        remarks
        dispatchedThrough
        destination
        vehicleNo
        miscellaneousCharge
        transportationCharges
        otherCharges
        totalAmount
        company {
          id
          name
          customerSet {
            edges {
              node {
                name
                address
                gstinNumber
                contactNo
              }
            }
          }
        }
        deliveryInstruction {
          id
          date
          customer {
            name
            address
            gstinNumber
            contactNo
          }
          deliveryInstructionNo
          purchaseOrderNumber
          purchaseOrderDate
          saleChallanProductDetails {
            productId
            product {
              id
              hsn {
                hsnCode
                gst
                minValue
                maxValue
              }
            }
            rate
            orderQuantity
            purchaseRate
            gstRate
            dispatchedQuantity
            remainingQuantity
            quantity
            movementType
          }
        }
        remarks
        cancelled
        hsnGstWiseAmount {
          hsnCode
          gst
          quantity
          amount
        }
        productDetails {
          edges {
            node {
              id
              date
              rate
              gstRate
              amount
              quantity
              purchaseRate
              movementType
              product {
                id
                name
                purchaseRate
                hsn {
                  hsnCode
                  gst
                }
                unit {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const saleChallanMutation = `
mutation addSaleChallan($input: CreateSaleChallanInput!) {
  createSaleChallan(input: $input) {
    newSaleChallan {
      id
      challanNo
    }
  }
}
`;

export const creditSaleInvoiceQuery = `
query CreditSaleInvoice($companyId: ID!, $startDate: Date!, $endDate: Date!) {
  invoiceCreditReport(companyId: $companyId, startDate: $startDate, endDate: $endDate) {
    id
    billDate
    billNo
    customerName
    netAmount
    creditAmount
  }
}
`;

export const invoiceCreditPaymentReportQuery = `
query InvoiceCreditPaymentReport($companyId: ID, $startDate: Date, $endDate: Date, $after: String) {
  allPaymentModes(
    salechallan_CompanyId: $companyId,
    salechallan_Date_Gte: $startDate,
    salechallan_Date_Lte: $endDate,
    paymentForCredit: true,
    first: 20,
    after: $after,
    orderBy: "salechallan__challan_no,salechallan__date,id",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        paymentType
        amount
        balanceAmount
        narration
        salechallanSet {
          edges {
            node {
              date
              deliveryInstruction {
                customer {
                  name
                }
              }
              challanNo
              totalAmount
              paymentModes(paymentType_Iexact: "Credit") {
                edges {
                  node {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

import * as React from 'react';
import { Button, notification } from 'antd';
import { ChallanNode, ChallanInput, PurchaseOrderNode } from '../../schema';
import { ChallanForm } from './ChallanForm';
import {
  challanObject,
  ProductDetails,
  productDetailsObject,
  ChallanError,
  challanErrorObj,
} from './constants';
import { ProductDetails as PurchaseOrderProductDetails } from '../PurchaseOrder/constants';
import { ChallanService } from '../../service/ChallanService';
import { CompanyNodeEdge } from '../master/Company/constants';

export class AddChallan extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      challan: this.handlePropsValuesUpdate(props),
      error: this.handlePropsErrorUpdate(props),
      buttonDisabled: false,
      purchaseOrder: props.challan ? props.challan.purchaseOrder : null,
    };
  }

  challanService = new ChallanService();

  componentWillReceiveProps(nextProps: Props) {
    const purchaseOrder: PurchaseOrderNode | undefined | null =
      nextProps.challan ? nextProps.challan.purchaseOrder : null;
    this.setState({
      challan: this.handlePropsValuesUpdate(nextProps),
      error: this.handlePropsErrorUpdate(nextProps),
      purchaseOrder,
    });
  }

  handlePropsValuesUpdate = (props: Props) => {
    return props.challan
      ? {
          ...props.challan,
          purchaseOrderId: props.challan.purchaseOrder?.id,
          dumpLocationId:
            props.challan.dumpLocation && props.challan.dumpLocation.id,
        }
      : JSON.parse(JSON.stringify(challanObject));
  };

  handlePropsErrorUpdate = (props: Props) => {
    const error: ChallanError = JSON.parse(JSON.stringify(challanErrorObj));
    if (props.challan) {
      const productDetails: ProductDetails[] = JSON.parse(
        props.challan.productDetails,
      );
      productDetails.forEach(() => {
        if (productDetails.length !== error.productDetails.length) {
          error.productDetails.push({
            receivedQuantity: '',
          });
        }
      });
    }
    return error;
  };

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const challan = { ...this.state.challan };
    challan[e.target.name] = e.target.value;
    this.setState({ challan });
  };

  checkError = () => {
    const error = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    Object.keys(challanErrorObj).forEach((errKey) => {
      if (
        !this.state.challan[errKey] &&
        ['productDetails', 'dumpLocationId'].indexOf(errKey) === -1
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
      if (
        errKey === 'dumpLocationId' &&
        !this.state.challan.dumpToHeadOffice &&
        !this.state.challan[errKey]
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      }
    });
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const productDetails: ProductDetails[] = JSON.parse(
      this.state.challan.productDetails,
    );
    const error: ChallanError = JSON.parse(JSON.stringify(this.state.error));
    let isError = true;
    error.productDetails[
      error.productDetails.length - 1
    ].receivedQuantity = `At least 1 Product must be Received`;
    const productDetailObj = productDetails.find(
      (pd) => pd.receivedQuantity !== 0,
    );
    if (productDetailObj) {
      isError = false;
      error.productDetails[
        error.productDetails.length - 1
      ].receivedQuantity = ``;
    }
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const challan = this.state.challan;
    const productDetails: ProductDetails[] = JSON.parse(
      challan.productDetails,
    ) as ProductDetails[];
    productDetails[index][e.target.name] = e.target.value;
    challan.productDetails = JSON.stringify(productDetails);
    this.setState({ challan });
  };

  saveChallan = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.challanService?.addChallan(
      this.state.challan,
      (response) => {
        if (!this.props.challan) {
          this.setState({
            challan: JSON.parse(JSON.stringify(challanObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Challan ' + this.state.challan.id ? 'Updated' : 'Added',
          description: `Challan for order no ${
            this.state.challan.challanNo
          } was successfully ${this.state.challan.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Challan ' + this.state.challan.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };
  clear = () => {
    this.setState({ challan: JSON.parse(JSON.stringify(challanObject)) });
  };

  purchaseOrderObjChangeHandler = (purchaseOrderObj: PurchaseOrderNode) => {
    const nextState = { ...this.state };
    nextState.challan.purchaseOrderId = purchaseOrderObj.id;
    nextState.purchaseOrder = purchaseOrderObj;
    const productDetails = JSON.parse(
      JSON.stringify([productDetailsObject]),
    ) as ProductDetails[];
    nextState.purchaseOrder.productDetailsWithAvailbleQty?.productDetails &&
      (
        JSON.parse(
          nextState.purchaseOrder.productDetailsWithAvailbleQty?.productDetails,
        ) as PurchaseOrderProductDetails[]
      ).forEach((pd, index) => {
        if (productDetails.length < index + 1) {
          productDetails.push(JSON.parse(JSON.stringify(productDetailsObject)));
          nextState.error.productDetails.push({
            receivedQuantity: '',
          });
        }
        productDetails[index].productName = pd.productName;
        productDetails[index].unit = pd.unit;
        productDetails[index].orderQuantity = pd.quantity;
        productDetails[index].rate = pd.rate;
        productDetails[index].amount = pd.amount;
        productDetails[index].availableQuantity = pd.availableQuantity;
        productDetails[index].dues = pd.availableQuantity
          ? (pd.quantity || 0) - pd.availableQuantity
          : pd.quantity;
      });
    nextState.challan.productDetails = JSON.stringify(productDetails);
    this.setState(nextState);
  };

  render() {
    return (
      <div>
        <ChallanForm
          values={this.state.challan}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          purchaseOrderObjChangeHandler={this.purchaseOrderObjChangeHandler}
          purchaseOrder={this.state.purchaseOrder}
          company={this.props.company}
          isEditActive={this.props.isEditActive}
          activeKey={this.props.activeKey}
          error={this.state.error}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveChallan}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  challan?: ChallanNode | null;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  isEditActive: boolean;
  activeKey: string;
}

interface State {
  challan: ChallanInput;
  error: ChallanError;
  buttonDisabled: boolean;
  purchaseOrder?: PurchaseOrderNode | null;
}

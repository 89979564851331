import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import { HsnInput, CreateHsnInput, CreateHsnPayload } from '../schema';
import { hsnMutation } from '../components/master/Hsn/constants';

export class HsnService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (HsnService.instance) {
      return HsnService.instance;
    } else {
      HsnService.instance = this;
    }
  }

  addHsn(
    data: HsnInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<{ input: CreateHsnInput }, CreateHsnPayload>(
      API_URL,
      {
        input: {
          hsn: { ...data, companyId: this.companyService?.company$.value?.id },
        },
      },
      hsnMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

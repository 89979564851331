import * as React from 'react';
import { TSInput } from '../../common/TSInput';
import { HsnNode, HsnInput } from '../../../schema';

export const HsnForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={''}
        value={props.values.hsnCode}
        onChange={props.onChange}
        name="hsnCode"
        type="text"
        addonBefore="Hsn Code"
      />
      <TSInput
        error={''}
        value={props.values.gst}
        onChange={props.onChange}
        name="gst"
        type="text"
        addonBefore="GST"
      />
      <TSInput
        error={''}
        value={props.values.minValue}
        onChange={props.onChange}
        name="minValue"
        type="number"
        addonBefore="Minimum Amount"
      />
      <TSInput
        error={''}
        value={props.values.maxValue}
        onChange={props.onChange}
        name="maxValue"
        type="text"
        addonBefore="Maximum Amount"
      />
    </div>
  );
};

interface Props {
  values: HsnInput;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
}

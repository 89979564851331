import * as React from 'react';
import moment from 'moment';
import { CompanyNodeEdge } from '../master/Company/constants';
import { Table, Spin, Button, Col, DatePicker, Divider, Row } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { exchangeReportQuery } from '../master/Product/constants';
import { ProductDetailsNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { downloadToFile } from '../helper';
import { BranchNodeEdge } from '../master/Branch/constants';
import { EXCHANGE_REPORT_CSV_URL } from '../../config';

export class ExchangeReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Bill No.',
      key: 'billNo',
      dataIndex: 'node.salesOldProductDetails.edges[0].node.billNo',
    },
    {
      title: 'Bill Date',
      key: 'billDate',
      dataIndex: 'node.salesOldProductDetails.edges[0].node.date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Product Name',
      key: 'product',
      dataIndex: 'node.product.name',
    },
    {
      title: 'Barcode 1',
      key: 'oldBarcode',
      dataIndex: 'node.product.oldBarcodeId',
    },
    {
      title: 'Barcode 2',
      key: 'newBarcode',
      dataIndex: 'node.product.newBarcodeId',
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'node.product.category.name',
    },
    {
      title: 'Division',
      key: 'division',
      dataIndex: 'node.product.division',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'node.quantity',
    },
    {
      title: 'Rate',
      key: 'rate',
      dataIndex: 'node.rate',
    },
    {
      title: 'Exchange Amount',
      key: 'amount',
      dataIndex: 'node.amount',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(EXCHANGE_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `item-wise-exchange-report-${
            this.props.branch.name
          }-${new Date().toISOString()}`,
          'ITEM WISE EXCHANGE REPORT',
          this.props.company.name,
          this.props.branch.name || '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const {
      company: { id: companyId },
      branch: { id: branchId },
    } = this.props;
    const { after, currentPage, startDate, endDate } = this.state;
    const variables = { companyId, branchId, after, startDate, endDate };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(this.state.startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(this.state.endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Divider />
        <GraphqlQuery
          queryString={exchangeReportQuery}
          variables={variables}
          render={(
            response: { allProductDetails: ProductDetailsNodeConnection },
            error,
            loading,
          ) => {
            if (loading) return <Spin />;
            if (error) return <h3>Something Went Wrong!!</h3>;
            if (response) {
              return [
                <Table
                  dataSource={response.allProductDetails.edges}
                  columns={this.columns()}
                  key="Table"
                  pagination={false}
                  rowKey="node.id"
                />,
                <Pagination
                  key="Pagination"
                  totalCount={0}
                  cursorBasedPagination
                  currentPage={currentPage}
                  batchSize={20}
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProductDetails.pageInfo.endCursor,
                      response.allProductDetails.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv(variables)}
                  key="button"
                />,
              ];
            }
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
}

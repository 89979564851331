import * as React from 'react';
import { Row, Col, Spin } from 'antd';
import { TSInput } from '../common/TSInput';
import { productQuery } from './constants';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { ProductNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';

export const OpeningStockReport: React.FunctionComponent<Props> = (props) => {
  const { after, currentPage, onPageChange } = props;
  return (
    <div>
      {props.categoryId && (
        <GraphqlQuery
          queryString={productQuery}
          variables={{
            companyId: props.company.id,
            categoryId: props.categoryId,
            branchId:
              props.business.type === 'branch' ? props.business.id : null,
            after,
          }}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (error) return <div>{JSON.stringify(error)}</div>;
            if (loading) return <Spin />;
            if (response) {
              return [
                <div key="report">
                  <Row>
                    <Col
                      span={4}
                      style={{
                        marginLeft: '8px',
                        marginTop: '9px',
                        marginBottom: '3px',
                      }}
                    >
                      {<b>Barcode 1</b>}
                    </Col>
                    <Col
                      span={3}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Barcode 2</b>}
                    </Col>
                    <Col
                      span={8}
                      style={{ marginTop: '9px', marginBottom: '3px' }}
                    >
                      {<b>Name</b>}
                    </Col>
                    <Col span={5}>{<b>Purchase Price</b>}</Col>
                    <Col span={3}>{<b>Quantity</b>}</Col>
                  </Row>
                  {response.allProducts.edges.map((prod, indexProd) => (
                    <Row key={indexProd}>
                      <Col
                        span={4}
                        style={{
                          marginLeft: '8px',
                          marginTop: '9px',
                          marginBottom: '3px',
                        }}
                      >
                        {prod.node?.oldBarcodeId}
                      </Col>
                      <Col
                        span={3}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.newBarcodeId}
                      </Col>
                      <Col
                        span={8}
                        style={{ marginTop: '9px', marginBottom: '3px' }}
                      >
                        {prod.node?.name}
                      </Col>
                      <Col span={5}>
                        <TSInput
                          error={''}
                          value={prod.node?.purchaseRate}
                          addonBefore="Pur. Price"
                        />
                      </Col>
                      <Col span={3}>
                        <TSInput
                          error={''}
                          value={
                            (prod.node?.openingStock as unknown as number) || 0
                          }
                          placeholder="Quantity"
                          name="quantity"
                        />
                      </Col>
                    </Row>
                  ))}
                </div>,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
      )}
    </div>
  );
};

export interface Props {
  categoryId: string;
  company: CompanyNodeEdge;
  business: Bussiness;
  after: string;
  currentPage: number;
  onPageChange: (
    next: boolean,
    afterProduct: string,
    nextPage: boolean,
  ) => void;
}

import * as React from 'react';
import { Button, notification, Modal } from 'antd';
import { ValueaditionService } from '../../service/ValueaditionService';
import { CustomerService } from '../../service/CustomerService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import {
  ValueaditionNodeEdgeQuery,
  ValueaditionEdges,
  ValueaditionNodeEdgeMutation,
  valueaditionObject,
  ValueaditionError,
  valueaditionErrorObj,
  productDetailsErrorObj,
} from './constants';
import { ValueaditionForm } from './ValueaditionForm';
import { ProductNode, CustomerNode } from '../../schema';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';
import { VendorEdges } from '../master/vendor/constants';
import { VendorService } from '../../service/VendorService';
import { UserNodeEdge } from '../../components/user/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyService } from '../../service/CompanyService';
import { ProductionForm } from './ProductionForm';
import { ProductDropDown } from '../master/Product/ProductDropdown';

export class AddValueadition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      valueadition: this.handlePropsValueUpdate(props),
      error: this.handlePropsErrorUpdate(props),
      buttonDisabled: false,
      productObjList: [],
      customerObj: null,
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
      vendors: [],
      valueaditioncost: 0,
      user: null,
      bussiness: null,
      branch: null,
      company: null,
      product: null,
    };
  }

  valueaditionService = new ValueaditionService();
  customerService = new CustomerService();
  vendorService = new VendorService();
  companyService = new CompanyService();

  valueaditioncost = 0;
  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      valueadition: this.handlePropsValueUpdate(nextProps),
      error: this.handlePropsErrorUpdate(nextProps),
    });
  }
  handlePropsValueUpdate(nextProps: Props) {
    const valueadition: any = nextProps.valueadition
      ? {
          ...nextProps.valueadition,
          customerId:
            nextProps.valueadition.customer &&
            nextProps.valueadition.customer.id,
          vendorId: nextProps.valueadition?.vendor?.id,
          oldApprovalId:
            nextProps.valueadition.oldValueadition &&
            nextProps.valueadition.oldValueadition.id,
          productDetails: nextProps.valueadition.productDetails?.edges.map(
            (pd) => ({ ...pd.node, productId: pd.node.product?.id }),
          ),
        }
      : JSON.parse(JSON.stringify(valueaditionObject));
    if (nextProps.valueadition) {
      delete valueadition.branch;
      delete valueadition.customer;
      delete valueadition.oldValueadition;
    }
    return valueadition;
  }

  handlePropsErrorUpdate(nextProps: Props) {
    const error: ValueaditionError = JSON.parse(
      JSON.stringify(valueaditionErrorObj),
    );
    nextProps.valueadition &&
      nextProps.valueadition.productDetails?.edges.forEach(() => {
        if (
          nextProps.valueadition?.productDetails?.edges.length !==
          error.productDetails.length
        ) {
          error.productDetails.push(productDetailsErrorObj);
        }
      });
    return error;
  }

  componentDidMount() {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const valueadition = { ...this.state.valueadition };
    const nextState: State = {
      ...this.state,
    };
    const error: ValueaditionError = JSON.parse(
      JSON.stringify(valueaditionErrorObj),
    );
    valueadition[e.target.name] = e.target.value;
    if (e.target.name === 'isReturn' && !e.target.value) {
      valueadition.oldValueaditionId = null;
      valueadition.productDetails = JSON.parse(
        JSON.stringify(valueaditionObject.productDetails),
      );
      valueadition.totalMrp = 0;
      valueadition.totalCostAmount = 0;
      valueadition.valueaditionCost = 0;
      error.oldValueaditionId = '';
      error.productDetails = [productDetailsErrorObj];
    }
    if (e.target.name === 'oldValueaditionId' && e.target.value) {
      this.getOldValueaditionData(valueadition, e.target.value as string);
    }
    this.setState({ valueadition });
  };

  getOldValueaditionData = (
    valueadition: ValueaditionNodeEdgeMutation,
    oldValueaditionId: string,
  ) => {
    const oldValueadition = this.props.valueaditionList.find(
      (appr) => appr.node.id === oldValueaditionId,
    );
    const error: ValueaditionError = JSON.parse(
      JSON.stringify(valueaditionErrorObj),
    );
    valueadition.productDetails =
      oldValueadition?.node.productDetails?.edges.map((pd) => ({
        ...pd.node,
        productId: pd.node.product?.id,
        movementForId: pd.node.movementFor && pd.node.movementFor.id,
        movementType: 'APPROVAL_RETURN' as const,
      }));

    valueadition.productDetails?.map((pd) => {
      if (valueadition.productDetails?.length !== error.productDetails.length) {
        error.productDetails.push(productDetailsErrorObj);
      }
      delete pd.product;
      delete pd.movementFor;
      pd.quantity = 0;
      pd.amount = 0;
      return pd;
    });
    valueadition.totalMrp = 0;
    valueadition.valueaditionCost = oldValueadition?.node.valueaditionCost;
    valueadition.totalCostAmount = 0;
    this.setState({ valueadition, error });
  };

  checkError = () => {
    const error: ValueaditionError = JSON.parse(
      JSON.stringify(this.state.error),
    );
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !this.state.valueadition[errKey] &&
        [
          'productDetails',
          'oldValueaditionId',
          'vendorId',
          'approvedByName',
          'customerId',
        ].indexOf(errKey) === -1
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (
        [
          'productDetails',
          'oldValueaditionId',
          'vendorId',
          'approvedByName',
          'customerId',
        ].indexOf(errKey) === -1
      ) {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  customError = () => {
    const error = { ...this.state.error };
    let isError = false;
    if (
      this.state.valueadition.isReturn &&
      !this.state.valueadition.oldValueaditionId
    ) {
      isError = true;
      error.oldValueaditionId = `This is a required field`;
    } else {
      error.oldValueaditionId = '';
    }
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const productDetails: ProductDetailNodeEdge[] = {
      ...(this.state.valueadition.productDetails || []),
    };
    const error: ValueaditionError = JSON.parse(
      JSON.stringify(this.state.error),
    );
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.valueadition.productDetails) {
      this.valueaditioncost =
        nextState.valueadition.productDetails[index].discount || 0;
      nextState.valueadition.valueaditionCost = 0;
      nextState.valueadition.productDetails[index][e.target.name] =
        e.target.value;
      nextState.valueadition.productDetails[index].amount =
        Number(nextState.valueadition.productDetails[index].quantity) *
        (nextState.valueadition.productDetails[index].discount || 0);
      this.calculateAmount(nextState);
      this.setState(nextState);
    }
  };

  productionChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | null } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (e.target.name === 'productId') {
      e.target.value = localStorage.getItem('myid');
    }
    if (nextState.valueadition.productDetails?.[index]) {
      if (e.target.name === 'rate') {
        nextState.valueadition.productDetails[index].purchaseRate = Number(
          e.target.value,
        );
        nextState.valueadition.productDetails[index].rate = Number(
          e.target.value,
        );
      }
      this.valueaditioncost =
        nextState.valueadition.productDetails[index].discount || 0;
      nextState.valueadition.valueaditionCost = 0;
      nextState.valueadition.productDetails[index][e.target.name] =
        e.target.value;
      nextState.valueadition.productDetails[index].amount =
        Number(nextState.valueadition.productDetails[index].quantity) *
        (nextState.valueadition.productDetails[index].discount || 0);
    }

    this.calculateAmount(nextState);
    this.setState(nextState);
  };

  calculateAmount = (nextState: State) => {
    if (nextState.valueadition.productDetails) {
      const totalMrp = Number(
        nextState.valueadition.productDetails
          .reduce((sum, p) => sum + (p.amount || 0), 0)
          .toFixed(2),
      );
      nextState.valueadition.totalMrp = totalMrp;
      nextState.valueadition.totalCostAmount = Number(
        nextState.valueadition.productDetails
          .reduce((s, p) => s + (p.quantity || 0) * this.valueaditioncost, 0)
          .toFixed(2),
      );
    }
    this.setState(nextState);
  };

  productionObjChangehandler = (productObj, index: number) => {
    const nextState = { ...this.state };
    if (nextState.valueadition.productDetails) {
      nextState.productObjList[index] = productObj;
      nextState.valueadition.productDetails[index].remark =
        productObj.finishedProduct;
      nextState.valueadition.productDetails[index].productId =
        localStorage.getItem('myid');
      nextState.valueadition.productDetails[index].rate =
        productObj.purchaseRate;
      nextState.valueadition.productDetails[index].purchaseRate =
        this.state.valueadition.productDetails?.[0].purchaseRate;
    }
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    if (nextState.valueadition.productDetails) {
      nextState.valueadition.productDetails[index].rate =
        productObj.sellingRate;
      nextState.valueadition.productDetails[index].purchaseRate =
        productObj.purchaseRate;
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.valueadition.productDetails?.push({
        date: null,
        productId: '',
        quantity: null,
        rate: null,
        amount: 0,
        discount: null,
        movementType: 'APPROVAL',
        companyId: null,
        remark: '',
      });
      nextState.valueadition.valueaditionCost = 0;
      nextState.error.productDetails.push(productDetailsErrorObj);
    } else {
      nextState.valueadition.productDetails?.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
      this.calculateAmount(nextState);
    }
    this.setState(nextState);
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.valueadition.customerId = customerObj.id;
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveValueadition = () => {
    if (this.checkError()) {
      return;
    }
    if (this.customError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.valueaditionService?.addValueadition(
      this.state.valueadition,
      () => {
        if (!this.props.valueadition) {
          this.setState({
            valueadition: JSON.parse(JSON.stringify(valueaditionObject)),
            error: JSON.parse(JSON.stringify(valueaditionErrorObj)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Valueadition ' + this.state.valueadition.id ? 'Updated' : 'Added',
          description: `Valueadition no ${
            this.state.valueadition.valueaditionNo
          } was successfuly ${
            this.state.valueadition.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        this.setState({ buttonDisabled: false });
        notification.error({
          message:
            'Valueadition ' + this.state.valueadition.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  clear = () => {
    this.setState({
      valueadition: JSON.parse(JSON.stringify(valueaditionObject)),
      error: JSON.parse(JSON.stringify(valueaditionErrorObj)),
    });
  };

  render() {
    const {
      valueadition,
      error,
      updateCount,
      modalAction,
      buttonDisabled,
      branch,
    } = this.state;
    const { company, valueaditionList, isEditActive } = this.props;
    return (
      <div>
        {branch?.type === 'company' ||
        (this.props.selectedoption === 'VENDOR' &&
          branch?.category === 'REGIONAL_OFFICE') ? (
          <ProductionForm
            values={valueadition}
            onChange={this.onChange}
            company={this.companyService?.company$.value}
            valueaditionList={valueaditionList}
            productChangeHandler={this.productChangeHandler}
            updateProductsRow={this.updateProductsRow}
            productObjChangehandler={this.productObjChangehandler}
            isEditActive={isEditActive}
            error={error}
            handleModalAction={this.handleModalAction}
            customerObjChangehandler={this.customerObjChangehandler}
            updateCount={updateCount}
            vendors={this.state.vendors}
            selectedoption={this.props.selectedoption}
            user={this.state.user}
            bussiness={this.state.bussiness}
            productionObjChangehandler={this.productionObjChangehandler}
            productionChangeHandler={this.productionChangeHandler}
          />
        ) : (
          <ValueaditionForm
            values={valueadition}
            onChange={this.onChange}
            company={this.companyService?.company$.value}
            valueaditionList={valueaditionList}
            productChangeHandler={this.productChangeHandler}
            updateProductsRow={this.updateProductsRow}
            productObjChangehandler={this.productObjChangehandler}
            isEditActive={isEditActive}
            error={error}
            handleModalAction={this.handleModalAction}
            customerObjChangehandler={this.customerObjChangehandler}
            updateCount={updateCount}
            vendors={this.state.vendors}
            selectedoption={this.props.selectedoption}
            user={this.state.user}
            bussiness={this.state.bussiness}
          />
        )}
        <Modal
          title="Add Customer"
          visible={modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            company={company}
            onCustomerAdded={() =>
              this.setState({ updateCount: updateCount + 1 })
            }
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={updateCount}
            showOnlyList
          />
        </Modal>
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveValueadition}
          children="Submit"
          style={{ width: '50%' }}
          disabled={buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  valueadition?: ValueaditionNodeEdgeQuery;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  valueaditionList: ValueaditionEdges[];
  isEditActive: boolean;
  selectedoption: 'CUSTOMER' | 'VENDOR';
}

interface State {
  valueadition: ValueaditionNodeEdgeMutation;
  error: ValueaditionError;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  customerObj: CustomerNode | null;
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
  vendors: VendorEdges[];
  valueaditioncost: number | string | boolean;
  user?: UserNodeEdge | null;
  bussiness: Bussiness | null;
  branch: BranchNodeEdge | null;
  company: CompanyNodeEdge | null;
  product: ProductDropDown | null;
}

import { ID, PageInfo } from '../../../schema';
import { ProductCategoryNodeEdge } from '../ProductCategories/constants';

export interface ProductSubCategoryNode {
  id?: ID;
  name: string;
  category?: ProductCategoryNodeEdge | null;
  categoryId: string | null;
  companyId?: string | null;
}

export interface ProductSubCategoryNodeEdge {
  cursor: string;
  node: ProductSubCategoryNode;
}

export interface ProductSubCategoryNodeConnection {
  pageInfo: PageInfo;
  edges: ProductSubCategoryNodeEdge[];
}

export const productSubCategoryObject: ProductSubCategoryNode = {
  name: '',
  category: null,
  categoryId: null,
  companyId: null,
};

export const productSubCategoriesQuery = `
  query ProductSubCategoriesList($companyId: ID, $after: String) {
    allProductSubCategories(first: 20, companyId: $companyId, orderBy: "-id", after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export interface AddProductSubCategoryVariables {
  input: {
    subCategory: ProductSubCategoryNode;
  };
}

export interface AddProductSubCategoryPayload {
  newSubCategory: string;
}

export const productSubCategoryMutation = `
mutation SubCategory($input: CreateProductSubCategoryInput!){
  createProductSubCategory(input: $input) {
    newSubCategory
  }
}
`;

import * as React from 'react';
import { Icon, Menu } from 'antd';
import { Feature, SideBarMenu } from '../../constants';
import { RouterProps } from 'react-router';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';

export const InventoryLink = (props: Props) => {
  const inventory: SideBarMenu = {
    [Feature.OPENING_STOCK]: (
      <Menu.Item
        key="17"
        onClick={() => props.history.push('/dashboard/opening_stock')}
      >
        <Icon type="mail" />
        <span>Opening Stock</span>
      </Menu.Item>
    ),
    [Feature.SALE]: (
      <Menu.Item
        key="18"
        onClick={() => props.history.push('/dashboard/sales')}
      >
        <Icon type="export" />
        <span>
          {props.bussiness?.type === 'branch'
            ? 'Sales'
            : props.bussiness?.type === 'company' && 'Sales Report'}
        </span>
      </Menu.Item>
    ),
    [Feature.VALUE_ADDITION]: (
      <Menu.Item
        key="19"
        onClick={() => props.history.push('/dashboard/value_addition')}
      >
        <Icon type="export" />
        <span>Value Addition</span>
      </Menu.Item>
    ),
    [Feature.PURCHASE]: (
      <Menu.Item
        key="20"
        onClick={() => props.history.push('/dashboard/purchase')}
      >
        <Icon type="shopping-cart" />
        <span>
          {props.company?.name === 'Jharcraft' ||
          props.company?.name.toLowerCase().includes('khadi')
            ? 'List Purchase'
            : props.company?.name.toLowerCase().includes('seva sadan')
            ? 'Stock In'
            : 'Purchase'}
        </span>
      </Menu.Item>
    ),
    [Feature.PURHCASE_ORDER]: (
      <Menu.Item
        key="21"
        onClick={() => props.history.push('/dashboard/purchase_order')}
      >
        <Icon type="solution" />
        <span>Purchase Order</span>
      </Menu.Item>
    ),
    [Feature.PURCHASE_ORDER_REPORT]: (
      <Menu.Item
        key="22"
        onClick={() => props.history.push('/dashboard/purchase_order_report')}
      >
        <span>Purchase Order Report</span>
      </Menu.Item>
    ),
    [Feature.PURCHASE_RETURN]: (
      <Menu.Item
        key="23"
        onClick={() => props.history.push('/dashboard/purchase_return')}
      >
        <Icon type="shopping-cart" />
        <span>Purchase Return</span>
      </Menu.Item>
    ),
    [Feature.CHALLAN]: (
      <Menu.Item
        key="24"
        onClick={() => props.history.push('/dashboard/challan')}
      >
        <span>
          {props.company?.name.toLowerCase().includes('jharcraft') ||
          props.company?.name.toLowerCase().includes('khadi')
            ? 'Goods Received Challan'
            : 'Challan'}
        </span>
      </Menu.Item>
    ),
    [Feature.MARGIN]: (
      <Menu.Item
        key="25"
        onClick={() => props.history.push('/dashboard/margin')}
      >
        <span>Set Margin</span>
      </Menu.Item>
    ),
    [Feature.TRANSFER]: (
      <Menu.Item
        key="26"
        onClick={() =>
          props.history.push(
            `/dashboard/transfer/${
              props.company?.name
                .toLowerCase()
                .includes('seva sadan' || 'tiles')
                ? 'to_department'
                : 'to_branch'
            }`,
          )
        }
      >
        <Icon type="to-top" />
        <span>
          {props.bussiness?.type === 'branch' &&
          props.bussiness?.category &&
          ['FINISHING_UNIT', 'REGIONAL_OFFICE'].indexOf(
            props.bussiness?.category,
          ) > -1
            ? 'Transfer For Product With Barcode'
            : 'Transfer'}
        </span>
      </Menu.Item>
    ),
    [Feature.RECEIVING]: (
      <Menu.Item
        key="27"
        onClick={() => props.history.push('/dashboard/receiving')}
      >
        <Icon type="vertical-align-bottom" />
        <span>
          {props.bussiness?.type === 'branch' &&
          props.bussiness?.category &&
          ['FINISHING_UNIT', 'REGIONAL_OFFICE'].indexOf(
            props.bussiness?.category,
          ) > -1
            ? 'Receiving For Product With Barcode'
            : 'Receiving'}
        </span>
      </Menu.Item>
    ),
    [Feature.DAMAGE]: (
      <Menu.Item
        key="28"
        onClick={() => props.history.push('/dashboard/damage')}
      >
        <Icon type="mail" />
        <span>Damage</span>
      </Menu.Item>
    ),
    [Feature.CODE_CONVERSION]: (
      <Menu.Item
        key="29"
        onClick={() => props.history.push('/dashboard/code_conversion')}
      >
        <Icon type="reload" />
        <span>Code Conversion</span>
      </Menu.Item>
    ),
    [Feature.STOCK_REPORT]: (
      <Menu.Item
        key="30"
        onClick={() => props.history.push('/dashboard/stock_report')}
      >
        <Icon type="stock" />
        <span>Stock Report</span>
      </Menu.Item>
    ),
    [Feature.SALE_INVOICE]: (
      <Menu.Item
        key="31"
        onClick={() => props.history.push('/dashboard/sale_invoice')}
      >
        <Icon type="vertical-align-bottom" />
        <span>Sale Invoice</span>
      </Menu.Item>
    ),
    [Feature.APPROVAL]: (
      <Menu.Item
        key="32"
        onClick={() => props.history.push('/dashboard/approval')}
      >
        <Icon type="file-done" />
        <span>Approval</span>
      </Menu.Item>
    ),
    [Feature.GIFT]: (
      <Menu.Item key="33" onClick={() => props.history.push('/dashboard/gift')}>
        <Icon type="gift" />
        <span>Gift</span>
      </Menu.Item>
    ),
    [Feature.DEPOSIT]: (
      <Menu.Item
        key="34"
        onClick={() => props.history.push('/dashboard/deposit')}
      >
        <Icon type="upload" />
        <span>Deposit</span>
      </Menu.Item>
    ),
    [Feature.REQUEST]: (
      <Menu.Item
        key="35"
        onClick={() => props.history.push('/dashboard/request')}
      >
        <Icon type="mail" />
        <span>Request</span>
      </Menu.Item>
    ),
    [Feature.DELIVERY_INSTRUCTION]: (
      <Menu.Item
        key="36"
        onClick={() => props.history.push('/dashboard/delivery_instruction')}
      >
        <Icon type="shopping-cart" />
        <span>Delivery Instruction</span>
      </Menu.Item>
    ),
    [Feature.ISSUE]: (
      <Menu.Item
        key="37"
        onClick={() => props.history.push('/dashboard/production/issue')}
      >
        <Icon type="to-top" />
        <span>Issue</span>
      </Menu.Item>
    ),
    [Feature.MEMO]: (
      <Menu.Item key="38" onClick={() => props.history.push('/dashboard/memo')}>
        <Icon type="read" />
        <span>Memo</span>
      </Menu.Item>
    ),
  };

  return inventory;
};

interface Props extends RouterProps {
  company: CompanyNodeEdge | null;
  bussiness: Bussiness | null;
}

import * as React from 'react';
import { Tabs, Icon, Skeleton, Table, Row, Col, DatePicker } from 'antd';
import { IssueNodeConnection, IssueNodeEdge } from '../../../schema';
import { CompanyService } from '../../../service/CompanyService';
import { AddIssue } from './AddIssue';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { issueQuery } from './constants';
import { Pagination } from 'sha-el-design/lib';
import moment from 'moment';
import { checkAddPermission } from '../../helper';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';
export class Issue extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedIssue: null,
      company: null,
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedIssue: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  issueColumn = () => [
    {
      title: 'Invoice No',
      dataIndex: 'node.billNo',
      key: 'billNo',
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ selectedIssue: record, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Remarks',
      dataIndex: 'node.remarks',
      key: 'remarks',
    },
  ];

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }
    const {
      company,
      after,
      currentPage,
      activeKey,
      startDate,
      endDate,
      selectedIssue,
    } = this.state;
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Issue List
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={issueQuery}
            variables={{
              companyId: company.id,
              after,
              activeKey,
              date_Gte: startDate,
              date_Lte: endDate,
            }}
            render={(
              response: { allIssues: IssueNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allIssues.edges}
                    columns={this.issueColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allIssues.pageInfo.endCursor,
                        response.allIssues.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Issue
              </span>
            }
            key="2"
          >
            <AddIssue
              issue={selectedIssue?.node}
              handleTabChange={this.handleTabChange}
              company={company}
            />
          </Tabs.TabPane>
        )}
        {/* <Tabs.TabPane tab={<span><Icon type='table' />Issue Report</span>} key='3'>
          <IssueReport
            company={company}
          />
        </Tabs.TabPane> */}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedIssue: IssueNodeEdge | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import { RequestInput, RequestNode } from '../../schema';
import {
  RequestError,
  requestObject,
  requestError,
  locationObjForGroup,
} from './constants';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { RequestForm } from './RequestForm';
import { Button, notification, Popconfirm } from 'antd';
import { RequestService } from '../../service/RequestService';
import { User } from '../user/constants';
import { isNullOrUndefined } from 'util';

export class AddRequest extends React.Component<Props, State> {
  requestService = new RequestService();

  constructor(props: Props) {
    super(props);

    this.state = {
      values: this.handlePropsValuesUpdate(props),
      error: requestError,
      buttonDisabled: false,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ values: this.handlePropsValuesUpdate(nextProps) });
  }

  handlePropsValuesUpdate = (props: Props) => {
    const values: RequestInput = props.selectedRequest
      ? {
          ...props.selectedRequest,
          branchId: props.selectedRequest.branch?.id,
          companyId: null,
        }
      : JSON.parse(JSON.stringify(requestObject));
    return values;
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState: State = { ...this.state };
    nextState.values[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  checkError = () => {
    const values = { ...this.state.values };
    const error = { ...this.state.error };
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !values[errKey] &&
        (errKey === 'isAvailable'
          ? values[errKey] !== false &&
            this.props.business.type === 'company' &&
            this.props.user?.user?.groups?.[0]?.name === 'Inventory'
          : true)
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      }
    });
    this.setState({ error });
    return isError;
  };

  saveRequest = () => {
    if (this.checkError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const userGroup = this.props.user?.user?.groups?.[0]?.name;
    this.requestService?.AddRequest(
      {
        ...this.state.values,
        currentLocation: this.getNextLocation(
          this.props.business.type,
          userGroup,
        )
          ?.split(' ')
          .reverse()[0]
          .toUpperCase(),
      },
      (response) => {
        notification.success({
          message: 'Request ' + this.state.values.id ? 'Updated' : 'Added',
          description: `Request was successfully forwarded`,
        });
        this.props.handleTabChange?.('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Request ' + this.state.values.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      values: JSON.parse(JSON.stringify(requestObject)),
      error: JSON.parse(JSON.stringify(requestError)),
      buttonDisabled: false,
    });
  };

  getCaptionForSubmit = () => {
    const userGroup = this.props.user?.user?.groups?.[0]?.name;
    if (userGroup === 'Production') {
      return 'Submit';
    }
    return `${
      userGroup === 'Inventory' ? 'Revert to ' : 'Forward to '
    }${this.getNextLocation(this.props.business.type, userGroup)}`;
  };

  getNextLocation = (
    businessType: 'branch' | 'company',
    userGroup: 'Inventory' | 'Marketing' | 'Production',
  ) => {
    if (businessType === 'branch') {
      return 'Marketing';
    }
    switch (userGroup) {
      case 'Inventory':
        return 'Marketing';

      case 'Marketing':
        return isNullOrUndefined(this.props.selectedRequest?.isAvailable)
          ? 'Central Store'
          : 'Production';
    }
  };

  render() {
    return (
      <div>
        <RequestForm
          values={this.state.values}
          error={this.state.error}
          onChange={this.onChange}
          isEditActive={this.props.isEditActive}
          user={this.props.user}
          business={this.props.business}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={() => {
            this.props.isEditActive ? {} : this.clear();
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="dashed"
            children="Clear"
            style={{ width: '50%', marginTop: '40px' }}
          />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveRequest}
          children={this.getCaptionForSubmit()}
          style={{ width: '50%', marginTop: '40px' }}
          disabled={
            this.state.buttonDisabled ||
            this.props.user?.user?.groups?.[0]?.name === 'Production'
          }
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business: Bussiness;
  selectedRequest?: RequestNode;
  isEditActive: boolean;
  handleTabChange?: (key) => void;
  user: User;
}

interface State {
  values: RequestInput;
  error: RequestError;
  buttonDisabled: boolean;
}

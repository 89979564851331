import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import {
  RemunerationNodeConnection,
  RemunerationNodeEdge,
} from '../../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../../master/Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  remuneration?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

export class RemunerationDropDown extends React.Component<
  AutoCompleteProps,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      company: null,
      touched: false,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  getName = (remuneration?: RemunerationNodeEdge) => {
    if (!remuneration) {
      return;
    }
    return remuneration.node?.name;
  };

  value = (remunerations: RemunerationNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    return this.getName(
      remunerations.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { name, company } = this.state;
    const { value, onSelect } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query RemunerationDropDown($after: String!, $id: ID, $name: String, $companyId: ID) {
          allRemunerations(first: 10, after: $after, id: $id, name_Icontains: $name, companyId: $companyId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }`}
        variables={{
          after: '',
          name: this.state.name,
          companyId: company.id,
          id: value && !name ? value : '',
        }}
        render={(
          response: { allRemunerations: RemunerationNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Remuneration"
                dataSource={response.allRemunerations.edges.map(
                  (remuneration) => (
                    <Option
                      key={remuneration.node?.id}
                      display={this.getName(remuneration)}
                      label={remuneration.node}
                      remuneration={remuneration}
                    >
                      <div
                        style={{
                          borderBottom: '1px solid #555',
                          fontWeight: 'bolder',
                        }}
                      >
                        {remuneration.node?.name}
                      </div>
                    </Option>
                  ),
                )}
                value={this.value(response.allRemunerations.edges)}
                optionLabelProp="display"
                style={{ width: '100%' }}
                onSearch={(remuneration) =>
                  this.setState({ name: remuneration, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    name: this.getName((f as any).props.remuneration) || '',
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  name: string;
  company: CompanyNodeEdge | null;
  touched: boolean;
}

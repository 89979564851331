import * as React from 'react';
import { ProductNodeEdge, ProductError } from './constants';
import { TSInput } from '../../common/TSInput';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { UnitEdges } from '../Unit/constants';
import { CompanyNodeEdge } from '../Company/constants';
import { Label } from '../../common/TSLabel';
import { Select } from 'antd';
import { HsnDropDown } from '../Hsn/HsnDropdown';
import { ProductSubCategoryDropdown } from '../ProductSubCategories/ProductSubCategoryDropdown';
import { VendorDropdown } from '../vendor/VendorDropdown';
import { DiscountCategoryDropdown } from '../DiscountCategory/DiscountCategoryDropdown';

export const ProductForm: React.FunctionComponent<Props> = (props) => {
  const isKhadi = props.company?.name.toLowerCase().includes('khadi');
  return (
    <div>
      {props.isViewActive ? (
        <TSInput
          value={props.values.category as any}
          error={''}
          addonBefore="Category"
          type="text"
        />
      ) : (
        props.categories && (
          <TSAutoComplete
            error={props.error?.categoryId || ''}
            inputProps={{ name: 'categoryId', addonBefore: 'Category' }}
            dataSource={props.categories}
            key="id"
            name="categoryId"
            onValueSelect={props.onChange}
            displayKey="name"
            textValue={getValue(props.categories, props.values.categoryId)}
            allowClear
          />
        )
      )}
      {(props.company.name === 'Jharcraft' ||
        props.company?.name.toLowerCase().includes('khadi')) &&
        (props.isViewActive ? (
          <TSInput
            value={props.values.subCategory?.name}
            error={''}
            addonBefore="Sub Category"
            type="text"
          />
        ) : (
          <Label title="Sub Category">
            <ProductSubCategoryDropdown
              onSelect={(e, f) => {
                props.isViewActive
                  ? () => {}
                  : props.onChange({
                      target: { name: 'subCategoryId', value: String(e) },
                    });
              }}
              value={props.values.subCategoryId || ''}
              companyId={props.company.id || ''}
              categoryId={props.values.categoryId || ''}
              disabled={!props.values.categoryId}
              allowClear
            />
          </Label>
        ))}
      <TSInput
        error={props.isViewActive ? '' : props.error?.oldBarcodeId || ''}
        value={props.values.oldBarcodeId}
        onChange={props.onChange}
        name="oldBarcodeId"
        type="text"
        addonBefore="Old Barcode Id"
      />
      <TSInput
        error={''}
        value={props.values.design}
        onChange={props.onChange}
        name="design"
        type="text"
        addonBefore="Design"
      />
      <TSInput
        error={''}
        value={props.values.dimension}
        onChange={props.onChange}
        name="dimension"
        type="text"
        addonBefore="Dimension"
      />
      <TSInput
        error={props.isViewActive ? '' : props.error?.newBarcodeId || ''}
        value={props.values.newBarcodeId}
        onChange={props.onChange}
        name="newBarcodeId"
        type="text"
        addonBefore="New Barcode Id"
        // disabled={!props.values.id}
      />
      <TSInput
        error={props.isViewActive ? '' : props.error?.name || ''}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      {props.isViewActive ? (
        <TSInput
          value={props.values.unit as any}
          error={''}
          addonBefore="Unit"
          type="text"
        />
      ) : (
        props.units && (
          <TSAutoComplete
            error={props.error?.unitId || ''}
            inputProps={{ name: 'unitId', addonBefore: 'Unit' }}
            dataSource={props.units}
            key="id"
            name="unitId"
            onValueSelect={props.onChange}
            displayKey="name"
            textValue={getValue(props.units, props.values.unitId)}
            allowClear
          />
        )
      )}
      <TSInput
        error={props.isViewActive ? '' : props.error?.colour || ''}
        value={props.values.colour}
        onChange={props.onChange}
        name="colour"
        type="text"
        addonBefore="Colour"
      />
      <TSInput
        error={props.isViewActive ? '' : props.error?.purchaseRate || ''}
        value={props.values.purchaseRate || ''}
        onChange={props.onChange}
        name="purchaseRate"
        type="number"
        addonBefore="Purchase Rate"
      />
      <TSInput
        error={props.isViewActive ? '' : props.error?.sellingRate || ''}
        value={props.values.sellingRate || ''}
        onChange={props.onChange}
        name="sellingRate"
        type="number"
        addonBefore="Selling Rate"
      />
      <div>
        <Label title="HSN">
          <HsnDropDown
            onSelect={(e, f) => {
              props.isViewActive
                ? () => {}
                : props.onChange({
                    target: { name: 'hsnId', value: String(e) },
                  });
            }}
            value={props.values.hsnId || ''}
            allowClear
          />
        </Label>
        <span style={{ color: 'red' }}>{props.error?.hsnId}</span>
      </div>
      {(props.company.name === 'Jharcraft' ||
        props.company?.name.toLowerCase().includes('khadi')) && (
        <div>
          <TSInput
            error={props.isViewActive ? '' : props.error?.division || ''}
            value={props.values.division}
            onChange={props.onChange}
            name="division"
            type="text"
            addonBefore="Division"
          />
          <TSInput
            error={props.isViewActive ? '' : props.error?.section || ''}
            value={props.values.section}
            onChange={props.onChange}
            name="section"
            type="text"
            addonBefore="Section"
          />
          <TSInput
            error={props.isViewActive ? '' : props.error?.materialType || ''}
            value={props.values.materialType}
            onChange={props.onChange}
            name="materialType"
            type="text"
            addonBefore="Material Type"
          />
          <div>
            <Label title="Product Type">
              <Select
                defaultValue={null}
                style={{ width: '100%' }}
                value={props.values.productType}
                onChange={(value) =>
                  props.onChange({
                    target: {
                      name: 'productType',
                      value: value && value.toString(),
                    },
                  })
                }
              >
                <Select.Option value={''}>{}</Select.Option>
                <Select.Option value="FINISHED">FINISHED</Select.Option>
                <Select.Option value="SEMI_FINISHED">
                  SEMI_FINISHED
                </Select.Option>
                <Select.Option value="RAW_MATERIAL">RAW_MATERIAL</Select.Option>
                {isKhadi && (
                  <Select.Option value="GRAMUDYOG">GRAMUDYOG</Select.Option>
                )}
                {isKhadi && (
                  <Select.Option value="WOOLLEN">WOOLLEN</Select.Option>
                )}
                {isKhadi && (
                  <Select.Option value="FABRIC">FABRIC</Select.Option>
                )}
                {isKhadi && (
                  <Select.Option value="READYMADE">READYMADE</Select.Option>
                )}
                {isKhadi && (
                  <Select.Option value="BLANKET">BLANKET</Select.Option>
                )}
              </Select>
            </Label>
            <span style={{ color: 'red' }}>{props.error?.productType}</span>
          </div>
        </div>
      )}

      {props.company.name.toLowerCase().includes('tiles') && (
        <div>
          <Label title="Product Type">
            <Select
              defaultValue={null}
              style={{ width: '100%' }}
              value={props.values.productType}
              onChange={(value) =>
                props.onChange({
                  target: {
                    name: 'productType',
                    value: value && value.toString(),
                  },
                })
              }
            >
              <Select.Option value={''}>{}</Select.Option>
              <Select.Option value="FINISHED">FINISHED</Select.Option>
              <Select.Option value="SEMI_FINISHED">SEMI_FINISHED</Select.Option>
              <Select.Option value="RAW_MATERIAL">RAW_MATERIAL</Select.Option>
            </Select>
          </Label>
          <span style={{ color: 'red' }}>{props.error?.productType}</span>
        </div>
      )}

      {(props.company.name.includes('Seva Sadan') ||
        props.company.name.toLowerCase().includes('tiles')) && (
        <TSInput
          error={''}
          value={props.values.reorderLevel || 0}
          onChange={props.onChange}
          name="reorderLevel"
          type="number"
          addonBefore="Reorder Level"
        />
      )}

      {props.company.name.toLowerCase().includes('tiles') && (
        <TSInput
          error={''}
          value={props.values.quantityPerPiece || 0}
          onChange={props.onChange}
          name="quantityPerPiece"
          type="number"
          addonBefore="Quantity Per Piece"
        />
      )}

      {(props.company.name === 'Jharcraft' ||
        props.company?.name.toLowerCase().includes('khadi')) && (
        <>
          {props.isViewActive ? (
            <TSInput
              value={props.values.vendor?.name}
              error={''}
              addonBefore="Vendor/ Society"
              type="text"
            />
          ) : (
            <Label title="Vendor/ Society">
              <VendorDropdown
                onSelect={(e, f) => {
                  props.isViewActive
                    ? () => {}
                    : props.onChange({
                        target: { name: 'vendorId', value: String(e) },
                      });
                }}
                value={props.values.vendorId || ''}
                companyId={props.company.id || ''}
                allowClear
              />
            </Label>
          )}
          <TSInput
            error={''}
            value={props.values.waiver}
            onChange={props.onChange}
            name="waiver"
            type="text"
            addonBefore="Waiver"
          />
        </>
      )}
      {props.company?.name.toLowerCase().includes('sugar') && (
        <Label title="Discount Category">
          <DiscountCategoryDropdown
            onSelect={(e) => {
              props.onChange({
                target: { name: 'discountCategoryId', value: String(e) },
              });
            }}
            value={props.values.discountCategoryId || ''}
            companyId={props.company.id || ''}
            allowClear
          />
        </Label>
      )}
    </div>
  );
};

interface Props {
  values: ProductNodeEdge;
  error?: ProductError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  categories?: ProductCategoryEdges[];
  units?: UnitEdges[];
  isViewActive: boolean;
  company: CompanyNodeEdge;
}

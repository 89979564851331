import * as React from 'react';
import { BranchNodeEdge, BranchEdges, category } from './constants';
import { Table, Tabs, Icon, Spin, Row, Col, Button, Select } from 'antd';
import { AddBranch } from './AddBranch';
import { checkAddPermission } from '../../helper';
import { BranchService } from '../../../service/BranchService';
import { UserService } from '../../../service/UserService';
import { Bussiness, CompanyNodeEdge } from '../Company/constants';
import { CompanyService } from '../../../service/CompanyService';
import { RouteComponentProps } from 'react-router';
import { TSInput } from '../../common/TSInput';
import { downloadToFile } from '../../helper';
import { UserNodeEdge } from '../../user/constants';
import { ColumnProps } from '../../common/Table';

export class Branch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      branch: [],
      activeKey: '1',
      selectedBranch: null,
      company: null,
      bussiness: null,
      searchBranch: '',
      selectedCategory: '',
      user: null,
    };
  }

  branchService = new BranchService();
  companyService = new CompanyService();
  userService = new UserService();

  branch: {
    cursor: string;
    node: BranchNodeEdge;
  }[] = [];

  componentDidMount() {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }, () => {
        this.branch = branch;
      }),
    );
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((bussiness) =>
      this.setState({ bussiness }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  downloadCsv = () => {
    const branch = this.state.branch.filter((e) => {
      if (this.state.selectedCategory) {
        return e.node.category === this.state.selectedCategory;
      }
      return e;
    });
    const data = branch
      .map((e) =>
        this.branchColumn()
          .map((col) => {
            return col.sortingValue?.(e, e);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.branchColumn()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    this.state.company?.name &&
      downloadToFile(
        csv,
        `location-report-${new Date().toISOString()}`,
        'LOCATION REPORT',
        this.state.company.name,
        '',
        '',
        '',
        this.state.selectedCategory,
      );
  };

  onSearch = (value: string) => {
    const newBranch = this.branch.filter((element) =>
      element.node.name?.toLowerCase().includes(value.toLowerCase()),
    );
    this.setState({
      branch: newBranch,
      searchBranch: value,
    });
  };

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedBranch: null });
      return;
    }
    this.setState({ activeKey });
  };

  branchColumn = () => {
    const branchColumns: ColumnProps<BranchEdges>[] = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'node.name',
        render: (text, obj: BranchEdges) => (
          <a
            onClick={() => {
              this.setState({ selectedBranch: obj, activeKey: '2' });
            }}
          >
            {text}
          </a>
        ),
        sortingValue: (text, obj: BranchEdges) => obj.node.name || '',
      },
    ];
    if (this.state.company?.name.toLowerCase().includes('khadi')) {
      branchColumns.push({
        title: 'Initials',
        key: 'initials',
        render: (text, obj: BranchEdges) => obj.node.initials,
        sortingValue: (text, obj: BranchEdges) => obj.node.initials || '',
      });
    }
    branchColumns.push(
      {
        title: 'Address',
        key: 'address',
        render: (text, obj: BranchEdges) => obj.node.address,
        sortingValue: (text, obj: BranchEdges) => obj.node.address || '',
      },
      {
        title: 'State',
        key: 'state',
        render: (text, obj: BranchEdges) => obj.node.state,
        sortingValue: (text, obj: BranchEdges) => obj.node.state || '',
      },
      {
        title: 'Contact No',
        key: 'contactNo',
        render: (text, obj: BranchEdges) => obj.node.contactNo,
        sortingValue: (text, obj: BranchEdges) => obj.node.contactNo || '',
      },
      {
        title: 'Email',
        key: 'email',
        render: (text, obj: BranchEdges) => obj.node.email,
        sortingValue: (text, obj: BranchEdges) => obj.node.email || '',
      },
      {
        title: 'Incharge',
        key: 'incharge',
        render: (text, obj: BranchEdges) => obj.node.incharge,
        sortingValue: (text, obj: BranchEdges) => obj.node.incharge || '',
      },
      {
        title: 'GST IN',
        key: 'gstIn',
        render: (text, obj: BranchEdges) => obj.node.gstin,
        sortingValue: (text, obj: BranchEdges) => obj.node.gstin || '',
      },
    );
    if (
      this.props.match.params.branchType !== 'department' &&
      !this.state.company?.name.toLocaleLowerCase().includes('ambalika')
    ) {
      branchColumns.push({
        title: 'Is Head Office',
        key: 'isHeadOffice',
        render: () => (this.state.bussiness?.type === 'company' ? 'Yes' : 'No'),
        sortingValue: (text, record) =>
          record.node.isHeadOffice ? 'Yes' : 'No',
      });

      if (
        this.state.company?.name.toLowerCase() === 'jharcraft' ||
        this.state.company?.name.toLowerCase().includes('khadi')
      ) {
        branchColumns.push(
          {
            title: 'Category',
            key: 'category',
            render: (text, obj: BranchEdges) => obj.node.category,
            sortingValue: (text, obj: BranchEdges) => obj.node.category || '',
          },
          {
            title: 'Parent Category',
            key: 'parent Category',
            render: (text, obj: BranchEdges) => obj.node.parentCategory,
            sortingValue: (text, obj: BranchEdges) =>
              obj.node.parentCategory || '',
          },
          {
            title: 'Parent Location',
            key: 'parentLocation',
            render: (text, obj: BranchEdges) => obj.node.parentLocation?.name,
            sortingValue: (text, obj: BranchEdges) =>
              obj.node.parentLocation?.name || '',
          },
        );
      }
    }
    if (this.state.company?.name.toLowerCase().includes('khadi')) {
      branchColumns.push({
        title: 'Favour Of',
        key: 'favourOf',
        render: (text, obj: BranchEdges) => obj.node.favourOf,
        sortingValue: (text, obj: BranchEdges) => obj.node.favourOf || '',
      });
    }
    return branchColumns;
  };

  render() {
    if (!this.state.company) {
      return <Spin />;
    }
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              {this.props.match.params.branchType === 'department'
                ? 'List Department'
                : 'List Locations'}
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.searchBranch}
                onChange={(e) => this.onSearch(e.target.value)}
                name="searchBranch"
                type="text"
                addonBefore="Search Branch"
                placeholder="Enter Branch"
              />
            </Col>
            <Col span={8}>
              <Select
                style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
                placeholder="Select Category"
                value={this.state.selectedCategory || undefined}
                onChange={(e) => this.setState({ selectedCategory: e })}
                showSearch
                allowClear
              >
                {category.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value.split('_').join(' ')}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Table
            dataSource={this.state.branch.filter((e) => {
              if (this.state.selectedCategory) {
                return e.node.category === this.state.selectedCategory;
              }
              return e;
            })}
            columns={this.branchColumn()}
            rowKey={(record) => record.node.id || ''}
          />
          <Button
            style={{ float: 'right' }}
            icon="download"
            onClick={() => this.downloadCsv()}
            key="button"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              {this.props.match.params.branchType === 'department'
                ? 'Add Department'
                : 'Add Location'}
            </span>
          }
          key="2"
        >
          <AddBranch
            branch={this.state.selectedBranch?.node}
            handleTabChange={this.handleTabChange}
            company={this.state.company}
            branchType={this.props.match.params.branchType}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  branch: BranchEdges[];
  searchBranch: string;
  selectedCategory: string;
  activeKey: string;
  selectedBranch: BranchEdges | null;
  company: CompanyNodeEdge | null;
  bussiness: Bussiness | null;
  user?: UserNodeEdge | null;
}

type Props = RouteComponentProps<{ branchType: 'branch' | 'department' }>;

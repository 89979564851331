import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  QuotationInput,
  CreateQuotationPayload,
  CreateQuotationInput,
} from '../schema';
import { quotationMutation } from '../components/Quotation/constants';

export class QuotationService {
  static instance;

  constructor() {
    if (QuotationService.instance) {
      return QuotationService.instance;
    } else {
      QuotationService.instance = this;
    }
  }

  companyService = new CompanyService();

  addQuotation(
    data: QuotationInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete (pd as any).product;
      delete (pd as any).movementFor;
      return pd;
    });
    data.companyId = this.companyService?.company$.getValue()?.id;

    Api.graphql<{ input: CreateQuotationInput }, CreateQuotationPayload>(
      API_URL,
      {
        input: {
          quotation: {
            ...data,
          },
        },
      },
      quotationMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import * as React from 'react';
import {
  Tabs,
  Icon,
  Skeleton,
  Row,
  Col,
  DatePicker,
  Table,
  Tooltip,
  Popconfirm,
  Button,
  Spin,
  notification,
} from 'antd';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { saleChallanQuery } from './constants';
import {
  SaleChallanNode,
  SaleChallanNodeConnection,
  SaleChallanNodeEdge,
  SaleChallanInput,
  ProductDetailsNodeConnection,
  ProductDetailsMovementType,
} from '../../schema';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import { AddSaleInvoice } from './AddSaleInvoice';
import { SalesInvoiceReceiptHindustanTiles } from './SalesInvoiceReceiptHindustanTiles';
import { CreditSaleInvoiceReport } from './CreditSaleInvoiceReport';
import { InvoiceCreditPaymentReport } from './InvoiceCreditPaymentReport';
import { GatePassReceipt } from './GatePassReceipt';
import { SaleInvoiceService } from '../../service/SaleInvoiceService';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';

export class SaleInvoice extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedChallan: null,
      company: null,
      currentPage: 1,
      after: '',
      startDate:
        moment().get('month') < 3
          ? `${moment().subtract(1, 'year').format('YYYY')}-04-01`
          : `${moment().get('year')}-04-01`,
      endDate: moment().format('YYYY-MM-DD'),
      updateSalesList: false,
      loading: {},
      remarks: {},
      user: null,
    };
  }

  saleChallanService = new SaleInvoiceService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) => this.setState(user));
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedChallan: null, currentPage: 1 });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  challanColumn = () => [
    {
      title: 'Date Received',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Invoice No',
      dataIndex: 'node.challanNo',
      key: 'challanNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedChallan: obj.node, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Delivery Instruction No',
      dataIndex: 'node.deliveryInstruction.deliveryInstructionNo',
      key: 'deliveryInstruction',
    },
    {
      title: 'customer',
      dataIndex: 'node.deliveryInstruction.customer.name',
      key: 'customer',
    },
    {
      title: 'Gate Pass',
      key: 'gatePass',
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ selectedChallan: record.node, activeKey: '4' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record, index) => (
        <div>
          <Tooltip title="Cancel">
            <Popconfirm
              title="Are you sure to cancel?"
              onConfirm={() =>
                this.cancelSaleInvoice(
                  {
                    ...record,
                    node: {
                      ...record.node,
                      remarks: this.state.remarks[index],
                    },
                  },
                  index,
                )
              }
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                children={
                  this.state.loading && this.state.loading[index] ? (
                    <Spin />
                  ) : (
                    <Icon type="close" />
                  )
                }
                disabled={record.node.cancelled}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Invoice">
            <Button
              onClick={() => {
                this.setState({ selectedChallan: record.node, activeKey: '3' });
              }}
              style={{ marginLeft: '5px' }}
            >
              <Icon type="download" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  cancelSaleInvoice = (saleInvoiceEdge: SaleChallanNodeEdge, index: number) => {
    const loading = {};
    loading[index] = true;
    this.setState({ loading });
    const saleInvoice: SaleChallanInput = JSON.parse(
      JSON.stringify(saleInvoiceEdge.node),
    );
    saleInvoice.cancelled = true;
    saleInvoice.challanNo += '-C';
    saleInvoice.deliveryInstructionId = (
      saleInvoice as any as SaleChallanNode
    ).deliveryInstruction?.id;
    const productDetails: ProductDetailsNodeConnection = (
      saleInvoice as any as SaleChallanNode
    ).productDetails as any;
    saleInvoice.productDetails = productDetails.edges.map((pd) => {
      return {
        ...pd.node,
        productId: pd.node?.product?.id,
        movementType: ProductDetailsMovementType.SALE_CHALLAN_CANCEL,
      };
    }) as any;
    this.saleChallanService?.addSaleChallan(
      saleInvoice,
      () => {
        this.setState({ loading: {}, updateSalesList: true });
        notification.success({
          message: 'Sale Invoice Cancelled',
          description: `Sale for invoice no ${saleInvoice.challanNo} was successfuly Cancelled`,
        });
      },
      (error) => {
        this.setState({ loading: {} });
        notification.error({
          message: 'Sale Invoice Cancellation Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }

    const {
      company,
      after,
      currentPage,
      activeKey,
      selectedChallan,
      startDate,
      endDate,
      updateSalesList,
    } = this.state;
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Sale Invoice
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={saleChallanQuery}
            variables={{
              companyId: company.id,
              after,
              activeKey,
              date_Gte: startDate,
              date_Lte: endDate,
              updateSalesList,
            }}
            render={(
              response: { allSaleChallans: SaleChallanNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (error) return <h3>Something Went Wrong!!</h3>;
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allSaleChallans.edges}
                    columns={this.challanColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allSaleChallans.pageInfo.endCursor,
                        response.allSaleChallans.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Sale Invoice
              </span>
            }
            key="2"
          >
            <AddSaleInvoice
              saleChallan={selectedChallan}
              handleTabChange={this.handleTabChange}
              company={company}
            />
          </Tabs.TabPane>
        )}
        {selectedChallan && activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Invioce
              </span>
            }
            key="3"
          >
            <PDFViewer style={{ width: '100%', height: '1000px' }}>
              <SalesInvoiceReceiptHindustanTiles
                saleChallan={selectedChallan}
                company={company}
              />
            </PDFViewer>
          </Tabs.TabPane>
        )}
        {selectedChallan && activeKey === '4' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Gate Pass
              </span>
            }
            key="4"
          >
            <PDFViewer style={{ width: '100%', height: '1000px' }}>
              <GatePassReceipt
                saleInvoice={selectedChallan}
                company={company}
              />
            </PDFViewer>
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Credit Sale Receipt
            </span>
          }
          key="5"
        >
          <CreditSaleInvoiceReport company={company} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Invoice Payment History Report
            </span>
          }
          key="6"
        >
          <InvoiceCreditPaymentReport company={company} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedChallan: SaleChallanNode | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  updateSalesList: boolean;
  loading: {
    [index: number]: boolean;
  };
  remarks: {
    [index: number]: string;
  };
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import { TSInput } from '../common/TSInput';
import { DatePicker, Divider, Row, Col, Button } from 'antd';
import moment from 'moment';
import { PurchaseNodeEdgeMutation } from '../Purchase/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { PurchaseNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { VendorEdges } from '../master/vendor/constants';
import { PurchaseDropDown } from '../Purchase/PurchaseDropdown';

export const PurchaseReturnForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Col span={5}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={18}>
        <PurchaseDropDown
          onSelect={(e, f) => {
            props.purchaseObjChangehandler((f as any).props.label);
          }}
          extrafields={[
            `productDetails {
              edges {
                cursor
                node {
                  id
                  product {
                    id
                    name
                    oldBarcodeId
                    newBarcodeId
                  }
                  rate
                  purchaseRate
                  quantity
                  discount
                  gstRate
                  amount
                  movementType
                  movementFor {
                    id
                  }
                }
              }
            }`,
            `vendor {
              id
            }`,
            `vendorInvoiceDate`,
            'vendorInvoiceNo',
          ]}
          allowClear
          company={props.company}
          value={props.values.purchaseToReturnId || ''}
        />
      </Col>
      <Col span={8}>
        <TSAutoComplete
          error=""
          inputProps={{ name: 'vendorId', placeholder: 'Vendor' }}
          dataSource={props.vendors}
          onValueSelect={() => {}}
          textValue={getValue(props.vendors, props.values.vendorId)}
          key="id"
          name="vendorId"
          displayKey="name"
          allowClear
        />
      </Col>
      <Col span={10}>
        <TSInput
          error=""
          value={props.values.vendorInvoiceNo || ''}
          name="vendorInvoiceNo"
          addonBefore="Vendor Invoice No"
        />
      </Col>
      <Col span={5}>
        <DatePicker
          value={
            props.values.vendorInvoiceDate
              ? moment(props.values.vendorInvoiceDate)
              : undefined
          }
          placeholder="Vendor Invoice Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product</Col>
        <Col span={4}>Quantity</Col>
        <Col span={4}>Pur. Rate</Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails?.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={8}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.productChangeHandler(
                    { target: { name: 'productId', value: String(e) } },
                    index,
                  );
                }}
                extrafields={['purchaseRate', 'sellingRate']}
                company={props.company}
                value={product.productId || ''}
                notEditable
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.productChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.purchaseRate || ''}
                name="purchaseRate"
                placeholder="Pur. Rate"
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updateProductsRow('-', index);
                }}
                disabled={props.values.productDetails?.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updateProductsRow('+', index);
                }}
                disabled={product.productId && product.quantity ? false : true}
              />
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

interface Props {
  values: PurchaseNodeEdgeMutation;
  company: CompanyNodeEdge;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  vendors: VendorEdges[];
  purchaseObjChangehandler: (value: PurchaseNode) => void;
}

import * as React from 'react';
import { DatePicker, Divider } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import Api from 'requestapijs';
import { RANGE_WISE_SALES_REPORT_URL, HEADER } from '../../config';
import { BranchNodeEdge } from '../master/Branch/constants';
import { ProductCategoryNodeEdge } from '../master/ProductCategories/constants';
import { ProductCategoryService } from '../../service/ProductCategoryService';
import { nestedAccess } from '../helper';
import { CompanyNodeEdge } from '../master/Company/constants';

export class RangeWiseSalesReportBranch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reportData: [],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      productCategories: [],
    };
  }

  productCategoryService = new ProductCategoryService();

  componentDidMount() {
    this.generateReport();
    this.productCategoryService
      ? (this.productCategoryService.orderBy = 'name')
      : '';
    this.productCategoryService?.productCategory$.subscribe(
      (productCategories) => this.setState({ productCategories }),
    );
  }

  componentWillReceiveProps() {
    this.generateReport();
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState, this.generateReport);
  };

  generateReport = () => {
    const nextState = { ...this.state };
    if (nextState.startDate && nextState.endDate) {
      const data = {
        company_id: this.props.company.id,
        branch_id: this.props.branch.id,
        start_date: nextState.startDate,
        end_date: nextState.endDate,
      };
      Api.post(RANGE_WISE_SALES_REPORT_URL, data, HEADER).subscribe(
        (response) => {
          this.setState({ reportData: response.response.data });
        },
      );
    }
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'node.name',
      sortingValue: (value) => value,
    },
    {
      title: '0-500',
      key: 'first',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '0-500', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '0-500', record.node.name) || 0,
    },
    {
      title: '501-1000',
      key: 'second',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '501-1000', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '501-1000', record.node.name) || 0,
    },
    {
      title: '1001-1500',
      key: 'third',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '1001-1500', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '1001-1500', record.node.name) || 0,
    },
    {
      title: '1501-2000',
      key: 'fourth',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '1501-2000', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '1501-2000', record.node.name) || 0,
    },
    {
      title: '2001-3000',
      key: 'fifth',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '2001-3000', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '2001-3000', record.node.name) || 0,
    },
    {
      title: '3001-5000',
      key: 'sixth',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '3001-5000', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '3001-5000', record.node.name) || 0,
    },
    {
      title: '5001-10000',
      key: 'seventh',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '5001-10000', record.node.name) ||
        0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '5001-10000', record.node.name) ||
        0,
    },
    {
      title: '> 10000',
      key: 'eighth',
      render: (value, record) =>
        nestedAccess(this.state.reportData, '10000<', record.node.name) || 0,
      sortingValue: (value, record) =>
        nestedAccess(this.state.reportData, '10000<', record.node.name) || 0,
    },
  ];

  render() {
    return (
      <div>
        <DatePicker
          format="DD-MM-YYYY"
          placeholder="Start Date"
          value={
            this.state.startDate
              ? moment(this.state.startDate)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(date) =>
            this.onChange({
              target: {
                name: 'startDate',
                value: date && moment(date).format('YYYY-MM-DD'),
              },
            })
          }
          disabledDate={(currentDate) => {
            if (!currentDate || !this.state.endDate) {
              return false;
            }
            return currentDate.valueOf() > moment(this.state.endDate).valueOf();
          }}
        />
        <DatePicker
          format="DD-MM-YYYY"
          placeholder="End Date"
          value={
            this.state.endDate
              ? moment(this.state.endDate)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(date) =>
            this.onChange({
              target: {
                name: 'endDate',
                value: date && moment(date).format('YYYY-MM-DD'),
              },
            })
          }
          disabledDate={(currentDate) => {
            if (!currentDate || !this.state.startDate) {
              return false;
            }
            return (
              currentDate.valueOf() < moment(this.state.startDate).valueOf()
            );
          }}
        />
        <Divider />
        <Table
          dataSource={this.state.productCategories}
          columns={this.columns()}
          rowKey="node.id"
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  productCategories: {
    cursor: string;
    node: ProductCategoryNodeEdge;
  }[];
  reportData: any;
}

import { ContractorInput } from '../../../schema';

export interface ContractorError {
  name: string;
  contactNo: string;
  tdsRate: string;
  serviceCharge: string;
}

export const contractorObject: ContractorInput = {
  name: '',
  address: '',
  contactNo: null,
  email: '',
  tdsRate: null,
  serviceCharge: null,
  companyId: null,
};

export const contractorErrorObject: ContractorError = {
  name: '',
  contactNo: '',
  tdsRate: '',
  serviceCharge: '',
};

export const contractorQuery = `
  query ContractorList($companyId: ID, $after: String, $name: String) {
    allContractor(companyId: $companyId, after: $after, first: 20, name_Icontains: $name) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          address
          contactNo
          email
          tdsRate
          serviceCharge
        }
      }
    }
  }
`;

export const contractorMutation = `
  mutation AddContractor(
    $input: CreateContractorInput!
  ) {
    createContractor(input: $input){
      newContractor {
        id
        name
      }
    }
  }
`;

import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { BranchNodeConnection } from '../../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface BranchDropDownProps extends AutoCompleteProps {
  extrafields: string[];
  companyId?: string;
}

export class BranchDropDown extends React.Component<
  BranchDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      company: null,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  render() {
    const { name, company } = this.state;
    const { extrafields } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query BranchDropDown($after: String!, $name: String, $companyId: ID) {
          allBranches(first: 10, after: $after, name_Icontains: $name, companyId: $companyId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }`}
        variables={{
          after: '',
          name: this.state.name,
          companyId: company.id,
        }}
        render={(
          response: { allBranches: BranchNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0', width: '100%' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Branch"
                dataSource={response.allBranches.edges.map((value) => (
                  <Option
                    key={value.node?.id}
                    display={value.node?.name}
                    label={value.node}
                  >
                    {value.node?.name}
                  </Option>
                ))}
                optionLabelProp="display"
                style={{ width: '100%' }}
                onSearch={(value) => this.setState({ name: value })}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  name: string;
  company: CompanyNodeEdge | null;
}

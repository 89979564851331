import * as React from 'react';
import { DatePicker, Divider, Button, Spin, Col } from 'antd';
import moment from 'moment';
import { Pagination } from 'sha-el-design';
import { Table } from '../../common/Table';
import { stockReportForProductsWithoutBarcode } from './constants';
import { BranchNodeEdge } from '../../master/Branch/constants';
import { downloadToFile } from '../../helper';
import { GraphqlQuery } from 'requestapijs';
import { ConversionNodeConnection, BranchCategory } from '../../../schema';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { CompanyService } from '../../../service/CompanyService';
import { TSInput } from '../../common/TSInput';
import { STOCK_REPORT_FOR_PRODUCTS_WITHOUT_BARCODE_CSV } from '../../../config';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';

export class StockReportForProductsWithoutBarcode extends React.Component<
  Props,
  State
> {
  companyService = new CompanyService();
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      branchId: '',
      after: '',
      productName: '',
      currentPage: 1,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'node.stock.product',
      sortingValue: (value) => value,
    },
    {
      title: 'Production',
      key: 'production',
      dataIndex: 'node.stock.production',
      sortingValue: (value) => value,
    },
    {
      title: 'Transfer',
      key: 'transfer',
      dataIndex: 'node.stock.transfer',
      sortingValue: (value) => value,
    },
    {
      title: 'Receiving',
      key: 'receiving',
      dataIndex: 'node.stock.receiving',
      sortingValue: (value) => value,
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(STOCK_REPORT_FOR_PRODUCTS_WITHOUT_BARCODE_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `stock-report-for-products-without-barcode-${new Date()}`,
          `STOCK REPORT FOR PRODUCTS WITHOUT BARCODE`,
          this.props.company.name,
          'Head Production',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    if (!this.state.startDate || !this.state.endDate) {
      return <Spin />;
    }
    const { startDate, endDate, after, productName, currentPage } = this.state;
    const companyId = this.props.company.id;
    const branchId = this.props.branch
      ? this.props.branch.id
      : this.state.branchId;
    return (
      <div>
        {this.companyService?.selectedBussiness$.value?.type !== 'branch' && (
          <Col span={7}>
            <TSAutoComplete
              error={''}
              inputProps={{ name: 'branchId', addonBefore: 'Location' }}
              dataSource={
                this.props.business?.branches?.edges.filter(
                  (element) =>
                    element.node.category === BranchCategory.FINISHING_UNIT,
                ) || []
              }
              key="id"
              name="branchId"
              onValueSelect={(e) => this.setState({ branchId: e.target.value })}
              displayKey="name"
              textValue={getValue(
                this.props.business?.branches?.edges,
                branchId,
              )}
            />
          </Col>
        )}
        <Col span={5}>
          <DatePicker
            format="DD-MM-YYYY"
            placeholder="Start Date"
            value={moment(this.state.startDate)}
            onChange={(date) =>
              this.onChange({
                target: {
                  name: 'startDate',
                  value: date && moment(date).format('YYYY-MM-DD'),
                },
              })
            }
            disabledDate={(currentDate) => {
              if (!currentDate || !this.state.endDate) {
                return false;
              }
              return (
                currentDate.valueOf() > moment(this.state.endDate).valueOf()
              );
            }}
            style={{ marginTop: '5px', width: '100%' }}
          />
        </Col>
        <Col span={5}>
          <DatePicker
            format="DD-MM-YYYY"
            placeholder="End Date"
            value={moment(this.state.endDate)}
            onChange={(date) =>
              this.onChange({
                target: {
                  name: 'endDate',
                  value: date && moment(date).format('YYYY-MM-DD'),
                },
              })
            }
            disabledDate={(currentDate) => {
              if (!currentDate || !this.state.startDate) {
                return false;
              }
              return (
                currentDate.valueOf() < moment(this.state.startDate).valueOf()
              );
            }}
            style={{ marginTop: '5px', width: '100%' }}
          />
        </Col>
        <Col span={7}>
          <TSInput
            error=""
            value={this.state.productName}
            onChange={(e) => this.setState({ productName: e.target.value })}
            name="productName"
            type="text"
            addonBefore="Search Product"
            placeholder="Enter Product Name"
          />
        </Col>
        <Divider />
        <GraphqlQuery
          queryString={stockReportForProductsWithoutBarcode}
          variables={{
            companyId,
            branchId,
            startDate,
            endDate,
            productName,
            after,
            first: 20,
          }}
          render={(
            response: { allConversions: ConversionNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Spin />;
            }
            if (response && branchId) {
              return [
                <Table
                  dataSource={response.allConversions.edges}
                  columns={this.columns()}
                  key="table"
                  rowKey="id"
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={response.allConversions.totalCount || 0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allConversions.pageInfo.endCursor,
                      response.allConversions.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  onClick={() =>
                    this.downloadCsv({
                      companyId,
                      branchId,
                      startDate,
                      endDate,
                      productName,
                    })
                  }
                  icon="download"
                  key="button"
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business?: Bussiness;
  branch?: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  branchId: string;
  after: string;
  productName: string;
  currentPage: number;
}

import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import { LOGIN_URL, HEADER } from '../config';
import { UserNodeEdge } from '../components/user/constants';

export class UserService {
  static instance;

  constructor() {
    if (UserService.instance) {
      return UserService.instance;
    } else {
      UserService.instance = this;
    }
  }

  public readonly user$ = new BehaviorSubject<{ user: UserNodeEdge } | null>(
    null,
  );

  login(data, onSuccess, onError) {
    Api.post(LOGIN_URL, data, HEADER).subscribe(
      (response) => {
        this.user$.next(response.response);
        localStorage.setItem('token', `Token ${response.response.token}`);
        onSuccess && onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }

  logout() {
    window.location.href = '/';
    localStorage.removeItem('token');
  }
}

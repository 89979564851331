import * as React from 'react';
import { Table, Tabs, Icon, Spin } from 'antd';
import { checkAddPermission } from '../helper';
import { GiftService } from '../../service/GiftService';
import { AddGift } from './AddGift';
import { CompanyService } from '../../service/CompanyService';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GiftNodeEdgeQuery } from './constants';
import moment from 'moment';
import { GiftReceiptJharcraft } from './GiftReceiptJharcraft';
import { PDFViewer } from '@react-pdf/renderer';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { GiftReport } from './GiftReport';

export class Gift extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      gifts: [],
      activeKey: '1',
      selectedGift: null,
      branch: null,
      company: null,
      isEditActive: false,
      user: null,
    };
  }

  giftService = new GiftService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.giftService?.gift$.subscribe((gifts) => this.setState({ gifts }));

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );

    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1' || this.state.activeKey === '3') {
      this.setState({ activeKey, selectedGift: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  giftColumn = () => [
    {
      title: 'Gift Receipt No',
      dataIndex: 'node.giftReceiptNo',
      key: 'giftReceiptNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedGift: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Customer',
      dataIndex: 'node.customer.name',
      key: 'customer',
    },
    {
      title: 'Total Cost Amount',
      dataIndex: 'node.totalCostAmount',
      key: 'totalCostAmount',
    },
    {
      title: 'Total MRP',
      dataIndex: 'node.totalMrp',
      key: 'totalMrp',
    },
    {
      title: 'Order By',
      dataIndex: 'node.orderByName',
      key: 'orderByName',
      render: (value, record) =>
        `${record.node.orderByName}(${record.node.orderByDesignation})`,
    },
    {
      title: 'Receipt',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedGift: obj, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  render() {
    if (!this.state.company) return <Spin />;
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                List Gift
              </span>
            }
            key="1"
            closable={false}
          >
            <Table
              dataSource={this.state.gifts}
              columns={this.giftColumn()}
              rowKey={(record) => record.node.id || ''}
            />
          </Tabs.TabPane>
          {this.state.user && checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Gift
                </span>
              }
              key="2"
            >
              {this.companyService?.company$.value && (
                <AddGift
                  gift={this.state.selectedGift?.node}
                  handleTabChange={this.handleTabChange}
                  company={this.companyService?.company$.value}
                  giftList={this.state.gifts}
                  isEditActive={this.state.isEditActive}
                />
              )}
            </Tabs.TabPane>
          )}
          {this.state.selectedGift && this.state.activeKey === '3' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Receipt
                </span>
              }
              key="3"
            >
              <PDFViewer style={{ width: '100%', height: '1000px' }}>
                {this.companyService?.company$.value &&
                this.companyService.selectedBussiness$.value &&
                (this.state.company.name === 'Jharcraft' ||
                  this.state.company?.name.toLowerCase().includes('khadi')) ? (
                  <GiftReceiptJharcraft
                    company={this.companyService?.company$.value}
                    gift={this.state.selectedGift.node}
                    branch={this.companyService.selectedBussiness$.value}
                  />
                ) : (
                  <div />
                )}
              </PDFViewer>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Item Wise Gift Report
              </span>
            }
            key="4"
            closable={false}
          >
            <GiftReport
              company={this.state.company}
              branch={this.state.branch}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

interface State {
  gifts: {
    cursor: string;
    node: GiftNodeEdgeQuery;
  }[];
  activeKey: string;
  selectedGift: {
    cursor: string;
    node: GiftNodeEdgeQuery;
  } | null;
  branch: BranchNodeEdge | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import {
  BranchNodeEdge,
  BranchError,
  branchObject,
  branchErrorObject,
  parentCategory,
  category,
} from './constants';
import { BranchForm } from './BranchForm';
import { Button, notification } from 'antd';
import { BranchService } from '../../../service/BranchService';
import { arrayRemove } from '../../../utils';
import { CompanyNodeEdge } from '../Company/constants';

export class AddBranch extends React.Component<Props, State> {
  branchService = new BranchService();

  constructor(props: Props) {
    super(props);
    this.state = {
      branch: props.branch
        ? { ...props.branch, companyId: props.branch.company?.id }
        : JSON.parse(JSON.stringify(branchObject)),
      error: { ...branchErrorObject },
      buttonDisabled: false,
      parentCategory: [...parentCategory],
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      branch: nextProps.branch || JSON.parse(JSON.stringify(branchObject)),
    });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const branch = { ...this.state.branch };
    branch[e.target.name] = e.target.value;
    let newparentCategoryArray = [];
    if (e.target.name === 'category') {
      newparentCategoryArray = arrayRemove(category, e.target.value);
    }
    this.setState({ branch, parentCategory: newparentCategoryArray });
  };

  checkBranchError = () => {
    const error = { ...this.state.error };
    const branch = { ...this.state.branch };
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (!branch[errKey]) {
        error[errKey] = `This is a required field`;
        isError = true;
      }
    });
    this.setState({ error });
    return isError;
  };

  checkDepartmentError = () => {
    const error = { ...this.state.error };
    const branch = { ...this.state.branch };
    let isError = false;
    if (!branch.name) {
      error.name = `This is a required field`;
      isError = true;
    }
    this.setState({ error });
    return isError;
  };

  saveBranch = () => {
    if (this.props.branchType === 'branch' && this.checkBranchError()) {
      return;
    }
    if (this.props.branchType === 'department' && this.checkDepartmentError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.branchService?.addBranch(
      {
        ...this.state.branch,
        category: this.state.branch.category || 'EMPORIUM',
      },
      (response) => {
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Branch ' + this.state.branch.id ? 'Updated' : 'Added',
          description: `Branch named ${
            this.state.branch.name
          } was successfully ${this.state.branch.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Branch ' + this.state.branch.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      branch: JSON.parse(JSON.stringify(branchObject)),
      error: { ...branchErrorObject },
    });
  };

  render() {
    return (
      <div>
        <BranchForm
          values={this.state.branch}
          error={this.state.error}
          onChange={this.onChange}
          parentCategory={this.state.parentCategory}
          company={this.props.company}
          branchType={this.props.branchType}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveBranch}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  branch?: BranchNodeEdge;
  handleTabChange: (activeKey: string) => void;
  company: CompanyNodeEdge;
  branchType: 'branch' | 'department';
}

interface State {
  branch: BranchNodeEdge;
  error: BranchError;
  buttonDisabled: boolean;
  parentCategory: string[];
}

import { UserNodeEdge } from '../components/user/constants';

export function nestedAccess(nestedObject: object, ...items) {
  let value = nestedObject;
  for (const i of items) {
    if (value) {
      value = value[i];
      continue;
    }
    break;
  }
  return value as any;
}

export function inWords(value: number) {
  const numbersDecimalSplitted = value.toFixed(2).split('.');
  const rupeesValue = getInWordsValue(
    Number(numbersDecimalSplitted[0]).toString(),
  );
  let paiseValue = '';
  if (numbersDecimalSplitted[1]) {
    paiseValue =
      Number(numbersDecimalSplitted[1]) === 0
        ? ''
        : `rupees and ${getInWordsValue(numbersDecimalSplitted[1])}paise `;
  }
  return rupeesValue + paiseValue + 'only';
}

function getInWordsValue(number: string) {
  // tslint:disable-next-line:max-line-length
  const a = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen ',
  ];
  const b = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];
  if (number.length > 9) return 'overflow';
  const n = ('000000000' + number)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  let str = '';
  str +=
    Number(n[1]) !== 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore '
      : '';
  str +=
    Number(n[2]) !== 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh '
      : '';
  str +=
    Number(n[3]) !== 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand '
      : '';
  str +=
    Number(n[4]) !== 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred '
      : '';
  str +=
    Number(n[5]) !== 0
      ? (str !== '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]])
      : '';
  return str;
}

export function downloadToFile(
  data: string,
  fileName: string,
  header: string,
  companyName: string,
  branchName: string,
  from: string,
  to: string,
  dataFor: string,
) {
  let str = `,,${header}\n,${companyName},(${branchName}),,Report Date: ${new Date()
    .toLocaleString()
    .replace(',', '-')}\n`;
  if (from !== to) str += `From: ${from},,To: ${to},,`;
  else str += `As on: ${from},,,`;
  str += dataFor !== '' ? `For: ${dataFor}\n\n\n` : '\n\n';

  data = str + data;

  const blob = new Blob([data], {
    type: 'text/plain;charset=utf-8;',
  });
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const checkViewPermission = (user: UserNodeEdge) => {
  const groups = user.user.groups ? user.user.groups : user.user.user.groups;
  const isAllowed = user.user.groups.some((group) => {
    return String(group.name).toLowerCase() === 'report';
  });
  return isAllowed;
};

export const checkAddPermission = (user: UserNodeEdge) => {
  const groups = user.user.groups ? user.user.groups : user.user.user.groups;
  const isAllowed = groups.some((group) => {
    return String(group.name).toLowerCase() === 'add';
  });
  return isAllowed;
};

export const checkProductionPermission = (user: UserNodeEdge) => {
  const groups = user.user.groups ? user.user.groups : user.user.user.groups;
  const isAllowed = groups.some((group) => {
    return String(group.name).toLowerCase() === 'production';
  });
  return isAllowed;
};

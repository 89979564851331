import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { SalesPersonEdges, SalesPersonQueryResponse } from './constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  salesPerson?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface SalesPersonDropDownProps extends AutoCompleteProps {
  branchId?: string;
  companyId?: string;
  extrafields?: string[];
}

export class SalesPersonDropDown extends React.Component<
  SalesPersonDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props: Readonly<SalesPersonDropDownProps>) {
    super(props);

    this.state = {
      name: '',
      salesPerson: null,
      touched: false,
    };
  }

  value = (salesPersons: SalesPersonEdges[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const salesPerson = salesPersons.find(
      (v) => v.node?.id === this.props.value,
    );
    if (!salesPerson) return;
    return salesPerson.node?.name;
  };

  render() {
    const { name } = this.state;
    const { branchId, companyId, extrafields, value, onSelect } = this.props;
    return (
      <GraphqlQuery
        queryString={`query SalesPersonDropDown($id: ID, $name: String, $branchId: ID, $companyId: ID) {
          allSalesPerson(id: $id, name_Icontains: $name, branchId: $branchId, companyId: $companyId) {
            edges {
              node {
                id,
                name
                ${extrafields ? extrafields.join(',\n') : ''}
              }
            }
          }
        }`}
        variables={{
          id: value && !name ? value : '',
          name,
          branchId,
          companyId,
        }}
        render={(response: SalesPersonQueryResponse, error, loading) => {
          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          if (response) {
            return (
              <div style={{ width: '100%' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Sales Person"
                  dataSource={response.allSalesPerson.edges.map(
                    (salesPerson) => (
                      <Option
                        key={salesPerson.node?.id}
                        label={salesPerson.node}
                        salesPerson={salesPerson}
                      >
                        {salesPerson.node?.name}
                      </Option>
                    ),
                  )}
                  value={this.value(response.allSalesPerson.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(salesPerson) =>
                    this.setState({ name: salesPerson, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.salesPerson.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
        }}
      />
    );
  }
}

interface State {
  name: string;
  salesPerson: SalesPersonEdges | null;
  touched: boolean;
}

import * as React from 'react';
import {
  QuotationNode,
  QuotationInput,
  ProductNode,
  CustomerNode,
  ProductDetailsInput,
} from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { QuotationService } from '../../service/QuotationService';
import { handleProductDetailsData } from '../Transfer/constants';
import { ProductDetailsNodeConnection } from '../Sales/constants';
import {
  quotationObject,
  quotationProductDetailsObject,
  QuotationError,
  quotationErrorObject,
  quotationProductDetailsError,
} from './constants';
import { notification, Button, Modal, Popconfirm } from 'antd';
import { QuotationForm } from './QuotationForm';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';

export class AddQuotation extends React.Component<Props, State> {
  QuotationService = new QuotationService();

  constructor(props: Props) {
    super(props);
    this.state = {
      quotation: this.handleValuePropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      buttonDisabled: false,
      productObjList: [],
      customerObj: null,
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const quotation: QuotationInput = this.handleValuePropsUpdate(nextProps);
    const error: QuotationError = this.handleErrorPropsUpdate(nextProps);
    this.setState({ quotation, error });
  }

  handleValuePropsUpdate(props: Props) {
    const quotation: QuotationInput = props.quotation
      ? {
          ...props.quotation,
          customerId: props.quotation.customer.id,
          productDetails: handleProductDetailsData(
            props.quotation
              .productDetails as any as ProductDetailsNodeConnection,
          ),
        }
      : JSON.parse(JSON.stringify(quotationObject));
    delete (quotation as any).customer;
    return quotation;
  }

  handleErrorPropsUpdate(nextProps: Props) {
    const error: QuotationError = JSON.parse(
      JSON.stringify(quotationErrorObject),
    );
    const productDetails =
      nextProps.quotation &&
      (nextProps.quotation
        .productDetails as any as ProductDetailsNodeConnection);
    productDetails &&
      productDetails.edges.forEach(() => {
        if (productDetails.edges.length !== error.productDetails.length) {
          error.productDetails.push(quotationProductDetailsError);
        }
      });
    return error;
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const quotation = { ...this.state.quotation };
    quotation[e.target.name] = e.target.value;
    this.setState({ quotation });
  };

  checkError = () => {
    const error: QuotationError = JSON.parse(JSON.stringify(this.state.error));
    const QuotationValue = this.state.quotation;
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !QuotationValue[errKey] &&
        QuotationValue[errKey] !== false &&
        errKey !== 'productDetails'
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.quotation.productDetails[index][e.target.name] = e.target.value;
    if (
      nextState.quotation.productDetails[index].quantity &&
      nextState.quotation.productDetails[index].rate
    ) {
      nextState.quotation.productDetails[index].amount =
        Number(nextState.quotation.productDetails[index].quantity) *
        Number(nextState.quotation.productDetails[index].rate);
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.quotation.productDetails.push(
        JSON.parse(JSON.stringify(quotationProductDetailsObject)),
      );
      nextState.error.productDetails.push(
        JSON.parse(JSON.stringify(quotationProductDetailsError)),
      );
    } else {
      nextState.quotation.productDetails.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.quotation.productDetails[index].rate = productObj.sellingRate;
    nextState.quotation.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  checkProductError = () => {
    const productDetails: ProductDetailsInput[] = {
      ...this.state.quotation.productDetails,
    };
    const error: State['error'] = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.quotation.customerId = customerObj.id;
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveQuotation = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.QuotationService?.addQuotation(
      this.state.quotation,
      (response) => {
        notification.success({
          message: 'Quotation ' + this.state.quotation.id ? 'Updated' : 'Added',
          description: `Quotation was successfully ${
            this.state.quotation.id ? 'Updated' : 'Added'
          }`,
        });
        this.clear();
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Quotation ' + this.state.quotation.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      quotation: JSON.parse(JSON.stringify(quotationObject)),
      error: JSON.parse(JSON.stringify(quotationErrorObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <QuotationForm
          values={this.state.quotation}
          error={this.state.error}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          company={this.props.company}
          updateCount={this.state.updateCount}
          customerObjChangehandler={this.customerObjChangehandler}
          handleModalAction={this.handleModalAction}
        />
        <Modal
          title="Add Customer"
          visible={this.state.modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            onCustomerAdded={() =>
              this.setState({ updateCount: this.state.updateCount + 1 })
            }
            company={this.props.company}
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={this.state.modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={this.state.updateCount}
            showOnlyList
          />
        </Modal>
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="dashed"
            onClick={this.clear}
            children="Clear"
            style={{ width: '50%' }}
          />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveQuotation}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  quotation?: QuotationNode;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
}

// type quotationKeys = keyof QuotationInput;

interface State {
  quotation: QuotationInput;
  error: QuotationError;
  customerObj: CustomerNode | null;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
}

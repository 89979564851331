import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { sumBy } from 'lodash';
import { inWords } from '../helper';
import { ChallanNode } from '../../schema';
import { ProductDetails } from './constants';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../config';

export const ChallanRecieptJharcraft: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailsList = JSON.parse(
    props.challan.productDetails,
  ) as ProductDetails[];
  const setBoxHeight = productDetailsList.length < 15;
  const totalAmount = sumBy(
    productDetailsList,
    (value) => Number(value.receivedQuantity) * Number(value.rate),
  );
  const orderQty = sumBy(productDetailsList, (value) =>
    Number(value.orderQuantity),
  );
  const recQty = sumBy(productDetailsList, (value) =>
    Number(value.receivedQuantity),
  );
  const vendor = props.challan.purchaseOrder?.vendor;
  const dumpLocation = props.challan.dumpToHeadOffice
    ? 'Head Office'
    : props.challan.dumpLocation?.name;
  console.log(totalAmount);
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.3pt' },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              {props.company.name.toLowerCase().includes('jharcraft') ||
              props.company?.name.toLowerCase().includes('khadi')
                ? 'Good Receipt Challan'
                : 'Challan Order'}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Challan No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.challan.challanNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Challan Date: {moment(props.challan.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.box]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Vendor Name: {vendor?.name}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Vendor Address: {vendor?.address}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              GSTIN: {vendor?.gstin}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Contact Person: {vendor?.contactPersonName}
            </Text>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Contact No: {vendor?.contactNo}
            </Text>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.challan.remarks}
            </Text>
          </View>
        </View>
        <View style={[style.section, style.box, { marginTop: 2 }]}>
          <View style={[style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textCenter,
                { padding: 3, flex: 0.2 },
              ]}
            >
              Sl.
            </Text>
            <Text
              style={[
                style.highFlexInlineHead,
                style.darkBlackText,
                { padding: 3 },
              ]}
            >
              Product
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.8,
                },
              ]}
            >
              Order Quantity
            </Text>
            <Text
              style={[
                style.inlineHead,
                style.darkBlackText,
                style.textRight,
                { padding: 3 },
              ]}
            >
              Received Quantity
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.5,
                },
              ]}
            >
              Rate
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.6,
                },
              ]}
            >
              Amount
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.8,
                },
              ]}
            >
              Dump Location
            </Text>
          </View>
          <View style={style.divider} />
          {productDetailsList.map((pd, i) => (
            <View style={[style.inline]} key={'pd' + i}>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  style.textCenter,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {pd.productName}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  {
                    padding: 3,
                    flex: 0.8,
                  },
                ]}
              >
                {pd.orderQuantity}
              </Text>
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3 },
                ]}
              >
                {pd.receivedQuantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  {
                    padding: 3,
                    flex: 0.5,
                  },
                ]}
              >
                {Number(pd.rate).toFixed(2)}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  {
                    padding: 3,
                    flex: 0.6,
                  },
                ]}
              >
                {(pd.receivedQuantity * Number(pd.rate)).toFixed(2)}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  {
                    padding: 3,
                    flex: 0.8,
                  },
                ]}
              >
                {dumpLocation}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            style.section,
            {
              marginTop: 0,
              borderLeft: '1pt solid black',
              borderRight: '1pt solid black',
            },
          ]}
        >
          <View style={[style.inline]}>
            <Text
              style={[
                style.highFlexInlineHead,
                style.darkBlackText,
                { padding: 3 },
              ]}
            >
              Total
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.lightBlackText,
                { flex: 0.2, padding: 3 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.8,
                },
              ]}
            >
              {orderQty}
            </Text>
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textRight,
                { padding: 3 },
              ]}
            >
              {recQty}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                {
                  padding: 3,
                  flex: 0.5,
                },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.6,
                },
              ]}
            >
              {Math.round(totalAmount).toFixed(2)}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.darkBlackText,
                style.textRight,
                {
                  padding: 3,
                  flex: 0.8,
                },
              ]}
            >
              {}
            </Text>
          </View>
        </View>
        <View style={[style.section, style.box, { marginTop: 0 }]}>
          <Text
            style={[
              style.darkBlackText,
              { fontSize: '10.2pt' },
              { padding: 4 },
            ]}
          >
            Rs. {inWords(Number(Math.round(totalAmount).toFixed(2)))}.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  challan: ChallanNode;
  company: CompanyNodeEdge;
}

import * as React from 'react';
import { Row, Col, Button, DatePicker, Divider } from 'antd';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { CompanyNodeEdge } from '../master/Company/constants';
import { ProductDetailsInput, ProductNode } from '../../schema';
import { TSInput } from '../common/TSInput';
import moment from 'moment';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { BranchEdges } from '../master/Branch/constants';

export const CodeConversionForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={5}>
          <DatePicker
            value={props.values[0].date ? moment(props.values[0].date) : null}
            format="DD-MM-YYYY"
            style={{ marginTop: '5px', width: '100%' }}
            placeholder="Date"
          />
        </Col>
        <Col span={19}>
          <TSAutoComplete
            error=""
            inputProps={{ name: 'movementForId', addonBefore: 'Location' }}
            dataSource={props.branch.filter(
              (br) => br.node.category === 'EMPORIUM',
            )}
            key="id"
            name="movementForId"
            onValueSelect={(e) =>
              props.onBranchChange({
                target: { name: 'movementForId', value: e.target.value },
              })
            }
            displayKey="name"
            textValue={getValue(props.branch, props.values[0].movementForId)}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>Old Product</Col>
        <Col span={4}>Quantity</Col>
        <Col span={8}>New Product</Col>
        <Col span={2}>Remove</Col>
        <Col span={2}>Add</Col>
      </Row>
      <Divider />
      {props.values.map((pd, index) => (
        <Row key={'products' + index}>
          <Col span={8}>
            <ProductDropDown
              onSelect={(e, f) => {
                props.productChangeHandler(
                  { target: { name: 'productId', value: String(e) } },
                  index,
                );
                props.productObjChangehandler((f as any).props.label, index);
              }}
              allowClear
              extrafields={['sellingRate', 'purchaseRate']}
              company={props.company}
              value={pd.productId || ''}
            />
          </Col>
          <Col span={4}>
            <TSInput
              error=""
              type="number"
              value={pd.quantity || ''}
              name="quantity"
              placeholder="Quantity"
              onChange={(e) => props.productChangeHandler(e, index)}
            />
          </Col>
          <Col span={8}>
            <ProductDropDown
              onSelect={(e, f) => {
                props.productChangeHandler(
                  { target: { name: 'newProductId', value: String(e) } },
                  index,
                );
              }}
              allowClear
              company={props.company}
              value={pd.newProductId || ''}
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              shape="circle"
              icon="minus"
              style={{ marginLeft: '5px' }}
              onClick={() => props.updateProductsRow('-', index)}
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              style={{ marginLeft: '5px' }}
              onClick={() => props.updateProductsRow('+', index)}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

interface Props {
  values: ProductDetailsInput[];
  company: CompanyNodeEdge;
  branch: BranchEdges[];
  onBranchChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  productObjChangehandler: (value: ProductNode, index: number) => void;
  updateProductsRow: (type: string, index: number) => void;
}

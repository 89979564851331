import { BehaviorSubject } from 'rxjs';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  ApprovalEdges,
  ApprovalQueryResponse,
  ApprovalQueryVariable,
  approvalQuery,
  ApprovalNodeEdgeMutation,
  AddApprovalVariable,
  approvalMutation,
} from '../components/Approval/constants';
import { DateService } from './DateService';

export class ApprovalService {
  static instance;

  constructor() {
    if (ApprovalService.instance) {
      return ApprovalService.instance;
    } else {
      ApprovalService.instance = this;
    }
    this.onInit();
  }

  onInit() {
    this.companyService?.selectedBussiness$.subscribe((bussiness) => {
      if (!bussiness) return;
      bussiness.type === 'branch'
        ? this.getAll({
            companyId: bussiness.companyId,
            branchId: bussiness.id,
            branchId_Isnull: false,
          })
        : this.getAll({
            companyId: bussiness.id,
            branchId: null,
            branchId_Isnull: true,
          });
    });
  }

  public readonly approval$ = new BehaviorSubject<ApprovalEdges[]>([]);
  companyService = new CompanyService();
  dateService = new DateService();

  getAll({ companyId, branchId, branchId_Isnull }) {
    let variables: any = { companyId, date_Lte: this.dateService?.date$.value };
    if (branchId) {
      variables = {
        ...variables,
        branchId,
      };
    } else {
      variables = {
        ...variables,
        branchId_Isnull,
      };
    }
    const body = {
      query: approvalQuery,
      variables,
    };

    Api.post<{ query: string; variables: ApprovalQueryVariable }>(
      API_URL,
      body,
      HEADER,
    ).subscribe((response) =>
      this.approval$.next(
        (response.response as ApprovalQueryResponse).data.allApprovals.edges,
      ),
    );
  }

  addApproval(
    data: ApprovalNodeEdgeMutation,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails?.map((pd) => {
      pd.date = data.date;
      pd.movementForId =
        this.companyService?.selectedBussiness$.value?.type === 'branch'
          ? this.companyService?.selectedBussiness$.getValue()?.id
          : null;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });

    Api.graphql<AddApprovalVariable, {}>(
      API_URL,
      {
        input: {
          approval: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
            branchId:
              this.companyService?.selectedBussiness$.value?.type === 'branch'
                ? this.companyService?.selectedBussiness$.getValue()?.id
                : null,
          },
        },
      },
      approvalMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.onInit();
      },
      (error) => onError(error),
    );
  }
}

import * as React from 'react';
import { SubareaInput, SubareaNode } from '../../../schema';
import { SubareaError } from './constants';
import { SubareaForm } from './SubareaForm';
import { Button, notification } from 'antd';
import { SUBAREA_ERROR, SUBAREA_DATA } from '../Subarea/constants';
import { SubareaService } from '../../../service/SubareaService';

export class AddSubarea extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      subarea: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
      error: SUBAREA_ERROR,
    };
  }

  subareaService = new SubareaService();

  handlePropsValueUpdate = (props: Props) => {
    const subarea = props.subarea
      ? {
          ...props.subarea,
          areaId: props.subarea.area?.id,
        }
      : JSON.parse(JSON.stringify(SUBAREA_DATA));
    return subarea;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ subarea: this.handlePropsValueUpdate(nextProps) });
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const subarea = { ...this.state.subarea };
    subarea[e.target.name] = e.target.value;
    this.setState({ subarea });
  };

  saveSubarea = () => {
    this.setState({ buttonDisabled: true });
    this.subareaService?.addSubarea(
      this.state.subarea,
      (response) => {
        notification.success({
          message: 'Subarea ' + this.state.subarea.id ? 'Updated' : 'Added',
          description: `Subarea ${this.state.subarea.name} was successfully ${
            this.state.subarea.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Subarea ' + this.state.subarea.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      subarea: JSON.parse(JSON.stringify(SUBAREA_ERROR)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <SubareaForm subarea={this.state.subarea} onChange={this.onChange} />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%', marginTop: '50px' }}
        />
        <Button
          type="primary"
          onClick={this.saveSubarea}
          children="Submit"
          style={{ width: '50%', marginTop: '50px' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  handleTabChange?: (key) => void;
  subarea?: SubareaNode;
}

interface State {
  subarea: SubareaInput;
  buttonDisabled: boolean;
  error: SubareaError;
}

import * as React from 'react';
import { TransferNode } from './constants';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { sumBy } from 'lodash';
import { inWords } from '../helper';

export const TransferReceipt: React.FunctionComponent<Props> = (props) => {
  const productDetailsEdges = props.transfer.productDetails.edges;
  const setBoxHeight = productDetailsEdges.length < 15;
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node.quantity || 0,
  );
  const totalAmount = sumBy(
    productDetailsEdges,
    (value) => (value.node.purchaseRate || 0) * (value.node.quantity || 0),
  );
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 18 }]}>
            <Text style={[{ flex: 0.3 }]}>{}</Text>
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              {props.company.name.toUpperCase()}
            </Text>
            <Text style={[{ flex: 0.3 }]}>{}</Text>
          </View>
          <View style={[style.section, style.inline, { marginTop: 2 }]}>
            <Text style={[{ flex: 0.3 }]}>{}</Text>
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { color: '#000000', marginLeft: 15, marginRight: 15 },
              ]}
            >
              {props.company.name.toLowerCase().includes('pearl')
                ? `ASLI MOTI SAHI DAM`
                : ''}
            </Text>
            <Text style={[{ flex: 0.3 }]}>{}</Text>
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Delivery Challan
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.business.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.business.state} (${props.business.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.transfer.transferInvoiceNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Date: {moment(props.transfer.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <View
              style={[
                style.box,
                { padding: 5, marginRight: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill From: (
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.category
                  : 'Head Office'}
                )
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.name
                  : props.company.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.address
                  : props.company.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.gstin
                  : props.company.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.state
                  : props.company.state}
                (
                {props.transfer.fromLocation
                  ? props.transfer.fromLocation.gstin?.slice(0, 2)
                  : props.company.gstin?.slice(0, 2)}
                )
              </Text>
            </View>
            <View
              style={[
                style.box,
                { padding: 5, marginLeft: 1 },
                style.inlineHead,
              ]}
            >
              <Text style={style.mediumBlackText}>
                Bill To: (
                {props.transfer.toLocation
                  ? props.transfer.toLocation.category
                  : 'Head Office'}
                )
                {props.transfer.toLocation
                  ? props.transfer.toLocation.name
                  : props.company.name}
              </Text>
              <Text style={style.mediumBlackText}>
                {props.transfer.toLocation
                  ? props.transfer.toLocation.address
                  : props.company.address}
              </Text>
              <Text style={[style.mediumBlackText, style.marginTop]}>
                GSTIN:{' '}
                {props.transfer.toLocation
                  ? props.transfer.toLocation.gstin
                  : props.company.gstin}
              </Text>
              <Text style={style.mediumBlackText}>
                GST State:{' '}
                {props.transfer.toLocation
                  ? props.transfer.toLocation.state
                  : props.company.state}
                (
                {props.transfer.toLocation
                  ? props.transfer.toLocation.gstin?.slice(0, 2)
                  : props.company.gstin?.slice(0, 2)}
                )
              </Text>
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <Text style={[style.mediumBlackText, { padding: 3 }]}>
              Remarks: {props.transfer.remarks}
            </Text>
          </View>
        </View>
        <View>
          {setBoxHeight ? (
            <View
              style={[style.section, style.box, { marginTop: 2, height: 450 }]}
            >
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.2 },
                  ]}
                >
                  Sl.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Product
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3 },
                  ]}
                >
                  Barcode
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Cost Price
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={style.divider} />
              {productDetailsEdges.map((pd, i) => (
                <View style={[style.inline]} key={'pd' + i} wrap={false}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      style.textCenter,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {i + 1}.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node.product?.name}({pd.node.rate}/-)
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node.product?.oldBarcodeId}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node.quantity}{' '}
                    {pd.node.product?.unit?.name.split('(')[1].replace(')', '')}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node.purchaseRate?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {(
                      (pd.node.quantity || 0) * (pd.node.purchaseRate || 0)
                    ).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
          ) : (
            <View style={[style.section, style.box, { marginTop: 2 }]}>
              <View style={[style.inline]}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.2 },
                  ]}
                >
                  Sl.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Product
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textCenter,
                    { padding: 3 },
                  ]}
                >
                  Barcode
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Cost Price
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.darkBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={style.divider} />
              {productDetailsEdges.map((pd, i) => (
                <View style={[style.inline]} key={'pd' + i} wrap={false}>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.lightBlackText,
                      style.textCenter,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {i + 1}.
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node.product?.name}({pd.node.rate}/-)
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textCenter,
                      { padding: 3 },
                    ]}
                  >
                    {pd.node.product?.oldBarcodeId}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.5 },
                    ]}
                  >
                    {pd.node.quantity}{' '}
                    {pd.node.product?.unit?.name.split('(')[1].replace(')', '')}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {pd.node.purchaseRate?.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3, flex: 0.6 },
                    ]}
                  >
                    {(
                      (pd.node.quantity || 0) * (pd.node.purchaseRate || 0)
                    ).toFixed(2)}
                  </Text>
                </View>
              ))}
            </View>
          )}
          <View
            style={[
              style.section,
              {
                marginTop: 0,
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                height: 17,
              },
            ]}
          >
            <View style={[style.inline]}>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                Total
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.2, padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.5 },
                ]}
              >
                {totalQty.toFixed(2)}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {Math.round(totalAmount).toFixed(2)}
              </Text>
            </View>
          </View>
          <View
            style={[style.section, style.box, { marginTop: 0, height: 22 }]}
          >
            <Text
              style={[
                style.darkBlackText,
                { fontSize: '10.2pt' },
                { padding: 4 },
              ]}
            >
              Rs. {inWords(Number(Math.round(totalAmount).toFixed(2)))}.
            </Text>
          </View>
          <View style={[style.section, style.inline, style.marginTop]}>
            <Text style={[style.inlineHead, style.darkBlackText]}>
              Received By
            </Text>
            <Text
              style={[style.inlineHead, style.darkBlackText, style.textCenter]}
            >
              Checked By
            </Text>
            <Text
              style={[style.inlineHead, style.darkBlackText, style.textRight]}
            >
              Authorized Signatory
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  transfer: TransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
}

import * as React from 'react';
import moment from 'moment';
import { CompanyService } from '../../service/CompanyService';
import { Button, Col, DatePicker, Row, Skeleton, Table } from 'antd';
import { Pagination } from 'sha-el-design';
import { GraphqlQuery } from 'requestapijs';
import {
  PurchaaseOrderStatusReportQuery,
  PurchaseOrderData,
} from './constants';
import { ChallanNode, ChallanNodeConnection } from '../../schema';
import { ProductDetails } from '../Challan/constants';
import { PURCHASE_ORDER_STATUS_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';
import { CompanyNodeEdge } from '../master/Company/constants';

export class PurchaseOrderStatusReport extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
      currentPage: 1,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
    };
  }

  companyService = new CompanyService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ companyId: company?.id }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  purchaseOrderStatusColumn = () => [
    {
      title: 'P.O Date',
      dataIndex: 'purchaseOrderDate',
      key: 'purOrdDate',
    },
    {
      title: 'P.O No',
      dataIndex: 'purchaseOrderNumber',
      key: 'purOrdNum',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
    },
    {
      title: 'P.O Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'P.O Qty',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'P.O Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'P.O Received Date',
      dataIndex: 'receivedDate',
      key: 'receivedDate',
    },
    {
      title: 'Challan No',
      dataIndex: 'challanNumber',
      key: 'challanNumber',
    },
    {
      title: 'Vendor Challan Date',
      dataIndex: 'vendorChallanDate',
      key: 'vendorChallanDate',
    },
    {
      title: 'Vendor Challan No',
      dataIndex: 'vendorChallanNumber',
      key: 'vendorChallanNumber',
    },
    {
      title: 'Receiving Quantity',
      dataIndex: 'receivedQuantity',
      key: 'receivedQuantity',
    },
    {
      title: 'Pending Quantity',
      dataIndex: 'pendingQuantity',
      key: 'pendingQuantity',
    },
    {
      title: 'CancelledQuantity',
      dataIndex: 'cancelledQuantity',
      key: 'cancelledQuantity',
    },
    {
      title: 'item Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'barcode',
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      key: 'mrp',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: 'Transfer From Location',
      dataIndex: 'transferFromLocation',
      key: 'transferFromLocation',
    },
  ];

  updatePurchaseOrderStatus = (purchaseOrderStatus: ChallanNode) => {
    const allPurchaseOrderStatus: PurchaseOrderData[] = [];
    const productDetails: ProductDetails[] = JSON.parse(
      purchaseOrderStatus.productDetails,
    );

    productDetails.forEach((productDetail) => {
      allPurchaseOrderStatus.push({
        purchaseOrderDate: purchaseOrderStatus.purchaseOrder?.date,
        purchaseOrderNumber: purchaseOrderStatus.purchaseOrder?.purchaseOrderNo,
        vendorName: purchaseOrderStatus.purchaseOrder?.vendor?.name,
        productId: productDetail.productId,
        productName: productDetail.productName,
        quantity: productDetail.orderQuantity,
        rate: productDetail.rate,
        receivedDate: purchaseOrderStatus.date,
        challanNumber: purchaseOrderStatus.challanNo,
        vendorChallanDate: purchaseOrderStatus.vendorChallanDate,
        vendorChallanNumber: purchaseOrderStatus.vendorChallanNo,
        receivedQuantity: productDetail.receivedQuantity,
        pendingQuantity:
          (productDetail.orderQuantity &&
            productDetail.orderQuantity - productDetail.receivedQuantity) ||
          0,
        cancelledQuantity: 0,
        itemName: productDetail.productName,
        barcode: '',
        mrp: null,
        transferFromLocation: '',
        remarks: purchaseOrderStatus.purchaseOrder?.remarks,
      });
    });

    return allPurchaseOrderStatus;
  };

  downloadCsv = () => {
    const queryParams = `companyId=${this.state.companyId}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;

    fetch(PURCHASE_ORDER_STATUS_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) =>
        downloadToFile(
          csv,
          `purchase-order-status-report-${new Date()}`,
          'PURCHASE ORDER STATUS REPORT',
          this.props.company?.name || '',
          'Head Office',
          this.state.startDate,
          this.state.endDate,
          '',
        ),
      );
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.companyId) {
      return <Skeleton active />;
    }

    const { companyId, currentPage, startDate, endDate, after } = this.state;
    const variables = { companyId, startDate, endDate, after };
    return (
      <>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={PurchaaseOrderStatusReportQuery}
          variables={variables}
          render={(
            response: { allChallans: ChallanNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (response && companyId) {
              const data: PurchaseOrderData[] = [];
              response.allChallans.edges.forEach(
                (value) =>
                  value.node &&
                  data.push(...this.updatePurchaseOrderStatus(value.node)),
              );
              return [
                <Table
                  key="table"
                  dataSource={data}
                  columns={this.purchaseOrderStatusColumn()}
                  rowKey={(obj, index) => index.toString()}
                  pagination={false}
                  scroll={{ x: true }}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allChallans.pageInfo.endCursor,
                      response.allChallans.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv()}
        />
      </>
    );
  }
}

interface State {
  companyId?: string | null;
  currentPage: number;
  startDate: string;
  endDate: string;
  after: string;
}

interface Props {
  company: CompanyNodeEdge | null;
}

import * as React from 'react';
import { AreaInput, AreaNode } from '../../../schema';
import { Button, notification } from 'antd';
import { AreaForm } from './AreaForm';
import { AreaService } from '../../../service/AreaService';
import { AREA_DATA } from './constants';

export class AddArea extends React.Component<Props, State> {
  areaService = new AreaService();

  constructor(props) {
    super(props);
    this.state = {
      area: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
    };
  }

  handlePropsValueUpdate = (props: Props) => {
    const area = props.area
      ? {
          ...props.area,
          districtId: props.area?.district?.id,
        }
      : JSON.parse(JSON.stringify(AREA_DATA));
    return area;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ area: this.handlePropsValueUpdate(nextProps) });
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const area = { ...this.state.area };
    area[e.target.name] = e.target.value;
    this.setState({ area });
  };

  saveArea = () => {
    this.setState({ buttonDisabled: true });
    this.areaService?.addArea(
      this.state.area,
      (response) => {
        notification.success({
          message: 'Area ' + this.state.area.id ? 'Updated' : 'Added',
          description: `Area ${this.state.area.name} was successfully ${
            this.state.area.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message: 'Area ' + this.state.area.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      area: JSON.parse(JSON.stringify(AREA_DATA)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <AreaForm area={this.state.area} onChange={this.onChange} />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%', marginTop: '50px' }}
        />
        <Button
          type="primary"
          onClick={this.saveArea}
          children="Submit"
          style={{ width: '50%', marginTop: '50px' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  handleTabChange?: (activeKey: string) => void;
  area: AreaNode;
}

interface State {
  area: AreaInput;
  buttonDisabled: boolean;
}

import * as React from 'react';
import {
  Row,
  Col,
  Skeleton,
  Spin,
  Table,
  Icon,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  SalesEdges,
  salesQuery,
  SalesNodeEdgeQuery,
  SalesNodeEdgeMutation,
} from './constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { SalesNodeConnection } from '../../schema';
import { TSInput } from '../common/TSInput';
import { DateService } from '../../service/DateService';
import { Pagination } from 'sha-el-design';
import { GraphqlQuery } from 'requestapijs';
import moment from 'moment';
import { SalesService } from '../../service/SalesService';
import { ColumnProps } from 'antd/lib/table';
import { checkAddPermission } from '../helper';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../user/constants';

export class SalesCancellation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      after: '',
      currentPage: 1,
      billNo: '',
      updateSalesList: false,
      loading: {},
      remarks: {},
      user: null,
    };
  }

  salesService = new SalesService();
  dateService = new DateService();
  userService = new UserService();
  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  salesColumn = () => {
    let salesColumns: ColumnProps<SalesEdges>[] = [
      {
        title: 'Bill No',
        dataIndex: 'node.billNo',
        key: 'billNo',
        render: (text, obj, index) => (
          <a
            onClick={() => {
              obj.node.branch?.id &&
                this.props.handleSelectedSales(obj, '5', obj.node.branch.id);
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (text, obj, index) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Customer',
        dataIndex: 'node.customer.name',
        key: 'customer',
      },
      {
        title: 'Mode',
        key: 'paymentMode',
        render: (value, record, index) =>
          ((record as SalesEdges).node.paymentModes as any).edges
            .map((pm) => pm.node.paymentType)
            .join(', '),
      },
      {
        title: 'Bill Amount',
        dataIndex: 'node.billAmount',
        key: 'billAmount',
        render: (value, record, index) => Number(Number(value).toFixed(2)),
      },
    ];
    if (this.props.company && this.props.company.gstRate) {
      salesColumns = [
        ...salesColumns,
        {
          title: 'Disc',
          dataIndex: 'node.discount',
          key: 'discount',
        },
      ];
    }
    salesColumns = [
      ...salesColumns,
      {
        title: 'Gross Amount',
        dataIndex: 'node.grossAmount',
        key: 'grossAmount',
        render: (value, record: SalesEdges) =>
          this.props.company && this.props.company.gstRate
            ? value
            : Number(record.node.totalAmount?.toFixed(2)),
      },
      {
        title: 'Net Amount',
        dataIndex: 'node.netAmount',
        key: 'netAmount',
        render: (value, record, index) => Number(Number(value).toFixed(2)),
      },
      {
        title: 'Remarks',
        key: 'remarks',
        render: (value, record, index) => (
          <TSInput
            error=""
            name="remarks"
            value={this.state.remarks[index] || record.node.remarks}
            type="text"
            onChange={(e) =>
              this.setState({ remarks: { [index]: e.target.value } })
            }
            placeholder="Remarks"
          />
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        render: (text, obj, index) => (
          <div>
            <Tooltip title="Cancel">
              <Popconfirm
                title="Are you sure to cancel?"
                onConfirm={() =>
                  this.cancelSales(
                    {
                      ...obj,
                      node: { ...obj.node, remarks: this.state.remarks[index] },
                    },
                    index,
                  )
                }
                okText="Yes"
                cancelText="No"
                disabled={
                  obj.node.cancelled ||
                  (!!this.state.user && !checkAddPermission(this.state.user))
                }
              >
                <Button
                  type="danger"
                  children={
                    this.state.loading && this.state.loading[index] ? (
                      <Spin />
                    ) : (
                      <Icon type="close" />
                    )
                  }
                  disabled={
                    obj.node.cancelled ||
                    (!!this.state.user && !checkAddPermission(this.state.user))
                  }
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Receipt">
              <Button
                onClick={() => {
                  obj.node.branch?.id &&
                    this.props.handleSelectedSales(
                      obj,
                      '6',
                      obj.node.branch.id,
                    );
                }}
                style={{ marginLeft: '5px' }}
              >
                <Icon type="download" />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];
    return salesColumns;
  };

  cancelSales = (salesEdge: SalesEdges, index: number) => {
    const loading = {};
    loading[index] = true;
    this.setState({ loading });
    const sales: SalesNodeEdgeMutation = JSON.parse(
      JSON.stringify(salesEdge.node),
    );
    sales.cancelled = true;
    sales.billNo += '-C';
    sales.branchId = (sales as any as SalesNodeEdgeQuery).branch?.id;
    sales.customerId =
      (sales as any as SalesNodeEdgeQuery).customer &&
      (sales as any as SalesNodeEdgeQuery).customer?.id;
    sales.productDetails = (
      sales as any as SalesNodeEdgeQuery
    ).productDetails?.edges.map((pd) => {
      pd.node.productId = pd.node.product?.id;
      pd.node.movementType = 'SALE_CANCEL';
      return pd.node;
    });
    sales.oldProductDetails = (
      sales as any as SalesNodeEdgeQuery
    ).oldProductDetails?.edges.map((opd) => {
      opd.node.productId = opd.node.product?.id;
      opd.node.movementType = 'EXCHANGE_CANCEL';
      return opd.node;
    });
    sales.paymentModes = (
      sales as any as SalesNodeEdgeQuery
    ).paymentModes.edges.map((pm) => ({ ...pm.node }));
    delete (sales as any as SalesNodeEdgeQuery).customer;
    delete (sales as any as SalesNodeEdgeQuery).branch;
    delete (sales as any as SalesNodeEdgeQuery).salesPerson;
    this.salesService?.addSales(
      sales,
      () => {
        this.setState({ loading: {}, updateSalesList: true });
        notification.success({
          message: 'Sale Cancelled',
          description: `Sale for bill no ${sales.billNo} was successfuly Cancelled`,
        });
      },
      (error) => {
        this.setState({ loading: {} });
        notification.error({
          message: 'Sale Cancellation Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.props.company) return <Spin />;

    const { after, currentPage, billNo, updateSalesList } = this.state;
    const {
      company: { id: companyId },
    } = this.props;
    const date_Lte = this.dateService?.date$.value;
    const variables = {
      companyId,
      branchId: '',
      date_Lte,
      billNo,
      after,
      updateSalesList,
      first: 10,
    };
    return (
      <div>
        <Row>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.billNo}
              onChange={(e) => this.setState({ billNo: e.target.value })}
              name="billNo"
              type="text"
              addonBefore="Search"
              placeholder="Enter Bill No"
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={salesQuery}
          variables={variables}
          render={(
            response: { allSales: SalesNodeConnection },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allSales.edges as any}
                  columns={this.salesColumn()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={30}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allSales.pageInfo.endCursor,
                      response.allSales.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  handleSelectedSales: (
    selectedSales: SalesEdges,
    activeKey: string,
    branchId: string,
  ) => void;
}

interface State {
  billNo: string;
  after: string;
  currentPage: number;
  updateSalesList: boolean;
  loading: {
    [index: number]: boolean;
  };
  remarks: {
    [index: number]: string;
  };
  user?: UserNodeEdge | null;
}

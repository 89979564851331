import * as React from 'react';
import { Button, notification, Modal } from 'antd';
import { GiftService } from '../../service/GiftService';
import { CustomerService } from '../../service/CustomerService';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  GiftNodeEdgeQuery,
  GiftEdges,
  GiftNodeEdgeMutation,
  giftObject,
} from './constants';
import { GiftForm } from './GiftForm';
import { ProductNode, CustomerNode } from '../../schema';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';

export class AddGift extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gift: this.handlePropsUpdate(props),
      buttonDisabled: false,
      productObjList: [],
      customerObj: null,
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
    };
  }

  giftService = new GiftService();
  customerService = new CustomerService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ gift: this.handlePropsUpdate(nextProps) });
  }

  handlePropsUpdate(nextProps: Props) {
    const gift: any = nextProps.gift
      ? {
          ...nextProps.gift,
          customerId: nextProps.gift.customer && nextProps.gift.customer.id,
          productDetails: nextProps.gift.productDetails?.edges.map((pd) => ({
            ...pd.node,
            productId: pd.node.product?.id,
          })),
        }
      : JSON.parse(JSON.stringify(giftObject));
    if (nextProps.gift) {
      delete gift.branch;
      delete gift.customer;
    }
    return gift;
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const gift = { ...this.state.gift };
    gift[e.target.name] = e.target.value;
    this.setState({ gift });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.gift.productDetails) {
      nextState.gift.productDetails[index][e.target.name] = e.target.value;
      if (
        nextState.gift.productDetails[index].quantity &&
        nextState.gift.productDetails[index].rate
      ) {
        nextState.gift.productDetails[index].amount =
          Number(nextState.gift.productDetails[index].quantity) *
          Number(nextState.gift.productDetails[index].rate);
      }
      const totalMrp = nextState.gift.productDetails.reduce(
        (sum, p) => (p.amount ? sum + p.amount : sum),
        0,
      );
      nextState.gift.totalMrp = totalMrp;
      nextState.gift.totalCostAmount = nextState.gift.productDetails.reduce(
        (sum, p) =>
          p.quantity && p.purchaseRate
            ? sum + p.quantity * p.purchaseRate
            : sum,
        0,
      );
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    if (nextState.gift.productDetails) {
      nextState.gift.productDetails[index].rate = productObj.sellingRate;
      nextState.gift.productDetails[index].purchaseRate =
        productObj.purchaseRate;
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.gift.productDetails?.push({
        date: null,
        productId: '',
        quantity: null,
        rate: null,
        amount: 0,
        movementType: 'GIFT',
        companyId: null,
      });
    } else {
      nextState.gift.productDetails?.splice(index, 1);
    }
    this.setState(nextState);
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.gift.customerId = customerObj.id;
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveGift = () => {
    this.setState({ buttonDisabled: true });
    const currentCustomer = this.state.customerObj;
    this.giftService?.addGift(
      this.state.gift,
      () => {
        if (!this.props.gift) {
          this.setState({
            gift: JSON.parse(JSON.stringify(giftObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        currentCustomer &&
          this.customerService?.addCustomer({ ...currentCustomer });
        notification.success({
          message: 'Gift ' + this.state.gift.id ? 'Updated' : 'Added',
          description: `Gift was successfuly ${
            this.state.gift.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message: 'Gift ' + this.state.gift.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({ gift: JSON.parse(JSON.stringify(giftObject)) });
  };

  render() {
    return (
      <div>
        <GiftForm
          values={this.state.gift}
          onChange={this.onChange}
          company={this.props.company}
          giftList={this.props.giftList}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          isEditActive={this.props.isEditActive}
          handleModalAction={this.handleModalAction}
          customerObjChangehandler={this.customerObjChangehandler}
          updateCount={this.state.updateCount}
        />
        <Modal
          title="Add Customer"
          visible={this.state.modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            company={this.props.company}
            onCustomerAdded={() =>
              this.setState({ updateCount: this.state.updateCount + 1 })
            }
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={this.state.modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={this.state.updateCount}
            showOnlyList
          />
        </Modal>
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveGift}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  gift?: GiftNodeEdgeQuery;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  giftList: GiftEdges[];
  isEditActive: boolean;
}

interface State {
  gift: GiftNodeEdgeMutation;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  customerObj: CustomerNode | null;
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
}

import { BehaviorSubject } from 'rxjs';
import {
  TransferEdges,
  TransferQueryResponse,
  TransferNode,
  AddTransferVariable,
  transferMutation,
  transferReturnQuery,
} from '../components/Transfer/constants';
import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { ProductDetailNodeEdge } from '../components/Sales/constants';
import { DateService } from './DateService';
import { CommissionDetailsNode } from '../schema';

export class TransferReturnService {
  static instance: TransferReturnService;
  constructor() {
    if (TransferReturnService.instance) {
      return TransferReturnService.instance;
    } else {
      TransferReturnService.instance = this;
    }
    this.onInit();
  }

  public readonly transferReturn$ = new BehaviorSubject<TransferEdges[]>([]);
  companyService = new CompanyService();
  dateService = new DateService();

  onInit() {
    this.companyService?.selectedBussiness$.subscribe((bussiness) => {
      if (!bussiness) return;
      bussiness.type === 'branch'
        ? this.getAll({ companyId: '', fromLocationId: bussiness.id })
        : this.getAll({ companyId: bussiness.id, fromLocationId: '' });
    });
  }

  getAll({ companyId: companyId, fromLocationId: fromLocationId }) {
    Api.graphql<
      { companyId: string; fromLocationId: string; date_Lte?: string },
      TransferQueryResponse
    >(
      API_URL,
      { companyId, fromLocationId, date_Lte: this.dateService?.date$.value },
      transferReturnQuery,
      HEADER,
      (response) => this.transferReturn$.next(response.data.allTransfers.edges),
      (error) => error,
    );
  }

  addTransferReturn(
    data: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete data.fromLocation;
    delete data.toLocation;
    data.productDetails = data.productDetails.map((p) => {
      p.date = data.date;
      p.movementForId = data.toLocationId;
      p.movementFromId = data.fromLocationId;
      p.companyId = this.companyService?.company$.getValue()?.id;
      delete p.product;
      delete p.movementFor;
      delete p.movementFrom;
      return p;
    });
    Api.graphql<AddTransferVariable, {}>(
      API_URL,
      {
        input: {
          transfer: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      transferMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.onInit();
      },
      (error) => onError(error),
    );
  }
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  ProductionInput,
  CreateProductionInput,
  CreateProductionPayload,
  ConversionDetailNode,
} from '../schema';
import { productionMutation } from '../components/Production/Production/constants';

export class ProductionService {
  static instance;

  constructor() {
    if (ProductionService.instance) {
      return ProductionService.instance;
    } else {
      ProductionService.instance = this;
    }
  }

  companyService = new CompanyService();

  addProduction(
    data: ProductionInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.branchId = this.companyService?.selectedBussiness$.getValue()?.id;
    data.conversionDetails.map((conversion) => {
      conversion.date = data.date;
      conversion.sourceBranchId = data.branchId;
      conversion.companyId = this.companyService?.company$.getValue()?.id;
      delete (conversion as any as ConversionDetailNode).conversion;
      return conversion;
    });
    Api.graphql<{ input: CreateProductionInput }, CreateProductionPayload>(
      API_URL,
      {
        input: {
          production: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      productionMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

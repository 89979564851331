import * as React from 'react';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { requestQuery, locationObjForGroup } from './constants';
import { Skeleton, Table, Tabs, Icon } from 'antd';
import { RequestNodeConnection, RequestNodeEdge } from '../../schema';
import { AddRequest } from './AddRequest';
import { checkAddPermission } from '../helper';
import { CompanyService } from '../../service/CompanyService';
import { Pagination } from 'sha-el-design';
import { UserService } from '../../service/UserService';
import { User, UserNodeEdge } from '../user/constants';
import moment from 'moment';

export class Request extends React.Component<Props, State> {
  private batchSize = 20;
  private afterStack = [''];
  companyService = new CompanyService();
  userService = new UserService();

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      business: null,
      activeKey: '1',
      currentPage: 1,
      after: '',
      selectedRequest: null,
      isEditActive: false,
      user: null,
    };
  }

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((userNode) =>
      this.setState({ user: userNode?.user }),
    );
    this.userService?.user$.subscribe((userForGroup) =>
      this.setState(userForGroup),
    );
  }

  columns = () => [
    {
      title: 'Sl. No.',
      key: 'sl_no',
      render: (_, record: RequestNodeEdge, index: number) => {
        return (
          <a
            onClick={() =>
              this.setState({
                selectedRequest: record,
                activeKey: '2',
                isEditActive: true,
              })
            }
          >
            {(this.state.currentPage - 1) * this.batchSize + index + 1}
          </a>
        );
      },
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (text) => text && moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'From',
      key: 'from',
      dataIndex: 'node.branch.name',
    },
    {
      title: 'Current Status',
      key: 'status',
      dataIndex: 'node.currentLocation',
      render: (text, record: RequestNodeEdge) => {
        switch (text) {
          case 'STORE':
            return 'Pending for confirmation of availability at Central Store';

          case 'PRODUCTION':
            return 'Details sent to Production Department';

          case 'MARKETING':
            if (typeof record.node?.isAvailable === 'boolean') {
              return 'Reverted back from central store to Marketing Department with the status of availability';
            }
            return 'Waiting at the Marketing Department to forward to Central Store';
        }
      },
    },
    {
      title: 'Availability',
      key: 'availability',
      dataIndex: 'node.isAvailable',
      align: 'center' as const,
      render: (text) =>
        typeof text === 'boolean' ? (text ? 'Yes' : 'No') : '-',
    },
  ];

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedRequest: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.business || !this.state.company) return <Skeleton active />;
    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      user,
      business,
    } = this.state;
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                List Request
              </span>
            }
            key="1"
            closable={false}
          >
            <GraphqlQuery
              queryString={requestQuery}
              variables={{
                after,
                companyId,
                first: 20,
                currentLocation:
                  locationObjForGroup[user?.user?.groups?.[0]?.name],
                branchId: business.type === 'branch' ? business.id : '',
                activeKey,
              }}
              render={(
                response: { allRequests: RequestNodeConnection },
                error,
                loading,
              ) => {
                if (error) {
                  return <h2>Something Went Wrong!!</h2>;
                }
                if (loading) {
                  return <Skeleton active />;
                }
                if (response && companyId) {
                  return [
                    <Table
                      key="table"
                      dataSource={response.allRequests.edges}
                      columns={this.columns()}
                      rowKey={(obj) => obj.node?.id || ''}
                      pagination={false}
                    />,
                    <Pagination
                      key="pagination"
                      totalCount={0}
                      currentPage={currentPage}
                      batchSize={20}
                      cursorBasedPagination
                      onChange={(_p, _ps, next) =>
                        this.onPageChange(
                          next,
                          response.allRequests.pageInfo.endCursor,
                          response.allRequests.pageInfo.hasNextPage,
                        )
                      }
                      style={{ float: 'right' }}
                    />,
                  ];
                }
                return <Skeleton active />;
              }}
            />
          </Tabs.TabPane>
          {(this.state.activeKey === '2' ||
            this.state.business.type === 'branch' ||
            // tslint:disable-next-line: max-line-length
            this.state.user?.user?.groups?.[0]?.name === 'Inventory') &&
            checkAddPermission(this.state.user) && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="plus" />
                    Add Request
                  </span>
                }
                key="2"
              >
                <AddRequest
                  company={this.state.company}
                  business={this.state.business}
                  selectedRequest={this.state.selectedRequest?.node}
                  isEditActive={this.state.isEditActive}
                  handleTabChange={this.handleTabChange}
                  user={this.state.user}
                />
              </Tabs.TabPane>
            )}
        </Tabs>
      </div>
    );
  }
}

interface State {
  company: CompanyNodeEdge | null;
  business: Bussiness | null;
  activeKey: string;
  currentPage: number;
  after: string;
  selectedRequest: RequestNodeEdge | null;
  isEditActive: boolean;
  user: any;
}

interface Props {}

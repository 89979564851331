import Api from 'requestapijs';
import {
  AddMemoPayload,
  AddMemoVariables,
  memoMutation,
  MemoNode,
} from '../components/Memo/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class MemoService {
  static instance;

  constructor() {
    if (MemoService.instance) {
      return MemoService.instance;
    } else {
      MemoService.instance = this;
    }
  }

  companyService = new CompanyService();

  addMemo(
    data: MemoNode,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    delete data.company;
    delete data.location;
    Api.graphql<AddMemoVariables, AddMemoPayload>(
      API_URL,
      {
        input: {
          memo: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      memoMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import { PageInfo } from '../../../schema';
import { BranchNodeEdge } from '../Branch/constants';
import { CompanyNodeEdge } from '../Company/constants';

export interface SalesPersonNodeEdge {
  id?: string;
  name: string;
  branchId?: string;
  branch?: BranchNodeEdge;
  companyId?: string;
  company?: CompanyNodeEdge;
}

export interface SalesPersonError {
  name: string;
}

export const salesPersonObject: SalesPersonNodeEdge = {
  name: '',
};

export const salesPersonErrorObject: SalesPersonError = {
  name: '',
};

export const allSalesPersonQuery = `
query SalesPersonList($branchId: ID, $companyId: ID) {
  allSalesPerson(branchId: $branchId, companyId: $companyId) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id,
        name
      }
    }
  }
}
`;

export interface SalesPersonQueryVariable {
  name: string;
  branchId: string;
  companyId: string;
}

export interface SalesPersonEdges {
  cursor: string;
  node: SalesPersonNodeEdge;
}

export interface SalesPersonQueryResponse {
  allSalesPerson: {
    pageInfo: PageInfo;
    edges: SalesPersonEdges[];
  };
}

export interface SalesPersonVariables {
  input: {
    salesPerson: SalesPersonNodeEdge;
  };
}

export const salesPersonMutation = `
mutation addSalesPerson($input: CreateSalesPersonInput!) {
  createSalesPerson(input: $input) {
    newSalesPerson
  }
}
`;

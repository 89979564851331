import * as React from 'react';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  Skeleton,
  Table,
  Row,
  Col,
  Button,
  DatePicker,
  Divider,
  Icon,
} from 'antd';
import { productIssueReportQuery } from './constants';
import { ProductIssue } from '../../schema';
import { PRODUCT_ISSUE_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class ProductIssueReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: 0,
      currentPage: 1,
      updateDataCount: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Job Order No',
      dataIndex: 'jobOrderNo',
      key: 'jobOrderNo',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'location',
      render: (data) => data || 'Head Office',
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Barcode 1',
      dataIndex: 'oldBarcode',
      key: 'oldBarcode',
    },
    {
      title: 'Barcode 2',
      dataIndex: 'newBarcode',
      key: 'newBarcode',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sortingValue: (text) => text,
    },
    {
      title: 'Division',
      dataIndex: 'division',
      key: 'division',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    {
      title: 'Cost Price',
      dataIndex: 'costPrice',
      key: 'costPrice',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Cost Value',
      dataIndex: 'costValue',
      key: 'costValue',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(PRODUCT_ISSUE_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `product-issue-${new Date()}`,
          'PRODUCT ISSUE REPORT',
          this.props.company?.name || '',
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { startDate, endDate, after, currentPage, updateDataCount } =
      this.state;
    const { company } = this.props;
    const variables = {
      after,
      companyId: company?.id,
      startDate,
      endDate,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={
                (this.state.startDate && moment(this.state.startDate)) || null
              }
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(this.state.endDate && moment(this.state.endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={productIssueReportQuery}
          variables={variables}
          render={(
            response: { productIssue: ProductIssue[] },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something went wrong</h3>;
            }
            if (response) {
              return [
                <Table
                  key="table"
                  dataSource={response.productIssue}
                  columns={this.columns()}
                  rowKey={(obj) => `${obj.jobOrderNo}${obj.oldBarcode}`}
                  pagination={false}
                  style={{ overflowX: 'scroll' }}
                />,
                <Row key="Pagination">
                  <Col offset={21} span={1}>
                    <Button onClick={this.onPrev} disabled={currentPage === 1}>
                      Prev
                    </Button>
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button type="primary">{currentPage}</Button>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={this.onNext}
                      disabled={
                        !response.productIssue?.length ||
                        response.productIssue?.length < 20
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
      </div>
    );
  }
}

interface State {
  startDate: string;
  endDate: string;
  after: number;
  currentPage: number;
  updateDataCount: number;
}

interface Props {
  company: CompanyNodeEdge | null;
}

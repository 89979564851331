import * as React from 'react';
import moment from 'moment';
import { Button, Col, Collapse, Divider, Row, Spin } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design/lib';
import { ProductCategoryNodeConnection } from '../../schema';
import { TSInput } from '../common/TSInput';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { productCategoryQuery } from '../OpeningStock/constants';
import { PhysicalStockReport } from './PhysicalStockReport';
import { PHYSICAL_STOCK_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class PhysicalStockReportCategory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      categoryId: '',
      afterCategory: '',
      afterProduct: '',
      currentPageCategory: 1,
      currentPageProduct: 1,
    };
  }

  downloadCsv = () => {
    let queryParams = `companyId=${this.props.company.id}`;
    queryParams +=
      this.props.business.type === 'branch'
        ? `&branchId=${this.props.business.id}`
        : '';

    fetch(PHYSICAL_STOCK_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) =>
        downloadToFile(
          csv,
          `physical-stock-report-${new Date()}`,
          'PHYSICAL STOCK REPORT',
          this.props.business.type === 'branch'
            ? this.props.business.companyName || ''
            : this.props.business.name,
          this.props.business.type === 'branch'
            ? this.props.business.name
            : 'Head Office',
          moment().format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStackCategory = [''];

  onPageChangeCategory = (
    next: boolean,
    afterCategory: string,
    nextPage: boolean,
  ) => {
    if (next && nextPage) {
      this.setState({
        afterCategory,
        currentPageCategory: this.state.currentPageCategory + 1,
      });
      this.afterStackCategory.push(afterCategory);
    } else if (!next && this.afterStackCategory.length > 1) {
      this.afterStackCategory.pop();
      this.setState({
        afterCategory:
          this.afterStackCategory[this.afterStackCategory.length - 1],
        currentPageCategory: this.state.currentPageCategory - 1,
      });
    }
  };

  afterStackProduct = [''];

  onPageChangeProduct = (
    next: boolean,
    afterProduct: string,
    nextPage: boolean,
  ) => {
    if (next && nextPage) {
      this.setState({
        afterProduct,
        currentPageProduct: this.state.currentPageProduct + 1,
      });
      this.afterStackProduct.push(afterProduct);
    } else if (!next && this.afterStackProduct.length > 1) {
      this.afterStackProduct.pop();
      this.setState({
        afterProduct: this.afterStackProduct[this.afterStackProduct.length - 1],
        currentPageProduct: this.state.currentPageProduct - 1,
      });
    }
  };

  render() {
    const {
      afterCategory,
      afterProduct,
      currentPageCategory,
      currentPageProduct,
    } = this.state;

    return (
      <Row>
        <Col span={8}>
          <TSInput
            error={''}
            key="date"
            name="date"
            value={moment().format('YYYY-MM-DD')}
            addonBefore="Date"
          />
        </Col>
        <Col span={16}>
          <TSInput
            error={''}
            key="id"
            name="branchId"
            value={this.props.business.name}
            addonBefore="Branch"
          />
        </Col>
        <Divider />
        <GraphqlQuery
          queryString={productCategoryQuery}
          variables={{
            companyId: this.props.company.id,
            after: afterCategory,
            orderBy: 'name',
          }}
          render={(
            response: {
              allProductCategories: ProductCategoryNodeConnection;
            },
            error,
            loading,
          ) => {
            if (loading) return <Spin />;
            if (this.props.company && response)
              return [
                <Collapse
                  key="collapse"
                  accordion={true}
                  onChange={(key) => {
                    this.setState({
                      categoryId: key && (key.slice(2) as string),
                    });
                  }}
                >
                  {response.allProductCategories.edges.map((pc, indexPg) => (
                    <Collapse.Panel
                      header={pc.node?.name}
                      key={'pc' + pc.node?.id}
                    >
                      <PhysicalStockReport
                        business={this.props.business}
                        company={this.props.company}
                        categoryId={this.state.categoryId}
                        after={afterProduct}
                        currentPage={currentPageProduct}
                        onPageChange={this.onPageChangeProduct}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>,
                <div>
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPageCategory}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChangeCategory(
                        next,
                        response.allProductCategories.pageInfo.endCursor,
                        response.allProductCategories.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />
                </div>,
              ];
            return <Spin />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv()}
        />
        ,
      </Row>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business: Bussiness;
}

interface State {
  categoryId: string;
  afterCategory: string;
  afterProduct: string;
  currentPageCategory: number;
  currentPageProduct: number;
}

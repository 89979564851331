import * as React from 'react';
import { BranchNodeEdge, BranchError, category } from './constants';
import { TSInput } from '../../common/TSInput';
import { Row, Col, Select } from 'antd';
import { BranchDropDown } from './BranchDropdown';
import { CompanyNodeEdge } from '../Company/constants';

export const BranchForm: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Row>
      <Col span={12}>
        <TSInput
          error={props.error.name}
          value={props.values.name}
          onChange={props.onChange}
          name="name"
          type="text"
          addonBefore="Name"
        />
      </Col>
      {props.company.name.toLowerCase().includes('khadi') && (
        <Col span={12}>
          <TSInput
            error={''}
            value={props.values.initials}
            onChange={props.onChange}
            maxLength={2}
            placeholder="Only 2 letters allowed"
            name="initials"
            type="text"
            addonBefore="Initials"
          />
        </Col>
      )}
      <Col span={12}>
        <TSInput
          error={''}
          value={props.values.address}
          onChange={props.onChange}
          name="address"
          type="text"
          addonBefore="Address"
        />
      </Col>
      <Col span={12}>
        <TSInput
          error={props.error.state}
          value={props.values.state}
          onChange={props.onChange}
          name="state"
          type="text"
          addonBefore="State"
        />
      </Col>
      <Col span={12}>
        <TSInput
          error={props.error.contactNo}
          value={props.values.contactNo}
          onChange={props.onChange}
          name="contactNo"
          type="text"
          addonBefore="Contact No"
        />
      </Col>
      <Col span={12}>
        <TSInput
          error={''}
          value={props.values.email}
          onChange={props.onChange}
          name="email"
          type="text"
          addonBefore="Email"
        />
      </Col>
      <Col span={12}>
        <TSInput
          error={''}
          value={props.values.incharge}
          onChange={props.onChange}
          name="incharge"
          type="text"
          addonBefore="Incharge"
        />
      </Col>
      {props.company.name.toLowerCase().includes('khadi') && (
        <Col span={12}>
          <TSInput
            error={''}
            value={props.values.favourOf}
            onChange={props.onChange}
            name="favourOf"
            type="text"
            addonBefore="Favour of"
          />
        </Col>
      )}
      {props.branchType !== 'department' && (
        <div>
          <Col span={12}>
            <TSInput
              error={props.error.gstin}
              value={props.values.gstin}
              onChange={props.onChange}
              name="gstin"
              type="text"
              addonBefore="GST IN"
            />
          </Col>
          {(props.company.name.toLowerCase() === 'jharcraft' ||
            props.company?.name.toLowerCase().includes('khadi')) && (
            <div>
              <Col span={12}>
                <Select
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                  placeholder="Select Category"
                  value={props.values.category || undefined}
                  onChange={(e) =>
                    props.onChange({ target: { name: 'category', value: e } })
                  }
                  showSearch
                  allowClear
                >
                  {category.map((value) => (
                    <Select.Option key={value} value={value}>
                      {value.split('_').join(' ')}
                    </Select.Option>
                  ))}
                </Select>
                <span style={{ color: 'red' }}>{props.error.category}</span>
              </Col>
              <Col span={12}>
                <Select
                  style={{ width: '100%', marginTop: '5px' }}
                  placeholder="Select Parent Category"
                  value={props.values.parentCategory || undefined}
                  onChange={(e) =>
                    props.onChange({
                      target: { name: 'parentCategory', value: e },
                    })
                  }
                  showSearch
                  allowClear
                >
                  {props.parentCategory.map((value) => (
                    <Select.Option key={value} value={value}>
                      {value.split('_').join(' ')}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={12}>
                <BranchDropDown
                  onSelect={(e) =>
                    props.onChange({
                      target: { name: 'parentLocationId', value: e },
                    })
                  }
                  allowClear
                />
              </Col>
            </div>
          )}
        </div>
      )}
    </Row>
  );
};

interface Props {
  values: BranchNodeEdge;
  error: BranchError;
  parentCategory: string[];
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } },
  ) => void;
  company: CompanyNodeEdge;
  branchType: 'branch' | 'department';
}

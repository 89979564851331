import * as React from 'react';
import '../assets/scss/App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Dashboard } from './Dashboard/Dashboard';
import { Login } from './user/Login';
import { GraphqlClient } from 'requestapijs';
import { API_URL, HEADER } from '../config';

export interface AppProps {}

new GraphqlClient({
  url: API_URL,
  headers: HEADER,
});

export default class App extends React.Component<AppProps, unknown> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
    );
  }
}

import * as React from 'react';
import { DepositInput, DepositNode } from '../../schema';
import { DepositError, depositObject, depositErrorObject } from './constants';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { DepositForm } from './DepositForm';
import { DepositService } from '../../service/DepositService';
import { notification, Popconfirm, Button } from 'antd';

export class AddDeposit extends React.Component<Props, State> {
  depositService = new DepositService();

  constructor(props: Props) {
    super(props);

    this.state = {
      values: props.deposit || JSON.parse(JSON.stringify(depositObject)),
      error: JSON.parse(JSON.stringify(depositErrorObject)),
      buttonDisabled: false,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const values = { ...this.state.values };
    values[e.target.name] = e.target.value;
    this.setState({ values });
  };

  checkError = () => {
    const values = { ...this.state.values };
    const error = { ...this.state.error };
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (!values[errKey]) {
        error[errKey] = `This is a required field`;
        isError = true;
      }
    });
    this.setState({ error });
    return isError;
  };

  saveDeposit = () => {
    if (this.checkError()) {
      return;
    }
    this.setState({ buttonDisabled: true });

    this.depositService?.addDeposit(
      this.state.values,
      () => {
        this.clear();
        notification.success({
          message: this.state.values.id ? 'Updated' : 'Added',
          description: `Deposit was successfully ${
            this.state.values.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Deposit ' + this.state.values.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      values: JSON.parse(JSON.stringify(depositObject)),
      buttonDisabled: false,
    });
  };

  render() {
    const { values, error, buttonDisabled } = this.state;
    const { deposit, isEditActive, company, business } = this.props;
    return (
      <div>
        <DepositForm
          values={values}
          error={error}
          isEditActive={isEditActive}
          onChange={this.onChange}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveDeposit}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  deposit: DepositNode | null;
  isEditActive: boolean;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  business: Bussiness;
}

interface State {
  values: DepositInput;
  error: DepositError;
  buttonDisabled: boolean;
}

import * as React from 'react';
import { PurchaseNodeEdgeQuery, purchaseQuery } from './constants';
import { Table, Tabs, Icon, Spin, Skeleton, Row, Col, DatePicker } from 'antd';
import { checkAddPermission } from '../helper';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { AddPurchase } from './AddPurchase';
import moment from 'moment';
import { ItemWisePurchaseReport } from './ItemWisePurchaseReport';
import { VendorWisePurchaseReport } from './VendorWisePurchaseReport';
import { GraphqlQuery } from 'requestapijs';
import { PurchaseNodeConnection, PurchaseNodeEdge } from '../../schema';
import { Pagination } from 'sha-el-design';
import { UserNodeEdge } from '../../components/user/constants';
import { UserService } from '../../service/UserService';

export class Purchase extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedPurchase: null,
      company: null,
      isEditActive: false,
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedPurchase: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  purchaseColumns = () => {
    const purchaseColumns: any[] = [];
    if (this.state.company?.name.includes('Seva Sadan')) {
      purchaseColumns.push({
        title: 'Goods Receiving No',
        dataIndex: 'node.receivingChallanNo',
        key: 'receivingChallanNo',
      });
    }
    purchaseColumns.push(
      {
        title: 'Bill No',
        dataIndex: 'node.billNo',
        key: 'billNo',
        render: (text, obj) =>
          this.state.company &&
          (this.state.company.name.toLowerCase().includes('pearl') ||
            this.state.company.name.toLowerCase().includes('seva sadan')) ? (
            <a
              onClick={() => {
                this.setState({
                  selectedPurchase: obj.node,
                  activeKey: '2',
                  isEditActive: this.state.company?.name.includes('Seva Sadan')
                    ? false
                    : true,
                });
              }}
            >
              {text}
            </a>
          ) : (
            text
          ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (text) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Vendor',
        dataIndex: 'node.vendor.name',
        key: 'vendor',
      },
      {
        title: 'Vendor Invoice Date',
        dataIndex: 'node.vendorInvoiceDate',
        key: 'vendorInvoiceDate',
        render: (text) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Vendor Invoice No',
        dataIndex: 'node.vendorInvoiceNo',
        key: 'vendorInvoiceNo',
      },
    );
    return purchaseColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const {
      company,
      after,
      currentPage,
      activeKey,
      selectedPurchase,
      isEditActive,
      startDate,
      endDate,
    } = this.state;
    if (!this.state.company) {
      return <Spin />;
    }
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List{' '}
              {company?.name.toLowerCase().includes('seva sadan')
                ? 'Stock In'
                : 'Purchase'}
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={purchaseQuery}
            variables={{
              companyId: company?.id,
              date_Gte: startDate,
              date_Lte: endDate,
              isReturn: false,
              after,
              activeKey,
            }}
            render={(
              response: { allPurchases: PurchaseNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (error) return <h3>Something Went Wrong!!</h3>;
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allPurchases.edges}
                    columns={this.purchaseColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={Infinity}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allPurchases.pageInfo.endCursor,
                        response.allPurchases.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {company &&
          company.name.toLowerCase() !== 'jharcraft' &&
          !company?.name.toLowerCase().includes('khadi') &&
          this.state.user &&
          checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add{' '}
                  {company?.name.toLowerCase().includes('seva sadan')
                    ? 'Stock In'
                    : 'Purchase'}
                </span>
              }
              key="2"
            >
              <AddPurchase
                purchase={selectedPurchase}
                handleTabChange={this.handleTabChange}
                company={company}
                isEditActive={isEditActive}
              />
            </Tabs.TabPane>
          )}
        {company && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Item Wise{' '}
                {company?.name.toLowerCase().includes('seva sadan')
                  ? 'Stock In'
                  : 'Purchase'}{' '}
                Report
              </span>
            }
            key="3"
            closable={false}
          >
            <ItemWisePurchaseReport company={company} activeKey={activeKey} />
          </Tabs.TabPane>
        )}
        {company && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Vendor Wise{' '}
                {company?.name.toLowerCase().includes('seva sadan')
                  ? 'Stock In'
                  : 'Purchase'}{' '}
                Report
              </span>
            }
            key="4"
            closable={false}
          >
            <VendorWisePurchaseReport company={company} activeKey={activeKey} />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedPurchase: PurchaseNodeEdgeQuery | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  user?: UserNodeEdge | null;
}

interface Props {}

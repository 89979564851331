import * as React from 'react';
import { OptionProps } from 'antd/lib/select';
import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import {
  ProductSubCategoryNodeConnection,
  ProductSubCategoryNodeEdge,
} from './constants';
import { GraphqlQuery } from 'requestapijs';
import { Spin } from 'antd';

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

export class ProductSubCategoryDropdown extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      name: '',
      productSubCategory: null,
      touched: false,
    };
  }

  value = (productSubCategories: ProductSubCategoryNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const productSubCategory = productSubCategories.find(
      (v) => v.node.id === this.props.value,
    );
    if (!productSubCategory) return;
    return productSubCategory.node.name;
  };

  render() {
    const { name } = this.state;
    const { value, onSelect, companyId, categoryId } = this.props;
    return (
      <GraphqlQuery
        queryString={`
          query ProductSubCategoryDropdown($id: ID, $name: String, $companyId: ID, $categoryId: ID) {
            allProductSubCategories(id: $id, name_Icontains: $name, companyId: $companyId, categoryId: $categoryId) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `}
        variables={{
          name,
          id: value && !name ? value : '',
          companyId,
          categoryId,
        }}
        render={(
          response: {
            allProductSubCategories: ProductSubCategoryNodeConnection;
          },
          error,
          loading,
        ) => {
          if (loading) return <Spin />;
          if (error) return <div>{JSON.stringify(error)}</div>;
          if (response) {
            return (
              <div style={{ width: '100%' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Product Sub Category"
                  dataSource={response.allProductSubCategories.edges.map(
                    (productSubCategory) => (
                      <Option
                        key={productSubCategory.node.id}
                        lable={productSubCategory.node}
                        productSubCategory={productSubCategory}
                      >
                        {productSubCategory.node.name}
                      </Option>
                    ),
                  )}
                  value={this.value(response.allProductSubCategories.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(productSubCategory) =>
                    this.setState({ name: productSubCategory, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.productSubCategory.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
          return <Spin />;
        }}
      />
    );
  }
}

interface CustomOption extends OptionProps {
  lable?: React.ReactNode;
  display?: string;
  productSubCategory?: any;
}

interface Props extends AutoCompleteProps {
  companyId: string;
  categoryId: string;
}

interface State {
  name: string;
  productSubCategory: ProductSubCategoryNodeEdge | null;
  touched: boolean;
}

import * as React from 'react';
import { Row, Col, DatePicker, Divider, Button, Radio } from 'antd';
import moment from 'moment';
import { Label } from '../../common/TSLabel';
import { TSInput } from '../../common/TSInput';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { ConversionDropDown } from '../Conversion/ConversionDropdown';
import {
  ConversionNode,
  ProductionTransferInput,
  HsnNode,
  JobOrderNode,
  ConversionDetailNodeConnection,
} from '../../../schema';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { BranchEdges } from '../../master/Branch/constants';
import { HsnDropDown } from '../../master/Hsn/HsnDropdown';
import { JobOrderDropDown } from '../../JobOrder/JobOrderDropdown';
import { VendorEdges } from '../../master/vendor/constants';
import { nestedAccess } from '../../helper';
import { ProductionReceivingError } from './constants';

export const ProductionReceivingForm: React.FunctionComponent<Props> = (
  props,
) => {
  return (
    <Row>
      <Col span={4}>
        <Label title="Transfer Date">
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(e) =>
              props.onChange({
                target: {
                  name: 'date',
                  value: e && moment(e).format('YYYY-MM-DD'),
                },
              })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={4}>
        <Label title="From">
          <Radio.Group
            name="fromVendor"
            value={props.values.fromVendor}
            onChange={(e) =>
              props.onChange({
                target: { name: 'fromVendor', value: e.target.value },
              })
            }
          >
            <Radio value={true}>Vendor</Radio>
            <Radio value={false}>PWCS/SHGS/CFC</Radio>
          </Radio.Group>
        </Label>
      </Col>
      <Col span={4}>
        <Label title="For Job Order">
          <Radio.Group
            name="forJobOrder"
            value={props.forJobOrder}
            onChange={(e) =>
              props.onChangeForJobOrder({
                target: { name: 'forJobOrder', value: e.target.value },
              })
            }
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Label>
      </Col>
      <Col span={7}>
        <Label title="Select Job Order">
          <JobOrderDropDown
            onSelect={(e, f) => {
              props.onChange({
                target: { name: 'oldJobOrderId', value: String(e) },
              });
              props.jobOrderObjChangehandler((f as any).props.label);
            }}
            value={props.values.jobOrderId || ''}
            toVendor={props.values.fromVendor}
            disabled={!props.forJobOrder}
            allowClear
          />
        </Label>
        <span style={{ color: 'red' }}>{props.error.jobOrderId}</span>
      </Col>
      {props.values.fromVendor ? (
        <Col span={5}>
          <TSAutoComplete
            error={props.error.vendorForId}
            inputProps={{ name: 'vendorForId', addonBefore: 'Vendor' }}
            dataSource={props.vendors}
            key="id"
            name="vendorForId"
            onValueSelect={(e) =>
              props.forJobOrder
                ? {}
                : props.onChange({
                    target: { name: 'vendorForId', value: e.target.value },
                  })
            }
            displayKey="name"
            textValue={getValue(props.vendors, props.values.vendorForId)}
          />
        </Col>
      ) : (
        <Col span={5}>
          <TSAutoComplete
            error={props.error.fromBranchId}
            inputProps={{ name: 'fromBranchId', addonBefore: 'Location' }}
            dataSource={props.branch.filter((br) => {
              if (props.business.type === 'branch') {
                return (
                  br.node.id !== props.business.id &&
                  br.node.category &&
                  ['PWCS', 'SHGS', 'CFC'].indexOf(br.node.category) > -1
                );
              }
              return (
                br.node.category &&
                ['PWCS', 'SHGS', 'CFC'].indexOf(br.node.category) > -1
              );
            })}
            key="id"
            name="fromBranchId"
            onValueSelect={(e) =>
              props.forJobOrder
                ? {}
                : props.onChange({
                    target: { name: 'fromBranchId', value: e.target.value },
                  })
            }
            displayKey="name"
            textValue={getValue(props.branch, props.values.fromBranchId)}
            allowClear={!props.values.id}
          />
        </Col>
      )}
      <Divider />
      <h4>Finished Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={7}>Product</Col>
        {props.forJobOrder && <Col span={3}>Quantity Remaining</Col>}
        <Col span={2}>Quantity</Col>
        <Col span={3}>Cost Price</Col>
        <Col span={3}>Amount</Col>
        <Col span={3}>HSN</Col>
        <Col span={2}>Gst Rate</Col>
        {!props.forJobOrder && (
          <div>
            <Col span={2}>Remove</Col>
            <Col span={1}>Add</Col>
          </div>
        )}
        <Divider />
      </Row>
      {props.values.conversionDetails.map((conversion, index) => (
        <div key={'conversionDetails' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={7}>
              <ConversionDropDown
                onSelect={(e, f) => {
                  props.forJobOrder
                    ? {}
                    : props.conversionObjChangehandler(
                        (f as any).props.label,
                        index,
                      );
                  props.forJobOrder
                    ? {}
                    : props.conversionDetailChangeHandler(
                        { target: { name: 'conversionId', value: String(e) } },
                        index,
                      );
                }}
                value={conversion.conversionId || ''}
                allowClear
              />
              <span style={{ color: 'red' }}>
                {props.error.conversionDetails[index].conversionId}
              </span>
            </Col>
            {props.forJobOrder && (
              <Col span={3}>
                <TSInput
                  error={''}
                  value={
                    Number(
                      (
                        props.jobOrder
                          ?.orderFor as never as ConversionDetailNodeConnection
                      )?.edges[`${index}`].node.quantity || 0,
                    ) -
                    Number(
                      (
                        props.jobOrder
                          ?.orderFor as never as ConversionDetailNodeConnection
                      )?.edges[`${index}`].node.quantityReceived || 0,
                    )
                  }
                  placeholder="Quantity Remaining"
                />
              </Col>
            )}
            <Col span={2}>
              <TSInput
                error={props.error.conversionDetails[index].quantity}
                type="number"
                value={conversion.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.conversionDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.conversionDetails[index].cost}
                type="number"
                value={conversion.cost || ''}
                name="cost"
                placeholder="Cost Price"
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.conversionDetails[index].amount}
                type="number"
                value={conversion.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={3}>
              <HsnDropDown
                onSelect={(e, f) => {
                  props.hsnObjChangehandler((f as any).props.label, index);
                  props.conversionDetailChangeHandler(
                    { target: { name: 'hsnId', value: String(e) } },
                    index,
                  );
                }}
                value={conversion.hsnId || ''}
                extrafields={[
                  `hsnWithSameCode {
                    hsnCode
                    gst
                    minValue
                    maxValue
                  }`,
                ]}
                allowClear
              />
              <span style={{ color: 'red' }}>
                {props.error.conversionDetails[index].hsnId}
              </span>
            </Col>
            <Col span={2}>
              <TSInput
                error={''}
                type="number"
                value={conversion.gstRate || ''}
                name="gstRate"
                placeholder="Gst Rate"
                required
                tabIndex={-1}
              />
            </Col>
            {!props.forJobOrder && (
              <div>
                <Col span={2}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="minus"
                    style={{ marginLeft: '5px' }}
                    onClick={() => props.updateconversionDetailsRow('-', index)}
                    disabled={props.values.conversionDetails.length === 1}
                  />
                </Col>
                <Col span={1}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="plus"
                    style={{ marginLeft: '5px' }}
                    onClick={() => props.updateconversionDetailsRow('+', index)}
                    disabled={
                      conversion.conversionId &&
                      conversion.quantity &&
                      conversion.cost &&
                      conversion.amount
                        ? false
                        : true
                    }
                  />
                </Col>
              </div>
            )}
          </Row>
        </div>
      ))}
      <Divider />
      <Col>
        <TSInput
          error={''}
          type="text"
          value={props.values.remarks}
          name="remarks"
          onChange={props.onChange}
          addonBefore="Remarks"
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: ProductionTransferInput;
  error: ProductionReceivingError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  onChangeForJobOrder: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => void;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  conversionDetailChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  hsnObjChangehandler: (hsnObj: HsnNode, index: number) => void;
  jobOrderObjChangehandler: (jobOrderObj: JobOrderNode) => void;
  vendors: VendorEdges[];
  forJobOrder: boolean;
  conversionObjChangehandler: (
    conversionObj: ConversionNode,
    index: number,
  ) => void;
  updateconversionDetailsRow: (type: string, index: number) => void;
  jobOrder: JobOrderNode | null;
}

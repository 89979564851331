import * as React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { BranchNodeEdge } from '../master/Branch/constants';
import moment from 'moment';
import { ApprovalNodeEdgeQuery } from './constants';

export const ApprovalReceipt: React.FunctionComponent<Props> = (props) => {
  return (
    <Document>
      <Page size="A4">
        <View style={[style.inline, style.section, { marginTop: 110 }]}>
          <Text
            style={[style.inlineHead, style.textCenter, style.mediumSizeText]}
          >
            {props.branch.name}
          </Text>
        </View>
        <View style={[style.inline, style.section]}>
          <Text
            style={[style.inlineHead, style.textCenter, style.mediumSizeText]}
          >
            {props.branch.address}, {props.branch.contactNo}
          </Text>
        </View>
        <View style={[style.inline, style.section]}>
          <Text style={[style.inlineHead, style.mediumSizeText]}>
            Date: {moment(props.approval.date).format('DD-MM-YYYY')}
          </Text>
        </View>
        <View style={[style.inline, style.section]} />
        <View style={[style.inline, style.section]} />
        <View style={[style.inline, style.section]}>
          <Text style={[style.inlineHead, style.mediumSizeText]}>
            Name: {props.approval.customer?.name}
          </Text>
          <Text
            style={[style.inlineHead, style.mediumSizeText, style.textRight]}
          >
            {}
          </Text>
        </View>
        <View style={[style.inline, style.section]}>
          <Text style={[style.inlineHead, style.mediumSizeText]}>
            Contact No: {props.approval.customer?.contactNo}
          </Text>
          <Text
            style={[style.inlineHead, style.mediumSizeText, style.textRight]}
          >
            GSTIN No. {props.branch.gstin}
          </Text>
        </View>
        <View style={[style.inline, style.section]}>
          <Text style={[style.inlineHead, style.mediumSizeText]}>
            Address: {props.approval.customer?.address}
          </Text>
          <Text
            style={[style.inlineHead, style.mediumSizeText, style.textRight]}
          >
            Bill No. {props.approval.approvalNo}
          </Text>
        </View>
        <View style={[style.section, style.box, { height: '362pt' }]}>
          <View style={[style.inline]}>
            <Text
              style={[
                style.largeText,
                style.textCenter,
                {
                  borderBottom: '1pt solid black',
                  borderRight: '1pt solid black',
                  flex: 0.2,
                  padding: 3,
                },
              ]}
            >
              SL.
            </Text>
            <Text
              style={[
                style.highFlexInlineHead,
                style.largeText,
                style.textCenter,
                {
                  borderBottom: '1pt solid black',
                  borderRight: '1pt solid black',
                  padding: 3,
                },
              ]}
            >
              PARTICULARS
            </Text>
            <Text
              style={[
                style.largeText,
                style.textCenter,
                {
                  borderBottom: '1pt solid black',
                  borderRight: '1pt solid black',
                  flex: 0.5,
                  padding: 3,
                },
              ]}
            >
              QTY.
            </Text>
            <Text
              style={[
                style.largeText,
                style.textCenter,
                {
                  borderBottom: '1pt solid black',
                  borderRight: '1pt solid black',
                  flex: 0.5,
                  padding: 3,
                },
              ]}
            >
              RATE
            </Text>
            <Text
              style={[
                style.largeText,
                style.textCenter,
                { borderBottom: '1pt solid black', flex: 0.6, padding: 3 },
              ]}
            >
              AMOUNT
            </Text>
          </View>
          {props.approval.productDetails.edges.map((p, i) => (
            <View style={[style.inline]}>
              <Text
                style={[
                  style.mediumSizeText,
                  style.textCenter,
                  { borderRight: '1pt solid black', flex: 0.2, padding: 3 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.mediumSizeText,
                  style.textCenter,
                  { borderRight: '1pt solid black', padding: 3 },
                ]}
              >
                {p.node.product?.name}
              </Text>
              <Text
                style={[
                  style.smallSizeText,
                  style.textRight,
                  { borderRight: '1pt solid black', flex: 0.5, padding: 3 },
                ]}
              >
                {p.node.quantity}
              </Text>
              <Text
                style={[
                  style.smallSizeText,
                  style.textRight,
                  { borderRight: '1pt solid black', flex: 0.5, padding: 3 },
                ]}
              >
                {Number(p.node.rate).toFixed(2)}
              </Text>
              <Text
                style={[
                  style.smallSizeText,
                  style.textRight,
                  { flex: 0.6, padding: 3 },
                ]}
              >
                {Number(p.node.amount).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[style.section, style.box, { height: '20pt', marginTop: 0 }]}
        >
          <View style={[style.inline]}>
            <Text
              style={[
                style.textCenter,
                style.mediumSizeText,
                { padding: 3, flex: 3.3 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.textRight,
                style.mediumSizeText,
                { borderRight: '1pt solid black', padding: 3, flex: 0.7 },
              ]}
            >
              Net Total
            </Text>
            <Text
              style={[
                style.textRight,
                style.smallSizeText,
                { padding: 3, flex: 0.6 },
              ]}
            >
              0.00
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 40, marginRight: 40 },
  textCenter: { textAlign: 'center' },
  smallSizeText: { color: 'black', fontSize: '9.8pt' },
  mediumSizeText: { color: 'black', fontSize: '10.4pt' },
  largeText: { color: 'black', fontSize: '11pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  verticalDivider: {
    border: 0,
    borderLeft: '1pt solid black',
    height: '380pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.8 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.5 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  approval: ApprovalNodeEdgeQuery;
  branch: BranchNodeEdge;
}

import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { ConversionNodeConnection, ConversionNodeEdge } from '../../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../../master/Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  conversion?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface ConversionDropDownProps extends AutoCompleteProps {
  extrafields?: string[];
  companyId?: string;
}

export class ConversionDropDown extends React.Component<
  ConversionDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      company: null,
      touched: false,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  value = (conversions: ConversionNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const conversion = conversions.find((v) => v.node?.id === this.props.value);
    if (!conversion) return;
    return conversion.node?.finishedProduct;
  };

  render() {
    const { name, company } = this.state;
    const { extrafields, value, onSelect } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query ConversionDropDown($after: String!, $id: ID, $name: String, $companyId: ID) {
          allConversions(first: 10, after: $after, id: $id, finishedProduct_Icontains: $name, companyId: $companyId) {
            edges {
              node {
                id
                finishedProduct
                netCost
                ${extrafields?.join('\n')}
              }
            }
          }
        }`}
        variables={{
          after: '',
          name,
          companyId: company.id,
          id: value && !name ? value : '',
        }}
        render={(
          response: { allConversions: ConversionNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Products"
                dataSource={response.allConversions.edges.map((conversion) => (
                  <Option
                    key={conversion.node?.id}
                    label={conversion.node}
                    conversion={conversion}
                  >
                    {conversion.node?.finishedProduct}
                  </Option>
                ))}
                value={this.value(response.allConversions.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(conversion) =>
                  this.setState({ name: conversion, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    name: (f as any).props.conversion.node.finishedProduct,
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  name: string;
  company: CompanyNodeEdge | null;
  touched: boolean;
}

import * as React from 'react';
import { DatePicker, Divider, Skeleton, Button } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { paymentModeWiseReportCentral } from './constants';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { PaymentModeReportCentral } from '../../schema';
import { downloadToFile } from '../helper';

export class PaymentModeSalesReportCentral extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      month: moment().format('YYYY-MM'),
    };
  }

  componentDidMount() {}

  componentWillReceiveProps() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Branch',
      key: 'branch',
      dataIndex: 'branch',
      sortingValue: (value) => value,
    },
    {
      title: 'Cash',
      key: 'cash',
      dataIndex: 'cash',
    },
    {
      title: 'Card',
      key: 'card',
      dataIndex: 'card',
    },
    {
      title: 'Credit',
      key: 'credit',
      dataIndex: 'credit',
    },
    {
      title: 'Cheque',
      key: 'cheque',
      dataIndex: 'cheque',
    },
    {
      title: 'Bank',
      key: 'bank',
      dataIndex: 'bank',
    },
    {
      title: 'COD',
      key: 'cod',
      dataIndex: 'cod',
    },
    {
      title: 'Cancel Sales',
      key: 'cancelSales',
      dataIndex: 'cancelSales',
    },
    {
      title: 'Total',
      key: 'totalForBranch',
      dataIndex: 'totalForBranch',
    },
  ];
  downloadCsv = (paymentModeListCentral: PaymentModeReportCentral[]) => {
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    const data = paymentModeListCentral
      .map((dl) =>
        this.columns()
          .map((col) => {
            if (col.dataIndex) {
              return dl[col.dataIndex];
            }
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `payment-mode-wise-sales-report-${'head-office'}-${new Date().toISOString()}`,
      'PAYMENT MODE WISE SALES REPORT',
      this.props.company.name,
      'Head Office',
      moment(startDate).format('DD-MM-YYYY'),
      moment(endDate).format('DD-MM-YYYY'),
      '',
    );
  };

  render() {
    const {
      company: { id: companyId },
    } = this.props;
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    return (
      <div>
        <DatePicker.MonthPicker
          format="MMM-YYYY"
          placeholder="Month"
          value={
            this.state.month
              ? moment(this.state.month)
              : moment(moment().format('YYYY-MM'))
          }
          onChange={(month) =>
            this.onChange({
              target: {
                name: 'month',
                value: month && month.format('YYYY-MM'),
              },
            })
          }
        />
        <Divider />
        <GraphqlQuery
          queryString={paymentModeWiseReportCentral}
          variables={{
            companyId,
            startDate,
            endDate,
          }}
          render={(
            response: { paymentModeListCentral: PaymentModeReportCentral[] },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.paymentModeListCentral}
                  columns={this.columns()}
                  rowKey={(obj, i) => i.toString()}
                  pagination={{ pageSize: 11 }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(response.paymentModeListCentral)
                  }
                  key="button"
                />,
              ];
            }
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  month: string;
}

import moment from 'moment';
import { IssueInput, ProductDetailsMovementType } from '../../../schema';

export const issueObject: IssueInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  companyId: '',
  billNo: '',
  productDetails: [
    {
      date: '',
      productId: '',
      rate: 0,
      purchaseRate: 0,
      quantity: null,
      amount: 0,
      movementType: ProductDetailsMovementType.ISSUE,
      companyId: '',
      remark: null,
    },
  ],
  remarks: '',
};

export interface IssueProductDetailsError {
  productId: string;
  quantity: string;
}

export const issueDetailsError: IssueProductDetailsError = {
  productId: '',
  quantity: '',
};

export interface IssueError {
  date: string;
  productDetails: IssueProductDetailsError[];
}

export const issueErrorObj: IssueError = {
  date: '',
  productDetails: [issueDetailsError],
};

export const issueMutation = `
mutation addIssue($input: CreateIssueInput!) {
  createIssue(input: $input){
    newIssue{
      id
    }
  }
}
`;

export const issueQuery = `
query IssueList($companyId: ID, $after: String, $date_Gte: Date, $date_Lte: Date) {
  allIssues(first: 20, after: $after, companyId: $companyId, date_Gte: $date_Gte, date_Lte: $date_Lte) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        date
        billNo
        remarks
        productDetails {
          edges {
            node {
              id
              product {
                id
              }
              quantity
              purchaseRate
              rate
              amount
              remark
              movementType
            }
          }
        }
      }
    }
  }
}
`;

import * as React from 'react';
import { Table, Tabs, Icon, Spin } from 'antd';
import { checkAddPermission } from '../helper';
import { ValueaditionService } from '../../service/ValueaditionService';
import { AddValueadition } from './AddValueadition';
import { CompanyService } from '../../service/CompanyService';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { ValueaditionNodeEdgeQuery, ValueaditionEdges } from './constants';
import { ValueaditionReceiptJharcraft } from './ValueaditionReceiptJharcraft';
import { PDFViewer } from '@react-pdf/renderer';
import { ValueaditionReceipt } from './ValueaditionReceipt';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ValueaditionReceiptKhadi } from './ValueaditionReceiptKhadi';

export class Valueadition extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      valueaditions: [],
      activeKey: '1',
      selectedValueadition: null,
      branch: null,
      company: null,
      isEditActive: false,
      user: null,
      product: null,
    };
  }

  valueaditionService = new ValueaditionService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.valueaditionService?.valueadition$.subscribe((valueaditions) =>
      this.setState({ valueaditions }),
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );

    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1' || this.state.activeKey === '3') {
      this.setState({
        activeKey,
        selectedValueadition: null,
        isEditActive: false,
      });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  valueaditionColumn = () => [
    {
      title: 'Value Addition No',
      dataIndex: 'node.valueaditionNo',
      key: 'valueaditionNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedValueadition: obj,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
    },
    {
      title: 'Return For',
      dataIndex: 'node.oldValueadition.valueaditionNo',
      key: 'returnFor',
      render: (value, record: ValueaditionEdges) => {
        return record.node.isReturn
          ? value
          : this.state.valueaditions.find(
              (app) => app.node?.oldValueadition?.id === record.node.id,
            )
          ? 'Returned'
          : '';
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'node.vendor.name',
      key: 'vendor',
    },
    {
      title: 'Customer',
      dataIndex: 'node.customer.name',
      key: 'customer',
    },
    {
      title: 'Total MRP',
      dataIndex: 'node.totalMrp',
      key: 'totalMrp',
    },
    {
      title: 'Receipt',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedValueadition: obj, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  getValueaditionsToReturn = (valueaditions: ValueaditionEdges[]) => {
    return valueaditions.filter(
      (app) =>
        !app.node.isReturn &&
        !valueaditions.find(
          (appr) => appr.node?.oldValueadition?.id === app.node.id,
        ),
    );
  };

  render() {
    if (!this.state.company) return <Spin />;
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          {this.state.user && checkViewPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  List Value Addition
                </span>
              }
              key="1"
              closable={false}
            >
              <Table
                dataSource={this.state.valueaditions}
                columns={this.valueaditionColumn()}
                rowKey={(record) => record.node.id || ''}
              />
            </Tabs.TabPane>
          )}
          {this.state.user &&
            (checkAddPermission(this.state.user) || this.state.company) &&
            this.companyService?.company$.value && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="plus" />
                    Add Value Addition
                  </span>
                }
                key="2"
              >
                <Tabs>
                  <Tabs.TabPane tab={<span>Customer</span>} key="4">
                    <AddValueadition
                      valueadition={this.state.selectedValueadition?.node}
                      handleTabChange={this.handleTabChange}
                      company={this.companyService?.company$.value}
                      valueaditionList={this.getValueaditionsToReturn(
                        this.state.valueaditions,
                      )}
                      isEditActive={this.state.isEditActive}
                      selectedoption="CUSTOMER"
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<span>Vendor</span>} key="5">
                    <AddValueadition
                      valueadition={this.state.selectedValueadition?.node}
                      handleTabChange={this.handleTabChange}
                      company={this.companyService?.company$.value}
                      valueaditionList={this.getValueaditionsToReturn(
                        this.state.valueaditions,
                      )}
                      isEditActive={this.state.isEditActive}
                      selectedoption="VENDOR"
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            )}
          {this.state.selectedValueadition &&
            this.companyService?.company$.value &&
            this.companyService.selectedBussiness$.value &&
            this.state.activeKey === '3' && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="plus" />
                    Receipt
                  </span>
                }
                key="3"
              >
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  {this.state.company.name === 'Jharcraft' ? (
                    <ValueaditionReceiptJharcraft
                      company={this.companyService.company$.value}
                      valueadition={this.state.selectedValueadition.node}
                      branch={this.companyService.selectedBussiness$.value}
                      selectedoption={this.props.selectedoption}
                    />
                  ) : this.state.company?.name
                      .toLowerCase()
                      .includes('khadi') ? (
                    <ValueaditionReceiptKhadi
                      company={this.companyService.company$.value}
                      valueadition={this.state.selectedValueadition.node}
                      branch={this.companyService.selectedBussiness$.value}
                      selectedoption={this.props.selectedoption}
                    />
                  ) : (
                    <ValueaditionReceipt
                      valueadition={this.state.selectedValueadition.node}
                      branch={this.companyService.selectedBussiness$.value}
                    />
                  )}
                </PDFViewer>
              </Tabs.TabPane>
            )}
        </Tabs>
      </div>
    );
  }
}

interface State {
  valueaditions: {
    cursor: string;
    node: ValueaditionNodeEdgeQuery;
  }[];
  activeKey: string;
  selectedValueadition: {
    cursor: string;
    node: ValueaditionNodeEdgeQuery;
  } | null;
  branch: BranchNodeEdge | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
  product: ProductDropDown | null;
}

interface Props {
  selectedoption: 'CUSTOMER' | 'VENDOR';
}

import * as React from 'react';
import { TransferReturnService } from '../../service/TransferReturnService';
import { notification, Button } from 'antd';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { BranchEdges } from '../master/Branch/constants';
import {
  handleProductDetailsData,
  transferError,
  TransferNode,
  TransferError,
  handleCommissionDetailsData,
} from '../Transfer/constants';
import { transferReturnNode } from './constants';
import { TransferReturnForm } from './TransferReturnForm';
import {
  CommissionDetailsNode,
  CommissionDetailsNodeConnection,
  ProductNode,
} from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';

export class AddTransferReturn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transferReturn: props.transferReturn
        ? {
            ...props.transferReturn,
            fromLocationId:
              props.transferReturn.fromLocation &&
              props.transferReturn.fromLocation.id,
            toLocationId:
              props.transferReturn.toLocation &&
              props.transferReturn.toLocation.id,
            productDetails: handleProductDetailsData(
              props.transferReturn
                .productDetails as ProductDetailsNodeConnection,
            ),
            commissionDetails: handleCommissionDetailsData(
              props.transferReturn
                .commissionDetails as CommissionDetailsNodeConnection,
            ),
          }
        : JSON.parse(JSON.stringify(transferReturnNode)),
      error: { ...transferError },
      branch: [],
      buttonDisabled: false,
      productObjList: [],
    };
  }

  transferReturnService = new TransferReturnService();

  componentWillReceiveProps(nextProps: Props) {
    const transferReturn = nextProps.transferReturn
      ? {
          ...nextProps.transferReturn,
          fromLocationId:
            nextProps.transferReturn.fromLocation &&
            nextProps.transferReturn.fromLocation.id,
          toLocationId:
            nextProps.transferReturn.toLocation &&
            nextProps.transferReturn.toLocation.id,
          productDetails: handleProductDetailsData(
            nextProps.transferReturn
              .productDetails as ProductDetailsNodeConnection,
          ),
        }
      : JSON.parse(JSON.stringify(transferReturnNode));
    this.setState({ transferReturn });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const transferReturn = { ...this.state.transferReturn };
    transferReturn[e.target.name] = e.target.value;
    this.setState({ transferReturn });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.transferReturn.productDetails[index][e.target.name] =
      e.target.value;
    if (
      nextState.transferReturn.productDetails[index].quantity &&
      nextState.transferReturn.productDetails[index].purchaseRate
    ) {
      nextState.transferReturn.productDetails[index].amount =
        Number(nextState.transferReturn.productDetails[index].quantity) *
        Number(nextState.transferReturn.productDetails[index].purchaseRate);
    }
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.transferReturn.productDetails.push({
        date: null,
        productId: '',
        quantity: 0,
        rate: 0,
        amount: 0,
        movementType: 'UP',
        companyId: null,
      });
    } else {
      nextState.transferReturn.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.transferReturn.productDetails[index].rate =
      productObj.sellingRate;
    nextState.transferReturn.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  saveTransferReturn = () => {
    this.setState({ buttonDisabled: true });
    this.transferReturnService?.addTransferReturn(
      {
        ...this.state.transferReturn,
        fromLocationId: this.props.business.id,
        toLocationId: null,
      },
      (response) => {
        this.setState({
          transferReturn: this.props.transferReturn
            ? {
                ...this.props.transferReturn,
                fromLocationId:
                  this.props.transferReturn.fromLocation &&
                  this.props.transferReturn.fromLocation.id,
                toLocationId:
                  this.props.transferReturn.toLocation &&
                  this.props.transferReturn.toLocation.id,
                productDetails: handleProductDetailsData(
                  this.props.transferReturn
                    .productDetails as ProductDetailsNodeConnection,
                ),
              }
            : JSON.parse(JSON.stringify(transferReturnNode)),
          error: { ...transferError },
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Transfer Return ' + this.state.transferReturn.id
              ? 'Updated'
              : 'Added',
          description: `Transfer Return was successfuly ${
            this.state.transferReturn.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Transfer Return ' + this.state.transferReturn.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      transferReturn: JSON.parse(JSON.stringify(transferReturnNode)),
      error: { ...transferError },
    });
  };

  render() {
    return (
      <div>
        <TransferReturnForm
          values={this.state.transferReturn}
          error={this.state.error}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          company={this.props.company}
          productObjChangehandler={this.productObjChangehandler}
          isEditActive={this.props.isEditActive}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveTransferReturn}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  transferReturn?: TransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
  handleTabChange: (key) => void;
  isEditActive: boolean;
}

interface State {
  transferReturn: TransferNode<
    ProductDetailNodeEdge[],
    CommissionDetailsNode[]
  >;
  error: TransferError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  productObjList: ProductNode[];
}

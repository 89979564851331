import * as React from 'react';
import { Tabs, Table, Icon } from 'antd';
import { TransferReturnService } from '../../service/TransferReturnService';
import { CompanyService } from '../../service/CompanyService';
import { AddTransferReturn } from './AddTransferReturn';
import { checkAddPermission } from '../helper';
import { TransferEdges } from '../Transfer/constants';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';

export class TransferReturn extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      transferReturns: [],
      activeKey: '1',
      selectedTransferReturn: null,
      business: null,
      company: null,
      isEditActive: false,
      user: null,
    };
  }

  transferReturnService = new TransferReturnService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.transferReturnService?.transferReturn$.subscribe((transferReturns) =>
      this.setState({ transferReturns }),
    );

    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );

    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({
        activeKey,
        selectedTransferReturn: null,
        isEditActive: false,
      });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  transferReturnColumn = () => [
    {
      title: 'Transfer Return Invoice No',
      dataIndex: 'node.transferInvoiceNo',
      key: 'transferInvoiceNo',
      render: (text, obj) =>
        this.state.business?.type === 'branch' ? (
          <a
            onClick={() => {
              this.setState({
                selectedTransferReturn: obj,
                activeKey: '2',
                isEditActive: true,
              });
            }}
          >
            {text}
          </a>
        ) : (
          text
        ),
    },
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
    },
    {
      title: 'To',
      key: 'headOffice',
      render: () => this.state.company?.name,
    },
  ];

  render() {
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Transfer Return
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.transferReturns}
            columns={this.transferReturnColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        {this.state.business?.type === 'branch' &&
          this.state.company &&
          this.state.user &&
          checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Transfer Return
                </span>
              }
              key="2"
            >
              <AddTransferReturn
                transferReturn={this.state.selectedTransferReturn?.node}
                handleTabChange={this.handleTabChange}
                company={this.state.company}
                business={this.state.business}
                isEditActive={this.state.isEditActive}
              />
            </Tabs.TabPane>
          )}
      </Tabs>
    );
  }
}

interface State {
  transferReturns: TransferEdges[];
  activeKey: string;
  selectedTransferReturn: TransferEdges | null;
  company: CompanyNodeEdge | null;
  business: Bussiness | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
}

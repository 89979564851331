import { BranchNodeEdge } from '../../master/Branch/constants';
import { User } from '../../user/constants';

export interface EmployeeNodeEdge {
  id?: string;
  userId?: string;
  userDetailId?: string;
  username?: string | null;
  password?: string | null;
  email?: string;
  firstName?: string;
  lastName?: string;
  isSuperuser?: boolean;
  user?: User;
  branch?: BranchNodeEdge;
  isStaff?: boolean;
  dateJoined?: string;
  lastLogin?: string;
  isActive?: boolean;
  branchId?: string | null;
  companyId?: string;
  groupId?: string;
  group?: {
    id?: string;
    name?: string;
  };
}

export interface EmployeeError {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  isSuperuser: string;
  branchId: string;
}

export const employeeObject: EmployeeNodeEdge = {
  username: null,
  password: null,
  email: '',
  firstName: '',
  lastName: '',
  isSuperuser: false,
  branchId: null,
  groupId: '',
};

export const employeeErrorObject: EmployeeError = {
  username: '',
  password: '',
  email: '',
  firstName: '',
  lastName: '',
  isSuperuser: '',
  branchId: '',
};

export const employeeQuery = `
  query EmployeeList($companyId: ID) {
    allUsers(companyId: $companyId) {
      edges {
        node {
          id
          branch {
            id
            name
          }
          user {
            id
            username
            email
            password
            firstName
            lastName
            isSuperuser
            isStaff
            dateJoined
            lastLogin
            isActive
          }
          isSuperuser
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export interface AddEmployeeVariables {
  input: {
    userDetails: EmployeeNodeEdge;
  };
}

export interface EmployeeQueryResponse {
  data: {
    allUsers: {
      edges: EmployeeEdges[];
    };
  };
}

export interface EmployeeEdges {
  cursor: string;
  node: EmployeeNodeEdge;
}

export const employeeMutation = `
  mutation addEmployee($input: CreateUserDetailInput!) {
    createUser(input: $input) {
      clientMutationId
      newUserDetails {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

import * as React from 'react';
import { DatePicker, Table, Spin, Row, Col, Skeleton, Button } from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  ProductCategoryNodeConnection,
  ProductCategoryNodeEdge,
} from '../../schema';
import { TSInput } from '../common/TSInput';
import { Bussiness } from '../master/Company/constants';
import { categoryWiseMonthlySaleQuery } from '../master/ProductCategories/constants';
import { downloadToFile } from '../helper';
import { Pagination } from 'sha-el-design';
import { CATEGORY_WISE_MONTHLY_SALE_REPORT_CSV_URL } from '../../config';

export class CategoryWiseMonthlySaleReport extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      year:
        moment().month() >= 3
          ? `${moment().year()}-04`
          : `${moment().year() - 1}-04`,
      name: '',
      currentPage: 1,
      after: '',
    };
  }

  componentWillUnmount() {}

  componentWillReceiveProps() {
    this.setState({
      year:
        moment().month() >= 3
          ? `${moment().year()}-04`
          : `${moment().year() - 1}-04`,
      name: '',
      currentPage: 1,
      after: '',
    });
  }

  yearChangeHandler = (year) => {
    this.setState({ year: moment(year).format('YYYY-MM') });
  };

  columns = () => [
    {
      title: 'Product Category',
      dataIndex: 'node.name',
      key: 'name',
    },
    {
      title: 'APR',
      key: 'APR',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['04']
        );
      },
    },
    {
      title: 'MAY',
      key: 'MAY',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['05']
        );
      },
    },
    {
      title: 'JUN',
      key: 'JUN',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['06']
        );
      },
    },
    {
      title: 'JUL',
      key: 'JUL',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['07']
        );
      },
    },
    {
      title: 'AUG',
      key: 'AUG',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['08']
        );
      },
    },
    {
      title: 'SEP',
      key: 'SEP',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['09']
        );
      },
    },
    {
      title: 'OCT',
      key: 'OCT',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['10']
        );
      },
    },
    {
      title: 'NOV',
      key: 'NOV',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['11']
        );
      },
    },
    {
      title: 'DEC',
      key: 'DEC',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['12']
        );
      },
    },
    {
      title: 'JAN',
      key: 'JAN',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['01']
        );
      },
    },
    {
      title: 'FEB',
      key: 'FEB',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['02']
        );
      },
    },
    {
      title: 'MAR',
      key: 'MAR',
      render: (text: ProductCategoryNodeEdge) => {
        return (
          text?.node?.monthlySaleReport &&
          JSON.parse(text.node.monthlySaleReport as any as string)['03']
        );
      },
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CATEGORY_WISE_MONTHLY_SALE_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `category-wise-monthly-sale-${new Date()}`,
          'CATEGORY WISE MONTHLY SALE REPORT',
          (this.props.business.type === 'branch'
            ? this.props.business.companyName
            : this.props.business.name) || '',
          this.props.business.type === 'branch'
            ? this.props.business.name
            : 'Head Office',
          moment(this.state.year).format('MMM-YYYY'),
          `MAR-${Number(this.state.year.slice(0, 4)) + 1}`,
          '',
        ),
      );
  };

  render() {
    const { companyId, branchId } = this.props;
    const { year, name, currentPage, after } = this.state;
    const variables = {
      year: year.slice(0, 4),
      companyId,
      branchId,
      orderBy: 'name',
      after,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker.MonthPicker
              value={moment(this.state.year)}
              onChange={this.yearChangeHandler}
              placeholder="Year"
              format="MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
              disabledDate={(currentDate) => !(currentDate?.month() === 3)}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              name="name"
              type="text"
              addonBefore="Search Product Category"
              placeholder="Enter Name"
            />
          </Col>
        </Row>
        {year && (
          <GraphqlQuery
            queryString={categoryWiseMonthlySaleQuery}
            variables={name ? { ...variables, name } : variables}
            render={(
              response: { allProductCategories: ProductCategoryNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProductCategories.edges}
                    columns={this.columns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProductCategories.pageInfo.endCursor,
                        response.allProductCategories.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
        ,
      </div>
    );
  }
}

interface State {
  year: string;
  name: string;
  currentPage: number;
  after: string;
}

interface Props {
  companyId: string;
  branchId?: string | null;
  business: Bussiness;
}

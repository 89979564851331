import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { ProductionTransferNodeConnection } from '../../../schema';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface ChallanDropDownProps extends AutoCompleteProps {
  extrafields: string[];
}

export class ProductionTransferDropDown extends React.Component<
  ChallanDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      transferInvoiceNo: '',
      company: null,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  render() {
    const { transferInvoiceNo, company } = this.state;
    const { extrafields } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query ProductionTransferDropDown($after: String!, $transferInvoiceNo: String, $companyId: ID, $status: String) {
          allProductionTransfers(
            companyId: $companyId,
            first: 10,
            transferInvoiceNo_Icontains: $transferInvoiceNo,
            after: $after,
            status_Contains: $status,
          ) {
            edges {
              node {
                id
                transferInvoiceNo
                productDetailsWithMargin {
                  productDetails
                }
                marginSet {
                  edges {
                    node {
                      id
                      freight
                      otherExpenses
                      productDetails
                    }
                  }
                }
              }
            }
          }
        }`}
        variables={{
          after: '',
          transferInvoiceNo,
          companyId: company.id,
          status: 'ACCEPTED',
        }}
        render={(
          response: {
            allProductionTransfers: ProductionTransferNodeConnection;
          },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Invoice No"
                dataSource={response.allProductionTransfers.edges.map(
                  (value) => (
                    <Option key={value.node?.id} label={value.node}>
                      {value.node?.transferInvoiceNo}
                    </Option>
                  ),
                )}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(value) =>
                  this.setState({ transferInvoiceNo: value })
                }
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  company: CompanyNodeEdge | null;
  transferInvoiceNo: string;
}

import * as React from 'react';
import { Table as AntTable, Icon, Row, Col, Input, Button, Select } from 'antd';
import { TableProps, ColumnProps as AntColumnProps } from 'antd/lib/table';
import { orderBy } from 'lodash';

export class Table<T> extends React.Component<Props<T>, State<T>> {
  dataSource: T[] = [];
  constructor(props: Props<T>) {
    super(props);
    this.dataSource = props.dataSource || [];
    this.state = {
      dataSource: props.dataSource || [],
      sorter: {},
      filterDropdownVisible: {},
      filterValue: {},
      filtered: {},
      removedColumns: [],
    };
  }

  componentWillReceiveProps(props) {
    this.dataSource = props.dataSource;
    this.setState({ dataSource: props.dataSource });
  }

  createFilter(column: ColumnProps<T>): ColumnProps<T> {
    if (!column.sortingValue) return {};
    return {
      filterDropdown: (
        <Input
          placeholder={`Search ${column.title}`}
          value={this.state.filterValue[column.key?.toLocaleString() || '']}
          onChange={(e) =>
            this.onFilterValueChange(
              column.key?.toLocaleString() || '',
              e,
              column,
            )
          }
        />
      ),
      filterIcon: (
        <Icon
          type="filter"
          style={{
            color: this.state.filtered[column.key?.toLocaleString() || '']
              ? '#108ee9'
              : '#aaa',
          }}
        />
      ),
      filterDropdownVisible:
        this.state.filterDropdownVisible[column.key?.toLocaleString() || ''],
      onFilterDropdownVisibleChange: (visible) => {
        const filterDropdownVisible = { ...this.state.filterDropdownVisible };
        filterDropdownVisible[column.key?.toLocaleString() || ''] = visible;
        this.setState({ filterDropdownVisible });
      },
      filterMultiple: true,
    };
  }

  onSort = (cell: ColumnProps<T>) => {
    if (!cell.sortingValue) {
      return;
    }
    const sorter = {};
    sorter[cell.key?.toLocaleString() || ''] =
      this.state.sorter[cell.key?.toLocaleString() || ''];
    sorter[cell.key?.toLocaleString() || ''] === 'asc'
      ? (sorter[cell.key?.toLocaleString() || ''] = 'desc')
      : (sorter[cell.key?.toLocaleString() || ''] = 'asc');
    this.setState({
      sorter,
      dataSource: orderBy(
        this.state.dataSource,
        (obj) => {
          return cell.sortingValue?.(
            this.getDataIndexValue(cell.dataIndex || '', obj),
            obj,
          );
        },
        sorter[cell.key?.toLocaleString() || ''],
      ),
    });
  };

  getDataIndexValue = (dataIndex: string, dataSourceObj: T) => {
    let value = dataSourceObj;
    if (dataIndex) {
      const dataKeys = dataIndex.split('.');
      dataKeys.forEach((dk) => {
        value = value[dk];
      });
    }
    return value;
  };

  onFilterValueChange = (
    key: string | number,
    e: React.ChangeEvent<HTMLInputElement>,
    column: ColumnProps<T>,
  ) => {
    const filterValue = { ...this.state.filterValue };
    const filtered = { ...this.state.filtered };
    filterValue[key] = e.target.value;
    filtered[key] = e.target.value ? true : false;
    this.setState({ filterValue, filtered }, () =>
      this.filter(column, filterValue),
    );
  };

  filter = (column: ColumnProps<T>, filterValue: object) => {
    const col = this.props.columns.filter((value) =>
      filterValue.hasOwnProperty(value.key?.toLocaleString() || ''),
    );
    const dataSource = this.dataSource.filter((d) => {
      const filteringValue = column.sortingValue?.(
        this.getDataIndexValue(column.dataIndex || '', d),
        d,
      );
      if (typeof filteringValue !== 'string') {
        return false;
      }
      return (
        filteringValue
          .toLowerCase()
          .includes(
            filterValue[column.key?.toLocaleString() || ''].toLowerCase(),
          ) &&
        col.every((value) => {
          const otherFilteringValue = value.sortingValue?.(
            this.getDataIndexValue(value.dataIndex || '', d),
            d,
          ) as string;
          return otherFilteringValue
            .toLowerCase()
            .includes(
              filterValue[value.key?.toLocaleString() || ''].toLowerCase(),
            );
        })
      );
    });
    this.setState({ dataSource });
  };

  createColumn() {
    const columns = this.props.columns.filter(
      (col) => this.state.removedColumns.indexOf(col.key as string) === -1,
    );
    return columns.map((column) => {
      column = { ...column, ...this.createFilter(column) };
      column.title = (
        <div>
          <Row>
            <Col span={20} onClick={() => this.onSort(column)}>
              {column.title}
            </Col>
            <Col span={4}>
              {column.sortingValue && (
                <div>
                  {this.state.sorter[column.key?.toLocaleString() || ''] ===
                    'asc' && (
                    <Icon
                      type="up"
                      style={{
                        fontSize: '15px',
                        color: 'green',
                      }}
                    />
                  )}
                  {this.state.sorter[column.key?.toLocaleString() || ''] ===
                    'desc' && (
                    <Icon
                      type="down"
                      style={{
                        fontSize: '15px',
                        color: 'green',
                      }}
                    />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      );
      column.onHeaderCell = (cell) => ({
        onClick: () => this.onSort(cell),
      });
      return column;
    });
  }

  clearAll = () => {
    this.setState({
      dataSource: this.props.dataSource || [],
      sorter: {},
      filterDropdownVisible: {},
      filterValue: {},
      filtered: {},
      removedColumns: [],
    });
  };

  onRowChange = (e) => {
    this.setState({ removedColumns: e });
  };

  render() {
    return (
      <div>
        <Button
          type="default"
          children="Clear Filters and Sorting"
          onClick={this.clearAll}
        />
        <Select
          value={this.state.removedColumns}
          onChange={this.onRowChange}
          placeholder="Select Columns To Remove"
          style={{ width: '50%' }}
          mode="multiple"
        >
          {this.props.columns.map((value) => (
            <Select.Option key={String(value.key)} value={value.key}>
              {value.title}
            </Select.Option>
          ))}
        </Select>
        <AntTable
          {...this.props}
          columns={this.createColumn()}
          dataSource={this.state.dataSource}
        />
      </div>
    );
  }
}

export interface ColumnProps<T> extends AntColumnProps<T> {
  sortingValue?: (text, obj) => string | number;
}

interface Props<T> extends TableProps<T> {
  columns: ColumnProps<T>[];
}

interface State<T> {
  dataSource: T[];
  sorter: {
    [key: string]: 'asc' | 'desc' | false;
  };
  filterDropdownVisible: {
    [key: string]: boolean;
  };
  filterValue: {
    [key: string]: string;
  };
  filtered: {
    [key: string]: boolean;
  };
  removedColumns: string[];
}

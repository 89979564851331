import * as React from 'react';
import { Button, notification } from 'antd';
import { RemunerationInput, RemunerationNode } from '../../../schema';
import { RemunerationService } from '../../../service/RemunerationService';
import { TSInput } from '../../common/TSInput';
import { remunerationObject } from './constants';

export class AddRemuneration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      remuneration: props.remuneration
        ? {
            ...props.remuneration,
          }
        : JSON.parse(JSON.stringify(remunerationObject)),
      buttonDisabled: false,
      error: {
        name: '',
      },
    };
  }

  remunerationService = new RemunerationService();

  componentWillReceiveProps(nextProps: Props) {
    const remuneration: any = nextProps.remuneration
      ? {
          ...nextProps.remuneration,
        }
      : JSON.parse(JSON.stringify(remunerationObject));
    this.setState({ remuneration });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const remuneration = { ...this.state.remuneration };
    remuneration[e.target.name] = e.target.value;
    this.setState({ remuneration });
  };

  saveRemuneration = () => {
    this.setState({ buttonDisabled: true });
    this.remunerationService?.addRemuneration(
      this.state.remuneration,
      (response) => {
        if (!this.props.remuneration) {
          this.setState({
            remuneration: JSON.parse(JSON.stringify(remunerationObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Remuneration ' + this.state.remuneration.id ? 'Updated' : 'Added',
          description:
            `Remuneration of name ${this.state.remuneration.name} was successfully ` +
            `${this.state.remuneration.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Remuneration ' + this.state.remuneration.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      remuneration: JSON.parse(JSON.stringify(remunerationObject)),
    });
  };

  render() {
    return (
      <div>
        <TSInput
          error={this.state.error.name}
          value={this.state.remuneration.name}
          onChange={this.onChange}
          name="name"
          type="text"
          addonBefore="Name"
          required
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveRemuneration}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  remuneration?: RemunerationNode | null;
  handleTabChange: (key) => void;
}

interface State {
  remuneration: RemunerationInput;
  error: {
    name: string;
  };
  buttonDisabled: boolean;
}

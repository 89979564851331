import { Icon, Menu } from 'antd';
import * as React from 'react';
import { RouterProps } from 'react-router';
import { Feature, SideBarMenu } from '../../constants';
import { CompanyNodeEdge } from '../master/Company/constants';

export const ProductionLink = (props: Props) => {
  const production: SideBarMenu = {
    [Feature.PRODUCTION_REPORT]: (
      <Menu.Item
        key="38"
        onClick={() => props.history.push('/dashboard/production/production')}
      >
        <span>
          <Icon type="interaction" />
          {props.company?.name === 'Jharcraft' ||
          props.company?.name.toLowerCase().includes('khadi')
            ? 'Production Report'
            : 'Production'}
        </span>
      </Menu.Item>
    ),
    [Feature.PRODUCTION_TRANSFER]: (
      <Menu.Item
        key="39"
        onClick={() => props.history.push('/dashboard/production_transfer')}
      >
        <Icon type="to-top" />
        <span>Transfer For Product Without Barcode</span>
      </Menu.Item>
    ),
    [Feature.PRODUCTION_RECEIVING]: (
      <Menu.Item
        key="40"
        onClick={() => props.history.push('/dashboard/production_receiving')}
      >
        <Icon type="vertical-align-bottom" />
        <span>Receiving For Product Without Barcode</span>
      </Menu.Item>
    ),
    [Feature.JOB_ORDER]: (
      <Menu.Item
        key="41"
        onClick={() => props.history.push('/dashboard/job_order')}
      >
        <Icon type="mail" />
        <span>Job Order</span>
      </Menu.Item>
    ),
    [Feature.CONVERSION]: (
      <Menu.Item
        key="42"
        onClick={() => props.history.push('/dashboard/production/conversion')}
      >
        <span>
          <Icon type="interaction" />
          {props.company?.name.toLowerCase().includes('jharcraft') ||
          props.company?.name.toLowerCase().includes('khadi')
            ? 'WAP'
            : 'Conversion'}
        </span>
      </Menu.Item>
    ),
    [Feature.DIRECT_PRODUCTION]: (
      <Menu.Item
        key="43"
        onClick={() =>
          props.history.push('/dashboard/production/direct-production')
        }
      >
        <Icon type="interaction" />
        <span>Production</span>
      </Menu.Item>
    ),
    [Feature.QUOTATION]: (
      <Menu.Item
        key="44"
        onClick={() => props.history.push('/dashboard/quotation')}
      >
        <Icon type="solution" />
        <span>Quotation</span>
      </Menu.Item>
    ),
  };

  return production;
};

interface Props extends RouterProps {
  company: CompanyNodeEdge | null;
}

import * as React from 'react';
import { Tabs, Icon, Skeleton, Table, Row, Col } from 'antd';
import { ConversionNodeConnection, ConversionNodeEdge } from '../../../schema';
import { CompanyService } from '../../../service/CompanyService';
import { AddConversion } from './AddConversion';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { conversionQuery } from './constants';
import { Pagination } from 'sha-el-design/lib';
import moment from 'moment';
import { TSInput } from '../../common/TSInput';
import { ConversionPdf } from './ConversionPdf';
import { checkAddPermission, checkProductionPermission } from '../../helper';
import { PDFViewer } from '@react-pdf/renderer';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class Conversion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedConversion: null,
      company: null,
      currentPage: 1,
      after: '',
      finishedProduct: '',
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedConversion: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  conversionColumn = () => {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (text) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Finished Product',
        dataIndex: 'node.finishedProduct',
        key: 'finishedProduct',
        render: (text, record) => {
          return this.state.user &&
            checkProductionPermission(this.state.user) ? (
            <a
              onClick={() => {
                this.setState({ selectedConversion: record, activeKey: '2' });
              }}
            >
              {text}
            </a>
          ) : (
            text
          );
        },
      },
      {
        title: 'Image',
        key: 'image',
        align: 'center' as const,
        render: (text, record) => <img width="100px" src={record.node.image} />,
      },
      {
        title: 'Finished Product',
        dataIndex: 'node.finishedProduct',
        key: 'finishedProduct',
      },
      {
        title: 'Old Design Code',
        dataIndex: 'node.oldDesignCode',
        key: 'oldDesignCode',
      },
      {
        title:
          this.state.company?.name.toLowerCase().includes('jharcraft') ||
          this.state.company?.name.toLowerCase().includes('khadi')
            ? 'WAP Number'
            : 'New Design Code',
        dataIndex: 'node.designCode',
        key: 'designCode',
      },
      {
        title: 'Raw Materials Cost',
        dataIndex: 'node.rawMaterialsCost',
        key: 'rawMaterialsCost',
      },
      {
        title: 'Remuneration Cost',
        dataIndex: 'node.remunerationsCost',
        key: 'remunerationCost',
      },
      {
        title: 'Net Cost',
        dataIndex: 'node.netCost',
        key: 'netCost',
      },
    ];
    if (
      this.state.company?.name.toLowerCase().includes('jharcraft') ||
      this.state.company?.name.toLowerCase().includes('khadi')
    ) {
      columns.push({
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({ selectedConversion: obj, activeKey: '3' });
            }}
          >
            <Icon type="download" />
          </a>
        ),
      });
    }
    return columns;
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }

    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      finishedProduct,
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              {this.state.company.name.toLowerCase().includes('jharcraft') ||
              this.state.company?.name.toLowerCase().includes('khadi')
                ? 'WAP List'
                : 'Conversion List'}
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.finishedProduct}
                onChange={(e) =>
                  this.setState({ finishedProduct: e.target.value })
                }
                name="finishedProduct"
                type="text"
                addonBefore="Search Finished Product"
                placeholder="Enter Name"
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={conversionQuery}
            variables={{
              companyId,
              first: 20,
              after,
              activeKey,
              product: finishedProduct,
            }}
            render={(
              response: { allConversions: ConversionNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allConversions.edges}
                    columns={this.conversionColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allConversions.pageInfo.endCursor,
                        response.allConversions.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user &&
          checkAddPermission(this.state.user) &&
          this.state.user &&
          checkProductionPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  {this.state.company.name
                    .toLowerCase()
                    .includes('jharcraft') ||
                  this.state.company?.name.toLowerCase().includes('khadi')
                    ? 'Add WAP'
                    : 'Add Conversion'}
                </span>
              }
              key="2"
            >
              <AddConversion
                conversion={this.state.selectedConversion}
                handleTabChange={this.handleTabChange}
                company={this.state.company}
              />
            </Tabs.TabPane>
          )}
        {this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                {this.state.company.name.toLowerCase().includes('jharcraft') ||
                this.state.company?.name.toLowerCase().includes('khadi')
                  ? 'WAP PDF'
                  : 'Conversion Pdf'}
              </span>
            }
            key="3"
          >
            {(this.state.company.name === 'Jharcraft' ||
              this.state.company?.name.toLowerCase().includes('khadi')) &&
              this.state?.selectedConversion?.node && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <ConversionPdf
                    conversion={this.state?.selectedConversion?.node}
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedConversion: ConversionNodeEdge | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  finishedProduct: string;
  user?: UserNodeEdge | null;
}

interface Props {}

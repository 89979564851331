import * as React from 'react';
import { specialDateReportQuery } from './constants';
import { Table, Skeleton, Button, Row, Col, DatePicker } from 'antd';
import { CompanyNodeEdge } from '../Company/constants';
import { GraphqlQuery } from 'requestapijs';
import moment from 'moment';
import { CustomerNodeConnection, CustomerNodeEdge } from '../../../schema';
import { uniqBy } from 'lodash';
import { downloadToFile } from '../../helper';

export class SpecialDateReport extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {}

  columns = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'node.address',
      key: 'address',
    },
    {
      title: 'Contact No',
      dataIndex: 'node.contactNo',
      key: 'contactNo',
    },
    {
      title: 'Email',
      dataIndex: 'node.email',
      key: 'email',
    },
    {
      title: 'Date of Birth',
      key: 'dateOfBirth',
      render: (text: CustomerNodeEdge) =>
        text.node?.dateOfBirth?.split('-').reverse().join('-'),
    },
    {
      title: 'Date of Anniversary',
      key: 'dateOfAnniversary',
      render: (text: CustomerNodeEdge) =>
        text.node?.dateOfAnniversary?.split('-').reverse().join('-'),
    },
    {
      title: 'Last Purchased',
      key: 'lastPurchaseDetails',
      render: (text: CustomerNodeEdge) => {
        const purchasedAt = text.node?.lastPurchaseDetails?.lastPurchasedAt;
        const purchasedFrom = text.node?.lastPurchaseDetails?.lastPurchasedFrom;
        return !purchasedAt
          ? purchasedFrom
          : `${moment(purchasedAt).format('DD-MM-YYYY')}(${purchasedFrom})`;
      },
    },
  ];

  downloadCsv = (allCustomers: CustomerNodeEdge[]) => {
    const data = allCustomers
      .map((csl, i) =>
        this.columns()
          .map((col) => {
            if (col.dataIndex) {
              const value = col.dataIndex.split('.');
              let returnValue = csl[value.splice(0, 1)[0]];
              value.forEach((val) => {
                returnValue = returnValue[val];
              });
              return returnValue;
            }
            return col.render?.(csl);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `special-date-report-${
        this.props.company.name
      }-${new Date().toISOString()},`,
      'SPECIAL DATE REPORT',
      this.props.company.name,
      'Head Office',
      moment(this.state.date).format('DD-MM-YYYY'),
      moment(this.state.date).format('DD-MM-YYYY'),
      '',
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              value={moment(this.state.date)}
              onChange={(e) =>
                this.setState({ date: moment(e).format('YYYY-MM-DD') })
              }
              placeholder="Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={specialDateReportQuery}
          variables={{
            companyId: this.props.company.id,
            birthDate: `-${moment(this.state.date).format('MM-DD')}`,
            anniversaryDate: `-${moment(this.state.date).format('MM-DD')}`,
          }}
          render={(
            response: {
              allCustomersUsingBirthDate: CustomerNodeConnection;
              allCustomersUsingAnniversaryDate: CustomerNodeConnection;
            },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response) {
              return [
                <Table
                  key="table"
                  dataSource={uniqBy(
                    [
                      ...response.allCustomersUsingBirthDate.edges,
                      ...response.allCustomersUsingAnniversaryDate.edges,
                    ],
                    'node.id',
                  )}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(
                      uniqBy(
                        [
                          ...response.allCustomersUsingBirthDate.edges,
                          ...response.allCustomersUsingAnniversaryDate.edges,
                        ],
                        'node.id',
                      ),
                    )
                  }
                  key="button"
                />,
              ];
            }
            return <Skeleton active />;
          }}
        />
      </div>
    );
  }
}

interface State {
  date: string;
}

interface Props {
  company: CompanyNodeEdge;
}

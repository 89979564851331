import moment from 'moment';
import { PageInfo } from '../../schema';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';

export interface MemoNode {
  id?: string;
  date: string;
  bookNumber: string;
  serialStart: string;
  serialEnd: string;
  financialYear: string;
  memoType: 'CASH MEMO' | 'BIJAK';
  locationId?: string | null;
  companyId?: string | null;
  location?: BranchNodeEdge;
  company?: CompanyNodeEdge;
}

export interface MemoError {
  bookNumber: string;
  serialStart: string;
  serialEnd: string;
  financialYear: string;
  locationId: string;
}

export interface MemoNodeEdge {
  cursor: string;
  node: MemoNode;
}

export interface MemoNodeConnection {
  pageInfo: PageInfo;
  edges: MemoNodeEdge[];
}

export const memoObject: MemoNode = {
  date: moment().format('YYYY-MM-DD'),
  bookNumber: '',
  serialStart: '',
  serialEnd: '',
  financialYear: '',
  memoType: 'CASH MEMO',
  locationId: null,
  companyId: null,
};

export const memoErrorObject: MemoError = {
  bookNumber: '',
  serialStart: '',
  serialEnd: '',
  financialYear: '',
  locationId: '',
};

export const memoQuery = `
query memoQuery ($companyId: ID, $after: String) {
  allMemo (companyId: $companyId, after: $after, first: 20, orderBy: "-id") {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        date
        bookNumber
        serialStart
        serialEnd
        financialYear
        memoType
        location {
          id
          name
        }
        company {
          name
        }
      }
    }
  }
}
`;

export interface MemoQueryResponse {
  data: {
    allMemo: {
      edges: MemoNodeEdge[];
    };
  };
}

export interface AddMemoVariables {
  input: {
    memo: MemoNode;
  };
}

export interface AddMemoPayload {
  newMemo: MemoNode;
}

export const memoMutation = `
mutation Memo($input: CreateMemoInput!) {
  createMemo (input: $input) {
    newMemo {
      id
    }
  }
}
`;

import * as React from 'react';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';

export class MdDashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  render() {
    return (
      <>
        {this.state.company?.name === 'Jharcraft' && (
          <iframe
            src="http://reports.scriptitsolution.tech/public/dashboard/b9fd3e6b-371b-482a-a918-7eb0cf54276d"
            frameBorder="0"
            width="100%"
            height="1000"
            allowTransparency
          ></iframe>
        )}
      </>
    );
  }
}

interface Props {}

interface State {
  company: CompanyNodeEdge | null;
}

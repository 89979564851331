import * as React from 'react';
import { DamageNode, DamageError } from './constants';
import { Row, Col, DatePicker, Divider, Button } from 'antd';
import moment from 'moment';
import { TSInput } from '../common/TSInput';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ProductNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';

export const DamageForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={8}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
          disabled
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product</Col>
        <Col span={4}>Quantity</Col>
        <Col span={4}>Pur. Rate</Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={8}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.isEditActive
                    ? () => {}
                    : props.productChangeHandler(
                        { target: { name: 'productId', value: String(e) } },
                        index,
                      );
                  props.isEditActive
                    ? () => {}
                    : props.productObjChangehandler(
                        (f as any).props.label,
                        index,
                      );
                }}
                allowClear
                extrafields={['sellingRate', 'purchaseRate']}
                company={props.company}
                value={product.productId || ''}
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.purchaseRate || ''}
                name="purchaseRate"
                placeholder="Pur. Rate"
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('-', index)
                }
                disabled={
                  props.values.productDetails.length === 1 || props.isEditActive
                }
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('+', index)
                }
                disabled={
                  (product.productId && product.quantity ? false : true) ||
                  props.isEditActive
                }
              />
            </Col>
          </Row>
        </div>
      ))}
      <TSInput
        error=""
        type="text"
        value={props.values.remarks}
        onChange={props.onChange}
        name="remarks"
        addonBefore="Remarks"
      />
    </Row>
  );
};

interface Props {
  values: DamageNode<ProductDetailNodeEdge[]>;
  error: DamageError;
  company: CompanyNodeEdge | null;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangehandler: (productObj: ProductNode, index: number) => void;
  isEditActive: boolean;
}

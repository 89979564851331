import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import { contractorMutation } from '../components/master/Contractor/constants';
import {
  CreateContractorInput,
  ContractorInput,
  CreateContractorPayload,
} from '../schema';

export class ContractorService {
  static instance: ContractorService;
  compnayService = new CompanyService();

  constructor() {
    if (ContractorService.instance) {
      return ContractorService.instance;
    } else {
      ContractorService.instance = this;
    }
  }

  addContractor(
    data: ContractorInput,
    onSuccess?: (response) => void,
    onError?: (error) => void,
  ) {
    Api.graphql<{ input: CreateContractorInput }, CreateContractorPayload>(
      API_URL,
      {
        input: {
          contractor: {
            ...data,
            companyId: this.compnayService?.company$.value?.id,
          },
        },
      },
      contractorMutation,
      HEADER,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }
}

import * as React from 'react';
import { UnitNodeEdge, UnitError } from './constants';
import { TSInput } from '../../common/TSInput';
import { Checkbox } from 'antd';

export const UnitForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error={props.error.value}
        value={props.values.value}
        onChange={props.onChange}
        name="value"
        type="text"
        addonBefore="Value"
      />
      <Checkbox
        checked={props.values.allowDecimal}
        onChange={(e) =>
          props.onChange({
            target: { name: 'allowDecimal', value: e.target.checked },
          })
        }
        style={{ margin: '10px 0' }}
      >
        Allow Decimal Value
      </Checkbox>
    </div>
  );
};

interface Props {
  values: UnitNodeEdge;
  error: UnitError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: boolean } },
  ) => void;
}

import * as React from 'react';
import moment from 'moment';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { Button, Col, DatePicker, Divider, Row, Skeleton, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { itemWiseTransferQuery } from './constants';
import { ProductDetailsNode, ProductDetailsNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design/lib';
import { BranchNodeEdge } from '../master/Branch/constants';
import { ITEM_WISE_TRANSFER_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class ItemWiseTransferReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
      isLoading: false,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => {
    const col = [
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'node.date',
        render: (value) => value && moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'Transfer Invoice Number',
        key: 'transNo',
        dataIndex: 'node.transferInvoiceNo',
      },
      {
        title: 'Receiving Invoice Number',
        key: 'receivNo',
        dataIndex: 'node.receivingInvoiceNo',
      },
      {
        title: 'Product Name',
        key: 'name',
        dataIndex: 'node.product.name',
      },
      {
        title: 'Old Barcode',
        key: 'oldBarcode',
        dataIndex: 'node.product.oldBarcodeId',
      },
      {
        title: 'New Barcode',
        key: 'newBarcode',
        dataIndex: 'node.product.newBarcodeId',
      },
      {
        title: 'Division',
        key: 'division',
        dataIndex: 'node.product.division',
      },
      {
        title: 'Category',
        key: 'category',
        dataIndex: 'node.product.category.name',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'node.quantity',
      },
      {
        title: 'CP',
        key: 'cp',
        dataIndex: 'node.product.purchaseRate',
      },
      {
        title: 'CP Value',
        key: 'cpValue',
        dataIndex: 'node',
        render: (value: ProductDetailsNode) =>
          (value.quantity * (value.product?.purchaseRate || 0)).toFixed(2),
      },
      {
        title: 'MRP',
        key: 'mrp',
        dataIndex: 'node.product.sellingRate',
      },
      {
        title: 'MRP Value',
        key: 'mrpValue',
        dataIndex: 'node',
        render: (value: ProductDetailsNode) =>
          (value.quantity * (value.product?.sellingRate || 0)).toFixed(2),
      },
      {
        title: 'GST(%)',
        key: 'gstRate',
        dataIndex: 'node.product.gstRate',
      },
      {
        title: 'HSN Code',
        key: 'hsnCode',
        dataIndex: 'node.product.hsnCode',
      },
      {
        title: 'Transfer To',
        key: 'transferTo',
        dataIndex: 'node.movementFor.name',
        render: (value) =>
          value || this.props.business.type === 'company'
            ? value
            : 'Head Office',
      },
    ];

    if (this.props.business.type === 'company') {
      col.push({
        title: 'To Vendor',
        key: 'vendorFor',
        dataIndex: 'node.vendorFor.name',
      });
    }

    return col;
  };

  downloadCsv = () => {
    let queryParams = `companyId=${this.props.company.id}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;
    queryParams +=
      this.props.business.type === 'branch'
        ? `&branchId=${this.props.branch?.id}`
        : '';

    this.setState({ isLoading: true });

    fetch(ITEM_WISE_TRANSFER_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) =>
        downloadToFile(
          csv,
          `item-wise-transfer-report-${new Date()}`,
          'ITEM WISE TRANSFER REPORT REPORT',
          this.props.business.type === 'branch'
            ? this.props.business.companyName || ''
            : this.props.business.name,
          this.props.business.type === 'branch'
            ? this.props.business.name
            : 'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      )
      .then(() => this.setState({ isLoading: false }));
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { startDate, endDate, currentPage, after } = this.state;
    const {
      company: { id: companyId },
      activeKey,
      business,
      branch,
    } = this.props;
    const movementFromId = business.type === 'branch' ? branch?.id : null;
    const isHeadOffice = business.type === 'company';
    const variables = {
      startDate,
      endDate,
      companyId,
      after,
      movementFromId,
      isHeadOffice,
      activeKey,
    };

    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Divider />
        {startDate && endDate && (
          <GraphqlQuery
            queryString={itemWiseTransferQuery}
            variables={variables}
            render={(
              response: { allProductDetails: ProductDetailsNodeConnection },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProductDetails.edges}
                    columns={this.columns()}
                    rowKey={(record) => record.node?.id || ''}
                    pagination={false}
                    scroll={{ x: true }}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProductDetails.pageInfo.endCursor,
                        response.allProductDetails.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                  <Button
                    key="download"
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() => this.downloadCsv()}
                    disabled={this.state.isLoading}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch?: BranchNodeEdge | null;
  business: Bussiness;
  activeKey: string;
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
  isLoading: boolean;
}

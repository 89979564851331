import { ChallanInput } from '../../schema';
import moment from 'moment';

export interface ProductDetails {
  productName: string;
  unit: string;
  rate: number | null;
  orderQuantity: number | null;
  availableQuantity?: number;
  dues: number | null;
  receivedQuantity: number;
  amount: number | null;
  productId: string | null;
}

export const productDetailsObject: ProductDetails = {
  productName: '',
  unit: '',
  rate: null,
  orderQuantity: null,
  amount: null,
  availableQuantity: 0,
  dues: null,
  receivedQuantity: 0,
  productId: null,
};

export const challanObject: ChallanInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  productDetails: JSON.stringify([productDetailsObject]),
  purchaseOrderId: null,
  vendorChallanNo: null,
  vendorChallanDate: null,
  companyId: null,
  dumpToHeadOffice: true,
  dumpLocationId: null,
  remarks: null,
};

export interface ChallanError {
  date: string;
  purchaseOrderId: string;
  vendorChallanNo: string;
  vendorChallanDate: string;
  dumpLocationId: string;
  productDetails: {
    receivedQuantity: string;
  }[];
}

export const challanErrorObj: ChallanError = {
  date: '',
  purchaseOrderId: '',
  vendorChallanNo: '',
  vendorChallanDate: '',
  dumpLocationId: '',
  productDetails: [
    {
      receivedQuantity: '',
    },
  ],
};

export const challanQuery = `
query ChallanList($companyId: ID, $first: Int, $after: String) {
  allChallans(companyId: $companyId, first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        date
        challanNo
        productDetails
        purchaseOrder {
          id
          date
          vendor {
            id
            name
          }
          purchaseOrderNo
        }
        vendorChallanNo
        vendorChallanDate
        dumpToHeadOffice
        dumpLocation {
          id
          name
        }
        remarks
      }
    }
  }
}
`;

export const challanMutation = `
mutation addChallan($input: CreateChallanInput!) {
  createChallan(input: $input){
    newChallan {
      id
      challanNo
    }
  }
}
`;

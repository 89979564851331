import React from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Divider, notification, Row } from 'antd';
import {
  JobOrderPartialCancelInput,
  ProductDetailsNodeConnection,
  TransferNode,
} from '../../schema';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { TSInput } from '../common/TSInput';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  JobOrderPartialCancelError,
  jobOrderPartialCancelError,
  JobOrderPartialCancelNode,
} from './constants';
import { TransferNode as TransferNodeEdge } from '../Transfer/constants';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { JobOrderService } from '../../service/JobOrderService';

export class JobOrderPartialCancel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      jobOrderPartialCancel: this.handleJobOrderPartialCancelPropsUpdate(
        this.props,
      ),
      jobOrderPartialCancelError: { ...jobOrderPartialCancelError },
      buttonDisabled: false,
    };
  }

  jobOrderService = new JobOrderService();

  handleJobOrderPartialCancelPropsUpdate(
    nextProps: Props,
  ): JobOrderPartialCancelNode {
    const oldTransfer: TransferNode = nextProps.transferForPartialCancel;
    const oldProductDetails = (
      oldTransfer.productDetails as unknown as ProductDetailsNodeConnection
    ).edges;

    const productDetails: ProductDetailNodeEdge[] = [];

    oldProductDetails.forEach((pd) => {
      productDetails.push({
        date: moment().format('YYYY-MM-DD'),
        productId: pd.node?.product?.id,
        rate: pd.node?.rate || 0,
        purchaseRate: pd.node?.purchaseRate,
        quantity: null,
        amount: null,
        movementType: 'DOWN',
        movementForId: pd.node?.movementFrom?.id,
        movementFromId: pd.node?.movementFor?.id,
        vendorForId: pd.node?.vendorFor?.id,
        status: 'ACCEPTED',
        companyId: nextProps.company.id,
      });
    });

    const transferData: TransferNodeEdge<ProductDetailNodeEdge[]> = {
      date: moment().format('YYYY-MM-DD'),
      fromHeadOffice: !!oldTransfer.toHeadOffice,
      toHeadOffice: !!oldTransfer.fromHeadOffice,
      fromLocationId: oldTransfer.toLocation?.id || null,
      toLocationId: oldTransfer.fromLocation?.id || null,
      productDetails: productDetails,
      status: 'ACCEPTED',
      isTransfer: true,
      remarks:
        'Partial Return for Job Order: ' +
        oldTransfer.jobOrder?.jobOrderNo +
        '-PC',
      fromVendor: !!oldTransfer.toVendor,
      toVendor: !!oldTransfer.fromVendor,
      vendorForId: oldTransfer.vendorFor?.id,
      companyId: nextProps.company.id,
    };

    const jobOrderPartialCancel: JobOrderPartialCancelNode = {
      jobOrderId: nextProps.transferForPartialCancel.jobOrder?.id || '',
      vendorInvoiceNo: '',
      vendorDate: moment().format('YYYY-MM-DD'),
      transferData: transferData,
      remarks: null,
      companyId: nextProps.company.id || '',
    };

    return jobOrderPartialCancel;
  }

  onChangeHandler(
    e: { target: { name: string; value: string } },
    index?: number,
  ) {
    const jobOrderPartialCancel = { ...this.state.jobOrderPartialCancel };

    if (index !== undefined && e.target.name === 'quantity') {
      jobOrderPartialCancel.transferData.productDetails[index].quantity =
        Number(e.target.value);
      jobOrderPartialCancel.transferData.productDetails[index].amount =
        (jobOrderPartialCancel.transferData.productDetails[index]
          .purchaseRate || 0) * Number(e.target.value);
    } else {
      jobOrderPartialCancel[e.target.name] = e.target.value;
    }

    this.setState({ jobOrderPartialCancel, jobOrderPartialCancelError });
  }

  checkError = () => {
    let isError = false;

    const jobOrderPartialCancel = { ...this.state.jobOrderPartialCancel };
    const jobOrderPartialCancelError = {
      ...this.state.jobOrderPartialCancelError,
    };

    if (
      jobOrderPartialCancel.vendorInvoiceNo === null ||
      jobOrderPartialCancel.vendorInvoiceNo === ''
    ) {
      isError = true;
      jobOrderPartialCancelError.vendorInvoiceNo =
        'This field is required. Please enter a value!';
    }
    if (jobOrderPartialCancel.vendorDate === null) {
      isError = true;
      jobOrderPartialCancelError.vendorDate =
        'This field is required. Please enter a value!';
    }

    this.setState({ jobOrderPartialCancelError });
    return isError;
  };

  saveParialCancel = () => {
    if (this.checkError()) return;

    this.setState({ buttonDisabled: true });
    const jobOrderPartialCancel = { ...this.state.jobOrderPartialCancel };
    jobOrderPartialCancel.transferData.productDetails =
      jobOrderPartialCancel.transferData.productDetails.filter(
        (pd) => pd.quantity !== null && pd.quantity !== 0,
      );
    this.jobOrderService.jobOrderPartialCancel(
      {
        ...(jobOrderPartialCancel as unknown as JobOrderPartialCancelInput),
      },
      () => {
        this.setState({
          buttonDisabled: false,
        });
        this.props.handleModalAction(false, null);
        notification.success({
          message: 'Job order status updated',
          description: 'Job order was partially cancelled',
        });
      },
      (error) => {
        notification.error({
          message: 'Job order Update Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
        this.props.handleModalAction(false, null);
      },
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={12}>
            <TSInput
              error={this.state.jobOrderPartialCancelError.vendorInvoiceNo}
              value={this.state.jobOrderPartialCancel.vendorInvoiceNo ?? ''}
              name="vendorInvoiceNo"
              type="text"
              onChange={(e) => {
                this.onChangeHandler(e);
              }}
              addonBefore="Vendor Invoice Number"
            />
          </Col>
          <Col span={12}>
            <DatePicker
              value={moment(this.state.jobOrderPartialCancel.vendorDate)}
              onChange={(e) => {
                this.onChangeHandler({
                  target: {
                    name: 'vendorDate',
                    value: (e && moment(e).format('YYYY-MM-DD')) ?? '',
                  },
                });
              }}
              placeholder="Vendor Date"
              format="DD-MM-YYYY"
              style={{ margin: '5px 0', width: '100%' }}
            />
            <span style={{ color: 'red' }}>
              {this.state.jobOrderPartialCancelError.vendorDate}
            </span>
          </Col>
        </Row>
        <Divider />
        <h4>Raw Materials</h4>
        <Row>
          <Col span={8}>Product</Col>
          <Col span={4}>Transfered Quantity</Col>
          <Col span={4}>Returned Quantity</Col>
          <Col span={4}>Pur. Rate</Col>
          <Col span={4}>Amount</Col>
          <Divider />
        </Row>
        {this.state.jobOrderPartialCancel.transferData &&
          this.state.jobOrderPartialCancel.transferData.productDetails.map(
            (product, index) => (
              <div key={'product' + index}>
                <Row>
                  <Col span={8}>
                    <ProductDropDown
                      extrafields={[
                        'purchaseRate',
                        'sellingRate',
                        'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                      ]}
                      company={this.props.company}
                      value={product.productId || ''}
                      disabled
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error=""
                      type="number"
                      value={
                        (
                          this.props.transferForPartialCancel
                            .productDetails as unknown as ProductDetailsNodeConnection
                        ).edges[index].node?.quantity || ''
                      }
                      name="transferedquantity"
                      placeholder="TransferedQuantity"
                      disabled
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error=""
                      type="number"
                      value={product.quantity ?? ''}
                      name="quantity"
                      placeholder="Returned Quantity"
                      onChange={(e) =>
                        this.onChangeHandler(
                          {
                            target: { name: 'quantity', value: e.target.value },
                          },
                          index,
                        )
                      }
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error=""
                      type="number"
                      value={product.purchaseRate || ''}
                      name="purchaseRate"
                      placeholder="Purchase Rate"
                      disabled
                    />
                  </Col>
                  <Col span={4}>
                    <TSInput
                      error=""
                      type="number"
                      value={product.amount || ''}
                      name="amount"
                      placeholder="Amount"
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ),
          )}
        <Divider />
        <Col>
          <TSInput
            error=""
            value={this.state.jobOrderPartialCancel.remarks ?? ''}
            name="remarks"
            type="text"
            onChange={(e) => {
              this.onChangeHandler(e);
            }}
            addonBefore="Remarks"
          />
        </Col>
        <Divider />

        <Row>
          <Col span={12}>
            <Button
              type="primary"
              onClick={this.saveParialCancel}
              children="Submit"
              style={{
                width: '80%',
                display: 'block',
                margin: 'auto',
              }}
              disabled={this.state.buttonDisabled}
            />
          </Col>
          <Col span={12}>
            <Button
              type="ghost"
              onClick={() => this.props.handleModalAction(false, null)}
              children="Close"
              style={{
                width: '80%',
                display: 'block',
                margin: 'auto',
              }}
              disabled={this.state.buttonDisabled}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

interface Props {
  transferForPartialCancel: TransferNode;
  company: CompanyNodeEdge;
  handleModalAction: (showModal: boolean, node: TransferNode | null) => void;
}
interface State {
  jobOrderPartialCancel: JobOrderPartialCancelNode;
  jobOrderPartialCancelError: JobOrderPartialCancelError;
  buttonDisabled: boolean;
}

import * as React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import {
  PurchaseNodeEdgeMutation,
  PurchaseNodeEdgeQuery,
  purchaseObject,
} from './constants';
import { PurchaseService } from '../../service/PurchaseService';
import { PurchaseForm } from './PurchaseForm';
import { handleProductDetailsData } from '../Transfer/constants';
import { VendorEdges } from '../master/vendor/constants';
import { VendorService } from '../../service/VendorService';
import { ProductNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { nestedAccess } from '../helper';

export class AddPurchase extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      purchase: props.purchase
        ? {
            ...props.purchase,
            vendorId: nestedAccess(props, 'purchase', 'vendor', 'id'),
            productDetails:
              props.purchase.productDetails &&
              handleProductDetailsData(props.purchase.productDetails),
          }
        : JSON.parse(JSON.stringify(purchaseObject)),
      productObjList: [],
      vendors: [],
      buttonDisabled: false,
    };
  }

  purchaseService = new PurchaseService();
  vendorService = new VendorService();

  componentWillReceiveProps(nextProps: Props) {
    const purchase: any = nextProps.purchase
      ? {
          ...nextProps.purchase,
          vendorId: nextProps.purchase.vendor.id,
          productDetails: nextProps.purchase.productDetails?.edges.map(
            (pd) => ({
              ...pd.node,
              productId: pd.node.product?.id,
            }),
          ),
        }
      : JSON.parse(JSON.stringify(purchaseObject));
    this.setState({ purchase });
  }

  componentDidMount() {
    this.fetchVendors();
  }

  fetchVendors = () => {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const purchase = { ...this.state.purchase };
    purchase[e.target.name] = e.target.value;
    const amount = this.handleAmount(purchase);
    purchase.netAmount = Math.round(
      Number(
        (
          Number(purchase.packingCharges) +
          Number(purchase.transportationCharges) +
          Number(purchase.otherCharges) +
          amount
        ).toFixed(2),
      ),
    );
    this.setState({ purchase });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.purchase.productDetails) {
      nextState.purchase.productDetails[index][e.target.name] = e.target.value;
      const taxableAmount =
        Number(nextState.purchase.productDetails[index].quantity) *
          Number(nextState.purchase.productDetails[index].purchaseRate) -
        (Number(nextState.purchase.productDetails[index].quantity) *
          Number(nextState.purchase.productDetails[index].purchaseRate) *
          Number(nextState.purchase.productDetails[index].discount)) /
          100;
      if (this.props.company.name.toLowerCase().includes('kraftribe')) {
        nextState.purchase.productDetails[index].amount = Number(
          taxableAmount.toFixed(2),
        );
      } else {
        nextState.purchase.productDetails[index].amount = Number(
          (
            taxableAmount +
            (taxableAmount *
              (nextState.purchase.productDetails[index].gstRate || 0)) /
              100
          ).toFixed(2),
        );
      }
      const amount = this.handleAmount(nextState.purchase);
      nextState.purchase.netAmount = Math.round(
        Number(
          (
            Number(nextState.purchase.packingCharges) +
            Number(nextState.purchase.transportationCharges) +
            Number(nextState.purchase.otherCharges) +
            amount
          ).toFixed(2),
        ),
      );
      this.setState(nextState);
    }
  };

  handleAmount = (purchase: PurchaseNodeEdgeMutation) => {
    return Number(
      purchase.productDetails?.reduce((sum, pd) => sum + Number(pd.amount), 0),
    );
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.purchase.productDetails?.push({
        date: null,
        productId: '',
        quantity: null,
        rate: null,
        purchaseRate: null,
        discount: null,
        gstRate: null,
        amount: null,
        movementType: 'PURCHASE',
        companyId: null,
      });
    } else {
      nextState.purchase.productDetails?.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    if (nextState.purchase.productDetails) {
      nextState.productObjList[index] = productObj;
      nextState.purchase.productDetails[index].rate = productObj.sellingRate;
      nextState.purchase.productDetails[index].purchaseRate =
        productObj.purchaseRate;
      nextState.purchase.productDetails[index].gstRate = productObj.hsn?.gst;
      this.setState(nextState);
    }
  };

  savePurchase = () => {
    this.setState({ buttonDisabled: true });
    this.purchaseService?.addPurchase(
      this.state.purchase,
      (response) => {
        if (!this.props.purchase) {
          this.setState({
            purchase: JSON.parse(JSON.stringify(purchaseObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Purchase ' + this.state.purchase.id ? 'Updated' : 'Added',
          description: `Purchase for bill no ${
            this.state.purchase.billNo
          } was successfuly ${this.state.purchase.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Purchase ' + this.state.purchase.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({ purchase: JSON.parse(JSON.stringify(purchaseObject)) });
  };

  render() {
    return (
      <div>
        <PurchaseForm
          values={this.state.purchase}
          onChange={this.onChange}
          vendors={this.state.vendors}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          company={this.props.company}
          isEditActive={this.props.isEditActive}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.savePurchase}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  purchase?: PurchaseNodeEdgeQuery | null;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  isEditActive: boolean;
}

interface State {
  purchase: PurchaseNodeEdgeMutation;
  vendors: VendorEdges[];
  buttonDisabled: boolean;
  productObjList: ProductNode[];
}

import * as React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { SaleChallanNode } from '../../schema';
import moment from 'moment';

export const GatePassReceipt: React.FunctionComponent<Props> = (props) => {
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <Text
          style={[
            style.darkBlackText,
            style.textCenter,
            { marginTop: 20, fontSize: '12pt' },
          ]}
        >
          Gate Pass
        </Text>
        <View style={style.divider} />
        <View fixed style={[style.section, style.box, { marginTop: 6 }]}>
          <View style={[style.inline, { padding: 3 }]}>
            <Text style={[style.mediumBlackText, style.inlineHead]}>
              Gate Pass No: {props.saleInvoice.challanNo.replace('HI/SI', 'GP')}
            </Text>
            <Text
              style={[style.mediumBlackText, style.inlineHead, style.textRight]}
            >
              Date: {moment().format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={[style.inline, { padding: 3 }]}>
            <Text style={[style.mediumBlackText, style.inlineHead]}>
              Vehicle No: {props.saleInvoice.vehicleNo}
            </Text>
            <Text
              style={[style.mediumBlackText, style.inlineHead, style.textRight]}
            >
              Destination: {props.saleInvoice.destination}
            </Text>
          </View>
          <View style={[style.inline, { padding: 3 }]}>
            <Text style={[style.mediumBlackText, style.inlineHead]}>
              Against Invoice No: {props.saleInvoice.challanNo}
            </Text>
            <Text
              style={[style.mediumBlackText, style.inlineHead, style.textRight]}
            >
              Invoice Amount: {props.saleInvoice.totalAmount}
            </Text>
          </View>
        </View>
        <Text
          style={[
            style.section,
            style.darkBlackText,
            style.textRight,
            { marginTop: 25 },
          ]}
        >
          Authorized Signatory
        </Text>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.4pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  saleInvoice: SaleChallanNode;
  company: CompanyNodeEdge;
}

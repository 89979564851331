import {
  SubareaInput,
  CreateSubareaPayload,
  CreateSubareaInput,
  SubareaNode,
} from '../schema';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { subareaMutation } from '../components/master/Subarea/constants';

export class SubareaService {
  static instance: SubareaService;

  constructor() {
    if (SubareaService.instance) {
      return SubareaService.instance;
    } else {
      SubareaService.instance = this;
    }
  }

  addSubarea(
    data: SubareaInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as SubareaNode).area;
    Api.graphql<{ input: CreateSubareaInput }, CreateSubareaPayload>(
      API_URL,
      { input: { subarea: data } },
      subareaMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

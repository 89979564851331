import * as React from 'react';
import {
  ConversionNode,
  ProductDetailsNodeConnection,
  RemunerationDetailNodeConnection,
} from '../../../schema';
import {
  StyleSheet,
  Page,
  View,
  Document,
  Image,
  Text,
} from '@react-pdf/renderer';
import { sumBy } from 'lodash';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../../config';

export const ConversionPdf: React.FunctionComponent<Props> = (props) => {
  const rawMaterialsEdges = (
    props.conversion.rawMaterials as any as ProductDetailsNodeConnection
  ).edges;
  const totalProductQty = sumBy(
    rawMaterialsEdges,
    (value) => value.node?.quantity || 0,
  );
  const totalProductWastage = sumBy(
    rawMaterialsEdges,
    (value) => value.node?.wastage || 0,
  );
  const remunerationEdges = (
    props.conversion.remunerations as any as RemunerationDetailNodeConnection
  ).edges;
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.15, height: 43 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              WAP
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              WAP For:{' '}
              <Text style={[style.darkBlackText]}>
                {props.conversion.finishedProduct}
              </Text>
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              Production Type:{' '}
              <Text style={[style.darkBlackText]}>
                {props.conversion.productionType}
              </Text>
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              Design Code:{' '}
              <Text style={[style.darkBlackText]}>
                {props.conversion.oldDesignCode}
              </Text>
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              WAP Number:{' '}
              <Text style={[style.darkBlackText]}>
                {props.conversion.designCode}
              </Text>
            </Text>
          </View>
          <View style={style.divider} />
        </View>
        <View>
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              Sl.
            </Text>
            <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
              Product
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.4 },
              ]}
            >
              Barcode
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Qty
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Wastage
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Rate
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {props.conversion.productionType === 'SERICULTURE'
                ? 'Peduncle Quantity'
                : 'Remarks'}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Amount
            </Text>
          </View>
          <View style={style.divider} />
          {rawMaterialsEdges.map((pd, i) => (
            <View
              style={[style.section, style.inline]}
              key={'pd' + i}
              wrap={false}
            >
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.3 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
                {pd.node?.product?.name}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  { flex: 0.4 },
                ]}
              >
                {pd.node?.product?.oldBarcodeId}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node?.quantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node?.wastage}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node?.rate}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {props.conversion.productionType === 'SERICULTURE'
                  ? pd.node?.peduncleQuantity
                  : pd.node?.remark}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {pd.node?.amount.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.highFlexInlineHead, style.darkBlackText]}>
              Total:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.4 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              {totalProductQty}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              {totalProductWastage}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.4 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {props.conversion.rawMaterialsCost &&
                Math.round(props.conversion.rawMaterialsCost).toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              Sl.
            </Text>
            <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
              Remuneration
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Qty
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Rate
            </Text>
            {props.conversion.productionType === 'HANDLOOM' ? (
              <Text
                style={[
                  style.inlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                Supervision Charge
              </Text>
            ) : (
              <View />
            )}
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Amount
            </Text>
          </View>
          <View style={style.divider} />
          {remunerationEdges.map((re, i) => (
            <View
              style={[style.section, style.inline]}
              key={'re' + i}
              wrap={false}
            >
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.3 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
                {re.node?.remuneration?.name}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {re.node?.quantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {re.node?.rate}
              </Text>
              {props.conversion.productionType === 'HANDLOOM' ? (
                <Text
                  style={[
                    style.inlineHead,
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  {re.node?.rate &&
                    re.node.supervisionCharge &&
                    (re.node.rate * re.node.supervisionCharge) / 100}
                </Text>
              ) : (
                <View />
              )}
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {re.node?.amount.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.highFlexInlineHead, style.darkBlackText]}>
              Total:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {}
            </Text>
            <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {props.conversion.remunerationsCost &&
                Math.round(props.conversion.remunerationsCost).toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.highFlexInlineHead, style.darkBlackText]}>
              Grand Total
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {}
            </Text>
            <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {props.conversion.netCost &&
                Math.round(props.conversion.netCost).toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.4pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  conversion: ConversionNode;
}

import { AreaInput } from '../../../schema';

export interface AreaError {
  name: string;
  districtId: string;
}

export const AREA_DATA: AreaInput = {
  name: '',
  districtId: '',
};

export const AREA_ERROR: AreaError = {
  name: '',
  districtId: '',
};

export const areaMutation = `
mutation addArea ($input: CreateAreaInput!) {
  createArea (input: $input) {
    newArea {
      id
      name
    }
  }
}
`;

export const areaQuery = `
query area ($after: String){
  allAreas(first: 20, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        name
        district {
          id
          name
        }
      }
    }
  }
}
`;

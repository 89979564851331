import * as React from 'react';
import { Table, Spin, Skeleton, Button, Row, Col, DatePicker } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { ProductNodeConnection, ProductNodeEdge } from '../../../schema';
import { ColumnProps } from '../../common/Table';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { downloadToFile } from '../../helper';
import { reorderLevelQuery } from '../../master/Product/constants';
import { Pagination } from 'sha-el-design';
import moment from 'moment';
import { TSInput } from '../../common/TSInput';
import { REORDER_REPORT_CSV_URL } from '../../../config';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';

export class ReorderReport extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      barcode: '',
      categoryId: null,
    };
  }

  componentWillReceiveProps() {
    this.setState({
      date: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      barcode: '',
    });
  }

  dateChangeHandler = (date) => {
    this.setState({ date: date && moment(date).format('YYYY-MM-DD') });
  };

  columns = () => {
    const stockColumns: ColumnProps<ProductNodeEdge>[] = [
      {
        title: 'Product Name',
        dataIndex: 'node.name',
        key: 'name',
      },
      {
        title: 'Barcode 1',
        dataIndex: 'node.oldBarcodeId',
        key: 'oldBarcodeId',
      },
      {
        title: 'Barcode 2',
        dataIndex: 'node.newBarcodeId',
        key: 'newBarcodeId',
      },
      {
        title: 'Reorder Level',
        dataIndex: 'node.reorderLevel',
        key: 'reorderLevel',
        render: (data) => Number(Number(data).toFixed(2)),
      },
      {
        title: 'Current Stock',
        key: 'totalStocks',
        render: (data) =>
          Number(Number(data.node.stock.totalStocks).toFixed(2)),
      },
      {
        title: 'Order Quantity',
        key: 'orderQuantity',
        dataIndex: '',
      },
    ];
    return stockColumns;
  };

  downloadCsv = (variables) => {
    const category = this.props.categories.find(
      (cat) => cat.node.id === this.state.categoryId,
    );
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(REORDER_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then(
        (v) =>
          category &&
          downloadToFile(
            v,
            `reorder-report-${new Date()}`,
            'REORDER REPORT',
            this.props.company.name,
            'Head Office',
            moment(this.state.date).format('DD-MM-YYYY'),
            moment(this.state.date).format('DD-MM-YYYY'),
            category.node.name,
          ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { date, after, barcode, categoryId } = this.state;
    const {
      company: { id: companyId },
    } = this.props;
    const variables = { categoryId, companyId, date, after, barcode };
    return (
      <div>
        <Row>
          <Col span={8}>
            <TSAutoComplete
              error={''}
              inputProps={{ name: 'categoryId', addonBefore: 'Category' }}
              dataSource={this.props.categories}
              key="id"
              name="categoryId"
              onValueSelect={(e) =>
                this.setState({ categoryId: e.target.value })
              }
              displayKey="name"
              textValue={getValue(this.props.categories, categoryId)}
              allowClear
            />
          </Col>
          <Col span={5}>
            <DatePicker
              value={(date && moment(date)) || null}
              onChange={this.dateChangeHandler}
              placeholder="Select Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
              allowClear
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
        </Row>
        {categoryId && date && (
          <GraphqlQuery
            queryString={reorderLevelQuery}
            variables={barcode ? { ...variables, barcode } : variables}
            render={(
              response: { allProducts: ProductNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProducts.edges}
                    columns={this.columns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={this.state.currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProducts.pageInfo.endCursor,
                        response.allProducts.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
      </div>
    );
  }
}

interface State {
  date: string;
  currentPage: number;
  after: string;
  barcode: string;
  categoryId: string | null;
}

interface Props {
  company: CompanyNodeEdge;
  categories: ProductCategoryEdges[];
}

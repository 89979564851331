import { CompanyNodeEdge } from '../master/Company/constants';
import { CustomerNodeEdge } from '../master/Customer/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import moment from 'moment';

export interface ApprovalNodeEdgeQuery {
  id?: string;
  date: string;
  customer?: CustomerNodeEdge;
  isReturn: boolean;
  oldApproval?: ApprovalNodeEdgeQuery;
  productDetails: ProductDetailsNodeConnection;
  approvalNo: string;
  branchSerialNo: string;
  totalCostAmount: number;
  totalMrp: number;
  approvedByDesignation: string;
  approvedByName: string;
  company?: CompanyNodeEdge;
  branch?: BranchNodeEdge;
}

export interface ApprovalNodeEdgeMutation {
  id?: string;
  date: string;
  customerId?: string | null;
  isReturn: boolean;
  oldApprovalId?: string | null;
  productDetails: ProductDetailNodeEdge[];
  approvalNo: string | null;
  branchSerialNo: string | null;
  totalCostAmount: number | null | undefined;
  totalMrp: number | null | undefined;
  approvedByDesignation: string | null;
  approvedByName: string | null;
  companyId?: string | null;
  branchId?: string | null;
}

export interface ProductError {
  productId: string;
  rate: string;
  quantity: string;
  amount: string;
}

export interface ApprovalError {
  date: string;
  customerId: string;
  oldApprovalId: string;
  productDetails: ProductError[];
  approvedByDesignation: string;
  approvedByName: string;
}

export const approvalObject: ApprovalNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  isReturn: false,
  oldApprovalId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: null,
      quantity: null,
      amount: 0,
      movementForId: '',
      movementType: 'APPROVAL',
      companyId: '',
    },
  ],
  approvalNo: null,
  branchSerialNo: null,
  totalCostAmount: null,
  totalMrp: null,
  approvedByDesignation: null,
  approvedByName: null,
  companyId: null,
  branchId: null,
};

export const productDetailsErrorObj: ProductError = {
  productId: '',
  rate: '',
  quantity: '',
  amount: '',
};

export const approvalErrorObj: ApprovalError = {
  date: '',
  customerId: '',
  oldApprovalId: '',
  productDetails: [productDetailsErrorObj],
  approvedByDesignation: '',
  approvedByName: '',
};

export const approvalQuery = `
  query ApprovalList($companyId: ID!, $branchId: ID, $date_Lte: Date!, $branchId_Isnull: Boolean) {
    allApprovals(companyId: $companyId, branchId: $branchId, date_Lte: $date_Lte, branchId_Isnull: $branchId_Isnull) {
      edges {
        node {
          id
          date
          customer {
            id
            name
            contactNo
            address
          }
          isReturn
          oldApproval {
            id
            approvalNo
          }
          productDetails {
            edges {
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  category {
                    id
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                movementType
                movementFor {
                  id
                  name
                }
              }
            }
          }
          approvalNo
          branchSerialNo
          totalCostAmount
          totalMrp
          approvedByDesignation
          approvedByName
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export const approvalReportQuery = `
query ApprovalReport($companyId: ID, $branchId: ID, $after: String, $isHeadOffice: Boolean) {
  allProductDetails(
    companyId: $companyId,
    movementForId: $branchId,
    movementForId_Isnull: $isHeadOffice,
    movementType_In: "APPROVAL,APPROVAL_RETURN",
    first: 20,
    after: $after,
    orderBy: "-date",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
        }
        quantity
        rate
        amount
        approvalNewProductDetails {
          edges {
            node {
              approvalNo
              date
              approvedByName
              approvedByDesignation
              customer {
                name
              }
              oldApproval {
                approvalNo
              }
            }
          }
        }
      }
    }
  }
}
`;

export interface ApprovalQueryVariable {
  companyId: string;
  branchId: string;
  date_Lte: string;
}

export interface ApprovalQueryResponse {
  data: {
    allApprovals: {
      edges: ApprovalEdges[];
    };
  };
}

export interface ApprovalEdges {
  cursor: string;
  node: ApprovalNodeEdgeQuery;
}

export interface AddApprovalVariable {
  input: {
    approval: ApprovalNodeEdgeMutation;
  };
}

export const approvalMutation = `
  mutation addApproval(
    $input: CreateApprovalInput!
  ) {
    createApproval(input: $input){
      newApproval {
        id
        date
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

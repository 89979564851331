import { StateNodeEdge, StateNodeConnection, StateInput } from '../schema';
import { BehaviorSubject } from 'rxjs';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { stateMutation } from '../components/master/State/constant';

export class StateService {
  static instance;

  constructor() {
    if (StateService.instance) {
      return StateService.instance;
    } else {
      StateService.instance = this;
    }
  }

  public readonly state$ = new BehaviorSubject<StateNodeEdge[]>([]);

  addState(
    data: StateInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<{}, StateNodeConnection>(
      API_URL,
      {
        input: {
          state: data,
        },
      },
      stateMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

import { Button, notification } from 'antd';
import * as React from 'react';
import { ProductSubCategoryService } from '../../../service/ProductSubCategoryService';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { ProductSubCategoryNode, productSubCategoryObject } from './constants';
import { ProductSubCategoryForm } from './ProductSubCategoryForm';

export class AddProductSubCategory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productSubCategory: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
    };
  }

  productSubCategoryService = new ProductSubCategoryService();

  handlePropsValueUpdate = (props: Props) => {
    const productSubCategory = props.productSubCategory
      ? {
          ...props.productSubCategory,
          categoryId: props.productSubCategory.category?.id,
        }
      : JSON.parse(JSON.stringify(productSubCategoryObject));

    return productSubCategory;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      productSubCategory: this.handlePropsValueUpdate(nextProps),
    });
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const productSubCategory: ProductSubCategoryNode = {
      ...this.state.productSubCategory,
    };
    productSubCategory[e.target.name] = e.target.value;
    this.setState({ productSubCategory });
  };

  saveProductSubCategory = () => {
    this.setState({ buttonDisabled: true });
    this.productSubCategoryService?.addProductSubCategory(
      this.state.productSubCategory,
      (response) => {
        notification.success({
          message:
            'Product Sub Category' + this.state.productSubCategory.id
              ? 'Updated'
              : 'Added',
          description: `Product Sub Category ${
            this.state.productSubCategory.name
          } was successfully ${
            this.state.productSubCategory.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Product Sub Category ' + this.state.productSubCategory.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      productSubCategory: JSON.parse(JSON.stringify(productSubCategoryObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <ProductSubCategoryForm
          values={this.state.productSubCategory}
          onChange={this.onChange}
          categories={this.props.productCategories}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveProductSubCategory}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  productSubCategory?: ProductSubCategoryNode;
  handleTabChange?: (key) => void;
  productCategories: ProductCategoryEdges[];
}

interface State {
  productSubCategory: ProductSubCategoryNode;
  buttonDisabled: boolean;
}

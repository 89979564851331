import * as React from 'react';
import { notification, Button, Popconfirm } from 'antd';
import {
  handleConversionDetailData,
  productionTransferObj,
  productionTransferError,
  ProductionTransferError,
} from './constants';
import { ProductionTransferForm } from './ProductionTransferForm';
import { BranchEdges } from '../../master/Branch/constants';
import { BranchService } from '../../../service/BranchService';
import {
  ConversionDetailNodeConnection,
  ConversionNode,
  ProductionTransferNode,
  ProductionTransferInput,
  ConversionDetailInput,
  HsnNode,
} from '../../../schema';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { ProductionTransferService } from '../../../service/ProductionTransferService';
import { forEach } from 'lodash';

export class AddProductionTransfer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productionTransfer: this.handleTransferPropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      branch: [],
      buttonDisabled: false,
      conversionDetails: [],
      hsnDetails: [],
    };
  }

  productionTransferService = new ProductionTransferService();
  branchService = new BranchService();

  componentDidMount() {
    this.fetchBranch();
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      productionTransfer: this.handleTransferPropsUpdate(nextProps),
      error: this.handleErrorPropsUpdate(nextProps),
    });
  }

  handleTransferPropsUpdate = (nextProps: Props) => {
    const productionTransfer = nextProps.productionTransfer
      ? {
          ...nextProps.productionTransfer,
          fromBranchId:
            nextProps.productionTransfer.fromBranch &&
            nextProps.productionTransfer.fromBranch.id,
          toBranchId:
            nextProps.productionTransfer.toBranch &&
            nextProps.productionTransfer.toBranch.id,
          vendorForId:
            nextProps.productionTransfer.vendorFor &&
            nextProps.productionTransfer.vendorFor.id,
          conversionDetails: handleConversionDetailData(
            nextProps.productionTransfer
              .conversionDetails as any as ConversionDetailNodeConnection,
          ),
        }
      : JSON.parse(
          JSON.stringify({
            ...productionTransferObj,
            toHeadOffice: this.props.business.type === 'branch' ? true : false,
          }),
        );
    delete productionTransfer.fromBranch;
    delete productionTransfer.toBranch;
    delete productionTransfer.vendorFor;
    delete productionTransfer.hsnGstWiseDetails;
    return productionTransfer;
  };

  handleErrorPropsUpdate = (nextProps: Props) => {
    const error: ProductionTransferError = JSON.parse(
      JSON.stringify(productionTransferError),
    );
    const conversionDetails: ConversionDetailNodeConnection =
      nextProps.productionTransfer &&
      (nextProps.productionTransfer.conversionDetails as any);
    conversionDetails &&
      conversionDetails.edges.forEach(() => {
        if (conversionDetails.edges.length !== error.conversionDetails.length) {
          error.conversionDetails.push({
            conversionId: '',
            quantity: '',
            cost: '',
            amount: '',
            hsnId: '',
          });
        }
      });
    return error;
  };

  fetchBranch = () => {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const productionTransfer = { ...this.state.productionTransfer };
    productionTransfer[e.target.name] = e.target.value;
    this.setState({ productionTransfer });
  };

  checkError = () => {
    const error = { ...this.state.error };
    let isError = false;
    const notRequiredFields = [
      'fromBranchId',
      'toBranchId',
      'companyId',
      'conversionDetails',
      'transferInvoiceNo',
      'receivingDate',
      'remarks',
      'jobOrderId',
      'vendorForId',
    ];
    forEach(this.state.productionTransfer, (value, key) => {
      if (
        typeof value === 'string' &&
        notRequiredFields.indexOf(key) < 0 &&
        !value.trim()
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (
        !value &&
        value !== false &&
        notRequiredFields.indexOf(key) < 0
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (notRequiredFields.indexOf(key) < 0) {
        error[key] = '';
      }
    });
    if (
      !this.state.productionTransfer.toHeadOffice &&
      !this.state.productionTransfer.toBranchId
    ) {
      error.toBranchId = `This is a required field`;
      isError = true;
    } else {
      error.toBranchId = '';
      isError = isError;
    }
    this.setState({ error });
    return isError;
  };

  conversionDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.productionTransfer.conversionDetails[index][e.target.name] =
      e.target.value;
    nextState.productionTransfer.conversionDetails[index].amount = Number(
      (
        Number(nextState.productionTransfer.conversionDetails[index].quantity) *
        Number(nextState.productionTransfer.conversionDetails[index].cost)
      ).toFixed(2),
    );
    if (
      e.target.name === 'conversionId' &&
      nextState.productionTransfer.conversionDetails[index].hsnId
    ) {
      this.hsnObjChangehandler(nextState.hsnDetails[index], index);
    }
    this.setState(nextState);
  };

  checkConversionDetailError = () => {
    const conversionDetails: ConversionDetailInput[] = {
      ...this.state.productionTransfer.conversionDetails,
    };
    let isError = false;
    const error = { ...this.state.error };
    error.conversionDetails.map((econ, index) => {
      Object.keys(econ).forEach((conkey) => {
        if (!conversionDetails[index][conkey]) {
          isError = true;
          econ[conkey] = `This is a required field`;
        } else {
          econ[conkey] = '';
        }
      });
      return econ;
    });
    this.setState({ error });
    return isError;
  };

  updateConsversionsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.productionTransfer.conversionDetails.push({
        date: null,
        conversionId: '',
        quantity: 0,
        cost: 0,
        amount: 0,
        hsnId: null,
        gstRate: null,
        companyId: null,
        sourceBranchId: null,
        destinationBranchId: null,
        conversionType: 'TRANSFER',
        status: 'PENDING',
        vendorForId: null,
      });
      nextState.error.conversionDetails.push({
        conversionId: '',
        cost: '',
        quantity: '',
        amount: '',
        hsnId: '',
      });
    } else {
      nextState.productionTransfer.conversionDetails.splice(index, 1);
      nextState.error.conversionDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  conversionObjChangehandler = (
    conversionObj: ConversionNode,
    index: number,
  ) => {
    const nextState = { ...this.state };
    nextState.conversionDetails[index] = conversionObj;
    nextState.productionTransfer.conversionDetails[index].cost =
      conversionObj.netCost;
    this.setState(nextState);
  };

  hsnObjChangehandler = (hsnObj: HsnNode, index: number) => {
    const nextState = { ...this.state };
    const cost = nextState.productionTransfer.conversionDetails[index].cost;
    nextState.hsnDetails[index] = hsnObj;
    nextState.productionTransfer.conversionDetails[index].gstRate =
      hsnObj.hsnWithSameCode.length > 1
        ? hsnObj.hsnWithSameCode.find(
            (hsn) =>
              cost &&
              hsn.maxValue !== undefined &&
              hsn.maxValue >= cost &&
              hsn.minValue !== undefined &&
              hsn.minValue <= cost,
          )?.gst
        : hsnObj.hsnWithSameCode[0].gst;
    this.setState(nextState);
  };

  saveProductionTransfer = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkConversionDetailError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.productionTransferService?.addProductionTransfer(
      {
        ...this.state.productionTransfer,
        fromBranchId:
          this.props.business.type === 'company'
            ? null
            : this.props.business.id,
        toBranchId: this.state.productionTransfer.toHeadOffice
          ? null
          : this.state.productionTransfer.toBranchId,
      },
      (response) => {
        this.setState({
          productionTransfer: this.handleTransferPropsUpdate(this.props),
          error: this.handleErrorPropsUpdate(this.props),
          buttonDisabled: false,
        });
        notification.success({
          message:
            'Production Transfer ' + this.state.productionTransfer.id
              ? 'Updated'
              : 'Added',
          description: `Production Transfer was successfully ${
            this.state.productionTransfer.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Production Transfer ' + this.state.productionTransfer.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      productionTransfer: JSON.parse(
        JSON.stringify({
          ...productionTransferObj,
          toHeadOffice: this.props.business.type === 'branch' ? true : false,
        }),
      ),
      error: { ...productionTransferError },
    });
  };

  render() {
    return (
      <div>
        <ProductionTransferForm
          values={this.state.productionTransfer}
          error={this.state.error}
          onChange={this.onChange}
          conversionDetailChangeHandler={this.conversionDetailChangeHandler}
          updateconversionDetailsRow={this.updateConsversionsRow}
          branch={this.state.branch}
          conversionObjChangehandler={this.conversionObjChangehandler}
          isEditActive={this.props.isEditActive}
          company={this.props.company}
          business={this.props.business}
          hsnObjChangehandler={this.hsnObjChangehandler}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveProductionTransfer}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  productionTransfer?: ProductionTransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
  isEditActive: boolean;
  handleTabChange: (key) => void;
}

interface State {
  productionTransfer: ProductionTransferInput;
  error: ProductionTransferError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  conversionDetails: ConversionNode[];
  hsnDetails: HsnNode[];
}

import * as React from 'react';
import { UnitNodeEdge, unitQuery } from './constants';
import { Table, Tabs, Icon } from 'antd';
import Api from 'requestapijs';
import { AddUnit } from './AddUnit';
import { checkAddPermission } from '../../helper';
import { API_URL } from '../../../config';
import { UnitService } from '../../../service/UnitService';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';

export class Unit extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
      activeKey: '1',
      selectedUnit: null,
      user: null,
    };
  }

  unitService = new UnitService();
  userService = new UserService();

  componentDidMount() {
    this.unitService?.unit$.subscribe((units) => this.setState({ units }));
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedUnit: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  unitColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedUnit: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'node.value',
      key: 'value',
    },
  ];

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Unit
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.units}
            columns={this.unitColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Unit
            </span>
          }
          key="2"
        >
          <AddUnit
            unit={this.state.selectedUnit?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  units: {
    cursor: string;
    node: UnitNodeEdge;
  }[];
  activeKey: string;
  selectedUnit: {
    cursor: string;
    node: UnitNodeEdge;
  } | null;
  user?: UserNodeEdge | null;
}

interface Props {}

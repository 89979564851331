import * as React from 'react';
import { TSInput } from '../../common/TSInput';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { EmployeeNodeEdge, EmployeeError } from './constants';
import { Checkbox } from 'antd';
import { getValue } from '../../common/AutoCompleteHelper';
import { BranchEdges } from '../../master/Branch/constants';
import { GroupDropdown } from './GroupDropdown';

export const EmployeeForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.username}
        value={props.values.username || ''}
        onChange={props.onChange}
        name="username"
        type="text"
        addonBefore="Username"
      />
      <TSInput
        error={props.error.email}
        value={props.values.email}
        onChange={props.onChange}
        name="email"
        type="text"
        addonBefore="Email"
      />
      <TSInput
        error={props.error.password}
        value={props.values.password || ''}
        onChange={props.onChange}
        name="password"
        type="password"
        addonBefore="Password"
      />
      <Checkbox
        checked={props.values.isSuperuser}
        onChange={(e) =>
          props.onChange({
            target: { name: 'isSuperuser', value: e.target.checked },
          })
        }
        // style={{ marginTop: '10px' }}
      >
        Is Admin?
      </Checkbox>
      {props.values.isSuperuser && (
        <GroupDropdown
          onSelect={(e, f) => {
            props.onChange({ target: { name: 'groupId', value: String(e) } });
          }}
          allowClear
          value={props.values.groupId || ''}
        />
      )}
      <TSAutoComplete
        error={props.error.branchId}
        inputProps={{ name: 'branchId', addonBefore: 'Location' }}
        dataSource={props.branches.filter(
          (branch) =>
            branch.node.category &&
            ['PWCS', 'SHGS', 'CFC'].indexOf(branch.node.category) === -1,
        )}
        key="id"
        name="branchId"
        onValueSelect={props.onChange}
        displayKey="name"
        textValue={getValue(props.branches, props.values.branchId)}
        allowClear
      />
    </div>
  );
};

interface Props {
  values: EmployeeNodeEdge;
  error: EmployeeError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  branches: BranchEdges[];
}

import * as React from 'react';
import { DatePicker, Divider, Spin, Row, Col, Button, Skeleton } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import Api, { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { itemWiseSalesCentralQuery } from '../master/Product/constants';
import {
  ProductNodeConnection,
  ProductNodeEdge,
  ItemWiseSalesReportCentral as ItemWiseCentral,
} from '../../schema';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../common/TSInput';
import {
  ITEM_WISE_SALES_REPORT_CSV_URL,
  ITEM_WISE_SALES_REPORT_TALLY_CSV,
} from '../../config';
import { downloadToFile } from '../helper';

export class ItemWiseSalesReportCentral extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      itemList: [],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      loading: true,
      currentPage: 1,
      after: '',
      barcode: '',
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }
    fetch(ITEM_WISE_SALES_REPORT_CSV_URL + '?' + queryParams)
      .then((v) => v.text())
      .then((value) =>
        downloadToFile(
          value,
          `item-sales-report-${new Date().toISOString()}`,
          'ITEM WISE SALES REPORT',
          this.props.company.name,
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  downloadCsvTally = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (key === 'first' || key === 'after') continue;

        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_SALES_REPORT_TALLY_CSV + '?' + queryParams)
      .then((v) => v.text())
      .then((value) =>
        downloadToFile(
          value,
          `item-sales-report-tally-${new Date().toISOString()}`,
          'ITEM WISE SALES REPORT TALLY',
          this.props.company.name,
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  columns = () => {
    const columns: any[] = [];
    columns.push(
      {
        title: 'Old Barcode Id',
        key: 'oldBarcodeId',
        dataIndex: 'node.oldBarcodeId',
        sortingValue: (value) => value,
      },
      {
        title: 'New Barcode Id',
        key: 'newBarcodeId',
        dataIndex: 'node.newBarcodeId',
        sortingValue: (value) => value,
      },
      {
        title: 'Item Name',
        key: 'itemName',
        dataIndex: 'node.name',
        sortingValue: (value) => value,
      },
    );

    if (
      this.props.company.name.toLowerCase() === 'jharcraft' ||
      this.props.company?.name.toLowerCase().includes('khadi')
    ) {
      columns.push(
        {
          title: 'HSN Code',
          key: 'hsnCode',
          dataIndex: 'node.hsn.hsnCode',
          sortingValue: (value) => value,
        },
        {
          title: 'GST Rate',
          key: 'gstRate',
          dataIndex: 'node.hsn.gst',
          sortingValue: (value) => value,
        },
      );
    }

    this.props.company.branches?.edges.map((branch) => {
      if (branch.node.category === 'EMPORIUM') {
        columns.push({
          title: branch.node.name,
          key: branch.node.name,
          render: (value: ProductNodeEdge) => {
            const reportData = (
              value.node?.itemWiseSalesCentral as any as ItemWiseCentral[]
            ).find(
              (salesReport) => salesReport.branchName === branch.node.name,
            );
            return reportData ? reportData.quantity : 0;
          },
        });
      }
    });
    columns.push(
      {
        title: 'Total Quantity',
        key: 'totalQuantity',
        render: (value: ProductNodeEdge) => {
          return value.node?.itemWiseSalesCentral[0]
            ? value.node?.itemWiseSalesCentral[0].totalQuantity
            : 0;
        },
      },
      {
        title: 'MRP',
        key: 'sellingRate',
        dataIndex: 'node.sellingRate',
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        render: (value: ProductNodeEdge) => {
          return value.node?.itemWiseSalesCentral[0]
            ? value.node?.itemWiseSalesCentral[0].totalAmount
            : 0;
        },
      },
    );
    return columns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { startDate, endDate, currentPage, after } = this.state;
    const companyId = this.props.company.id;
    const variables = { startDate, endDate, companyId, first: 20, after };
    const { barcode } = this.state;
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(this.state.startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(this.state.endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
        </Row>
        <Divider />
        <GraphqlQuery
          queryString={itemWiseSalesCentralQuery}
          variables={barcode ? { ...variables, barcode } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={response.allProducts.totalCount || 0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
        <>
          <Button
            key="download"
            style={{ float: 'right', margin: '5px' }}
            icon="download"
            onClick={() => this.downloadCsv(variables)}
          />
          <span style={{ float: 'right', margin: '10px 5px' }}>
            Item Wise Sales Report
          </span>
          {this.props.company?.name.toLowerCase().includes('sugar') && (
            <>
              <Button
                key="download"
                style={{ float: 'right', margin: '5px' }}
                icon="download"
                onClick={() => this.downloadCsvTally(variables)}
              />
              <span style={{ float: 'right', margin: '10px 5px' }}>
                GST Sales Report (Tally)
              </span>
            </>
          )}
        </>
        ,
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  itemList: any[];
  loading: boolean;
  currentPage: number;
  after: string;
  barcode: string;
}

import { RemunerationInput } from '../../../schema';

export const remunerationMutation = `
mutation addRemuneration(
  $input: CreateRemunerationInput!
) {
  createRemuneration(input: $input){
    newRemuneration {
      id
      name
    }
  }
}`;

export const remunerationObject: RemunerationInput = {
  name: '',
  companyId: null,
};

export const remunerationQuery = `
query RemunerationList($companyId: ID!, $after: String) {
  allRemunerations(companyId: $companyId, first: 20, after: $after) {
    pageInfo {
      startCursor
        endCursor
        hasNextPage
        hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
      }
    }
  }
}`;

import Api from 'requestapijs';
import { CompanyService } from './CompanyService';
import {
  unitQuery,
  UnitQueryResponse,
  UnitQueryVariable,
  UnitNodeEdge,
  UnitEdges,
  UnitVariables,
  unitMutation,
} from '../components/master/Unit/constants';
import { API_URL, HEADER } from '../config';
import { BehaviorSubject } from 'rxjs';

export class UnitService {
  static instance;

  constructor() {
    if (UnitService.instance) {
      return UnitService.instance;
    } else {
      UnitService.instance = this;
    }
    this.onInit();
  }

  companyService = new CompanyService();
  private readonly token = localStorage.getItem('token');
  public readonly unit$ = new BehaviorSubject<UnitEdges[]>([]);

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  getAll(companyId: string) {
    if (!this.companyService?.company$.value?.id) {
      return [];
    }
    Api.graphql<UnitQueryVariable, UnitQueryResponse>(
      API_URL,
      { companyId: this.companyService?.company$.value?.id },
      unitQuery,
      HEADER,
      (response) => this.unit$.next(response.data.allUnits.edges),
      (error) => error,
    );
  }

  addUnit(
    data: UnitNodeEdge,
    onSuccess: (response) => void,
    onError: (error) => void,
  ) {
    Api.graphql<UnitVariables, {}>(
      API_URL,
      {
        input: {
          unit: { ...data, companyId: this.companyService?.company$.value?.id },
        },
      },
      unitMutation,
      HEADER,
      (response) => {
        this.companyService?.company$.value?.id &&
          this.getAll(this.companyService?.company$.value?.id);
        onSuccess(response);
      },
      (error) => error,
    );
  }
}

import * as React from 'react';
import { OpeningStockService } from '../../service/OpeningStockService';
import { notification, Button, Popconfirm } from 'antd';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { openingStockObject, OpeningStockNode } from './constants';
import { OpeningStockForm } from './OpeningStockForm';
import { ProductNode } from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';

export class AddOpeningStock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openingStock: JSON.parse(JSON.stringify(openingStockObject)),
      buttonDisabled: false,
      productObjList: [],
    };
  }

  openingStockService = new OpeningStockService();

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const openingStock = { ...this.state.openingStock };
    openingStock[e.target.name] = e.target.value;
    this.setState({ openingStock });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.openingStock.productDetails[index][e.target.name] =
      e.target.value;
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.openingStock.productDetails.push({
        date: null,
        productId: '',
        quantity: 0,
        rate: 0,
        amount: 0,
        movementType: 'OPENING_STOCK',
        companyId: null,
      });
    } else {
      nextState.openingStock.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.openingStock.productDetails[index].quantity =
      (productObj.openingStock as unknown as number) || 0;
    nextState.openingStock.productDetails[index].rate = productObj.sellingRate;
    nextState.openingStock.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  saveOpeningStock = () => {
    this.setState({ buttonDisabled: true });
    const openingStock = { ...this.state.openingStock };
    openingStock.companyId = this.props.company.id;
    openingStock.branchId =
      this.props.business.type === 'branch' ? this.props.business.id : '';
    this.openingStockService?.addOpeningStock(
      openingStock,
      (response) => {
        this.setState({
          openingStock: JSON.parse(JSON.stringify(openingStockObject)),
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Updated',
          description: `Opening Stock was successfully Updated`,
        });
      },
      (error) =>
        notification.error({
          message: 'Opening Stock Update Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      openingStock: JSON.parse(JSON.stringify(openingStockObject)),
    });
  };

  render() {
    return (
      <div>
        <OpeningStockForm
          value={this.state.openingStock}
          onChange={this.onChange}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          company={this.props.company}
          currentBusiness={this.props.business}
          productObjChangeHandler={this.productObjChangehandler}
          productObjList={this.state.productObjList}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveOpeningStock}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business: Bussiness;
}

interface State {
  openingStock: OpeningStockNode<ProductDetailNodeEdge[]>;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
}

import * as React from 'react';
import { Icon, Menu } from 'antd';
import { RouterProps } from 'react-router';
import { Feature, SideBarMenu } from '../../constants';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';

export const MasterLink = (props: Props) => {
  const master: SideBarMenu = {
    [Feature.BRANCH]: (
      <Menu.Item
        key="1"
        onClick={() =>
          props.history.push(
            `/dashboard/master/branch/${
              props.company?.name.toLowerCase().includes('seva sadan')
                ? 'department'
                : 'branch'
            }`,
          )
        }
      >
        {(props.company?.name as string).includes('Seva Sadan')
          ? 'Department'
          : 'Branch'}
      </Menu.Item>
    ),
    [Feature.UNIT]: (
      <Menu.Item
        key="2"
        onClick={() => props.history.push('/dashboard/master/unit')}
      >
        Units
      </Menu.Item>
    ),
    [Feature.PRODUCT_CATEGORY]: (
      <Menu.Item
        key="3"
        onClick={() =>
          props.history.push('/dashboard/master/product_categories')
        }
      >
        Product Categories
      </Menu.Item>
    ),
    [Feature.PRODUCT_SUB_CATEGORY]: (
      <Menu.Item
        key="4"
        onClick={() =>
          props.history.push('/dashboard/master/product_sub_categories')
        }
      >
        Product Sub Categories
      </Menu.Item>
    ),
    [Feature.HSN]: (
      <Menu.Item
        key="5"
        onClick={() => props.history.push('/dashboard/master/hsn')}
      >
        HSN
      </Menu.Item>
    ),
    [Feature.PRODUCT]: (
      <Menu.Item
        key="6"
        onClick={() => props.history.push('/dashboard/master/product')}
      >
        {props.bussiness?.type === 'branch' &&
          !props.company?.name.toLowerCase().includes('jharcraft') && (
            <Icon type="mail" />
          )}
        <span>Products</span>
      </Menu.Item>
    ),
    [Feature.VENDOR]: (
      <Menu.Item
        key="7"
        onClick={() => props.history.push('/dashboard/master/vendor')}
      >
        Vendors
      </Menu.Item>
    ),
    [Feature.CUSTOMER]: (
      <Menu.Item
        key="8"
        onClick={() => props.history.push('/dashboard/master/customer')}
      >
        Customers
      </Menu.Item>
    ),
    [Feature.REMUNERATION]: (
      <Menu.Item
        key="9"
        onClick={() => props.history.push('/dashboard/master/remuneration')}
      >
        Remuneration
      </Menu.Item>
    ),
    [Feature.USER]: (
      <Menu.Item
        key="10"
        onClick={() => props.history.push('/dashboard/master/user')}
      >
        Users
      </Menu.Item>
    ),
    [Feature.STATE]: (
      <Menu.Item
        key="11"
        onClick={() => props.history.push('/dashboard/master/state')}
      >
        State
      </Menu.Item>
    ),
    [Feature.DISTRICT]: (
      <Menu.Item
        key="12"
        onClick={() => props.history.push('/dashboard/master/district')}
      >
        District
      </Menu.Item>
    ),
    [Feature.AREA]: (
      <Menu.Item
        key="13"
        onClick={() => props.history.push('/dashboard/master/area')}
      >
        Area
      </Menu.Item>
    ),
    [Feature.SUB_AREA]: (
      <Menu.Item
        key="14"
        onClick={() => props.history.push('/dashboard/master/subarea')}
      >
        Subarea
      </Menu.Item>
    ),
    [Feature.PRODUCT_CONVERSION]: (
      <Menu.Item
        key="15"
        onClick={() =>
          props.history.push('/dashboard/master/product_conversion')
        }
      >
        Product Conversion
      </Menu.Item>
    ),
    [Feature.CONTRACTOR]: (
      <Menu.Item
        key="16"
        onClick={() => props.history.push('/dashboard/master/contractor')}
      >
        Contractor
      </Menu.Item>
    ),
    [Feature.DISCOUNT_CATEGORY]: (
      <Menu.Item
        key="m17"
        onClick={() =>
          props.history.push('/dashboard/master/discount_categories')
        }
      >
        Discount Categories
      </Menu.Item>
    ),
    [Feature.DISCOUNT]: (
      <Menu.Item
        key="m18"
        onClick={() => props.history.push('/dashboard/master/discount')}
      >
        Discount
      </Menu.Item>
    ),
    [Feature.SALES_PERSON]: (
      <Menu.Item
        key="m19"
        onClick={() => props.history.push('/dashboard/master/sales_person')}
      >
        Sales Person
      </Menu.Item>
    ),
  };

  return master;
};

interface Props extends RouterProps {
  company: CompanyNodeEdge | null;
  bussiness: Bussiness | null;
}

import * as React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export const TSInput: React.FunctionComponent<Props> = (props) => {
  return (
    <div style={{ ...props.style, margin: '5px 0' }}>
      <Input
        style={{ border: props.error ? '1px solid red' : '' }}
        {...props}
      />
      <span style={{ color: 'red' }}>{props.error}</span>
    </div>
  );
};

interface Props extends InputProps {
  error: string;
}

import * as React from 'react';
import { DistrictForm } from './DistrictForm';
import { Button, notification } from 'antd';
import { DistrictService } from '../../../service/DistrictService';
import { DistrictInput, DistrictNode } from '../../../schema';
import { DISTRICT_DATA } from './constant';

export class AddDistrict extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      district: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
    };
  }

  handlePropsValueUpdate = (props: Props) => {
    const district = props.district
      ? {
          ...props.district,
          stateId: (props.district as any as DistrictNode).state?.id || '',
        }
      : DISTRICT_DATA;
    return district;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ district: this.handlePropsValueUpdate(nextProps) });
  }

  districtService = new DistrictService();

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const district = { ...this.state.district };
    district[e.target.name] = e.target.value;
    this.setState({ district });
  };

  saveDistrict = () => {
    this.setState({ buttonDisabled: true });
    this.districtService?.AddDistrict(
      this.state.district,
      (response) => {
        notification.success({
          message: 'District ' + this.state.district.id ? 'Updated' : 'Added',
          description: `District ${this.state.district.name} was successfuly ${
            this.state.district.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'District' + this.state.district.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      district: JSON.parse(JSON.stringify(DISTRICT_DATA)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <DistrictForm district={this.state.district} onChange={this.onChange} />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%', marginTop: '50px' }}
        />
        <Button
          type="primary"
          onClick={this.saveDistrict}
          children="Submit"
          style={{ width: '50%', marginTop: '50px' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  handleTabChange?: (key) => void;
  district?: DistrictNode;
}

interface State {
  district: DistrictInput;
  buttonDisabled: boolean;
}

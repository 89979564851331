import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import { sumBy } from 'lodash';
import { inWords } from '../helper';
import moment from 'moment';
import {
  JHARKHAND_GOVT_LOGO_URL,
  JHARKHAND_KHADI_LOGO_URL,
  KHADI_INDIA_LOGO_URL,
} from '../../config';
import { PurchaseNode, ProductDetailsNodeConnection } from '../../schema';

export const PurchaseReturnReceiptKhadi: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailsEdges = (
    props.purchaseReturn
      .productDetails as unknown as ProductDetailsNodeConnection
  ).edges;
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node?.quantity || 0,
  );
  const totalAmount = sumBy(
    productDetailsEdges,
    (value) => value.node?.amount || 0,
  );
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.3, height: 50, margin: 'auto' }]}
              source={KHADI_INDIA_LOGO_URL}
            />
          </View>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.2, height: 55 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                {
                  fontSize: '15.3pt',
                  marginLeft: '10px',
                  marginRight: '10px',
                },
              ]}
            >
              Jharkhand State Khadi &amp; Village Industries Board 2nd Floor,
              Udyog Bhavan, Ratu Road, Ranchi — 834001.
            </Text>
            <Image
              style={[{ flex: 0.3, height: 50 }]}
              source={JHARKHAND_KHADI_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Purchase Return
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.company.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.company.state} (${props.company.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {props.purchaseReturn.billNo}
              </Text>
            </Text>
            <Text
              style={[
                style.inlineHeadDouble,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Date: {moment(props.purchaseReturn.date).format('DD-MM-YYYY')}
            </Text>
          </View>
          <View style={style.divider} />
        </View>
        <View style={[style.section, style.box, { padding: 5 }]}>
          <View>
            <Text style={style.mediumBlackText}>
              Vendor Name: {props.purchaseReturn.vendor?.name}
            </Text>
            <Text style={style.mediumBlackText}>
              Vendor Address: {props.purchaseReturn.vendor?.address}
            </Text>
            <Text style={style.mediumBlackText}>
              GSTIN: {props.purchaseReturn.vendor?.gstin}
            </Text>
            <Text style={style.mediumBlackText}>
              Contact Person: {props.purchaseReturn.vendor?.contactPersonName}
            </Text>
            <Text style={style.mediumBlackText}>
              Contact No: {props.purchaseReturn.vendor?.contactNo}
            </Text>
          </View>
        </View>
        <View style={[style.divider, { marginTop: 2 }]} />
        <View>
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textLeft,
                { flex: 0.2 },
              ]}
            >
              Sl.
            </Text>
            <Text
              style={[
                style.highFlexInlineHead,
                style.mediumBlackText,
                { flex: 3.1 },
                style.textLeft,
              ]}
            >
              Product
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textLeft,
              ]}
            >
              Barcode
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.5 },
              ]}
            >
              Qty
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.5 },
              ]}
            >
              Pur. Rate
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Amount
            </Text>
          </View>
          <View style={style.divider} />
          {productDetailsEdges.map((pd, i) => (
            <View
              style={[style.section, style.inline]}
              key={'pd' + i}
              wrap={false}
            >
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  style.textLeft,
                  { flex: 0.2 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.mediumBlackText,
                  style.textLeft,
                  { flex: 3.1 },
                ]}
              >
                {pd.node?.product?.name}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textLeft,
                ]}
              >
                {pd.node?.product?.oldBarcodeId}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.5 },
                ]}
              >
                {pd.node?.quantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.5 },
                ]}
              >
                {pd.node?.purchaseRate}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {pd.node?.amount.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.highFlexInlineHead,
                style.darkBlackText,
                style.textLeft,
                { flex: 3.1 },
              ]}
            >
              Grand Total:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.2 },
              ]}
            >
              {}
            </Text>
            <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.5 },
              ]}
            >
              {totalQty}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.5 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {totalAmount.toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={style.section}>
            <Text style={style.darkBlackText}>Rs. {inWords(totalAmount)}.</Text>
          </View>
          <View style={style.divider} />
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#363636', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.4pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  purchaseReturn: PurchaseNode;
  company: CompanyNodeEdge;
}

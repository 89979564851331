import { Icon, Skeleton, Tabs } from 'antd';
import React from 'react';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { PurchaseOrderStatusReport } from './PurchaseOrderStatusReport';
import { PurchaseOrderSummarizeStatusReport } from './PurchaseOrderSummarizeStatusReport';

export class PurchaseOrderReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeKey: '1',
      company: null,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.componentDidMount();
    }
    this.setState({ activeKey });
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }

    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Purchase Order Status Report
            </span>
          }
          key="1"
          closable={false}
        >
          <PurchaseOrderStatusReport company={this.state.company} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Purchase Order Status Report (Summarize)
            </span>
          }
          key="2"
          closable={false}
        >
          <PurchaseOrderSummarizeStatusReport company={this.state.company} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  company: CompanyNodeEdge | null;
}

interface Props {}

import {
  QuotationInput,
  ProductDetailsInput,
  ProductDetailsMovementType,
} from '../../schema';
import moment from 'moment';

export const quotationProductDetailsObject: ProductDetailsInput = {
  productId: null,
  date: moment().format('YYYY-MM-DD'),
  movementType: ProductDetailsMovementType.QUOTATION,
  quantity: null,
  rate: null,
  purchaseRate: null,
  amount: null,
  companyId: null,
};

export const quotationObject: QuotationInput = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  productDetails: [quotationProductDetailsObject],
  companyId: null,
};

export interface QuotationProductDetailsError {
  productId: string;
  quantity: string;
  rate: string;
  amount: string;
}

export const quotationProductDetailsError: QuotationProductDetailsError = {
  productId: '',
  quantity: '',
  rate: '',
  amount: '',
};

export interface QuotationError {
  date: string;
  customerId: string;
  productDetails: QuotationProductDetailsError[];
}

export const quotationErrorObject: QuotationError = {
  customerId: '',
  date: '',
  productDetails: [quotationProductDetailsError],
};

export const quotationQuery = `query Quotation(
  $date: Date,
  $after: String,
  $first: Int,
  $date_Lte:Date,
  $date_Gte:Date,
  $company: ID,
  $name: String
  ) 
{
allQuotation(
  date: $date,
  after: $after,
  first: $first,
  date_Lte: $date_Lte,
  date_Gte: $date_Gte,
  company: $company,
  customer_Name_Icontains: $name
)
{
  pageInfo {
    hasNextPage
    endCursor
  }  
  edges {
    node {
      quotationNumber
      date
      customer{
        id
        name
      }
      productDetails
      {
        edges{
          node{
            id
            amount
            quantity
            product
            {
              id
              sellingRate
            }
          
          }
        }
      }
    }
  }
}
}
`;

export const quotationMutation = `
mutation QuotationMutation($input: CreateQuotationInput!) {
  createQuotation(input: $input) {
    newQuotation {
      id
    }
  }
}
`;

import * as React from 'react';
import { Row, Col, DatePicker, Divider, Button } from 'antd';
import moment from 'moment';
import { TSInput } from '../common/TSInput';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { OpeningStockNode } from './constants';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ProductNode } from '../../schema';

export const OpeningStockForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={8}>
        <DatePicker
          value={
            props.value.date
              ? moment(props.value.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={16}>
        <TSInput
          error={''}
          key="id"
          name="branchId"
          value={props.currentBusiness.name}
          addonBefore="Branch"
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={12}>Product</Col>
        <Col span={4}>MRP</Col>
        <Col span={4}>Quantity</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.value.productDetails.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={12}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.productObjChangeHandler((f as any).props.label, index);
                  props.productChangeHandler(
                    { target: { name: 'productId', value: String(e) } },
                    index,
                  );
                }}
                allowClear
                extrafields={[
                  'purchaseRate',
                  'sellingRate',
                  `openingStock(branchId: "${
                    props.currentBusiness.type === 'branch'
                      ? props.currentBusiness.id
                      : ''
                  }", ` + `companyId: "${props.company.id}")`,
                ]}
                company={props.company}
                value={product.productId || ''}
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.rate || 0}
                name="rate"
                placeholder="Rate"
                required
                tabIndex={-1}
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={product.quantity || 0}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.productChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updateProductsRow('-', index);
                }}
                disabled={props.value.productDetails.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  props.updateProductsRow('+', index);
                }}
                disabled={product.productId && product.quantity ? false : true}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Row>
  );
};

interface Props {
  value: OpeningStockNode<ProductDetailNodeEdge[]>;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge;
  currentBusiness: Bussiness;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangeHandler: (value: ProductNode, index: number) => void;
  productObjList: ProductNode[];
}

import * as React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import {
  directProductionObject,
  DirectProductionError,
  directProductionErrorObj,
  directProductionDetailsError,
} from './constants';
import { DirectProductionForm } from './DirectProductionForm';
import { CompanyNodeEdge } from '../../master/Company/constants';
import {
  DirectProductionNode,
  DirectProductionInput,
  ProductNode,
  ProductDetailsMovementType,
  ProductDetailsInput,
} from '../../../schema';
import { DirectProductionService } from '../../../service/DirectProductionService';
import { ProductDetailsNodeConnection } from '../../Sales/constants';

export class AddDirectProduction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      directProduction: this.handleDirectProductionPropsValue(props),
      buttonDisabled: false,
      error: this.handleErrorPropsUpdate(props),
    };
  }

  directProductionService = new DirectProductionService();

  componentWillReceiveProps(props: Props) {
    this.setState({
      directProduction: this.handleDirectProductionPropsValue(props),
      error: this.handleErrorPropsUpdate(props),
    });
  }

  handleDirectProductionPropsValue = (props: Props) => {
    const directProduction: DirectProductionInput = props.directProduction
      ? {
          ...props.directProduction,
          productDetails: (
            props.directProduction
              .productDetails as any as ProductDetailsNodeConnection
          ).edges.map((productDetail) => ({
            ...productDetail.node,
            productId: productDetail.node.product?.id,
          })),
        }
      : JSON.parse(JSON.stringify(directProductionObject));
    return directProduction;
  };

  handleErrorPropsUpdate(nextProps: Props) {
    const error: DirectProductionError = JSON.parse(
      JSON.stringify(directProductionErrorObj),
    );
    const productDetails: ProductDetailsNodeConnection =
      nextProps.directProduction &&
      (nextProps.directProduction.productDetails as any);
    productDetails &&
      productDetails.edges.forEach(() => {
        if (productDetails.edges.length !== error.productDetails.length) {
          error.productDetails.push({
            productId: '',
            quantity: '',
          });
        }
      });
    return error;
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const directProduction = { ...this.state.directProduction };
    directProduction[e.target.name] = e.target.value;
    this.setState({ directProduction });
  };

  checkError = () => {
    const error: DirectProductionError = JSON.parse(
      JSON.stringify(this.state.error),
    );
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (!this.state.directProduction[errKey] && errKey !== 'productDetails') {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  productDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.directProduction.productDetails[index][e.target.name] =
      e.target.value;
    nextState.directProduction.productDetails[index].amount = Number(
      (
        Number(nextState.directProduction.productDetails[index].quantity) *
        Number(nextState.directProduction.productDetails[index].purchaseRate)
      ).toFixed(2),
    );
    this.setState(nextState);
  };

  productObjChangeHandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.directProduction.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    nextState.directProduction.productDetails[index].rate =
      productObj.sellingRate;
    this.setState(nextState);
  };

  checkProductError = () => {
    const productDetails: ProductDetailsInput[] = {
      ...this.state.directProduction.productDetails,
    };
    const error: State['error'] = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  updateRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.directProduction.productDetails.push({
        date: '',
        productId: '',
        rate: 0,
        purchaseRate: 0,
        quantity: null,
        amount: 0,
        movementType: ProductDetailsMovementType.PRODUCTION,
        companyId: '',
        remark: null,
      });
      nextState.error.productDetails.push(
        JSON.parse(JSON.stringify(directProductionDetailsError)),
      );
    } else {
      nextState.directProduction.productDetails.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  saveDirectProduction = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.directProductionService?.addDirectProduction(
      this.state.directProduction,
      (response) => {
        if (!this.props.directProduction) {
          this.setState({
            directProduction: JSON.parse(
              JSON.stringify(directProductionObject),
            ),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Direct Production ' + this.state.directProduction.id
              ? 'Updated'
              : 'Added',
          description:
            `Direct Production was successfully ` +
            `${this.state.directProduction.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        this.setState({ buttonDisabled: false });
        notification.error({
          message:
            'Direct Production' + this.state.directProduction.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  clear = () => {
    this.setState({
      directProduction: JSON.parse(JSON.stringify(directProductionObject)),
      error: JSON.parse(JSON.stringify(directProductionErrorObj)),
    });
  };

  render() {
    return (
      <div>
        <DirectProductionForm
          values={this.state.directProduction}
          error={this.state.error}
          company={this.props.company}
          onChange={this.onChange}
          productDetailChangeHandler={this.productDetailChangeHandler}
          updateRow={this.updateRow}
          productObjChangeHandler={this.productObjChangeHandler}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveDirectProduction}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  directProduction?: DirectProductionNode;
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  directProduction: DirectProductionInput;
  error: DirectProductionError;
  buttonDisabled: boolean;
}

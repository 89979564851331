import { MarginInput } from '../../schema';

export interface ProductDetails {
  productName: string;
  rate: number | null;
  quantity: number | null;
  amount: number | null;
  total: number;
  margin: number | null;
  mrp: number | null;
  productId: string | null;
  challanPdIndex?: number;
}

export const productDetailsObject: ProductDetails = {
  productName: '',
  rate: null,
  quantity: null,
  amount: null,
  total: 0,
  margin: 0,
  mrp: 0,
  productId: null,
};

export const marginObject: MarginInput = {
  itemsFrom: 'PURCHASE',
  challanId: null,
  productionTransferId: null,
  freight: 0,
  otherExpenses: 0,
  productDetails: JSON.stringify([productDetailsObject]),
  companyId: null,
};

export const marginQuery = `
query MarginList {
  allMargins {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        itemsFrom
        challan {
          id
          challanNo
          vendorChallanNo
          productDetails
        }
        freight
        otherExpenses
        productDetails
      }
    }
  }
}
`;

export const marginListDataQuery = `
query MarginListData($companyId: ID!, $after: Int!, $startDate: Date!, $endDate: Date!) {
  marginListData(companyId: $companyId, after: $after, count: 20, startDate: $startDate, endDate: $endDate) {
    id
    itemFrom
    product
    rate
    quantity
    amount
    totalPerQuantity
    margin
    mrp
    oldBarcode
    newBarcode
  }
}
`;

export const marginMutation = `
mutation addMargin($input: CreateMarginInput!) {
  createMargin(input: $input){
    newMargin {
      id
      productDetails
      challan {
        id
        challanNo
        productDetailsWithMargin {
          productDetails
        }
      }
      productionTransfer {
        id
        transferInvoiceNo
        productDetailsWithMargin {
          productDetails
        }
      }
    }
  }
}
`;

export const productSearchQuery = `
  query ProductList($companyId: ID!, $after: String, $barcode: String, $sellingRate_Lte: Float, $sellingRate_Gte: Float) {
    allProducts(
      companyId: $companyId,
      first: 20,
      after: $after,
      barcodeBothContains: $barcode,
      sellingRate_Lte: $sellingRate_Lte,
      sellingRate_Gte: $sellingRate_Gte
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          category {
            id
            name
          }
          oldBarcodeId
          newBarcodeId
          name
          sellingRate
          hsn {
            id
            hsnCode
            gst
          }
        }
      }
    }
  }
`;

import * as React from 'react';
import moment from 'moment';
import { CompanyNodeEdge } from '../master/Company/constants';
import { Table, Spin, Button, Col, Divider, Row } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { ProductDetailsNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { downloadToFile } from '../helper';
import { ITEM_WISE_PURCHASE_RETURN_REPORT_CSV } from '../../config';
import { TSInput } from '../common/TSInput';
import { purchaseReturnReportQuery } from './constants';

export class PurchaseReturnReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      after: '',
      currentPage: 1,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Purchase Return No',
      key: 'purchaseReturnNo',
      dataIndex: 'node.purchaseSet.edges[0].node.billNo',
    },
    {
      title: 'Purchase Return Date',
      key: 'purchaseReturnDate',
      dataIndex: 'node.purchaseSet.edges[0].node.date',
    },
    {
      title: 'Product Name',
      key: 'product',
      dataIndex: 'node.product.name',
    },
    {
      title: 'Barcode 1',
      key: 'oldBarcode',
      dataIndex: 'node.product.oldBarcodeId',
    },
    {
      title: 'Barcode 2',
      key: 'newBarcode',
      dataIndex: 'node.product.newBarcodeId',
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'node.product.category.name',
    },
    {
      title: 'Division',
      key: 'division',
      dataIndex: 'node.product.division',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'node.quantity',
    },
    {
      title: 'Purchase Rate',
      key: 'purchaseRate',
      dataIndex: 'node.product.purchaseRate',
    },
    {
      title: 'Purchase Return Amount',
      key: 'amount',
      dataIndex: 'node.amount',
    },
    {
      title: 'Vendor Name',
      key: 'vendorName',
      dataIndex: 'node.purchaseSet.edges[0].node.vendor.name',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_PURCHASE_RETURN_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `item-wise-purchase-return-report-${new Date().toISOString()}`,
          'ITEM WISE PURCHASE RETURN REPORT',
          this.props.company?.name || '',
          'Head Office',
          moment().format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const companyId = this.props.company?.id;
    const { after, currentPage } = this.state;
    const variables = { companyId, after };
    return (
      <div>
        <Row>
          <Col span={5}>
            <TSInput
              error=""
              addonBefore="Date"
              value={moment().format('YYYY-MM-DD')}
            />
          </Col>
          <Col span={7}>
            <TSInput
              error=""
              addonBefore="Location"
              value={this.props.company?.name}
            />
          </Col>
        </Row>
        <Divider />
        <GraphqlQuery
          queryString={purchaseReturnReportQuery}
          variables={variables}
          render={(
            response: { allProductDetails: ProductDetailsNodeConnection },
            error,
            loading,
          ) => {
            if (loading) return <Spin />;
            if (error) return <h3>Something Went Wrong!!</h3>;
            if (response) {
              return [
                <Table
                  dataSource={response.allProductDetails.edges}
                  columns={this.columns()}
                  key="Table"
                  pagination={false}
                  rowKey="node.id"
                  scroll={{ x: true }}
                />,
                <Pagination
                  key="Pagination"
                  totalCount={0}
                  cursorBasedPagination
                  currentPage={currentPage}
                  batchSize={20}
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProductDetails.pageInfo.endCursor,
                      response.allProductDetails.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv(variables)}
                  key="button"
                />,
              ];
            }
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge | null;
}

interface State {
  after: string;
  currentPage: number;
}

import * as React from 'react';
import { DatePicker, Divider, Button, Spin } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { dailySalesCentralQuery } from './constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { downloadToFile } from '../helper';
import { GraphqlQuery } from 'requestapijs';

export class DailySalesReportCentral extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      month: moment().format('YYYY-MM'),
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => {
    const columns: any[] = [];
    const branch_details = this.props.company.branches?.edges.map(
      (branch) => branch.node,
    );

    const branches: branch[] = [];
    branch_details?.forEach((value) => {
      branches.push({
        name: value.name + ' Gross',
        category: value.category,
      });
      branches.push({
        name: value.name + ' Net',
        category: value.category,
      });
    });

    branches?.push({
      name: 'total_gross_for_date',
      category: 'EMPORIUM',
    });
    branches?.push({
      name: 'total_net_for_date',
      category: 'EMPORIUM',
    });
    columns.push({
      title: 'Date',
      key: 'date',
      render: (text) =>
        Object.keys(text)[0] === 'total_for_branch'
          ? 'Total'
          : Object.keys(text)[0] === 'total_value_addition'
          ? 'Value Addition'
          : moment(Object.keys(text)[0]).format('DD-MM-YYYY'),
    });
    branches?.map((branch) => {
      if (branch.category === 'EMPORIUM') {
        columns.push({
          title:
            branch.name === 'total_gross_for_date'
              ? 'Total Gross Amount'
              : branch.name === 'total_net_for_date'
              ? 'Total Net Amount'
              : branch.name,
          key: branch.name,
          render: (text) => {
            const currBranchData = text[Object.keys(text)[0]].find(
              (rec) => rec.name === branch.name,
            );
            return Number(currBranchData ? currBranchData.amount : 0).toFixed(
              2,
            );
          },
        });
      }
    });
    return columns;
  };

  downloadCsv = (dailySalesCentralList: any[]) => {
    const data = dailySalesCentralList
      .map((dl) =>
        this.columns()
          .map((col) => {
            if (col.dataIndex) {
              return dl[col.dataIndex];
            }
            return col.render(dl);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `daily-sales-report-${new Date().toISOString()}`,
      'DAILY SALES REPORT',
      this.props.company.name,
      'Head Office',
      `01-${moment(this.state.month).format('MM-YYYY')}`,
      `${moment(this.state.month, 'YYYY-MM')
        .endOf('month')
        .format('DD')}-${moment(this.state.month).format('MM-YYYY')}`,
      '',
    );
  };

  render() {
    const companyId = this.props.company.id;
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    return (
      <div>
        <DatePicker.MonthPicker
          format="MMM-YYYY"
          placeholder="Month"
          value={
            this.state.month
              ? moment(this.state.month)
              : moment(moment().format('YYYY-MM'))
          }
          onChange={(month) =>
            this.onChange({
              target: {
                name: 'month',
                value: month && month.format('YYYY-MM'),
              },
            })
          }
        />
        <Divider />
        {this.state.month && (
          <GraphqlQuery
            queryString={dailySalesCentralQuery}
            variables={{ companyId, startDate, endDate }}
            render={(
              response: { dailySalesCentral: string },
              error,
              loading,
            ) => {
              if (loading) {
                return <Spin />;
              }
              if (response && companyId) {
                return [
                  <Table
                    dataSource={JSON.parse(response.dailySalesCentral) as any[]}
                    columns={this.columns()}
                    key="table"
                    rowKey="id"
                    pagination={{ pageSize: 11 }}
                    scroll={{ x: true }}
                  />,
                  <Button
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() =>
                      this.downloadCsv(JSON.parse(response.dailySalesCentral))
                    }
                    key="button"
                  />,
                ];
              }
              return <Spin />;
            }}
          />
        )}
      </div>
    );
  }
}

type branch = {
  name: string;
  category: string | undefined;
};

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  month: string;
}

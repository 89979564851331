import * as React from 'react';
import { RequestInput } from '../../schema';
import { RequestError } from './constants';
import { Row, Col, DatePicker, Radio } from 'antd';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { User } from '../user/constants';
import { Label } from '../common/TSLabel';
import { Bussiness } from '../master/Company/constants';

export const RequestForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={5}>
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: {
                        name: 'date',
                        value: e && moment(e).format('YYYY-MM-DD'),
                      },
                    })
            }
            placeholder="Select Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
          <span style={{ color: 'red' }}>{props.error.date}</span>
        </Col>
        <Col span={24}>
          <TextArea
            value={props.values.detail}
            onChange={
              props.isEditActive
                ? () => {}
                : (e) =>
                    props.onChange({
                      target: { name: 'detail', value: e.target.value },
                    })
            }
            name="detail"
            placeholder="Enter Detail"
          />
          <span style={{ color: 'red' }}>{props.error.detail}</span>
        </Col>
        {props.business.type !== 'branch' && (
          <Col span={5}>
            <Label title="Availability">
              <Radio.Group
                name="isAvailable"
                value={props.values.isAvailable}
                onChange={(e) =>
                  props.onChange({
                    target: { name: 'isAvailable', value: e.target.value },
                  })
                }
                disabled={props.user?.user?.groups?.[0]?.name !== 'Inventory'}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Label>
            <span style={{ color: 'red' }}>{props.error.isAvailable}</span>
          </Col>
        )}
      </Row>
    </div>
  );
};

interface Props {
  values: RequestInput;
  error: RequestError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  isEditActive: boolean;
  user: User;
  business: Bussiness;
}

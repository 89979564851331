export const finishedStockReportQuery = `
query stockTypeWiseQuery($companyId: ID!, $type:String, $date:Date!)
{
  allProducts(companyId: $companyId, productType_In:$type) {
    edges {
      node {
        id
        name
        unit {
          name
        }
        stock(companyId: $companyId, date: $date) {
          openingStock
          production
          saleChallan
          damage
          totalStocks
          mrp
          totalStocksValue
        }
      }
    }
  }
}
`;

export const rawStockReportQuery = `
query rawStockQuery($companyId: ID!, $type:String, $date:Date!)
{
  allProducts(companyId: $companyId, productType_In:$type) {
    edges {
      node {
        id
        name
        unit {
          name
        }
        stock(companyId: $companyId, date: $date) {
          openingStock
          purchase
          purchaseReturn
					issue
          totalStocks
          mrp
          totalStocksValue
        }
      }
    }
  }
}
`;

export const vendorWiseStockQuery = `
query VendorWiseStockList($companyId: ID!, $vendorId: ID!, $date: String!) {
    vendorWiseTotalStock(companyId: $companyId, date:$date, vendorId: $vendorId){
      productBarCode
      stock
      name
      mrp
      totalPurchase
      totalSale
      purchaseRate
      purchaseValue
      stockValue
    }
  }

`;

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  RemunerationInput,
  CreateRemunerationInput,
  CreateRemunerationPayload,
} from '../schema';
import { remunerationMutation } from '../components/master/Remuneration/constants';

export class RemunerationService {
  static instance;

  constructor() {
    if (RemunerationService.instance) {
      return RemunerationService.instance;
    } else {
      RemunerationService.instance = this;
    }
  }

  companyService = new CompanyService();

  addRemuneration(
    data: RemunerationInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<{ input: CreateRemunerationInput }, CreateRemunerationPayload>(
      API_URL,
      {
        input: {
          remuneration: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      remunerationMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

import * as React from 'react';
import { DatePicker, Divider, Row, Col, Button, Skeleton } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { itemWisePurchaseQuery } from '../master/Product/constants';
import { ItemWisePurchase } from '../../schema';
import { ITEM_WISE_PURCHASE_REPORT_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';

export class ItemWisePurchaseReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 10 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 10 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'GRC No',
      key: 'grcNo',
      dataIndex: 'grcNo',
    },
    {
      title: 'GRC Date',
      key: 'grcDate',
      dataIndex: 'grcDate',
    },
    {
      title: 'Vendor Name',
      key: 'vendorName',
      dataIndex: 'vendorName',
    },
    {
      title: 'Item Name',
      key: 'itemName',
      dataIndex: 'itemName',
    },
    {
      title: 'Old Barcode',
      key: 'oldBarcode',
      dataIndex: 'oldBarcode',
    },
    {
      title: 'New Barcode',
      key: 'newBarcode',
      dataIndex: 'newBarcode',
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: 'Division',
      key: 'division',
      dataIndex: 'division',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Purchase Cost',
      key: 'purchaseCost',
      dataIndex: 'purchaseCost',
      render: (value) => value.toFixed(2),
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      render: (value) => value.toFixed(2),
    },
    {
      title: 'MRP',
      key: 'mrp',
      dataIndex: 'mrp',
    },
    {
      title: 'Purchase Order No',
      key: 'purchaseOrderNo',
      dataIndex: 'purchaseOrderNo',
    },
    {
      title: 'Purchase Order Date',
      key: 'purchaseOrderDate',
      dataIndex: 'purchaseOrderDate',
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_PURCHASE_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `item-wise-${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'stock-in'
              : 'purchase'
          }-${new Date()}`,
          `ITEM WISE ${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'STOCK IN'
              : 'PURCHASE'
          } REPORT`,
          this.props.company.name,
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { startDate, endDate, after, currentPage } = this.state;
    const {
      company: { id: companyId },
      activeKey,
    } = this.props;
    const variables = {
      companyId,
      startDate,
      endDate,
      after,
      activeKey,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(this.state.startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(this.state.endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Divider />
        <GraphqlQuery
          queryString={itemWisePurchaseQuery}
          variables={variables}
          render={(
            response: { itemWisePurchase: ItemWisePurchase[] },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (error) {
              return <h3>Something went wrong</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.itemWisePurchase}
                  columns={this.columns()}
                  rowKey={(obj) => obj.grcNo}
                  pagination={false}
                />,
                <Row key="Pagination">
                  <Col offset={21} span={1}>
                    <Button onClick={this.onPrev} disabled={currentPage === 1}>
                      Prev
                    </Button>
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button type="primary">{currentPage}</Button>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={this.onNext}
                      disabled={
                        response.itemWisePurchase &&
                        (!response.itemWisePurchase.length ||
                          response.itemWisePurchase.length < 10)
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>,
              ];
            }
            return <Skeleton />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
        ,
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
}

interface State {
  startDate: string;
  endDate: string;
  currentPage: number;
  after: number;
}

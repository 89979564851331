import * as React from 'react';
import { CompanyNodeEdge, CompanyError } from './constants';
import { TSInput } from '../../common/TSInput';

export const CompanyForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error={props.error.gstin}
        value={props.values.gstin}
        onChange={props.onChange}
        name="gstin"
        type="text"
        addonBefore="GST IN"
      />
      <TSInput
        error={props.error.gstRate}
        value={props.values.gstRate || 0}
        onChange={props.onChange}
        name="gstRate"
        type="number"
        addonBefore="GST Rate"
      />
      <TSInput
        error={props.error.hsnCode}
        value={props.values.hsnCode}
        onChange={props.onChange}
        name="hsnCode"
        type="text"
        addonBefore="HSN Code"
      />
      <TSInput
        error={props.error.address}
        value={props.values.address}
        onChange={props.onChange}
        name="address"
        type="text"
        addonBefore="Address"
      />
      <TSInput
        error={props.error.state}
        value={props.values.state}
        onChange={props.onChange}
        name="state"
        type="text"
        addonBefore="State"
      />
      <TSInput
        error={props.error.contactNo}
        value={props.values.contactNo}
        onChange={props.onChange}
        name="contactNo"
        type="number"
        addonBefore="Contact No"
      />
      <TSInput
        error={props.error.amountPerPointSale}
        value={props.values.amountPerPointSale || 0}
        onChange={props.onChange}
        name="amountPerPointSale"
        type="number"
        addonBefore="Amount Per Point Sale"
      />
      <TSInput
        error={props.error.amountPerPointRedeem}
        value={props.values.amountPerPointRedeem || 0}
        onChange={props.onChange}
        name="amountPerPointRedeem"
        type="number"
        addonBefore="Amount Per Point Redeem"
      />
      {props.values.name.toLowerCase().includes('tiles') ||
        (!!props.values && (
          <React.Fragment>
            <TSInput
              error={props.error.bankName}
              value={props.values.bankName}
              onChange={props.onChange}
              name="bankName"
              type="text"
              addonBefore="Bank Name"
            />
            <TSInput
              error={props.error.branch}
              value={props.values.branch}
              onChange={props.onChange}
              name="branch"
              type="text"
              addonBefore="Branch"
            />
            <TSInput
              error={props.error.accountNo}
              value={props.values.accountNo}
              onChange={props.onChange}
              name="accountNo"
              type="text"
              addonBefore="Account Number"
            />
            <TSInput
              error={props.error.ifscCode}
              value={props.values.ifscCode}
              onChange={props.onChange}
              name="ifscCode"
              type="text"
              addonBefore="Ifsc Code"
            />
          </React.Fragment>
        ))}
    </div>
  );
};

interface Props {
  values: CompanyNodeEdge;
  error: CompanyError;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

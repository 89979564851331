import * as React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { SalesNodeEdgeQuery } from './constants';
import moment from 'moment';
import { inWords } from '../helper';

export const SaleRecieptFashion11: React.FunctionComponent<Props> = (props) => (
  <Document>
    <Page
      size={{ width: 215, height: 2000 }}
      style={[styles.body, { margin: '0px', padding: '2px' }]}
    >
      <View>
        <Text style={[{ fontSize: 12, textAlign: 'center' }]}>ENARA</Text>
        <Text style={[{ fontSize: 12, textAlign: 'center' }]}>
          {props.company && props.company.name}
        </Text>
        <Text
          style={[
            styles.author,
            styles.inlineHeadDouble,
            { fontSize: 8, textAlign: 'center' },
          ]}
        >
          GSTIN No. 20AAOPS8260N1Z4
        </Text>
        <Text
          style={[
            styles.inlineHeadDouble,
            { fontSize: 9, textAlign: 'center' },
          ]}
        >
          {props.branch.address}
        </Text>
        <Text
          style={[
            styles.inlineHeadDouble,
            { fontSize: 9, textAlign: 'center' },
          ]}
        >
          Contact Number:- {props.branch.contactNo}
        </Text>
        <Text
          style={[
            styles.inlineHeadDouble,
            { fontSize: 10, textAlign: 'center', margin: '4px' },
          ]}
        >
          TAX INVOICE
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Date: {moment(props.sale.date).format('DD-MM-YYYY')}
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Name: {props.sale.customer && props.sale.customer.name}
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Contact No: {props.sale.customer && props.sale.customer.contactNo}
        </Text>
      </View>
      <View style={[styles.inline, { marginBottom: '4px' }]}>
        <Text style={[styles.author, styles.boxMargin, styles.inlineHead]}>
          Bill No: {props.sale.billNo}
        </Text>
      </View>
      <View style={[styles.inline]}>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.minFlex,
            { fontWeight: 'bold' },
          ]}
        >
          S.No.
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHead,
            { fontWeight: 'bold' },
          ]}
        >
          Items
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHeadDouble,
            { fontWeight: 'bold', flex: 4 },
          ]}
        >
          Qty.
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.minFlex,
            { fontWeight: 'bold', flex: 6 },
          ]}
        >
          Rate
        </Text>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            styles.inlineHeadDouble,
            { fontWeight: 'bold', marginRight: '10%', textAlign: 'center' },
          ]}
        >
          Amount
        </Text>
      </View>
      {props.sale.productDetails?.edges.map((item, i) => (
        <View style={[styles.inline]} key={i}>
          <Text
            style={[
              styles.author,
              styles.boxMargin,
              styles.snoflex,
              { marginRight: '12px' },
            ]}
          >
            {i + 1}
          </Text>
          <Text
            style={[
              styles.author,
              styles.boxMargin,
              styles.inlineHead,
              { fontWeight: 'bold' },
            ]}
          >
            {item.node.product?.name}
          </Text>
          <Text
            style={[
              styles.author,
              styles.boxMargin,
              styles.inlineHeadDouble,
              { fontWeight: 'bold', textAlign: 'center' },
            ]}
          >
            {item.node.quantity}
          </Text>
          <Text
            style={[
              styles.author,
              styles.boxMargin,
              styles.minFlex,
              { fontWeight: 'bold', textAlign: 'right' },
            ]}
          >
            {Number(item.node.rate).toFixed(2)}
          </Text>
          <Text
            style={[
              styles.author,
              styles.boxMargin,
              styles.inlineHeadDouble,
              { fontWeight: 'bold', textAlign: 'right', marginRight: '15%' },
            ]}
          >
            {Number(item.node.amount).toFixed(2)}
          </Text>
        </View>
      ))}
      {props.sale.oldProductDetails?.edges.length ? (
        <View>
          <Text style={[{ textAlign: 'center', fontSize: 10 }]}>Exchange</Text>
          {props.sale.oldProductDetails?.edges.map((item, i) => (
            <View style={[styles.inline]} key={i}>
              <Text
                style={[
                  styles.author,
                  styles.boxMargin,
                  styles.snoflex,
                  { marginRight: '12px' },
                ]}
              >
                {i + 1}
              </Text>
              <Text
                style={[
                  styles.author,
                  styles.boxMargin,
                  styles.inlineHead,
                  { fontWeight: 'bold' },
                ]}
              >
                {item.node.product?.name}
              </Text>
              <Text
                style={[
                  styles.author,
                  styles.boxMargin,
                  styles.inlineHeadDouble,
                  { fontWeight: 'bold', textAlign: 'center' },
                ]}
              >
                {item.node.quantity}
              </Text>
              <Text
                style={[
                  styles.author,
                  styles.boxMargin,
                  styles.minFlex,
                  { fontWeight: 'bold', textAlign: 'right' },
                ]}
              >
                {Number(item.node.rate).toFixed(2)}
              </Text>
              <Text
                style={[
                  styles.author,
                  styles.boxMargin,
                  styles.inlineHeadDouble,
                  {
                    fontWeight: 'bold',
                    textAlign: 'right',
                    marginRight: '15%',
                  },
                ]}
              >
                {Number(item.node.amount).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>
      ) : (
        <View />
      )}
      <View
        style={[
          styles.author,
          styles.boxMargin,
          styles.price,
          { marginTop: '4px' },
        ]}
      >
        <Text style={[styles.minFlex]}>
          Total: Rs. {Number(props.sale.netAmount).toFixed(2)}
        </Text>
        <Text style={[styles.minFlex]}>
          (Rupees {inWords(Math.round(props.sale.netAmount))})
        </Text>
      </View>
      <View style={[styles.boxMargin, { fontSize: 9 }]}>
        <Text style={[styles.minFlex]}>GST Inclusive</Text>
      </View>
      <View style={[{ margin: '3px 0' }]}>
        <Text style={[styles.author]}>Terms and Condition</Text>
        <Text style={[styles.terms]}>
          Goods once sold will not be Refunded or Exchanged
        </Text>
        <Text style={[styles.terms]}>
          All disputes are subject to Ranchi jurisdiction only
        </Text>
      </View>
      <View style={[styles.inline, { textAlign: 'center' }]}>
        <Text
          style={[
            styles.author,
            styles.boxMargin,
            { fontWeight: 'extrabold', paddingRight: '10px' },
          ]}
        >
          Thank You!!! Visit Again!!!
        </Text>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 0,
  },
  author: {
    fontSize: 10,
  },
  inline: { flexDirection: 'row' },
  minFlex: { flex: 5 },
  inlineHead: { flex: 9 },
  inlineHeadDouble: { flex: 5 },
  boxMargin: { margin: 1 },
  price: { marginRight: '5%', textAlign: 'right' },
  snoflex: { flex: 3 },
  terms: { fontSize: 9, padding: '2px 0', paddingRight: '15px' },
});

interface Props {
  sale: SalesNodeEdgeQuery;
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

import * as React from 'react';
import { Table, Tabs, Icon, Skeleton, Spin, Row, Col, DatePicker } from 'antd';
import { nestedAccess, checkAddPermission } from '../helper';
import { PurchaseNodeEdgeQuery, purchaseQuery } from '../Purchase/constants';
import { AddPurchaseReturn } from './AddPurchaseReturn';
import { CompanyNodeEdge } from '../master/Company/constants';
import { CompanyService } from '../../service/CompanyService';
import moment from 'moment';
import { Pagination } from 'sha-el-design';
import { GraphqlQuery } from 'requestapijs';
import { PurchaseNodeConnection, PurchaseNodeEdge } from '../../schema';
import { PDFViewer } from '@react-pdf/renderer';
import { PurchaseReturnReceiptJharcraft } from './PurchaseReturnReceiptJharcraft';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';
import { PurchaseReturnReport } from './PurchaseReturnReport';
import { PurchaseReturnReceiptKhadi } from './PurchaseReturnReceiptKhadi';

export class PurchaseReturn extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedPurchaseReturn: null,
      company: null,
      currentPage: 1,
      after: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedPurchaseReturn: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  purchaseReturnColumns = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Bill No',
      dataIndex: 'node.billNo',
      key: 'billNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedPurchaseReturn: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Purchase Bill No',
      dataIndex: 'node.purchaseToReturn.billNo',
      key: 'purchaseToReturnBillNo',
    },
    {
      title: 'Receipt',
      key: 'receipt',
      dataIndex: 'receipt',
      render: (text, obj: PurchaseNodeEdge) => (
        <a
          onClick={() => {
            this.setState({ selectedPurchaseReturn: obj, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const {
      company,
      after,
      currentPage,
      activeKey,
      selectedPurchaseReturn,
      startDate,
      endDate,
    } = this.state;
    if (!company) {
      return <Spin />;
    }
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Purchase Returns
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                value={moment(startDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'startDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !endDate) {
                    return false;
                  }
                  return currentDate.valueOf() > moment(endDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="End Date"
                value={moment(endDate)}
                onChange={(date) =>
                  this.onChange({
                    target: {
                      name: 'endDate',
                      value: date && moment(date).format('YYYY-MM-DD'),
                    },
                  })
                }
                disabledDate={(currentDate) => {
                  if (!currentDate || !startDate) {
                    return false;
                  }
                  return currentDate.valueOf() < moment(startDate).valueOf();
                }}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={purchaseQuery}
            variables={{
              companyId: company.id,
              date_Gte: startDate,
              date_Lte: endDate,
              isReturn: true,
              after,
              activeKey,
            }}
            render={(
              response: { allPurchases: PurchaseNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (error) return <h3>Something Went Wrong!!</h3>;
              if (response && company) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allPurchases.edges}
                    columns={this.purchaseReturnColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={Infinity}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allPurchases.pageInfo.endCursor,
                        response.allPurchases.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Purchase Return
              </span>
            }
            key="2"
          >
            <AddPurchaseReturn
              purchaseReturn={
                selectedPurchaseReturn?.node as unknown as PurchaseNodeEdgeQuery
              }
              handleTabChange={this.handleTabChange}
              company={company}
            />
          </Tabs.TabPane>
        )}
        {activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            {this.state.selectedPurchaseReturn?.node &&
              (company?.name === 'Jharcraft' ? (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <PurchaseReturnReceiptJharcraft
                    company={company}
                    purchaseReturn={this.state?.selectedPurchaseReturn?.node}
                  />
                </PDFViewer>
              ) : (
                company?.name.toLowerCase().includes('khadi') && (
                  <PDFViewer style={{ width: '100%', height: '1000px' }}>
                    <PurchaseReturnReceiptKhadi
                      company={company}
                      purchaseReturn={this.state?.selectedPurchaseReturn?.node}
                    />
                  </PDFViewer>
                )
              ))}
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Item Wise Purchase Return Report
            </span>
          }
          key="4"
          closable={false}
        >
          <PurchaseReturnReport company={this.state.company} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedPurchaseReturn: PurchaseNodeEdge | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  startDate: string;
  endDate: string;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import { ProductNodeEdge } from './constants';
import { ProductForm } from './ProductForm';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../../../config';
import { CompanyNodeEdge } from '../Company/constants';
import { CompanyService } from '../../../service/CompanyService';

export class ViewProduct extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: null,
      company: null,
    };
    this.getSelectedProduct();
  }

  private readonly token = localStorage.getItem('token');
  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  getSelectedProduct() {
    const productQuery = `
      query Product($id: ID!) {
        product(id: $id) {
          id
          category {
            id
            name
          }
          name
          unit {
            id
            name
          }
          colour
          purchaseRate
          sellingRate
          stock
        }
      }
    `;
    Api.graphql<{}, ProductQueryResponse>(
      API_URL,
      { id: this.props.route.match.params.id },
      productQuery,
      HEADER,
      (response) => {
        if (response) {
          const selectedProduct = response.data.product;
          selectedProduct.category = selectedProduct.category?.name as any;
          selectedProduct.unit = selectedProduct.unit?.name as any;
          this.setState({ selectedProduct });
        }
      },
      (error) => error,
    );
  }

  render() {
    return (
      <div>
        {this.state.selectedProduct && this.state.company && (
          <ProductForm
            values={this.state.selectedProduct}
            onChange={() => ''}
            isViewActive={true}
            company={this.state.company}
          />
        )}
      </div>
    );
  }
}

interface State {
  selectedProduct: any;
  company: CompanyNodeEdge | null;
}

interface Props {
  route?: any;
}

interface ProductQueryResponse {
  data: {
    product: ProductNodeEdge;
  };
}

import * as React from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Divider, Row, Skeleton, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { itemWiseProductionReceivingQuery } from './constants';
import { Pagination } from 'sha-el-design/lib';
import {
  ConversionDetailNode,
  ConversionDetailNodeConnection,
  ProductionTransferNodeConnection,
} from '../../../schema';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { ITEM_WISE_PRODUCTION_RECEIVING_REPORT_CSV } from '../../../config';
import { downloadToFile } from '../../helper';

export class ItemWiseProductionReceivngReport extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
      isLoading: false,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node',
      render: (value: ConversionDetailNode) =>
        moment(
          (
            value.productiontransferSet as unknown as ProductionTransferNodeConnection
          ).edges[0].node?.receivingDate,
        ).format('DD-MM-YYYY'),
    },
    {
      title: 'Job Receiving Invoice Number',
      key: 'receivNo',
      dataIndex: 'node',
      render: (value: ConversionDetailNode) =>
        (
          value.productiontransferSet as unknown as ProductionTransferNodeConnection
        ).edges[0].node?.receivingInvoiceNo,
    },
    {
      title: 'Vendor Name',
      key: 'vendorName',
      dataIndex: 'node.vendorFor.name',
    },
    {
      title: 'Location Name',
      key: 'locationName',
      dataIndex: 'node.sourceBranch.name',
    },
    {
      title: 'Product Name',
      key: 'name',
      dataIndex: 'node.conversion.finishedProduct',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'node.quantity',
    },
    {
      title: 'Cost Price',
      key: 'costPrice',
      dataIndex: 'node.cost',
    },
    {
      title: 'Cost Value',
      key: 'costValue',
      dataIndex: 'node.amount',
    },
    {
      title: 'Job Order Number',
      key: 'jobOrderNo',
      dataIndex: 'node',
      render: (value: ConversionDetailNode) =>
        (
          value.productiontransferSet as unknown as ProductionTransferNodeConnection
        ).edges[0].node?.jobOrder?.jobOrderNo,
    },
    {
      title: 'Job Order Date',
      key: 'jobOrderDate',
      dataIndex: 'node.productiontransferSet.edges[0].node.jobOrder.date',
      render: (value) => value && moment(value).format('DD-MM-YYYY'),
    },
  ];

  downloadCsv = () => {
    const queryParams = `companyId=${this.props.company.id}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;

    this.setState({ isLoading: true });

    fetch(ITEM_WISE_PRODUCTION_RECEIVING_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) =>
        downloadToFile(
          csv,
          `item-wise-production_receiving-report-${new Date()}`,
          'ITEM WISE PRODUCTION RECEIVING REPORT',
          this.props.company.name,
          'Head Production',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      )
      .then(() => this.setState({ isLoading: false }));
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { startDate, endDate, currentPage, after } = this.state;
    const {
      company: { id: companyId },
      activeKey,
    } = this.props;
    const variables = {
      startDate,
      endDate,
      companyId,
      after,
      activeKey,
    };

    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: (date && moment(date).format('YYYY-MM-DD')) || '',
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Divider />
        {startDate && endDate && (
          <GraphqlQuery
            queryString={itemWiseProductionReceivingQuery}
            variables={variables}
            render={(
              response: {
                allConversionDetails: ConversionDetailNodeConnection;
              },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allConversionDetails.edges}
                    columns={this.columns()}
                    rowKey={(record) => record.node?.id || ''}
                    pagination={false}
                    scroll={{ x: true }}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allConversionDetails.pageInfo.endCursor,
                        response.allConversionDetails.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                  <Button
                    key="download"
                    style={{ float: 'right' }}
                    icon="download"
                    onClick={() => this.downloadCsv()}
                    disabled={this.state.isLoading}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  activeKey: string;
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
  isLoading: boolean;
}

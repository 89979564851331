import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  ConversionInput,
  CreateConversionInput,
  CreateConversionPayload,
  ProductDetailsNode,
  RemunerationDetailNode,
} from '../schema';
import { conversionMutation } from '../components/Production/Conversion/constants';

export class ConversionService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (ConversionService.instance) {
      return ConversionService.instance;
    } else {
      ConversionService.instance = this;
    }
  }

  addConversion(
    data: ConversionInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.rawMaterials = data.rawMaterials?.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete (pd as any as ProductDetailsNode).product;
      delete (pd as any as ProductDetailsNode).movementFor;
      delete (pd as any as ProductDetailsNode).movementFrom;
      return pd;
    });
    data.remunerations = data.remunerations?.map((rd) => {
      rd.date = data.date;
      rd.companyId = this.companyService?.company$.getValue()?.id;
      delete (rd as any as RemunerationDetailNode).remuneration;
      return rd;
    });
    Api.graphql<{ input: CreateConversionInput }, CreateConversionPayload>(
      API_URL,
      {
        input: {
          conversion: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      conversionMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

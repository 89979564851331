import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import {
  AddDiscountCategoryPayload,
  AddDiscountCategoryVariables,
  allDiscountCategoriesQuery,
  discountCategoryMutation,
  DiscountCategoryNode,
  DiscountCategoryNodeEdge,
  DiscountCategoryQueryResponse,
} from '../components/master/DiscountCategory/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class DiscountCategoryService {
  static instance;

  constructor() {
    if (DiscountCategoryService.instance) {
      return DiscountCategoryService.instance;
    } else {
      DiscountCategoryService.instance = this;
    }
    this.onInit();
  }

  companyService = new CompanyService();
  public readonly discountCategory$ = new BehaviorSubject<
    DiscountCategoryNodeEdge[]
  >([]);

  onInit() {
    this.companyService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  getAll(companyId: string) {
    Api.graphql<{}, DiscountCategoryQueryResponse>(
      API_URL,
      { companyId },
      allDiscountCategoriesQuery,
      HEADER,
      (response) =>
        this.discountCategory$.next(response.data.allDiscountCategories.edges),
      (error) => error,
    );
  }

  addDiscountCategory(
    data: DiscountCategoryNode,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    Api.graphql<AddDiscountCategoryVariables, AddDiscountCategoryPayload>(
      API_URL,
      {
        input: {
          discountCategory: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      discountCategoryMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

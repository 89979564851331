import * as React from 'react';
import moment from 'moment';
import {
  SaleChallanNode,
  ProductDetailsNodeConnection,
  CustomerNode,
  CustomerNodeConnection,
} from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { sumBy } from 'lodash';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { inWords } from '../helper';
import {
  RAMESHWARAM_INDUSTRIES_HEADER,
  RAMESHWARAM_PROJECTS_HEADER,
} from '../../config';

export const SalesInvoiceReceiptHindustanTiles: React.FunctionComponent<InvoiceHindustanTilesProps> =
  (props: InvoiceHindustanTilesProps) => {
    const productDetailEdges = (
      props.saleChallan.productDetails as any as ProductDetailsNodeConnection
    ).edges;
    const totalQuantity = sumBy(productDetailEdges, (value) =>
      Number(value.node?.quantity),
    );
    const totalProductAmount = sumBy(
      productDetailEdges,
      (value) => Number(value.node?.rate) * Number(value.node?.quantity),
    );
    const setBoxHeight = productDetailEdges.length < 9;
    const extraCharges =
      Number(props.saleChallan.miscellaneousCharge) +
      Number(props.saleChallan.transportationCharges) +
      Number(props.saleChallan.otherCharges);
    const gstValueOnExtraCharges =
      (extraCharges * Number(productDetailEdges[0].node?.gstRate)) / 100;
    const totalAmountWithoutGst = totalProductAmount + extraCharges;
    const totalGstValue =
      (totalAmountWithoutGst *
        Number(productDetailEdges[0].node?.product?.hsn?.gst)) /
      100;
    const cgstAndSgst = (
      (productDetailEdges[0].node?.product?.hsn?.gst || 0) / 2
    ).toFixed(2);
    return (
      <Document>
        <Page size="A4" style={{ paddingBottom: 50 }}>
          <View
            style={
              props.company.name.toLowerCase().includes('hindustan')
                ? { top: 150 }
                : {}
            }
          >
            <View fixed>
              {!props.company.name.toLowerCase().includes('hindustan') && (
                <View style={[style.section, style.inline, { marginTop: 12 }]}>
                  {props.company.name.toLowerCase().includes('projects') ? (
                    <Image
                      style={[{ height: 100 }]}
                      source={RAMESHWARAM_PROJECTS_HEADER}
                    />
                  ) : (
                    <Image
                      style={[{ height: 100 }]}
                      source={RAMESHWARAM_INDUSTRIES_HEADER}
                    />
                  )}
                </View>
              )}
              <View style={style.section}>
                <Text
                  style={[
                    style.textCenter,
                    style.mediumBlackText,
                    { fontSize: '12pt' },
                  ]}
                >
                  Tax Invoice{' '}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.textRight,
                    { fontSize: '12pt' },
                  ]}
                >
                  {props.company.name.toLowerCase().includes('hindustan')
                    ? props.company.gstin
                    : []}{' '}
                </Text>
                <View style={style.divider} />
              </View>
              <View
                style={{
                  borderLeft: '1 pt solid black',
                  borderRight: '1 pt solid black',
                  left: 15,
                  width: 565,
                  borderBottom: '1 pt solid black',
                }}
              >
                <View style={[style.section, style.inline]}>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    Invoice No:{' '}
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.challanNo}
                    </Text>
                  </Text>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    Invoice Date:{' '}
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.date}
                    </Text>
                  </Text>
                </View>
                <View style={[style.section]}>
                  <Text style={[style.mediumBlackText]}>To</Text>
                </View>
                <View style={[style.section, style.inline]}>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.deliveryInstruction?.customer.name}
                    </Text>
                  </Text>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.dispatchedThrough}
                    </Text>
                  </Text>
                </View>
                <View style={[style.section, style.inline]}>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.deliveryInstruction?.customer.address}
                    </Text>
                  </Text>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.vehicleNo}
                    </Text>
                  </Text>
                </View>
                <View style={[style.section, style.inline]}>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {
                        props.saleChallan.deliveryInstruction?.customer
                          .gstinNumber
                      }
                    </Text>
                  </Text>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {props.saleChallan.destination}
                    </Text>
                  </Text>
                </View>
                <View style={[style.section, style.inline]}>
                  <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                    <Text style={[style.darkBlackText]}>
                      {
                        props.saleChallan.deliveryInstruction?.customer
                          .contactNo
                      }
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
            {
              <View>
                {setBoxHeight ? (
                  <View
                    style={[
                      style.section,
                      style.box,
                      { marginTop: 2, height: 270 },
                    ]}
                  >
                    <View style={[style.inline]}>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textCenter,
                          { padding: 3, flex: 0.2 },
                        ]}
                      >
                        Sl.
                      </Text>
                      <Text
                        style={[
                          style.highFlexInlineHead,
                          style.darkBlackText,
                          { padding: 3 },
                        ]}
                      >
                        Product
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textCenter,
                          {
                            padding: 3,
                            flex: 0.5,
                          },
                        ]}
                      >
                        HSN No.
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.5,
                          },
                        ]}
                      >
                        Quantity
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Sale Price
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Unit
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Amount
                      </Text>
                    </View>
                    <View style={style.divider} />
                    {productDetailEdges.map((pd, i) => (
                      <View style={[style.inline]} key={'pd' + i} wrap={false}>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.lightBlackText,
                            style.textCenter,
                            { flex: 0.2, padding: 3 },
                          ]}
                        >
                          {i + 1}.
                        </Text>
                        <Text
                          style={[
                            style.highFlexInlineHead,
                            style.mediumBlackText,
                            { padding: 3 },
                          ]}
                        >
                          {pd.node?.product?.name}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textCenter,
                            {
                              padding: 3,
                              flex: 0.5,
                            },
                          ]}
                        >
                          {pd.node?.product?.hsn?.hsnCode}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.5,
                            },
                          ]}
                        >
                          {pd.node?.quantity}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node?.rate.toFixed(2)}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node?.product?.unit.name}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node &&
                            (pd.node.quantity * pd.node.rate).toFixed(2)}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : (
                  <View style={[style.section, style.box, { marginTop: 2 }]}>
                    <View style={[style.inline]}>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textCenter,
                          { padding: 3, flex: 0.2 },
                        ]}
                      >
                        Sl.
                      </Text>
                      <Text
                        style={[
                          style.highFlexInlineHead,
                          style.darkBlackText,
                          { padding: 3 },
                        ]}
                      >
                        Product
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textCenter,
                          {
                            padding: 3,
                            flex: 0.5,
                          },
                        ]}
                      >
                        HSN No.
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.5,
                          },
                        ]}
                      >
                        Quantity
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Sale Price
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Unit
                      </Text>
                      <Text
                        style={[
                          style.lowFlexInlineHead,
                          style.darkBlackText,
                          style.textRight,
                          {
                            padding: 3,
                            flex: 0.6,
                          },
                        ]}
                      >
                        Amount
                      </Text>
                    </View>
                    <View style={style.divider} />
                    {productDetailEdges.map((pd, i) => (
                      <View style={[style.inline]} key={'pd' + i} wrap={false}>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.lightBlackText,
                            style.textCenter,
                            { flex: 0.2, padding: 3 },
                          ]}
                        >
                          {i + 1}.
                        </Text>
                        <Text
                          style={[
                            style.highFlexInlineHead,
                            style.mediumBlackText,
                            { padding: 3 },
                          ]}
                        >
                          {pd.node?.product?.name}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textCenter,
                            {
                              padding: 3,
                              flex: 0.5,
                            },
                          ]}
                        >
                          {pd.node?.product?.hsn?.hsnCode}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.5,
                            },
                          ]}
                        >
                          {pd.node?.quantity}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node?.rate.toFixed(2)}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node?.product?.unit.name}
                        </Text>
                        <Text
                          style={[
                            style.lowFlexInlineHead,
                            style.mediumBlackText,
                            style.textRight,
                            {
                              padding: 3,
                              flex: 0.6,
                            },
                          ]}
                        >
                          {pd.node &&
                            (pd.node.quantity * pd.node.rate).toFixed(2)}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
                <View
                  style={[
                    style.section,
                    {
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                      marginTop: 0,
                      height: 17,
                    },
                  ]}
                >
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.darkBlackText,
                        { padding: 3 },
                      ]}
                    >
                      Total
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3, marginLeft: -50 },
                      ]}
                    >
                      {totalQuantity.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3 },
                      ]}
                    >
                      {totalProductAmount.toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    style.section,
                    style.inline,
                    {
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      { flex: 2.7, padding: 3 },
                    ]}
                  >
                    Transportation Cost
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {props.saleChallan.transportationCharges.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={[
                    style.section,
                    style.inline,
                    {
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3, flex: 2.7 },
                    ]}
                  >
                    Laying and Fitting Charges
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {props.saleChallan.miscellaneousCharge.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={[
                    style.section,
                    style.inline,
                    {
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3, flex: 2.7 },
                    ]}
                  >
                    Other Charges
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {props.saleChallan.otherCharges.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={[
                    style.section,
                    style.inline,
                    {
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3, flex: 2.7 },
                    ]}
                  >
                    CGST @ {cgstAndSgst}%:
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {(totalGstValue / 2).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={[
                    style.section,
                    style.inline,
                    {
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                    },
                  ]}
                >
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { flex: 0.2, padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.darkBlackText,
                      { padding: 3 },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.highFlexInlineHead,
                      style.mediumBlackText,
                      { padding: 3, flex: 2.7 },
                    ]}
                  >
                    SGST @ {cgstAndSgst}%:
                  </Text>
                  <Text
                    style={[
                      style.lowFlexInlineHead,
                      style.mediumBlackText,
                      style.textRight,
                      { padding: 3 },
                    ]}
                  >
                    {(totalGstValue / 2).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={[
                    style.section,
                    {
                      borderLeft: '1pt solid black',
                      borderRight: '1pt solid black',
                      borderTop: '1pt solid black',
                      marginTop: 0,
                      height: 22,
                    },
                  ]}
                >
                  <View style={[style.inline]}>
                    <Text
                      style={[
                        style.highFlexInlineHead,
                        style.darkBlackText,
                        { padding: 3 },
                      ]}
                    >
                      Grand Total
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.lightBlackText,
                        { flex: 0.2, padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        { padding: 3 },
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        style.lowFlexInlineHead,
                        style.mediumBlackText,
                        style.textRight,
                        { padding: 4, fontSize: '10.2pt' },
                      ]}
                    >
                      {(
                        Number(props.saleChallan.totalAmount) +
                        Number(totalGstValue)
                      ).toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    style.section,
                    style.box,
                    { marginTop: 0, height: 22 },
                  ]}
                >
                  <Text
                    style={[
                      style.darkBlackText,
                      { padding: 4, fontSize: '10.2pt' },
                    ]}
                  >
                    Rs.{' '}
                    {inWords(
                      Number(
                        (
                          Number(props.saleChallan.totalAmount) +
                          Number(totalGstValue)
                        ).toFixed(2),
                      ),
                    )}
                    .
                  </Text>
                </View>
              </View>
            }
            <View wrap={false}>
              <View
                style={[
                  style.box,
                  style.section,
                  style.inline,
                  { marginTop: 2 },
                ]}
              >
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    { borderRight: '1 pt solid black', padding: 3, flex: 0.5 },
                  ]}
                >
                  HSN Code
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.3,
                    },
                  ]}
                >
                  CGST %
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.3,
                    },
                  ]}
                >
                  SGST %
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.5,
                    },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.8,
                    },
                  ]}
                >
                  Taxable Amount
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  CGST Amount
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  SGST Amount
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    { padding: 3, flex: 0.9 },
                  ]}
                >
                  Total Amount
                </Text>
              </View>
              <View
                style={[
                  style.section,
                  {
                    marginTop: 0,
                    borderLeft: '1 pt solid black',
                    borderRight: '1 pt solid black',
                    borderBottom: '1 pt solid black',
                  },
                ]}
              >
                {props.saleChallan.hsnGstWiseAmount?.map((obj) => (
                  <View style={[style.inline, { marginTop: 0 }]}>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.lowFlexInlineHead,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {obj.hsnCode}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.lowFlexInlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.3,
                        },
                      ]}
                    >
                      {cgstAndSgst}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.lowFlexInlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.3,
                        },
                      ]}
                    >
                      {cgstAndSgst}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.lowFlexInlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.5,
                        },
                      ]}
                    >
                      {obj.quantity?.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.inlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.8,
                        },
                      ]}
                    >
                      {obj.amount?.toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.inlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {(totalGstValue / 2).toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.inlineHead,
                        style.textRight,
                        {
                          borderRight: '1 pt solid black',
                          borderBottom: '1 pt solid black',
                          padding: 3,
                          flex: 0.6,
                        },
                      ]}
                    >
                      {(totalGstValue / 2).toFixed(2)}
                    </Text>
                    <Text
                      style={[
                        style.mediumBlackText,
                        style.inlineHead,
                        style.textRight,
                        {
                          padding: 3,
                          borderBottom: '1 pt solid black',
                          flex: 0.9,
                        },
                      ]}
                    >
                      {obj.amount &&
                        obj.gst &&
                        (
                          obj.amount +
                          obj.amount * (obj.gst / 100) +
                          Number(totalGstValue)
                        ).toFixed(2)}
                    </Text>
                  </View>
                ))}
              </View>
              <View
                style={[
                  style.section,
                  style.inline,
                  {
                    marginTop: 0,
                    borderLeft: '1 pt solid black',
                    borderRight: '1 pt solid black',
                    borderBottom: '1 pt solid black',
                  },
                ]}
              >
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    { borderRight: '1 pt solid black', padding: 3, flex: 0.5 },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.3,
                    },
                  ]}
                >
                  {(Number(productDetailEdges[0].node?.gstRate) / 2).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.3,
                    },
                  ]}
                >
                  {(Number(productDetailEdges[0].node?.gstRate) / 2).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.lowFlexInlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.5,
                    },
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.8,
                    },
                  ]}
                >
                  {extraCharges.toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {(gstValueOnExtraCharges / 2).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    {
                      borderRight: '1 pt solid black',
                      padding: 3,
                      flex: 0.6,
                    },
                  ]}
                >
                  {(gstValueOnExtraCharges / 2).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.inlineHead,
                    style.textRight,
                    { padding: 3, flex: 0.9 },
                  ]}
                >
                  {(extraCharges + gstValueOnExtraCharges).toFixed(2)}
                </Text>
              </View>
              <View
                style={[
                  style.section,
                  {
                    marginTop: 0,
                    borderLeft: '1 pt solid black',
                    borderRight: '1 pt solid black',
                    borderBottom: '1 pt solid black',
                  },
                ]}
              >
                <View style={[style.inline, { marginTop: 0 }]}>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.lowFlexInlineHead,
                      {
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 1,
                      },
                    ]}
                  >
                    Grand Total
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 7,
                        flex: 0.1,
                      },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.lowFlexInlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.5,
                      },
                    ]}
                  >
                    {sumBy(
                      props.saleChallan.hsnGstWiseAmount,
                      (obj) => obj.quantity || 0,
                    ).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.8,
                      },
                    ]}
                  >
                    {(
                      sumBy(
                        props.saleChallan.hsnGstWiseAmount,
                        (obj) => obj.amount || 0,
                      ) + extraCharges
                    ).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    {(
                      (Number(
                        sumBy(
                          props.saleChallan.hsnGstWiseAmount,
                          (obj) =>
                            (obj.amount &&
                              obj.gst &&
                              obj.amount * (obj.gst / 100)) ||
                            0,
                        ),
                      ) +
                        gstValueOnExtraCharges) /
                      2
                    ).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        borderRight: '1 pt solid black',
                        borderBottom: '1 pt solid black',
                        padding: 3,
                        flex: 0.6,
                      },
                    ]}
                  >
                    {(
                      (Number(
                        sumBy(
                          props.saleChallan.hsnGstWiseAmount,
                          (obj) =>
                            (obj.amount &&
                              obj.gst &&
                              obj.amount * (obj.gst / 100)) ||
                            0,
                        ),
                      ) +
                        gstValueOnExtraCharges) /
                      2
                    ).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      style.darkBlackText,
                      style.inlineHead,
                      style.textRight,
                      {
                        padding: 3,
                        borderBottom: '1 pt solid black',
                        flex: 0.9,
                      },
                    ]}
                  >
                    {(
                      Number(props.saleChallan.totalAmount) +
                      Number(totalGstValue)
                    ).toFixed(2)}
                  </Text>
                </View>
              </View>
              <View style={[style.section, style.inline]}>
                <Text
                  style={[
                    style.inlineHead,
                    style.darkBlackText,
                    style.textRight,
                  ]}
                >
                  For {props.company.name.replace('(tiles)', '')}
                </Text>
              </View>
              <View style={[style.section, style.inline, style.marginTop]}>
                <Text style={[style.inlineHead, style.darkBlackText]}>
                  Received By
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.darkBlackText,
                    style.textCenter,
                  ]}
                >
                  Checked By
                </Text>
                <Text
                  style={[
                    style.inlineHead,
                    style.darkBlackText,
                    style.textRight,
                  ]}
                >
                  Authorized Signatory
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 40 },
  position: { top: 200 },
});

export interface InvoiceHindustanTilesProps {
  saleChallan: SaleChallanNode;
  company: CompanyNodeEdge;
}

import * as React from 'react';
import { ProductNodeEdge, productQuery } from './constants';
import { ProductNodeEdge as ProductEdges } from '../../../schema';
import { Tabs, Icon, Skeleton, Row, Col, Button } from 'antd';
import { AddProduct } from './AddProduct';
import { checkAddPermission, downloadToFile } from '../../helper';
import { Table, ColumnProps } from '../../common/Table';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { ProductNodeConnection } from '../../../schema';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../../common/TSInput';
import { ProductCategoryService } from '../../../service/ProductCategoryService';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { ReorderReport } from './ReorderReport';
import { saveSvgAsPng } from 'save-svg-as-png';
import { ItemWiseReceiptAndSalesStatusReport } from './ItemWiseReceiptAndSalesStatusReport';
import { CreateBarCode } from './CreateBarCode';
import BarCode from 'jsbarcode';
import { UserNodeEdge } from '../../../components/user/constants';
import { UserService } from '../../../service/UserService';
import TextArea from 'antd/lib/input/TextArea';
import { PRODUCT_LIST_CSV, PRODUCT_LIST_TALLY_CSV } from '../../../config';
import moment from 'moment';

export class Product extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      activeKey: '1',
      selectedProduct: null,
      isViewActive: false,
      company: null,
      currentPage: 1,
      after: '',
      barcode: '',
      productCategories: [],
      sellingRate: '',
      purchaseRate: '',
      user: null,
      isLoading: false,
      loadingMessage: '',
    };
  }

  companyService = new CompanyService();
  productCategoryService = new ProductCategoryService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.productCategoryService?.productCategory$.subscribe(
      (productCategories) => this.setState({ productCategories }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedProduct: null });
      this.componentDidMount();
      return;
    }
    if (activeKey === '3') {
      setTimeout(() => this.updateBarCode(), 1000);
    }
    this.setState({ activeKey });
  };

  productColumn = () => {
    const productColumns: ColumnProps<ProductEdges>[] = [
      {
        title: 'Name',
        dataIndex: 'node.name',
        key: 'name',
        render: (text, obj: ProductEdges) => {
          if (
            this.companyService?.selectedBussiness$.value?.type === 'branch'
          ) {
            return text;
          } else {
            return (
              <a
                onClick={() => {
                  this.setState({
                    selectedProduct: {
                      cursor: obj.cursor,
                      node: obj.node as unknown as ProductNodeEdge,
                    },
                    activeKey: '2',
                  });
                }}
              >
                {text}
              </a>
            ) as unknown;
          }
        },
        sortingValue: (text) => text,
      },
      {
        title: 'Category',
        dataIndex: 'node.category.name',
        key: 'category',
      },
      {
        title: 'Sub Category',
        dataIndex: 'node.subCategory.name',
        key: 'subCategory',
      },
      {
        title: 'Unit',
        dataIndex: 'node.unit.name',
        key: 'unit',
      },
      {
        title: 'Colour',
        dataIndex: 'node.colour',
        key: 'colour',
      },
      {
        title: 'Purchasing Rate',
        dataIndex: 'node.purchaseRate',
        key: 'purchaseRate',
      },
      {
        title: 'Selling Rate',
        dataIndex: 'node.sellingRate',
        key: 'sellingRate',
      },
    ];
    if (this.state.company?.name.toLowerCase().includes('pearl')) {
      productColumns.push(
        {
          title: 'HSN',
          key: 'hsnCode',
          render: () => this.state.company?.hsnCode,
        },
        {
          title: 'GST',
          key: 'gstRate',
          render: () => this.state.company?.gstRate,
        },
      );
    } else {
      productColumns.push(
        {
          title: 'HSN',
          dataIndex: 'node.hsn.hsnCode',
          key: 'hsnCode',
        },
        {
          title: 'GST',
          dataIndex: 'node.hsn.gst',
          key: 'gstRate',
        },
      );
    }
    productColumns.push(
      {
        title: 'Old Barcode Id',
        dataIndex: 'node.oldBarcodeId',
        key: 'oldBarcodeId',
      },
      {
        title: 'New Barcode Id',
        dataIndex: 'node.newBarcodeId',
        key: 'newBarcodeId',
      },
      {
        title: 'Design',
        dataIndex: 'node.design',
        key: 'design',
      },
      {
        title: 'Dimension',
        dataIndex: 'node.dimension',
        key: 'dimension',
      },
      {
        title: 'Division',
        dataIndex: 'node.division',
        key: 'division',
      },
      {
        title: 'Material Type',
        dataIndex: 'node.materialType',
        key: 'materialType',
      },
      {
        title: 'Section',
        dataIndex: 'node.section',
        key: 'section',
      },
      {
        title: 'Society/Vendor',
        dataIndex: 'node.vendor.name',
        key: 'vendor',
      },
    );
    if (this.state.company?.name.toLowerCase().includes('tiles')) {
      productColumns.push({
        title: 'Quantity For 1 Piece',
        dataIndex: 'node.quantityPerPiece',
        key: 'quantityPerPiece',
      });
    }
    if (this.companyService?.selectedBussiness$.value?.type === 'company') {
      productColumns.push({
        title: 'Bar code',
        key: 'bar',
        render: (data, record: ProductEdges) => (
          <a
            onClick={() =>
              this.setState(
                {
                  selectedProduct: {
                    cursor: record.cursor,
                    node: record.node as unknown as ProductNodeEdge,
                  },
                },
                () => this.handleTabChange('3'),
              )
            }
          >
            <Icon type="download" />
          </a>
        ),
      });
    }
    return productColumns;
  };
  /**
   * Function to generate QR Code.
   */
  updateQrCode = () => {
    const svg = document.getElementById(
      `qr-code${this.state.selectedProduct?.node.id}`,
    ) as unknown as SVGElement;
    const nameTextFirst = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const nameTextSecond = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const oldBarcodeIdText = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const newBarcodeIdText = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const priceText = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );

    nameTextFirst.innerHTML = `${this.state.selectedProduct?.node.name.slice(
      0,
      35,
    )}-`;
    nameTextSecond.innerHTML = `${this.state.selectedProduct?.node.name.slice(
      35,
    )}`;
    oldBarcodeIdText.innerHTML =
      this.state.selectedProduct?.node.oldBarcodeId || '';
    newBarcodeIdText.innerHTML =
      this.state.selectedProduct?.node.newBarcodeId || '';
    priceText.innerHTML = `MRP: Rs.${this.state.selectedProduct?.node.sellingRate}/-`;

    nameTextFirst.setAttribute('x', '0.5');
    nameTextFirst.setAttribute('y', '1.3');
    nameTextSecond.setAttribute('x', '0.5');
    nameTextSecond.setAttribute('y', '2.6');
    oldBarcodeIdText.setAttribute('x', '0.8');
    oldBarcodeIdText.setAttribute('y', '26.5');
    priceText.setAttribute('x', '6');
    priceText.setAttribute('y', '28.5');
    newBarcodeIdText.setAttribute('x', '22');
    newBarcodeIdText.setAttribute('y', '26.5');

    nameTextFirst.style.fontSize = '1.3px';
    nameTextSecond.style.fontSize = '1.3px';
    oldBarcodeIdText.style.fontSize = '1.5px';
    priceText.style.fontSize = '2px';
    newBarcodeIdText.style.fontSize = '1.5px';

    svg.appendChild(nameTextFirst);
    svg.appendChild(nameTextSecond);
    svg.appendChild(oldBarcodeIdText);
    svg.appendChild(priceText);
    svg.appendChild(newBarcodeIdText);
  };
  /**
   * Function to generate Bar Code.
   */
  updateBarCode = () => {
    BarCode('#barcode', `${this.state.selectedProduct?.node.newBarcodeId}`, {
      marginTop: 45,
      marginBottom: 55,
      font: 'monospace',
      height: this.state.company?.name.toLowerCase().includes('kraftribe')
        ? 70
        : 50,
      width: this.state.company?.name.toLowerCase().includes('kraftribe')
        ? 5
        : 3,
    });
    const svg = document.getElementById(`barcode`) as unknown as SVGElement;
    const category = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const companyName = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );
    const mrp = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    const barcodeText = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text',
    );

    barcodeText.innerHTML = this.state.barcodeText || '';

    companyName.setAttribute('x', '35%');
    companyName.setAttribute('y', '16');
    category.setAttribute('x', '0%');
    category.setAttribute('y', '40');
    category.style.fontWeight = '600';
    category.style.fontSize = this.state.company?.name
      .toLowerCase()
      .includes('kraftribe')
      ? '28px'
      : '16px';
    category.style.fontWeight = '600';
    companyName.style.fontSize = this.state.company?.name
      .toLowerCase()
      .includes('kraftribe')
      ? '24px'
      : '16px';
    companyName.style.fontWeight = '900';
    mrp.setAttribute('x', '10');
    mrp.setAttribute('y', '84%');
    mrp.style.fontSize = this.state.company?.name
      .toLowerCase()
      .includes('kraftribe')
      ? '34px'
      : '20px';
    mrp.style.fontWeight = '600';
    barcodeText.setAttribute('x', '0%');
    barcodeText.setAttribute('y', '95%');
    barcodeText.style.fontSize = this.state.company?.name
      .toLowerCase()
      .includes('kraftribe')
      ? '24px'
      : '16px';
    barcodeText.style.fontWeight = '700';

    const companyText = document.createTextNode(
      `${this.state.company?.name?.toUpperCase()}`,
    );
    const categoryText = document.createTextNode(
      `${this.state.selectedProduct?.node.category?.name}--${this.state.selectedProduct?.node.name}`,
    );
    const mrpText = document.createTextNode(
      `MRP: Rs. ${this.state.selectedProduct?.node.sellingRate} / ${this.state.selectedProduct?.node.unit?.name}`,
    );
    companyName.appendChild(companyText);
    category.appendChild(categoryText);
    mrp.appendChild(mrpText);

    svg.appendChild(companyName);
    svg.appendChild(category);
    svg.appendChild(mrp);
    svg.appendChild(barcodeText);
  };

  downloadQR = () => {
    saveSvgAsPng(
      document.getElementById(`qr-code${this.state.selectedProduct?.node.id}`),
      `${this.state.selectedProduct?.node.oldBarcodeId}-${this.state.selectedProduct?.node.oldBarcodeId}.png`,
      { scale: 10 },
    );
  };

  downloadBar = () => {
    saveSvgAsPng(
      document.getElementById(`barcode`),
      `${this.state.selectedProduct?.node.oldBarcodeId}.png`,
      { scale: 1.5 },
    );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  downloadCsv = () => {
    const company = { ...this.state.company };
    const bussiness = { ...this.companyService?.selectedBussiness$.value };

    this.setState({ isLoading: true, loadingMessage: 'Please Wait' });

    fetch(PRODUCT_LIST_CSV + '?' + `companyId=${company?.id}`)
      .then((value) => value.text())
      .then((csv) => {
        if (csv === 'Wait') {
          this.setState({
            loadingMessage:
              'Generating data. You can downlaod it after 5 to 10 mins',
          });
        } else if (csv === 'Creating') {
          this.setState({
            loadingMessage:
              'You have already requested for this data. Please wait!',
          });
        } else {
          const data = csv.split('####');
          downloadToFile(
            data[0],
            `product-list-${company?.name}-${new Date()}-${btoa(data[1])}`,
            'PRODUCT LIST',
            company?.name || '',
            (bussiness.type === 'branch' ? bussiness.name : 'Head Office') ||
              '',
            moment().format('DD-MM-YYYY'),
            moment().format('DD-MM-YYYY'),
            '',
          );
          this.setState({ loadingMessage: '' });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  downloadCsvTally = () => {
    const company = { ...this.state.company };
    const bussiness = { ...this.companyService?.selectedBussiness$.value };

    this.setState({ isLoading: true, loadingMessage: 'Please Wait' });

    fetch(PRODUCT_LIST_TALLY_CSV + '?' + `companyId=${company?.id}`)
      .then((value) => value.text())
      .then((data) => {
        downloadToFile(
          data,
          `product-list-tally-${company?.name}-${new Date()}-${btoa(data[1])}`,
          'PRODUCT LIST TALLY',
          company?.name || '',
          (bussiness.type === 'branch' ? bussiness.name : 'Head Office') || '',
          moment().format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
          '',
        );
        this.setState({ loadingMessage: '' });
      })
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;

    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      sellingRate,
      purchaseRate,
    } = this.state;
    const variables = {
      companyId,
      first: 20,
      after,
      activeKey,
      sellingRate,
      purchaseRate,
    };

    !sellingRate ? delete variables.sellingRate : Number(sellingRate);
    !purchaseRate ? delete variables.purchaseRate : Number(purchaseRate);

    const { barcode } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Product
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.barcode}
                onChange={(e) => this.setState({ barcode: e.target.value })}
                name="barcode"
                type="text"
                addonBefore="Search Product"
                placeholder="Enter Barcode or Name"
              />
            </Col>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.sellingRate}
                onChange={(e) => this.setState({ sellingRate: e.target.value })}
                name="sellingRate"
                type="number"
                addonBefore="Selling Rate"
                placeholder="Enter Selling Rate"
              />
            </Col>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.purchaseRate}
                onChange={(e) =>
                  this.setState({ purchaseRate: e.target.value })
                }
                name="purchaseRate"
                type="number"
                addonBefore="Purchasing Rate"
                placeholder="Enter Purchasing Rate"
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={productQuery}
            variables={barcode ? { ...variables, barcode } : variables}
            render={(
              response: { allProducts: ProductNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProducts.edges}
                    columns={this.productColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                    scroll={{ x: true }}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={response.allProducts.totalCount || 0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProducts.pageInfo.endCursor,
                        response.allProducts.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                  this.state.company?.name
                    .toLowerCase()
                    .includes('jharcraft') ||
                  this.state.company?.name.toLowerCase().includes('khadi') ? (
                    <>
                      <Button
                        key="download"
                        style={{ float: 'right' }}
                        icon="download"
                        onClick={() => this.downloadCsv()}
                        disabled={this.state.isLoading}
                      />
                      <span style={{ float: 'right', margin: '7px' }}>
                        {this.state.loadingMessage}
                      </span>
                    </>
                  ) : (
                    this.state.company?.name
                      .toLowerCase()
                      .includes('sugar') && (
                      <>
                        <Button
                          key="download"
                          style={{ float: 'right' }}
                          icon="download"
                          onClick={() => this.downloadCsvTally()}
                          disabled={this.state.isLoading}
                        />
                        <span style={{ float: 'right', margin: '7px' }}>
                          Product List (Tally)
                          {this.state.loadingMessage}
                        </span>
                      </>
                    )
                  ),
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.companyService?.selectedBussiness$.value?.type !== 'branch' && [
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Product
              </span>
            }
            key="2"
          >
            <AddProduct
              product={this.state.selectedProduct?.node}
              products={this.state.products}
              handleTabChange={this.handleTabChange}
              company={this.state.company}
              productCategories={this.state.productCategories}
            />
          </Tabs.TabPane>,
          this.state.activeKey === '3' && (
            <Tabs.TabPane tab={<span>Bar Code</span>} key="3" closable={true}>
              <CreateBarCode downloadBar={this.downloadBar} />
              <TextArea
                placeholder="Enter text to insert in Barcode"
                value={this.state.barcodeText}
                onChange={(e) =>
                  this.setState(
                    { barcodeText: e.target.value },
                    this.updateBarCode,
                  )
                }
              />
            </Tabs.TabPane>
          ),
          (this.state.company.name.includes('Seva Sadan') ||
            this.state.company.name.toLowerCase().includes('tiles')) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Reorder Report
                </span>
              }
              key="4"
              closable={false}
            >
              <ReorderReport
                categories={this.state.productCategories}
                company={this.state.company}
              />
            </Tabs.TabPane>
          ),
          (this.state.company.name.toLowerCase().includes('jharcraft') ||
            this.state.company?.name.toLowerCase().includes('khadi')) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Item Wise Receipt And Sales Status Report
                </span>
              }
              key="5"
              closable={false}
            >
              <ItemWiseReceiptAndSalesStatusReport
                company={this.state.company}
                productCategories={this.state.productCategories}
              />
            </Tabs.TabPane>
          ),
        ]}
      </Tabs>
    );
  }
}

interface State {
  products: {
    cursor: string;
    node: ProductNodeEdge;
  }[];
  activeKey: string;
  selectedProduct: {
    cursor: string;
    node: ProductNodeEdge;
  } | null;
  isViewActive: boolean;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  barcode: string;
  productCategories: ProductCategoryEdges[];
  sellingRate?: string;
  purchaseRate?: string;
  user?: UserNodeEdge | null;
  barcodeText?: string;
  isLoading: boolean;
  loadingMessage: string;
}

interface Props {}

import Api from 'requestapijs';
import {
  AddProductVariables,
  ProductNodeEdge,
  productMutation,
} from '../components/master/Product/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class ProductService {
  static instance;

  constructor() {
    if (ProductService.instance) {
      return ProductService.instance;
    } else {
      ProductService.instance = this;
    }
  }

  companyService = new CompanyService();

  addProduct(
    data: ProductNodeEdge,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    Api.graphql<AddProductVariables, {}>(
      API_URL,
      {
        input: {
          product: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      productMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

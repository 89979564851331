import { DistrictInput } from '../../../schema';

export interface DistrictError {
  name: string;
  stateId: string;
}

export const DISTRICT_DATA: DistrictInput = {
  name: '',
  stateId: '',
};

export const DISTRICT_ERROR: DistrictError = {
  name: '',
  stateId: '',
};

export const districtMutation = `
mutation addDistrict ($input: CreateDistrictInput!) {
  createDistrict (input: $input) {
    newDistrict {
      id
      name
    }
  }
}
`;

export const districtQuery = `
query District($after: String) {
  allDistricts (first: 20, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        state {
          id
          name
        }
      }
    }
  }
}
`;

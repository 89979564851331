import { CompanyNodeEdge } from '../master/Company/constants';
import { CustomerNodeEdge } from '../master/Customer/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import moment from 'moment';

export interface GiftNodeEdgeQuery {
  id?: string;
  date: string;
  customer?: CustomerNodeEdge;
  productDetails?: ProductDetailsNodeConnection;
  giftReceiptNo: string;
  branchSerialNo: string;
  totalCostAmount: number;
  totalMrp: number;
  orderByDesignation: string;
  orderByName: string;
  company?: CompanyNodeEdge;
  branch?: BranchNodeEdge;
}

export interface GiftNodeEdgeMutation {
  id?: string;
  date: string;
  customerId?: string | null;
  productDetails?: ProductDetailNodeEdge[];
  giftReceiptNo: string | null;
  branchSerialNo: string | null;
  totalCostAmount: number | null;
  totalMrp: number | null;
  orderByDesignation: string | null;
  orderByName: string | null;
  companyId?: string | null;
  branchId?: string | null;
}

export const giftObject: GiftNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: null,
      quantity: null,
      amount: 0,
      movementForId: '',
      movementType: 'GIFT',
      companyId: '',
    },
  ],
  giftReceiptNo: null,
  branchSerialNo: null,
  totalCostAmount: null,
  totalMrp: null,
  orderByDesignation: null,
  orderByName: null,
  companyId: null,
  branchId: null,
};

export const giftQuery = `
  query GiftList($companyId: ID!, $branchId: ID, $date_Lte: Date!) {
    allGifts(companyId: $companyId, branchId: $branchId, date_Lte: $date_Lte) {
      edges {
        node {
          id
          date
          customer {
            id
            name
            contactNo
          }
          productDetails {
            edges {
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  category {
                    id
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                movementType
                movementFor {
                  id
                  name
 
                }
              }
            }
          }
          giftReceiptNo
          branchSerialNo
          totalCostAmount
          totalMrp
          orderByDesignation
          orderByName
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export const giftReportQuery = `
query GiftReport($companyId: ID, $branchId: ID, $after: String) {
  allProductDetails(
    companyId: $companyId,
    movementForId: $branchId,
    movementType: "GIFT",
    first: 20,
    after: $after,
    orderBy: "-date",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
        }
        quantity
        rate
        amount
        giftSet {
          edges {
            node {
              giftReceiptNo
              date
              orderByName
              orderByDesignation
              customer {
                name
              }
            }
          }
        }
      }
    }
  }
}`;

export interface GiftQueryVariable {
  companyId: string;
  branchId: string;
  date_Lte?: string;
}

export interface GiftQueryResponse {
  data: {
    allGifts: {
      edges: GiftEdges[];
    };
  };
}

export interface GiftEdges {
  cursor: string;
  node: GiftNodeEdgeQuery;
}

export interface AddGiftVariable {
  input: {
    gift: GiftNodeEdgeMutation;
  };
}

export const giftMutation = `
  mutation addGift(
    $input: CreateGiftInput!
  ) {
    createGift(input: $input){
      newGift {
        id
        date
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { JobOrderNodeConnection, JobOrderNodeEdge } from '../../schema';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  jobOrder?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface JobOrderDropDownProps extends AutoCompleteProps {
  extrafields: string[];
  activeKey?: string;
  toVendor?: boolean;
}

export class JobOrderDropDown extends React.Component<
  JobOrderDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      jobOrderNo: '',
      company: null,
      touched: false,
    };
  }

  companyService = new CompanyService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
  }

  getOrderNumber = (jobOrder?: JobOrderNodeEdge) => {
    if (!jobOrder) {
      return;
    }
    return jobOrder.node?.jobOrderNo;
  };

  value = (jobOrders: JobOrderNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.jobOrderNo || '';
    }
    return this.getOrderNumber(
      jobOrders.find((v) => v.node?.id === this.props.value),
    );
  };

  render() {
    const { jobOrderNo, company } = this.state;
    const { extrafields, value, onSelect, activeKey, toVendor } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query JobOrderDropDown(
          $after: String!,
          $id: ID,
          $jobOrderNo: String,
          $companyId: ID,
          $toVendor: Boolean,
          $category: String,
        ) {
          allJobOrders(
            companyId: $companyId,
            first: 10,
            id: $id,
            jobOrderNo_Icontains: $jobOrderNo,
            after: $after,
            transfers_ToVendor: $toVendor,
            transfers_ToLocation_Category_In: $category,
          ) {
            edges {
              node {
                id
                date
                jobOrderNo
                orderFor {
                  edges {
                    node {
                      id
                      date
                      conversion {
                        id
                        finishedProduct
                        designCode
                        oldDesignCode
                        netCost
                        rawMaterials {
                          edges {
                            node {
                              id
                              product {
                                id
                                name
                              }
                              rate
                              purchaseRate
                              quantity
                              wastage
                              gstRate
                            }
                          }
                        }
                        productionType
                      }
                      quantity
                      cost
                      amount
                      conversionType
                      quantityReceived
                    }
                  }
                }
                transfers(first: 1) {
                  edges {
                    node {
                      id
                      toHeadOffice
                      toLocation {
                        id
                        name
                      }
                      remarks
                      fromVendor
                      toVendor
                      vendorFor {
                        id
                        name
                      }
                      productDetails {
                        edges {
                          node {
                            id
                            product {
                              id
                              purchaseRate
                            }
                            movementFor {
                              id
                            }
                            movementFrom {
                              id
                            }
                            rate
                            purchaseRate
                            quantity
                            amount
                            vendorFor {
                              id
                            }
                            availableQuantityForJobOrder {
                              jobOrderId
                              quantity
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`}
        variables={{
          after: '',
          jobOrderNo,
          companyId: company.id,
          activeKey,
          id: value && !jobOrderNo ? value : '',
          toVendor,
          category: toVendor === false ? 'PWCS,SHGS' : '',
        }}
        render={(
          response: { allJobOrders: JobOrderNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <h3>Something went wrong</h3>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Job Order"
                dataSource={response.allJobOrders.edges.map((jobOrder) => (
                  <Option
                    key={jobOrder.node?.id}
                    label={jobOrder.node}
                    jobOrder={jobOrder}
                  >
                    {jobOrder.node?.jobOrderNo}
                  </Option>
                ))}
                value={this.value(response.allJobOrders.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(jobOrder) =>
                  this.setState({ jobOrderNo: jobOrder, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    jobOrderNo:
                      this.getOrderNumber((f as any).props.jobOrder) || null,
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  company?: CompanyNodeEdge | null;
  jobOrderNo: string | null;
  touched: boolean;
}

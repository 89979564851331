import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  VendorEdges,
  VendorQueryResponse,
  vendorQuery,
  VendorQueryVariable,
  AddVendorVariables,
  vendorMutation,
  VendorNode,
} from '../components/master/vendor/constants';

export class VendorService {
  static instance: VendorService;
  constructor() {
    if (VendorService.instance) {
      return VendorService.instance;
    } else {
      VendorService.instance = this;
    }
    this.onInit();
  }

  public readonly vendor$ = new BehaviorSubject<VendorEdges[]>([]);
  private readonly token = localStorage.getItem('token');
  compnayService = new CompanyService();

  onInit() {
    this.compnayService?.company$.subscribe(
      (company) => company?.id && this.getAll(company.id),
    );
  }

  getAll(companyId: string) {
    Api.graphql<VendorQueryVariable, VendorQueryResponse>(
      API_URL,
      { companyId },
      vendorQuery,
      HEADER,
      (response) => this.vendor$.next(response.data.allVendors.edges),
      (error) => error,
    );
  }

  addVendor(
    data: VendorNode,
    onSuccess?: (response) => void,
    onError?: (error) => void,
  ) {
    delete data.company;
    Api.graphql<AddVendorVariables, {}>(
      API_URL,
      {
        input: {
          vendor: {
            ...data,
            companyId: this.compnayService?.company$.value?.id,
          },
        },
      },
      vendorMutation,
      HEADER,
      (response) => {
        this.onInit();
        onSuccess && onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }
}

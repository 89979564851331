import * as React from 'react';
import { QuotationInput, ProductNode, CustomerNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';
import { Row, Col, DatePicker, Divider, Button, Icon, Radio } from 'antd';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { TSInput } from '../common/TSInput';
import moment from 'moment';
import { CustomerDropDown } from '../master/Customer/CustomerDropdown';
import { QuotationError } from './constants';
import { Label } from '../common/TSLabel';

export const QuotationForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={4}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
        <span style={{ color: 'red' }}>{props.error.date}</span>
      </Col>

      <Col span={10}>
        <CustomerDropDown
          onSelect={(e, f) => {
            props.customerObjChangehandler((f as any).props.label);
            props.onChange({
              target: { name: 'customerId', value: String(e) },
            });
          }}
          allowClear
          company={props.company}
          value={props.values.customerId || ''}
          updateCount={props.updateCount}
        />
        <span style={{ color: 'red' }}>{props.error.customerId}</span>
      </Col>
      <Col span={1}>
        <Button
          children={<Icon type="plus" />}
          type="primary"
          onClick={() => props.handleModalAction('ADD_CUSTOMER')}
          style={{ marginTop: '5px' }}
        />
      </Col>
      <Col span={1}>
        <Button
          children={<Icon type="edit" />}
          type="primary"
          onClick={() => props.handleModalAction('LIST_CUSTOMER')}
          style={{ marginTop: '5px' }}
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={8}>Product</Col>
        <Col span={4}>Quantity</Col>
        <Col span={4}>Rate</Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={8}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.productChangeHandler(
                    { target: { name: 'productId', value: String(e) } },
                    index,
                  );
                  props.productObjChangehandler((f as any).props.label, index);
                }}
                allowClear
                extrafields={['sellingRate', 'purchaseRate']}
                company={props.company}
                value={product.productId || ''}
                productTypeIn="FINISHED"
              />
              <span style={{ color: 'red' }}>
                {props.error.productDetails[index].productId}
              </span>
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.productDetails[index].quantity}
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.productChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.productDetails[index].rate}
                type="number"
                value={product.rate || ''}
                name="rate"
                placeholder="Rate"
                required
                onChange={(e) => {
                  props.productChangeHandler(e, index);
                }}
              />
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.productDetails[index].amount}
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateProductsRow('-', index)}
                disabled={props.values.productDetails.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateProductsRow('+', index)}
                disabled={product.productId && product.quantity ? false : true}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />
    </Row>
  );
};

interface Props {
  values: QuotationInput;
  error: QuotationError;
  company: CompanyNodeEdge;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangehandler: (productObj: ProductNode, index: number) => void;
  customerObjChangehandler: (value: CustomerNode) => void;
  handleModalAction: (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => void;
  updateCount: number;
}

import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import moment from 'moment';

export interface OpeningStockNode<T = ProductDetailsNodeConnection> {
  id?: string;
  company?: CompanyNodeEdge;
  companyId?: string | null;
  branch?: BranchNodeEdge;
  branchId?: string | null;
  productDetails: T;
  date?: string;
}

export const openingStockObject: OpeningStockNode<ProductDetailNodeEdge[]> = {
  companyId: null,
  branchId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      quantity: 0,
      amount: 0,
      movementForId: '',
      movementType: 'OPENING_STOCK',
      companyId: '',
    },
  ],
  date: moment().format('YYYY-MM-DD'),
};

export interface OpeningStockQueryVariable {
  companyId: string;
  branchId?: string;
  date_Lte: string;
}

export interface OpeningStockQueryResponse {
  data: {
    allOpeningStocks: {
      edges: OpeningStockEdges[];
    };
  };
}

export interface OpeningStockEdges {
  cursor?: string;
  node: OpeningStockNode;
}

export interface AddOpeningStockVariable {
  input: {
    openingStock: OpeningStockNode<ProductDetailNodeEdge[]>;
  };
}

export const OpeningStockMutation = `
  mutation addOpeningStock($input: CreateOpeningStockInput!) {
    createOpeningStock(input: $input) {
      newOpeningStock {
        id
        date
      }
    }
  }
`;

export const productCategoryQuery = `
query ProductCategoriesList($companyId: ID, $after: String!, $orderBy: String) {
  allProductCategories(companyId: $companyId, first: 20, after: $after, orderBy: $orderBy) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
      }
    }
  }
}
`;

export const productQuery = `
query ProductList($companyId: ID!, $categoryId: ID, $after: String, $branchId: ID) {
  allProducts(companyId: $companyId, first: 20, categoryId: $categoryId, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        oldBarcodeId
        newBarcodeId
        purchaseRate
        openingStock(branchId: $branchId, companyId: $companyId)
      }
    }
  }
}
`;

export const SetDefaultOpeningStockMutation = `
mutation SetDefaultOpeningStockMutation($input: SetDefaultOpeningStockInput!) {
  setDefaultOpeningStock(input: $input) {
    response
  }
}
`;

import { BranchNodeEdge } from '../master/Branch/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import moment from 'moment';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  CommissionDetailsNode,
  CommissionDetailsNodeConnection,
  VendorNode,
} from '../../schema';

export interface TransferNode<
  T = ProductDetailsNodeConnection,
  C = CommissionDetailsNodeConnection,
> {
  id?: string;
  date: string;
  fromHeadOffice: boolean;
  toHeadOffice: boolean;
  fromLocation?: BranchNodeEdge;
  fromLocationId?: string | null;
  toLocation?: BranchNodeEdge;
  toLocationId?: string | null;
  hsnGstWiseAmount?: {
    hsnCode: string;
    gst: number;
    quantity: number;
    amount: number;
  }[];
  hsnGstWiseAmountReceiving?: {
    hsnCode: string;
    gst: number;
    quantity: number;
    amount: number;
  }[];
  productDetails: T;
  status?: 'PENDING' | 'REJECTED' | 'ACCEPTED' | 'PARTIALLY_ACCEPTED';
  isTransfer: boolean;
  remarks: string | null;
  transferInvoiceNo?: string | null;
  receivingDate?: string | null;
  fromVendor: boolean;
  toVendor: boolean;
  vendorFor?: VendorNode;
  vendorForId?: string;
  company?: CompanyNodeEdge;
  companyId?: string | null;
  commissionDetails?: C;
}

export interface TransferError {
  date: string;
  fromLocationId?: string;
  toLocationId?: string;
  productDetails: {
    productId: string;
    purchaseRate: string;
    quantity: string;
    amount: string;
  }[];
  isTransfer: string;
  status: string;
}

export const transferNode: TransferNode<
  ProductDetailNodeEdge[],
  CommissionDetailsNode[]
> = {
  date: moment().format('YYYY-MM-DD'),
  fromHeadOffice: false,
  toHeadOffice: false,
  fromLocationId: null,
  toLocationId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      quantity: 0,
      amount: 0,
      gstRate: 0,
      movementForId: '',
      movementFromId: '',
      movementType: 'DOWN',
      status: 'PENDING',
      companyId: null,
    },
  ],
  status: 'PENDING',
  isTransfer: true,
  remarks: null,
  transferInvoiceNo: null,
  receivingDate: null,
  fromVendor: false,
  toVendor: false,
  companyId: null,
  commissionDetails: [],
};

export const transferError: TransferError = {
  date: '',
  fromLocationId: '',
  toLocationId: '',
  productDetails: [
    {
      productId: '',
      purchaseRate: '',
      quantity: '',
      amount: '',
    },
  ],
  isTransfer: '',
  status: '',
};

export interface TransferQueryVariables {
  companyId: string;
  fromLocationId: string;
  fromHeadOffice: boolean;
  date_Lte: string;
  jobOrderId_Isnull: boolean;
}

export const rejectedTransferQuery = `
  query TransferList(
    $fromLocationId: ID,
    $companyId: ID,
    $fromHeadOffice: Boolean,
    $date_Lte: Date!,
    $jobOrderId_Isnull: Boolean,
    $after: String,
    $invoiceNo: String,
    $status: String,
  ) {
    allTransfers(
      fromLocationId: $fromLocationId,
      isTransfer: true,
      companyId: $companyId,
      fromHeadOffice: $fromHeadOffice,
      date_Lte: $date_Lte,
      jobOrderId_Isnull: $jobOrderId_Isnull,
      first: 20,
      after: $after,
      transferInvoiceNo_Icontains: $invoiceNo,
      status_In: $status,
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          date
          fromHeadOffice
          toHeadOffice
          fromLocation {
            id
            name
            address
            gstin
            state
            category
          }
          toLocation {
            id
            name
            address
            gstin
            state
            category
          }
          fromVendor
          toVendor
          vendorFor {
            id
            name
            address
            gstin
            state
          }
          isTransfer
          remarks
          status
          transferInvoiceNo
          hsnGstWiseAmount {
            hsnCode
            gst
            quantity
            amount
          }
          productDetails {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  unit {
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                gstRate
                movementType
                status
                remark
                movementFor {
                  id
                  name
                }
                movementFrom {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const transferQuery = `
  query TransferList(
    $fromLocationId: ID,
    $companyId: ID,
    $fromHeadOffice: Boolean,
    $date_Lte: Date!,
    $jobOrderId_Isnull: Boolean,
    $after: String,
    $invoiceNo: String,
  ) {
    allTransfers(
      fromLocationId: $fromLocationId,
      isTransfer: true,
      companyId: $companyId,
      fromHeadOffice: $fromHeadOffice,
      date_Lte: $date_Lte,
      jobOrderId_Isnull: $jobOrderId_Isnull,
      first: 20,
      after: $after,
      transferInvoiceNo_Icontains: $invoiceNo,
      status_In: "PENDING,REJECTED,ACCEPTED,PARTIALLY_ACCEPTED",
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          date
          fromHeadOffice
          toHeadOffice
          fromLocation {
            id
            name
            address
            gstin
            state
            category
          }
          toLocation {
            id
            name
            address
            gstin
            state
            category
          }
          fromVendor
          toVendor
          vendorFor {
            id
            name
            address
            gstin
            state
          }
          isTransfer
          remarks
          status
          transferInvoiceNo
          hsnGstWiseAmount {
            hsnCode
            gst
            quantity
            amount
          }
          productDetails {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  unit {
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                gstRate
                movementType
                status
                remark
                movementFor {
                  id
                  name
                }
                movementFrom {
                  id
                  name
                }
              }
            }
          }
          commissionDetails {
            edges {
              node {
                category
                description
                descriptionPercent
                descriptionValue
              }
            }
          }
        }
      }
    }
  }
`;

export const transferReturnQuery = `
  query TransferReturnList($fromLocationId: ID, $companyId: ID, $fromHeadOffice: Boolean, $date_Lte: Date!) {
    allTransfers(fromLocationId: $fromLocationId, isTransfer: false, companyId: $companyId, fromHeadOffice: $fromHeadOffice, date_Lte: $date_Lte) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          date
          fromHeadOffice
          toHeadOffice
          fromLocation {
            id
            name
          }
          toLocation {
            id
            name
            address
            gstin
            state
          }
          isTransfer
          remarks
          transferInvoiceNo
          productDetails {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsnCode
                  hsn {
                    hsnCode
                  }
                }
                rate
                purchaseRate
                quantity
                gstRate
                movementType
                movementFor {
                  id
                  name
                }
                movementFrom {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const locationWiseTransferQuery = `
query locationWiseTransferReport($companyId: ID,
  $after: String,
  $startDate: Date,
  $endDate: Date,
  $transferFrom: ID,
  $transferTo: ID,
  $fromHeadOffice: Boolean,
  $toHeadOffice: Boolean,
) {
  allTransfers(
    companyId: $companyId,
    first: 20,
    after: $after,
    isTransfer: true,
    date_Gte: $startDate,
    date_Lte: $endDate,
    fromLocationId: $transferFrom,
    toLocationId:$transferTo,
    fromHeadOffice: $fromHeadOffice,
    toHeadOffice: $toHeadOffice,
    status_Contains: "ACCEPTED",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        transferInvoiceNo
        fromLocation {
          id
          name
        }
        toLocation {
          id
          name
        }
        remarks
        status
        productDetails {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              product {
                id
                name
                newBarcodeId
              }
              purchaseRate
              quantity
              amount
            }
          }
        }
      }
    }
  }
}
`;

export const interLocationTransferQuery = `
query InterLocationTransferList($companyId: ID, $after: String, $startDate: Date, $endDate: Date) {
  allTransfers(
    companyId: $companyId,
    fromLocationId_Isnull: false,
    toLocationId_Isnull: false,
    first: 20,
    after: $after,
    isTransfer: true,
    status_Contains: "ACCEPTED",
    date_Gte: $startDate,
    date_Lte: $endDate,
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        transferInvoiceNo
        fromLocation {
          name
        }
        toLocation {
          id
          name
        }
        remarks
        productDetails {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              product {
                id
              }
              purchaseRate
              quantity
              amount
              gstRate
            }
          }
        }
      }
    }
  }
}
`;

export const itemWiseTransferQuery = `
query transferReport($companyId: ID!, $startDate: Date!, $endDate: Date!, $movementFromId: ID, $after: String, $isHeadOffice: Boolean) {
	allProductDetails(
    companyId: $companyId, 
    date_Gte: $startDate, 
    date_Lte: $endDate, 
    movementFromId: $movementFromId,
    movementFromId_Isnull: $isHeadOffice,
    transfer_FromVendor: false,
    status: "ACCEPTED", 
    after: $after, 
    first: 20
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        product {
          name
          oldBarcodeId
          newBarcodeId
          purchaseRate
          sellingRate
          division
          category {
            name
          }
          gstRate
          hsnCode
        }
        quantity
        movementFor {
          name
        }
        transferInvoiceNo
        receivingInvoiceNo
        vendorFor {
          name
        }
      }
    }
  }
}
`;

export const itemWiseTransitQuery = `
query transferReport($companyId: ID!, $startDate: Date!, $endDate: Date!, $movementFromId: ID, $after: String, $isHeadOffice: Boolean) {
	allProductDetails(
    companyId: $companyId, 
    date_Gte: $startDate, 
    date_Lte: $endDate, 
    movementFromId: $movementFromId,
    movementFromId_Isnull: $isHeadOffice,
    transfer_FromVendor: false,
    status: "PENDING", 
    after: $after, 
    first: 20
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        product {
          name
          oldBarcodeId
          newBarcodeId
          purchaseRate
          sellingRate
          division
          category {
            name
          }
        }
        quantity
        movementFor {
          name
        }
        transferInvoiceNo
        vendorFor {
          name
        }
      }
    }
  }
}
`;

export interface AddTransferVariable {
  input: {
    transfer: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  };
}

export interface TransferQueryResponse {
  data: {
    allTransfers: {
      edges: TransferEdges[];
    };
  };
}

export interface TransferEdges {
  cusror: string;
  node: TransferNode;
}

export const transferMutation = `
  mutation addTransfer(
    $input: CreateTransferInput!
  ) {
    createTransfer(input: $input) {
      newTransfer {
        id
        date
        fromLocation {
          id
          name
        }
        toLocation {
          id
          name
        }
      }
    }
  }
`;

export const transferDeleteMutation = `
  mutation DeleteTransfer(
    $input: DeleteTransferInput!
  ) {
    deleteTransfer(input: $input) {
      transferToDelete {
        id
        transferInvoiceNo
      }
    }
  }
`;

export const handleProductDetailsData = (
  productDetails?: ProductDetailsNodeConnection,
): ProductDetailNodeEdge[] => {
  if (!productDetails?.hasOwnProperty('edges')) return [];
  return productDetails.edges.map((pd) => {
    const newProductDetails = {
      ...pd.node,
      rate:
        pd.node.rate || pd.node.rate === 0
          ? pd.node.rate
          : pd.node.product?.sellingRate || 0,
      productId: pd.node?.product?.id,
      movementForId: pd.node?.movementFor?.id,
      movementFromId: pd.node?.movementFrom?.id,
      vendorForId: pd.node?.vendorFor?.id,
    };
    delete newProductDetails.product;
    delete newProductDetails.movementFor;
    delete newProductDetails.movementFrom;
    delete newProductDetails.vendorFor;
    return newProductDetails;
  });
};

export const descriptions = [
  'Special Addition(%)',
  'Packing/Other Charge',
  'Courier and Postal Charges',
];

export const generateCommissionDetailsObj = (): CommissionDetailsNode[] => {
  const commissionDetailsObj: CommissionDetailsNode[] = [];
  descriptions.forEach((el) => {
    commissionDetailsObj.push({
      category: '',
      description: el,
      descriptionPercent: 0,
      descriptionValue: 0,
    });
  });

  return commissionDetailsObj;
};

export const handleCommissionDetailsData = (
  commissionDetails?: CommissionDetailsNodeConnection,
): CommissionDetailsNode[] => {
  if (!commissionDetails?.hasOwnProperty('edges')) return [];
  return commissionDetails.edges.map((cd) => {
    const newCommissionDetails = { ...cd.node };
    delete newCommissionDetails.transferSet;
    return newCommissionDetails as unknown as CommissionDetailsNode;
  });
};

import * as React from 'react';
import { MemoNode, MemoNodeEdge } from './constants';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  JHARKHAND_GOVT_LOGO_URL,
  JHARKHAND_KHADI_LOGO_URL,
  KHADI_INDIA_LOGO_URL,
} from '../../config';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';

export const MemoReceipt: React.FunctionComponent<Props> = (props) => {
  const memo: MemoNode = props.memo.node;
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.3, height: 50, margin: 'auto' }]}
              source={KHADI_INDIA_LOGO_URL}
            />
          </View>
          <View style={[style.section, style.inline, { marginTop: 5 }]}>
            <Image
              style={[{ flex: 0.2, height: 55 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                {
                  fontSize: '15.3pt',
                  marginLeft: '10px',
                  marginRight: '10px',
                },
              ]}
            >
              Jharkhand State Khadi &amp; Village Industries Board 2nd Floor,
              Udyog Bhavan, Ratu Road, Ranchi — 834001.
            </Text>
            <Image
              style={[{ flex: 0.3, height: 50 }]}
              source={JHARKHAND_KHADI_LOGO_URL}
            />
          </View>
          <View style={style.section}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '11pt' },
              ]}
            >
              {props.company.address}
            </Text>
          </View>
          <View style={[style.divider, { marginTop: 10 }]} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Memo Receipt
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              GSTIN: {props.business.gstin}
            </Text>
            <Text
              style={[style.inlineHead, style.mediumBlackText, style.textRight]}
            >
              GST STATE:{' '}
              {`${props.business.state} (${props.business.gstin?.slice(0, 2)})`}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <View
              style={[
                style.box,
                { padding: 3, marginRight: 1 },
                style.inlineHead,
              ]}
            >
              <View>
                <Text style={style.mediumBlackText}>
                  From: Head Office ( {memo.company?.name} )
                </Text>
              </View>
            </View>
            <View
              style={[
                style.box,
                { padding: 3, marginLeft: 1 },
                style.inlineHead,
              ]}
            >
              <View>
                <Text style={style.mediumBlackText}>
                  To: {memo.location?.name}
                </Text>
              </View>
            </View>
          </View>
          <View style={[style.section, { marginTop: 7 }]}>
            <View style={[style.inline, style.box]}>
              <View style={{ width: '20%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Date
                </Text>
              </View>
              <View style={{ width: '30%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.date}
                </Text>
              </View>
              <View style={{ width: '20%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Book Number
                </Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.bookNumber}
                </Text>
              </View>
            </View>
            <View style={[style.inline, style.box]}>
              <View style={{ width: '20%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Serial Start
                </Text>
              </View>
              <View style={{ width: '30%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.serialStart}
                </Text>
              </View>
              <View style={{ width: '20%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Serial End
                </Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.serialEnd}
                </Text>
              </View>
            </View>
            <View style={[style.inline, style.box]}>
              <View
                style={{
                  width: '20%',
                  borderRight: '1pt solid black',
                }}
              >
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Financial Year
                </Text>
              </View>
              <View style={{ width: '30%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.financialYear}
                </Text>
              </View>
              <View style={{ width: '20%', borderRight: '1pt solid black' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  Memo Type
                </Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text
                  style={[
                    style.textCenter,
                    style.darkBlackText,
                    { padding: 3 },
                  ]}
                >
                  {memo.memoType}
                </Text>
              </View>
            </View>
          </View>
          <View style={[style.inline, { marginTop: 40 }]}>
            <View style={{ width: '50%' }}>
              <Text
                style={[style.textCenter, style.darkBlackText, { padding: 3 }]}
              >
                Receivers Signature
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text
                style={[style.textCenter, style.darkBlackText, { padding: 3 }]}
              >
                Authorized Signatory
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  inlineHead: { flex: 1 },
  textRight: { textAlign: 'right' },
});

interface Props {
  memo: MemoNodeEdge;
  company: CompanyNodeEdge;
  business: Bussiness;
}

import { Icon, Skeleton, Table, Tabs } from 'antd';
import React from 'react';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design/lib';
import { CompanyService } from '../../../service/CompanyService';
import { UserService } from '../../../service/UserService';
import { checkAddPermission } from '../../helper';
import { UserNodeEdge } from '../../user/constants';
import { AddDiscountCategory } from './AddDiscountCategory';
import {
  discountCategoriesQuery,
  DiscountCategoryNodeConnection,
  DiscountCategoryNodeEdge,
} from './constants';

export class DiscountCategories extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedDiscountCategory: null,
      after: '',
      currentPage: 1,
      user: null,
      companyId: null,
      companyName: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyId: company.id }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyName: company.name }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedDiscountCategory: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productSubCategoryColumns = () => {
    const col = [
      {
        title: 'Name',
        dataIndex: 'node.name',
        key: 'name',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({ selectedDiscountCategory: obj, activeKey: '2' });
            }}
          >
            {text}
          </a>
        ),
        sortingValue: (text) => text,
      },
      {
        title: 'Description',
        dataIndex: 'node.description',
        key: 'description',
        sortingValue: (text) => text,
      },
    ];

    if (this.state.companyName?.toLowerCase().includes('khadi')) {
      col.push(
        {
          title: 'MDA%',
          dataIndex: 'node.mda',
          key: 'mda',
          sortingValue: (text) => text,
        },
        {
          title: 'Commission%',
          dataIndex: 'node.commission',
          key: 'commission',
          sortingValue: (text) => text,
        },
      );
    }

    return col;
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey, companyId } = this.state;
    const variables = { after, companyId, activeKey };
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Discount Categories
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={discountCategoriesQuery}
            variables={variables}
            render={(
              response: {
                allDiscountCategories: DiscountCategoryNodeConnection;
              },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allDiscountCategories.edges}
                    columns={this.productSubCategoryColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allDiscountCategories.pageInfo.endCursor,
                        response.allDiscountCategories.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Discount Category
            </span>
          }
          key="2"
        >
          <AddDiscountCategory
            discountCategory={this.state.selectedDiscountCategory?.node}
            handleTabChange={this.handleTabChange}
            companyName={this.state.companyName}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedDiscountCategory: DiscountCategoryNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  companyId?: string | null;
  companyName: string | null;
}

interface Props {}

import * as React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import {
  Table,
  Tabs,
  Icon,
  Spin,
  Skeleton,
  Row,
  Col,
  Popconfirm,
  notification,
  Button,
  Modal,
} from 'antd';
import { checkAddPermission } from '../helper';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { DateService } from '../../service/DateService';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../common/TSInput';
import {
  TransferNodeConnection,
  TransferNodeEdge,
  JobOrderNode,
  TransferNode,
  JobOrderPartialCancelNode,
} from '../../schema';
import { JobOrderService } from '../../service/JobOrderService';
import { jobOrderQuery } from './constants';
import { AddJobOrder } from './AddJobOrder';
import { JobOrderReceiptJharcraft } from './JobOrderReceiptJharcraft';
import { JobOrderTaxInvoiceJharcraft } from './JobOrderTaxInvoiceJharcraft';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import moment from 'moment';
import { ProductIssueReport } from './ProductIssueReport';
import { JobOrderPartialCancel } from './JobOrderPartialCancel';
import { JobOrderPartialCancelReport } from './JobOrderPartialCancelReport';
import { JobOrderPartialCancelTaxInvoiceJharcraft } from './JobOrderPartialCancelTaxInvoiceJharcraft';

export class JobOrder extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedTransfer: null,
      isEditActive: false,
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      jobOrderNo: '',
      selectedJobOrder: null,
      user: null,
      updateListCount: 0,
      showModal: false,
      transferForPartialCancel: null,
      selectedPartialCancel: null,
    };
  }

  jobOrderService = new JobOrderService();
  companyService = new CompanyService();
  dateService = new DateService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );

    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  jobOrderColumn = () => [
    {
      title: 'Job Order No',
      dataIndex: 'node.jobOrder.jobOrderNo',
      key: 'jobOrderNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedTransfer: obj,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'node.jobOrder.date',
      key: 'date',
      render: (text, obj) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Bill To',
      dataIndex: 'node.toLocation.name',
      key: 'toLocation',
      render: (text, record) => text || record?.node?.vendorFor?.name,
    },
    {
      title: 'Receipt',
      key: 'receipt',
      dataIndex: 'receipt',
      render: (text, obj: TransferNodeEdge) => {
        const transfer = obj.node;
        return (
          <a
            onClick={() => {
              this.setState({
                selectedTransfer: obj,
                activeKey:
                  (transfer?.fromHeadOffice
                    ? this.state.company?.state?.toLowerCase()
                    : transfer?.fromLocation?.state?.toLowerCase()) ===
                  (transfer?.toVendor
                    ? transfer.vendorFor?.state?.toLowerCase()
                    : transfer?.toLocation?.state?.toLowerCase())
                    ? '3'
                    : '4',
              });
            }}
          >
            <Icon type="download" />
          </a>
        );
      },
    },
    {
      title: 'Cancel',
      key: 'cancel',
      render: (text: TransferNodeEdge) => (
        <Popconfirm
          title="Are you sure to Cancel?"
          onConfirm={() =>
            text.node?.jobOrder?.id &&
            this.jobOrderService?.cancelJobOrder(text.node.jobOrder.id, () => {
              this.setState({
                updateListCount: this.state.updateListCount + 1,
              });
              notification.success({
                message: 'Cancelled',
                description: `Job Order was successfully cancelled`,
              });
            })
          }
          okText="Yes"
          cancelText="No"
          disabled={
            text.node?.status === 'DISABLED' ||
            text.node?.jobOrder?.partialCancel ||
            (!!this.state.user && !checkAddPermission(this.state.user))
          }
        >
          <Button
            children={<Icon type="delete" />}
            type="danger"
            disabled={
              text.node?.status === 'DISABLED' ||
              text.node?.jobOrder?.partialCancel ||
              (!!this.state.user && !checkAddPermission(this.state.user))
            }
          />
        </Popconfirm>
      ),
    },
    {
      title: 'Partial Return',
      key: 'partialReturn',
      render: (transferNodeEdge: TransferNodeEdge) => (
        <Button
          onClick={() =>
            transferNodeEdge.node &&
            this.handleModalAction(true, transferNodeEdge.node)
          }
          disabled={transferNodeEdge.node?.jobOrder?.partialCancel}
        >
          <Icon type="hourglass" />
        </Button>
      ),
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  handleTabChange = (activeKey: string) => {
    if (
      activeKey === '1' ||
      this.state.activeKey === '3' ||
      this.state.activeKey === '4'
    ) {
      this.setState({ activeKey, selectedTransfer: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  handleSelectedPartialCancel = (
    selectedPartialCancel: JobOrderPartialCancelNode,
  ) => {
    this.setState({ selectedPartialCancel });
    this.handleTabChange('8');
  };

  getSelectedJobOrder = (transfer: TransferNodeEdge) => {
    if (!transfer) return null;
    const jobOrder = transfer.node?.jobOrder;
    const transfers: TransferNodeConnection = {
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: '',
      },
      edges: [
        {
          cursor: transfer.cursor,
          node: transfer.node,
        },
      ],
    };
    if (jobOrder)
      (jobOrder.transfers as any as TransferNodeConnection) = transfers;
    return jobOrder;
  };

  handleModalAction = (showModal: boolean, node: TransferNode | null) => {
    this.setState({ showModal, transferForPartialCancel: node });
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;

    const {
      company: { id: companyId },
      business,
      after,
      currentPage,
      activeKey,
      jobOrderNo,
      updateListCount,
      showModal,
    } = this.state;
    const date_Lte = this.dateService?.date$.value;
    const variables =
      business.type === 'branch'
        ? {
            companyId,
            fromLocationId: business.id,
            fromHeadOffice: false,
            date_Lte,
            after,
            activeKey,
            updateListCount,
            showModal,
          }
        : {
            companyId,
            fromLocationId: null,
            fromHeadOffice: true,
            date_Lte,
            after,
            activeKey,
            updateListCount,
            showModal,
          };
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Job Order
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.jobOrderNo}
                onChange={(e) => this.setState({ jobOrderNo: e.target.value })}
                name="jobOrderNo"
                type="text"
                addonBefore="Search"
                placeholder="Enter Job Order No"
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={jobOrderQuery}
            variables={
              jobOrderNo
                ? { ...variables, jobOrderNo }
                : { ...variables, after }
            }
            render={(
              response: { allTransfers: TransferNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allTransfers.edges}
                    columns={this.jobOrderColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Modal
                    title="Job Order Partial Return"
                    visible={this.state.showModal}
                    footer={<div />}
                    onCancel={() => this.handleModalAction(false, null)}
                  >
                    {this.state.transferForPartialCancel &&
                      this.state.company && (
                        <JobOrderPartialCancel
                          transferForPartialCancel={
                            this.state.transferForPartialCancel
                          }
                          company={this.state.company}
                          handleModalAction={this.handleModalAction}
                        />
                      )}
                  </Modal>,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allTransfers.pageInfo.endCursor || '',
                        response.allTransfers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Job Order
              </span>
            }
            key="2"
          >
            {this.companyService?.company$.value && (
              <AddJobOrder
                jobOrder={
                  this.state.selectedTransfer
                    ? this.getSelectedJobOrder(this.state.selectedTransfer)
                    : null
                }
                handleTabChange={this.handleTabChange}
                isEditActive={this.state.isEditActive}
                company={this.companyService?.company$.value}
                business={this.state.business}
                activeKey={this.state.activeKey}
              />
            )}
          </Tabs.TabPane>
        )}
        {this.state.selectedTransfer && this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            {this.companyService?.company$.value &&
              this.companyService.selectedBussiness$.value &&
              this.state.selectedTransfer && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <JobOrderReceiptJharcraft
                    company={this.companyService?.company$.value}
                    business={this.companyService?.selectedBussiness$.value}
                    jobOrder={this.getSelectedJobOrder(
                      this.state.selectedTransfer,
                    )}
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
        {this.state.selectedTransfer && this.state.activeKey === '4' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="4"
          >
            {this.companyService?.company$.value &&
              this.companyService.selectedBussiness$.value &&
              this.state.selectedTransfer && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <JobOrderTaxInvoiceJharcraft
                    company={this.companyService?.company$.value}
                    business={this.companyService.selectedBussiness$.value}
                    jobOrder={
                      this.getSelectedJobOrder(this.state.selectedTransfer) ||
                      null
                    }
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Job Order Status Report
            </span>
          }
          key="5"
        >
          <iframe
            src="http://reports.scriptitsolution.tech/public/question/5e99a4f5-df07-481d-bbe2-21814ba2b3e9"
            frameBorder="0"
            width="100%"
            height="800"
            allowTransparency
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Product Issue Report
            </span>
          }
          key="6"
          closable={false}
        >
          <ProductIssueReport company={this.state.company} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Job Order Partial Return Report
            </span>
          }
          key="7"
          closable={false}
        >
          <JobOrderPartialCancelReport
            company={this.state.company}
            activeKey={this.state.activeKey}
            handleSelectedPartialCancel={this.handleSelectedPartialCancel}
          />
        </Tabs.TabPane>
        {this.state.selectedPartialCancel && this.state.activeKey === '8' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="8"
          >
            {this.companyService?.company$.value &&
              this.companyService.company$.value.name
                .toLowerCase()
                .includes('jharcraft') &&
              this.companyService.selectedBussiness$.value &&
              this.state.selectedPartialCancel && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <JobOrderPartialCancelTaxInvoiceJharcraft
                    company={this.companyService?.company$.value}
                    business={this.companyService.selectedBussiness$.value}
                    jobOrderPartialCancel={this.state.selectedPartialCancel}
                  />
                </PDFViewer>
              )}
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedTransfer: TransferNodeEdge | null;
  isEditActive: boolean;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  jobOrderNo: string;
  selectedJobOrder: JobOrderNode | null;
  user?: UserNodeEdge | null;
  updateListCount: number;
  showModal: boolean;
  transferForPartialCancel: TransferNode | null;
  selectedPartialCancel: JobOrderPartialCancelNode | null;
}

interface Props {}

import { StateInput, StateNodeEdge } from '../../../schema';

export interface StateError {
  name: string;
  gstCode: string;
}

export const STATE_DATA: StateInput = {
  name: '',
  gstCode: '',
};

export const STATE_ERROR: StateError = {
  name: '',
  gstCode: '',
};

export interface StateEdges {
  cursor: string;
  node: StateNodeEdge;
}

export const stateMutation = `
mutation addState($input: CreateStateInput!) {
  createState(input: $input) {
    newState {
      id
      name
      gstCode
    }
  }
}
`;

export const stateQuery = `
query StateList {
  allStates {
    edges {
      node {
        id
        name
        gstCode
      }
    }
  }
}
`;

import * as React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { sumBy } from 'lodash';
import { inWords } from '../helper';
import { PurchaseOrderNode } from '../../schema';
import { ProductDetails } from './constants';
import {
  RAMESHWARAM_INDUSTRIES_HEADER,
  RAMESHWARAM_PROJECTS_HEADER,
} from '../../config';

export const PurchaseOrderReceiptHindgroup: React.FunctionComponent<Props> = (
  props,
) => {
  const productDetailsList = JSON.parse(
    props.purchaseOrder.productDetails,
  ) as ProductDetails[];
  const setBoxHeight = productDetailsList.length < 15;
  const totalQty = sumBy(productDetailsList, (value) => Number(value.quantity));
  const totalAmount = sumBy(productDetailsList, (value) => value.amount || 0);
  const totalCost = sumBy(productDetailsList, (value) => Number(value.rate));
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View
          style={
            props.company.name.toLowerCase().includes('hindustan')
              ? { top: 150 }
              : {}
          }
        >
          <View>
            {!props.company.name.toLowerCase().includes('hindustan') && (
              <View style={[style.section, style.inline, { marginTop: 12 }]}>
                {props.company.name.toLowerCase().includes('projects') ? (
                  <Image
                    style={[{ height: 100 }]}
                    source={RAMESHWARAM_PROJECTS_HEADER}
                  />
                ) : (
                  <Image
                    style={[{ height: 100 }]}
                    source={RAMESHWARAM_INDUSTRIES_HEADER}
                  />
                )}
              </View>
            )}
            <View style={[style.divider, { marginTop: 10 }]} />
            <View style={style.section}>
              <Text
                style={[
                  style.textCenter,
                  style.mediumBlackText,
                  { fontSize: '12pt' },
                ]}
              >
                Purchase Order
              </Text>
            </View>
            <View style={style.divider} />
            <View style={[style.section, style.inline]}>
              <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
                PO No:{' '}
                <Text style={[style.darkBlackText]}>
                  {props.purchaseOrder.purchaseOrderNo}
                </Text>
              </Text>
              <Text
                style={[
                  style.inlineHeadDouble,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                PO Date: {moment(props.purchaseOrder.date).format('DD-MM-YYYY')}
              </Text>
            </View>
            <View style={style.divider} />
            <View style={[style.section, style.box]}>
              <View style={[{ display: 'flex' }, { flexDirection: 'row' }]}>
                <View style={[{ width: '50%' }]}>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    To,
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.purchaseOrder.vendor?.name}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.purchaseOrder.vendor?.address}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    GSTIN: {props.purchaseOrder.vendor?.gstin}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.purchaseOrder.vendor?.contactPersonName}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.purchaseOrder.vendor?.contactNo}
                  </Text>
                </View>
                <Text
                  style={[
                    { width: '50%' },
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  Quotation Detail: {props.purchaseOrder.quotationDetails}
                </Text>
              </View>
            </View>
            <View style={[style.section, style.box]}>
              <View style={[{ display: 'flex' }, { flexDirection: 'row' }]}>
                <View style={[{ width: '50%' }]}>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    Billing Address
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.company.name.toLowerCase().includes('(tiles)')
                      ? props.company.name.replace('(tiles)', '')
                      : props.company.name}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.company.address}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    GSTIN: {props.company.gstin}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.company.state}
                  </Text>
                  <Text style={[style.mediumBlackText, { padding: 3 }]}>
                    {props.company.contactNo}
                  </Text>
                </View>
                <Text
                  style={[
                    { width: '50%' },
                    style.mediumBlackText,
                    style.textRight,
                  ]}
                >
                  Delivery Address: {props.purchaseOrder.shippingAddress}
                </Text>
              </View>
            </View>
            <View style={[style.section, style.box, { marginTop: 2 }]}>
              <Text style={[style.mediumBlackText, { padding: 3 }]}>
                Remarks: {props.purchaseOrder.remarks}
              </Text>
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <View style={[style.inline]}>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textCenter,
                  { padding: 3, flex: 0.2 },
                ]}
              >
                Sl.
              </Text>
              <Text
                style={[
                  style.highFlexInlineHead,
                  style.darkBlackText,
                  { padding: 3 },
                ]}
              >
                Product
              </Text>
              <Text style={[style.darkBlackText, { padding: 3 }]}>HSN</Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.5 },
                ]}
              >
                Quantity
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textCenter,
                  { padding: 3, flex: 0.4 },
                ]}
              >
                Unit
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                Cost Price
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                Amount
              </Text>
            </View>
            <View style={style.divider} />
            {productDetailsList.map((pd, i) => (
              <View style={[style.inline]} key={'pd' + i}>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.lightBlackText,
                    style.textCenter,
                    { flex: 0.2, padding: 3 },
                  ]}
                >
                  {i + 1}.
                </Text>
                <Text
                  style={[
                    style.highFlexInlineHead,
                    style.mediumBlackText,
                    { padding: 3 },
                  ]}
                >
                  {pd.productName.split(',')[2]}
                </Text>
                {/* <Text
                  style={[
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {pd.productName.split(',')[0]}
                </Text> */}
                <Text
                  style={[
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.4 },
                  ]}
                >
                  {
                    pd.productName.split(',')[
                      pd.productName.split(',').length - 1
                    ]
                  }
                </Text>
                <Text
                  style={[
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.5 },
                  ]}
                >
                  {pd.quantity}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textCenter,
                    { padding: 3, flex: 0.4 },
                  ]}
                >
                  {pd.unit}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  {Number(pd.rate).toFixed(2)}
                </Text>
                <Text
                  style={[
                    style.lowFlexInlineHead,
                    style.mediumBlackText,
                    style.textRight,
                    { padding: 3, flex: 0.6 },
                  ]}
                >
                  {pd.amount?.toFixed(2)}
                </Text>
              </View>
            ))}
          </View>
          <View style={[style.section, style.box, { marginTop: 2 }]}>
            <View style={[style.inline]}>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textCenter,
                  { padding: 1, flex: 0.4 },
                ]}
              >
                Total
              </Text>
              <Text style={[style.darkBlackText, { padding: 3, flex: 3.4 }]}>
                {}
              </Text>

              <Text
                style={[
                  style.darkBlackText,
                  { padding: 1, textAlign: 'center', flex: 0.1 },
                ]}
              >
                {totalQty}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textCenter,
                  { padding: 3, flex: 0.4 },
                ]}
              >
                {}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {totalCost}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.darkBlackText,
                  style.textRight,
                  { padding: 3, flex: 0.6 },
                ]}
              >
                {totalAmount}
              </Text>
            </View>
          </View>
          <View style={[style.section, style.box, { marginTop: 0 }]}>
            <Text
              style={[
                style.darkBlackText,
                { fontSize: '10.2pt' },
                { padding: 4 },
              ]}
            >
              Rs. {inWords(Number(Math.round(totalAmount).toFixed(2)))}.
            </Text>
            <Text
              style={[style.darkBlackText, { fontSize: '9pt' }, { padding: 4 }]}
            >
              Inclusive / Exclusive GST
            </Text>
          </View>
          <View
            style={[
              style.section,
              {
                marginTop: 2,
                position: 'absolute',
                bottom: '-300px',
                width: '100%',
              },
              style.inline,
            ]}
          >
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { padding: 2 },
              ]}
            >
              Prepared By:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { padding: 2 },
              ]}
            >
              Approved By:
            </Text>
            <Text style={[style.mediumBlackText, { padding: 2, flex: 0.5 }]}>
              Authorized By:
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.6pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textJustify: { textAlign: 'justify' },
  marginTop: { marginTop: 20 },
});

interface Props {
  purchaseOrder: PurchaseOrderNode;
  company: CompanyNodeEdge;
}

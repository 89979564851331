import { ProductDetailsInput } from '../../schema';
import moment from 'moment';

export const values: ProductDetailsInput[] = [
  {
    date: moment().format('YYYY-MM-DD'),
    productId: null,
    quantity: null,
    purchaseRate: null,
    rate: null,
    amount: null,
    newProductId: null,
    movementType: 'CODE_CONVERSION',
    companyId: null,
  },
];

export const productDetailsMutation = `
mutation ProductDetails($input: CreateProductDetailsInput!) {
  createProductDetails(input: $input) {
    newProductDetails {
      id
      date
      product {
        id
        name
      }
    }
  }
}
`;

export const productDetailsQuery = `
query productDetails($after: String, $companyId: ID, $movementType: String, $date: Date, $movementForId_Isnull: Boolean, $movementForId: ID) {
  allProductDetails(
    movementType: $movementType,
    first: 20,
    after: $after,
    companyId: $companyId,
    date_Lte: $date,
    date_Gte: $date,
    movementForId_Isnull: $movementForId_Isnull,
    movementForId: $movementForId,
    orderBy: "-id",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        product {
          id
          name
          oldBarcodeId
          newBarcodeId
        }
        quantity
        newProduct {
          id
          name
          oldBarcodeId
          newBarcodeId
        }
        quantityForNewProduct
        movementFor {
          name
        }
      }
    }
  }
}
`;

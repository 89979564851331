import { PageInfo } from '../../../schema';
import { BranchNodeEdge } from '../Branch/constants';
import { DiscountCategoryNode } from '../DiscountCategory/constants';

export interface DiscountNode {
  id?: string;
  startDate: string;
  endDate: string;
  category?: DiscountCategoryNode;
  categoryId: string | null;
  discountPercent: number;
  branch?: BranchNodeEdge;
  branchId: string | null;
  companyId?: string | null;
}

export const discountObject: DiscountNode = {
  startDate: '',
  endDate: '',
  categoryId: null,
  discountPercent: 0,
  branchId: null,
};

export interface DiscountNodeEdge {
  cursor: string;
  node: DiscountNode;
}

export interface DiscountNodeConnection {
  pageInfo: PageInfo;
  edges: DiscountNodeEdge[];
}

export const discountQuery = (
  date: string,
  branchId?: string | null,
  companyId?: string | null,
) => `
{
  allDiscount(
    startDate_Lte: "${date}",
    endDate_Gte: "${date}",
    branchId: "${branchId}",
    companyId: "${companyId}"
  ) {
    edges {
      node {
        category {
          name
        }
        discountPercent
      }
    }
  }
}
`;

export const allDiscountQuery = `
query DiscountList($companyId: ID, $after: String) {
  allDiscount(
    first: 20,
    companyId: $companyId,
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        startDate
        endDate
        category {
          id
          name
        }
        branch {
          id
          name
        }
        discountPercent
      }
    }
  }
}
`;

export interface DiscountQueryResponse {
  data: {
    allDiscount: {
      edges: DiscountNodeEdge[];
    };
  };
}

export interface AddDiscountVariables {
  input: {
    discount: DiscountNode;
  };
}

export interface AddDiscountPayload {
  newDiscount: string;
}

export const discountMutation = `
mutation CreateDiscount($input: CreateDiscountInput!) {
  createDiscount(input: $input) {
    newDiscount
  }
}
`;

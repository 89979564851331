import * as React from 'react';
import { DatePicker, Divider, Skeleton, Button } from 'antd';
import moment from 'moment';
import { Table } from '../common/Table';
import { paymentModeWiseReportBranch } from './constants';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { PaymentModeReportBranch } from '../../schema';
import { downloadToFile } from '../helper';
import { BranchNodeEdge } from '../master/Branch/constants';

export class PaymentModeSalesReportBranch extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      month: moment().format('YYYY-MM'),
    };
  }

  componentDidMount() {}

  componentWillReceiveProps() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      render: (value, obj) =>
        obj.date !== 'Total' ? moment(obj.date).format('DD-MM-YYYY') : obj.date,
      sortingValue: (value, obj) => obj.date,
    },
    {
      title: 'Cash',
      key: 'cash',
      dataIndex: 'cash',
    },
    {
      title: 'Card',
      key: 'card',
      dataIndex: 'card',
    },
    {
      title: 'Credit',
      key: 'credit',
      dataIndex: 'credit',
    },
    {
      title: 'Cheque',
      key: 'cheque',
      dataIndex: 'cheque',
    },
    {
      title: 'Bank',
      key: 'bank',
      dataIndex: 'bank',
    },
    {
      title: 'COD',
      key: 'cod',
      dataIndex: 'cod',
    },
    {
      title: 'Cancel Sales',
      key: 'cancelSales',
      dataIndex: 'cancelSales',
    },
    {
      title: 'Total',
      key: 'totalForDate',
      dataIndex: 'totalForDate',
    },
  ];
  downloadCsv = (paymentModeListBranch: PaymentModeReportBranch[]) => {
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    const data = paymentModeListBranch
      .map((dl) =>
        this.columns()
          .map((col) => {
            if (col.dataIndex) {
              return dl[col.dataIndex];
            }
            return col.render?.(dl, dl);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columns()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `payment-mode-wise-sales-report-${
        this.props.business.name
      }-${new Date().toISOString()}`,
      'PAYMENT MODE WISE SALES REPORT',
      this.props.company.name,
      this.props.business.name,
      moment(startDate).format('DD-MM-YYYY'),
      moment(endDate).format('DD-MM-YYYY'),
      '',
    );
  };
  render() {
    const {
      company: { id: companyId },
      business,
    } = this.props;
    const currentMonth = moment().format('YYYY-MM');
    const startDate = `${this.state.month}-01`;
    const endDate =
      this.state.month === currentMonth
        ? moment().format('YYYY-MM-DD')
        : moment(this.state.month).endOf('month').format('YYYY-MM-DD');
    return (
      <div>
        <DatePicker.MonthPicker
          format="MMM-YYYY"
          placeholder="Month"
          value={
            this.state.month
              ? moment(this.state.month)
              : moment(moment().format('YYYY-MM'))
          }
          onChange={(month) =>
            this.onChange({
              target: {
                name: 'month',
                value: month && month.format('YYYY-MM'),
              },
            })
          }
        />
        <Divider />
        <GraphqlQuery
          queryString={paymentModeWiseReportBranch}
          variables={{
            companyId,
            branchId: business.type === 'branch' ? business.id : '',
            startDate,
            endDate,
          }}
          render={(
            response: { paymentModeListBranch: PaymentModeReportBranch[] },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.paymentModeListBranch}
                  columns={this.columns()}
                  rowKey={(obj) => obj.date || ''}
                  pagination={{ pageSize: 11 }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(response.paymentModeListBranch)
                  }
                  key="button"
                />,
              ];
            }
          }}
        />
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  business: Bussiness;
}

interface State {
  month: string;
}

import * as React from 'react';
import { Button, notification, Modal } from 'antd';
import { ApprovalService } from '../../service/ApprovalService';
import { CustomerService } from '../../service/CustomerService';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  ApprovalNodeEdgeQuery,
  ApprovalEdges,
  ApprovalNodeEdgeMutation,
  approvalObject,
  ApprovalError,
  approvalErrorObj,
  productDetailsErrorObj,
} from './constants';
import { ApprovalForm } from './ApprovalForm';
import { ProductNode, CustomerNode } from '../../schema';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';

export class AddApproval extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      approval: this.handlePropsValueUpdate(props),
      error: this.handlePropsErrorUpdate(props),
      buttonDisabled: false,
      productObjList: [],
      customerObj: null,
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
    };
  }

  approvalService = new ApprovalService();
  customerService = new CustomerService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      approval: this.handlePropsValueUpdate(nextProps),
      error: this.handlePropsErrorUpdate(nextProps),
    });
  }

  handlePropsValueUpdate(nextProps: Props) {
    const approval: any = nextProps.approval
      ? {
          ...nextProps.approval,
          customerId:
            nextProps.approval.customer && nextProps.approval.customer.id,
          oldApprovalId:
            nextProps.approval.oldApproval && nextProps.approval.oldApproval.id,
          productDetails: nextProps.approval.productDetails?.edges.map(
            (pd) => ({
              ...pd.node,
              productId: pd.node.product?.id,
            }),
          ),
        }
      : JSON.parse(JSON.stringify(approvalObject));
    if (nextProps.approval) {
      delete approval.branch;
      delete approval.customer;
      delete approval.oldApproval;
    }
    return approval;
  }

  handlePropsErrorUpdate(nextProps: Props) {
    const error: ApprovalError = JSON.parse(JSON.stringify(approvalErrorObj));
    nextProps.approval &&
      nextProps.approval.productDetails?.edges.forEach(() => {
        if (
          nextProps.approval?.productDetails?.edges.length !==
          error.productDetails.length
        ) {
          error.productDetails.push(productDetailsErrorObj);
        }
      });
    return error;
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const approval = { ...this.state.approval };
    const error: ApprovalError = JSON.parse(JSON.stringify(approvalErrorObj));
    approval[e.target.name] = e.target.value;
    if (e.target.name === 'isReturn' && !e.target.value) {
      approval.oldApprovalId = null;
      approval.productDetails = JSON.parse(
        JSON.stringify(approvalObject.productDetails),
      );
      approval.totalMrp = 0;
      approval.totalCostAmount = 0;
      error.oldApprovalId = '';
      error.productDetails = [productDetailsErrorObj];
    }
    if (e.target.name === 'oldApprovalId' && e.target.value) {
      this.getOldApprovalData(approval, e.target.value as string);
    }
    this.setState({ approval });
  };

  getOldApprovalData = (
    approval: ApprovalNodeEdgeMutation,
    oldApprovalId: string,
  ) => {
    const oldApproval = this.props.approvalList.find(
      (appr) => appr.node.id === oldApprovalId,
    );
    const error: ApprovalError = JSON.parse(JSON.stringify(approvalErrorObj));
    oldApproval &&
      (approval.productDetails = oldApproval.node.productDetails.edges.map(
        (pd) => ({
          ...pd.node,
          id: null,
          productId: pd.node.product?.id,
          movementForId: pd.node.movementFor && pd.node.movementFor.id,
          movementType: 'APPROVAL_RETURN' as const,
        }),
      ));
    approval.productDetails?.map((pd) => {
      if (approval.productDetails?.length !== error.productDetails.length) {
        error.productDetails.push(productDetailsErrorObj);
      }
      delete pd.product;
      delete pd.movementFor;
      return pd;
    });
    approval.totalMrp = oldApproval?.node.totalMrp;
    approval.totalCostAmount = oldApproval?.node.totalCostAmount;
    this.setState({ approval, error });
  };

  checkError = () => {
    const error: ApprovalError = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (
        !this.state.approval[errKey] &&
        ['productDetails', 'oldApprovalId'].indexOf(errKey) === -1
      ) {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (['productDetails', 'oldApprovalId'].indexOf(errKey) === -1) {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  customError = () => {
    const error = { ...this.state.error };
    let isError = false;
    if (this.state.approval.isReturn && !this.state.approval.oldApprovalId) {
      isError = true;
      error.oldApprovalId = `This is a required field`;
    } else {
      error.oldApprovalId = '';
    }
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const productDetails: ProductDetailNodeEdge[] = {
      ...this.state.approval.productDetails,
    };
    const error: ApprovalError = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.approval.productDetails[index][e.target.name] = e.target.value;
    nextState.approval.productDetails[index].amount =
      Number(nextState.approval.productDetails[index].quantity) *
      Number(nextState.approval.productDetails[index].rate);
    this.calculateAmount(nextState);
    this.setState(nextState);
  };

  calculateAmount = (nextState: State) => {
    const totalMrp = Number(
      nextState.approval.productDetails
        ?.reduce((sum, p) => {
          if (p.amount) return sum + p.amount;
          return sum;
        }, 0)
        .toFixed(2),
    );
    nextState.approval.totalMrp = totalMrp;
    nextState.approval.totalCostAmount = Number(
      nextState.approval.productDetails
        ?.reduce((s, p) => {
          if (p.quantity && p.purchaseRate)
            return s + p.quantity * p.purchaseRate;
          return s;
        }, 0)
        .toFixed(2),
    );
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.approval.productDetails[index].rate = productObj.sellingRate;
    nextState.approval.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.approval.productDetails?.push({
        date: null,
        productId: '',
        quantity: null,
        rate: null,
        amount: 0,
        movementType: 'APPROVAL',
        companyId: null,
      });
      nextState.error.productDetails.push(productDetailsErrorObj);
    } else {
      nextState.approval.productDetails?.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
      this.calculateAmount(nextState);
    }
    this.setState(nextState);
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.approval.customerId = customerObj.id;
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveApproval = () => {
    if (this.checkError()) {
      return;
    }
    if (this.customError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    console.log(this.state.approval);
    this.approvalService?.addApproval(
      this.state.approval,
      () => {
        if (!this.props.approval) {
          this.setState({
            approval: JSON.parse(JSON.stringify(approvalObject)),
            error: JSON.parse(JSON.stringify(approvalErrorObj)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Approval ' + this.state.approval.id ? 'Updated' : 'Added',
          description: `Approval no ${
            this.state.approval.approvalNo
          } was successfuly ${this.state.approval.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        this.setState({ buttonDisabled: false });
        notification.error({
          message:
            'Approval ' + this.state.approval.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  clear = () => {
    this.setState({
      approval: JSON.parse(JSON.stringify(approvalObject)),
      error: JSON.parse(JSON.stringify(approvalErrorObj)),
    });
  };

  render() {
    const { approval, error, updateCount, modalAction, buttonDisabled } =
      this.state;
    const { company, approvalList, isEditActive } = this.props;
    return (
      <div>
        <ApprovalForm
          values={approval}
          onChange={this.onChange}
          company={company}
          approvalList={approvalList}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjChangehandler={this.productObjChangehandler}
          isEditActive={isEditActive}
          error={error}
          handleModalAction={this.handleModalAction}
          customerObjChangehandler={this.customerObjChangehandler}
          updateCount={updateCount}
        />
        <Modal
          title="Add Customer"
          visible={modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            company={company}
            onCustomerAdded={() =>
              this.setState({ updateCount: updateCount + 1 })
            }
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={updateCount}
            showOnlyList
          />
        </Modal>
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveApproval}
          children="Submit"
          style={{ width: '50%' }}
          disabled={buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  approval?: ApprovalNodeEdgeQuery;
  handleTabChange: (key) => void;
  company: CompanyNodeEdge;
  approvalList: ApprovalEdges[];
  isEditActive: boolean;
}

interface State {
  approval: ApprovalNodeEdgeMutation;
  error: ApprovalError;
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  customerObj: CustomerNode | null;
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
}

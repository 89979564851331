import * as React from 'react';

export class CreateBarCode extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <svg id="barcode" />
        <a onClick={this.props.downloadBar}> Download Bar Code </a>
      </>
    );
  }
}

interface Props {
  downloadBar: () => void;
}

export interface ProductCategoryNodeEdge {
  id?: string;
  name: string;
  initials: string;
  companyId?: string;
}

export interface ProductCategoryError {
  name: string;
  initials: string;
}

export const productCategoriesObject: ProductCategoryNodeEdge = {
  name: '',
  initials: '',
};

export const productCategoriesErrorObject: ProductCategoryError = {
  name: '',
  initials: '',
};

export const productCategoriesQuery = `
  query ProductCategoriesList($companyId: ID, $orderBy: String) {
    allProductCategories(companyId: $companyId, orderBy: $orderBy) {
      edges {
        cursor
        node {
          id
          name
          initials
        }
      }
    }
  }
`;

export const categoryWiseStocksQuery = `
query CategoryWiseStockList($companyId: ID, $branchId: ID, $date: Date!, $name: String, $orderBy: String, $fy_stock: Boolean, $after:String, $first:Int) {
  allProductCategories(companyId: $companyId, name_Icontains: $name, orderBy: $orderBy, after: $after, first: $first) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        categoryStock(branchId: $branchId, date: $date, companyId: $companyId, fyStock: $fy_stock) {
          openingStock
          transfer
          receiving
          sale
          saleReturn
          damage
          purchase
          purchaseReturn
          totalStocks
          inwardQty
          inwardValue
          outwardQty
          outwardValue
          openingStockValue
          closingStockValue
        }
      }
    }
  }
}
`;

export const categoryWiseMonthlySaleQuery = `
query CategoryWiseMonthlySaleList($companyId: ID, $branchId: ID!, $year: String!, $name: String, $orderBy: String, $after: String) {
  allProductCategories(companyId: $companyId, name_Icontains: $name, orderBy: $orderBy, first: 20, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        monthlySaleReport(branchId: $branchId, year: $year)
      }
    }
  }
}
`;

export interface ProductCategoryQueryResponse {
  data: {
    allProductCategories: {
      edges: ProductCategoryEdges[];
    };
  };
}

export interface ProductCategoryEdges {
  cursor: string;
  node: ProductCategoryNodeEdge;
}

export interface AddProductCategoryVariables {
  input: {
    productCategory: ProductCategoryNodeEdge;
  };
}

export const productCategoryMutation = `
  mutation addProductCategories(
    $input: CreateProductCategoryInput!
  ) {
    createProductCategory(input: $input){
      newProductCategory {
        id
        name
      }
    }
  }
`;

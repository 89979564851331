import React from 'react';
import {
  SalesPersonError,
  SalesPersonNodeEdge,
  salesPersonErrorObject,
  salesPersonObject,
} from './constants';
import { SalesService } from '../../../service/SalesService';
import { Button, notification } from 'antd';
import { SalesPersonForm } from './SalesPersonForm';

export class AddSalesPerson extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      salesPerson:
        props.salesPerson || JSON.parse(JSON.stringify(salesPersonObject)),
      error: { ...salesPersonErrorObject },
      buttonDisabled: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      salesPerson:
        nextProps.salesPerson || JSON.parse(JSON.stringify(salesPersonObject)),
    });
  }

  salesService = new SalesService();

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const salesPerson = { ...this.state.salesPerson };
    salesPerson[e.target.name] = e.target.value;
    this.setState({ salesPerson });
  };

  saveSalesPerson = () => {
    this.setState({ buttonDisabled: true });
    this.salesService?.addSalesPerson(
      this.state.salesPerson,
      () => {
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Sales Person detail ' + this.state.salesPerson.id
              ? 'Updated'
              : 'Added',
          description: `Sales Person named ${
            this.state.salesPerson.name
          } was successfuly ${this.state.salesPerson.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Sales Person ' + this.state.salesPerson.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      salesPerson: JSON.parse(JSON.stringify(salesPersonObject)),
      error: { ...salesPersonErrorObject },
    });
  };

  render() {
    return (
      <div>
        <SalesPersonForm
          values={this.state.salesPerson}
          error={this.state.error}
          onChange={this.onChange}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveSalesPerson}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  salesPerson?: SalesPersonNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  salesPerson: SalesPersonNodeEdge;
  error: SalesPersonError;
  buttonDisabled: boolean;
}

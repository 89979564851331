import * as React from 'react';
import {
  Table,
  Tabs,
  Icon,
  Spin,
  Skeleton,
  Row,
  Col,
  DatePicker,
  Button,
  Divider,
  Radio,
} from 'antd';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import {
  ProductDetailsNodeEdge,
  ProductDetailsNodeConnection,
} from '../../schema';
import { GraphqlQuery } from 'requestapijs';
import { AddCodeConversion } from './AddCodeConversion';
import moment from 'moment';
import { productDetailsQuery } from './constants';
import { Pagination } from 'sha-el-design';
import { Label } from '../common/TSLabel';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { BranchEdges } from '../master/Branch/constants';
import { BranchService } from '../../service/BranchService';
import { CODE_CONVERSION_LIST_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';

export class CodeConversion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      codeConversions: [],
      activeKey: '1',
      company: null,
      after: '',
      currentPage: 1,
      branch: [],
      date: null,
      locationType: 'ALL',
      branchId: '',
      user: null,
    };
  }

  companyService = new CompanyService();
  branchService = new BranchService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    if (e.target.name === 'locationType') {
      nextState.branchId = '';
    }
    this.setState(nextState);
  };

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productDetailsColumn = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Old Product',
      dataIndex: 'node.product.name',
      key: 'oldProduct',
      render: (text, obj: ProductDetailsNodeEdge) =>
        `${text} (${obj.node?.product?.oldBarcodeId},${obj.node?.product?.newBarcodeId})`,
    },
    {
      title: 'Quantity',
      dataIndex: 'node.quantity',
      key: 'quantity',
    },
    {
      title: 'New Product',
      dataIndex: 'node.newProduct.name',
      key: 'newProduct',
      render: (text, obj: ProductDetailsNodeEdge) =>
        `${text} (${obj.node?.newProduct?.oldBarcodeId},${obj.node?.newProduct?.newBarcodeId})`,
    },
    {
      title: 'Location',
      dataIndex: 'node.movementFor.name',
      key: 'movementFor',
    },
  ];

  downloadCsv = (variables) => {
    let branch;
    if (this.state.branchId) {
      branch = this.state.branch.find(
        (br) => br.node.id === this.state.branchId,
      );
    }
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(CODE_CONVERSION_LIST_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then(
        (v) =>
          this.state.company &&
          downloadToFile(
            v,
            `code-convesion-list-${new Date()}`,
            'CODE CONVERSION LIST',
            this.state.company.name,
            this.state.locationType !== 'OTHER_LOCATION'
              ? this.state.locationType
              : this.state.branchId
              ? branch.node.name
              : 'ALL EMPORIUM',
            this.state.date ? moment(this.state.date).format('DD-MM-YYYY') : '',
            this.state.date ? moment(this.state.date).format('DD-MM-YYYY') : '',
            '',
          ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) return <Spin />;
    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      branch,
      date,
      locationType,
      branchId,
    } = this.state;
    const movementForId_Isnull = locationType === 'HEAD_OFFICE' ? true : false;
    let variables: any = {
      companyId,
      activeKey,
      after,
      movementType: 'CODE_CONVERSION',
      date,
      movementForId: branchId,
      locationType,
    };
    variables =
      locationType !== 'ALL'
        ? { ...variables, movementForId_Isnull }
        : variables;
    return (
      <div>
        <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                List Code Conversion
              </span>
            }
            key="1"
            closable={false}
          >
            <div>
              <Row>
                <Col span={5}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Date"
                    value={date ? moment(date) : null}
                    onChange={(value) =>
                      this.onChange({
                        target: {
                          name: 'date',
                          value: value && moment(value).format('YYYY-MM-DD'),
                        },
                      })
                    }
                    style={{ marginTop: '5px', width: '100%' }}
                  />
                </Col>
                <Col span={9}>
                  <Label title="Location Type">
                    <Radio.Group
                      name="locationType"
                      value={locationType}
                      onChange={(e) =>
                        this.onChange({
                          target: {
                            name: 'locationType',
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      <Radio value={'ALL'}>All</Radio>
                      <Radio value={'HEAD_OFFICE'}>Head Office</Radio>
                      <Radio value={'OTHER_LOCATION'}>Other Location</Radio>
                    </Radio.Group>
                  </Label>
                </Col>
                <Col span={10}>
                  <TSAutoComplete
                    error={''}
                    inputProps={{ name: 'branchId', addonBefore: 'Location' }}
                    dataSource={branch.filter(
                      (br) => br.node.category === 'EMPORIUM',
                    )}
                    key="id"
                    name="branchId"
                    onValueSelect={(e) =>
                      this.onChange({
                        target: { name: 'branchId', value: e.target.value },
                      })
                    }
                    displayKey="name"
                    textValue={getValue(branch, branchId)}
                    disabled={locationType !== 'OTHER_LOCATION'}
                  />
                </Col>
              </Row>
              <Divider />
              <GraphqlQuery
                queryString={productDetailsQuery}
                variables={variables}
                render={(
                  response: { allProductDetails: ProductDetailsNodeConnection },
                  error,
                  loading,
                ) => {
                  if (error) {
                    return <h3>Something Went Wrong!!</h3>;
                  }
                  if (loading) return <Skeleton active />;
                  if (response && companyId) {
                    return [
                      <Table
                        key="table"
                        dataSource={response.allProductDetails.edges}
                        columns={this.productDetailsColumn()}
                        rowKey={(obj) => obj.node?.id || ''}
                        pagination={false}
                      />,
                      <Pagination
                        key="pagination"
                        totalCount={0}
                        currentPage={currentPage}
                        batchSize={20}
                        cursorBasedPagination
                        onChange={(_p, _ps, next) =>
                          this.onPageChange(
                            next,
                            response.allProductDetails.pageInfo.endCursor,
                            response.allProductDetails.pageInfo.hasNextPage,
                          )
                        }
                        style={{ float: 'right' }}
                      />,
                    ];
                  }
                  return <Skeleton active />;
                }}
              />
              <Button
                key="download"
                style={{ float: 'right' }}
                icon="download"
                onClick={() => this.downloadCsv(variables)}
              />
            </div>
          </Tabs.TabPane>
          {this.state.user && checkAddPermission(this.state.user) && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Code Conversion
                </span>
              }
              key="2"
            >
              {this.companyService?.company$.value && (
                <AddCodeConversion
                  handleTabChange={this.handleTabChange}
                  company={this.companyService?.company$.value}
                  branch={branch}
                />
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

interface State {
  codeConversions: ProductDetailsNodeEdge[];
  activeKey: string;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  branch: BranchEdges[];
  date: string | null;
  locationType: 'HEAD_OFFICE' | 'OTHER_LOCATION' | 'ALL';
  branchId: string;
  user?: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import { Tabs, Icon, Skeleton } from 'antd';
import { Table } from '../common/Table';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import { challanQuery } from './constants';
import {
  ChallanNode,
  ChallanNodeConnection,
  ChallanNodeEdge,
} from '../../schema';
import { AddChallan } from './AddChallan';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import { ChallanRecieptJharcraft } from './ChallanRecieptJharcraft';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';
import { ChallanReceiptKhadi } from './ChallanRecieptKhadi';

export class Challan extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedChallan: null,
      company: null,
      currentPage: 1,
      after: '',
      isEditActive: false,
      user: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe(
      (user) => user?.user && this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedChallan: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  challanColumn = () => [
    {
      title: 'Date Received',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
      sortingValue: (text) => text,
    },
    {
      title: 'Challan No',
      dataIndex: 'node.challanNo',
      key: 'challanNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedChallan: obj.node,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Purchase Order No',
      dataIndex: 'node.purchaseOrder.purchaseOrderNo',
      key: 'purchaseOrderNo',
      sortingValue: (text) => text,
    },
    {
      title: 'Vendor',
      dataIndex: 'node.purchaseOrder.vendor.name',
      key: 'vendor',
      sortingValue: (text) => text,
    },
    {
      title: 'Vendor Challan Date',
      dataIndex: 'node.vendorChallanDate',
      key: 'vendorChallanDate',
      render: (text) => moment(text).format('DD-MM-YYYY'),
      sortingValue: (text) => text,
    },
    {
      title: 'Vendor Challan No',
      dataIndex: 'node.vendorChallanNo',
      key: 'vendorChallanNo',
      sortingValue: (text) => text,
    },
    {
      title: 'Dump Location',
      key: 'dump location',
      render: (obj: ChallanNodeEdge) =>
        obj.node?.dumpToHeadOffice
          ? 'Head Office'
          : `${obj.node?.dumpLocation?.name}`,
    },
    {
      title: 'Receipt',
      key: 'receipt',
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ selectedChallan: record.node, activeKey: '3' });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage && after) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }

    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              {this.state.company.name.toLowerCase().includes('jharcraft') ||
              this.state.company?.name.toLowerCase().includes('khadi')
                ? 'List Goods Received Challan'
                : 'List Purchase Challan'}
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={challanQuery}
            variables={{ companyId, first: 20, after, activeKey }}
            render={(
              response: { allChallans: ChallanNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allChallans.edges}
                    columns={this.challanColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allChallans.pageInfo.endCursor,
                        response.allChallans.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                {this.state.company.name.toLowerCase().includes('jharcraft') ||
                this.state.company?.name.toLowerCase().includes('khadi')
                  ? 'Add Goods Received Challan'
                  : 'Add Purchase Challan'}
              </span>
            }
            key="2"
          >
            <AddChallan
              challan={this.state.selectedChallan}
              handleTabChange={this.handleTabChange}
              company={this.state.company}
              isEditActive={this.state.isEditActive}
              activeKey={this.state.activeKey}
            />
          </Tabs.TabPane>
        )}
        {this.state.selectedChallan && this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            <PDFViewer style={{ width: '100%', height: '1000px' }}>
              {this.state.company.name.toLowerCase().includes('khadi') ? (
                <ChallanReceiptKhadi
                  challan={this.state.selectedChallan}
                  company={this.state.company}
                />
              ) : (
                <ChallanRecieptJharcraft
                  challan={this.state.selectedChallan}
                  company={this.state.company}
                />
              )}
            </PDFViewer>
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedChallan: ChallanNode | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  isEditActive: boolean;
  user: UserNodeEdge | null;
}

interface Props {}

import * as React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import {
  issueObject,
  IssueError,
  issueErrorObj,
  issueDetailsError,
} from './constants';
import { IssueForm } from './IssueForm';
import { CompanyNodeEdge } from '../../master/Company/constants';
import {
  IssueNode,
  IssueInput,
  ProductNode,
  ProductDetailsMovementType,
  ProductDetailsInput,
} from '../../../schema';
import { IssueService } from '../../../service/IssueService';
import { ProductDetailsNodeConnection } from '../../Sales/constants';

export class AddIssue extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      issue: this.handleIssuePropsValue(props),
      buttonDisabled: false,
      error: this.handleErrorPropsUpdate(props),
    };
  }

  issueService = new IssueService();

  componentWillReceiveProps(props: Props) {
    this.setState({
      issue: this.handleIssuePropsValue(props),
      error: this.handleErrorPropsUpdate(props),
    });
  }

  handleIssuePropsValue = (props: Props) => {
    const issue: IssueInput = props.issue
      ? {
          ...props.issue,
          productDetails: (
            props.issue.productDetails as any as ProductDetailsNodeConnection
          ).edges.map((productDetail) => ({
            ...productDetail.node,
            productId: productDetail.node.product?.id,
          })),
        }
      : JSON.parse(JSON.stringify(issueObject));
    return issue;
  };

  handleErrorPropsUpdate(nextProps: Props) {
    const error: IssueError = JSON.parse(JSON.stringify(issueErrorObj));
    const productDetails: ProductDetailsNodeConnection =
      nextProps.issue && (nextProps.issue.productDetails as any);
    productDetails &&
      productDetails.edges.forEach(() => {
        if (productDetails.edges.length !== error.productDetails.length) {
          error.productDetails.push({
            productId: '',
            quantity: '',
          });
        }
      });
    return error;
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const issue = { ...this.state.issue };
    issue[e.target.name] = e.target.value;
    this.setState({ issue });
  };

  checkError = () => {
    const error: IssueError = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    Object.keys(error).forEach((errKey) => {
      if (!this.state.issue[errKey] && errKey !== 'productDetails') {
        isError = true;
        error[errKey] = `This is a required field`;
      } else if (errKey !== 'productDetails') {
        error[errKey] = ``;
      }
    });
    this.setState({ error });
    return isError;
  };

  productDetailChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.issue.productDetails[index][e.target.name] = e.target.value;
    nextState.issue.productDetails[index].amount = Number(
      (
        Number(nextState.issue.productDetails[index].quantity) *
        Number(nextState.issue.productDetails[index].purchaseRate)
      ).toFixed(2),
    );
    this.setState(nextState);
  };

  productObjChangeHandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.issue.productDetails[index].purchaseRate =
      productObj.purchaseRate;
    nextState.issue.productDetails[index].rate = productObj.sellingRate;
    this.setState(nextState);
  };

  checkProductError = () => {
    const productDetails: ProductDetailsInput[] = {
      ...this.state.issue.productDetails,
    };
    const error: State['error'] = JSON.parse(JSON.stringify(this.state.error));
    let isError = false;
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (!productDetails[index][pdkey]) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
      });
      return epd;
    });
    this.setState({ error });
    return isError;
  };

  updateRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.issue.productDetails.push({
        date: '',
        productId: '',
        rate: 0,
        purchaseRate: 0,
        quantity: null,
        amount: 0,
        movementType: ProductDetailsMovementType.PRODUCTION,
        companyId: '',
        remark: null,
      });
      nextState.error.productDetails.push(
        JSON.parse(JSON.stringify(issueDetailsError)),
      );
    } else {
      nextState.issue.productDetails.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
    }
    this.setState(nextState);
  };

  saveIssue = () => {
    if (this.checkError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    this.issueService?.addIssue(
      this.state.issue,
      (response) => {
        if (!this.props.issue) {
          this.setState({
            issue: JSON.parse(JSON.stringify(issueObject)),
          });
        }
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Direct Production ' + this.state.issue.id ? 'Updated' : 'Added',
          description:
            `Direct Production was successfully ` +
            `${this.state.issue.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        this.setState({ buttonDisabled: false });
        notification.error({
          message:
            'Direct Production' + this.state.issue.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
      },
    );
  };

  clear = () => {
    this.setState({
      issue: JSON.parse(JSON.stringify(issueObject)),
      error: JSON.parse(JSON.stringify(issueErrorObj)),
    });
  };

  render() {
    return (
      <div>
        <IssueForm
          values={this.state.issue}
          error={this.state.error}
          company={this.props.company}
          onChange={this.onChange}
          productDetailChangeHandler={this.productDetailChangeHandler}
          updateRow={this.updateRow}
          productObjChangeHandler={this.productObjChangeHandler}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveIssue}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  issue?: IssueNode;
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  issue: IssueInput;
  error: IssueError;
  buttonDisabled: boolean;
}

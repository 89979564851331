import * as React from 'react';
import { TransferService } from '../../service/TransferService';
import { notification, Button, Popconfirm } from 'antd';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import { BranchEdges } from '../master/Branch/constants';
import { BranchService } from '../../service/BranchService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import {
  handleCommissionDetailsData,
  handleProductDetailsData,
  transferNode,
  TransferNode,
} from '../Transfer/constants';
import { ReceivingError, receivingError } from './constants';
import { ReceivingForm } from './ReceivingForm';
import moment from 'moment';
import {
  CommissionDetailsNode,
  CommissionDetailsNodeConnection,
} from '../../schema';

export class AddReceiving extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transfer: this.handleTransferPropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      branch: [],
      buttonDisabled: false,
      acceptAll: false,
    };
  }

  transferService = new TransferService();
  branchService = new BranchService();

  componentDidMount() {
    this.fetchBranch();
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      transfer: this.handleTransferPropsUpdate(nextProps),
      error: this.handleErrorPropsUpdate(nextProps),
    });
  }

  handleTransferPropsUpdate = (nextProps: Props) => {
    const transfer = nextProps.transfer
      ? {
          ...nextProps.transfer,
          fromLocationId:
            nextProps.transfer.fromLocation &&
            nextProps.transfer.fromLocation.id,
          toLocationId:
            nextProps.transfer.toLocation && nextProps.transfer.toLocation.id,
          productDetails: handleProductDetailsData(
            nextProps.transfer.productDetails as ProductDetailsNodeConnection,
          ),
          commissionDetails: handleCommissionDetailsData(
            nextProps.transfer
              .commissionDetails as CommissionDetailsNodeConnection,
          ),
        }
      : JSON.parse(
          JSON.stringify({
            ...transferNode,
            toHeadOffice: this.props.business.type === 'branch' ? true : false,
          }),
        );
    delete transfer.fromLocation;
    delete transfer.toLocation;
    delete transfer.hsnGstWiseAmount;
    return transfer;
  };

  handleErrorPropsUpdate = (nextProps: Props) => {
    const error: ReceivingError = JSON.parse(JSON.stringify(receivingError));
    nextProps.transfer &&
      nextProps.transfer.productDetails.edges.forEach(() => {
        if (
          nextProps.transfer?.productDetails.edges.length !==
          error.productDetails.length
        ) {
          error.productDetails.push({
            productId: '',
            remark: '',
            status: '',
          });
        }
      });
    return error;
  };

  fetchBranch = () => {
    this.branchService?.branch$.subscribe((branch) =>
      this.setState({ branch }),
    );
  };

  checkProductError = () => {
    const produtDetails: ProductDetailNodeEdge[] = {
      ...this.state.transfer.productDetails,
    };
    let isError = false;
    const error = { ...this.state.error };
    error.productDetails.map((pd, index) => {
      if (produtDetails[index].status === 'PENDING') {
        isError = true;
        pd.productId = `Please Accept or Reject`;
      } else {
        isError = isError;
        pd.productId = '';
      }
      if (
        produtDetails[index].status === 'REJECTED' &&
        !produtDetails[index].remark
      ) {
        isError = true;
        pd.remark = `This is a required field`;
      } else {
        isError = isError;
        pd.remark = '';
      }
      return pd;
    });
    this.setState({ error });
    return isError;
  };

  onChangeAcceptAll = (e: { target: { name: string; value: boolean } }) => {
    const acceptAll = e.target.value;
    const transfer = { ...this.state.transfer };
    if (acceptAll) {
      transfer.productDetails = transfer.productDetails.map((pd) => ({
        ...pd,
        status: 'ACCEPTED',
      }));
    } else {
      transfer.productDetails = transfer.productDetails.map((pd) => ({
        ...pd,
        status: 'PENDING',
      }));
    }
    this.setState({ acceptAll, transfer });
  };

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.transfer.productDetails[index][e.target.name] = e.target.value;
    if (e.target.name === 'status' && e.target.value === 'REJECTED') {
      nextState.transfer.productDetails.push(
        nextState.transfer.productDetails.splice(index, 1)[0],
      );
    } else if (e.target.name === 'status' && e.target.value === 'ACCEPTED') {
      nextState.transfer.productDetails.unshift(
        nextState.transfer.productDetails.splice(index, 1)[0],
      );
    }
    this.setState(nextState);
  };

  saveReceiving = () => {
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const transfer: TransferNode<
      ProductDetailNodeEdge[],
      CommissionDetailsNode[]
    > = {
      ...this.state.transfer,
      receivingDate: moment().format('YYYY-MM-DD'),
    };
    transfer.commissionDetails = [];
    if (
      transfer.productDetails[0].status !==
      transfer.productDetails[transfer.productDetails.length - 1].status
    )
      transfer.status = 'PARTIALLY_ACCEPTED';
    else transfer.status = transfer.productDetails[0].status;
    this.transferService?.addTransfer(
      transfer,
      (response) => {
        this.setState({
          transfer: JSON.parse(JSON.stringify(transferNode)),
          error: JSON.parse(JSON.stringify(receivingError)),
        });
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Receiving ' + this.state.transfer.id ? 'Updated' : 'Added',
          description: `Receiving was successfully ${
            this.state.transfer.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Receiving ' + this.state.transfer.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      transfer: JSON.parse(
        JSON.stringify({
          ...transferNode,
          toHeadOffice: this.props.business.type === 'branch' ? true : false,
        }),
      ),
      error: { ...receivingError },
    });
  };

  render() {
    return (
      <div>
        <ReceivingForm
          values={this.state.transfer}
          error={this.state.error}
          branch={this.state.branch}
          productChangeHandler={this.productChangeHandler}
          company={this.props.company}
          business={this.props.business}
          acceptAll={this.state.acceptAll}
          onChangeAcceptAll={this.onChangeAcceptAll}
        />
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveReceiving}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  transfer?: TransferNode;
  company: CompanyNodeEdge;
  business: Bussiness;
  handleTabChange: (key) => void;
}

interface State {
  transfer: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  error: ReceivingError;
  branch: BranchEdges[];
  buttonDisabled: boolean;
  acceptAll: boolean;
}

import {
  CreateAreaInput,
  AreaInput,
  AreaNode,
  CreateAreaPayload,
} from '../schema';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { areaMutation } from '../components/master/Area/constants';

export class AreaService {
  static instance: AreaService;

  constructor() {
    if (AreaService.instance) {
      return AreaService.instance;
    } else {
      AreaService.instance = this;
    }
  }

  addArea(
    data: AreaInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as AreaNode).district;
    Api.graphql<{ input: CreateAreaInput }, CreateAreaPayload>(
      API_URL,
      { input: { area: data } },
      areaMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import * as React from 'react';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { Skeleton, Table, Row, Col, Button, Icon } from 'antd';
import { jobOrderPartialCancelReportQuery } from './constants';
import {
  JobOrderPartialCancelNode,
  JobOrderPartialCancelNodeConnection,
} from '../../schema';

export class JobOrderPartialCancelReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      after: 0,
      currentPage: 1,
    };
  }

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Job Order No',
      dataIndex: 'node.jobOrder.jobOrderNo',
      key: 'jobOrderNo',
    },
    {
      title: 'Vendor Invoice No',
      dataIndex: 'node.vendorInvoiceNo',
      key: 'vendorInvoiceNo',
    },
    {
      title: 'Vendor Date',
      dataIndex: 'node.vendorDate',
      key: 'vendorDate',
    },
    {
      title: 'Remarks',
      dataIndex: 'node.remarks',
      key: 'remarks',
    },
    {
      title: 'Receipt',
      dataIndex: 'node',
      key: 'recipt',
      render: (node: JobOrderPartialCancelNode) => (
        <a
          onClick={() => {
            this.props.handleSelectedPartialCancel(node);
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
  ];

  render() {
    const { after, currentPage } = this.state;
    const { activeKey, company } = this.props;
    const variables = {
      after,
      activeKey,
      companyId: company?.id,
    };
    return (
      <div>
        <GraphqlQuery
          queryString={jobOrderPartialCancelReportQuery}
          variables={variables}
          render={(
            response: {
              allJobOrderPartialCancel: JobOrderPartialCancelNodeConnection;
            },
            error,
            loading,
          ) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something went wrong</h3>;
            }
            if (response) {
              return [
                <Table
                  key="table"
                  dataSource={response.allJobOrderPartialCancel?.edges}
                  columns={this.columns()}
                  rowKey={(obj) => `${obj.node?.id}`}
                  pagination={false}
                  style={{ overflowX: 'scroll' }}
                />,
                <Row key="Pagination">
                  <Col offset={21} span={1}>
                    <Button onClick={this.onPrev} disabled={currentPage === 1}>
                      Prev
                    </Button>
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button type="primary">{currentPage}</Button>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={this.onNext}
                      disabled={
                        !response.allJobOrderPartialCancel?.edges?.length ||
                        response.allJobOrderPartialCancel?.edges?.length < 20
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>,
              ];
            }
            return <Skeleton active />;
          }}
        />
      </div>
    );
  }
}

interface State {
  after: number;
  currentPage: number;
}

interface Props {
  activeKey: string;
  company: CompanyNodeEdge | null;
  handleSelectedPartialCancel: (
    selectedPartialCancel: JobOrderPartialCancelNode,
  ) => void;
}

import Api from 'requestapijs';
import {
  AddProductSubCategoryPayload,
  AddProductSubCategoryVariables,
  productSubCategoryMutation,
  ProductSubCategoryNode,
} from '../components/master/ProductSubCategories/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class ProductSubCategoryService {
  static instance;

  constructor() {
    if (ProductSubCategoryService.instance) {
      return ProductSubCategoryService.instance;
    } else {
      ProductSubCategoryService.instance = this;
    }
  }

  companyService = new CompanyService();

  addProductSubCategory(
    data: ProductSubCategoryNode,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    delete data.category;
    Api.graphql<AddProductSubCategoryVariables, AddProductSubCategoryPayload>(
      API_URL,
      {
        input: {
          subCategory: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      productSubCategoryMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import { Button, notification } from 'antd';
import * as React from 'react';
import { DiscountService } from '../../../service/DiscountService';
import { DiscountNode, discountObject } from './constants';
import { DiscountForm } from './DiscountForm';

export class AddDiscount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      discount: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
    };
  }

  discountService = new DiscountService();

  handlePropsValueUpdate = (props: Props) => {
    const discount = props.discount
      ? {
          ...props.discount,
          categoryId: props.discount.category?.id,
          branchId: props.discount.branch?.id,
        }
      : JSON.parse(JSON.stringify(discountObject));

    return discount;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      discount: this.handlePropsValueUpdate(nextProps),
    });
  }

  componentDidMount() {}

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const discount: DiscountNode = {
      ...this.state.discount,
    };
    discount[e.target.name] = e.target.value;
    this.setState({ discount });
  };

  saveDiscount = () => {
    this.setState({ buttonDisabled: true });
    this.discountService?.addDiscount(
      this.state.discount,
      () => {
        notification.success({
          message: 'Discount' + this.state.discount.id ? 'Updated' : 'Added',
          description: `Discount was successfully ${
            this.state.discount.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
        this.clear();
      },
      (error) => {
        notification.error({
          message:
            'Discount ' + this.state.discount.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      discount: JSON.parse(JSON.stringify(discountObject)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <DiscountForm values={this.state.discount} onChange={this.onChange} />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveDiscount}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  discount?: DiscountNode;
  handleTabChange?: (key) => void;
  companyId?: string | null;
}

interface State {
  discount: DiscountNode;
  buttonDisabled: boolean;
}

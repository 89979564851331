export enum Feature {
  // Master
  BRANCH = 'BRANCH',
  UNIT = 'UNIT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_SUB_CATEGORY = 'PRODUCT_SUB_CATEGORY',
  HSN = 'HSN',
  PRODUCT = 'PRODUCT',
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  REMUNERATION = 'REMUNERATION',
  USER = 'USER',
  STATE = 'STATE',
  DISTRICT = 'DISTRICT',
  AREA = 'AREA',
  SUB_AREA = 'SUB_AREA',
  PRODUCT_CONVERSION = 'PRODUCT_CONVERSION',
  CONTRACTOR = 'CONTRACTOR',
  DISCOUNT_CATEGORY = 'DISCOUNT_CATEGORY',
  DISCOUNT = 'DISCOUNT',
  SALES_PERSON = 'SALES_PERSON',

  // Inventory
  MASTER = 'MASTER',
  OPENING_STOCK = 'OPENING_STOCK',
  SALE = 'SALE',
  VALUE_ADDITION = 'VALUE_ADDITION',
  PURCHASE = 'PURCHASE',
  PURHCASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_ORDER_REPORT = 'PURCHASE_ORDER_REPORT',
  CHALLAN = 'CHALLAN',
  MARGIN = 'MARGIN',
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  TRANSFER = 'TRANSFER',
  RECEIVING = 'RECEIVING',
  DAMAGE = 'DAMAGE',
  CODE_CONVERSION = 'CODE_CONVERSION',
  STOCK_REPORT = 'STOCK_REPORT',
  SALE_INVOICE = 'SALE_INVOICE',
  APPROVAL = 'APPROVAL',
  GIFT = 'GIFT',
  DEPOSIT = 'DEPOSIT',
  REQUEST = 'REQUEST',
  DELIVERY_INSTRUCTION = 'DELIVERY_INSTRUCTION',
  ISSUE = 'ISSUE',
  MEMO = 'MEMO',

  // Production
  PRODUCTION_REPORT = 'PRODUCTION_REPORT',
  PRODUCTION_TRANSFER = 'PRODUCTION_TRANSFER',
  PRODUCTION_RECEIVING = 'PRODUCTION_RECEIVING',
  JOB_ORDER = 'JOB_ORDER',
  CONVERSION = 'CONVERSION',
  DIRECT_PRODUCTION = 'DIRECT_PRODUCTION',
  QUOTATION = 'QUOTATION',
}

export type SideBarMenu = Partial<Record<Feature, JSX.Element>>;

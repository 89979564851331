import * as React from 'react';
import { ConversionInput, ProductNode } from '../../../schema';
import {
  Row,
  DatePicker,
  Col,
  Divider,
  Button,
  Select,
  InputNumber,
} from 'antd';
import moment from 'moment';
import { ProductDropDown } from '../../master/Product/ProductDropdown';
import { TSInput } from '../../common/TSInput';
import { PRODUCTION_TYPE, HIND_PROD_TYPE } from './constants';
import { CompanyNodeEdge } from '../../master/Company/constants';
import FileBase64 from 'react-file-base64';
import { RemunerationDropDown } from '../../master/Remuneration/RemunerationDropdown';

export const ConversionForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={3}>
          <DatePicker
            value={
              props.values?.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(e) =>
              props.onChange({
                target: {
                  name: 'date',
                  value: e && moment(e).format('YYYY-MM-DD'),
                },
              })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Col>
        <Col span={3}>
          <Select
            style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
            placeholder="Select Production Type"
            value={props.values?.productionType}
            onChange={(e) =>
              props.onChange({
                target: { name: 'productionType', value: String(e) },
              })
            }
            showSearch
          >
            {!props.company.name.toLowerCase().includes('tiles')
              ? PRODUCTION_TYPE.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))
              : HIND_PROD_TYPE.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
          </Select>
        </Col>
        <Col span={5}>
          <TSInput
            error=""
            type="text"
            value={props.values?.finishedProduct}
            name="finishedProduct"
            placeholder="Finished Product"
            onChange={(e) => props.onChange(e)}
            required
          />
        </Col>
        <Col span={4}>
          <TSInput
            error=""
            type="text"
            value={props.values?.oldDesignCode}
            name="oldDesignCode"
            placeholder="Old Design Code"
            onChange={(e) => props.onChange(e)}
            required
          />
        </Col>
        <Col span={5}>
          <div style={{ marginLeft: '20px' }}>
            {props.values?.image ? (
              <img width="100px" src={props.values.image} />
            ) : (
              'Image'
            )}
            <br />
            <FileBase64
              onDone={(file) => {
                props.onChange({
                  target: { name: 'image', value: file.base64 },
                });
              }}
            />
          </div>
        </Col>
        <Col span={4}>
          <TSInput
            error=""
            type="text"
            value={props.values?.remark}
            name="remark"
            placeholder="Description"
            onChange={(e) => props.onChange(e)}
          />
        </Col>
      </Row>
      <Divider />
      <h4>Raw Materials</h4>
      <Row>
        <Col span={6}>Product</Col>
        <Col span={2}>Quantity</Col>
        <Col span={3}>Rate</Col>
        <Col span={2}>Wastage(%)</Col>
        <Col span={2}>Wastage</Col>
        <Col span={3}>Amount</Col>
        <Col span={3}>
          {props.values?.productionType === 'SERICULTURE'
            ? 'Peduncle Qty'
            : 'Remarks'}
        </Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values?.rawMaterials?.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={6}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.productObjChangehandler((f as any).props.label, index);
                  props.productChangeHandler(
                    { target: { name: 'productId', value: String(e) } },
                    index,
                    'product',
                  );
                }}
                allowClear
                extrafields={[
                  'purchaseRate',
                  'sellingRate',
                  'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                ]}
                company={props.company}
                value={product.productId || ''}
                productTypeIn={
                  props.company.name.toLowerCase().includes('tiles')
                    ? 'SEMI_FINISHED, RAW_MATERIAL'
                    : ''
                }
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'product')
                }
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error=""
                type="number"
                value={product.purchaseRate || ''}
                name="purchaseRate"
                placeholder="Rate"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'product')
                }
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={props.wastageList?.[index]}
                name="wastage"
                placeholder="Wastage(%)"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'wastageList')
                }
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.wastage}
                name="wastage"
                placeholder="Wastage"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'product')
                }
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error=""
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
                readOnly
              />
            </Col>
            {props.values?.productionType === 'SERICULTURE' ? (
              <Col span={3}>
                <TSInput
                  error=""
                  type="number"
                  value={product.peduncleQuantity}
                  name="peduncleQuantity"
                  placeholder="Peduncle Quantity"
                  onChange={(e) =>
                    props.productChangeHandler(e, index, 'product')
                  }
                  required
                />
              </Col>
            ) : (
              <Col span={3}>
                <TSInput
                  error=""
                  type="text"
                  value={product.remark || ''}
                  name="remark"
                  placeholder="Remark"
                  onChange={(e) =>
                    props.productChangeHandler(e, index, 'product')
                  }
                  required
                />
              </Col>
            )}
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('-', index, 'product')}
                disabled={props.values?.rawMaterials?.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('+', index, 'product')}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Col span={24}>
        <TSInput
          error=""
          type="text"
          value={props.values?.rawMaterialsCost}
          name="rawMaterialsCost"
          placeholder="Raw Materials Cost"
          addonBefore="Total Raw Materials Cost"
          readOnly
        />
      </Col>
      <Divider />
      <h4>Remunerations</h4>
      <Row>
        <Col span={6}>Remuneration</Col>
        <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
          Quantity
        </Col>
        <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
          Rate
        </Col>
        {props.values?.productionType === 'HANDLOOM' && (
          <Col span={3}>Supervision Charge(%)</Col>
        )}
        <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
          Amount
        </Col>
        <Col span={4}>Remark</Col>
        <Col span={1}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values?.remunerations?.map((remuneration, index) => (
        <div key={'remunerations' + index}>
          <Row>
            <Col span={6}>
              <RemunerationDropDown
                onSelect={(e, f) => {
                  props.productChangeHandler(
                    { target: { name: 'remunerationId', value: String(e) } },
                    index,
                    'remuneration',
                  );
                }}
                value={remuneration.remunerationId}
                allowClear
              />
            </Col>
            <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
              <TSInput
                error=""
                type="number"
                value={remuneration.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'remuneration')
                }
                required
              />
            </Col>
            <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
              <TSInput
                error=""
                type="number"
                value={remuneration.rate || ''}
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'remuneration')
                }
                name="rate"
                placeholder="Rate"
                required
              />
            </Col>
            {props.values?.productionType === 'HANDLOOM' && (
              <Col span={3}>
                <InputNumber
                  style={{ marginTop: '5px', width: '100%' }}
                  value={remuneration.supervisionCharge || 0}
                  name="supervisionCharge"
                  placeholder="Supervision Charge(%)"
                  onChange={(e) =>
                    props.productChangeHandler(
                      {
                        target: { name: 'supervisionCharge', value: Number(e) },
                      },
                      index,
                      'remuneration',
                    )
                  }
                  required
                  max={10}
                />
              </Col>
            )}
            <Col span={props.values?.productionType === 'HANDLOOM' ? 3 : 4}>
              <TSInput
                error=""
                type="number"
                value={remuneration.amount || ''}
                name="amount"
                placeholder="Amount"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'remuneration')
                }
                required
                readOnly
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="text"
                value={remuneration.remark || ''}
                name="remark"
                placeholder="Remark"
                onChange={(e) =>
                  props.productChangeHandler(e, index, 'remuneration')
                }
                required
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('-', index, 'remuneration')}
                disabled={props.values?.remunerations?.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('+', index, 'remuneration')}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Col span={24}>
        <TSInput
          error=""
          type="text"
          value={props.values?.remunerationsCost}
          name="remunerationsCost"
          placeholder="Remuneration Cost"
          addonBefore="Total Remuneration Cost"
          readOnly
        />
      </Col>
      <Divider />
      <Col span={24}>
        <TSInput
          error=""
          type="text"
          value={props.values?.netCost}
          name="netCost"
          placeholder="Net Cost"
          addonBefore="Net Cost"
          readOnly
        />
      </Col>
    </div>
  );
};

interface Props {
  values?: ConversionInput;
  company: CompanyNodeEdge;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string | number } },
    index: number,
    rowType: string,
  ) => void;
  updateRow: (type: string, index: number, rowType: string) => void;
  productObjChangehandler: (productObj: ProductNode, index: number) => void;
  wastageList?: string[];
}

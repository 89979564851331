import { BehaviorSubject } from 'rxjs';
import { CompanyService } from './CompanyService';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { DateService } from './DateService';
import {
  ProductionTransferInput,
  CreateProductionTransferInput,
  CreateProductionTransferPayload,
  ConversionDetailNode,
} from '../schema';
import {
  ProductionTransferEdges,
  productionTransferQuery,
  productionTransferMutation,
  ProductionTransferQueryResponse,
} from '../components/Production/ProductionTransfer/constants';

export class ProductionTransferService {
  static instance: ProductionTransferService;
  constructor() {
    if (ProductionTransferService.instance) {
      return ProductionTransferService.instance;
    } else {
      ProductionTransferService.instance = this;
    }
    this.onInit();
  }

  public readonly productionTransfer$ = new BehaviorSubject<
    ProductionTransferEdges[]
  >([]);
  companyService = new CompanyService();
  dateService = new DateService();

  onInit() {
    this.companyService?.selectedBussiness$.subscribe((bussiness) => {
      if (!bussiness) return;
      bussiness.type === 'branch'
        ? this.getAll({
            companyId: bussiness.companyId,
            fromBranchId: bussiness.id,
            fromHeadOffice: false,
          })
        : this.getAll({
            companyId: bussiness.id,
            fromBranchId: null,
            fromHeadOffice: true,
          });
    });
  }

  getAll({
    companyId: companyId,
    fromBranchId: fromBranchId,
    fromHeadOffice: fromHeadOffice,
  }) {
    Api.graphql<
      {
        companyId: string;
        fromBranchId: string;
        fromHeadOffice: boolean;
        date_Lte?: string;
      },
      ProductionTransferQueryResponse
    >(
      API_URL,
      {
        companyId,
        fromBranchId,
        fromHeadOffice,
        date_Lte: this.dateService?.date$.value,
      },
      productionTransferQuery,
      HEADER,
      (response) =>
        this.productionTransfer$.next(
          response.data.allProductionTransfers.edges,
        ),
      (error) => error,
    );
  }

  addProductionTransfer(
    data: ProductionTransferInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.conversionDetails.map((conversion) => {
      conversion.date = data.date;
      conversion.sourceBranchId = data.fromBranchId;
      conversion.destinationBranchId = data.toBranchId;
      conversion.vendorForId = data.vendorForId;
      conversion.companyId = this.companyService?.company$.getValue()?.id;
      delete (conversion as any as ConversionDetailNode).conversion;
      delete (conversion as any as ConversionDetailNode).hsn;
      delete (conversion as any as ConversionDetailNode).sourceBranch;
      delete (conversion as any as ConversionDetailNode).destinationBranch;
      delete (conversion as any as ConversionDetailNode).vendorFor;
      return conversion;
    });
    Api.graphql<
      { input: CreateProductionTransferInput },
      CreateProductionTransferPayload
    >(
      API_URL,
      {
        input: {
          productionTransfer: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      productionTransferMutation,
      HEADER,
      (response) => {
        onSuccess(response);
        this.onInit();
      },
      (error) => onError(error),
    );
  }
}

import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import {
  CustomerEdges,
  AddCustomerVariables,
  customerMutation,
} from '../components/master/Customer/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class CustomerService {
  static instance: CustomerService;
  public readonly customer$ = new BehaviorSubject<CustomerEdges[]>([]);
  compnayService = new CompanyService();

  constructor() {
    if (CustomerService.instance) {
      return CustomerService.instance;
    } else {
      CustomerService.instance = this;
    }
  }

  addCustomer(data, onSuccess?: (response) => void, onError?: (error) => void) {
    Api.graphql<AddCustomerVariables, {}>(
      API_URL,
      {
        input: {
          customer: {
            ...data,
            companyId: this.compnayService?.company$.value?.id,
          },
        },
      },
      customerMutation,
      HEADER,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => onError && onError(error),
    );
  }
}

import * as React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { SalesNodeEdgeQuery, SalesEdges, salesQuery } from './constants';
import { Table, Tabs, Icon, Spin, Skeleton, Row, Col } from 'antd';
import { checkAddPermission } from '../helper';
import { SalesService } from '../../service/SalesService';
import { AddSales } from './AddSales';
import { SaleReciept } from './SaleReciept';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { DailySalesReportBranch } from './DailySalesReportBranch';
import { ItemWiseSalesReportBranch } from './ItemWiseSalesReportBranch';
import { RangeWiseSalesReportBranch } from './RangeWiseSalesReportBranch';
import { DailySalesReportCentral } from './DailySalesReportCentral';
import { ItemWiseSalesReportCentral } from './ItemWiseSalesReportCentral';
import { RangeWiseSalesReportCentral } from './RangeWiseSalesReportCentral';
import { PaymentModeSalesReportBranch } from './PaymentModeSalesReportBranch';
import { SaleReceiptJharcraft } from './SaleReceiptJharcraft';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { DateService } from '../../service/DateService';
import { SalesNodeConnection } from '../../schema';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../common/TSInput';
import { BranchNodeEdge } from '../master/Branch/constants';
import { SalesCancellation } from './SalesCancellation';
import { ExchangeReport } from './ExchangeReport';
import { CreditSalesReport } from './CreditSalesReport';
import { CreditPaymentReport } from './CreditPaymentReport';
import { MonthlySalesReport } from './MonthlySalesReport';
import { MonthlyGSTSalesReport } from './MonthlyGSTSalesReport';
import { CashReport } from './CashReport';
import { MonthlyGSTSlabWiseReport } from './MonthlyGSTSlabWiseReport';
import { PaymentModeSalesReportCentral } from './PaymentModeSalesReportCentral';
import { MasterDataReport } from './MasterDataReport';
import { ItemWiseDailySalesReport } from './ItemWiseDailySalesReport';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkViewPermission } from '../helper';
import { SaleRecieptFashion11 } from './SaleRecieptFashion11';
import { CustomerHistory } from '../master/Customer/CustomerHistory';
import { SalesCancelReport } from './SalesCancelReport';
import { SaleReceiptKhadi } from './SaleReceiptKhadi';
import { SaleReceiptSugarHigh } from './SalesReceiptSugarHigh';
import { SaleReceiptThermalPrinter } from './SalesReceiptThermalPrinter';

export class Sales extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedSales: null,
      isEditActive: false,
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      billNo: '',
      branchForCancellation: null,
      user: null,
      branch: null,
    };
  }

  salesService = new SalesService();
  companyService = new CompanyService();
  dateService = new DateService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (
      activeKey === '1' ||
      this.state.activeKey === '3' ||
      this.state.activeKey === '6'
    ) {
      this.setState({ activeKey, selectedSales: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  salesColumn = () => {
    let salesColumns = [
      {
        title: 'Bill No',
        dataIndex: 'node.billNo',
        key: 'billNo',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({
                selectedSales: obj,
                activeKey: '2',
                isEditActive: true,
              });
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (text) => moment(text).format('DD-MM-YYYY'),
      },
      {
        title: 'Customer',
        dataIndex: 'node.customer.name',
        key: 'customer',
      },
      {
        title: 'Mode',
        key: 'paymentMode',
        render: (value, record) =>
          [
            ...new Set(
              (record as SalesEdges).node.paymentModes.edges.map(
                (pm) => pm.node.paymentType,
              ),
            ).values(),
          ].join(', '),
      },
      {
        title: 'Bill Amount',
        dataIndex: 'node.billAmount',
        key: 'billAmount',
        render: (value, record) => Number(Number(value).toFixed(2)),
      },
    ];
    if (this.state.company && this.state.company.gstRate) {
      salesColumns = [
        ...salesColumns,
        {
          title: 'Disc(%)',
          dataIndex: 'node.discount',
          key: 'discount',
        },
      ];
    }
    salesColumns = [
      ...salesColumns,
      {
        title: 'Gross Amount',
        dataIndex: 'node.grossAmount',
        key: 'grossAmount',
        render: (value, record: SalesEdges) =>
          this.state.company && this.state.company.gstRate
            ? value
            : Number(record.node.totalAmount?.toFixed(2)),
      },
      {
        title: 'Net Amount',
        dataIndex: 'node.netAmount',
        key: 'netAmount',
        render: (value, record) => Number(Number(value).toFixed(2)),
      },
      {
        title: 'Receipt',
        key: 'receipt',
        dataIndex: 'receipt',
        render: (text, obj: SalesEdges) => (
          <a
            onClick={() => {
              this.setState({ selectedSales: obj, activeKey: '3' });
            }}
          >
            <Icon type="download" />
          </a>
        ),
      },
    ];
    return salesColumns;
  };

  handleSelectedSales = (
    selectedSales: SalesEdges,
    activeKey: string,
    branchId: string,
  ) => {
    const branchForCancellation = this.state.company?.branches?.edges.find(
      (br) => br.node.id === branchId,
    )?.node;
    this.setState({ selectedSales, activeKey, branchForCancellation });
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;

    const {
      company: { id: companyId },
      business: { id: businessId },
      after,
      currentPage,
      activeKey,
      billNo,
    } = this.state;
    const date_Lte = this.dateService?.date$.value;
    const variables = {
      companyId,
      branchId: businessId,
      date_Lte,
      activeKey,
      first: 20,
    };
    return (
      <div>
        {this.companyService?.company$.value &&
        this.companyService.selectedBussiness$.value ? (
          this.companyService.selectedBussiness$.value.type === 'branch' ? (
            <Tabs
              activeKey={this.state.activeKey}
              onChange={this.handleTabChange}
            >
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    List Sales
                  </span>
                }
                key="1"
                closable={false}
              >
                <Row>
                  <Col span={8}>
                    <TSInput
                      error=""
                      value={this.state.billNo}
                      onChange={(e) =>
                        this.setState({ billNo: e.target.value })
                      }
                      name="billNo"
                      type="text"
                      addonBefore="Search"
                      placeholder="Enter Bill No"
                    />
                  </Col>
                </Row>
                <GraphqlQuery
                  queryString={salesQuery}
                  variables={
                    billNo ? { ...variables, billNo } : { ...variables, after }
                  }
                  render={(
                    response: { allSales: SalesNodeConnection },
                    error,
                    loading,
                  ) => {
                    if (loading) return <Skeleton active />;
                    if (response && companyId) {
                      return [
                        <Table
                          key="table"
                          dataSource={response.allSales.edges}
                          columns={this.salesColumn()}
                          rowKey={(obj) => obj.node?.id || ''}
                          pagination={false}
                        />,
                        <Pagination
                          key="pagination"
                          totalCount={0}
                          currentPage={currentPage}
                          batchSize={30}
                          cursorBasedPagination
                          onChange={(_p, _ps, next) =>
                            this.onPageChange(
                              next,
                              response.allSales.pageInfo.endCursor,
                              response.allSales.pageInfo.hasNextPage,
                            )
                          }
                          style={{ float: 'right' }}
                        />,
                      ];
                    }
                    return <Skeleton active />;
                  }}
                />
              </Tabs.TabPane>
              {this.state.user && checkAddPermission(this.state.user) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="plus" />
                      Add Sales
                    </span>
                  }
                  key="2"
                >
                  <AddSales
                    sales={this.state.selectedSales?.node}
                    handleTabChange={this.handleTabChange}
                    isEditActive={this.state.isEditActive}
                    company={this.companyService?.company$.value}
                    business={this.state.business}
                  />
                </Tabs.TabPane>
              )}
              {this.state.selectedSales && this.state.activeKey === '3' && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="file" />
                      Receipt
                    </span>
                  }
                  key="3"
                >
                  <PDFViewer style={{ width: '100%', height: '1000px' }}>
                    {this.companyService?.company$.value.name ===
                    'Jharcraft' ? (
                      <SaleReceiptJharcraft
                        company={this.companyService?.company$.value}
                        branch={this.companyService.selectedBussiness$.value}
                        sale={this.state.selectedSales.node}
                      />
                    ) : this.companyService?.company$.value.name
                        .toLowerCase()
                        .includes('khadi') ? (
                      <SaleReceiptKhadi
                        company={this.companyService?.company$.value}
                        branch={this.companyService.selectedBussiness$.value}
                        sale={this.state.selectedSales.node}
                      />
                    ) : this.companyService?.company$.value.name ===
                      'Fashion 11-11' ? (
                      <SaleRecieptFashion11
                        company={this.companyService?.company$.value}
                        sale={this.state.selectedSales.node}
                        branch={this.companyService.selectedBussiness$.value}
                      />
                    ) : this.companyService?.company$.value.name
                        .toLowerCase()
                        .includes('sugar high') ? (
                      <SaleReceiptSugarHigh
                        company={this.companyService?.company$.value}
                        sale={this.state.selectedSales.node}
                        branch={this.companyService.selectedBussiness$.value}
                      />
                    ) : this.companyService?.company$.value.name
                        .toLowerCase()
                        .includes('ambalika') ? (
                      <SaleReceiptThermalPrinter
                        company={this.companyService?.company$.value}
                        sale={this.state.selectedSales.node}
                        branch={this.companyService.selectedBussiness$.value}
                      />
                    ) : (
                      <SaleReciept
                        company={this.companyService?.company$.value}
                        sale={this.state.selectedSales.node}
                        branch={this.companyService.selectedBussiness$.value}
                      />
                    )}
                  </PDFViewer>
                </Tabs.TabPane>
              )}
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Daily Sales Report
                  </span>
                }
                key="4"
                closable={false}
              >
                <DailySalesReportBranch
                  branch={this.state.business}
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              {/* <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Item Wise Sales Report
                  </span>
                }
                key="5"
                closable={false}
              >
                <ItemWiseSalesReportBranch
                  company={this.companyService?.company$.value}
                  branch={this.state.business}
                />
              </Tabs.TabPane> */}

              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Item Wise Daily Sales Report
                  </span>
                }
                key="6"
                closable={false}
              >
                <ItemWiseDailySalesReport
                  company={this.companyService?.company$.value}
                  branch={this.state.business}
                />
              </Tabs.TabPane>
              {this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi') || (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <Icon type="table" />
                        Range Wise Sales Report
                      </span>
                    }
                    key="7"
                    closable={false}
                  >
                    <RangeWiseSalesReportBranch
                      company={this.companyService?.company$.value}
                      branch={this.state.business}
                    />
                  </Tabs.TabPane>
                )}
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Item Wise Exchange Report
                  </span>
                }
                key="8"
                closable={false}
              >
                <ExchangeReport
                  company={this.companyService?.company$.value}
                  branch={this.state.business}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Payment Mode Wise Sales Report
                  </span>
                }
                key="9"
                closable={false}
              >
                <PaymentModeSalesReportBranch
                  company={this.state.company}
                  business={this.state.business}
                />
              </Tabs.TabPane>
              {(this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi')) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Credit Sales Report
                    </span>
                  }
                  key="10"
                  closable={false}
                >
                  <CreditSalesReport
                    company={this.companyService?.company$.value}
                    business={this.state.business}
                    branch={this.state.branch}
                  />
                </Tabs.TabPane>
              )}
              {(this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi')) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Credit Payment History Report
                    </span>
                  }
                  key="11"
                  closable={false}
                >
                  <CreditPaymentReport
                    company={this.companyService?.company$.value}
                    business={this.state.business}
                  />
                </Tabs.TabPane>
              )}
              {(this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi')) && [
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Cash Bank Report
                    </span>
                  }
                  key="12"
                  closable={false}
                >
                  <CashReport
                    company={this.companyService?.company$.value}
                    branch={this.state.business}
                  />
                </Tabs.TabPane>,
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Customer History Report
                    </span>
                  }
                  key="13"
                  closable={false}
                >
                  <CustomerHistory
                    companyId={this.companyService.company$.value.id}
                    branchId={this.state.business.id}
                    company={this.state.company}
                  />
                </Tabs.TabPane>,
                // <Tabs.TabPane tab={<span><Icon type='table' />Monthly GST Slab Wise Report</span>} key='12' closable={false}>
                //   <MonthlyGSTSlabWiseReport
                //     company={this.companyService?.company$.value}
                //     branch={this.state.business}
                //   />
                // </Tabs.TabPane>,
              ]}
            </Tabs>
          ) : (
            <Tabs
              activeKey={this.state.activeKey}
              onChange={this.handleTabChange}
            >
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Daily Sales Report
                  </span>
                }
                key="1"
                closable={false}
              >
                <DailySalesReportCentral
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Item Wise Sales Report
                  </span>
                }
                key="2"
                closable={false}
              >
                <ItemWiseSalesReportCentral
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              {this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi') || (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <Icon type="table" />
                        Range Wise Sales Report
                      </span>
                    }
                    key="3"
                    closable={false}
                  >
                    <RangeWiseSalesReportCentral
                      company={this.companyService?.company$.value}
                    />
                  </Tabs.TabPane>
                )}
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Cancel Sales
                  </span>
                }
                key="4"
                closable={false}
              >
                <SalesCancellation
                  company={this.companyService?.company$.value}
                  handleSelectedSales={this.handleSelectedSales}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Item Wise Sales Cancel Report
                  </span>
                }
                key="14"
                closable={false}
              >
                <SalesCancelReport
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              {this.state.activeKey === '5' && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="plus" />
                      Add Sales
                    </span>
                  }
                  key="5"
                >
                  <AddSales
                    sales={this.state.selectedSales?.node}
                    handleTabChange={this.handleTabChange}
                    isEditActive={this.state.isEditActive}
                    company={this.companyService?.company$.value}
                    business={this.state.business}
                  />
                </Tabs.TabPane>
              )}
              {this.state.selectedSales &&
                this.state.branchForCancellation &&
                this.state.activeKey === '6' && (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <Icon type="file" />
                        Receipt
                      </span>
                    }
                    key="6"
                  >
                    <PDFViewer style={{ width: '100%', height: '1000px' }}>
                      {this.companyService?.company$.value.name ===
                      'Jharcraft' ? (
                        <SaleReceiptJharcraft
                          company={this.companyService?.company$.value}
                          branch={this.state.branchForCancellation}
                          sale={this.state.selectedSales.node}
                        />
                      ) : this.companyService?.company$.value.name
                          .toLowerCase()
                          .includes('khadi') ? (
                        <SaleReceiptKhadi
                          company={this.companyService?.company$.value}
                          branch={this.state.branchForCancellation}
                          sale={this.state.selectedSales.node}
                        />
                      ) : this.companyService?.company$.value.name ===
                        'Fashion 11-11' ? (
                        <SaleRecieptFashion11
                          company={this.companyService?.company$.value}
                          sale={this.state.selectedSales.node}
                          branch={this.state.branchForCancellation}
                        />
                      ) : (
                        <SaleReciept
                          company={this.companyService?.company$.value}
                          sale={this.state.selectedSales.node}
                          branch={this.state.branchForCancellation}
                        />
                      )}
                    </PDFViewer>
                  </Tabs.TabPane>
                )}
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Payment Mode Wise Sales Report
                  </span>
                }
                key="7"
                closable={false}
              >
                <PaymentModeSalesReportCentral company={this.state.company} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Monthly Sales Report
                  </span>
                }
                key="8"
                closable={false}
              >
                <MonthlySalesReport
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="table" />
                    Monthly GST Sales Report
                  </span>
                }
                key="9"
                closable={false}
              >
                <MonthlyGSTSalesReport
                  company={this.companyService?.company$.value}
                />
              </Tabs.TabPane>
              {(this.companyService?.company$.value.name === 'Jharcraft' ||
                this.companyService?.company$.value.name
                  .toLowerCase()
                  .includes('khadi')) && [
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Cash Bank Report
                    </span>
                  }
                  key="10"
                  closable={false}
                >
                  <CashReport company={this.companyService?.company$.value} />
                </Tabs.TabPane>,
                // <Tabs.TabPane tab={<span><Icon type='table' />Monthly GST Slab Wise Report</span>} key='11' closable={false}>
                //   <MonthlyGSTSlabWiseReport
                //     company={this.companyService?.company$.value}
                //   />
                // </Tabs.TabPane>,
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="table" />
                      Master Data
                    </span>
                  }
                  key="12"
                  closable={false}
                >
                  <MasterDataReport
                    company={this.companyService?.company$.value}
                  />
                </Tabs.TabPane>,
              ]}
            </Tabs>
          )
        ) : (
          <Spin />
        )}
      </div>
    );
  }
}

interface State {
  activeKey: string;
  selectedSales: {
    cursor: string;
    node: SalesNodeEdgeQuery;
  } | null;
  isEditActive: boolean;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  billNo: string;
  after: string;
  currentPage: number;
  branchForCancellation?: BranchNodeEdge | null;
  user?: UserNodeEdge | null;
  branch: BranchNodeEdge | null;
}

interface Props {}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  ChallanInput,
  CreateChallanInput,
  CreateChallanPayload,
  ChallanNode,
} from '../schema';
import { challanMutation } from '../components/Challan/constants';

export class ChallanService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (ChallanService.instance) {
      return ChallanService.instance;
    } else {
      ChallanService.instance = this;
    }
  }

  addChallan(
    data: ChallanInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as ChallanNode).purchaseOrder;
    delete (data as any as ChallanNode).dumpLocation;
    Api.graphql<{ input: CreateChallanInput }, CreateChallanPayload>(
      API_URL,
      {
        input: {
          challan: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      challanMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import { IssueInput, CreateIssuePayload, CreateIssueInput } from '../schema';
import { issueMutation } from '../components/Production/Issue/constants';

export class IssueService {
  static instance;

  constructor() {
    if (IssueService.instance) {
      return IssueService.instance;
    } else {
      IssueService.instance = this;
    }
  }

  companyService = new CompanyService();

  addIssue(
    data: IssueInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete (pd as any).product;
      delete (pd as any).movementFor;
      return pd;
    });
    Api.graphql<{ input: CreateIssueInput }, CreateIssuePayload>(
      API_URL,
      {
        input: {
          issue: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      issueMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import * as React from 'react';
import { CompanyNodeEdge } from './constants';
import { TSInput } from '../../common/TSInput';
import { capitalize } from 'lodash';
import { AddCompany } from './AddCompany';
import { Icon, Tooltip } from 'antd';
import { CompanyService } from '../../../service/CompanyService';

export class CompanyPanel extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      panelType: panelType.VIEW,
    };
  }

  companyService = new CompanyService();

  content = () => {
    switch (this.state.panelType) {
      case panelType.VIEW:
        return (
          <div>
            <TSInput
              error={''}
              value={this.props.company.name}
              name="name"
              type="text"
              addonBefore="Name"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.gstin}
              name="gstin"
              type="text"
              addonBefore="GST IN"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.gstRate || 0}
              name="gstRate"
              type="number"
              addonBefore="GST Rate"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.hsnCode}
              name="hsnCode"
              type="text"
              addonBefore="HSN Code"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.address}
              name="address"
              type="text"
              addonBefore="Address"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.state}
              name="state"
              type="text"
              addonBefore="State"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.contactNo}
              name="contactNo"
              type="number"
              addonBefore="Contact No"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.amountPerPointSale || 0}
              name="amountPerPointSale"
              type="number"
              addonBefore="Amount Per Point Sale"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.amountPerPointRedeem || ''}
              name="amountPerPointRedeem"
              type="text"
              addonBefore="Amount Per Point Redeem"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.bankName}
              name="bank_name"
              type="text"
              addonBefore="Bank Name"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.branch}
              name="branch"
              type="text"
              addonBefore="Branch"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.accountNo}
              name="accountNo"
              type="text"
              addonBefore="Account Number"
              disabled
            />
            <TSInput
              error={''}
              value={this.props.company.ifscCode}
              name="ifscCode"
              type="text"
              addonBefore="IFSC Code"
              disabled
            />
          </div>
        );
      case panelType.EDIT:
        return <AddCompany company={this.props.company} />;
    }
  };

  togglePanel = () => {
    this.setState({
      panelType:
        this.state.panelType === panelType.EDIT
          ? panelType.VIEW
          : panelType.EDIT,
    });
  };

  render() {
    return (
      <div>
        <h2>{capitalize(this.props.company.name)}</h2>
        {this.props.isSuperUser && (
          <div>
            <Tooltip title="Edit">
              <Icon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  padding: '5px',
                  border: '1px solid #40a9ff',
                  borderRadius: '50%',
                }}
                onClick={this.togglePanel}
                type="edit"
              />
            </Tooltip>
            <Tooltip title="Use">
              <Icon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  padding: '5px',
                  border: '1px solid #40a9ff',
                  borderRadius: '50%',
                }}
                onClick={() => this.companyService?.changeBussiness('company')}
                type="pushpin"
              />
            </Tooltip>
          </div>
        )}
        {this.content()}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  isSuperUser: boolean;
}

enum panelType {
  'VIEW' = 'VIEW',
  'EDIT' = 'EDIT',
}

interface State {
  panelType: panelType;
}

import * as React from 'react';
import { Tabs, Table, Icon } from 'antd';
import { DamageEdges } from './constants';
import { DamageService } from '../../service/DamageService';
import { AddDamage } from './AddDamage';
import { CompanyNodeEdge } from '../master/Company/constants';
import { CompanyService } from '../../service/CompanyService';
import moment from 'moment';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';
import { DamageReport } from './DamageReport';

export class Damage extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      damages: [],
      activeKey: '1',
      selectedDamage: null,
      company: null,
      isEditActive: false,
      user: null,
    };
  }

  damageService = new DamageService();
  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.damageService?.damage$.subscribe((damages) =>
      this.setState({ damages }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedDamage: null, isEditActive: false });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  damageColumn = () => [
    {
      title: 'Date',
      dataIndex: 'node.date',
      key: 'date',
      render: (value, obj) => (
        <a
          onClick={() => {
            this.setState({
              selectedDamage: obj,
              activeKey: '2',
              isEditActive: true,
            });
          }}
        >
          {value && moment(value).format('DD-MM-YYYY')}
        </a>
      ),
    },
    {
      title: 'Products',
      key: 'products',
      render: (value, record) => {
        const products = record.node.productDetails.edges.map(
          (ed) => ed.node.product.name,
        );
        return products.join(', ');
      },
    },
  ];

  render() {
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Damage
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.damages}
            columns={this.damageColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        {this.state?.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Damage
              </span>
            }
            key="2"
          >
            <AddDamage
              damage={this.state.selectedDamage?.node}
              handleTabChange={this.handleTabChange}
              company={this.state.company}
              isEditActive={this.state.isEditActive}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Item Wise Damage Report
            </span>
          }
          key="3"
          closable={false}
        >
          <DamageReport company={this.state.company} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  damages: DamageEdges[];
  activeKey: string;
  selectedDamage: DamageEdges | null;
  company: CompanyNodeEdge | null;
  isEditActive: boolean;
  user?: UserNodeEdge | null;
}

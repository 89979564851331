import * as React from 'react';
import { PurchaseNodeEdgeMutation } from './constants';
import { TSInput } from '../common/TSInput';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { DatePicker, Divider, Row, Col, Button } from 'antd';
import moment from 'moment';
import { VendorEdges } from '../master/vendor/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import { ProductNode } from '../../schema';
import { CompanyNodeEdge } from '../master/Company/constants';

export const PurchaseForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={4}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
          disabled={!props.company.name.toLowerCase().includes('tiles')}
        />
      </Col>
      <Col span={8}>
        <TSAutoComplete
          error=""
          inputProps={{ name: 'vendorId', placeholder: 'Vendor' }}
          dataSource={props.vendors}
          onValueSelect={props.onChange}
          textValue={getValue(props.vendors, props.values.vendorId)}
          key="id"
          name="vendorId"
          displayKey="name"
          allowClear
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          value={props.values.vendorInvoiceNo || ''}
          onChange={props.onChange}
          name="vendorInvoiceNo"
          type="text"
          addonBefore="Vendor Invoice No"
        />
      </Col>
      <Col span={4}>
        <DatePicker
          value={
            props.values.vendorInvoiceDate
              ? moment(props.values.vendorInvoiceDate)
              : undefined
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'vendorInvoiceDate',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Vendor Invoice Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          value={props.values.purchaseOrderNo || ''}
          onChange={props.onChange}
          name="purchaseOrderNo"
          type="text"
          addonBefore="Purchase Order No"
        />
      </Col>
      <Col span={8}>
        <DatePicker
          value={
            props.values.purchaseOrderDate
              ? moment(props.values.purchaseOrderDate)
              : undefined
          }
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'purchaseOrderDate',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Purchase Order Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={8}>
        <TSInput
          error=""
          value={props.values.receivingChallanNo || ''}
          onChange={props.onChange}
          name="receivingChallanNo"
          type="text"
          addonBefore="Goods Receiving No"
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={6}>Product</Col>
        <Col span={2}>Quantity</Col>
        <Col span={2}>Pur. Rate</Col>
        <Col span={2}>Discount</Col>
        <Col span={2}>Taxable Amt.</Col>
        <Col span={2}>Gst Rate</Col>
        <Col span={2}>Gst Amount</Col>
        <Col span={2}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.productDetails?.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={6}>
              <ProductDropDown
                onSelect={(e, f) => {
                  props.isEditActive
                    ? () => {}
                    : props.productObjChangehandler(
                        (f as any).props.label,
                        index,
                      );
                  props.isEditActive
                    ? () => {}
                    : props.productChangeHandler(
                        { target: { name: 'productId', value: String(e) } },
                        index,
                      );
                }}
                allowClear
                extrafields={['purchaseRate', 'sellingRate', 'hsn {\n gst \n}']}
                company={props.company}
                value={product.productId || ''}
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.purchaseRate || ''}
                name="purchaseRate"
                placeholder="Pur. Rate"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.discount || ''}
                name="discount"
                placeholder="Discount"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                value={(
                  Number(product.quantity) * Number(product.purchaseRate) -
                  (Number(product.quantity) *
                    Number(product.purchaseRate) *
                    Number(product.discount)) /
                    100
                ).toFixed(2)}
                placeholder="Taxable Amt."
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.gstRate || ''}
                name="gstRate"
                placeholder="Gst Rate"
                required
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                value={(
                  (Number(product.quantity) * Number(product.purchaseRate) -
                    (Number(product.quantity) *
                      Number(product.purchaseRate) *
                      Number(product.discount)) /
                      100) *
                  ((product.gstRate || 0) / 100)
                ).toFixed(2)}
                placeholder="Gst Amount"
              />
            </Col>
            <Col span={2}>
              <TSInput
                error=""
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('-', index)
                }
                disabled={
                  props.values.productDetails?.length === 1 ||
                  props.isEditActive
                }
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateProductsRow('+', index)
                }
                disabled={
                  (product.productId &&
                  product.quantity &&
                  product.rate &&
                  product.amount
                    ? false
                    : true) || props.isEditActive
                }
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />
      <Col span={6}>
        <TSInput
          error=""
          type="number"
          value={props.values.packingCharges || ''}
          name="packingCharges"
          addonBefore="Packing Charges"
          onChange={(e) =>
            props.isEditActive
              ? {}
              : props.onChange({
                  target: {
                    name: 'packingCharges',
                    value: Number(e.target.value),
                  },
                })
          }
          required
        />
      </Col>
      <Col span={6}>
        <TSInput
          error=""
          type="number"
          value={props.values.transportationCharges || ''}
          name="transportationCharges"
          addonBefore="Transportation Charges"
          onChange={(e) =>
            props.isEditActive
              ? {}
              : props.onChange({
                  target: {
                    name: 'transportationCharges',
                    value: Number(e.target.value),
                  },
                })
          }
          required
        />
      </Col>
      <Col span={6}>
        <TSInput
          error=""
          type="number"
          value={props.values.otherCharges || ''}
          name="otherCharges"
          addonBefore="Other Charges"
          onChange={(e) =>
            props.isEditActive
              ? {}
              : props.onChange({
                  target: {
                    name: 'otherCharges',
                    value: Number(e.target.value),
                  },
                })
          }
          required
        />
      </Col>
      <Col span={6}>
        <TSInput
          error=""
          type="number"
          value={props.values.netAmount || ''}
          name="netAmount"
          addonBefore="Net Amount"
          required
        />
      </Col>
      <TSInput
        error=""
        value={props.values.remarks || ''}
        onChange={props.onChange}
        name="remarks"
        type="text"
        addonBefore="Remarks"
      />
    </Row>
  );
};

interface Props {
  values: PurchaseNodeEdgeMutation;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  vendors: VendorEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  productObjChangehandler: (productObj: ProductNode, index: number) => void;
  company: CompanyNodeEdge;
  isEditActive: boolean;
}

import * as React from 'react';
import { TSInput } from '../../common/TSInput';
import { CompanyNodeEdge } from '../Company/constants';
import { ContractorError } from './constants';
import { ContractorInput } from '../../../schema';

export const ContractorForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error=""
        value={props.values.address}
        onChange={props.onChange}
        name="address"
        type="text"
        addonBefore="Address"
      />
      <TSInput
        error={props.error.contactNo}
        value={props.values.contactNo || 0}
        onChange={props.onChange}
        name="contactNo"
        type="number"
        addonBefore="Contact No"
      />
      <TSInput
        error=""
        value={props.values.email}
        onChange={props.onChange}
        name="email"
        type="text"
        addonBefore="Email"
      />
      <TSInput
        error={props.error.tdsRate}
        value={props.values.tdsRate || ''}
        onChange={props.onChange}
        name="tdsRate"
        type="text"
        addonBefore="TDS Rate"
      />
      <TSInput
        error={props.error.serviceCharge}
        value={props.values.serviceCharge || ''}
        onChange={props.onChange}
        name="serviceCharge"
        type="text"
        addonBefore="Service Charge"
      />
    </div>
  );
};

interface Props {
  values: ContractorInput;
  error: ContractorError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge;
}

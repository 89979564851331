import Api from 'requestapijs';
import { BehaviorSubject } from 'rxjs';
import {
  AddDiscountPayload,
  AddDiscountVariables,
  discountMutation,
  DiscountNode,
  DiscountNodeEdge,
  discountQuery,
  DiscountQueryResponse,
} from '../components/master/Discount/constants';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';

export class DiscountService {
  static instance;

  constructor() {
    if (DiscountService.instance) {
      return DiscountService.instance;
    } else {
      DiscountService.instance = this;
    }
  }

  companyService = new CompanyService();
  public readonly discount$ = new BehaviorSubject<DiscountNodeEdge[]>([]);

  addDiscount(
    data: DiscountNode,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    delete data.branch;
    delete data.category;
    Api.graphql<AddDiscountVariables, AddDiscountPayload>(
      API_URL,
      {
        input: {
          discount: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      discountMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

export const getDiscounts = async (props: Props) => {
  const query = JSON.stringify({
    query: discountQuery(props.date, props.branchId, props.companyId),
  });

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: HEADER as unknown as HeadersInit,
  });
  const resp: DiscountQueryResponse = await response.json();
  return resp.data?.allDiscount.edges;
};

interface Props {
  date: string;
  branchId?: string | null;
  companyId?: string | null;
}

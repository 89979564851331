import * as React from 'react';
import moment from 'moment';
import { Row, Col, DatePicker, Divider, Radio } from 'antd';
import { TSInput } from '../common/TSInput';
import { ProductDetails, ChallanError } from './constants';
import { ChallanInput, PurchaseOrderNode } from '../../schema';
import { PurchaseOrderDropDown } from '../PurchaseOrder/PurchaseOrderDropdown';
import { Label } from '../common/TSLabel';
import { CompanyNodeEdge } from '../master/Company/constants';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';

export const ChallanForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={4}>
        <DatePicker
          value={props.values.date ? moment(props.values.date) : undefined}
          onChange={(e) =>
            props.onChange({
              target: {
                name: 'date',
                value: e && moment(e).format('YYYY-MM-DD'),
              },
            })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
          disabled
        />
        <span style={{ color: 'red' }}>{props.error.date}</span>
      </Col>
      <Col span={8}>
        <PurchaseOrderDropDown
          onSelect={(e, f) => {
            props.isEditActive
              ? () => {}
              : props.purchaseOrderObjChangeHandler((f as any).props.label);
            props.isEditActive
              ? () => {}
              : props.onChange({
                  target: { name: 'purchaseOrderId', value: String(e) },
                });
          }}
          activeKey={props.activeKey}
          value={props.values.purchaseOrderId || ''}
          allowClear
        />
        <span style={{ color: 'red' }}>{props.error.purchaseOrderId}</span>
      </Col>
      <Col span={6}>
        <TSInput
          error=""
          type="text"
          value={
            (props.purchaseOrder && props.purchaseOrder.vendor?.name) || ''
          }
          placeholder="Vendor"
        />
      </Col>
      <Col span={6}>
        <Label title="Purchase Order Date">
          <DatePicker
            value={
              props.purchaseOrder && props.purchaseOrder.date
                ? moment(props.purchaseOrder.date)
                : undefined
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
      </Col>
      <Col span={5}>
        <TSInput
          error={props.error.vendorChallanNo}
          type="text"
          value={props.values.vendorChallanNo || ''}
          name="vendorChallanNo"
          placeholder="Vendor Challan No"
          onChange={props.onChange}
          required
        />
      </Col>
      <Col span={6}>
        <Label title="Vendor Challan Date">
          <DatePicker
            value={
              props.values.vendorChallanDate
                ? moment(props.values.vendorChallanDate)
                : undefined
            }
            onChange={(e) =>
              props.onChange({
                target: {
                  name: 'vendorChallanDate',
                  value: e && moment(e).format('YYYY-MM-DD'),
                },
              })
            }
            placeholder="Select Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Label>
        <span style={{ color: 'red' }}>{props.error.vendorChallanDate}</span>
      </Col>
      <Col span={6}>
        <Label title="Dump To">
          <Radio.Group
            name="dumpToHeadOffice"
            value={props.values.dumpToHeadOffice}
            onChange={(e) =>
              props.onChange({
                target: { name: 'dumpToHeadOffice', value: e.target.value },
              })
            }
          >
            <Radio value={true}>Head Office</Radio>
            <Radio value={false}>Other Location</Radio>
          </Radio.Group>
        </Label>
      </Col>
      {props.values.dumpToHeadOffice ? (
        <Col span={7}>
          <TSInput
            error={''}
            addonBefore="Head Office"
            value={props.company.name}
          />
        </Col>
      ) : (
        <Col span={7}>
          <TSAutoComplete
            error={props.error.dumpLocationId}
            inputProps={{
              name: 'dumpLocationId',
              addonBefore: 'Dump Location',
            }}
            dataSource={
              props.company.branches ? props.company.branches.edges : []
            }
            key="id"
            name="dumpLocationId"
            onValueSelect={props.onChange}
            displayKey="name"
            textValue={getValue(
              props.company.branches?.edges,
              props.values.dumpLocationId,
            )}
            allowClear
          />
        </Col>
      )}
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={9}>Product Name</Col>
        <Col span={2}>Order Quantity</Col>
        <Col span={2}>Rate</Col>
        <Col span={2}>Amount</Col>
        <Col span={3}>Available Quantity</Col>
        <Col span={2}>Dues</Col>
        <Col span={3}>Received Quantity</Col>
        <Divider />
      </Row>
      {(JSON.parse(props.values.productDetails) as ProductDetails[]).map(
        (product, index) => (
          <div key={'products' + index}>
            <Row>
              <Col span={1}>{index + 1}.</Col>
              <Col span={9}>
                <TSInput
                  error=""
                  value={product.productName}
                  name="productName"
                  type="text"
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.orderQuantity || ''}
                  name="orderQuantity"
                  placeholder="Order Quantity"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.rate || ''}
                  name="rate"
                  placeholder="Rate"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.amount || ''}
                  name="amount"
                  placeholder="Amount"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error=""
                  type="number"
                  value={product.availableQuantity}
                  name="availableQuantity"
                  placeholder="Available Quantity"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error=""
                  type="number"
                  value={product.dues || ''}
                  name="dues"
                  placeholder="Dues"
                  required
                  tabIndex={-1}
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error=""
                  type="number"
                  value={product.receivedQuantity}
                  name="receivedQuantity"
                  placeholder="Received Quantity"
                  onChange={
                    props.isEditActive
                      ? () => {}
                      : (e) => props.productChangeHandler(e, index)
                  }
                  disabled={product.dues === 0}
                  required
                />
              </Col>
            </Row>
          </div>
        ),
      )}
      <span style={{ color: 'red' }}>
        {
          props.error.productDetails[props.error.productDetails.length - 1]
            .receivedQuantity
        }
      </span>
      <TSInput
        error=""
        type="text"
        value={props.values.remarks || ''}
        name="remarks"
        placeholder="Remarks"
        onChange={props.onChange}
        required
      />
    </Row>
  );
};

interface Props {
  values: ChallanInput;
  error: ChallanError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  purchaseOrderObjChangeHandler: (value: PurchaseOrderNode) => void;
  purchaseOrder?: PurchaseOrderNode | null;
  company: CompanyNodeEdge;
  isEditActive: boolean;
  activeKey: string;
}

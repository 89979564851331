import * as React from 'react';
import {
  UnitNodeEdge,
  UnitError,
  unitObject,
  unitErrorObject,
} from './constants';
import { UnitForm } from './UnitForm';
import { Button, notification } from 'antd';
import { UnitService } from '../../../service/UnitService';

export class AddUnit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      unit: props.unit || JSON.parse(JSON.stringify(unitObject)),
      error: { ...unitErrorObject },
      buttonDisabled: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      unit: nextProps.unit || JSON.parse(JSON.stringify(unitObject)),
    });
  }

  unitService = new UnitService();

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const unit = { ...this.state.unit };
    unit[e.target.name] = e.target.value;
    this.setState({ unit });
  };

  saveUnit = () => {
    this.setState({ buttonDisabled: true });
    this.unitService?.addUnit(
      this.state.unit,
      (response) => {
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'Unit ' + this.state.unit.id ? 'Updated' : 'Added',
          description: `Unit named ${this.state.unit.name} was successfuly ${
            this.state.unit.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message: 'Unit ' + this.state.unit.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      unit: JSON.parse(JSON.stringify(unitObject)),
      error: { ...unitErrorObject },
    });
  };

  render() {
    return (
      <div>
        <UnitForm
          values={this.state.unit}
          error={this.state.error}
          onChange={this.onChange}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveUnit}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  unit?: UnitNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  unit: UnitNodeEdge;
  error: UnitError;
  buttonDisabled: boolean;
}

import { notification } from 'antd';
import { UserService } from './UserService';
import { BehaviorSubject } from 'rxjs';
import {
  CompanyNodeEdge,
  initialQuery,
  InitialQueryResponse,
  AddCompanyVariables,
  companyMutation,
  Bussiness,
} from '../components/master/Company/constants';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';

export class CompanyService {
  static instance;

  constructor() {
    if (CompanyService.instance) {
      return CompanyService.instance;
    } else {
      CompanyService.instance = this;
    }
    this.init();
  }

  userService = new UserService();
  public readonly company$ = new BehaviorSubject<CompanyNodeEdge | null>(null);
  public readonly shouldUpdate$ = new BehaviorSubject<number>(0);
  public readonly selectedBussiness$ = new BehaviorSubject<Bussiness | null>(
    null,
  );
  private user;

  init() {
    this.userService?.user$.subscribe((user) => {
      this.user = user;
      user && this.getInitialData(user.user.id);
    });
    this.shouldUpdate$.subscribe(() =>
      this.getInitialData(this.user?.user?.id),
    );
  }

  getInitialData(userId, bussiness = 'branch') {
    if (!userId) {
      return;
    }

    Api.graphql<{}, InitialQueryResponse>(
      API_URL,
      { id: userId },
      initialQuery,
      HEADER,
      (response) => {
        this.company$.next(response.data.userDetail.company);
        bussiness = response.data.userDetail.branch ? 'branch' : 'company';
        this.selectedBussiness$.next({
          ...response.data.userDetail[bussiness],
          type: bussiness,
        });
      },
      (error) => error,
    );
  }

  addCompany(
    company: CompanyNodeEdge,
    onSuccess: (response) => void,
    onError: (response) => void,
  ) {
    const input = { input: { company } };
    delete input.input.company.branches;
    Api.graphql<AddCompanyVariables, {}>(
      API_URL,
      input,
      companyMutation,
      HEADER,
      (response) => {
        this.getInitialData(this.user.user.id);
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }

  changeBussiness(bussiness) {
    this.getInitialData(this.user.user.id, bussiness);
    notification.info({
      message: 'Using ' + bussiness,
      description: '',
    });
  }
}

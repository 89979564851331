import * as React from 'react';
import { EmployeeService } from '../../../service/EmployeeService';
import { EmployeeNodeEdge } from './constants';
import { Tabs, Icon, Table, Row, Col } from 'antd';
import { checkAddPermission } from '../../helper';
import { AddEmployee } from './AddEmployee';
import { TSInput } from '../../common/TSInput';
import { UserNodeEdge } from '../../user/constants';
import { UserService } from '../../../service/UserService';

export class Employee extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      activeKey: '1',
      selectedEmployee: null,
      userName: '',
      user: null,
    };
  }

  employeeService = new EmployeeService();
  userService = new UserService();

  componentDidMount() {
    this.employeeService?.employee$.subscribe((employees) =>
      this.setState({ employees }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedEmployee: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  employeeColumn = () => [
    {
      title: 'User Name',
      dataIndex: 'node.user.username',
      key: 'username',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedEmployee: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      // sortingValue: (text) => text,
    },
    {
      title: 'Email',
      dataIndex: 'node.user.email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'node.user.firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'node.user.lastName',
      key: 'lastName',
    },
    {
      title: 'Branch',
      dataIndex: 'node.branch.name',
      key: 'branch',
    },
    {
      title: 'Admin',
      key: 'admin',
      render: (value, record) => (record.node.user.isSuperuser ? 'YES' : 'NO'),
    },
  ];

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { userName } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List User
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={userName}
                onChange={(e) => this.setState({ userName: e.target.value })}
                name="username"
                type="text"
                addonBefore="Search Username"
                placeholder="Enter Username"
              />
            </Col>
          </Row>
          <Table
            dataSource={this.state.employees.filter((value) => {
              if (value?.node?.username) {
                return value?.node?.username.includes(userName);
              }
              return true;
            })}
            columns={this.employeeColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add User
            </span>
          }
          key="2"
        >
          <AddEmployee
            employee={
              this.state.selectedEmployee && {
                ...this.state.selectedEmployee?.node?.user,
                userDetailId: this.state.selectedEmployee?.node?.id,
                branch: { ...this.state.selectedEmployee?.node?.branch },
                group: { ...this.state.selectedEmployee?.node?.group },
              }
            }
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  employees: {
    cursor: string;
    node: EmployeeNodeEdge;
  }[];
  userName: string;
  activeKey: string;
  selectedEmployee: {
    cursor: string;
    node: EmployeeNodeEdge;
  } | null;
  user?: UserNodeEdge | null;
}

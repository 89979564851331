import * as React from 'react';
import { DatePicker, Table, Spin, Row, Col, Skeleton, Button } from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  ProductCategoryNodeConnection,
  ProductCategoryNodeEdge,
  Int,
} from '../../schema';
import { TSInput } from '../common/TSInput';
import { ColumnProps } from '../common/Table';
import { DateService } from '../../service/DateService';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { categoryWiseStocksQuery } from '../master/ProductCategories/constants';
import { downloadToFile } from '../helper';
import { Pagination } from 'sha-el-design/lib';
export class CategoryWiseStockReport extends React.Component<Props, State> {
  dateService = new DateService();

  constructor(props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      name: '',
      after: '',
      currentPage: 1,
    };
  }

  componentWillUnmount() {
    this.dateService?.date$.next(moment().format('YYYY-MM-DD'));
  }

  dateChangeHandler = (date) => {
    this.setState({ date: moment(date).format('YYYY-MM-DD') }, () => {
      this.dateService?.date$.next(this.state.date);
    });
  };

  columns = () => {
    if (this.props.fy_stock) {
      const stockColumns: ColumnProps<ProductCategoryNodeEdge>[] = [
        {
          title: 'Product Category',
          dataIndex: 'node.name',
          key: 'name',
          // tslint:disable-next-line: max-line-length
          render: (text, record) => (
            <a
              onClick={() =>
                this.props.handleCategoryData(
                  record.node?.id || '',
                  record.node?.name || '',
                  '3',
                  this.state.date,
                  this.props.fy_stock,
                )
              }
            >
              {text}
            </a>
          ),
        },
        {
          title: 'Opening Stock',
          dataIndex: 'node.categoryStock.openingStock',
          key: 'openingStock',
        },
        {
          title: 'Opening Stock Value',
          dataIndex: 'node.categoryStock.openingStockValue',
          key: 'openingStockValue',
        },
        {
          title: 'Inward',
          align: 'center',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'node.categoryStock.inwardQty',
              key: 'inwardQty',
            },
            {
              title: 'Value',
              dataIndex: 'node.categoryStock.inwardValue',
              key: 'inwardValue',
            },
          ],
        },
        {
          title: 'Outward',
          align: 'center',
          width: 100,
          children: [
            {
              title: 'Quantity',
              dataIndex: 'node.categoryStock.outwardQty',
              key: 'outwardQty',
            },
            {
              title: 'Value',
              dataIndex: 'node.categoryStock.outwardValue',
              key: 'outwardValue',
            },
          ],
        },
        {
          title: 'Closing Stock',
          key: 'totalStocks',
          dataIndex: 'node.categoryStock.totalStocks',
        },
        {
          title: 'Closing Stock Value',
          key: 'closingStockValue',
          dataIndex: 'node.categoryStock.closingStockValue',
        },
      ];
      return stockColumns;
    }
    const stockColumns: ColumnProps<ProductCategoryNodeEdge>[] = [
      {
        title: 'Product Category',
        dataIndex: 'node.name',
        key: 'name',
        // tslint:disable-next-line: max-line-length
        render: (text, record) => (
          <a
            onClick={() =>
              this.props.handleCategoryData(
                record.node?.id || '',
                record.node?.name || '',
                '3',
                this.state.date,
                this.props.fy_stock,
              )
            }
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Opening Stock',
        dataIndex: 'node.categoryStock.openingStock',
        key: 'openingStock',
      },
      {
        title: 'Receiving',
        dataIndex: 'node.categoryStock.receiving',
        key: 'receiving',
      },
    ];
    if (this.props.business.type === 'branch') {
      stockColumns.push(
        {
          title: 'Transfer Return',
          dataIndex: 'node.categoryStock.transfer',
          key: 'transfer',
        },
        {
          title: 'Sale',
          dataIndex: 'node.categoryStock.sale',
          key: 'sale',
        },
        {
          title: 'Sale Return',
          dataIndex: 'node.categoryStock.saleReturn',
          key: 'saleReturn',
        },
      );
    } else {
      stockColumns.push(
        {
          title: 'Transfer',
          dataIndex: 'node.categoryStock.transfer',
          key: 'transfer',
        },
        {
          title: 'Purchase',
          dataIndex: 'node.categoryStock.purchase',
          key: 'purchase',
        },
        {
          title: 'Purchase Return',
          dataIndex: 'node.categoryStock.purchaseReturn',
          key: 'purchaseReturn',
        },
        {
          title: 'Damage',
          dataIndex: 'node.categoryStock.damage',
          key: 'damage',
        },
      );
    }
    stockColumns.push({
      title: 'Closing Stock',
      key: 'totalStocks',
      dataIndex: 'node.categoryStock.totalStocks',
    });
    return stockColumns;
  };

  columnsCsv = () => {
    if (this.props.fy_stock) {
      const stockColumns: ColumnProps<ProductCategoryNodeEdge>[] = [
        {
          title: 'Product Category',
          dataIndex: 'node.name',
        },
        {
          title: 'Opening Stock',
          dataIndex: 'node.categoryStock.openingStock',
        },
        {
          title: 'Opening stock value',
          dataIndex: 'node.categoryStock.openingStockValue',
        },
        {
          title: 'Inward Qty',
          dataIndex: 'node.categoryStock.inwardQty',
        },
        {
          title: 'Inward Value',
          dataIndex: 'node.categoryStock.inwardValue',
        },
        {
          title: 'Outward Qty',
          dataIndex: 'node.categoryStock.outwardQty',
        },
        {
          title: 'Outward Value',
          dataIndex: 'node.categoryStock.outwardValue',
        },
        {
          title: 'Closing Stock',
          dataIndex: 'node.categoryStock.totalStocks',
        },
        {
          title: 'Closing Stock Value',
          dataIndex: 'node.categoryStock.closingStockValue',
        },
      ];
      return stockColumns;
    }
    const stockColumns: ColumnProps<ProductCategoryNodeEdge>[] = [
      {
        title: 'Product Category',
        dataIndex: 'node.name',
        key: 'name',
        // tslint:disable-next-line: max-line-length
        render: (text, record) => (
          <a
            onClick={() =>
              this.props.handleCategoryData(
                record.node?.id || '',
                record.node?.name || '',
                '3',
                this.state.date,
                this.props.fy_stock,
              )
            }
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Opening Stock',
        dataIndex: 'node.categoryStock.openingStock',
        key: 'openingStock',
      },
      {
        title: 'Receiving',
        dataIndex: 'node.categoryStock.receiving',
        key: 'receiving',
      },
    ];
    if (this.props.business.type === 'branch') {
      stockColumns.push(
        {
          title: 'Transfer Return',
          dataIndex: 'node.categoryStock.transfer',
          key: 'transfer',
        },
        {
          title: 'Sale',
          dataIndex: 'node.categoryStock.sale',
          key: 'sale',
        },
        {
          title: 'Sale Return',
          dataIndex: 'node.categoryStock.saleReturn',
          key: 'saleReturn',
        },
      );
    } else {
      stockColumns.push(
        {
          title: 'Transfer',
          dataIndex: 'node.categoryStock.transfer',
          key: 'transfer',
        },
        {
          title: 'Purchase',
          dataIndex: 'node.categoryStock.purchase',
          key: 'purchase',
        },
        {
          title: 'Purchase Return',
          dataIndex: 'node.categoryStock.purchaseReturn',
          key: 'purchaseReturn',
        },
        {
          title: 'Damage',
          dataIndex: 'node.categoryStock.damage',
          key: 'damage',
        },
      );
    }
    stockColumns.push({
      title: 'Closing Stock',
      key: 'totalStocks',
      dataIndex: 'node.categoryStock.totalStocks',
    });
    return stockColumns;
  };

  downloadCsv = (categoryWiseStockList: ProductCategoryNodeEdge[]) => {
    const data = categoryWiseStockList
      .map((csl, i) =>
        this.columnsCsv()
          .map((col) => {
            if (col.dataIndex) {
              const value = col.dataIndex.split('.');
              let returnValue = csl[value.splice(0, 1)[0]];
              value.forEach((val) => {
                returnValue = returnValue[val];
              });
              return returnValue;
            }
            return col.render?.(csl, csl, i);
          })
          .join(','),
      )
      .join('\n');

    const csv =
      this.columnsCsv()
        .map((col) => col.title)
        .join(',') +
      '\n' +
      data;
    downloadToFile(
      csv,
      `category-wise-stock-report-${
        this.props.business.name
      }-${new Date().toISOString()}`,
      'CATEGORY WISE STOCK REPORT',
      (this.props.business.type === 'branch'
        ? this.props.business.companyName
        : this.props.business.name) || '',
      this.props.business.type === 'branch'
        ? this.props.business.name
        : 'Head Office',
      moment(this.state.date).format('DD-MM-YYYY'),
      moment(this.state.date).format('DD-MM-YYYY'),
      '',
    );
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { companyId, branchId, fy_stock } = this.props;
    const { date, currentPage, after } = this.state;
    // tslint:disable-next-line: max-line-length
    const variables =
      this.props.company.name.toLowerCase().includes('jharcraft') ||
      this.props.company?.name.toLowerCase().includes('khadi')
        ? {
            date,
            companyId,
            branchId,
            orderBy: 'name',
            fy_stock,
            after,
            first: 25,
          }
        : { date, companyId, branchId, orderBy: 'name', fy_stock, after };
    const { name } = this.state;
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              value={moment(this.state.date)}
              onChange={this.dateChangeHandler}
              placeholder="Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              name="name"
              type="text"
              addonBefore="Search Product Category"
              placeholder="Enter Name"
            />
          </Col>
        </Row>
        <GraphqlQuery
          queryString={categoryWiseStocksQuery}
          variables={name ? { ...variables, name } : variables}
          render={(
            response: { allProductCategories: ProductCategoryNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProductCategories.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={0}
                  currentPage={currentPage}
                  batchSize={25}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProductCategories.pageInfo.endCursor,
                      response.allProductCategories.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() =>
                    this.downloadCsv(response.allProductCategories.edges)
                  }
                  key="button"
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </div>
    );
  }
}

interface State {
  date: string;
  name: string;
  after: string;
  currentPage: number;
}

interface Props {
  company: CompanyNodeEdge;
  branchId?: string | null;
  business: Bussiness;
  handleCategoryData: (
    categoryId: string,
    categoryName: string,
    activeKey: string,
    categoryDate: string,
    fy_stock?: boolean,
  ) => void;
  fy_stock: boolean;
  companyId: string;
}

import * as React from 'react';
import { GiftNodeEdgeQuery } from './constants';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { CompanyNodeEdge } from '../master/Company/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { sumBy } from 'lodash';
import moment from 'moment';
import { JHARCRAFT_LOGO_URL, JHARKHAND_GOVT_LOGO_URL } from '../../config';

export const GiftReceiptJharcraft: React.FunctionComponent<Props> = (props) => {
  const productDetailsEdges = props.gift.productDetails?.edges;
  const totalQty = sumBy(
    productDetailsEdges,
    (value) => value.node.quantity || 0,
  );
  return (
    <Document>
      <Page size="A4" style={{ paddingBottom: 50 }}>
        <View fixed>
          <View style={[style.section, style.inline, { marginTop: 12 }]}>
            <Image
              style={[{ flex: 0.3, height: 43 }]}
              source={JHARCRAFT_LOGO_URL}
            />
            <Text
              style={[
                style.inlineHead,
                style.mediumBlackText,
                style.textCenter,
                { fontSize: '15.8pt', marginLeft: 15, marginRight: 15 },
              ]}
            >
              Jharkhand Silk, Textile &amp; Handicraft Development Corporation
              Ltd.
            </Text>
            <Image
              style={[{ flex: 0.15, height: 43 }]}
              source={JHARKHAND_GOVT_LOGO_URL}
            />
          </View>
          <View style={[style.section, { marginTop: 0 }]}>
            <Text
              style={[
                style.darkBlackText,
                style.textCenter,
                { fontSize: '13pt' },
              ]}
            >
              {props.branch.name}
            </Text>
          </View>
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.lightBlackText]}>
              {props.branch.address}, Mo: {props.branch.contactNo}
            </Text>
            <Text
              style={[style.inlineHead, style.lightBlackText, { flex: 0.7 }]}
            >
              Helpline No: {props.company.contactNo}
            </Text>
            <Text style={[style.inlineHead, style.lightBlackText]}>
              Email: {props.branch.email}
            </Text>
            <Text
              style={[style.inlineHead, style.lightBlackText, { flex: 0.8 }]}
            >
              GSTIN: {props.branch.gstin}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={style.section}>
            <Text
              style={[
                style.textCenter,
                style.mediumBlackText,
                { fontSize: '12pt' },
              ]}
            >
              Gift Invoice
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.inlineHeadDouble, style.mediumBlackText]}>
              Invoice No:{' '}
              <Text style={[style.darkBlackText]}>
                {`${props.gift.giftReceiptNo}(${props.gift.branchSerialNo})`}
              </Text>
            </Text>
            <Text style={[style.lightBlackText, { flex: 0.5 }]}>
              Date: {moment(props.gift.date).format('DD-MM-YYYY')}
            </Text>
            <Text
              style={[style.lightBlackText, style.textCenter, { flex: 0.4 }]}
            >
              Cashier: Manager
            </Text>
            <Text
              style={[style.lightBlackText, style.textRight, { flex: 0.4 }]}
            >
              Original Bill
            </Text>
          </View>
          <View style={style.divider} />
        </View>
        <View>
          <View style={[style.section, style.inline]}>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              Sl.
            </Text>
            <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
              Product
            </Text>
            <Text style={[style.inlineHead, style.mediumBlackText]}>
              Barcode
            </Text>
            <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
              HSN Code
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Qty
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              Rate
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              Amount
            </Text>
          </View>
          <View style={style.divider} />
          {productDetailsEdges?.map((pd, i) => (
            <View
              style={[style.section, style.inline]}
              key={'pd' + i}
              wrap={false}
            >
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.lightBlackText,
                  { flex: 0.3 },
                ]}
              >
                {i + 1}.
              </Text>
              <Text style={[style.highFlexInlineHead, style.mediumBlackText]}>
                {pd.node.product?.name}
              </Text>
              <Text style={[style.inlineHead, style.mediumBlackText]}>
                {pd.node.product?.oldBarcodeId}
              </Text>
              <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
                {pd.node.product?.hsn?.hsnCode}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node.quantity}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                  { flex: 0.4 },
                ]}
              >
                {pd.node.rate}
              </Text>
              <Text
                style={[
                  style.lowFlexInlineHead,
                  style.mediumBlackText,
                  style.textRight,
                ]}
              >
                {pd.node.amount?.toFixed(2)}
              </Text>
            </View>
          ))}
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.highFlexInlineHead, style.darkBlackText]}>
              Grand Total:
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.3 },
              ]}
            >
              {}
            </Text>
            <Text style={[style.inlineHead, style.mediumBlackText]}>{}</Text>
            <Text style={[style.lowFlexInlineHead, style.mediumBlackText]}>
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
                { flex: 0.4 },
              ]}
            >
              {totalQty}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                { flex: 0.4 },
              ]}
            >
              {}
            </Text>
            <Text
              style={[
                style.lowFlexInlineHead,
                style.mediumBlackText,
                style.textRight,
              ]}
            >
              {Math.round(props.gift.totalMrp).toFixed(2)}
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, style.inline]}>
            <Text style={[style.darkBlackText, style.highFlexInlineHead]}>
              Customer: {props.gift.customer && props.gift.customer.name}
              {props.gift.customer?.contactNo
                ? `(${props.gift.customer.contactNo})`
                : ''}
            </Text>
            <Text style={[style.textRight, style.darkBlackText, { flex: 0.5 }]}>
              Net Payable: 0.00/-
            </Text>
          </View>
          <View style={style.divider} />
          <View style={[style.section, { marginTop: 25 }]}>
            <Text style={[style.darkBlackText, style.textRight]}>
              Order By:
            </Text>
            <Text style={[style.darkBlackText, style.textRight]}>
              {props.gift.orderByName}
            </Text>
            <Text style={[style.darkBlackText, style.textRight]}>
              ({props.gift.orderByDesignation})
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const style = StyleSheet.create({
  section: { color: 'black', marginTop: 2, marginLeft: 15, marginRight: 15 },
  textCenter: { textAlign: 'center' },
  lightBlackText: { color: '#606060', fontSize: '7.4pt' },
  mediumBlackText: { color: '#282828', fontSize: '8.4pt' },
  darkBlackText: { color: '#000000', fontSize: '9.5pt' },
  divider: {
    border: 0,
    borderTop: '1pt solid black',
    height: '1pt',
    display: 'block',
  },
  box: { border: '1pt solid black' },
  inline: { flexDirection: 'row' },
  highFlexInlineHead: { flex: 2.5 },
  inlineHead: { flex: 1 },
  inlineHeadDouble: { flex: 2 },
  lowFlexInlineHead: { flex: 0.7 },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  textJustify: { textAlign: 'justify' },
});

interface Props {
  gift: GiftNodeEdgeQuery;
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

import * as React from 'react';
import { Row, Col, DatePicker, Divider, Button, Radio } from 'antd';
import moment from 'moment';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { getValue } from '../common/AutoCompleteHelper';
import { TSInput } from '../common/TSInput';
import { BranchEdges } from '../master/Branch/constants';
import { ProductDropDown } from '../master/Product/ProductDropdown';
import {
  JobOrderInput,
  ConversionNode,
  JobOrderNode,
  ProductDetailsNode,
} from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { Label } from '../common/TSLabel';
import { JobOrderError } from './constants';
import { VendorEdges } from '../master/vendor/constants';
import { ConversionDropDown } from '../Production/Conversion/ConversionDropdown';
import { JobOrderDropDown } from './JobOrderDropdown';
export const JobOrderForm: React.FC<Props> = (props) => {
  return (
    <Row>
      <Col span={9}>
        <Label title="">
          <Radio.Group
            name="continuation"
            value={props.values.continuation}
            onChange={(e) =>
              props.onChange({
                target: { name: 'continuation', value: e.target.value },
              })
            }
            disabled={props.isEditActive}
          >
            <Radio value={false}>New Order</Radio>
            <Radio value={true}>Continue With A Previous Order</Radio>
          </Radio.Group>
        </Label>
      </Col>
      <Col span={9}>
        <Label title="Select Job Order">
          <JobOrderDropDown
            onSelect={(e, f) => {
              props.isEditActive
                ? () => {}
                : props.onChange({
                    target: { name: 'oldJobOrderId', value: String(e) },
                  });
              props.isEditActive
                ? () => {}
                : props.jobOrderObjChangeHandler((f as any).props.label);
            }}
            value={props.values.oldJobOrderId || ''}
            activeKey={props.activeKey}
            disabled={!props.values.continuation || props.isEditActive}
            allowClear
          />
        </Label>
      </Col>
      <Col span={6}>
        <DatePicker
          value={
            props.values.transferData.date
              ? moment(props.values.transferData.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          disabled
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={8}>
        <Label title="To">
          <Radio.Group
            name="toVendor"
            value={props?.values?.transferData?.toVendor}
            onChange={(e) =>
              props.onChangeTransfer({
                target: { name: 'toVendor', value: e.target.value },
              })
            }
            disabled={props.company.name.toLowerCase().includes('pearl')}
          >
            <Radio value={true}>Vendor</Radio>
            <Radio value={false}>Production Centre</Radio>
          </Radio.Group>
        </Label>
      </Col>
      {props.values.transferData.toVendor ? (
        <Col span={16}>
          <TSAutoComplete
            error={props.error.transferData?.vendorForId || ''}
            inputProps={{ name: 'vendorForId', addonBefore: 'Vendor' }}
            dataSource={props.vendors}
            key="id"
            name="vendorForId"
            onValueSelect={(e) =>
              props.isEditActive
                ? {}
                : props.onChangeTransfer({
                    target: { name: 'vendorForId', value: e.target.value },
                  })
            }
            displayKey="name"
            textValue={getValue(
              props.vendors,
              props.values.transferData.vendorForId,
            )}
            allowClear={!props.isEditActive}
          />
        </Col>
      ) : (
        <Col span={16}>
          <TSAutoComplete
            error={props.error.transferData?.toLocationId || ''}
            inputProps={{ name: 'toLocationId', addonBefore: 'Location' }}
            dataSource={props.branch.filter((br) => {
              if (props.business.type === 'branch')
                return (
                  br.node.id !== props.business.id &&
                  br.node.category !== 'EMPORIUM'
                );
              return br.node.category !== 'EMPORIUM';
            })}
            key="id"
            name="toLocationId"
            onValueSelect={(e) =>
              props.isEditActive
                ? {}
                : props.onChangeTransfer({
                    target: { name: 'toLocationId', value: e.target.value },
                  })
            }
            displayKey="name"
            textValue={getValue(
              props.branch,
              props.values.transferData.toLocationId,
            )}
            allowClear={!props.isEditActive}
          />
        </Col>
      )}
      <Divider />
      <h4>Order For</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={10}>Finished Product</Col>
        <Col span={3}>Quantity</Col>
        <Col span={3}>Cost Price</Col>
        <Col span={4}>Amount</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.orderFor.map((conversionDetail, index) => (
        <div key={'conversionDetails' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={10}>
              <ConversionDropDown
                onSelect={(e, f) => {
                  props.isEditActive
                    ? () => {}
                    : props.conversionObjChangeHandler(
                        (f as any).props.label,
                        index,
                        false,
                      );
                  props.isEditActive
                    ? () => {}
                    : props.conversionDetailChangeHandler(
                        { target: { name: 'conversionId', value: String(e) } },
                        index,
                      );
                }}
                extrafields={[
                  `rawMaterials {
                    edges {
                      node {
                        id
                        product {
                          id
                          name
                        }
                        rate
                        purchaseRate
                        quantity
                        wastage
                        gstRate
                      }
                    }
                  }
                  productionType
                  designCode`,
                ]}
                value={conversionDetail.conversionId || ''}
                allowClear
              />
              <span style={{ color: 'red' }}>
                {props.error.orderFor?.[index].conversionId}
              </span>
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.orderFor?.[index].quantity || ''}
                type="number"
                value={conversionDetail.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.conversionDetailChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.orderFor?.[index].cost || ''}
                type="number"
                value={conversionDetail.cost || ''}
                name="cost"
                placeholder="Cost Price"
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error={props.error.orderFor?.[index].amount || ''}
                type="number"
                value={conversionDetail.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateconversionDetailsRow('-', index)
                }
                disabled={props.values.orderFor.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateconversionDetailsRow('+', index)
                }
                disabled={
                  !(
                    conversionDetail.conversionId &&
                    conversionDetail.quantity &&
                    conversionDetail.cost &&
                    conversionDetail.amount
                  )
                }
              />
            </Col>
          </Row>
        </div>
      ))}
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={8}>Product</Col>
        <Col span={3}>Available Quantity</Col>
        <Col span={3}>More Quantity Needed</Col>
        <Col span={3}>Quantity To Transfer</Col>
        <Col span={3}>Pur. Rate</Col>
        <Col span={4}>Amount</Col>
        {/* {nestedAccess(props, 'conversion', 'productionType') === 'HANDLOOM' && <div>
          <Col span={2}>
            Reed
          </Col>
          <Col span={2}>
            Pick
          </Col>
          <Col span={3}>
            GSM
          </Col>
        </div>} */}
        <Divider />
      </Row>
      {props.values.transferData.productDetails?.map((product, index) => (
        <div key={'products' + index}>
          <Row>
            <Col span={8}>
              <ProductDropDown
                onSelect={(e, f) => {}}
                allowClear
                extrafields={[
                  'purchaseRate',
                  'sellingRate',
                  'hsn {\n gst \n hsnWithSameCode \n{ \n hsnCode \n gst \n minValue \n maxValue \n} }',
                ]}
                company={props.company}
                value={product.productId || ''}
              />
              <span style={{ color: 'red' }}>
                {props.error.transferData?.productDetails[index].productId}
              </span>
            </Col>
            <Col span={3}>
              <TSInput
                error={''}
                value={
                  props.values.oldJobOrderId
                    ? (
                        (product as any as ProductDetailsNode)
                          .availableQuantityForJobOrder || []
                      ).find(
                        (quan) =>
                          quan.jobOrderId === props.values.oldJobOrderId,
                      )?.quantity
                    : 0
                }
                placeholder="Available Quantity"
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={''}
                value={
                  Number(
                    props.quantityNeededList.reduce(
                      (sum, qn) =>
                        sum +
                        (qn.productId === product.productId ? qn.quantity : 0),
                      0,
                    ),
                  ) -
                  (Number(
                    props.values.oldJobOrderId
                      ? (
                          (product as any as ProductDetailsNode)
                            .availableQuantityForJobOrder || []
                        ).find(
                          (quan) =>
                            quan.jobOrderId === props.values.oldJobOrderId,
                        )?.quantity
                      : 0,
                  ) || 0)
                }
                placeholder="More Quantity Needed"
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={
                  props.error.transferData?.productDetails[index].quantity || ''
                }
                type="number"
                value={product.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.productChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={
                  props.error.transferData?.productDetails[index]
                    .purchaseRate || ''
                }
                type="number"
                value={product.purchaseRate || ''}
                name="purchaseRate"
                placeholder="Pur. Rate"
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error={
                  props.error.transferData?.productDetails[index].amount || ''
                }
                type="number"
                value={product.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            {/* {nestedAccess(props, 'conversion', 'productionType') === 'HANDLOOM' && <div>
              <Col span={2}>
                <TSInput
                  error={''}
                  type='text'
                  value={product.reed}
                  name='reed'
                  placeholder='Reed'
                  onChange={props.isEditActive ? () => {} : e => props.productChangeHandler(e, index)}
                />
              </Col>
              <Col span={2}>
                <TSInput
                  error={''}
                  type='text'
                  value={product.pick}
                  name='pick'
                  placeholder='Pick'
                  onChange={props.isEditActive ? () => {} : e => props.productChangeHandler(e, index)}
                />
              </Col>
              <Col span={3}>
                <TSInput
                  error={''}
                  type='text'
                  value={product.gsm}
                  name='gsm'
                  placeholder='GSM'
                  onChange={props.isEditActive ? () => {} : e => props.productChangeHandler(e, index)}
                />
              </Col>
            </div>} */}
          </Row>
        </div>
      ))}
      <Divider />
      <Col>
        <TSInput
          error={''}
          type="text"
          value={props.values.transferData.remarks || ''}
          name="remarks"
          addonBefore="WAP No"
          onChange={props.onChangeTransfer}
          required
        />
      </Col>
    </Row>
  );
};

interface Props {
  values: JobOrderInput;
  error: JobOrderError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => void;
  onChangeTransfer: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean } },
  ) => void;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  vendors: VendorEdges[];
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateProductsRow: (type: string, index: number) => void;
  conversionObjChangeHandler: (
    conversionObj: ConversionNode,
    index: number,
    remove: boolean,
  ) => void;
  conversionDetailChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateconversionDetailsRow: (type: string, index: number) => void;
  jobOrderObjChangeHandler: (jobOrderObj: JobOrderNode) => void;
  isEditActive: boolean;
  activeKey: string;
  quantityNeededList: {
    conversionId: string;
    productId: string;
    quantity: number;
  }[];
}

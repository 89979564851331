const CONFIG = {
  API_URL: 'https://api.jharcraft.co.in/api',
  // API_URL: 'http://localhost:8000/api',
  // API_URL: 'https://api.jharkhandkhadi.in/api',
  // API_URL: 'https://inventory-api.scriptitsolution.tech/api',
};

const HEADER = {
  Authorization: localStorage.getItem('token'),
  // 'content-type': 'application/json',
};

const API_URL = `${CONFIG.API_URL}/graphql/`;
const LOGIN_URL = `${CONFIG.API_URL}/login/`;
const ITEM_WISE_SALES_REPORT_URL = `${CONFIG.API_URL}/sales/item_wise_report`;
const RANGE_WISE_SALES_REPORT_URL = `${CONFIG.API_URL}/sales/range_wise_report`;
const ITEM_WISE_SALES_REPORT_CENTRAL_URL = `${CONFIG.API_URL}/sales/item_wise_report_central`;
const RANGE_WISE_SALES_REPORT_CENTRAL_URL = `${CONFIG.API_URL}/sales/range_wise_report_central`;
const ITEM_WISE_SALES_REPORT_CSV_URL = `${CONFIG.API_URL}/product/item_sales_report_csv`;
const ITEM_WISE_DAILY_SALES_REPORT_CSV_URL = `${CONFIG.API_URL}/sales/item_wise_daily_sales_csv`;
const ITEM_STOCK_FOR_CATEGORY_REPORT_CSV_URL = `${CONFIG.API_URL}/product/item_stock_for_category_report_csv`;
const CATEGORY_WISE_MONTHLY_SALE_REPORT_CSV_URL = `${CONFIG.API_URL}/product/category_wise_mothly_sale_report_csv`;
const ITEM_WISE_PURCHASE_REPORT_CSV_URL = `${CONFIG.API_URL}/product/item_purchase_report_csv`;
const REORDER_REPORT_CSV_URL = `${CONFIG.API_URL}/product/reorder_report_csv`;
const EXCHANGE_REPORT_CSV_URL = `${CONFIG.API_URL}/product/exchange_report_csv`;
const VENDOR_WISE_PURCHASE_REPORT_CSV_URL = `${CONFIG.API_URL}/purchase/vendor_wise_purchase_report_csv`;
const LOCATION_WISE_PURCHASE_REPORT_CSV_URL = `${CONFIG.API_URL}/transfer/location_wise_transfer_report_csv`;
const FINISHED_PRODUCT_TRANSFER_REPORT_CSV_URL = `${CONFIG.API_URL}/production/finished_product_transfer_report_csv`;
const STOCK_REPORT_FOR_PRODUCTS_WITHOUT_BARCODE_CSV = `${CONFIG.API_URL}/production/stock_report_for_products_without_barcode_csv`;
const CUSTOMER_LIST_CSV_URL = `${CONFIG.API_URL}/customer/customer_list_csv`;
const CODE_CONVERSION_LIST_CSV_URL = `${CONFIG.API_URL}/product/code_conversion_list_csv`;
const CREDIT_PAYMENT_HISTORY_CSV_URL = `${CONFIG.API_URL}/sales/credit_payment_history_csv`;
const MASTER_DATA_CSV_URL = `${CONFIG.API_URL}/sales/master_data_csv`;
const ITEM_WISE_RECEIPT_AND_SALES_CSV_URL = `${CONFIG.API_URL}/product/item_wise_receipt_and_sales_status_csv`;
const DIRECT_PRODUCTION_REPORT_CSV_URL = `${CONFIG.API_URL}/product/direct_production_report_csv`;
const ITEM_WISE_TOTAL_STOCK_CSV_URL = `${CONFIG.API_URL}/product/item_wise_total_stock_report_csv`;
const PHYSICAL_STOCK_REPORT_CSV = `${CONFIG.API_URL}/product/physical_stock_report_csv`;
const PURCHASE_ORDER_STATUS_REPORT_CSV = `${CONFIG.API_URL}/purchase/purchase_order_status_report_csv`;
const STOCK_REPORT_CSV = `${CONFIG.API_URL}/product/stock_report_csv`;
const ITEM_WISE_TRANSFER_REPORT_CSV = `${CONFIG.API_URL}/transfer/item_wise_transfer_report_csv`;
const ITEM_WISE_RECEIVING_REPORT_CSV = `${CONFIG.API_URL}/transfer/item_wise_receiving_report_csv`;
const ITEM_WISE_APPROVAL_REPORT_CSV = `${CONFIG.API_URL}/product/item_wise_approval_report_csv`;
const ITEM_WISE_GIFT_REPORT_CSV = `${CONFIG.API_URL}/product/item_wise_gift_report_csv`;
const ITEM_WISE_DAMAGE_REPORT_CSV = `${CONFIG.API_URL}/product/item_wise_damage_report_csv`;
const ITEM_WISE_PURCHASE_RETURN_REPORT_CSV = `${CONFIG.API_URL}/product/item_wise_purchase_return_report_csv`;
const PRODUCT_ISSUE_CSV = `${CONFIG.API_URL}/transfer/product_issue_csv`;
const NEW_STOCK_REPORT_CSV = `${CONFIG.API_URL}/product/new_stock_report_csv`;
const CUSTOMER_HISTORY_REPORT_CSV = `${CONFIG.API_URL}/customer/customer_history_report_csv`;
const SALES_CANCEL_REPORT_CSV = `${CONFIG.API_URL}/sales/sales_cancel_report_csv`;
const CASH_BANK_REPORT_CSV = `${CONFIG.API_URL}/sales/cash_bank_report_csv`;
const CREDIT_SALES_REPORT_CSV = `${CONFIG.API_URL}/sales/credit_sales_report_csv`;
const ITEM_WISE_SALES_REPORT_TALLY_CSV = `${CONFIG.API_URL}/sales/item_wise_sales_report_tally_csv`;
const INTER_LOCATION_TRANSFER_REPORT = `${CONFIG.API_URL}/transfer/inter_location_transfer_report_csv`;
const MARGIN_REPORT_CSV = `${CONFIG.API_URL}/purchase/margin_report_csv`;
const ITEM_WISE_TRANSIT_REPORT_CSV = `${CONFIG.API_URL}/transfer/item_wise_transit_report_csv`;
const PRODUCT_LIST_CSV = `${CONFIG.API_URL}/product/product_list_csv`;
const PRODUCT_LIST_TALLY_CSV = `${CONFIG.API_URL}/product/product_list_tally_csv`;
const PURCHASE_ORDER_SUMMARIZE_STATUS_REPORT_CSV = `${CONFIG.API_URL}/purchase/purchase_order_summarize_status_report_csv`;
const ITEM_WISE_PRODUCTION_RECEIVING_REPORT_CSV = `${CONFIG.API_URL}/production/item_wise_production_receiving_report_csv`;
const SUMMARIZED_PRODUCTION_RECEIVING_REPORT_CSV = `${CONFIG.API_URL}/production/summarized_production_receiving_report_csv`;

const JHARKHAND_GOVT_LOGO_URL =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/jharkhand_govt_logo.png';
const JHARCRAFT_LOGO_URL =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/jharcraft_logo.jpg';
const RAMESHWARAM_PROJECTS_HEADER =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/Letterhead%2520RPPL%2520.jpg';
const RAMESHWARAM_INDUSTRIES_HEADER =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/Letterhead%2520RI.jpg';
const KHADI_INDIA_LOGO_URL =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/khadi_india_logo.png';
const JHARKHAND_KHADI_LOGO_URL =
  'https://inventory-old.s3.ap-south-1.amazonaws.com/jharkhand_khadi_logo.png';

export {
  API_URL,
  LOGIN_URL,
  ITEM_WISE_SALES_REPORT_URL,
  RANGE_WISE_SALES_REPORT_URL,
  ITEM_WISE_SALES_REPORT_CENTRAL_URL,
  RANGE_WISE_SALES_REPORT_CENTRAL_URL,
  HEADER,
  ITEM_WISE_SALES_REPORT_CSV_URL,
  ITEM_STOCK_FOR_CATEGORY_REPORT_CSV_URL,
  CATEGORY_WISE_MONTHLY_SALE_REPORT_CSV_URL,
  ITEM_WISE_PURCHASE_REPORT_CSV_URL,
  REORDER_REPORT_CSV_URL,
  EXCHANGE_REPORT_CSV_URL,
  VENDOR_WISE_PURCHASE_REPORT_CSV_URL,
  LOCATION_WISE_PURCHASE_REPORT_CSV_URL,
  FINISHED_PRODUCT_TRANSFER_REPORT_CSV_URL,
  STOCK_REPORT_FOR_PRODUCTS_WITHOUT_BARCODE_CSV,
  CUSTOMER_LIST_CSV_URL,
  CODE_CONVERSION_LIST_CSV_URL,
  CREDIT_PAYMENT_HISTORY_CSV_URL,
  MASTER_DATA_CSV_URL,
  ITEM_WISE_RECEIPT_AND_SALES_CSV_URL,
  DIRECT_PRODUCTION_REPORT_CSV_URL,
  JHARKHAND_GOVT_LOGO_URL,
  JHARCRAFT_LOGO_URL,
  KHADI_INDIA_LOGO_URL,
  JHARKHAND_KHADI_LOGO_URL,
  ITEM_WISE_DAILY_SALES_REPORT_CSV_URL,
  ITEM_WISE_TOTAL_STOCK_CSV_URL,
  RAMESHWARAM_INDUSTRIES_HEADER,
  RAMESHWARAM_PROJECTS_HEADER,
  PHYSICAL_STOCK_REPORT_CSV,
  PURCHASE_ORDER_STATUS_REPORT_CSV,
  STOCK_REPORT_CSV,
  ITEM_WISE_TRANSFER_REPORT_CSV,
  ITEM_WISE_RECEIVING_REPORT_CSV,
  ITEM_WISE_APPROVAL_REPORT_CSV,
  ITEM_WISE_GIFT_REPORT_CSV,
  ITEM_WISE_DAMAGE_REPORT_CSV,
  ITEM_WISE_PURCHASE_RETURN_REPORT_CSV,
  PRODUCT_ISSUE_CSV,
  NEW_STOCK_REPORT_CSV,
  CUSTOMER_HISTORY_REPORT_CSV,
  SALES_CANCEL_REPORT_CSV,
  CASH_BANK_REPORT_CSV,
  CREDIT_SALES_REPORT_CSV,
  INTER_LOCATION_TRANSFER_REPORT,
  MARGIN_REPORT_CSV,
  ITEM_WISE_TRANSIT_REPORT_CSV,
  PRODUCT_LIST_CSV,
  PURCHASE_ORDER_SUMMARIZE_STATUS_REPORT_CSV,
  ITEM_WISE_PRODUCTION_RECEIVING_REPORT_CSV,
  SUMMARIZED_PRODUCTION_RECEIVING_REPORT_CSV,
  PRODUCT_LIST_TALLY_CSV,
  ITEM_WISE_SALES_REPORT_TALLY_CSV,
};

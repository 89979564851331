import * as React from 'react';
import { Tabs, Icon } from 'antd';
import { checkAddPermission } from '../../helper';
import { VendorService } from '../../../service/VendorService';
import { VendorEdges } from './constants';
import { AddVendor } from './AddVendor';
import { Table } from '../../common/Table';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../user/constants';

export class Vendor extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      vendors: [],
      activeKey: '1',
      selectedVendor: null,
      user: null,
    };
  }

  vendorService = new VendorService();
  userService = new UserService();

  componentDidMount() {
    this.vendorService?.vendor$.subscribe((vendors) =>
      this.setState({ vendors }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedVendor: null });
      return;
    }
    this.setState({ activeKey });
  };

  vendorColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedVendor: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Address',
      dataIndex: 'node.address',
      key: 'address',
    },
    {
      title: 'Contact Person Name',
      dataIndex: 'node.contactPersonName',
      key: 'contactPersonName',
    },
    {
      title: 'Contact No',
      dataIndex: 'node.contactNo',
      key: 'contactNo',
    },
    {
      title: 'Email',
      dataIndex: 'node.email',
      key: 'email',
    },
    {
      title: 'GST IN',
      dataIndex: 'node.gstin',
      key: 'gstin',
    },
  ];

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Vendor
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.vendors}
            columns={this.vendorColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Vendor
            </span>
          }
          key="2"
        >
          <AddVendor
            vendor={this.state.selectedVendor?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  vendors: VendorEdges[];
  activeKey: string;
  selectedVendor: VendorEdges | null;
  user?: UserNodeEdge | null;
}

interface Props {}

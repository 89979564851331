import * as React from 'react';
import { Tabs, Table, Icon, Spin, Skeleton, Row, Col } from 'antd';
import { CompanyService } from '../../service/CompanyService';
import { checkAddPermission } from '../helper';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import moment from 'moment';
import { AddReceiving } from './AddReceiving';
import { GraphqlQuery } from 'requestapijs';
import { pendingReceivingQuery } from './constants';
import { TransferNodeConnection, TransferNodeEdge } from '../../schema';
import { Pagination } from 'sha-el-design/lib';
import { DateService } from '../../service/DateService';
import { AcceptedReceiving } from './AcceptedReceiving';
import { PDFViewer } from '@react-pdf/renderer';
import { ReceivingReceiptJharcraft } from './ReceivingReceiptJharcraft';
import { ReceivingTaxInvoiceJharcraft } from './ReceivingTaxInvoiceJharcraft';
import { TSInput } from '../common/TSInput';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { ItemWiseReceivingReport } from './ItemWiseReceivingReport';
import { BranchNodeEdge } from '../master/Branch/constants';
import { TransferNode } from '../Transfer/constants';
import { ReceivingTaxInvoiceKhadi } from './ReceivingTaxInvoiceKhadi';
import { ReceivingReceiptKhadi } from './ReceivingReceiptKhadi';

export class PendingReceiving extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedTransfer: null,
      business: null,
      company: null,
      after: '',
      currentPage: 1,
      invoiceNo: '',
      user: null,
      branch: null,
    };
  }

  companyService = new CompanyService();
  dateService = new DateService();
  userService = new UserService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );

    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService.selectedBussiness$.subscribe((branch) =>
      this.setState({ branch }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedTransfer: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  transferColumn = () => {
    const transferColumns = [
      {
        title: 'Transfer Invoice No',
        dataIndex: 'node.transferInvoiceNo',
        key: 'transferInvoiceNo',
        render: (text, obj) => (
          <a
            onClick={() => {
              this.setState({ selectedTransfer: obj, activeKey: '2' });
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'node.date',
        key: 'date',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'From',
        dataIndex: 'node.fromLocation.name',
        key: 'fromLocation',
        render: (value) => value || 'Head Office',
      },
    ];
    return transferColumns;
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  handleSelectedReceiving = (
    selectedTransfer: TransferNodeEdge,
    activeKey: string,
  ) => {
    this.setState({ selectedTransfer, activeKey });
  };

  render() {
    if (!this.state.company || !this.state.business) return <Spin />;
    const {
      company: { id: companyId },
      currentPage,
      activeKey,
      business: { type: businessType, id: businessId },
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Pending Receiving
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={this.state.invoiceNo}
                onChange={(e) => this.setState({ invoiceNo: e.target.value })}
                name="name"
                type="text"
                addonBefore="Search Pending Receiving"
                placeholder="Enter Invoice No."
              />
            </Col>
          </Row>

          <GraphqlQuery
            queryString={pendingReceivingQuery}
            variables={{
              companyId,
              activeKey,
              toLocationId: businessType === 'branch' ? businessId : '',
              toHeadOffice: businessType === 'company' ? true : false,
              date_Lte: this.dateService?.date$.value,
              transferInvoice: this.state.invoiceNo,
            }}
            render={(
              response: { allTransfers: TransferNodeConnection },
              error,
              loading,
            ) => {
              if (loading) {
                return <Spin />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allTransfers.edges}
                    columns={this.transferColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allTransfers.pageInfo.endCursor,
                        response.allTransfers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user &&
          checkAddPermission(this.state.user) &&
          this.state.activeKey === '2' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Add Receiving
                </span>
              }
              key="2"
            >
              <AddReceiving
                transfer={
                  this.state.selectedTransfer?.node as unknown as TransferNode
                }
                handleTabChange={this.handleTabChange}
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Accepted Receiving
            </span>
          }
          key="3"
        >
          <AcceptedReceiving
            activeKey={this.state.activeKey}
            handleSelectedReceiving={this.handleSelectedReceiving}
          />
        </Tabs.TabPane>
        {this.state.selectedTransfer && this.state.activeKey === '4' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="4"
          >
            {this.state.selectedTransfer.node &&
            this.state.company?.name === 'Jharcraft' ? (
              <PDFViewer style={{ width: '100%', height: '1000px' }}>
                <ReceivingReceiptJharcraft
                  company={this.state.company}
                  business={this.state.business}
                  receiving={this.state.selectedTransfer.node}
                />
              </PDFViewer>
            ) : (
              this.state.selectedTransfer.node &&
              this.state.company?.name.toLowerCase().includes('khadi') && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <ReceivingReceiptKhadi
                    company={this.state.company}
                    business={this.state.business}
                    receiving={this.state.selectedTransfer.node}
                  />
                </PDFViewer>
              )
            )}
          </Tabs.TabPane>
        )}
        {this.state.selectedTransfer && this.state.activeKey === '5' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="5"
          >
            {this.state.selectedTransfer.node &&
            this.state.company?.name === 'Jharcraft' ? (
              <PDFViewer style={{ width: '100%', height: '1000px' }}>
                <ReceivingTaxInvoiceJharcraft
                  company={this.state.company}
                  business={this.state.business}
                  receiving={this.state.selectedTransfer.node}
                />
              </PDFViewer>
            ) : (
              this.state.selectedTransfer.node &&
              this.state.company?.name.toLowerCase().includes('khadi') && (
                <PDFViewer style={{ width: '100%', height: '1000px' }}>
                  <ReceivingTaxInvoiceKhadi
                    company={this.state.company}
                    business={this.state.business}
                    receiving={this.state.selectedTransfer.node}
                  />
                </PDFViewer>
              )
            )}
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Item Wise Receiving Report
            </span>
          }
          key="6"
        >
          <ItemWiseReceivingReport
            company={this.state.company}
            branch={this.state.branch}
            business={this.state.business}
            activeKey={this.state.activeKey}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedTransfer: TransferNodeEdge | null;
  business: Bussiness | null;
  company: CompanyNodeEdge | null;
  after: string;
  invoiceNo: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  branch?: BranchNodeEdge | null;
}

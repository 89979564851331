import * as React from 'react';
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Button,
  Skeleton,
  Icon,
  Table,
  Checkbox,
  Modal,
} from 'antd';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import { CompanyNodeEdge } from '../master/Company/constants';
import { TransferNodeConnection, CommissionDetailsNode } from '../../schema';
import { LOCATION_WISE_PURCHASE_REPORT_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';
import { locationWiseTransferQuery, transferNode } from './constants';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { TransferNode } from './constants';
import { ProductDetailNodeEdge } from '../Sales/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import { getValue } from '../common/AutoCompleteHelper';
import { AddTransfer } from './AddTransfer';
import { Pagination } from 'sha-el-design/lib';
import { Label } from '../common/TSLabel';

export class LocationWiseTransferReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      after: '',
      currentPage: 1,
      updateDataCount: 0,
      values: transferNode,
      transferFrom: null,
      transferTo: null,
      openDetails: false,
      selectedTransfer: null,
      toHeadOffice: null,
      fromHeadOffice: null,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'node.date',
      render: (value) => value && moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Transfer Number',
      key: 'transferNumber',
      dataIndex: 'node.transferInvoiceNo',
    },
    {
      title: 'From',
      key: 'transferFrom',
      dataIndex: 'node.fromLocation.name',
      render: (value) => (value ? value : 'Head Office'),
    },
    {
      title: 'To',
      key: 'transferTo',
      dataIndex: 'node.toLocation.name',
      render: (value) => (value ? value : 'Head Office'),
    },
    {
      title: 'Details',
      key: 'details',
      render: (value) => {
        return (
          <Button
            onClick={() =>
              this.setState({
                selectedTransfer: value?.node,
                openDetails: true,
              })
            }
            children={<Icon type="eye" />}
          />
        );
      },
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }
    fetch(LOCATION_WISE_PURCHASE_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'department'
              : 'location'
          }-wise-tansfer-${new Date()}`,
          `${
            this.props.company.name.toLowerCase().includes('seva sadan')
              ? 'DEPARTMENT'
              : 'LOCATION'
          } WISE TRANSFER REPORT`,
          this.props.company.name,
          'Head Office',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const {
      startDate,
      endDate,
      currentPage,
      after,
      updateDataCount,
      transferFrom,
      transferTo,
      fromHeadOffice,
      toHeadOffice,
      selectedTransfer,
    } = this.state;
    const {
      company: { id: companyId },
    } = this.props;
    const variables = {
      startDate,
      endDate,
      companyId,
      after,
      updateDataCount,
      transferFrom,
      transferTo,
      fromHeadOffice,
      toHeadOffice,
    };
    return (
      <div>
        <Row>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={(startDate && moment(startDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !endDate) {
                  return false;
                }
                return currentDate.valueOf() > moment(endDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !startDate) {
                  return false;
                }
                return currentDate.valueOf() < moment(startDate).valueOf();
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          {(this.props.company.name.toLowerCase() === 'jharcraft' ||
            this.props.company?.name.toLowerCase().includes('khadi')) && (
            <>
              {' '}
              <Col span={3}>
                <Label title="From Head Office">
                  <Checkbox
                    onChange={(e) =>
                      this.setState({
                        fromHeadOffice: e.target.checked
                          ? e.target.checked
                          : null,
                        transferFrom: null,
                      })
                    }
                  />
                </Label>
              </Col>
              <Col span={5}>
                <TSAutoComplete
                  error=""
                  inputProps={{ name: 'transferFrom', addonBefore: 'From' }}
                  dataSource={this.props.company.branches?.edges || []}
                  key="id"
                  name="transferFrom"
                  onValueSelect={(e) =>
                    this.setState({ transferFrom: e.target.value })
                  }
                  displayKey="name"
                  textValue={
                    this.state.transferFrom
                      ? getValue(
                          this.props.company.branches?.edges,
                          this.state.transferFrom,
                        )
                      : ''
                  }
                  allowClear={true}
                  disabled={!!this.state.fromHeadOffice}
                />
              </Col>
              <Col span={3}>
                <Label title="To Head Office">
                  <Checkbox
                    onChange={(e) =>
                      this.setState({
                        toHeadOffice: e.target.checked
                          ? e.target.checked
                          : null,
                        transferTo: null,
                      })
                    }
                  />
                </Label>
              </Col>
              <Col span={5}>
                <TSAutoComplete
                  error=""
                  inputProps={{ name: 'transferTo', addonBefore: 'To' }}
                  dataSource={this.props.company.branches?.edges || []}
                  key="id"
                  name="transferTo"
                  onValueSelect={(e) =>
                    this.setState({ transferTo: e.target.value })
                  }
                  displayKey="name"
                  textValue={
                    this.state.transferTo
                      ? getValue(
                          this.props.company.branches?.edges,
                          this.state.transferTo,
                        )
                      : ''
                  }
                  allowClear={true}
                  disabled={!!this.state.toHeadOffice}
                />
              </Col>
            </>
          )}
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        {startDate && endDate && (
          <GraphqlQuery
            queryString={locationWiseTransferQuery}
            variables={variables}
            render={(
              response: { allTransfers: TransferNodeConnection },
              error,
              loading,
            ) => {
              if (error) {
                return <h3>Something Went Wrong!!</h3>;
              }
              if (loading) {
                return <Skeleton active />;
              }
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allTransfers.edges}
                    columns={this.columns()}
                    rowKey={(record) => record.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allTransfers.pageInfo.endCursor,
                        response.allTransfers.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        )}
        <Modal
          title="View Transfer Details"
          visible={this.state.openDetails}
          footer={<div />}
          onCancel={() => this.setState({ openDetails: false })}
        >
          <AddTransfer
            transfer={selectedTransfer}
            company={this.props.company}
            business={
              {
                ...(selectedTransfer && selectedTransfer.fromLocation),
                type: 'branch',
              } as any
            }
            isEditActive
            isViewActive
          />
        </Modal>
        {startDate && endDate && (
          <Button
            key="download"
            style={{ float: 'right' }}
            icon="download"
            onClick={() => this.downloadCsv(variables)}
          />
        )}
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  after: string;
  currentPage: number;
  updateDataCount: number;
  values: TransferNode<ProductDetailNodeEdge[], CommissionDetailsNode[]>;
  transferTo: BranchNodeEdge | null;
  transferFrom: BranchNodeEdge | null;
  openDetails: boolean;
  selectedTransfer: TransferNode | null;
  toHeadOffice: boolean | null;
  fromHeadOffice: boolean | null;
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  DeliveryInstructionInput,
  CreateDeliveryInstructionPayload,
  CreateDeliveryInstructionInput,
} from '../schema';
import { deliveryInstructionMutation } from '../components/DeliveryInstruction/constants';

export class DeliveryInstructionService {
  static instance;

  constructor() {
    if (DeliveryInstructionService.instance) {
      return DeliveryInstructionService.instance;
    } else {
      DeliveryInstructionService.instance = this;
    }
  }

  companyService = new CompanyService();

  addDeliveryInstruction(
    data: DeliveryInstructionInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data.productDetails = data.productDetails.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete (pd as any).product;
      delete (pd as any).movementFor;
      return pd;
    });
    Api.graphql<
      { input: CreateDeliveryInstructionInput },
      CreateDeliveryInstructionPayload
    >(
      API_URL,
      {
        input: {
          deliveryInstruction: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      deliveryInstructionMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  SaleChallanInput,
  CreateSaleChallanInput,
  CreateSaleChallanPayload,
  SaleChallanNode,
} from '../schema';
import { saleChallanMutation } from '../components/SaleInvoice/constants';

export class SaleInvoiceService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (SaleInvoiceService.instance) {
      return SaleInvoiceService.instance;
    } else {
      SaleInvoiceService.instance = this;
    }
  }

  addSaleChallan(
    data: SaleChallanInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as SaleChallanNode).deliveryInstruction;
    delete (data as any as SaleChallanNode).hsnGstWiseAmount;
    data.productDetails = data.productDetails.map((pd) => {
      pd.date = data.date;
      pd.companyId = this.companyService?.company$.getValue()?.id;
      delete (pd as any).orderQuantity;
      delete (pd as any).dispatchedQuantity;
      delete (pd as any).remainingQuantity;
      delete (pd as any).orderAmount;
      delete (pd as any).product;
      return pd;
    });
    Api.graphql<{ input: CreateSaleChallanInput }, CreateSaleChallanPayload>(
      API_URL,
      {
        input: {
          saleChallan: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      saleChallanMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

import * as React from 'react';
import { Table, Tabs, Icon, Spin, Row, Col, Skeleton } from 'antd';
import { CustomerService } from '../../../service/CustomerService';
import { CompanyService } from '../../../service/CompanyService';
import { CompanyNodeEdge } from '../Company/constants';
import { checkAddPermission } from '../../helper';
import { TSInput } from '../../common/TSInput';
import { GraphqlQuery } from 'requestapijs';
import { ContractorNodeConnection, ContractorNodeEdge } from '../../../schema';
import { Pagination } from 'sha-el-design';
import { contractorQuery } from './constants';
import { AddContractor } from './AddContractor';
import { UserService } from '../../../service/UserService';
import { UserNodeEdge } from '../../../components/user/constants';

export class Contractor extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedContractor: null,
      company: null,
      name: '',
      after: '',
      currentPage: 1,
      user: null,
    };
  }

  customerService = new CustomerService();
  companyService = new CompanyService();
  userService = new UserService();
  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedContractor: null });
      return;
    }
    this.setState({ activeKey });
  };

  contractorColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() =>
            this.setState({ selectedContractor: obj, activeKey: '2' })
          }
          children={text}
        />
      ),
    },
    {
      title: 'Address',
      dataIndex: 'node.address',
      key: 'address',
    },
    {
      title: 'Contact No',
      dataIndex: 'node.contactNo',
      key: 'contactNo',
    },
    {
      title: 'Email',
      dataIndex: 'node.email',
      key: 'email',
    },
    {
      title: 'TDS Rate',
      dataIndex: 'node.tdsRate',
      key: 'tdsRate',
    },
    {
      title: 'Service Charge',
      dataIndex: 'node.serviceCharge',
      key: 'serviceCharge',
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) return <Spin />;
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { company, name, after, currentPage, activeKey } = this.state;
    const variables = { companyId: company.id, after, name, activeKey };

    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Contractor
            </span>
          }
          key="1"
          closable={false}
        >
          <Row>
            <Col span={8}>
              <TSInput
                error=""
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                name="name"
                type="text"
                addonBefore="Search Contractor"
                placeholder="Enter Name or Contact No."
              />
            </Col>
          </Row>
          <GraphqlQuery
            queryString={contractorQuery}
            variables={name ? { ...variables, name } : variables}
            render={(
              response: { allContractor: ContractorNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response && company) {
                return [
                  <Table
                    key="Table"
                    dataSource={response.allContractor.edges}
                    columns={this.contractorColumn()}
                    rowKey={(record) => record.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={Infinity}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allContractor.pageInfo.endCursor,
                        response.allContractor.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Contractor
            </span>
          }
          key="2"
        >
          <AddContractor
            company={company}
            contractor={this.state.selectedContractor?.node}
            handleTabChange={this.handleTabChange}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedContractor: ContractorNodeEdge | null;
  company: CompanyNodeEdge | null;
  name: string;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
}

interface Props {}

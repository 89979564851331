import { Icon, Skeleton, Table, Tabs } from 'antd';
import React from 'react';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design/lib';
import { CompanyService } from '../../../service/CompanyService';
import { UserService } from '../../../service/UserService';
import { checkAddPermission } from '../../helper';
import { UserNodeEdge } from '../../user/constants';
import moment from 'moment';

import {
  allDiscountQuery,
  DiscountNodeConnection,
  DiscountNodeEdge,
} from './constants';
import { AddDiscount } from './AddDiscount';

export class Discount extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedDiscount: null,
      after: '',
      currentPage: 1,
      user: null,
      companyId: null,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyId: company.id }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedDiscount: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  discountColumns = () => [
    {
      title: 'Category Name',
      dataIndex: 'node.category.name',
      key: 'category',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedDiscount: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Branch',
      dataIndex: 'node.branch.name',
      key: 'branchName',
      sortingValue: (text) => text,
    },
    {
      title: 'Start Date',
      dataIndex: 'node.startDate',
      key: 'startDate',
      render: (text) => moment(text).format('DD-MM-YYYY'),
      sortingValue: (text) => text,
    },
    {
      title: 'End Date',
      dataIndex: 'node.endDate',
      key: 'endDate',
      render: (text) => moment(text).format('DD-MM-YYYY'),
      sortingValue: (text) => text,
    },

    {
      title: 'Discount Percent(%)',
      dataIndex: 'node.discountPercent',
      key: 'discountPercent',
      sortingValue: (text) => text,
    },
  ];

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey, companyId } = this.state;
    const variables = { after, companyId, activeKey };
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Discount
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={allDiscountQuery}
            variables={variables}
            render={(
              response: {
                allDiscount: DiscountNodeConnection;
              },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allDiscount.edges}
                    columns={this.discountColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allDiscount.pageInfo.endCursor,
                        response.allDiscount.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Discount
            </span>
          }
          key="2"
        >
          <AddDiscount
            discount={this.state.selectedDiscount?.node}
            handleTabChange={this.handleTabChange}
            companyId={this.state.companyId}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedDiscount: DiscountNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  companyId?: string | null;
}

interface Props {}

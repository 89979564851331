import * as React from 'react';
import { Col, Row, Button, notification } from 'antd';
import { TSInput } from '../../common/TSInput';
import { StateInput, StateNode } from '../../../schema';
import { StateError, STATE_DATA, STATE_ERROR } from './constant';
import { StateService } from '../../../service/StateService';

export class AddState extends React.Component<Props, State> {
  stateService = new StateService();

  constructor(props: Props) {
    super(props);
    this.state = {
      state: this.handlePropsValueUpdate(props),
      buttonDisabled: false,
      error: STATE_ERROR,
    };
  }

  handlePropsValueUpdate = (props: Props) => {
    const state = props.state || STATE_DATA;
    return state;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ state: this.handlePropsValueUpdate(nextProps) });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const state = { ...this.state.state };
    state[e.target.name] = e.target.value;
    this.setState({ state });
  };

  onSubmit = () => {
    this.setState({ buttonDisabled: true });
    this.stateService?.addState(
      { ...this.state.state },
      (response) => {
        this.setState({ buttonDisabled: false });
        notification.success({
          message: 'State ' + this.state.state.id ? 'Updated' : 'Added',
          description: `state named ${this.state.state.name} was successfully ${
            this.state.state.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'state ' + this.state.state.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };
  clear = () => {
    this.setState({
      state: JSON.parse(JSON.stringify(STATE_DATA)),
      buttonDisabled: false,
    });
  };

  render() {
    const error = this.state.error;
    const value = this.state.state;

    return (
      <div>
        <Row>
          <Col span={12}>
            <TSInput
              error={error.name}
              value={value.name}
              name="name"
              type="text"
              addonBefore="State"
              onChange={this.onChange}
            />
          </Col>
          <Col span={12}>
            <TSInput
              error={error.gstCode}
              value={value.gstCode}
              name="gstCode"
              type="text"
              addonBefore="GST Code"
              onChange={this.onChange}
            />
          </Col>
        </Row>
        <Row>
          <Button
            type="dashed"
            children="Clear"
            style={{ width: '50%' }}
            onClick={this.clear}
          />
          <Button
            type="primary"
            children="Submit"
            style={{ width: '50%' }}
            onClick={this.onSubmit}
            disabled={this.state.buttonDisabled}
          />
        </Row>
      </div>
    );
  }
}

interface Props {
  handleTabChange: (activeKey: string) => void;
  state?: StateNode;
}

interface State {
  state: StateInput;
  error: StateError;
  buttonDisabled: boolean;
}

import * as React from 'react';
import { CompanyNodeEdge } from './constants';
import { Table, Tabs, Icon } from 'antd';
import Api from 'requestapijs';
import { AddCompany } from './AddCompany';
import { checkAddPermission } from '../../helper';
import { API_URL } from '../../../config';
import { UserNodeEdge } from '../../../components/user/constants';
import { UserService } from '../../../service/UserService';

export class Company extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      company: [],
      activeKey: '1',
      selectedCompany: null,
      user: null,
    };
  }
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedCompany: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  companyColumn = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedCompany: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      // sortingValue: (text) => text,
    },
    {
      title: 'GST In',
      dataIndex: 'node.gstin',
      key: 'gstin',
    },
    {
      title: 'GST Rate',
      dataIndex: 'node.gstRate',
      key: 'gstRate',
    },
    {
      title: 'Amount Per Point Sale',
      dataIndex: 'node.amountPerPointSale',
      key: 'amountPerPointSale',
    },
    {
      title: 'Amount Per Point Redeem',
      dataIndex: 'node.amountPerPointRedeem',
      key: 'amountPerPointRedeem',
    },
    {
      title: 'Bank Name',
      dataIndex: 'node.bankName',
      key: 'bankName',
    },
    {
      title: 'Branch',
      dataIndex: 'node.branch',
      key: 'branch',
    },
    {
      title: 'Account Number',
      dataIndex: 'node.accountNo',
      key: 'accountNo',
    },
    {
      title: 'IFSC Code',
      dataIndex: 'node.ifscCode',
      key: 'ifscCode',
    },
  ];

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Company
            </span>
          }
          key="1"
          closable={false}
        >
          <Table
            dataSource={this.state.company}
            columns={this.companyColumn()}
            rowKey={(record) => record.node.id || ''}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Company
            </span>
          }
          key="2"
        >
          <AddCompany company={this.state.selectedCompany?.node} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  company: {
    cursor: string;
    node: CompanyNodeEdge;
  }[];
  activeKey: string;
  selectedCompany: {
    cursor: string;
    node: CompanyNodeEdge;
  } | null;
  user?: UserNodeEdge | null;
}

interface Props {}

interface Response {
  data: {
    allCompanies: {
      edges: {
        cursor: string;
        node: CompanyNodeEdge;
      }[];
    };
  };
}

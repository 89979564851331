import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { PurchaseNodeConnection, PurchaseNodeEdge } from '../../schema';
import { OptionProps } from 'antd/lib/select';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  purchase?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface PurchaseDropDownProps extends AutoCompleteProps {
  company: CompanyNodeEdge;
  extrafields?: string[];
}

export class PurchaseDropDown extends React.Component<
  PurchaseDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      billNo: '',
      touched: false,
    };
  }

  value = (purchases: PurchaseNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.billNo;
    }

    const purchase = purchases.find((v) => v.node?.id === this.props.value);
    if (!purchase) return;
    return purchase.node?.billNo;
  };

  render() {
    const { billNo } = this.state;
    const { extrafields, value, onSelect, company } = this.props;
    if (!company) return <Spin />;
    return (
      <GraphqlQuery
        queryString={`query PurchaseDropDown($after: String!, $id: ID, $billNo: String, $companyId: ID) {
          allPurchases(first: 10, after: $after, id: $id, billNo_Icontains: $billNo, companyId: $companyId, isReturn: false) {
            edges {
              node {
                id
                billNo
                ${extrafields?.join('\n')}
              }
            }
          }
        }`}
        variables={{
          after: '',
          billNo,
          companyId: company.id,
          id: value && !billNo ? value : '',
        }}
        render={(
          response: { allPurchases: PurchaseNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }

          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          return (
            <div style={{ margin: '5px 0' }}>
              <AutoComplete
                {...this.props}
                placeholder="Select Bill No To Return"
                dataSource={response.allPurchases.edges.map((purchase) => (
                  <Option
                    key={purchase.node?.id}
                    label={purchase.node}
                    purchase={purchase}
                  >
                    {purchase.node?.billNo}
                  </Option>
                ))}
                value={this.value(response.allPurchases.edges)}
                optionLabelProp="children"
                style={{ width: '100%' }}
                onSearch={(purchase) =>
                  this.setState({ billNo: purchase, touched: true })
                }
                onSelect={(e, f) => {
                  this.setState({
                    billNo: (f as any).props.purchase.node.billNo,
                    touched: true,
                  });
                  onSelect && onSelect(e, f);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

interface State {
  billNo: string;
  touched: boolean;
}

import * as React from 'react';
import { ProductConversionForm } from './ProductConversionForm';
import moment from 'moment';
import { Button, Popconfirm, notification } from 'antd';
import { ProductDetailsService } from '../../../service/ProductDetailsService';
import { ProductNode, ProductDetailsInput } from '../../../schema';
import { CompanyNodeEdge } from '../Company/constants';
import { values } from './constant';

export class AddProductConversion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      values: JSON.parse(JSON.stringify(values)),
      buttonDisabled: false,
      productObjList: [],
      newProductObjList: [],
    };
  }

  productDetailsService = new ProductDetailsService();

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.values[index][e.target.name] = e.target.value;
    nextState.values[index].amount =
      Number(nextState.values[index].quantity) *
      Number(nextState.values[index].rate);
    this.setState(nextState);
  };

  productObjChangeHandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.productObjList[index] = productObj;
    nextState.values[index].purchaseRate = productObj.purchaseRate;
    nextState.values[index].rate = productObj.sellingRate;
    this.setState(nextState);
  };

  newProductObjChangeHandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.newProductObjList[index] = productObj;
    this.setState(nextState);
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.values.push({
        date: moment().format('YYYY-MM-DD'),
        productId: null,
        quantity: null,
        purchaseRate: null,
        rate: null,
        amount: null,
        newProductId: null,
        movementType: 'ITEM_CONVERSION',
        companyId: null,
      });
    } else {
      nextState.values.splice(index, 1);
    }
    this.setState(nextState);
  };

  saveProductConversion = () => {
    this.setState({ buttonDisabled: true });
    this.productDetailsService?.addProductDetails(
      this.state.values,
      (response) => {
        this.clear();
        notification.success({
          message:
            'Product Conversion ' + this.state.values[0].id
              ? 'Updated'
              : 'Added',
          description: `Product Conversion was successfully ${
            this.state.values[0].id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Product Conversion ' + this.state.values[0].id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      values: JSON.parse(JSON.stringify(values)),
      buttonDisabled: false,
    });
  };

  render() {
    return (
      <div>
        <ProductConversionForm
          values={this.state.values}
          company={this.props.company}
          productChangeHandler={this.productChangeHandler}
          productObjChangeHandler={this.productObjChangeHandler}
          newProductObjChangeHandler={this.newProductObjChangeHandler}
          updateProductsRow={this.updateProductsRow}
          productObjList={this.state.productObjList}
          newProductObjList={this.state.newProductObjList}
        />
        <div style={{ marginTop: '50px' }}>
          <Popconfirm
            title="Are you sure to clear?"
            onConfirm={this.clear}
            okText="Yes"
            cancelText="No"
          >
            <Button type="dashed" children="Clear" style={{ width: '50%' }} />
          </Popconfirm>
          <Button
            type="primary"
            onClick={this.saveProductConversion}
            children="Submit"
            style={{ width: '50%' }}
            disabled={this.state.buttonDisabled}
          />
        </div>
      </div>
    );
  }
}

interface State {
  values: ProductDetailsInput[];
  buttonDisabled: boolean;
  productObjList: ProductNode[];
  newProductObjList: ProductNode[];
}

interface Props {
  company: CompanyNodeEdge;
  handleTabChange: (key) => void;
}

import * as React from 'react';
import {
  Tabs,
  Icon,
  Spin,
  Collapse,
  Row,
  Col,
  Form,
  InputNumber,
  Button,
  notification,
} from 'antd';
import { OpeningStockReport } from './OpeningStockReport';
import { CompanyService } from '../../service/CompanyService';
import { AddOpeningStock } from './AddOpeningStock';
import { GraphqlQuery } from 'requestapijs';
import { openingStockObject, productCategoryQuery } from './constants';
import { ProductCategoryNodeConnection, ProductNodeEdge } from '../../schema';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { Pagination } from 'sha-el-design';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';
import { OpeningStockService } from '../../service/OpeningStockService';
import { PhysicalStockEntry } from '../PhysicalStock/PhysicalStockEntry';
import { PhysicalStockReportCategory } from '../PhysicalStock/PhysicalStockReportCategory';

export class OpeningStock extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      openingStocksForCurrentCategory: [],
      productsForCurrentCategory: [],
      activeKey: '1',
      isViewActive: false,
      afterCategory: '',
      afterProduct: '',
      currentPageCategory: 1,
      currentPageProduct: 1,
      categoryId: '',
      company: null,
      business: null,
      user: null,
      defaultQuantity: 1,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();
  openingStockService = new OpeningStockService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) =>
      this.setState({ business }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  afterStackCategory = [''];

  onPageChangeCategory = (
    next: boolean,
    afterCategory: string,
    nextPage: boolean,
  ) => {
    if (next && nextPage) {
      this.setState({
        afterCategory,
        currentPageCategory: this.state.currentPageCategory + 1,
      });
      this.afterStackCategory.push(afterCategory);
    } else if (!next && this.afterStackCategory.length > 1) {
      this.afterStackCategory.pop();
      this.setState({
        afterCategory:
          this.afterStackCategory[this.afterStackCategory.length - 1],
        currentPageCategory: this.state.currentPageCategory - 1,
      });
    }
  };

  afterStackProduct = [''];

  onPageChangeProduct = (
    next: boolean,
    afterProduct: string,
    nextPage: boolean,
  ) => {
    if (next && nextPage) {
      this.setState({
        afterProduct,
        currentPageProduct: this.state.currentPageProduct + 1,
      });
      this.afterStackProduct.push(afterProduct);
    } else if (!next && this.afterStackProduct.length > 1) {
      this.afterStackProduct.pop();
      this.setState({
        afterProduct: this.afterStackProduct[this.afterStackProduct.length - 1],
        currentPageProduct: this.state.currentPageProduct - 1,
      });
    }
  };

  setDefaultQuanitiy = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.state.defaultQuantity &&
      this.openingStockService?.addDefaultOpeningStock(
        this.state.defaultQuantity,
        () => {
          notification.success({
            message: 'Updated',
            description: `Opening Stock was successfully Updated`,
          });
          this.setState({
            defaultQuantity: null,
          });
        },
        () =>
          notification.error({
            message: 'Opening Stock Update Error',
          }),
      );
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const {
      afterCategory,
      currentPageCategory,
      afterProduct,
      currentPageProduct,
    } = this.state;

    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              Opening Stock
            </span>
          }
          key="1"
          closable={false}
        >
          {this.state.company ? (
            <>
              {this.state.company.name === 'Fashion 11-11' && (
                <Row>
                  <Col>
                    <Form
                      name="defaultQuantity"
                      layout="inline"
                      onSubmit={this.setDefaultQuanitiy}
                    >
                      <Form.Item label="Set Default Quantity">
                        <InputNumber
                          size="small"
                          value={this.state.defaultQuantity || 0}
                          onChange={(value) =>
                            this.setState({
                              defaultQuantity: value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          htmlType="submit"
                          disabled={this.state.defaultQuantity === null}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              )}
              <GraphqlQuery
                queryString={productCategoryQuery}
                variables={{
                  companyId: this.state.company.id,
                  after: afterCategory,
                  orderBy: 'name',
                }}
                render={(
                  response: {
                    allProductCategories: ProductCategoryNodeConnection;
                  },
                  error,
                  loading,
                ) => {
                  if (loading) return <Spin />;
                  if (this.state.company && response)
                    return [
                      <Collapse
                        key="collapse"
                        accordion={true}
                        onChange={(key) => {
                          this.setState({
                            categoryId: key && (key.slice(2) as string),
                          });
                        }}
                      >
                        {response.allProductCategories.edges.map(
                          (pc, indexPg) =>
                            this.state.business &&
                            this.state.company && (
                              <Collapse.Panel
                                header={pc.node?.name}
                                key={'pc' + pc.node?.id}
                              >
                                <OpeningStockReport
                                  business={this.state.business}
                                  company={this.state.company}
                                  categoryId={this.state.categoryId}
                                  after={afterProduct}
                                  currentPage={currentPageProduct}
                                  onPageChange={this.onPageChangeProduct}
                                />
                              </Collapse.Panel>
                            ),
                        )}
                      </Collapse>,
                      <Pagination
                        key="pagination"
                        totalCount={0}
                        currentPage={currentPageCategory}
                        batchSize={20}
                        cursorBasedPagination
                        onChange={(_p, _ps, next) =>
                          this.onPageChangeCategory(
                            next,
                            response.allProductCategories.pageInfo.endCursor,
                            response.allProductCategories.pageInfo.hasNextPage,
                          )
                        }
                        style={{ float: 'right' }}
                      />,
                    ];
                  return <Spin />;
                }}
              />
            </>
          ) : (
            <Spin />
          )}
        </Tabs.TabPane>
        {this.state.user &&
          checkAddPermission(this.state.user) &&
          this.state.business &&
          this.state.company && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Opening Stock Entry
                </span>
              }
              key="2"
              closable={false}
            >
              <AddOpeningStock
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )}
        {this.state.company &&
          this.state.company.name === 'Jharcraft' &&
          this.state.business && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Physical Stock Report
                </span>
              }
              key="3"
              closable={false}
            >
              <PhysicalStockReportCategory
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )}
        {/* {this.state.company &&
          this.state.company.name === 'Jharcraft' &&
          this.state.business && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="plus" />
                  Physical Stock Entry
                </span>
              }
              key="4"
              closable={false}
            >
              <PhysicalStockEntry
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )} */}
      </Tabs>
    );
  }
}

interface State {
  openingStocksForCurrentCategory: any[];
  activeKey: string;
  isViewActive: boolean;
  productsForCurrentCategory: ProductNodeEdge[];
  afterCategory: string;
  afterProduct: string;
  currentPageCategory: number;
  currentPageProduct: number;
  categoryId: string;
  company: CompanyNodeEdge | null;
  business: Bussiness | null;
  user?: UserNodeEdge | null;
  defaultQuantity?: number | null;
}

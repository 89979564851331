import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import { CompanyService } from './CompanyService';
import {
  MarginInput,
  MarginNode,
  CreateMarginPayload,
  CreateMarginInput,
} from '../schema';
import { marginMutation } from '../components/Margin/constants';

export class MarginService {
  static instance;
  companyService = new CompanyService();

  constructor() {
    if (MarginService.instance) {
      return MarginService.instance;
    } else {
      MarginService.instance = this;
    }
  }

  addMargin(
    data: MarginInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    delete (data as any as MarginNode).challan;
    Api.graphql<{ input: CreateMarginInput }, CreateMarginPayload>(
      API_URL,
      {
        input: {
          margin: {
            ...data,
            companyId: this.companyService?.company$.getValue()?.id,
          },
        },
      },
      marginMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      (error) => onError(error),
    );
  }
}

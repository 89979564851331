import Api from 'requestapijs';
import { API_URL, HEADER } from '../config';
import {
  RequestInput,
  CreateRequestInput,
  CreateRequestPayload,
  RequestNode,
} from '../schema';
import { requestMutation } from '../components/Request/constants';
import { CompanyService } from './CompanyService';

export class RequestService {
  static instance: RequestService;
  companyService = new CompanyService();

  constructor() {
    if (RequestService.instance) {
      return RequestService.instance;
    } else {
      RequestService.instance = this;
    }
  }

  AddRequest(
    data: RequestInput,
    onSuccess: (reponse) => void,
    onError: (response) => void,
  ) {
    data = {
      ...data,
      branchId:
        data.branchId || this.companyService?.selectedBussiness$.value?.id,
      companyId: this.companyService?.company$.value?.id,
    };
    delete (data as any as RequestNode).branch;
    Api.graphql<{ input: CreateRequestInput }, CreateRequestPayload>(
      API_URL,
      { input: { request: data } },
      requestMutation,
      HEADER,
      (response) => {
        onSuccess(response);
      },
      onError,
    );
  }
}

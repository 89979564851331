import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { DistrictNodeEdge, DistrictNodeConnection } from '../../../schema';
import { OptionProps } from 'antd/lib/select';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  district?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface DistrictDropDownProps extends AutoCompleteProps {
  extrafields?: string[];
}

export class DistrictDropdown extends React.Component<
  DistrictDropDownProps,
  State
> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props: Readonly<DistrictDropDownProps>) {
    super(props);

    this.state = {
      name: '',
      district: null,
      touched: false,
    };
  }

  value = (districts: DistrictNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const district = districts.find((v) => v.node?.id === this.props.value);
    if (!district) return;
    return district.node?.name;
  };

  render() {
    const { name } = this.state;
    const { extrafields, value, onSelect } = this.props;
    return (
      <GraphqlQuery
        queryString={`query DistrictDropDown($id: ID, $name: String, ) {
          allDistricts(id: $id, name_Icontains: $name) {
            edges {
              node {
                id
                name
              }
            }
          }
        }`}
        variables={{
          after: '',
          name,
          id: value && !name ? value : '',
        }}
        render={(
          response: { allDistricts: DistrictNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          if (response) {
            return (
              <div style={{ margin: '5px 0' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select District"
                  dataSource={response.allDistricts.edges.map((district) => (
                    <Option
                      key={district.node?.id}
                      label={district.node}
                      district={district}
                    >
                      {district.node?.name}
                    </Option>
                  ))}
                  value={this.value(response.allDistricts.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(district) =>
                    this.setState({ name: district, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.district.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
        }}
      />
    );
  }
}

interface State {
  name: string;
  district: DistrictNodeEdge | null;
  touched: boolean;
}

import * as React from 'react';
import {
  ProductNodeEdge,
  ProductError,
  productObject,
  productErrorObject,
  productWithBarcodeQuery,
  ProductQueryResponse,
} from './constants';
import { ProductForm } from './ProductForm';
import { Button, notification } from 'antd';
import { UnitEdges } from '../Unit/constants';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { ProductService } from '../../../service/ProductService';
import { UnitService } from '../../../service/UnitService';
import { forEach } from 'lodash';
import { CompanyNodeEdge } from '../Company/constants';
import { ProductNode } from '../../../schema';
import Api from 'requestapijs';
import { API_URL, HEADER } from '../../../config';

export class AddProduct extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      product: props.product
        ? {
            ...props.product,
            unitId: props.product.unit?.id,
            categoryId: props.product.category?.id,
            subCategoryId:
              props.product.subCategory && props.product.subCategory.id,
            hsnId: props.product.hsn && props.product.hsn.id,
            vendorId: props.product.vendor && props.product.vendor.id,
            discountCategoryId:
              props.product.discountCategory &&
              props.product.discountCategory.id,
          }
        : props.productForMargin
        ? {
            ...JSON.parse(JSON.stringify(productObject)),
            name: props.productForMargin.name,
            purchaseRate: props.productForMargin.purchaseRate,
            sellingRate: props.productForMargin.sellingRate,
          }
        : JSON.parse(JSON.stringify(productObject)),
      error: { ...productErrorObject },
      units: [],
      buttonDisabled: false,
      addedProduct: null,
    };
    delete this.state.product.unit;
    delete this.state.product.category;
    delete this.state.product.subCategory;
    delete this.state.product.hsn;
    delete this.state.product.stock;
    delete this.state.product.vendor;
    delete this.state.product.discountCategory;
  }

  productService = new ProductService();
  unitService = new UnitService();

  componentWillReceiveProps(nextProps: Props) {
    const product: ProductNodeEdge = nextProps.product
      ? {
          ...nextProps.product,
          unitId: nextProps.product.unit?.id,
          categoryId: nextProps.product.category?.id,
          subCategoryId:
            nextProps.product.subCategory && nextProps.product.subCategory.id,
          hsnId: nextProps.product.hsn && nextProps.product.hsn.id,
          vendorId: nextProps.product.vendor && nextProps.product.vendor.id,
          discountCategoryId:
            nextProps.product.discountCategory &&
            nextProps.product.discountCategory.id,
        }
      : nextProps.productForMargin
      ? {
          ...JSON.parse(JSON.stringify(productObject)),
          name: nextProps.productForMargin.name,
          purchaseRate: nextProps.productForMargin.purchaseRate,
          sellingRate: nextProps.productForMargin.sellingRate,
        }
      : JSON.parse(JSON.stringify(productObject));
    delete product.unit;
    delete product.category;
    delete product.subCategory;
    delete product.hsn;
    delete product.stock;
    delete product.vendor;
    delete product.discountCategory;

    this.setState({ product });
  }

  componentDidMount() {
    this.fetchUnits();
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const product = { ...this.state.product };
    product[e.target.name] = e.target.value;
    if (e.target.name === 'gstRate') {
      product.cgstRate = product.sgstRate = Number(e.target.value) / 2;
    }
    if (
      ['cgstRate', 'sgstRate'].indexOf(e.target.name) > -1 &&
      product.cgstRate &&
      product.sgstRate
    ) {
      product.gstRate = Number(product.cgstRate) + Number(product.sgstRate);
    }
    if (this.props.company.name.toLowerCase().includes('pearl')) {
      product.name = product.newBarcodeId = product.oldBarcodeId;
    }
    this.setState({ product });
  };

  checkError = () => {
    const error = { ...this.state.error };
    let isError = false;
    const requiredFields = [
      'categoryId',
      'name',
      'unitId',
      'purchaseRate',
      'sellingRate',
    ];
    forEach(this.state.product, (value, key) => {
      if (
        typeof value === 'string' &&
        requiredFields.indexOf(key) > -1 &&
        !value.trim()
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (!value && requiredFields.indexOf(key) > -1) {
        isError = true;
        error[key] = `This is a Required field`;
      } else {
        error[key] = '';
      }
    });

    if (!this.props.company.gstRate && !this.state.product.hsnId) {
      isError = true;
      error.hsnId = `This is a required field`;
    }

    const requiredFieldsForJharcraft = [
      'division',
      'section',
      'materialType',
      'productType',
    ];
    if (
      this.props.company.name.toLowerCase() === 'jharcraft' ||
      this.props.company?.name.toLowerCase().includes('khadi')
    ) {
      forEach(this.state.product, (value, key) => {
        if (
          typeof value === 'string' &&
          requiredFieldsForJharcraft.indexOf(key) > -1 &&
          !value.trim()
        ) {
          isError = true;
          error[key] = `This is a Required field`;
        } else if (!value && requiredFieldsForJharcraft.indexOf(key) > -1) {
          isError = true;
          error[key] = `This is a Required field`;
        }
      });
    }

    this.setState({ error }, () => {
      if (!isError) {
        this.olBarcodeCheck();
      }
    });
    return isError;
  };

  olBarcodeCheck = () => {
    const error = { ...this.state.error };
    const product = { ...this.state.product };
    if (product.oldBarcodeId) {
      Api.graphql<{}, ProductQueryResponse>(
        API_URL,
        { companyId: this.props.company.id, barcode: product.oldBarcodeId },
        productWithBarcodeQuery,
        HEADER,
        (response) => {
          const productData = response.data.allProducts.edges;
          if (productData.length && productData[0].node.id !== product.id) {
            error.oldBarcodeId =
              'A product with same barcode id already exists';
            this.setState({ error }, this.newBarcodeCheck);
          } else {
            error.oldBarcodeId = '';
            this.setState({ error }, this.newBarcodeCheck);
          }
        },
        (err) => err,
      );
    } else {
      this.newBarcodeCheck();
    }
  };

  newBarcodeCheck = () => {
    const error = { ...this.state.error };
    const product = { ...this.state.product };
    if (product.newBarcodeId) {
      Api.graphql<{}, ProductQueryResponse>(
        API_URL,
        { companyId: this.props.company.id, barcode: product.newBarcodeId },
        productWithBarcodeQuery,
        HEADER,
        (response) => {
          const productData = response.data.allProducts.edges;
          if (productData.length && productData[0].node.id !== product.id) {
            error.newBarcodeId =
              'A product with same barcode id already exists';
            this.setState({ error }, this.saveProduct);
          } else {
            error.newBarcodeId = '';
            this.setState({ error }, this.saveProduct);
          }
        },
        (err) => err,
      );
    } else {
      this.saveProduct();
    }
  };

  fetchUnits = () => {
    this.unitService?.unit$.subscribe((units) => this.setState({ units }));
  };

  onSubmit = () => {
    if (this.checkError()) {
      return;
    }
  };

  saveProduct = () => {
    if (this.state.error.oldBarcodeId || this.state.error.newBarcodeId) {
      return;
    }
    this.setState({ buttonDisabled: true });
    const product = { ...this.state.product };
    if (this.props.company.name.toLowerCase().includes('pearl')) {
      product.hsnCode = this.props.company.hsnCode;
      product.gstRate = this.props.company.gstRate || 0;
      product.sgstRate = Number((product.gstRate / 2).toFixed(2));
      product.cgstRate = Number((product.gstRate / 2).toFixed(2));
    }
    this.productService?.addProduct(
      product,
      (response) => {
        this.setState({
          product:
            this.props.product || JSON.parse(JSON.stringify(productObject)),
          error: { ...productErrorObject },
        });
        this.setState(
          {
            buttonDisabled: false,
            addedProduct: response.data.createProduct.newProduct,
          },
          () => {
            this.props.handleAddedProduct &&
              this.props.handleAddedProduct(this.state.addedProduct);
          },
        );
        notification.success({
          message: 'Product ' + this.state.product.id ? 'Updated' : 'Added',
          description: `Product named ${
            this.state.product.name
          } was successfuly ${this.state.product.id ? 'Updated' : 'Added'}`,
        });
        this.props.handleTabChange && this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message:
            'Product ' + this.state.product.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    this.setState({
      product: JSON.parse(JSON.stringify(productObject)),
      error: { ...productErrorObject },
    });
  };

  render() {
    return (
      <div>
        <ProductForm
          values={this.state.product}
          error={this.state.error}
          onChange={this.onChange}
          units={this.state.units}
          categories={this.props.productCategories}
          isViewActive={false}
          company={this.props.company}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.onSubmit}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  product?: ProductNodeEdge;
  products?: {
    cursor: string;
    node: ProductNodeEdge;
  }[];
  handleTabChange?: (key) => void;
  company: CompanyNodeEdge;
  productForMargin?: {
    name: string;
    purchaseRate: number;
    sellingRate: number | null;
  };
  handleAddedProduct?: (addedProduct) => void;
  productCategories: ProductCategoryEdges[];
}

interface State {
  product: ProductNodeEdge;
  error: ProductError;
  units: UnitEdges[];
  buttonDisabled: boolean;
  addedProduct: ProductNode | null;
}

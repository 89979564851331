import * as React from 'react';
import { Row, Col, Divider, Popover, Button, Icon, Radio } from 'antd';
import { TSInput } from '../common/TSInput';
import { ProductDetails } from './constants';
import { MarginInput, ChallanNode, ProductionTransferNode } from '../../schema';
import { Label } from '../common/TSLabel';
import { ChallanDropDown } from '../Challan/ChallanDropdown';
import { ProductionTransferDropDown } from '../Production/ProductionTransfer/ProductionTransferDropdown';

export const MarginForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Row>
      <Col span={6}>
        <Label title="From">
          <Radio.Group
            name="itemsFrom"
            value={props.values.itemsFrom}
            onChange={(e) =>
              props.onChange({
                target: { name: 'itemsFrom', value: e.target.value },
              })
            }
          >
            <Radio value="PURCHASE">Purchase</Radio>
            <Radio value="PRODUCTION">Production</Radio>
          </Radio.Group>
        </Label>
      </Col>
      <Col span={8}>
        <Label title="Select Invoice No">
          {props.values.itemsFrom === 'PURCHASE' ? (
            <ChallanDropDown
              onSelect={(e, f) => {
                props.challanObjChangeHandler((f as any).props.label);
              }}
              allowClear
            />
          ) : (
            <ProductionTransferDropDown
              onSelect={(e, f) => {
                props.transferObjChangeHandler((f as any).props.label);
              }}
              allowClear
            />
          )}
        </Label>
      </Col>
      <Col span={5}>
        <TSInput
          error=""
          type="number"
          value={props.values.freight}
          name="freight"
          addonBefore="Freight"
          onChange={props.onChange}
          required
        />
      </Col>
      <Col span={5}>
        <TSInput
          error=""
          type="number"
          value={props.values.otherExpenses}
          name="otherExpenses"
          addonBefore="Other Expenses"
          onChange={props.onChange}
          required
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={10}>Product Name</Col>
        <Col span={2}>Quantity</Col>
        <Col span={2}>Rate</Col>
        <Col span={2}>Amount</Col>
        <Col span={2}>Total Cost</Col>
        <Col span={2}>Margin %</Col>
        <Col span={2}>MRP</Col>
        <Col span={2}>Tag Product</Col>
        <Divider />
      </Row>
      {props.values.productDetails &&
        (JSON.parse(props.values.productDetails) as ProductDetails[]).map(
          (product, index) => (
            <div key={'products' + index}>
              <Row>
                <Col span={10}>
                  <TSInput
                    error=""
                    value={product.productName}
                    name="productName"
                    placeholder="Product Name"
                    type="text"
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.quantity || ''}
                    name="quantity"
                    placeholder="Quantity"
                    required
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.rate || ''}
                    name="rate"
                    placeholder="Rate"
                    required
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.amount || ''}
                    name="amount"
                    placeholder="Amount"
                    required
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.total && product.total.toFixed(2)}
                    name="total"
                    placeholder="Total"
                    required
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.margin || ''}
                    name="margin"
                    placeholder="Margin %"
                    onChange={
                      product.productId
                        ? () => {}
                        : (e) => props.productChangeHandler(e, index)
                    }
                    required
                  />
                </Col>
                <Col span={2}>
                  <TSInput
                    error=""
                    type="number"
                    value={product.mrp || ''}
                    name="mrp"
                    placeholder="MRP"
                    required
                  />
                </Col>
                <Col span={2}>
                  <Popover
                    title="Select An Option"
                    trigger="click"
                    content={
                      <div>
                        <Button
                          children="Search From Existing Products"
                          onClick={() =>
                            props.onClickSearchProduct(product, index)
                          }
                        />
                        <Button
                          children="Add New Product"
                          onClick={() =>
                            props.onClickAddProduct(product, index)
                          }
                        />
                      </div>
                    }
                  >
                    <Button
                      children={<Icon type="link" />}
                      style={{ marginTop: '5px', marginLeft: '20px' }}
                      disabled={!!product.productId}
                    />
                  </Popover>
                </Col>
              </Row>
            </div>
          ),
        )}
    </Row>
  );
};

interface Props {
  values: MarginInput;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  productChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  challanObjChangeHandler: (value: ChallanNode) => void;
  onClickSearchProduct: (productDetails: ProductDetails, index: number) => void;
  onClickAddProduct: (productDetails: ProductDetails, index: number) => void;
  transferObjChangeHandler: (
    productionTransferObj: ProductionTransferNode,
  ) => void;
}

import { PurchaseOrderInput } from '../../schema';
import moment from 'moment';

export const purchaseOrderObject: PurchaseOrderInput = {
  date: moment().utcOffset('+0530').format('YYYY-MM-DD'),
  purchaseOrderNo: '',
  vendorId: null,
  productDetails: JSON.stringify([
    {
      productName: '',
      unit: '',
      rate: null,
      quantity: null,
      amount: null,
    },
  ]),
  remarks: null,
  companyId: null,
};

export interface ProductDetailsError {
  productName: string;
  quantity: string;
  unit: string;
  rate: string;
}

export interface PurchaseOrderError {
  date: string;
  vendorId: string;
  productDetails: ProductDetailsError[];
}

export const productDetailsErrorObject: ProductDetailsError = {
  productName: '',
  quantity: '',
  unit: '',
  rate: '',
};

export const purchaseOrderErrorObject: PurchaseOrderError = {
  date: '',
  vendorId: '',
  productDetails: [productDetailsErrorObject],
};

export interface ProductDetails {
  productName: string;
  rate: number | null;
  quantity: number | null;
  unit: string;
  amount: number | null;
  availableQuantity?: number;
}

export const purchaseOrderQuery = `
query PurchaseOrderList($companyId: ID, $after: String) {
  allPurchaseOrders(companyId: $companyId, first: 20, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        date
        purchaseOrderNo
        vendor {
          id
          name
          address
          gstin
          contactNo
          contactPersonName
        }
        productDetails
        remarks
        shippingAddress
        quotationDetails
        isCancel
      }
    }
  }
}
`;

export const purchaseOrderMutation = `
mutation addPurchaseOrder($input: CreatePurchaseOrderInput!) {
  createPurchaseOrder(input: $input){
    newPurchaseOrder {
      id
      purchaseOrderNo
    }
  }
}
`;

export const purchaseOrderCancelMutation = `
  mutation CancelPurchaseOrder(
    $input: CancelPurchaseOrderInput!
  ) {
    cancelPurchaseOrder(input: $input) {
      purchaseOrderToCancel {
        id
        purchaseOrderNo
      }
    }
  }
`;

export interface PurchaseOrderData {
  purchaseOrderDate?: string;
  purchaseOrderNumber?: string;
  vendorName?: string;
  productId: string | null;
  productName: string;
  quantity: number | null;
  rate: number | null;
  receivedDate: string;
  challanNumber: string;
  vendorChallanDate: string;
  vendorChallanNumber: string;
  receivedQuantity: number;
  pendingQuantity: number;
  cancelledQuantity: number;
  itemName: string;
  barcode: string;
  // costPrice: number;
  mrp: number | null;
  transferFromLocation: string;
  remarks?: string;
}

export const PurchaaseOrderStatusReportQuery = `
query PurchaseOrderStatusReport($companyId: ID, $startDate: Date, $endDate: Date, $after: String) {
  allChallans(companyId: $companyId, first: 20, purchaseOrder_Date_Gte: $startDate, purchaseOrder_Date_Lte: $endDate, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges{
      cursor
      node{
        id
        date
        purchaseOrder {
          date
          purchaseOrderNo
          vendor {
            name
          }
					remarks
        }
        productDetails
        challanNo
        vendorChallanDate
        vendorChallanNo
      }
    }
  }
}
`;

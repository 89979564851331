import * as React from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Row, Skeleton, Spin, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { TSInput } from '../common/TSInput';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { DateService } from '../../service/DateService';
import {
  BranchCategory,
  ProductNodeConnection,
  ProductNodeEdge,
  StockReport,
} from '../../schema';
import { getValue } from '../common/AutoCompleteHelper';
import { stocksQuery } from '../master/Product/constants';
import { CompanyService } from '../../service/CompanyService';
import { ColumnProps } from '../common/Table';
import { Pagination } from 'sha-el-design/lib';

export class StockSummary extends React.Component<Props, State> {
  companyService = new CompanyService();
  dateService = new DateService();

  constructor(props: Props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      barcode: '',
      branchId: props.branchId,
    };
  }

  componentWillUnmount() {
    this.dateService?.date$.next(moment().format('YYYY-MM-DD'));
  }

  dateChangeHandler = (date) => {
    this.setState({ date: moment(date).format('YYYY-MM-DD') }, () => {
      this.dateService?.date$.next(this.state.date);
    });
  };

  columns = () => {
    const stockColumns: ColumnProps<ProductNodeEdge>[] = [
      {
        title: 'Product',
        dataIndex: 'node.name',
        key: 'name',
        render: (value, record) =>
          `${value}(${record.node?.oldBarcodeId},${record.node?.newBarcodeId})`,
      },
      {
        title: 'Category',
        key: 'category',
        dataIndex: 'node.category.name',
      },
      {
        title: 'Unit',
        key: 'unit',
        dataIndex: 'node.unit.name',
        render: (value) =>
          this.props.company.name.toLowerCase().includes('tiles')
            ? 'PCS'
            : value && value.split('(')[0].toUpperCase(),
        sortingValue: (value) =>
          this.props.company.name.toLowerCase().includes('tiles')
            ? 'PCS'
            : value && value.split('(')[0].toUpperCase(),
      },
      {
        title: 'Opening Stock',
        dataIndex: 'node.stock.openingStock',
        key: 'openingStock',
      },
      {
        title: 'Transfer',
        dataIndex: 'node.stock.transfer',
        key: 'transfer',
      },
    ];

    if (
      !this.companyService?.company$.value?.name
        .toLowerCase()
        .includes('seva sadan')
    ) {
      stockColumns.push({
        title: 'Receiving',
        dataIndex: 'node.stock.receiving',
        key: 'receiving',
      });
    }
    if (this.companyService?.selectedBussiness$.value?.type === 'branch') {
      stockColumns.push(
        {
          title: 'Sale',
          dataIndex: 'node.stock.sale',
          key: 'sale',
          render: (text, record) =>
            this.props.company.name.toLowerCase().includes('tiles')
              ? text / (record.node?.quantityPerPiece || 1)
              : text,
        },
        {
          title: 'Sale Return',
          dataIndex: 'node.stock.saleReturn',
          key: 'saleReturn',
        },
        {
          title: 'Approval',
          dataIndex: 'node.stock.approval',
          key: 'approval',
        },
        {
          title: 'Approval Return',
          dataIndex: 'node.stock.approvalReturn',
          key: 'approvalReturn',
        },
        {
          title: 'Gift',
          dataIndex: 'node.stock.gift',
          key: 'gift',
        },
      );
    } else {
      stockColumns.push(
        {
          title: 'Purchase',
          dataIndex: 'node.stock.purchase',
          key: 'purchase',
        },
        {
          title: 'Purchase Return',
          dataIndex: 'node.stock.purchaseReturn',
          key: 'purchaseReturn',
        },
        {
          title: 'Damage',
          dataIndex: 'node.stock.damage',
          key: 'damage',
        },
      );
    }
    stockColumns.push(
      {
        title: 'Code Conversion',
        dataIndex: 'node.stock.codeConversion',
        key: 'codeConversion',
      },
      {
        title: 'Closing Stocks',
        dataIndex: 'node.stock.totalStocks',
        key: 'totalStocks',
        render: (data, record) => {
          const quantityPerPiece = Number(record.node?.quantityPerPiece) || 1;
          return this.props.company.name.toLowerCase().includes('tiles')
            ? Number(data) -
                Number((record.node?.stock as StockReport).sale) +
                Number((record.node?.stock as StockReport).sale) /
                  quantityPerPiece
            : Number(Number(data).toFixed(2));
        },
      },
      {
        title: 'MRP',
        dataIndex: 'node.stock.mrp',
        key: 'mrp',
      },
      {
        title: 'Closing Stock Value',
        dataIndex: 'node.stock.totalStocksValue',
        key: 'stockValue',
        render: (data, record) => {
          const quantityPerPiece = Number(record.node?.quantityPerPiece) || 1;
          return this.props.company.name.toLowerCase().includes('tiles')
            ? (data / ((record.node?.stock as StockReport).mrp || 0) -
                Number((record.node?.stock as StockReport).sale) +
                Number((record.node?.stock as StockReport).sale) /
                  quantityPerPiece) *
                ((record.node?.stock as StockReport).mrp || 0)
            : Number(Number(data).toFixed(2));
        },
      },
    );
    return stockColumns;
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { date, currentPage, after, branchId } = this.state;
    const { companyId, business } = this.props;
    const variables = {
      date,
      companyId,
      branchId,
      first: 20,
      after,
      mostUsedProduct: true,
    };
    const { barcode } = this.state;
    return (
      <>
        <Row>
          <Col span={5}>
            <DatePicker
              value={moment(date)}
              onChange={this.dateChangeHandler}
              placeholder="Date"
              format="DD-MM-YYYY"
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
          {business.type === 'company' && (
            <Col span={8}>
              <TSAutoComplete
                error={''}
                inputProps={{ name: 'branchId', addonBefore: 'Location' }}
                dataSource={
                  business.branches?.edges.filter(
                    (element) =>
                      element.node.category === BranchCategory.EMPORIUM,
                  ) || []
                }
                key="id"
                name="branchId"
                onValueSelect={(e) =>
                  this.setState({ branchId: e.target.value })
                }
                displayKey="name"
                textValue={getValue(business.branches?.edges, branchId)}
              />
            </Col>
          )}
          {business.type === 'company' && (
            <Col span={3}>
              <Button
                children="Clear Location"
                onClick={() => this.setState({ branchId: '' })}
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          )}
        </Row>
        <GraphqlQuery
          queryString={stocksQuery}
          variables={barcode ? { ...variables, barcode } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={response.allProducts.totalCount || 0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
              ];
            }
            return <Spin />;
          }}
        />
      </>
    );
  }
}

interface State {
  date: string;
  currentPage: number;
  after: string;
  barcode: string;
  branchId?: string | null;
}

interface Props {
  company: CompanyNodeEdge;
  companyId: string;
  branchId?: string | null;
  business: Bussiness;
}

import * as React from 'react';
import { CustomerNodeEdge, CustomerError } from './constants';
import { TSInput } from '../../common/TSInput';
import { CompanyNodeEdge } from '../Company/constants';

export const CustomerForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <TSInput
        error={props.error.name}
        value={props.values.name}
        onChange={props.onChange}
        name="name"
        type="text"
        addonBefore="Name"
      />
      <TSInput
        error={props.error.address}
        value={props.values.address}
        onChange={props.onChange}
        name="address"
        type="text"
        addonBefore="Address"
      />
      <TSInput
        error={props.error.contactNo}
        value={props.values.contactNo || 0}
        onChange={props.onChange}
        name="contactNo"
        type="number"
        addonBefore="Contact No"
      />
      <TSInput
        error={props.error.email}
        value={props.values.email}
        onChange={props.onChange}
        name="email"
        type="text"
        addonBefore="Email"
      />
      <TSInput
        error={props.error.gstinNumber}
        value={props.values.gstinNumber}
        onChange={props.onChange}
        name="gstinNumber"
        type="text"
        addonBefore="GSTIN Number"
      />
      <TSInput
        error={props.error.gstinLegalName}
        value={props.values.gstinLegalName}
        onChange={props.onChange}
        name="gstinLegalName"
        type="text"
        addonBefore="GSTIN Legal Name"
      />
      {props.company.name === 'Jharcraft' ||
        props.company?.name.toLowerCase().includes('khadi') ||
        props.company.name.toLowerCase().includes('tiles') || (
          <div>
            <TSInput
              error={props.error.dateOfBirth}
              value={props.values.dateOfBirth || ''}
              onChange={props.onChange}
              name="dateOfBirth"
              type="text"
              addonBefore="Date Of Birth"
            />
            <TSInput
              error={props.error.dateOfAnniversary}
              value={props.values.dateOfAnniversary || ''}
              onChange={props.onChange}
              name="dateOfAnniversary"
              type="text"
              addonBefore="Date Of Anniversary"
            />
          </div>
        )}
      <TSInput
        error={''}
        value={props.values.creditLimit || ''}
        onChange={props.onChange}
        name="creditLimit"
        type="text"
        addonBefore="Credit Limit"
      />
    </div>
  );
};

interface Props {
  values: CustomerNodeEdge;
  error: CustomerError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge;
}

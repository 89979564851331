import * as React from 'react';
import {
  Tabs,
  Icon,
  Skeleton,
  Spin,
  Popconfirm,
  notification,
  Button,
} from 'antd';
import { Table } from '../common/Table';
import { CompanyService } from '../../service/CompanyService';
import { CompanyNodeEdge } from '../master/Company/constants';
import { GraphqlQuery } from 'requestapijs';
import { Pagination } from 'sha-el-design';
import {
  PurchaseOrderNode,
  PurchaseOrderNodeConnection,
  PurchaseOrderNodeEdge,
} from '../../schema';
import { purchaseOrderQuery } from './constants';
import { AddPurchaseOrder } from './AddPurchaseOrder';
import moment from 'moment';
import { PurchaseOrderReceiptJharcraft } from './PurchaseOrderReceiptJharcraft';
import { PurchaseOrderReceiptHindgroup } from './PurchaseOrderReceiptHindgroup';
import { PDFViewer } from '@react-pdf/renderer';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../../components/user/constants';
import { checkAddPermission } from '../helper';
import { PurchaseOrderService } from '../../service/PurchaseOrderService';
import { PurchaseOrderReceiptKhadi } from './PurchaseOderReceiptKhadi';

export class PurchaseOrder extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      selectedPurchaseOrder: null,
      company: null,
      currentPage: 1,
      after: '',
      user: null,
      updateListCount: 0,
    };
  }

  companyService = new CompanyService();
  userService = new UserService();
  purchaseOrderService = new PurchaseOrderService();

  componentDidMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedPurchaseOrder: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  purchaseOrderColumn = () => [
    {
      title: 'Date Ordered on',
      dataIndex: 'node.date',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Purchase Order No',
      dataIndex: 'node.purchaseOrderNo',
      key: 'purchaseOrderNo',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedPurchaseOrder: obj.node, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Vendor',
      dataIndex: 'node.vendor.name',
      key: 'vendor',
    },
    {
      title: 'Receipt',
      key: 'receipt',
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({
              selectedPurchaseOrder: record.node,
              activeKey: '3',
            });
          }}
        >
          <Icon type="download" />
        </a>
      ),
    },
    {
      title: 'Cancel',
      key: 'cancel',
      render: (text: PurchaseOrderNodeEdge) => (
        <Popconfirm
          title="Are you sure to Cancel?"
          onConfirm={() =>
            text.node?.id &&
            this.purchaseOrderService?.cancelPurchaseOrder(text.node.id, () => {
              this.setState({
                updateListCount: this.state.updateListCount + 1,
              });
              notification.success({
                message: 'Cancelled',
                description: `Purchase Order was successfully cancelled`,
              });
            })
          }
          okText="Yes"
          cancelText="No"
          disabled={
            text.node?.isCancel ||
            (!!this.state.user && !checkAddPermission(this.state.user))
          }
        >
          <Button
            children={<Icon type="delete" />}
            type="danger"
            disabled={
              text.node?.isCancel ||
              (!!this.state.user && !checkAddPermission(this.state.user))
            }
          />
        </Popconfirm>
      ),
    },
  ];

  afterStack = [''];

  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (!this.state.company) {
      return <Skeleton active />;
    }

    const {
      company: { id: companyId },
      after,
      currentPage,
      activeKey,
      updateListCount,
    } = this.state;
    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Purchase Order
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={purchaseOrderQuery}
            variables={{
              companyId,
              first: 20,
              after,
              activeKey,
              updateListCount,
            }}
            render={(
              response: { allPurchaseOrders: PurchaseOrderNodeConnection },
              error,
              loading,
            ) => {
              if (loading) return <Spin />;
              if (error) return <h3>Something Went Wrong!!</h3>;
              if (response && companyId) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allPurchaseOrders.edges}
                    columns={this.purchaseOrderColumn()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allPurchaseOrders.pageInfo.endCursor,
                        response.allPurchaseOrders.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        {this.state.user && checkAddPermission(this.state.user) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="plus" />
                Add Purchase Order
              </span>
            }
            key="2"
          >
            <AddPurchaseOrder
              purchaseOrder={this.state.selectedPurchaseOrder}
              handleTabChange={this.handleTabChange}
              company={this.state.company}
            />
          </Tabs.TabPane>
        )}
        {this.state.selectedPurchaseOrder && this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="file" />
                Receipt
              </span>
            }
            key="3"
          >
            <PDFViewer style={{ width: '100%', height: '1000px' }}>
              {this.state.company.name.toLowerCase().includes('tiles') ? (
                <PurchaseOrderReceiptHindgroup
                  purchaseOrder={this.state.selectedPurchaseOrder}
                  company={this.state.company}
                />
              ) : this.state.company.name.toLowerCase().includes('khadi') ? (
                <PurchaseOrderReceiptKhadi
                  purchaseOrder={this.state.selectedPurchaseOrder}
                  company={this.state.company}
                />
              ) : (
                <PurchaseOrderReceiptJharcraft
                  purchaseOrder={this.state.selectedPurchaseOrder}
                  company={this.state.company}
                />
              )}
            </PDFViewer>
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedPurchaseOrder: PurchaseOrderNode | null;
  company: CompanyNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  updateListCount: number;
}

interface Props {}

import * as React from 'react';
import { ProductionTransferError } from './constants';
import { Row, Col, DatePicker, Divider, Button, Radio } from 'antd';
import moment from 'moment';
import { Label } from '../../common/TSLabel';
import { TSInput } from '../../common/TSInput';
import { TSAutoComplete } from '../../common/TSAutoComplete';
import { getValue } from '../../common/AutoCompleteHelper';
import { ConversionDropDown } from '../Conversion/ConversionDropdown';
import {
  ConversionNode,
  ProductionTransferInput,
  HsnNode,
} from '../../../schema';
import { CompanyNodeEdge, Bussiness } from '../../master/Company/constants';
import { BranchEdges } from '../../master/Branch/constants';
import { HsnDropDown } from '../../master/Hsn/HsnDropdown';

export const ProductionTransferForm: React.FunctionComponent<Props> = (
  props,
) => {
  return (
    <Row>
      <Col span={3}>
        <DatePicker
          value={
            props.values.date
              ? moment(props.values.date)
              : moment(moment().format('YYYY-MM-DD'))
          }
          onChange={
            props.isEditActive
              ? () => {}
              : (e) =>
                  props.onChange({
                    target: {
                      name: 'date',
                      value: e && moment(e).format('YYYY-MM-DD'),
                    },
                  })
          }
          placeholder="Date"
          format="DD-MM-YYYY"
          style={{ margin: '5px 0', width: '100%' }}
        />
      </Col>
      <Col span={6}>
        <Label title="To">
          <Radio.Group
            name="toHeadOffice"
            value={props.values.toHeadOffice}
            onChange={(e) =>
              props.onChange({
                target: { name: 'toHeadOffice', value: e.target.value },
              })
            }
            disabled={
              props.business.type === 'company' ||
              props.company.name.toLowerCase().includes('pearl')
            }
          >
            <Radio value={true}>Head Office</Radio>
            <Radio value={false}>Other Location</Radio>
          </Radio.Group>
        </Label>
      </Col>
      {props.values.toHeadOffice ? (
        <Col span={6}>
          <TSInput
            error={''}
            addonBefore="Head Office"
            value={props.company.name}
          />
        </Col>
      ) : (
        <Col span={6}>
          <TSAutoComplete
            error={props.error.toBranchId || ''}
            inputProps={{ name: 'toBranchId', addonBefore: 'Location' }}
            dataSource={props.branch.filter((br) => {
              if (props.business.type === 'branch')
                return (
                  br.node.id !== props.business.id &&
                  br.node.category !== 'EMPORIUM'
                );
              return br.node.category !== 'EMPORIUM';
            })}
            key="id"
            name="toBranchId"
            onValueSelect={props.onChange}
            displayKey="name"
            textValue={getValue(props.branch, props.values.toBranchId)}
            allowClear
          />
        </Col>
      )}
      <Col span={9}>
        <TSInput
          error=""
          value={props.values.remarks}
          onChange={(e) =>
            props.onChange({
              target: { name: 'remarks', value: e.target.value },
            })
          }
          addonBefore="Remarks"
          type="text"
        />
      </Col>
      <Divider />
      <h4>Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={7}>Product</Col>
        <Col span={2}>Quantity</Col>
        <Col span={3}>Cost Price</Col>
        <Col span={3}>Amount</Col>
        <Col span={3}>HSN</Col>
        <Col span={2}>Gst Rate</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.conversionDetails.map((conversion, index) => (
        <div key={'conversionDetails' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={7}>
              <ConversionDropDown
                onSelect={(e, f) => {
                  props.isEditActive
                    ? () => {}
                    : props.conversionObjChangehandler(
                        (f as any).props.label,
                        index,
                      );
                  props.isEditActive
                    ? () => {}
                    : props.conversionDetailChangeHandler(
                        { target: { name: 'conversionId', value: String(e) } },
                        index,
                      );
                }}
                value={conversion.conversionId || ''}
                allowClear
              />
              <span style={{ color: 'red' }}>
                {props.error.conversionDetails[index].conversionId}
              </span>
            </Col>
            <Col span={2}>
              <TSInput
                error={props.error.conversionDetails[index].quantity}
                type="number"
                value={conversion.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.conversionDetailChangeHandler(e, index)
                }
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.conversionDetails[index].cost}
                type="number"
                value={conversion.cost || ''}
                name="cost"
                onChange={
                  props.isEditActive
                    ? () => {}
                    : (e) => props.conversionDetailChangeHandler(e, index)
                }
                placeholder="Cost Price"
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error={props.error.conversionDetails[index].amount}
                type="number"
                value={conversion.amount || ''}
                name="amount"
                placeholder="Amount"
                required
              />
            </Col>
            <Col span={3}>
              <HsnDropDown
                onSelect={(e, f) => {
                  props.isEditActive
                    ? () => {}
                    : props.hsnObjChangehandler((f as any).props.label, index);
                  props.isEditActive
                    ? () => {}
                    : props.conversionDetailChangeHandler(
                        { target: { name: 'hsnId', value: String(e) } },
                        index,
                      );
                }}
                value={conversion.hsnId || ''}
                extrafields={[
                  `hsnWithSameCode {
                    hsnCode
                    gst
                    minValue
                    maxValue
                  }`,
                ]}
                allowClear
              />
              <span style={{ color: 'red' }}>
                {props.error.conversionDetails[index].hsnId}
              </span>
            </Col>
            <Col span={2}>
              <TSInput
                error={''}
                type="number"
                value={conversion.gstRate || 0}
                name="gstRate"
                placeholder="Gst Rate"
                required
                tabIndex={-1}
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateconversionDetailsRow('-', index)
                }
                disabled={props.values.conversionDetails.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={
                  props.isEditActive
                    ? () => {}
                    : () => props.updateconversionDetailsRow('+', index)
                }
                disabled={
                  conversion.conversionId &&
                  conversion.quantity &&
                  conversion.cost &&
                  conversion.amount
                    ? false
                    : true
                }
              />
            </Col>
          </Row>
        </div>
      ))}
    </Row>
  );
};

interface Props {
  values: ProductionTransferInput;
  error: ProductionTransferError;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  company: CompanyNodeEdge;
  business: Bussiness;
  branch: BranchEdges[];
  conversionDetailChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateconversionDetailsRow: (type: string, index: number) => void;
  conversionObjChangehandler: (
    conversionObj: ConversionNode,
    index: number,
  ) => void;
  hsnObjChangehandler: (hsnObj: HsnNode, index: number) => void;
  isEditActive: boolean;
}

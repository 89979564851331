import * as React from 'react';
import { ProductionInput, ConversionNode } from '../../../schema';
import { Row, DatePicker, Col, Divider, Button } from 'antd';
import moment from 'moment';
import { TSInput } from '../../common/TSInput';
import { CompanyNodeEdge } from '../../master/Company/constants';
import { ConversionDropDown } from '../Conversion/ConversionDropdown';

export const ProductionForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <DatePicker
            value={
              props.values.date
                ? moment(props.values.date)
                : moment(moment().format('YYYY-MM-DD'))
            }
            onChange={(e) =>
              props.onChange({
                target: {
                  name: 'date',
                  value: e && moment(e).format('YYYY-MM-DD'),
                },
              })
            }
            placeholder="Date"
            format="DD-MM-YYYY"
            style={{ margin: '5px 0', width: '100%' }}
          />
        </Col>
        <Col span={12}>
          <TSInput
            error={''}
            type="text"
            value={props.values.remark}
            name="remark"
            placeholder="Remarks"
            onChange={(e) => props.onChange(e)}
            addonBefore="Remarks"
          />
        </Col>
      </Row>
      <Divider />
      <h4>Finished Products</h4>
      <Row>
        <Col span={1}>Sl. No.</Col>
        <Col span={6}>Product</Col>
        <Col span={3}>Quantity</Col>
        <Col span={3}>Cost</Col>
        <Col span={4}>Amount</Col>
        <Col span={4}>Remarks</Col>
        <Col span={2}>Remove</Col>
        <Col span={1}>Add</Col>
        <Divider />
      </Row>
      {props.values.conversionDetails.map((conversion, index) => (
        <div key={'conversionDetails' + index}>
          <Row>
            <Col span={1}>{index + 1}.</Col>
            <Col span={6}>
              <ConversionDropDown
                onSelect={(e, f) => {
                  props.conversionObjChangehandler(
                    (f as any).props.label,
                    index,
                  );
                  props.conversionDetailChangeHandler(
                    { target: { name: 'conversionId', value: String(e) } },
                    index,
                  );
                }}
                value={conversion.conversionId || ''}
                allowClear
              />
            </Col>
            <Col span={3}>
              <TSInput
                error=""
                type="number"
                value={conversion.quantity || ''}
                name="quantity"
                placeholder="Quantity"
                onChange={(e) => props.conversionDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={3}>
              <TSInput
                error=""
                type="number"
                value={conversion.cost || ''}
                name="cost"
                placeholder="Cost"
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="number"
                value={conversion.amount || ''}
                name="amount"
                placeholder="Amount"
                onChange={(e) => props.conversionDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={4}>
              <TSInput
                error=""
                type="text"
                value={conversion.remark || ''}
                name="remark"
                placeholder="Remarks"
                onChange={(e) => props.conversionDetailChangeHandler(e, index)}
                required
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                shape="circle"
                icon="minus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('-', index)}
                disabled={props.values.conversionDetails.length === 1}
              />
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                style={{ marginLeft: '5px' }}
                onClick={() => props.updateRow('+', index)}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Col span={24}>
        <TSInput
          error=""
          type="text"
          value={props.values.amount}
          name="amount"
          placeholder="amount"
          addonBefore="Total Amount"
        />
      </Col>
    </div>
  );
};

interface Props {
  values: ProductionInput;
  company: CompanyNodeEdge;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => void;
  conversionDetailChangeHandler: (
    e: { target: { name: string; value: string } },
    index: number,
  ) => void;
  updateRow: (type: string, index: number) => void;
  conversionObjChangehandler: (
    conversionObj: ConversionNode,
    index: number,
  ) => void;
}

import { PageInfo } from '../../../schema';

export interface DiscountCategoryNode {
  id?: string;
  name: string;
  description?: string;
  mda: number;
  commission: number;
  companyId?: string | null;
}

export interface DiscountCategoryNodeEdge {
  cursor: string;
  node: DiscountCategoryNode;
}

export interface DiscountCategoryNodeConnection {
  pageInfo: PageInfo;
  edges: DiscountCategoryNodeEdge[];
}

export const discountCategoryObject: DiscountCategoryNode = {
  name: '',
  mda: 0,
  commission: 0,
  companyId: null,
};

export const discountCategoriesQuery = `
query discountCategoriesList($companyId: ID, $after: String) {
  allDiscountCategories(first: 20, companyId: $companyId, orderBy: "-id", after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        name
        description
        mda
        commission
      }
    }
  }
}
`;

export const allDiscountCategoriesQuery = `
query discountCategory($companyId: ID) {
  allDiscountCategories(companyId: $companyId) {
    edges {
      node {
        name
        mda
        commission
      }
    }
  }
}
`;

export interface DiscountCategoryQueryResponse {
  data: {
    allDiscountCategories: {
      edges: DiscountCategoryNodeEdge[];
    };
  };
}

export interface AddDiscountCategoryVariables {
  input: {
    discountCategory: DiscountCategoryNode;
  };
}

export interface AddDiscountCategoryPayload {
  newDiscountCategory: string;
}

export const discountCategoryMutation = `
mutation DiscountCategory($input: CreateDiscountCategoryInput!){
  createDiscountCategory(input: $input) {
    newDiscountCategory
  }
}
`;

import { CompanyNodeEdge } from '../master/Company/constants';
import { CustomerNodeEdge } from '../master/Customer/constants';
import { VendorNode } from '../master/vendor/constants';
import { BranchNodeEdge } from '../master/Branch/constants';
import {
  ProductDetailsNodeConnection,
  ProductDetailNodeEdge,
} from '../Sales/constants';
import moment from 'moment';

export interface ValueaditionNodeEdgeQuery {
  id?: string;
  date: string;
  customer: CustomerNodeEdge;
  vendor: VendorNode;
  isReturn: boolean;
  isVendor: boolean;
  oldValueadition?: ValueaditionNodeEdgeQuery;
  productDetails?: ProductDetailsNodeConnection;
  valueaditionNo: string;
  branchSerialNo: string;
  totalCostAmount: number;
  totalMrp: number;
  valueaditionCost: number;
  remark?: string;
  approvedByName: string;
  company?: CompanyNodeEdge;
  branch?: BranchNodeEdge;
}

export interface ValueaditionNodeEdgeMutation {
  id?: string;
  date: string;
  customerId: string | null;
  vendorId: string | null;
  isReturn: boolean;
  oldValueaditionId?: string | null;
  productDetails?: ProductDetailNodeEdge[];
  valueaditionNo: string | null;
  branchSerialNo: string | null;
  totalCostAmount: number | null;
  valueaditionCost?: number | null;
  totalMrp: number | null;
  remark?: string | null;
  approvedByName: string | null;
  companyId?: string | null;
  branchId?: string | null;
}

export interface ProductError {
  productId?: string;
  rate?: string;
  quantity?: string;
  amount?: string;
  discount?: string;
}

export interface ValueaditionError {
  date: string;
  customerId: string;
  vendorId: string;
  oldValueaditionId: string;
  productDetails: ProductError[];
  remark: string;
  approvedByName: string;
}

export const valueaditionObject: ValueaditionNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  vendorId: null,
  isReturn: false,
  oldValueaditionId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: null,
      quantity: null,
      amount: 0,
      movementForId: '',
      movementType: 'APPROVAL',
      companyId: '',
      discount: null,
      remark: '',
    },
  ],
  valueaditionNo: null,
  branchSerialNo: null,
  totalCostAmount: null,
  valueaditionCost: null,
  totalMrp: null,
  remark: null,
  approvedByName: null,
  companyId: null,
  branchId: null,
};

export const productDetailsErrorObj: ProductError = {
  productId: '',
  rate: '',
  quantity: '',
  amount: '',
  discount: '',
};

export const valueaditionErrorObj: ValueaditionError = {
  date: '',
  customerId: '',
  vendorId: '',
  oldValueaditionId: '',
  productDetails: [productDetailsErrorObj],
  remark: '',
  approvedByName: '',
};

export const valueaditionQuery = `
  query ValueaditionList($companyId: ID!, $branchId: ID, $date_Lte: Date!, $branchId_Isnull: Boolean) {
    allValueaditions(companyId: $companyId, branchId: $branchId, date_Lte: $date_Lte, branchId_Isnull: $branchId_Isnull) {
      edges {
        node {
          id
          date
          customer {
            id
            name
            contactNo
            address
          }
          vendor {
            id
            name
            contactNo
          }
          isReturn
          oldValueadition {
            id
            valueaditionNo
          }
          productDetails {
            edges {
              node {
                id
                product {
                  id
                  name
                  oldBarcodeId
                  hsn {
                    hsnCode
                  }
                  category {
                    id
                    name
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                discount
                remark
                movementType
                movementFor {
                  id
                  name
                }
              }
            }
          }
          valueaditionNo
          branchSerialNo
          totalCostAmount
          valueaditionCost
          totalMrp
          remark
          approvedByName
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export interface ValueaditionQueryVariable {
  companyId: string;
  branchId: string;
  date_Lte: string;
}

export interface ValueaditionQueryResponse {
  data: {
    allValueaditions: {
      edges: ValueaditionEdges[];
    };
  };
}

export interface ValueaditionEdges {
  cursor: string;
  node: ValueaditionNodeEdgeQuery;
}

export interface AddValueaditionVariable {
  input: {
    valueadition: ValueaditionNodeEdgeMutation;
  };
}

export const valueaditionMutation = `
  mutation addValueadition(
    $input: CreateValueaditionInput!
  ) {
    createValueadition(input: $input){
      newValueadition {
        id
        date
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

import * as React from 'react';
import { Icon, Skeleton, Tabs } from 'antd';
import { CompanyService } from '../../../service/CompanyService';
import { UserService } from '../../../service/UserService';
import { checkAddPermission } from '../../helper';
import { UserNodeEdge } from '../../user/constants';
import {
  productSubCategoriesQuery,
  ProductSubCategoryNodeEdge,
  ProductSubCategoryNodeConnection,
} from './constants';
import { GraphqlQuery } from 'requestapijs';
import { Table } from '../../common/Table';
import { Pagination } from 'sha-el-design';
import { ProductCategoryEdges } from '../ProductCategories/constants';
import { ProductCategoryService } from '../../../service/ProductCategoryService';
import { AddProductSubCategory } from './AddProductSubCategory';

export class ProductSubCategories extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      selectedProductSubCategory: null,
      after: '',
      currentPage: 1,
      user: null,
      companyId: null,
      productCategories: [],
    };
  }

  companyService = new CompanyService();
  productCategoryService = new ProductCategoryService();
  userService = new UserService();

  componentDidMount() {
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
    this.productCategoryService?.productCategory$.subscribe(
      (productCategories) => this.setState({ productCategories }),
    );
    this.companyService?.company$.subscribe(
      (company) => company && this.setState({ companyId: company.id }),
    );
  }

  handleTabChange = (activeKey: string) => {
    if (activeKey === '1') {
      this.setState({ activeKey, selectedProductSubCategory: null });
      this.componentDidMount();
      return;
    }
    this.setState({ activeKey });
  };

  productSubCategoryColumns = () => [
    {
      title: 'Name',
      dataIndex: 'node.name',
      key: 'name',
      render: (text, obj) => (
        <a
          onClick={() => {
            this.setState({ selectedProductSubCategory: obj, activeKey: '2' });
          }}
        >
          {text}
        </a>
      ),
      sortingValue: (text) => text,
    },
    {
      title: 'Category',
      dataIndex: 'node.category.name',
      key: 'category',
      sortingValue: (text) => text,
    },
  ];

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    if (this.state.user && !checkAddPermission(this.state.user))
      return <h1>Access Denied</h1>;
    const { after, currentPage, activeKey, companyId } = this.state;
    const variables = { after, companyId, activeKey };
    return (
      <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="table" />
              List Product Sub Categories
            </span>
          }
          key="1"
          closable={false}
        >
          <GraphqlQuery
            queryString={productSubCategoriesQuery}
            variables={variables}
            render={(
              response: {
                allProductSubCategories: ProductSubCategoryNodeConnection;
              },
              error,
              loading,
            ) => {
              if (loading) return <Skeleton active />;
              if (response) {
                return [
                  <Table
                    key="table"
                    dataSource={response.allProductSubCategories.edges}
                    columns={this.productSubCategoryColumns()}
                    rowKey={(obj) => obj.node?.id || ''}
                    pagination={false}
                  />,
                  <Pagination
                    key="pagination"
                    totalCount={0}
                    currentPage={currentPage}
                    batchSize={20}
                    cursorBasedPagination
                    onChange={(_p, _ps, next) =>
                      this.onPageChange(
                        next,
                        response.allProductSubCategories.pageInfo.endCursor,
                        response.allProductSubCategories.pageInfo.hasNextPage,
                      )
                    }
                    style={{ float: 'right' }}
                  />,
                ];
              }
              return <Skeleton active />;
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="plus" />
              Add Product Sub Category
            </span>
          }
          key="2"
        >
          <AddProductSubCategory
            productSubCategory={this.state.selectedProductSubCategory?.node}
            handleTabChange={this.handleTabChange}
            productCategories={this.state.productCategories}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  selectedProductSubCategory: ProductSubCategoryNodeEdge | null;
  after: string;
  currentPage: number;
  user?: UserNodeEdge | null;
  companyId?: string | null;
  productCategories: ProductCategoryEdges[];
}

interface Props {}

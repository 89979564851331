import * as React from 'react';
import {
  productCategoriesObject,
  productCategoriesErrorObject,
  ProductCategoryNodeEdge,
  ProductCategoryError,
} from './constants';
import { ProductCategoriesForm } from './ProductCategoriesForm';
import { Button, notification } from 'antd';
import { ProductCategoryService } from '../../../service/ProductCategoryService';

export class AddProductCategories extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productCategories:
        props.productCategories ||
        JSON.parse(JSON.stringify(productCategoriesObject)),
      error: { ...productCategoriesErrorObject },
      buttonDisabled: false,
    };
  }

  productCategoryService = new ProductCategoryService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      productCategories:
        nextProps.productCategories ||
        JSON.parse(JSON.stringify(productCategoriesObject)),
    });
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const productCategories = { ...this.state.productCategories };
    productCategories[e.target.name] = e.target.value;
    this.setState({ productCategories });
  };

  saveProductCategories = () => {
    this.setState({ buttonDisabled: true });
    this.productCategoryService?.addProductCategory(
      this.state.productCategories,
      (response) => {
        this.setState({ buttonDisabled: false });
        notification.success({
          message:
            'Product Category ' + this.state.productCategories.id
              ? 'Updated'
              : 'Added',
          description: `Product Category named ${
            this.state.productCategories.name
          }
            was successfuly ${
              this.state.productCategories.id ? 'Updated' : 'Added'
            }`,
        });
        this.props.handleTabChange('1');
      },
      (error) =>
        notification.error({
          message:
            'Product Category ' + this.state.productCategories.id
              ? 'Update Error'
              : 'Add Error',
          description: JSON.stringify(error),
        }),
    );
  };

  clear = () => {
    this.setState({
      productCategories: JSON.parse(JSON.stringify(productCategoriesObject)),
      error: { ...productCategoriesErrorObject },
    });
  };

  render() {
    return (
      <div>
        <ProductCategoriesForm
          values={this.state.productCategories}
          error={this.state.error}
          onChange={this.onChange}
        />
        <Button
          type="dashed"
          onClick={this.clear}
          children="Clear"
          style={{ width: '50%' }}
        />
        <Button
          type="primary"
          onClick={this.saveProductCategories}
          children="Submit"
          style={{ width: '50%' }}
          disabled={this.state.buttonDisabled}
        />
      </div>
    );
  }
}

interface Props {
  productCategories?: ProductCategoryNodeEdge;
  handleTabChange: (key) => void;
}

interface State {
  productCategories: ProductCategoryNodeEdge;
  error: ProductCategoryError;
  buttonDisabled: boolean;
}

export interface AcceptProductionReceivingError {
  receivingDate: string;
  conversionDetails: {
    conversionId: string;
    status: string;
    remark: string;
  }[];
  status: string;
}

export const acceptProductionReceivingError: AcceptProductionReceivingError = {
  receivingDate: '',
  conversionDetails: [
    {
      conversionId: '',
      remark: '',
      status: '',
    },
  ],
  status: '',
};

export interface ProductionReceivingError {
  jobOrderId: string;
  conversionDetails: {
    conversionId: string;
    cost: string;
    quantity: string;
    amount: string;
    hsnId: string;
  }[];
  fromBranchId: string;
  vendorForId: string;
}

export const productionReceivingError: ProductionReceivingError = {
  jobOrderId: '',
  conversionDetails: [
    {
      conversionId: '',
      cost: '',
      quantity: '',
      amount: '',
      hsnId: '',
    },
  ],
  fromBranchId: '',
  vendorForId: '',
};

export const pendingProductionReceivingQuery = `
  query PendingReceivingList($toBranchId: ID, $companyId: ID, $toHeadOffice: Boolean, $date_Lte: Date!) {
    allProductionTransfers(
      toBranchId: $toBranchId,
      companyId: $companyId,
      toHeadOffice: $toHeadOffice,
      date_Lte: $date_Lte,
      status: "PENDING"
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          date
          receivingDate
          toHeadOffice
          fromBranch {
            id
            name
          }
          toBranch {
            id
          }
          remarks
          transferInvoiceNo
          status
          fromVendor
          toVendor
          conversionDetails {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              cursor
              node {
                id
                conversion {
                  id
                  finishedProduct
                }
                cost
                quantity
                amount
                gstRate
                hsn {
                  id
                  hsnCode
                }
                conversionType
                remark
                status
                sourceBranch {
                  id
                  name
                }
                destinationBranch {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const acceptedProductionReceivingQuery = `
  query AcceptedReceivingList($toBranchId: ID, $companyId: ID, $toHeadOffice: Boolean, $date_Lte: Date!, $after: String) {
    allProductionTransfers(
      toBranchId: $toBranchId,
      companyId: $companyId,
      toHeadOffice: $toHeadOffice,
      date_Lte: $date_Lte,
      status_Contains: "ACCEPTED",
      after: $after,
      first: 20
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          date
          receivingDate
          toHeadOffice
          fromBranch {
            id
            name
            address
            gstin
            state
            category
          }
          toBranch {
            id
            name
            address
            gstin
            state
            category
          }
          remarks
          status
          transferInvoiceNo
          receivingInvoiceNo
          vendorFor {
            id
            name
            gstin
            address
            state
          }
          hsnGstWiseDetailsReceiving {
            hsnCode
            gst
            quantity
            amount
          }
          conversionDetails(status: "ACCEPTED") {
            edges {
              cursor
              node {
                id
                conversion {
                  id
                  finishedProduct
                }
                cost
                quantity
                amount
                gstRate
                hsn {
                  hsnCode
                }
                conversionType
                remark
                status
                sourceBranch {
                  id
                  name
                }
                destinationBranch {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const itemWiseProductionReceivingQuery = `
query ProductionReceivingReport ($companyId: ID!, $startDate: Date!, $endDate: Date!, $after: String) {
  allConversionDetails (
    companyId: $companyId, 
    productiontransfer_ReceivingDate_Gte: $startDate, 
    productiontransfer_ReceivingDate_Lte: $endDate, 
    conversionType: "TRANSFER", 
    status: "ACCEPTED", 
    after: $after, 
    first: 20
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        conversion {
          finishedProduct
        }
        quantity
        cost
        amount
        sourceBranch {
          name
        }
        vendorFor {
          name
        }
        productiontransferSet {
          edges {
            node {
              receivingDate
              receivingInvoiceNo
              jobOrder {
                date
                jobOrderNo
              }
            }
          }
        }
      }
    }
	}
}
`;

export const summarizedProductionReceivingReport = `
query SummarizedReceivingReportQuery($companyId: ID!, $startDate: Date!, $endDate: Date!, $after: Int!) {
  summarizedReceivingReport(
    companyId: $companyId,
    startDate: $startDate,
    endDate: $endDate,
    limit: 20,
    offset: $after,
  ) {
    jobOrderDate
    jobOrderNo
    productName
    vendor
    productionCenter
    orderedQty
    receivedQty
    costPrice
    wapNo
  }
}
`;

import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { GraphqlQuery } from 'requestapijs';
import { Spin, AutoComplete } from 'antd';
import { AreaNodeConnection, AreaNodeEdge } from '../../../schema';
import { OptionProps } from 'antd/lib/select';

interface CustomOption extends OptionProps {
  label?: React.ReactNode;
  display?: string;
  area?: any;
}

const Option = AutoComplete.Option as React.ClassicComponentClass<CustomOption>;

interface AreaDropDownProps extends AutoCompleteProps {
  extrafields?: string[];
}

export class AreaDropDown extends React.Component<AreaDropDownProps, State> {
  static defaultProps = {
    extrafields: [],
  };

  constructor(props: Readonly<AreaDropDownProps>) {
    super(props);

    this.state = {
      name: '',
      area: null,
      touched: false,
    };
  }

  value = (areas: AreaNodeEdge[]) => {
    if (this.state.touched) {
      return this.state.name;
    }

    const area = areas.find((v) => v.node?.id === this.props.value);
    if (!area) return;
    return area.node?.name;
  };

  render() {
    const { name } = this.state;
    const { extrafields, value, onSelect } = this.props;
    return (
      <GraphqlQuery
        queryString={`query AreaDropDown($id: ID, $name: String, ) {
          allAreas(id: $id, name_Icontains: $name) {
            edges {
              node {
                id
                name
              }
            }
          }
        }`}
        variables={{
          after: '',
          name,
          id: value && !name ? value : '',
        }}
        render={(
          response: { allAreas: AreaNodeConnection },
          error,
          loading,
        ) => {
          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <div>{JSON.stringify(error)}</div>;
          }
          if (response) {
            return (
              <div style={{ margin: '5px 0' }}>
                <AutoComplete
                  {...this.props}
                  placeholder="Select Area"
                  dataSource={response.allAreas.edges.map((area) => (
                    <Option key={area.node?.id} label={area.node} area={area}>
                      {area.node?.name}
                    </Option>
                  ))}
                  value={this.value(response.allAreas.edges)}
                  optionLabelProp="children"
                  style={{ width: '100%' }}
                  onSearch={(area) =>
                    this.setState({ name: area, touched: true })
                  }
                  onSelect={(e, f) => {
                    this.setState({
                      name: (f as any).props.area.node.name,
                      touched: true,
                    });
                    onSelect && onSelect(e, f);
                  }}
                />
              </div>
            );
          }
        }}
      />
    );
  }
}

interface State {
  name: string;
  area: AreaNodeEdge | null;
  touched: boolean;
}

import moment from 'moment';
import { ProductDetailsInput } from '../../../schema';

export const values: ProductDetailsInput[] = [
  {
    date: moment().format('YYYY-MM-DD'),
    productId: null,
    quantity: null,
    purchaseRate: null,
    rate: null,
    amount: null,
    newProductId: null,
    movementType: 'ITEM_CONVERSION',
    companyId: null,
  },
];

export const productDetailsMutation = `
mutation ProductDetails($input: CreateProductDetailsInput!) {
  createProductDetails(input: $input) {
    newProductDetails {
      id
      date
      product {
        id
        name
      }
    }
  }
}
`;

export const productDetailsQuery = `
query productDetails($after: String, $companyId: ID) {
  allProductDetails(
    movementType: "PRODUCT_CONVERSION",
    first: 20,
    after: $after,
    companyId: $companyId,
    orderBy: "-id",
    status_In: "PENDING,REJECTED,ACCEPTED",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        product {
          id
          name
        }
        quantity
        newProduct {
          id
          name
        }
      }
    }
  }
}
`;
